import type {
  GetStaff200Response,
  GetStaffs200ResponseResultsInner,
} from "@/api/";

import _ from "lodash";

export function replace(oldStaff: any, updatedStaff: any) {
  baseDummyData = _.concat(
    _.filter(baseDummyData, (staff) => staff !== oldStaff),
    { ...updatedStaff, accountActiveFlag: oldStaff.accountActiveFlag }
  );
}

export function changeActiveFlag(oldStaff: any, newAccountActiveFlag: boolean) {
  baseDummyData = _.concat(
    _.filter(baseDummyData, (staff) => staff !== oldStaff),
    { ...oldStaff, accountActiveFlag: newAccountActiveFlag }
  );
}

export function changeDummyPassword(oldStaff: any, newDummyPassword: any) {
  baseDummyData = _.concat(
    _.filter(baseDummyData, (staff) => staff !== oldStaff),
    { ...oldStaff, dummyPassword: newDummyPassword }
  );
}
/**
 * dummy data for StaffQuery APIs.
 * return GetStaff200Response[]
 */
export const defaultBaseDummyData: GetStaff200Response[] = [
  {
    staffId: 7777,
    staffNumber: "7777",
    staffRoleType: "admin",
    accountActiveFlag: true,
    performanceBasedPayFlag: true,
    dummyPassword: "password123",
    personal: {
      firstName: "Valerie",
      lastName: "Yamanaka",
      firstNameKana: "ヴァレリー",
      lastNameKana: "ヤマナカ",
      dateOfBirth: "1987-07-21",
      sex: "female",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress1: "system@doitplanning.com",
      mailAddress2: "system@doitplanning.com",
    },
    emergencyContact: {
      firstName: "Takeru",
      lastName: "Yamanaka",
      firstNameKana: "たける",
      lastNameKana: "ヤマナカ",
      tel1: "080000227435",
    },
    departmentAffiliation: [
      {
        departmentAffiliationId: 1,
        departmentId: 1,
        affiliationAtFrom: "2022-07-21",
        affiliationAtTo: "2022-07-21",
      },
    ],
    companyAffiliation: {
      hiringDateAt: "2022-07-21",
      leavingDateAt: "2022-07-21",
    },
    commissionShareDefault: {
      companyShare: "15.5",
      myselfShare: "77.5",
      bossShare: "5",
      otherStaffId1: 1234567,
      otherStaffShare1: "1.5",
      otherStaffId2: 1234567,
      otherStaffShare2: "0.25",
      otherStaffId3: 1234567,
      otherStaffShare3: "0.25",
    },
    incentiveShareDefault: {
      companyShare: "5",
      myselfShare: "58",
      bossShare: "30",
      otherStaffId1: 1234567,
      otherStaffShare1: "1.25",
      otherStaffId2: 1234567,
      otherStaffShare2: "2.25",
      otherStaffId3: 1234567,
      otherStaffShare3: "3.5",
    },
    license: {
      lifeInsuranceStaffNumber: "ab234238495",
      nonLifeInsuranceStaffNumber: "f839283645",
      variableInsuranceSalesQualificationNumber: "38374",
      foreignCurrencyInsuranceSalesQualificationNumber: "d93283",
      smallAmountShortTermQualificationNumber: "n83747",
      hasSpecializedCourse: true,
      hasUniversityCourse: false,
      hasAppliedCourse: false,
      hasAfpQualification: true,
      hasCfpQualification: true,
      fpQualification: "noQualification",
      securitiesSalespersonQualification: "firstClass",
    },
    performanceBasedPay: {
      employmentInsuranceFlag: false,
      longTermCareInsuranceFlag: false,
      healthInsuranceStandardMonthlyRemuneration: 360000,
      welfarePensionStandardMonthlyRemuneration: 360000,
      basicSalary: 150000,
      entryFee: 20000,
      basicSalaryDeductionFlag: false,
      socialInsurancePremiumDeductionFlag: false,
      invoiceBasedFlag: false,
      invoiceCode: "12345",
      performanceBasedPayToStaffId: 1234567,
      fixedSalaryFlag: true,
      specialDeductionFlag: true,
      performanceBasedPayTermFrom: "202407",
      performanceBasedPayTermTo: "202412",
    },
  },
  {
    staffId: 69736,
    staffNumber: "69736",
    staffRoleType: "normal",
    accountActiveFlag: true,
    performanceBasedPayFlag: false,
    dummyPassword: "password123",
    personal: {
      firstName: "ハルト",
      lastName: "田中",
      firstNameKana: "はると",
      lastNameKana: "たなか",
      dateOfBirth: "1980-07-21",
      sex: "male",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress1: "system@doitplanning.com",
      mailAddress2: "system@doitplanning.com",
    },
    emergencyContact: {
      firstName: "まり",
      lastName: "佐藤",
      firstNameKana: "まり",
      lastNameKana: "さとう",
      tel1: "080000227435",
    },
    departmentAffiliation: [
      {
        departmentAffiliationId: 1,
        departmentId: 1,
        affiliationAtFrom: "2022-07-21",
        affiliationAtTo: "2022-07-21",
      },
    ],
    companyAffiliation: {
      hiringDateAt: "2022-07-21",
      leavingDateAt: "2022-07-21",
    },
    performanceBasedPay: {
      employmentInsuranceFlag: false,
      longTermCareInsuranceFlag: false,
      healthInsuranceStandardMonthlyRemuneration: 360000,
      welfarePensionStandardMonthlyRemuneration: 360000,
      basicSalary: 150000,
      entryFee: 20000,
      basicSalaryDeductionFlag: false,
      socialInsurancePremiumDeductionFlag: false,
      invoiceBasedFlag: false,
      invoiceCode: "12345",
      performanceBasedPayToStaffId: 1234567,
    },
    commissionShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    incentiveShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    license: {
      lifeInsuranceStaffNumber: "ab234238495",
      nonLifeInsuranceStaffNumber: "f839283645",
      variableInsuranceSalesQualificationNumber: "38374",
      foreignCurrencyInsuranceSalesQualificationNumber: "d93283",
      smallAmountShortTermQualificationNumber: "n83747",
      hasSpecializedCourse: true,
      hasUniversityCourse: false,
      hasAppliedCourse: false,
      hasAfpQualification: true,
      hasCfpQualification: true,
      fpQualification: "noQualification",
      securitiesSalespersonQualification: "firstClass",
    },
  },

  {
    staffId: 54224,
    staffNumber: "54224",
    staffRoleType: "normal",
    accountActiveFlag: true,
    performanceBasedPayFlag: false,
    dummyPassword: "password123",
    personal: {
      firstName: "メイ",
      lastName: "田中",
      firstNameKana: "めい",
      lastNameKana: "たなか",
      dateOfBirth: "1980-07-21",
      sex: "female",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress1: "system@doitplanning.com",
      mailAddress2: "system@doitplanning.com",
    },
    emergencyContact: {
      firstName: "まり",
      lastName: "佐藤",
      firstNameKana: "まり",
      lastNameKana: "さとう",
      tel1: "080000227435",
    },
    departmentAffiliation: [
      {
        departmentAffiliationId: 2,
        departmentId: 2,
        affiliationAtFrom: "2022-07-21",
        affiliationAtTo: "2022-07-21",
      },
    ],
    companyAffiliation: {
      hiringDateAt: "2022-07-21",
      leavingDateAt: "2022-07-21",
    },
    performanceBasedPay: {
      employmentInsuranceFlag: false,
      longTermCareInsuranceFlag: false,
      healthInsuranceStandardMonthlyRemuneration: 360000,
      welfarePensionStandardMonthlyRemuneration: 360000,
      basicSalary: 150000,
      entryFee: 20000,
      basicSalaryDeductionFlag: false,
      socialInsurancePremiumDeductionFlag: false,
      invoiceBasedFlag: false,
      invoiceCode: "12345",
      performanceBasedPayToStaffId: 1234567,
    },
    commissionShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    incentiveShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    license: {
      lifeInsuranceStaffNumber: "ab234238495",
      nonLifeInsuranceStaffNumber: "f839283645",
      variableInsuranceSalesQualificationNumber: "38374",
      foreignCurrencyInsuranceSalesQualificationNumber: "d93283",
      smallAmountShortTermQualificationNumber: "n83747",
      hasSpecializedCourse: true,
      hasUniversityCourse: false,
      hasAppliedCourse: false,
      hasAfpQualification: true,
      hasCfpQualification: true,
      fpQualification: "noQualification",
      securitiesSalespersonQualification: "firstClass",
    },
  },
  {
    staffId: 24383,
    staffNumber: "24383",
    staffRoleType: "normal",
    accountActiveFlag: true,
    performanceBasedPayFlag: false,
    dummyPassword: "password123",
    personal: {
      firstName: "ハルト",
      lastName: "中山",
      firstNameKana: "はると",
      lastNameKana: "なかやま",
      dateOfBirth: "1980-07-21",
      sex: "male",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress1: "system@doitplanning.com",
      mailAddress2: "system@doitplanning.com",
    },
    emergencyContact: {
      firstName: "まり",
      lastName: "佐藤",
      firstNameKana: "まり",
      lastNameKana: "さとう",
      tel1: "080000227435",
    },
    departmentAffiliation: [
      {
        departmentAffiliationId: 3,
        departmentId: 3,
        affiliationAtFrom: "2022-07-21",
        affiliationAtTo: "2022-07-21",
      },
    ],
    companyAffiliation: {
      hiringDateAt: "2022-07-21",
      leavingDateAt: "2022-07-21",
    },
    performanceBasedPay: {
      employmentInsuranceFlag: false,
      longTermCareInsuranceFlag: false,
      healthInsuranceStandardMonthlyRemuneration: 360000,
      welfarePensionStandardMonthlyRemuneration: 360000,
      basicSalary: 150000,
      entryFee: 20000,
      basicSalaryDeductionFlag: false,
      socialInsurancePremiumDeductionFlag: false,
      invoiceBasedFlag: false,
      invoiceCode: "12345",
      performanceBasedPayToStaffId: 1234567,
    },
    commissionShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    incentiveShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    license: {
      lifeInsuranceStaffNumber: "ab234238495",
      nonLifeInsuranceStaffNumber: "f839283645",
      variableInsuranceSalesQualificationNumber: "38374",
      foreignCurrencyInsuranceSalesQualificationNumber: "d93283",
      smallAmountShortTermQualificationNumber: "n83747",
      hasSpecializedCourse: true,
      hasUniversityCourse: false,
      hasAppliedCourse: false,
      hasAfpQualification: true,
      hasCfpQualification: true,
      fpQualification: "noQualification",
      securitiesSalespersonQualification: "firstClass",
    },
  },
  {
    staffId: 60489,
    staffNumber: "60489",
    staffRoleType: "normal",
    accountActiveFlag: true,
    performanceBasedPayFlag: false,
    dummyPassword: "password123",
    personal: {
      firstName: "メイ",
      lastName: "中山",
      firstNameKana: "めい",
      lastNameKana: "なかやま",
      dateOfBirth: "1980-07-21",
      sex: "female",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress1: "system@doitplanning.com",
      mailAddress2: "system@doitplanning.com",
    },
    emergencyContact: {
      firstName: "まり",
      lastName: "佐藤",
      firstNameKana: "まり",
      lastNameKana: "さとう",
      tel1: "080000227435",
    },
    departmentAffiliation: [
      {
        departmentAffiliationId: 1,
        departmentId: 1,
        affiliationAtFrom: "2022-07-21",
        affiliationAtTo: "2022-07-21",
      },
    ],
    companyAffiliation: {
      hiringDateAt: "2022-07-21",
      leavingDateAt: "2022-07-21",
    },
    performanceBasedPay: {
      employmentInsuranceFlag: false,
      longTermCareInsuranceFlag: false,
      healthInsuranceStandardMonthlyRemuneration: 360000,
      welfarePensionStandardMonthlyRemuneration: 360000,
      basicSalary: 150000,
      entryFee: 20000,
      basicSalaryDeductionFlag: false,
      socialInsurancePremiumDeductionFlag: false,
      invoiceBasedFlag: false,
      invoiceCode: "12345",
      performanceBasedPayToStaffId: 1234567,
    },
    commissionShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    incentiveShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    license: {
      lifeInsuranceStaffNumber: "ab234238495",
      nonLifeInsuranceStaffNumber: "f839283645",
      variableInsuranceSalesQualificationNumber: "38374",
      foreignCurrencyInsuranceSalesQualificationNumber: "d93283",
      smallAmountShortTermQualificationNumber: "n83747",
      hasSpecializedCourse: true,
      hasUniversityCourse: false,
      hasAppliedCourse: false,
      hasAfpQualification: true,
      hasCfpQualification: true,
      fpQualification: "noQualification",
      securitiesSalespersonQualification: "firstClass",
    },
  },
  //
  {
    staffId: 36353,
    staffNumber: "36353",
    staffRoleType: "normal",
    accountActiveFlag: false,
    performanceBasedPayFlag: false,
    dummyPassword: "password123",
    personal: {
      firstName: "まりあ",
      lastName: "中山",
      firstNameKana: "まりあ",
      lastNameKana: "なかやま",
      dateOfBirth: "1980-07-21",
      sex: "female",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress1: "system@doitplanning.com",
      mailAddress2: "system@doitplanning.com",
    },
    emergencyContact: {
      firstName: "まり",
      lastName: "佐藤",
      firstNameKana: "まり",
      lastNameKana: "さとう",
      tel1: "080000227435",
    },
    departmentAffiliation: [
      {
        departmentAffiliationId: 2,
        departmentId: 2,
        affiliationAtFrom: "2022-07-21",
        affiliationAtTo: "2022-07-21",
      },
    ],
    companyAffiliation: {
      hiringDateAt: "2022-07-21",
      leavingDateAt: "2022-07-21",
    },
    performanceBasedPay: {
      employmentInsuranceFlag: false,
      longTermCareInsuranceFlag: false,
      healthInsuranceStandardMonthlyRemuneration: 360000,
      welfarePensionStandardMonthlyRemuneration: 360000,
      basicSalary: 150000,
      entryFee: 20000,
      basicSalaryDeductionFlag: false,
      socialInsurancePremiumDeductionFlag: false,
      invoiceBasedFlag: false,
      invoiceCode: "12345",
      performanceBasedPayToStaffId: 1234567,
    },
    commissionShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    incentiveShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    license: {
      lifeInsuranceStaffNumber: "ab234238495",
      nonLifeInsuranceStaffNumber: "f839283645",
      variableInsuranceSalesQualificationNumber: "38374",
      foreignCurrencyInsuranceSalesQualificationNumber: "d93283",
      smallAmountShortTermQualificationNumber: "n83747",
      hasSpecializedCourse: true,
      hasUniversityCourse: false,
      hasAppliedCourse: false,
      hasAfpQualification: true,
      hasCfpQualification: true,
      fpQualification: "noQualification",
      securitiesSalespersonQualification: "firstClass",
    },
  },
  //
  {
    staffId: 74833,
    staffNumber: "74833",
    staffRoleType: "normal",
    accountActiveFlag: false,
    performanceBasedPayFlag: false,
    dummyPassword: "password123",
    personal: {
      firstName: "まりこ",
      lastName: "中山",
      firstNameKana: "まりこ",
      lastNameKana: "なかやま",
      dateOfBirth: "1980-07-21",
      sex: "female",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress1: "system@doitplanning.com",
      mailAddress2: "system@doitplanning.com",
    },
    emergencyContact: {
      firstName: "まり",
      lastName: "佐藤",
      firstNameKana: "まり",
      lastNameKana: "さとう",
      tel1: "080000227435",
    },
    departmentAffiliation: [
      {
        departmentAffiliationId: 3,
        departmentId: 3,
        affiliationAtFrom: "2022-07-21",
        affiliationAtTo: "2022-07-21",
      },
    ],
    companyAffiliation: {
      hiringDateAt: "2022-07-21",
      leavingDateAt: "2022-07-21",
    },
    performanceBasedPay: {
      employmentInsuranceFlag: false,
      longTermCareInsuranceFlag: false,
      healthInsuranceStandardMonthlyRemuneration: 360000,
      welfarePensionStandardMonthlyRemuneration: 360000,
      basicSalary: 150000,
      entryFee: 20000,
      basicSalaryDeductionFlag: false,
      socialInsurancePremiumDeductionFlag: false,
      invoiceBasedFlag: false,
      invoiceCode: "12345",
      performanceBasedPayToStaffId: 1234567,
    },
    commissionShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    incentiveShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    license: {
      lifeInsuranceStaffNumber: "ab234238495",
      nonLifeInsuranceStaffNumber: "f839283645",
      variableInsuranceSalesQualificationNumber: "38374",
      foreignCurrencyInsuranceSalesQualificationNumber: "d93283",
      smallAmountShortTermQualificationNumber: "n83747",
      hasSpecializedCourse: true,
      hasUniversityCourse: false,
      hasAppliedCourse: false,
      hasAfpQualification: true,
      hasCfpQualification: true,
      fpQualification: "noQualification",
      securitiesSalespersonQualification: "firstClass",
    },
  },
  //
  {
    staffId: 65432,
    staffNumber: "65432",
    staffRoleType: "normal",
    accountActiveFlag: true,
    performanceBasedPayFlag: false,
    dummyPassword: "password123",
    personal: {
      firstName: "雄司",
      lastName: "山本",
      firstNameKana: "ゆうじ",
      lastNameKana: "やまもと",
      dateOfBirth: "1980-07-21",
      sex: "male",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress1: "system@doitplanning.com",
      mailAddress2: "system@doitplanning.com",
    },
    emergencyContact: {
      firstName: "まり",
      lastName: "佐藤",
      firstNameKana: "まり",
      lastNameKana: "さとう",
      tel1: "080000227435",
    },
    departmentAffiliation: [
      {
        departmentAffiliationId: 1,
        departmentId: 1,
        affiliationAtFrom: "2022-07-21",
        affiliationAtTo: "2022-07-21",
      },
    ],
    companyAffiliation: {
      hiringDateAt: "2022-07-21",
      leavingDateAt: "2022-07-21",
    },
    performanceBasedPay: {
      employmentInsuranceFlag: false,
      longTermCareInsuranceFlag: false,
      healthInsuranceStandardMonthlyRemuneration: 360000,
      welfarePensionStandardMonthlyRemuneration: 360000,
      basicSalary: 150000,
      entryFee: 20000,
      basicSalaryDeductionFlag: false,
      socialInsurancePremiumDeductionFlag: false,
      invoiceBasedFlag: false,
      invoiceCode: "12345",
      performanceBasedPayToStaffId: 1234567,
    },
    commissionShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    incentiveShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    license: {
      lifeInsuranceStaffNumber: "ab234238495",
      nonLifeInsuranceStaffNumber: "f839283645",
      variableInsuranceSalesQualificationNumber: "38374",
      foreignCurrencyInsuranceSalesQualificationNumber: "d93283",
      smallAmountShortTermQualificationNumber: "n83747",
      hasSpecializedCourse: true,
      hasUniversityCourse: false,
      hasAppliedCourse: false,
      hasAfpQualification: true,
      hasCfpQualification: true,
      fpQualification: "noQualification",
      securitiesSalespersonQualification: "firstClass",
    },
  },
  //
  {
    staffId: 76547,
    staffNumber: "76547",
    staffRoleType: "normal",
    accountActiveFlag: true,
    performanceBasedPayFlag: false,
    dummyPassword: "password123",
    personal: {
      firstName: "直輝",
      lastName: "上田",
      firstNameKana: "なおき",
      lastNameKana: "うえだ",
      dateOfBirth: "1980-07-21",
      sex: "male",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress1: "system@doitplanning.com",
      mailAddress2: "system@doitplanning.com",
    },
    emergencyContact: {
      firstName: "まり",
      lastName: "佐藤",
      firstNameKana: "まり",
      lastNameKana: "さとう",
      tel1: "080000227435",
    },
    departmentAffiliation: [
      {
        departmentAffiliationId: 2,
        departmentId: 2,
        affiliationAtFrom: "2022-07-21",
        affiliationAtTo: "2022-07-21",
      },
    ],
    companyAffiliation: {
      hiringDateAt: "2022-07-21",
      leavingDateAt: "2022-07-21",
    },
    performanceBasedPay: {
      employmentInsuranceFlag: false,
      longTermCareInsuranceFlag: false,
      healthInsuranceStandardMonthlyRemuneration: 360000,
      welfarePensionStandardMonthlyRemuneration: 360000,
      basicSalary: 150000,
      entryFee: 20000,
      basicSalaryDeductionFlag: false,
      socialInsurancePremiumDeductionFlag: false,
      invoiceBasedFlag: false,
      invoiceCode: "12345",
      performanceBasedPayToStaffId: 1234567,
    },
    commissionShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    incentiveShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    license: {
      lifeInsuranceStaffNumber: "ab234238495",
      nonLifeInsuranceStaffNumber: "f839283645",
      variableInsuranceSalesQualificationNumber: "38374",
      foreignCurrencyInsuranceSalesQualificationNumber: "d93283",
      smallAmountShortTermQualificationNumber: "n83747",
      hasSpecializedCourse: true,
      hasUniversityCourse: false,
      hasAppliedCourse: false,
      hasAfpQualification: true,
      hasCfpQualification: true,
      fpQualification: "noQualification",
      securitiesSalespersonQualification: "firstClass",
    },
  },
  //
  {
    staffId: 89077,
    staffNumber: "89077",
    staffRoleType: "normal",
    accountActiveFlag: true,
    performanceBasedPayFlag: false,
    dummyPassword: "password123",
    personal: {
      lastName: "黒田",
      firstName: "一夫",
      firstNameKana: "かずお",
      lastNameKana: "くろだ",
      dateOfBirth: "1980-07-21",
      sex: "male",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress1: "system@doitplanning.com",
      mailAddress2: "system@doitplanning.com",
    },
    emergencyContact: {
      firstName: "まり",
      lastName: "佐藤",
      firstNameKana: "まり",
      lastNameKana: "さとう",
      tel1: "080000227435",
    },
    departmentAffiliation: [
      {
        departmentAffiliationId: 3,
        departmentId: 3,
        affiliationAtFrom: "2022-07-21",
        affiliationAtTo: "2022-07-21",
      },
    ],
    companyAffiliation: {
      hiringDateAt: "2022-07-21",
      leavingDateAt: "2022-07-21",
    },
    performanceBasedPay: {
      employmentInsuranceFlag: false,
      longTermCareInsuranceFlag: false,
      healthInsuranceStandardMonthlyRemuneration: 360000,
      welfarePensionStandardMonthlyRemuneration: 360000,
      basicSalary: 150000,
      entryFee: 20000,
      basicSalaryDeductionFlag: false,
      socialInsurancePremiumDeductionFlag: false,
      invoiceBasedFlag: false,
      invoiceCode: "12345",
      performanceBasedPayToStaffId: 1234567,
    },
    commissionShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    incentiveShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    license: {
      lifeInsuranceStaffNumber: "ab234238495",
      nonLifeInsuranceStaffNumber: "f839283645",
      variableInsuranceSalesQualificationNumber: "38374",
      foreignCurrencyInsuranceSalesQualificationNumber: "d93283",
      smallAmountShortTermQualificationNumber: "n83747",
      hasSpecializedCourse: true,
      hasUniversityCourse: false,
      hasAppliedCourse: false,
      hasAfpQualification: true,
      hasCfpQualification: true,
      fpQualification: "noQualification",
      securitiesSalespersonQualification: "firstClass",
    },
  },
  //
  {
    staffId: 90374,
    staffNumber: "90374",
    staffRoleType: "normal",
    accountActiveFlag: true,
    performanceBasedPayFlag: false,
    dummyPassword: "password123",
    personal: {
      lastName: "山中",
      firstName: "航",
      firstNameKana: "わたる",
      lastNameKana: "やまなか",
      dateOfBirth: "1980-07-21",
      sex: "male",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress1: "system@doitplanning.com",
      mailAddress2: "system@doitplanning.com",
    },
    emergencyContact: {
      firstName: "まり",
      lastName: "佐藤",
      firstNameKana: "まり",
      lastNameKana: "さとう",
      tel1: "080000227435",
    },
    departmentAffiliation: [
      {
        departmentAffiliationId: 2,
        departmentId: 2,
        affiliationAtFrom: "2022-07-21",
        affiliationAtTo: "2022-07-21",
      },
    ],
    companyAffiliation: {
      hiringDateAt: "2022-07-21",
      leavingDateAt: "2022-07-21",
    },
    performanceBasedPay: {
      employmentInsuranceFlag: false,
      longTermCareInsuranceFlag: false,
      healthInsuranceStandardMonthlyRemuneration: 360000,
      welfarePensionStandardMonthlyRemuneration: 360000,
      basicSalary: 150000,
      entryFee: 20000,
      basicSalaryDeductionFlag: false,
      socialInsurancePremiumDeductionFlag: false,
      invoiceBasedFlag: false,
      invoiceCode: "12345",
      performanceBasedPayToStaffId: 1234567,
    },
    commissionShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    incentiveShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    license: {
      lifeInsuranceStaffNumber: "ab234238495",
      nonLifeInsuranceStaffNumber: "f839283645",
      variableInsuranceSalesQualificationNumber: "38374",
      foreignCurrencyInsuranceSalesQualificationNumber: "d93283",
      smallAmountShortTermQualificationNumber: "n83747",
      hasSpecializedCourse: true,
      hasUniversityCourse: false,
      hasAppliedCourse: false,
      hasAfpQualification: true,
      hasCfpQualification: true,
      fpQualification: "noQualification",
      securitiesSalespersonQualification: "firstClass",
    },
  },
  //
  {
    staffId: 30448,
    staffNumber: "30448",
    staffRoleType: "normal",
    accountActiveFlag: true,
    performanceBasedPayFlag: false,
    dummyPassword: "password123",
    personal: {
      lastName: "Smith",
      firstName: "John",
      firstNameKana: "ジョン",
      lastNameKana: "スミス",
      dateOfBirth: "1980-07-21",
      sex: "male",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress1: "system@doitplanning.com",
      mailAddress2: "system@doitplanning.com",
    },
    emergencyContact: {
      firstName: "まり",
      lastName: "佐藤",
      firstNameKana: "まり",
      lastNameKana: "さとう",
      tel1: "080000227435",
    },
    departmentAffiliation: [
      {
        departmentAffiliationId: 3,
        departmentId: 3,
        affiliationAtFrom: "2022-07-21",
        affiliationAtTo: "2022-07-21",
      },
    ],
    companyAffiliation: {
      hiringDateAt: "2022-07-21",
      leavingDateAt: "2022-07-21",
    },
    performanceBasedPay: {
      employmentInsuranceFlag: false,
      longTermCareInsuranceFlag: false,
      healthInsuranceStandardMonthlyRemuneration: 360000,
      welfarePensionStandardMonthlyRemuneration: 360000,
      basicSalary: 150000,
      entryFee: 20000,
      basicSalaryDeductionFlag: false,
      socialInsurancePremiumDeductionFlag: false,
      invoiceBasedFlag: false,
      invoiceCode: "12345",
      performanceBasedPayToStaffId: 1234567,
    },
    commissionShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    incentiveShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    license: {
      lifeInsuranceStaffNumber: "ab234238495",
      nonLifeInsuranceStaffNumber: "f839283645",
      variableInsuranceSalesQualificationNumber: "38374",
      foreignCurrencyInsuranceSalesQualificationNumber: "d93283",
      smallAmountShortTermQualificationNumber: "n83747",
      hasSpecializedCourse: true,
      hasUniversityCourse: false,
      hasAppliedCourse: false,
      hasAfpQualification: true,
      hasCfpQualification: true,
      fpQualification: "noQualification",
      securitiesSalespersonQualification: "firstClass",
    },
  },
  //
  {
    staffId: 90909,
    staffNumber: "90909",
    staffRoleType: "normal",
    accountActiveFlag: true,
    performanceBasedPayFlag: false,
    dummyPassword: "password123",
    personal: {
      lastName: "Smith",
      firstName: "Jane",
      firstNameKana: "ジェイン",
      lastNameKana: "スミス",
      dateOfBirth: "1980-07-21",
      sex: "female",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress1: "system@doitplanning.com",
      mailAddress2: "system@doitplanning.com",
    },
    emergencyContact: {
      firstName: "まり",
      lastName: "佐藤",
      firstNameKana: "まり",
      lastNameKana: "さとう",
      tel1: "080000227435",
    },
    departmentAffiliation: [
      {
        departmentAffiliationId: 2,
        departmentId: 2,
        affiliationAtFrom: "2022-07-21",
        affiliationAtTo: "2022-07-21",
      },
    ],
    companyAffiliation: {
      hiringDateAt: "2022-07-21",
      leavingDateAt: "2022-07-21",
    },
    performanceBasedPay: {
      employmentInsuranceFlag: false,
      longTermCareInsuranceFlag: false,
      healthInsuranceStandardMonthlyRemuneration: 360000,
      welfarePensionStandardMonthlyRemuneration: 360000,
      basicSalary: 150000,
      entryFee: 20000,
      basicSalaryDeductionFlag: false,
      socialInsurancePremiumDeductionFlag: false,
      invoiceBasedFlag: false,
      invoiceCode: "12345",
      performanceBasedPayToStaffId: 1234567,
    },
    commissionShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    incentiveShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    license: {
      lifeInsuranceStaffNumber: "ab234238495",
      nonLifeInsuranceStaffNumber: "f839283645",
      variableInsuranceSalesQualificationNumber: "38374",
      foreignCurrencyInsuranceSalesQualificationNumber: "d93283",
      smallAmountShortTermQualificationNumber: "n83747",
      hasSpecializedCourse: true,
      hasUniversityCourse: false,
      hasAppliedCourse: false,
      hasAfpQualification: true,
      hasCfpQualification: true,
      fpQualification: "noQualification",
      securitiesSalespersonQualification: "firstClass",
    },
  },
  //
  {
    staffId: 48377,
    staffNumber: "48377",
    staffRoleType: "normal",
    accountActiveFlag: true,
    performanceBasedPayFlag: false,
    dummyPassword: "password123",
    personal: {
      lastName: "田村",
      firstName: "さら",
      firstNameKana: "さら",
      lastNameKana: "たむら",
      dateOfBirth: "1980-07-21",
      sex: "female",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress1: "system@doitplanning.com",
      mailAddress2: "system@doitplanning.com",
    },
    emergencyContact: {
      firstName: "まり",
      lastName: "佐藤",
      firstNameKana: "まり",
      lastNameKana: "さとう",
      tel1: "080000227435",
    },
    departmentAffiliation: [
      {
        departmentAffiliationId: 2,
        departmentId: 2,
        affiliationAtFrom: "2022-07-21",
        affiliationAtTo: "2022-07-21",
      },
    ],
    companyAffiliation: {
      hiringDateAt: "2022-07-21",
      leavingDateAt: "2022-07-21",
    },
    performanceBasedPay: {
      employmentInsuranceFlag: false,
      longTermCareInsuranceFlag: false,
      healthInsuranceStandardMonthlyRemuneration: 360000,
      welfarePensionStandardMonthlyRemuneration: 360000,
      basicSalary: 150000,
      entryFee: 20000,
      basicSalaryDeductionFlag: false,
      socialInsurancePremiumDeductionFlag: false,
      invoiceBasedFlag: false,
      invoiceCode: "12345",
      performanceBasedPayToStaffId: 1234567,
    },
    commissionShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    incentiveShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    license: {
      lifeInsuranceStaffNumber: "ab234238495",
      nonLifeInsuranceStaffNumber: "f839283645",
      variableInsuranceSalesQualificationNumber: "38374",
      foreignCurrencyInsuranceSalesQualificationNumber: "d93283",
      smallAmountShortTermQualificationNumber: "n83747",
      hasSpecializedCourse: true,
      hasUniversityCourse: false,
      hasAppliedCourse: false,
      hasAfpQualification: true,
      hasCfpQualification: true,
      fpQualification: "noQualification",
      securitiesSalespersonQualification: "firstClass",
    },
  },
  //
  {
    staffId: 1234567,
    staffNumber: "7809A",
    staffRoleType: "admin",
    accountActiveFlag: true,
    performanceBasedPayFlag: false,
    dummyPassword: "password123",
    personal: {
      lastName: "山中",
      firstName: "ルカ",
      firstNameKana: "るか",
      lastNameKana: "やまなか",
      dateOfBirth: "1980-07-21",
      sex: "female",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress1: "system@doitplanning.com",
      mailAddress2: "system@doitplanning.com",
    },
    emergencyContact: {
      firstName: "まり",
      lastName: "佐藤",
      firstNameKana: "まり",
      lastNameKana: "さとう",
      tel1: "080000227435",
    },
    departmentAffiliation: [
      {
        departmentAffiliationId: 3,
        departmentId: 3,
        affiliationAtFrom: "2022-07-21",
        affiliationAtTo: "2022-07-21",
      },
    ],
    companyAffiliation: {
      hiringDateAt: "2022-07-21",
      leavingDateAt: "2022-07-21",
    },
    performanceBasedPay: {
      employmentInsuranceFlag: false,
      longTermCareInsuranceFlag: false,
      healthInsuranceStandardMonthlyRemuneration: 360000,
      welfarePensionStandardMonthlyRemuneration: 360000,
      basicSalary: 150000,
      entryFee: 20000,
      basicSalaryDeductionFlag: false,
      socialInsurancePremiumDeductionFlag: false,
      invoiceBasedFlag: false,
      invoiceCode: "12345",
      performanceBasedPayToStaffId: 1234567,
    },
    commissionShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    incentiveShareDefault: {
      companyShare: 15,
      myselfShare: 65,
      bossShare: 5,
      otherStaffId1: 1234567,
      otherStaffShare1: 5,
      otherStaffId2: 1234567,
      otherStaffShare2: 5,
      otherStaffId3: 1234567,
      otherStaffShare3: 5,
    },
    license: {
      lifeInsuranceStaffNumber: "ab234238495",
      nonLifeInsuranceStaffNumber: "f839283645",
      variableInsuranceSalesQualificationNumber: "38374",
      foreignCurrencyInsuranceSalesQualificationNumber: "d93283",
      smallAmountShortTermQualificationNumber: "n83747",
      hasSpecializedCourse: true,
      hasUniversityCourse: false,
      hasAppliedCourse: false,
      hasAfpQualification: true,
      hasCfpQualification: true,
      fpQualification: "noQualification",
      securitiesSalespersonQualification: "firstClass",
    },
  },
] as GetStaff200Response[];
export let baseDummyData: GetStaff200Response[] = defaultBaseDummyData;
export const initializeBaseDummyData = () => {
  baseDummyData = defaultBaseDummyData;
};
/**
 * GetStaffs.
 * return GetStaffs200Response[]
 */

export const dummyGetStaffs200Response = () => {
  return baseDummyData.map((staff) => {
    return {
      staffId: staff.staffId,
      staffNumber: staff.staffNumber,
      lastName: staff.personal.lastName,
      firstName: staff.personal.firstName,
      accountActiveFlag: staff.accountActiveFlag,
    };
  }) as GetStaffs200ResponseResultsInner[];
};
