import { defineStore } from "pinia";
import { computed, ref } from "vue";

/**
 * state for Contractor (契約者) of Contract Reports(申達管理).
 * this state manage both of individual contract(契約者(個人)) and corporate contract(契約者(法人)).
 */
export interface BaseContractor {
  postCode: string;
  addressInformation: {
    postCode: string | undefined; // @
    address1: string | undefined; // @
    address2: string | undefined; // @
    address3: string | undefined; // @
    address4?: string | undefined;
    address5?: string | undefined;
  };
  tel1: string;
  tel2: string;
}

//  create type for individual contract
export type ContractorType = {
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  dateOfBirth: string;
  dateOfBirthWareki: string;
} & BaseContractor;

// create type for corporate contract.
export type ContractorCorporationType = {
  corporationName: string;
  corporationRepresentativeLastName: string;
  corporationRepresentativeFirstName: string;
  corporationClosingMonth: string;
} & BaseContractor;

export type Contractor =
  | ContractorType
  | ContractorCorporationType
  | Record<string, unknown>;

export const useContractReportContractorStore = defineStore(
  "contractor",
  () => {
    // initialize state
    const currentItem = ref<Contractor>({
      // initialize all properties with default values
      firstName: "",
      lastName: "",
      firstNameKana: "",
      lastNameKana: "",
      dateOfBirth: "",
      dateOfBirthWareki: "",
      postCode: "",
      addressInformation: {
        postCode: "",
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        address5: "",
      },
      tel1: "",
      tel2: "",
      corporationName: "",
      corporationRepresentativeLastName: "",
      corporationRepresentativeFirstName: "",
      corporationClosingMonth: "",

      // postCode: "",
      // addressInformation: {
      //   postCode: "",
      //   address1: "",
      //   address2: "",
      //   address3: "",
      // },
      // tel1: "",
      // tel2: "",
    });

    const clearContractReports = () => {
      currentItem.value = {};
    };

    // declare getter/setters
    const contractor = computed((): Contractor => {
      return currentItem.value;
    });
    const setContractor = (contractor: Contractor) => {
      currentItem.value = contractor;
    };

    return {
      currentItem,
      clearContractReports,
      contractor,
      setContractor,
    };
  }
);
