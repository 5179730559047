<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { useAutocompleteLogic } from "@/composables/use-autocomplete-staff-api";
import type { DebouncedFunc } from "lodash-es";

const { loading } = useAutocompleteLogic();
const isComposing = ref(false);

// isEditable and getStaffData() passed from the parent component as a prop
const props = defineProps<{
  isEditable: boolean;
  getStaffData: DebouncedFunc<(query: string) => Promise<void>>;
  currentEmployee: any;
  modelValue: string;
}>();

const computedInputValue = computed({
  get: () => props.modelValue,
  set: (value: string) => {
    emits("update:modelValue", value);
  },
});
const emits = defineEmits<{
  (e: "clear-current-employee"): void;
  (e: "update:modelValue", value: string): void;
}>();

watchEffect(() => {
  if (!isComposing.value) {
    // A special case
    if (props.currentEmployee === "clear") {
      computedInputValue.value = "";
      return;
    }
    // }

    if (props.currentEmployee) {
      computedInputValue.value = `${props.currentEmployee.staffNumber}: ${props.currentEmployee.staffFullName}`;
    }
  }
});

function handleCompositionStart() {
  isComposing.value = true;
}

async function handleCompositionEnd() {
  isComposing.value = false;
  handleInput();
}

async function handleInput() {
  if (!isComposing.value) {
    emits("clear-current-employee"); // 1. Clear current employee
    loading.value = true;
    await props.getStaffData(computedInputValue.value); // 2. Fetch new employee

    loading.value = false;
  }
}
</script>

<template>
  <div class="input-area">
    <input
      type="text"
      v-model="computedInputValue"
      @compositionstart="handleCompositionStart"
      @compositionend="handleCompositionEnd"
      @input="handleInput"
      :disabled="!isEditable"
      class="search-input"
      data-testid="searchAutocomplete"
    />
    <span v-if="loading">Loading...</span>
  </div>
</template>

<style scoped lang="scss">
.input-area {
  border: 1px solid grey;
  border: var(--fk-border);
  border-radius: var(--fk-border-radius);
}
.search-input {
  margin-bottom: 0px;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  font-size: 1.25rem;

  &:disabled {
    background-color: #f8f8f8;
    color: #757575;
    border-color: #e0e0e2;
    cursor: not-allowed; // カーソルを「禁止」アイコンに変更
  }
}
</style>
