import { SecuritiesApiFactory } from "@/api";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import axiosInstance from "@/utils/axios-customized-instance";
import { safeAsyncCall, safeAsyncCallAndRaiseError } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const { getSecurities, postSecurities, putSecurities, deleteSecurities } =
  SecuritiesApiFactory(...args, undefined, axiosInstance);

export function useSecuritiesService() {
  return {
    /**
     * Get security by the given parameters.
     * @param args
     */
    get: safeAsyncCall(async (...args: Parameters<typeof getSecurities>) => {
      const res = await getSecurities(...args);
      return res; // { data, status }
    }),
    /**
     * Add new security by the given parameters.
     * @param args
     */
    add: safeAsyncCallAndRaiseError(
      async (...args: Parameters<typeof postSecurities>) => {
        const res = await postSecurities(...args);
        const { data: id } = res;
        return id;
      }
    ),
    /**
     * Update existing security.
     * @param args
     */
    update: safeAsyncCallAndRaiseError(
      async (...args: Parameters<typeof putSecurities>) => {
        const res = await putSecurities(...args);
        return res;
      }
    ),
    /**
     * Delete existing security.
     * @param args
     */
    delete: safeAsyncCall(
      async (...args: Parameters<typeof deleteSecurities>) => {
        const res = await deleteSecurities(...args);
        return res;
      }
    ),
  };
}
