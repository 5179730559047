import type { GetInsuranceTypes200ResponseResultsInner } from "@/api";
import _ from "lodash-es";

export let dummyInsuranceCompanyTypes: GetInsuranceTypes200ResponseResultsInner[] =
  [
    {
      insuranceTypeId: 1234567,
      insuranceTypeName: "個人ガン保険",
      insuranceCategory: "nonLifeInsurance",
      sortNumber: 1,
    },
    {
      insuranceTypeId: 1234568,
      insuranceTypeName: "A保険",
      insuranceCategory: "nonLifeInsurance",
      sortNumber: 2,
    },
    {
      insuranceTypeId: 1234569,
      insuranceTypeName: "B保険",
      insuranceCategory: "lifeInsurance",
      sortNumber: 3,
    },
    {
      insuranceTypeId: 1234560,
      insuranceTypeName: "C保険",
      insuranceCategory: "lifeInsurance",
      sortNumber: 10,
    },
  ];

// Replace function to use with mock Put requests.
export function replace(oldRecord: any, updatedRecord: any) {
  dummyInsuranceCompanyTypes = _.concat(
    _.filter(dummyInsuranceCompanyTypes, (record) => record !== oldRecord),
    updatedRecord
  );
  sortBySortNumber();
}

// function to sort the data by 'sortNumber' in ascending order
function sortBySortNumber(): void {
  dummyInsuranceCompanyTypes.sort((a, b) => a.sortNumber - b.sortNumber);
}

// add new data, while also sorting by sortNumber
export function addNewData(newData: any): void {
  dummyInsuranceCompanyTypes.push(newData);
  sortBySortNumber();
}
