import type { PotentialCustomerIndividualScreenItem } from "@/types/PotentialCustomer/potential-customer-individual";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useFamilyInfoStore = defineStore("family-info", () => {
  const currentItem = ref<PotentialCustomerIndividualScreenItem["family"]>([]);

  return {
    currentItem,
  };
});
