import type { PotentialCustomerIndividualScreenItem } from "@/types/PotentialCustomer/potential-customer-individual";
import { defineStore } from "pinia";
import { ref } from "vue";

export const usePotentialCustomerIndividualStore = defineStore(
  "potential-customer-individual-info",
  () => {
    // initialize state
    const currentItem = ref<
      PotentialCustomerIndividualScreenItem["potentialCustomerIndividual"]
    >({
      // initialize all properties of PotentialCustomerIndividual
      // regardless of whether they are required or optional
      lastName: "",
      firstName: "",
      lastNameKana: "",
      firstNameKana: "",
      dateOfBirth: "",
      dateOfBirthWareki: "",
      addressInformation: {
        postCode: "",
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        address5: "",
      },
      tel1: "",
      tel2: "",
      mailAddress: "",
    });

    return {
      currentItem,
    };
  }
);
