import { rest } from "msw";
import type { Market } from "@/api/";

import { dummyData } from "@/mocks/Market/dummy-markets";

import { useEnvironmentVars } from "@/composables/use-environment-vars";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

/**
 * handler for GetMaeketQuery API mock server.
 */
export const handlers = [
  rest.get(`${BASE_PATH}/market`, (req, res, ctx) => {
    const keyword = req.url.searchParams.get("keyword");

    let filteredMarkets = [] as Market[];
    filteredMarkets = dummyData;

    if (keyword) {
      filteredMarkets.filter((market) => {
        // TODO: マーケット名の部分一致
        return market.marketName == keyword;
      });
    }

    return res(
      ctx.status(200),
      ctx.json({
        results: filteredMarkets,
        pagination: {
          totalCount: 0,
        },
      })
    );
  }),
];
