// Define types for Mode and Screen
type Mode = {
  [key: string]: string | null;
};

export type Screen = {
  id: number;
  name: string;
  labelName: string;
  type: string;
  mode: Mode;
  path: string;
  subSystem: string;
  accessRoles: string[];
  description: string;
};

export const screens: Screen[] = [
  {
    id: 1,
    name: "CreateAdjustmentDeduction",
    labelName: "調整控除額管理",
    type: "create",
    mode: {
      new: "新規作成",
    },
    path: "/adjustments-deductions/new",
    subSystem: "AdjustmentDeduction",
    accessRoles: ["admin"],
    description: "調整控除額を作成する画面。",
  },
  {
    id: 2,
    name: "CreateContractReport",
    labelName: "申達管理",
    type: "create",
    mode: {
      new: "新規作成",
    },
    path: "/contract-report/:contractorType/:insuranceType/new",
    subSystem: "ContractReport",
    accessRoles: ["admin", "normal"],
    description: "新しい申達報告を作成する画面。",
  },
  {
    id: 3,
    name: "CreateDepartment",
    labelName: "所属管理",
    type: "create",
    mode: {
      new: "新規作成",
    },
    path: "/department-management/new",
    subSystem: "Department",
    accessRoles: ["admin"],
    description: "新しい所属を作成する画面。",
  },
  {
    id: 4,
    name: "CreatePotentialCustomerCorporation",
    labelName: "案件管理（法人）",
    type: "create",
    mode: {
      new: "新規作成",
    },
    path: "/potential-customer/corporation/new",
    subSystem: "PotentialCustomer",
    accessRoles: ["admin", "normal"],
    description: "新しい案件（法人）を作成する画面。",
  },
  {
    id: 5,
    name: "CreatePotentialCustomerIndividual",
    labelName: "案件管理（個人）",
    type: "create",
    mode: {
      new: "新規作成",
    },
    path: "/potential-customer/individual/new",
    subSystem: "PotentialCustomer",
    accessRoles: ["admin", "normal"],
    description: "新しい案件（個人）を作成する画面。",
  },
  {
    id: 6,
    name: "CreateUserManagement",
    labelName: "ユーザー管理",
    type: "create",
    mode: {
      new: "新規作成",
    },
    path: "/user-management/new",
    subSystem: "UserManagement",
    accessRoles: ["admin"],
    description: "新しいユーザーを作成する画面。",
  },
  {
    id: 7,
    name: "EditAdjustmentDeduction",
    labelName: "調整控除額管理",
    type: "edit",
    mode: {
      editable: "編集",
    },
    path: "/adjustments-deductions/:normalId/:adjustmentDeductionId",
    subSystem: "AdjustmentDeduction",
    accessRoles: ["admin"],
    description: "調整控除額を編集する画面。",
  },
  {
    id: 8,
    name: "EditContractReport",
    labelName: "申達管理",
    type: "edit",
    mode: {
      editable: "編集",
      readOnly: "参照",
    },
    path: "/contract-reports/:contractorType/:insuranceType/:id",
    subSystem: "ContractReport",
    accessRoles: ["admin", "normal"],
    description: "既存の申達報告を編集する画面。",
  },
  {
    id: 9,
    name: "EditDepartment",
    labelName: "所属管理",
    type: "edit",
    mode: {
      editable: "編集",
      readOnly: "参照",
    },
    path: "/department-management/:departmentId",
    subSystem: "Department",
    accessRoles: ["admin"],
    description: "既存の所属を編集する画面。",
  },
  {
    id: 10,
    name: "EditPotentialCustomerCorporation",
    labelName: "案件管理（法人）",
    type: "edit",
    mode: {
      editable: "編集",
      readOnly: "参照",
    },
    path: "/potential-customer/corporation/:id",
    subSystem: "PotentialCustomer",
    accessRoles: ["admin"],
    description: "既存の案件（法人）を編集する画面。",
  },
  {
    id: 11,
    name: "EditPotentialCustomerIndividual",
    labelName: "案件管理（個人）",
    type: "edit",
    mode: {
      editable: "編集",
      readOnly: "参照",
    },
    path: "/potential-customer/individual/:id",
    subSystem: "PotentialCustomer",
    accessRoles: ["admin"],
    description: "既存の案件（個人）を編集する画面。",
  },
  {
    id: 12,
    name: "EditUserManagement",
    labelName: "ユーザー管理",
    type: "edit",
    mode: {
      editable: "編集",
      readOnly: "参照",
    },
    path: "/user-management/:normalId",
    subSystem: "UserManagement",
    accessRoles: ["admin"],
    description: "既存のユーザーを編集する画面。",
  },
  {
    id: 13,
    name: "SearchAdjustmentsDeductions",
    labelName: "調整控除額管理",
    type: "search",
    mode: {
      search: "検索",
    },
    path: "/adjustments-deductions/search",
    subSystem: "AdjustmentDeduction",
    accessRoles: ["admin"],
    description: "調整控除額を検索する画面。",
  },
  {
    id: 14,
    name: "SearchContractReports",
    labelName: "申達管理",
    type: "search",
    mode: {
      search: "検索",
    },
    path: "/contract-reports/:insuranceCategory/search",
    subSystem: "ContractReport",
    accessRoles: ["admin", "normal"],
    description: "申達報告を検索する画面。",
  },
  {
    id: 15,
    name: "SearchDepartments",
    labelName: "所属管理",
    type: "search",
    mode: {
      search: "検索",
    },
    path: "/department-management/search",
    subSystem: "Department",
    accessRoles: ["admin"],
    description: "所属を検索する画面。",
  },
  {
    id: 16,
    name: "SearchPotentialCustomer",
    labelName: "案件管理",
    type: "search",
    mode: {
      search: "検索",
    },
    path: "/potential-customer/search",
    subSystem: "PotentialCustomer",
    accessRoles: ["admin"],
    description: "案件を検索する画面。",
  },
  {
    id: 18,
    name: "SearchSecurities",
    labelName: "証券管理",
    type: "search",
    mode: {
      search: "検索",
    },
    path: "/securities-management/search/:insuranceCategory",
    subSystem: "Security",
    accessRoles: ["admin", "normal"],
    description: "証券を検索する画面。",
  },
  {
    id: 19,
    name: "SearchUsers",
    labelName: "ユーザー管理",
    type: "search",
    mode: {
      search: "検索",
    },
    path: "/user-management/search",
    subSystem: "UserManagement",
    accessRoles: ["admin"],
    description: "ユーザーを検索する画面。",
  },
  {
    id: 20,
    name: "CreateSecurities",
    labelName: "証券管理",
    type: "create",
    mode: {
      new: "新規作成",
    },
    path: "/securities-management/search/:insuranceCategory",
    subSystem: "Security",
    accessRoles: ["admin", "normal"],
    description: "証券を検索する画面。",
  },
  {
    id: 21,
    name: "SearchSecurities",
    labelName: "証券管理",
    type: "edit",
    mode: {
      editable: "編集",
      readOnly: "参照",
    },
    path: "/securities-management/search/:insuranceCategory",
    subSystem: "Security",
    accessRoles: ["admin", "normal"],
    description: "証券を編集する画面。",
  },
  {
    id: 22,
    name: "SearchAdjustmentDeductionTypes",
    labelName: "調整控除項目マスタ",
    type: "search",
    mode: {
      search: "検索",
    },
    path: "/adjustment-deduction-types/search",
    subSystem: "AdjustmentDeductionTypes",
    accessRoles: ["admin"],
    description: "調整控除項目を検索する画面。",
  },
  {
    id: 23,
    name: "CreateAdjustmentDeductionTypes",
    labelName: "調整控除項目マスタ",
    type: "new",
    mode: {
      new: "新規作成",
    },
    path: "/adjustment-deduction-types/new",
    subSystem: "AdjustmentDeductionTypes",
    accessRoles: ["admin"],
    description: "調整控除項目を作成する画面。",
  },
  {
    id: 24,
    name: "EditAdjustmentDeductionTypes",
    labelName: "調整控除項目マスタ",
    type: "edit",
    mode: {
      edit: "編集",
      readOnly: "参照",
    },
    path: "/adjustment-deduction-types/:adjustmentDeductionTypeId",
    subSystem: "AdjustmentDeductionTypes",
    accessRoles: ["admin"],
    description: "調整控除項目を編集する画面。",
  },
  {
    id: 25,
    name: "SearchInsuranceCompany",
    labelName: "保険会社マスタ",
    type: "search",
    mode: {
      search: "検索",
    },
    path: "/insurance-company-management/search",
    subSystem: "InsuranceCompanyManagement",
    accessRoles: ["admin"],
    description: "保険会社の情報を検索する画面。",
  },
  {
    id: 26,
    name: "CreateInsuranceCompany",
    labelName: "保険会社マスタ",
    type: "new",
    mode: {
      new: "新規作成",
    },
    path: "/insurance-company-management/new",
    subSystem: "InsuranceCompanyManagement",
    accessRoles: ["admin"],
    description: "保険会社を登録する画面。",
  },
  {
    id: 27,
    name: "EditInsuranceCompany",
    labelName: "保険会社マスタ",
    type: "edit",
    mode: {
      edit: "編集",
      readOnly: "参照",
    },
    path: "/insurance-company-management/:insuranceCompanyId",
    subSystem: "InsuranceCompanyManagement",
    accessRoles: ["admin"],
    description: "保険会社を編集する画面。",
  },
  {
    id: 28,
    name: "SearchMarkets",
    labelName: "マーケットマスタ",
    type: "search",
    mode: {
      search: "検索",
    },
    path: "/market-management/search",
    subSystem: "MarketManagement",
    accessRoles: ["admin"],
    description: "マーケットの情報を検索する画面。",
  },
  {
    id: 29,
    name: "CreateMarkets",
    labelName: "マーケットマスタ",
    type: "new",
    mode: {
      new: "新規作成",
    },
    path: "/market-management/new",
    subSystem: "MarketManagement",
    accessRoles: ["admin"],
    description: "マーケットの情報を登録する画面。",
  },
  {
    id: 30,
    name: "EditMarkets",
    labelName: "マーケットマスタ",
    type: "edit",
    mode: {
      edit: "編集",
      readOnly: "参照",
    },
    path: "/market-management/:marketId",
    subSystem: "MarketManagement",
    accessRoles: ["admin"],
    description: "マーケットの情報を編集する画面。",
  },
  {
    id: 31,
    name: "SearchInsuranceTypes",
    labelName: "保険種類マスタ",
    type: "search",
    mode: {
      search: "検索",
    },
    path: "/insurance-type-management/search",
    subSystem: "InsuranceTypeManagement",
    accessRoles: ["admin"],
    description: "保険種類の情報を検索する画面。",
  },
  {
    id: 32,
    name: "CreateInsuranceType",
    labelName: "保険種類マスタ",
    type: "new",
    mode: {
      new: "新規作成",
    },
    path: "/insurance-type-management/new",
    subSystem: "InsuranceTypeManagement",
    accessRoles: ["admin"],
    description: "保険種類の情報を登録する画面。",
  },
  {
    id: 33,
    name: "EditInsuranceType",
    labelName: "保険種類マスタ",
    type: "edit",
    mode: {
      edit: "編集",
    },
    path: "/insurance-type-management/:insuranceTypeId",
    subSystem: "InsuranceTypeManagement",
    accessRoles: ["admin"],
    description: "保険種類の情報を編集する画面。",
  },
  {
    id: 34,
    name: "CheckCommissionIngestionStatus",
    labelName: "手数料業績給計算",
    type: "edit",
    mode: {
      other: null,
    },
    path: "/commission-ingestion-status",
    subSystem: "CommissionAndPerformanceBasedPayCalculation",
    accessRoles: ["admin"],
    description: "手数料や業績給を計算する画面。",
  },
  {
    id: 35,
    name: "CommissionMatching",
    labelName: "手数料マッチング",
    type: "other",
    mode: {
      other: null,
    },
    path: "/commission-matching",
    subSystem: "CommissionAndPerformanceBasedPayCalculation",
    accessRoles: ["admin"],
    description: "手数料マッチングする画面。",
  },
  {
    id: 36,
    name: "PerformanceBasedPayCalculationResultsSummary",
    labelName: "業績給計算結果一覧",
    type: "other",
    mode: {
      other: null,
    },
    path: "/performance-based-pay-calculation-summary",
    subSystem: "CommissionAndPerformanceBasedPayCalculationManagement",
    accessRoles: ["admin"],
    description: "業績給計算、手数料計算の結果を確認する画面。",
  },
  {
    id: 37,
    name: "PerformanceBasedPaySlip",
    labelName: "支払通知書",
    type: "other",
    mode: {
      other: null,
    },
    path: "/performance-based-pay-slip",
    subSystem: "CommissionAndPerformanceBasedPayCalculationManagement",
    accessRoles: ["admin"],
    description: "業績給計算、手数料計算の結果を確認する画面。",
  },
  {
    id: 38,
    name: "CommissionInsuranceCompanyCalculationResults",
    labelName: "保険会社別当月支払明細書",
    type: "other",
    mode: {
      other: null,
    },
    path: "/commission-insurance-company-calculation",
    subSystem: "CommissionAndPerformanceBasedPayCalculationManagement",
    accessRoles: ["admin"],
    description: "保険会社別当月支払明細書を確認する画面。",
  },
  {
    id: 39,
    name: "SaveCommissionData",
    labelName: "手数料マッピングと設定保存",
    type: "other",
    mode: {
      other: null,
    },
    path: "/commission-and-performance-based-pay-calculation/save-commission-data/:month/:insuranceCompanyId/:commissionCategory/",
    subSystem: "CommissionAndPerformanceBasedPayCalculation",
    accessRoles: ["admin"],
    description: "手数料データ保存する画面。",
  },
  {
    id: 40,
    name: "MonthlyPaymentStatementForContract",
    labelName: "契約別当月支払明細書",
    type: "other",
    mode: {
      other: null,
    },
    path: "/monthly-payment-contract-statement/",
    subSystem: "CommissionAndPerformanceBasedPayCalculation",
    accessRoles: ["admin"],
    description: "契約別当月支払明細書を確認する画面。",
  },
  {
    id: 41,
    name: "SearchPurchasePotentialCustomerManagement",
    labelName: "購入案件支払管理",
    type: "search",
    mode: {
      search: "検索",
    },
    path: "/purchase-potential-customer-management/search",
    subSystem: "PurchasePotentialCustomerManagement",
    accessRoles: ["admin"],
    description: "購入案件支払の情報を検索する画面。",
  },
  {
    id: 42,
    name: "CreatePurchasePotentialCustomerManagement",
    labelName: "購入案件支払管理",
    type: "new",
    mode: {
      new: "新規作成",
    },
    path: "/purchase-potential-customer-management/new",
    subSystem: "PurchasePotentialCustomerManagement",
    accessRoles: ["admin"],
    description: "購入案件支払の新規作成する画面。",
  },
  {
    id: 43,
    name: "EditPurchasePotentialCustomerManagement",
    labelName: "購入案件支払管理",
    type: "edit",
    mode: {
      edit: "編集",
    },
    path: "/purchase-potential-customer-management/:purchasePotentialCustomerId",
    subSystem: "PurchasePotentialCustomerManagement",
    accessRoles: ["admin"],
    description: "購入案件支払の編集する画面。",
  },
  {
    id: 44,
    name: "SearchStaffCodeMapping",
    labelName: "募集人コードマッピング管理",
    type: "search",
    mode: {
      search: "検索",
    },
    path: "/staff-code-mapping-management/search",
    subSystem: "StaffCodeMappingManagement",
    accessRoles: ["admin"],
    description: "募集人コードマッピングの情報を検索する画面。",
  },
  {
    id: 45,
    name: "CreateStaffCodeMapping",
    labelName: "募集人コードマッピング管理",
    type: "new",
    mode: {
      new: "新規作成",
    },
    path: "/staff-code-mapping-management/new",
    subSystem: "StaffCodeMapping",
    accessRoles: ["admin"],
    description: "募集人コードマッピングの新規作成する画面。",
  },
  {
    id: 46,
    name: "EditStaffCodeMapping",
    labelName: "募集人コードマッピング管理",
    type: "edit",
    mode: {
      edit: "編集",
    },
    path: "/staff-code-mapping-management/:staffId",
    subSystem: "StaffCodeMappingManagement",
    accessRoles: ["admin"],
    description: "募集人コードマッピングの編集する画面。",
  },
  {
    id: 47,
    name: "SearchOperationLogs",
    labelName: "操作履歴",
    type: "search",
    mode: {
      search: "検索",
    },
    path: "/operation-logs/search",
    subSystem: "OperationLogs",
    accessRoles: ["admin"],
    description: "操作履歴を検索を検索する画面。",
  },
];
