import { InsuranceCompanyApiFactory } from "@/api";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import axiosInstance from "@/utils/axios-customized-instance";
import { safeAsyncCall } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const { getInsuranceCompany, postInsuranceCompany, putInsuranceCompany } =
  InsuranceCompanyApiFactory(...args, undefined, axiosInstance);

export default function useInsuranceCompanyService() {
  return {
    get: safeAsyncCall(
      async (...args: Parameters<typeof getInsuranceCompany>) => {
        const res = await getInsuranceCompany(...args);
        return res.data;
      }
    ),
    add: safeAsyncCall(
      async (...args: Parameters<typeof postInsuranceCompany>) => {
        const res = await postInsuranceCompany(...args);
        return res; // { data, status }
      }
    ),
    update: safeAsyncCall(
      async (...args: Parameters<typeof putInsuranceCompany>) => {
        const res = await putInsuranceCompany(...args);
        return res; // { data, status }
      }
    ),
  };
}
