import type { Market, PostMarketRequest } from "@/api";
import { rest } from "msw";
import { dummyData, addNewData, replace } from "./dummy-markets";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import randomInteger from "random-int";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

export const handlers = [
  /**
   * handler for GetMarket API mock server.
   */
  rest.get(`${BASE_PATH}/market/:marketId`, (req, res, ctx) => {
    const marketId = Number(req.params.marketId);
    const market = dummyData.find((market) => market.marketId === marketId);
    if (!market) {
      return res(ctx.status(404), ctx.json({ message: "market not found" }));
    }
    return res(ctx.status(200), ctx.json(market));
  }),

  /**
   * PostMarket
   * return marketId.
   */
  rest.post(`${BASE_PATH}/market`, async (req, res, ctx) => {
    const request = await req.json();
    const newMarket = await convert(request);

    addNewData(newMarket);
    console.log("new market: ", newMarket);

    return res(ctx.status(201), ctx.text(String(newMarket.marketId)));
  }),
  /**
   * PutMarket
   */
  rest.put(`${BASE_PATH}/market/:marketId`, async (req, res, ctx) => {
    const request = await req.json();
    const marketId = Number(req.params.marketId);

    const newRecord = await convert(request, marketId);

    // finds a record with the matching ID
    const oldRecord = dummyData.find(
      (record) => record.marketId === newRecord.marketId
    );
    // replaces the old data with the new
    replace(oldRecord, newRecord);

    return res(ctx.status(204));
  }),
];

/**
 * Convert json shape from PostMarketRequest to Market
 * @param params PostMarketRequest
 * @returns Market
 */
export const convert = async (params: PostMarketRequest, marketId?: number) => {
  return {
    marketId: marketId ? Number(marketId) : randomInteger(1000000, 9999999),
    marketName: params.marketName,
    sortNumber: params.sortNumber,
  } as Market;
};
