<script setup lang="ts">
import { useErrorMessageStore } from "@/stores/ErrorMessage";
import { getLabelAndMode, getValueFromEnum } from "@/utils/helper";
import { computed, onBeforeUnmount } from "vue";

const errorMessageStore = useErrorMessageStore();
const errorMessages = computed(() => errorMessageStore.state.errorMessages);

// Props are the locigal values like them:
// insuranceType: "lifeInsurance/nonLifeInsurance",
// contractorType: "individual/corporation",

const props = defineProps<{
  screenId: number;
  insuranceType?: "lifeInsurance" | "nonLifeInsurance";
  contractorType?: "individual" | "corporation";
  modeKey: string;
}>();

// Only set the label name if it needs to be displayed on the screen.
// Otherwize, set it to null.
const contractorLabel = computed(() => {
  return props && props.contractorType
    ? getValueFromEnum("potentialCustomerAttribute", props.contractorType)
    : null;
});
const insuranceTypeLabel = computed(() => {
  return props && props.insuranceType
    ? getValueFromEnum("insuranceCategory", props.insuranceType)
    : null;
});

// Make it as reactive reference.
const ScreenLabel = computed(() => {
  return getLabelAndMode(
    props.screenId,
    props.modeKey,
    insuranceTypeLabel.value,
    contractorLabel.value
  );
});

onBeforeUnmount(() => {
  // Each screen shoudl execute the following code before unmounting.
  // Reset the global error message before the component is mounted.
  errorMessageStore.$reset();
  console.log("ScreenTitle.vue: onBeforeUnmount");
});
</script>

<template>
  <div class="text-h4 font-weight-black">{{ ScreenLabel }}</div>
  <v-alert
    v-for="(message, index) in errorMessages"
    :key="index"
    class="pa-3 mb-3"
    type="error"
    close-label="Close Alert"
    closable
    >{{ message }} index: {{ index }}</v-alert
  >
</template>

<style scoped></style>
