<script lang="ts">
import { defineComponent } from "vue";
import { Auth } from "aws-amplify";
import { amplifyConfig } from "@/aws_config";
import { useLoggedInUserStore } from "./stores/LoggedInUser";

export default defineComponent({
  name: "App",

  data() {
    return {
      signedIn: false,
      rail: false,
      drawer: true,
      sendingRequest: false,
    };
  },

  computed: {
    // Get staffRoleType from useLoggedInUserStore
    staffRoleType() {
      const store = useLoggedInUserStore();
      return store.staffRoleType;
    },
  },
  methods: {
    async handleLogin() {
      // navigate to the hosted login UI
      await Auth.federatedSignIn();
    },
    handleLogout() {
      Auth.signOut();
      this.signedIn = false;
    },
  },
  async mounted() {
    // Configure the Auth module with the Amplify configuration
    Auth.configure(amplifyConfig);

    // check if the user is already signed in
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (error) {
      console.log("error: ", error);
    }

    if (user) {
      // assign the logged in user info if loggedInUser is undefined.
      const store = useLoggedInUserStore();
      if (!store.loggedInUser && !store.loggedInUserId) {
        this.sendingRequest = true;
        await store.setLoggedInUser();
        this.sendingRequest = false;
      }
      this.signedIn = true;
    } else {
      this.signedIn = false;
      // print that the user is not signed in
      console.log("not signed in");
    }
  },
});
</script>

<template>
  <v-app>
    <v-app-bar app>
      <v-img
        src="/doit-commission-system.png"
        alt="Logo"
        class="toolbar-logo"
        max-width="54"
        max-height="54"
        contain
      ></v-img>
      <v-tool-bar>
        <v-toolbar-title
          :style="{ 'font-weight': 'bold', 'font-size': '24px' }"
        >
          Doit 手数料システム
        </v-toolbar-title>
      </v-tool-bar>
      <v-spacer></v-spacer>
      <span v-if="sendingRequest"> ログイン情報確認中... </span>
      <span
        v-if="!sendingRequest && !signedIn"
        :style="{ color: 'red', 'font-weight': 'bold', 'font-size': '24px' }"
      >
        ログインしてください
      </span>
      <v-spacer></v-spacer>
      <v-btn v-if="signedIn" @click="handleLogout">ログアウト</v-btn>
      <v-btn v-else color="accent" @click="handleLogin">ログイン</v-btn>
    </v-app-bar>

    <v-navigation-drawer
      app
      width="350"
      v-model="drawer"
      :rail="rail"
      permanent
      @click="rail = false"
    >
      <v-list-item nav>
        <template v-slot:append>
          <v-btn
            variant="text"
            :icon="rail ? 'mdi-chevron-right' : 'mdi-chevron-left'"
            @click.stop="rail = !rail"
          ></v-btn>
        </template>
      </v-list-item>

      <v-card class="mx-auto" max-width="400" tile>
        <v-list nav>
          <v-list-subheader class="text-h6 font-weight-black"
            >一般</v-list-subheader
          >
          <router-link
            to="/potential-customer/search"
            style="text-decoration: none; color: inherit"
          >
            <v-list-item
              prepend-icon="mdi-tag-outline"
              title="案件管理"
              value="potential-customer-management-search"
            ></v-list-item>
          </router-link>

          <router-link
            to="/contract-reports/life-insurance/search"
            style="text-decoration: none; color: inherit"
          >
            <v-list-item
              prepend-icon="mdi-tag-outline"
              title="生保申達管理"
              value="search-contract-reports-life-insurance"
            ></v-list-item>
          </router-link>
          <router-link
            to="/contract-reports/non-life-insurance/search"
            style="text-decoration: none; color: inherit"
          >
            <v-list-item
              prepend-icon="mdi-tag-outline"
              title="損保申達管理"
              value="search-contract-reports-non-life-insurance"
            ></v-list-item>
          </router-link>
          <router-link
            to="/performance-based-pay-calculation-summary"
            style="text-decoration: none; color: inherit"
          >
            <v-list-item
              prepend-icon="mdi-tag-outline"
              title="業績給・手数料管理"
              value="performance-based-pay-calculation-summary"
            ></v-list-item>
          </router-link>

          <div v-if="staffRoleType === 'admin'">
            <v-list-subheader class="text-h6 font-weight-black"
              >管理</v-list-subheader
            >
            <router-link
              to="/securities-management/search/life-insurance"
              style="text-decoration: none; color: inherit"
            >
              <v-list-item
                prepend-icon="mdi-tag"
                title="生保証券管理"
                value="search-securities-life-insurance"
              ></v-list-item>
            </router-link>
            <router-link
              to="/securities-management/search/non-life-insurance"
              style="text-decoration: none; color: inherit"
            >
              <v-list-item
                prepend-icon="mdi-tag"
                title="損保証券管理"
                value="search-securities-non-life-insurance"
              ></v-list-item>
            </router-link>

            <router-link
              to="/commission-and-performance-based-pay-calculation/check-status"
              style="text-decoration: none; color: inherit"
            >
              <v-list-item
                prepend-icon="mdi-tag"
                title="手数料・業績給計算"
                value="check-commission-ingestion-status"
              ></v-list-item>
            </router-link>
            <router-link
              to="/adjustments-deductions/search"
              style="text-decoration: none; color: inherit"
            >
              <v-list-item
                prepend-icon="mdi-tag"
                title="調整控除額管理"
                value="search-adjustment-deductions"
              ></v-list-item>
            </router-link>
            <router-link
              to="/purchase-potential-customer-management/search"
              style="text-decoration: none; color: inherit"
            >
              <v-list-item
                prepend-icon="mdi-tag"
                title="購入案件支払管理"
                value="search-purchase-potential-customer-management"
              ></v-list-item>
            </router-link>
            <router-link
              to="/department-management/search"
              style="text-decoration: none; color: inherit"
            >
              <v-list-item
                prepend-icon="mdi-tag"
                title="所属管理"
                value="search-departments"
              ></v-list-item>
            </router-link>
            <router-link
              to="/user-management/search"
              style="text-decoration: none; color: inherit"
            >
              <v-list-item
                prepend-icon="mdi-tag"
                title="ユーザー管理"
                value="create-user-management"
              ></v-list-item>
            </router-link>
            <router-link
              to="/insurance-type-management/search"
              style="text-decoration: none; color: inherit"
            >
              <v-list-item
                prepend-icon="mdi-tag"
                title="保険種類マスタ"
                value="search-insurance-types"
              ></v-list-item>
            </router-link>
            <router-link
              to="/insurance-company-management/search"
              style="text-decoration: none; color: inherit"
            >
              <v-list-item
                prepend-icon="mdi-tag"
                title="保険会社マスタ"
                value="search-insurance-company"
              ></v-list-item>
            </router-link>
            <router-link
              to="/market-management/search"
              style="text-decoration: none; color: inherit"
            >
              <v-list-item
                prepend-icon="mdi-tag"
                title="マーケットマスタ"
                value="search-market-management"
              ></v-list-item>
            </router-link>
            <router-link
              to="/adjustment-deduction-types/search"
              style="text-decoration: none; color: inherit"
            >
              <v-list-item
                prepend-icon="mdi-tag"
                title="調整控除項目マスタ"
                value="search-adjustment-deduction-types"
              ></v-list-item>
            </router-link>
            <router-link
              to="/staff-code-mapping-management/search"
              style="text-decoration: none; color: inherit"
            >
              <v-list-item
                prepend-icon="mdi-tag"
                title="募集人コードマッピング管理"
                value="search-staff-code-mapping-management"
                densitiy="compact"
              ></v-list-item>
            </router-link>
            <router-link
              to="/operation-logs/search"
              style="text-decoration: none; color: inherit"
            >
              <v-list-item
                prepend-icon="mdi-tag"
                title="操作履歴"
                value="search-operation-logs"
                densitiy="compact"
              ></v-list-item>
            </router-link>
          </div>
        </v-list>
      </v-card>
    </v-navigation-drawer>

    <v-main v-if="signedIn">
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <!-- https://router.vuejs.org/guide/advanced/transitions#Forcing-a-transition-between-reused-views -->
        <router-view :key="$route.path" />
      </v-container>
    </v-main>
    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
html,
body {
  font-family: "Noto Sans JP", sans-serif;
}

#app {
  font-family: "Noto Sans JP", sans-serif;
}
/*
 *TODO: Consistencies of font-size and opacity needed between Vuetify and Formkit.
 */

td,
th {
  font-size: 1rem !important;
}
// FormKit selector customize
.formkit-input {
  --fk-font-size-input: 1.25rem;
}
.formkit-label {
  --fk-font-size-label: 1.25rem;
  --fk-font-size-option: 1rem;
}
.formkit-message {
  --fk-font-size-message: 1rem;
}
button {
  --fk-font-size-button: 1.25rem;
}

// Vuetify selecotr customize
.v-list-item-title {
  font-size: 1.25rem !important;
}

.v-list-subheader__text {
  --v-medium-emphasis-opacity: none;
  font-size: 1.25rem;
}

.v-list-item--nav .v-list-item-title {
  line-height: 1.25rem !important;
}

.v-card-subtitle {
  font-size: 1.25rem !important;
  --v-medium-emphasis-opacity: none;
}

.v-card-title {
  font-size: 1.5rem !important;
  --v-medium-emphasis-opacity: none;
}

.v-btn--size-default {
  font-size: 1.25rem !important;
}

.v-btn__content {
  font-size: 1.25rem;
}

.v-tabs--density-default {
  --v-tabs-height: 64px !important;
}

.v-breadcrumbs-item {
  font-size: 1.5rem !important;
}
/*
 * Other customize to be done
 */
// https://formkit.com/playground?fkv=1.0.0-beta.7&fileTab=Playground.vue&files=JTVCJTdCJTIybmFtZSUyMiUzQSUyMlBsYXlncm91bmQudnVlJTIyJTJDJTIyZWRpdG9yJTIyJTNBJTIyJTNDc2NyaXB0JTIwc2V0dXAlM0UlNUNuJTJGJTJGJTIwRVNNJTIwaW1wb3J0cyUyMGFyZSUyMHN1cHBvcnRlZCUyMGZyb20lMjBVUkxzJTIwKGh0dHBzJTNBJTJGJTJGY2RuLi4uKSU1Q24lMkYlMkYlMjBhcyUyMHdlbGwlMjBhcyUyMHNlY29uZGFyeSUyMHBsYXlncm91bmQlMjBmaWxlcyUyMCguJTJGT3RoZXJGaWxlLnZ1ZSkuJTVDbiUzQyUyRnNjcmlwdCUzRSU1Q24lNUNuJTNDdGVtcGxhdGUlM0UlNUNuJTIwJTIwJTNDRm9ybUtpdCU1Q24lMjAlMjAlMjAlMjB0eXBlJTNEJTVDJTIydGV4dCU1QyUyMiU1Q24lMjAlMjAlMjAlMjBsYWJlbCUzRCU1QyUyMkZvcm1LaXQlMjBJbnB1dCU1QyUyMiU1Q24lMjAlMjAlMjAlMjBoZWxwJTNEJTVDJTIyZWRpdCUyMG1lJTIwdG8lMjBnZXQlMjBzdGFydGVkJTVDJTIyJTVDbiUyMCUyMCUyMCUyMHdyYXBwZXItY2xhc3MlM0QlNUMlMjJtb3JpeWElNUMlMjIlNUNuJTIwJTIwJTJGJTNFJTVDbiUzQyUyRnRlbXBsYXRlJTNFJTVDbiU1Q24lM0NzdHlsZSUzRSU1Q24ubXJpeWElMjAlN0IlNUNuJTIwJTIwLS1may1tYXgtd2lkdGgtaW5wdXQlM0ElMjBub25lJTNCJTVDbiU3RCU1Q24lM0MlMkZzdHlsZSUzRSU1Q24lMjIlMkMlMjJhZGRlZCUyMiUzQXRydWUlN0QlNUQ&imports=JTdCJTIybmFtZSUyMiUzQSUyMkltcG9ydE1hcCUyMiUyQyUyMmVkaXRvciUyMiUzQSUyMiU3QiU1Q24lMjAlMjAlNUMlMjJ2dWUlNUMlMjIlM0ElMjAlNUMlMjJodHRwcyUzQSUyRiUyRmNkbi5qc2RlbGl2ci5uZXQlMkZucG0lMkZ2dWUlNDAzJTJGZGlzdCUyRnZ1ZS5lc20tYnJvd3Nlci5taW4uanMlNUMlMjIlNUNuJTdEJTVDbiUyMiU3RA
.formkit-wrapper {
  // default: 25em
  --fk-max-width-input: none;
}

[data-invalid] .formkit-inner {
  border-color: red;
  box-shadow: 0 0 0 1px red;
}

.searchInput {
  font-size: 1.25rem;
}
.toolbar-logo {
  margin-right: 16px; /* ここでスペースを調整 */
  margin-left: 16px; /* ここでスペースを調整 */
}
</style>
