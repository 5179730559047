<script setup lang="ts">
import { onUnmounted, onUpdated } from "vue";

const props = withDefaults(
  defineProps<{
    noDataFound: boolean;
    message?: string;
  }>(),
  {
    message: "対象の情報がありません",
  }
);

onUpdated(() => {
  console.log("NoDataFound.vue is updated");
});
onUnmounted(() => {
  console.log("NoDataFound.vue is unmounted");
});
</script>

<template>
  <v-alert v-if="noDataFound" type="info" outlined class="ma-4">
    {{ message }}
  </v-alert>
</template>

<style scoped></style>
