import {
  useContractReportPotentialCustomerTypeStore,
  type PotentialCustomerType,
} from "@/stores/ContractReports/PotentialCustomerType";

// Simply export the same store to use it as an alias.
export const useSecuritiesManagementPotentialCustomerTypeStore =
  useContractReportPotentialCustomerTypeStore;

export type { PotentialCustomerType };
