import { rest } from "msw";
import type { GetAdjustmentDeductionTypes200ResponseResultsInner } from "@/api/";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import { adjustmentDeductionTypesData } from "../AdjustmentDeductionType/dummy-data";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

/**
 * AdjustmentDeductionTypesQuery
 * GetAdjustmentDeductionTypes API
 */

export const handlers = [
  rest.get(`${BASE_PATH}/adjustmentDeductionType`, (req, res, ctx) => {
    const adjustmentDeductionTypeAttribute = req.url.searchParams.get(
      "adjustmentDeductionTypeAttribute"
    );

    let filteredAdjustmentDeductionTypes =
      [] as GetAdjustmentDeductionTypes200ResponseResultsInner[];

    filteredAdjustmentDeductionTypes = adjustmentDeductionTypesData;

    if (adjustmentDeductionTypeAttribute) {
      filteredAdjustmentDeductionTypes =
        filteredAdjustmentDeductionTypes.filter((type) => {
          return (
            type.adjustmentDeductionTypeAttribute ==
            adjustmentDeductionTypeAttribute
          );
        });
    }

    return res(
      ctx.status(200),
      ctx.json({
        results: filteredAdjustmentDeductionTypes,
      })
    );
  }),
];
