import type { GetInsuranceTypes200ResponseResultsInner } from "@/api";
import { dummyInsuranceCompanyTypes } from "@/mocks/InsuranceType/dummy-data";
import { rest } from "msw";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

export const handlers = [
  /**
   * InsuranceTypeQuery
   * GetInsuranceTypes
   */
  rest.get(`${BASE_PATH}/insuranceType`, (req, res, ctx) => {
    const insuranceCategory = req.url.searchParams.get("insuranceCategory");
    let filteredInsuranceTypes =
      [] as GetInsuranceTypes200ResponseResultsInner[];

    filteredInsuranceTypes = dummyInsuranceCompanyTypes;

    if (insuranceCategory) {
      filteredInsuranceTypes = filteredInsuranceTypes.filter((type) => {
        return type.insuranceCategory == insuranceCategory;
      });
    }

    return res(
      ctx.status(200),
      ctx.json({
        results: filteredInsuranceTypes,
        pagination: {
          totalCount: 0,
        },
      })
    );
  }),
];
