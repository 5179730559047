import type { PaymentMethod } from "@/api";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

// 保険情報フォーム

export interface BaseInsuranceInfo {
  insuranceCompanyId: number;
  insuranceTypeId: number;
  paymentMethod: PaymentMethod;
  deemedAnnualPayment: boolean | undefined;
  insuranceFee: number;
}
export type LifeInsuranceInfo = {
  contractDateOn: string;
} & BaseInsuranceInfo;

export type NonLifeInsuranceInfo = {
  policyNumber: string; // 証券番号
  applicationDateOn: string; // 申込日
  insuranceStartDateOn: string; // 保険始期
  insuranceEndDateOn: string; // 保険終期
  receiptDateOn?: string; // 領収日
  returnDateOn?: string; // 返戻日
  nonLifeInsuranceAccountingCategory:
    | "new"
    | "renewal"
    | "transfer"
    | "unknown"; // 計上区分
  nonLifeInsuranceDepositCategory:
    | "directDebit"
    | "agencyAccount"
    | "creditCard"
    | "salaryDeduction"
    | "paymentSlip"
    | "unknown"; // 入金区分
} & BaseInsuranceInfo;

export type InsuranceInfo =
  | LifeInsuranceInfo
  | NonLifeInsuranceInfo
  | Record<string, unknown>;

export const useContractReportInsuranceInfoStore = defineStore(
  "insurance-info",
  () => {
    // state
    const currentItem = ref<InsuranceInfo>({
      insuranceCompanyId: undefined,
      insuranceTypeId: undefined,
      paymentMethod: undefined,
      deemedAnnualPayment: false,
      insuranceFee: 0,
      contractDateOn: "",
      policyNumber: "",
      applicationDateOn: "",
      insuranceStartDateOn: "",
      insuranceEndDateOn: "",
      receiptDateOn: "",
      returnDateOn: "",
      nonLifeInsuranceAccountingCategory: "",
      nonLifeInsuranceDepositCategory: "",
    });

    const clearContractReports = () => {
      currentItem.value = {};
    };

    /**
     * 保険情報
     */

    const insuranceInfo = computed((): InsuranceInfo => {
      return currentItem.value;
    });
    const setInsuranceInfo = (insuranceInfo: InsuranceInfo) => {
      currentItem.value = insuranceInfo;
    };

    return {
      currentItem,
      insuranceInfo,
      setInsuranceInfo,
      clearContractReports,
    };
  }
);
