import type { ContractorAttribute, InsuranceCategory } from "@/api";
import type { SecuritiesScreenItem } from "@/types/SecuritiesManagement/securities-management";
import { defineStore } from "pinia";
import { ref } from "vue";

// definition:
//
// insuranceCategory: InsuranceCategory;
// contractorAttribute: ContractorAttribute;
// contractReportId?: number;
// potentialCustomerId ?: number | undefined;
// staffId: number;

/**
 * Defining the info
 */
type MetaInfoBase = SecuritiesScreenItem["metaInfo"];

export type MetaInfo = MetaInfoBase;

export const useMetaInfoStore = defineStore("meta-info", () => {
  // initialize state
  const currentItem = ref<MetaInfo>({
    insuranceCategory: "",
    contractorAttribute: "",
    staffId: "",
    securitiesId: undefined,
    contractReportId: undefined,
  });

  return {
    currentItem,
  };
});
