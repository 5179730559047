import _ from "lodash";

export const dummyOperationLogs = [
  {
    logAt: "2024-08-18T20:00:00Z",
    apiName: "PostStaff",
    resourceId: "111",
    staff: {
      firstName: "麻由美",
      lastName: "大澤",
      staffId: 1234567,
      staffNumber: "00123",
    },
  },
  {
    logAt: "2024-08-18T21:30:00Z",
    apiName: "PutStaff",
    resourceId: "222",
    staff: {
      firstName: "Valerie",
      lastName: "Yamanaka",
      staffId: 7777,
      staffNumber: "7777",
    },
  },
  {
    logAt: "2024-09-01T20:00:30Z",
    apiName: "PutStaffPassword",
    resourceId: "333",
    staff: {
      firstName: "麻由美",
      lastName: "大澤",
      staffId: 1234567,
      staffNumber: "00123",
    },
  },
  {
    logAt: "2024-09-01T20:00:30Z",
    apiName: "PostStaff",
    resourceId: "4444",
    staff: {
      firstName: "麻由美",
      lastName: "大澤",
      staffId: 1234567,
      staffNumber: "00123",
    },
  },
];
