import { rest } from "msw";
import type { GetCommissionStatus200ResponseResultsInner } from "@/api/";
import {
  commissionStatusData,
  getCommissionsData,
  commissionInsuranceCompanyCalculationResults,
  getCommissionCalculationResults,
} from "@/mocks/CommissionQuery/dummy-data";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import _ from "lodash-es";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);
import { format } from "date-fns";

const duration = import.meta.env.VITE_ENV === "test" ? 0 : 2000;

/**
 * CommissionQuery
 * GetCommissions API
 * GetCommissionMonthCurrent API
 * GetCommissionStatus API
 * GetCommissionCalculationResults
 * PutCommissionCalculation API
 * GetCommissionsCsv API
 **/

export const handlers = [
  // GetCommissions API
  rest.get(
    `${BASE_PATH}/commission/import/:commissionImportId`,
    async (req, res, ctx) => {
      const commissionImportId = req.params.commissionImportId;
      const record = getCommissionsData.find(
        (item) => item.commissionImportId === Number(commissionImportId)
      );

      await new Promise((resolve) => setTimeout(resolve, 2000));
      if (record) {
        const { commissionImportId, ...recordWithoutId } = record;

        console.log(recordWithoutId);

        return res(ctx.status(200), ctx.json(recordWithoutId));
      } else {
        return res(ctx.status(404), ctx.json("No record found"));
      }
    }
  ),

  // GetCommissionMonthCurrent API
  rest.get(`${BASE_PATH}/commission/month/current`, (req, res, ctx) => {
    // month will dynamically set the current month with `yyyymm` format
    const month = new Date().toISOString().slice(0, 7).replace(/-/g, "");
    return res(ctx.status(200), ctx.json(month));
  }),

  // GetCommissionStatus API
  rest.get(`${BASE_PATH}/commission/status/:month`, (req, res, ctx) => {
    // month is not used in this mock
    const month = req.params.month;
    let results: GetCommissionStatus200ResponseResultsInner[] = [];

    // Get the current month with `yyyymm` format using date-fns.format
    const currentMonth = new Date();

    const currentMonthString = format(
      new Date(currentMonth.setMonth(currentMonth.getMonth())),
      "yyyyMM"
    );

    if (month === "202410" || month === currentMonthString) {
      results = commissionStatusData;
    }

    return res(
      ctx.status(200),
      ctx.json({
        results,
      })
    );
  }),

  // GetCommissionInsuranceCompanyCalculationResults API
  rest.get(
    `${BASE_PATH}/commission/calculation/:staffId/:month/insuranceCompany`,
    (req, res, ctx) => {
      // month is not used in this mock
      const month = req.params.month;
      const staffId = req.params.staffId;

      // for now, there is only one result to return.
      // if more sophisticated mocking is required, change this to allow filtering.

      return res(
        ctx.status(200),
        ctx.json(commissionInsuranceCompanyCalculationResults)
      );
    }
  ),

  // GetCommissionCalculationResults API
  rest.get(
    `${BASE_PATH}/commission/calculation/:staffId/:month`,
    (req, res, ctx) => {
      // month is not used in this mock
      const month = req.params.month;
      const staffId = req.params.staffId;

      // for now, there is only one result to return.
      // if more sophisticated mocking is required, change this to allow filtering.

      return res(
        ctx.status(200),
        ctx.json({
          results: getCommissionCalculationResults,
          pagination: {
            totalCount: 0,
          },
        })
      );
    }
  ),

  // GetCommissionsCsv API
  rest.get(
    `${BASE_PATH}/commission/import/:commissionImportId/csv`,
    (req, res, ctx) => {
      // create a dummy CSV
      const csvData = `"header1","header2","header3"
"row1-1","row1-2","row1-3"`;

      return res(
        ctx.status(200),
        ctx.set("Content-Type", "text/csv"),
        ctx.set("Content-Disposition", `attachment;`),
        ctx.set("charset", "Shift-JIS"),
        ctx.body(csvData)
      );
    }
  ),

  // PutCommissionCalculation API
  rest.put(`${BASE_PATH}/commission/calculation`, async (req, res, ctx) => {
    // find the commission data from dummy data based on the parameter which is a commsionImportId
    return res(ctx.status(204), ctx.delay(duration));
  }),

  // GetCommissionCalculationResults Csv
  rest.get(
    `${BASE_PATH}/commission/calculation/:staffId/:month/csv`,

    (req, res, ctx) => {
      const staffId = req.params.staffId;
      const month = req.params.staffId;

      // create a dummy CSV
      const csvData = `"header1","header2","header3"
"row1-1","row1-2","row1-3"`;

      return res(
        ctx.status(200),
        ctx.set("Content-Type", "text/csv"),
        ctx.set("Content-Disposition", `attachment;`),
        ctx.body(csvData)
      );
    }
  ),
];
