import { defineStore } from "pinia";
import { useStaffQueryService } from "@/services/use-staff-query-service";
import { computed, ref } from "vue";
import type { GetMeStaffCurrent200Response } from "@/api";

type LoggedInUser = GetMeStaffCurrent200Response;
export const useLoggedInUserStore = defineStore("logged-in-user", () => {
  const loggedInUser = ref<LoggedInUser>();

  const loggedInUserId = computed(() => loggedInUser.value?.staffId);

  const setLoggedInUser = async () => {
    loggedInUser.value =
      (await useStaffQueryService().findbyMeCurrent()) ?? undefined;
  };

  const staffRoleType = computed(
    () => loggedInUser.value?.staffRoleType ?? "normal"
  );

  return {
    loggedInUser,
    loggedInUserId,
    setLoggedInUser,
    staffRoleType,
  };
});
