// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify(
  // https://stackoverflow.com/questions/68778689/cannot-use-namespace-themedefinition-as-a-type-in-vuetify
  {
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            primary: "#63a4ff",
            secondary: "#e3f2fd",
            accent: "#9c27b0",
            error: "#f44336",
            warning: "#F9AA33",
            info: "#607d8b",
            success: "#2196f3",
          },
        },
      },
    },
  }
);
