<script setup lang="ts">
import { computed, ref } from "vue";
import { reset } from "@formkit/core";

const props = defineProps<{
  importAction: (file: File, ...args: any[]) => Promise<any>;
  importArgs: any[];
  buttonSize?: string;
}>();

// states
const file = computed(() =>
  data.value.uploadFile && data.value.uploadFile.length > 0
    ? data.value.uploadFile[0].file
    : null
);
const alert = ref(false);
const completed = ref(false);
const sendingRequest = ref(false);
const failed = ref(false);
const data = ref<{
  uploadFile: any[];
}>({ uploadFile: [] });

const uploadFile = async (file: any | null) => {
  if (!file) return;
  try {
    sendingRequest.value = true;
    // call the function passed from parent component
    const response = await props.importAction(file, ...props.importArgs);
    // const response = await _uploadFile(file, mockedImportFile);

    // reset the form
    reset("data");

    completed.value = true;
    alert.value = true;
  } catch (error) {
    failed.value = true;
    console.error(error);
  } finally {
    sendingRequest.value = false;
  }
};
</script>

<template>
  <v-card width="auto" class="d-flex justify-center align-center">
    <v-sheet class="ma-10" v-if="completed">
      <v-alert
        class=""
        v-model="alert"
        color="success"
        icon="$success"
        :text="`インポートに成功しました。`"
      ></v-alert>
    </v-sheet>
    <v-sheet class="ma-10" v-if="failed">
      <v-alert
        class=""
        color="error"
        icon="$error"
        :text="`インポートに失敗しました。`"
      ></v-alert>
    </v-sheet>
    <v-sheet
      class="d-flex ma-10"
      v-if="!completed && !sendingRequest && !failed"
    >
      <FormKit type="group" id="data" v-model="data">
        <FormKit
          id="uploadFile"
          name="uploadFile"
          type="file"
          accept=".csv"
          help="CSVファイルのみ選択できます。"
          data-testid="file-input"
        />
      </FormKit>
      <v-btn
        class="mx-2"
        :disabled="!file"
        @click="uploadFile(file)"
        :size="buttonSize ?? 'large'"
        color="primary"
        >CSVインポート</v-btn
      >
    </v-sheet>
    <v-card v-if="sendingRequest">
      <v-card-text>
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-card>
</template>
