import { DepartmentApiFactory } from "@/api";

import { useEnvironmentVars } from "@/composables/use-environment-vars";
import axiosInstance from "@/utils/axios-customized-instance";
import { safeAsyncCall } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const { getDepartment, postDepartment, putDepartment } = DepartmentApiFactory(
  ...args,
  undefined,
  axiosInstance
);

export function useDepartmentService() {
  return {
    /**
     * Get department by the given parameters.
     * @param args
     */
    get: safeAsyncCall(async (...args: Parameters<typeof getDepartment>) => {
      const res = await getDepartment(...args);
      return res; // { data, status }
    }),
    /**
     * Add department by the given parameters.
     * @param args
     */
    add: safeAsyncCall(async (...args: Parameters<typeof postDepartment>) => {
      const res = await postDepartment(...args);
      return res; // { data, status }
    }),
    /**
     * Update department by the given parameters.
     * @param args
     */
    update: safeAsyncCall(async (...args: Parameters<typeof putDepartment>) => {
      const res = await putDepartment(...args);
      return res; // { data, status }
    }),
  };
}
