import type { PotentialCustomerCorporationScreenItem } from "@/types/PotentialCustomer/potential-customer-corporation";
import { defineStore } from "pinia";
import { ref } from "vue";

export const usePotentialCustomerCorporationInfoStore = defineStore(
  "potential-customer-corporation-info",
  () => {
    // initialize state
    const currentItem = ref<
      PotentialCustomerCorporationScreenItem["potentialCustomerCorporation"]
    >({
      corporationName: "",
      corporationClosingMonth: undefined,
      corporationRepresentativeFirstName: "",
      corporationRepresentativeLastName: "",
      addressInformation: {
        postCode: "",
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        address5: "",
      },
      tel1: "",
      tel2: "",
      mailAddress: "",
    });

    return {
      currentItem,
    };
  }
);
