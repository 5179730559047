import { rest } from "msw";
import {
  baseDummyData,
  changeActiveFlag,
  replace,
  changeDummyPassword,
} from "./dummy-get-staff";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import type { PostStaffRequest, PutStaffPasswordRequest } from "@/api";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);
import randomInteger from "random-int";
import _ from "lodash";

export const handlers = [
  /**
   * Staff
   * GetStaff
   */
  rest.get(`${BASE_PATH}/staff/:staffId`, (req, res, ctx) => {
    const staffId = req.params.staffId;
    const staff = baseDummyData.find(
      (staff) => staff.staffId === Number(staffId)
    );

    if (!staff) {
      return res(
        ctx.status(404),
        ctx.json({
          message: "staff not found",
        })
      );
    }

    return res(ctx.status(200), ctx.json(staff));
  }),

  /**
   * Staff
   * PostStaff
   */

  rest.post(`${BASE_PATH}/staff`, async (req, res, ctx) => {
    const request = await req.json();
    const newStaff = await convert(request);

    const convertedStaff = { accountActiveFlag: true, ...newStaff };
    baseDummyData.push(convertedStaff);

    return res(ctx.text(String(newStaff.staffId)));
  }),

  /**
   * Staff
   * PutStaff
   */

  rest.put(`${BASE_PATH}/staff/:staffId`, async (req, res, ctx) => {
    const request = await req.json();
    const staffId = Number(req.params.staffId);

    const newStaff = await convert(request, staffId);

    // find a record with the matching ID
    const oldStaff = baseDummyData.find((staff) => staff.staffId === staffId);

    replace(oldStaff, newStaff);

    // delay the response to simulate async
    return res(ctx.status(204), ctx.delay(200));
  }),

  /**
   * Staff
   * PutStaffAccountActive
   */

  rest.put(
    `${BASE_PATH}/staff/:staffId/accountActive`,
    async (req, res, ctx) => {
      const newAccountActiveFlag = await req.json();
      const staffId = Number(req.params.staffId);

      const oldStaff = baseDummyData.find((staff) => staff.staffId === staffId);

      changeActiveFlag(oldStaff, newAccountActiveFlag.accountActiveFlag);

      return res(ctx.status(204));
    }
  ),

  /**
   * Staff
   * PutStaffPassword
   */

  rest.put(`${BASE_PATH}/staff/:staffId/password`, async (req, res, ctx) => {
    const passwordParam = await req.json();
    const password = passwordParam.password;
    const staffId = Number(req.params.staffId);

    const oldStaff = baseDummyData.find((staff) => staff.staffId === staffId);

    changeDummyPassword(oldStaff, password);

    return res(ctx.status(204));
  }),
];

const convert = async (
  params: PostStaffRequest,
  staffId?: number,
  dummyPassword?: any
) => {
  const data = {
    staffId: staffId ?? randomInteger(1000000, 9999999),
    dummyPassword: dummyPassword ?? undefined,
    ...params,
  };
  return data;
};
