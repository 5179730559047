import { rest } from "msw";
import {
  adjustmentDeductions,
  replace,
} from "@/mocks/AdjustmentDeduction/dummy-records";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import type {
  GetAdjustmentDeductions200ResponseResultsInner,
  PostAdjustmentDeductionRequest,
} from "@/api";
import randomInteger from "random-int";
import useAdjustmentDeductionTypeQueryService from "@/services/use-adjustment-deduction-types-query-service";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

/**
 * Convert json shape from PostAdjustmentDeductionRequest to GetAdjustmentDeductions200Response
 * @param params PostAdjustmentDeductionRequest
 * @returns GetAdjustmentDeductions200Response
 */
export const convert = async (
  params: PostAdjustmentDeductionRequest,
  adjustmentDeductionId?: number
) => {
  // adjustmentDeductionTypeAttribute Available values : adjustment, deduction, deductionForValuables
  const adjustmentDeductionTypeList = await (
    await useAdjustmentDeductionTypeQueryService().findBy()
  ).results;

  const deductionMatchData = adjustmentDeductionTypeList.find(
    (deduction) =>
      deduction.adjustmentDeductionTypeId ===
      Number(params.adjustmentDeductionTypeId)
  );
  const adjustmentDeductionTypeName = deductionMatchData
    ? deductionMatchData.adjustmentDeductionTypeName
    : "";

  return {
    adjustmentDeductionId: adjustmentDeductionId
      ? Number(adjustmentDeductionId)
      : randomInteger(1000000, 9999999),
    adjustmentDeductionTypeId: params.adjustmentDeductionTypeId,
    adjustmentDeductionTypeName: adjustmentDeductionTypeName,
    adjustmentDeductionName: params.adjustmentDeductionName,
    adjustmentDeductionFee: params.adjustmentDeductionFee,
    adjustmentDeductionTerm: params.adjustmentDeductionTerm,
    staff: {
      staffId: params.staffId,
      staffNumber: "UPDATED",
      firstName: "UPDATED",
      lastName: "UPDATED",
      departmentName: "",
      departmentId: 0,
    },
  } as GetAdjustmentDeductions200ResponseResultsInner;
};

/**
 * handlers for AdjustmentDeduction.
 */
export const handlers = [
  /**
   * PostAdjustmentDeduction API.
   * params staffId, adjustmentDeductionTypeId, adjustmentDeductionMemo,adjustmentDeductionFee, adjustmentDeductionTerm
   * return adjustmentDeductionId.
   */
  rest.post(`${BASE_PATH}/adjustmentDeduction`, async (req, res, ctx) => {
    const request = await req.json();
    const newAdjustmentDeduction = await convert(request);

    adjustmentDeductions.push(newAdjustmentDeduction);
    console.log("new adjustment/deduction: ", newAdjustmentDeduction);

    return res(
      ctx.status(201),
      ctx.text(String(newAdjustmentDeduction.adjustmentDeductionId))
    );
  }),

  /**
   * Delete AdjustmentDeduction API.
   * params adjustmentDeductionId
   */
  rest.delete(
    `${BASE_PATH}/adjustmentDeduction/:adjustmentDeductionId`,
    async (req, res, ctx) => {
      // get today's Date for comparison
      const date: Date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      let newMonth = month.toString();
      if (newMonth.length === 1) {
        newMonth = newMonth.padStart(2, "0");
      }
      const newYear = year.toString();
      const todaysDate = Number(newYear + newMonth);

      // find the matching record
      const id = req.params.adjustmentDeductionId;
      const matchRecord = adjustmentDeductions.find(
        (record) => record.adjustmentDeductionId === Number(id)
      );

      // get index and the adjustmentDeductionTermFrom from the record
      const index = adjustmentDeductions.indexOf(matchRecord);
      const recordDate = Number(
        matchRecord?.adjustmentDeductionTerm.adjustmentDeductionTermFrom
      );

      // splice out the record
      if (index > -1 && recordDate > todaysDate) {
        adjustmentDeductions.splice(index, 1);
        return res(ctx.status(204));
      } else if (recordDate <= todaysDate) {
        return res(ctx.json("Cannot delete record."));
      } else {
        return res(ctx.json("No record found."));
      }
    }
  ),
  /**
   *
   * AdjustmentDeduction.
   *
   * PutAdjustmentDeduction API.
   */
  rest.put(
    `${BASE_PATH}/adjustmentDeduction/:adjustmentDeductionId`,
    async (req, res, ctx) => {
      const request = await req.json();
      const newAdjustmentDeduction = await convert(
        request,
        request.adjustmentDeductionId
      );

      // finds a record with the matching ID
      const oldAdjustmentDeduction = adjustmentDeductions.find(
        (adjustmentDeduction) =>
          adjustmentDeduction.adjustmentDeductionId ===
          newAdjustmentDeduction.adjustmentDeductionId
      );
      // replaces the old data with the new
      replace(oldAdjustmentDeduction, newAdjustmentDeduction);

      return res(ctx.status(204));
    }
  ),
];
