import { rest } from "msw";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

const dummyData = [
  {
    address1: "東京都",
    address2: "品川区",
    address3: "北品川",
    kana1: "ﾄｳｷｮｳﾄ",
    kana2: "ｼﾅｶﾞﾜｸ",
    kana3: "ｷﾀｼﾅｶﾞﾜ",
    prefcode: "13",
    zipcode: "1400001",
  },
  {
    address1: "東京都",
    address2: "新宿区",
    address3: "四谷",
    kana1: "ﾄｳｷｮｳﾄ",
    kana2: "ｼﾝｼﾞｭｸｸ",
    kana3: "ﾖﾂﾔ",
    prefcode: "13",
    zipcode: "1600004",
  },
];

export const handlers = [
  rest.get(`https://zipcloud.ibsnet.co.jp/`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        result: "root",
      })
    );
  }),

  // curl --request GET \
  //   --url 'https://zipcloud.ibsnet.co.jp/api/search?zipcode=1400333'
  rest.get(`https://zipcloud.ibsnet.co.jp/api/search`, (req, res, ctx) => {
    // Desctructure params for findBy
    const zipcode = req.url.searchParams.get("zipcode");

    //   Retrieve the information if zipcode matches with the parameter
    const result = dummyData.find((data) => data.zipcode === zipcode);

    return res(
      ctx.status(200),
      ctx.json({
        message: null,
        results: result ? [result] : null,
        status: 200,
      })
    );
  }),
];
