import type { GetAdjustmentDeductions200ResponseResultsInner } from "@/api";
import _ from "lodash-es";

// dummyData
export let adjustmentDeductions: GetAdjustmentDeductions200ResponseResultsInner[] =
  [
    {
      staff: {
        staffId: 7777,
        staffNumber: "A7777",
        firstName: "Varelie",
        lastName: "Yamanaka",
        departmentName: "",
        departmentId: 0,
      },
      adjustmentDeductionId: 1234567,
      adjustmentDeductionTypeId: 1234567,
      adjustmentDeductionTypeName: "アシスタント費",
      adjustmentDeductionName: "アシスタント一郎",
      adjustmentDeductionFee: 200000,
      adjustmentDeductionTerm: {
        adjustmentDeductionTermFrom: "202104",
        adjustmentDeductionTermTo: "202105",
      },
    },
    {
      staff: {
        staffId: 7777,
        staffNumber: "A7777",
        firstName: "Varelie",
        lastName: "Yamanaka",
        departmentName: "",
        departmentId: 0,
      },
      adjustmentDeductionId: 1234568,
      adjustmentDeductionTypeId: 1234568,
      adjustmentDeductionTypeName: "証券フォルダ",
      adjustmentDeductionName: "カレンダー",
      adjustmentDeductionFee: -200000,
      adjustmentDeductionTerm: {
        adjustmentDeductionTermFrom: "209904",
        adjustmentDeductionTermTo: "209905",
      },
    },
    {
      staff: {
        staffId: 7777,
        staffNumber: "A7777",
        firstName: "Varelie",
        lastName: "Yamanaka",

        departmentName: "",
        departmentId: 0,
      },
      adjustmentDeductionId: 1234569,
      adjustmentDeductionTypeId: 1234569,
      adjustmentDeductionTypeName: "証券フォルダ",
      adjustmentDeductionName: "カレンダー",
      adjustmentDeductionFee: 600000,
      adjustmentDeductionTerm: {
        adjustmentDeductionTermFrom: "202306",
        adjustmentDeductionTermTo: "202307",
      },
    },
  ];

// Replace function to use with mock Put requests.
export function replace(oldRecord: any, updatedRecord: any) {
  adjustmentDeductions = _.concat(
    _.filter(adjustmentDeductions, (record) => record !== oldRecord),
    updatedRecord
  );
}
