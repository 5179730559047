<script setup lang="ts">
import { onMounted, ref } from "vue";
import SearchFilter from "@/components/PotentialCustomerManagement/SearchFilter.vue";
import ResultTable from "@/components/PotentialCustomerManagement/ResultTable.vue";
import { usePotentialCustomerIndividualStore } from "@/stores/potential-customer-individual";
import { usePotentialCustomerCorporationStore } from "@/stores/potential-customer-corporation";
import { useContractReportStore } from "@/stores/ContractReports/ContractReports";
import { useSecuritiesStore } from "@/stores/SecuritiesManagement/SecuritiesManagement";
import type { ContractorAttribute } from "@/api";

// Screen Title
import ScreenTitle from "@/components/ScreenTitle.vue";
import { useRouter } from "vue-router";

/**
 * Search for customers, contract reports, and secutities with the specified conditions.
 */
const attribute = ref<ContractorAttribute>("individual");

//-----------------------------------------------------------------------------
// Use store(pinia) to find customers and show them on the screen.
//-----------------------------------------------------------------------------
const potentialCustomerIndividualStore = usePotentialCustomerIndividualStore();
const potentialCustomerCorporationStore =
  usePotentialCustomerCorporationStore();
const contractReportStore = useContractReportStore();
const securitiesStore = useSecuritiesStore();

const router = useRouter();

// reset noDataFound status when moving to another screen
router.beforeEach((to, from, next) => {
  // reset noDataFound status not to show no data found message in the next screen
  potentialCustomerIndividualStore.noDataFound = false;
  potentialCustomerCorporationStore.noDataFound = false;

  // reset each result list
  potentialCustomerIndividualStore.clearCustomers();
  potentialCustomerCorporationStore.clearCorporations();
  contractReportStore.clearContracts();
  securitiesStore.clearSecurities();
  next();
});

onMounted(() => {
  potentialCustomerIndividualStore.clearCustomers();
  potentialCustomerCorporationStore.clearCorporations();
  contractReportStore.clearContracts();
  securitiesStore.clearSecurities();

  potentialCustomerIndividualStore.noDataFound = false;
  potentialCustomerCorporationStore.noDataFound = false;
});
//-----------------------------------------------------------------------------
// Switch whether to show the search filter or not.
//-----------------------------------------------------------------------------
const drawer = ref(false);
const toggleDrawer = (value: boolean) => (drawer.value = value);

//-----------------------------------------------------------------------------
// Lifecycle hook
//-----------------------------------------------------------------------------

onMounted(() => {
  potentialCustomerIndividualStore.clearCustomers();
  potentialCustomerCorporationStore.clearCorporations();
  contractReportStore.clearContracts();
  securitiesStore.clearSecurities();

  potentialCustomerIndividualStore.noDataFound = false;
  potentialCustomerCorporationStore.noDataFound = false;
});
</script>

<template>
  <screen-title :screenId="16" modeKey="search" />
  <search-filter
    :drawer="drawer"
    @change="toggleDrawer"
    v-model:contractorAttribute="attribute"
  />

  <result-table
    :contractorAttribute="attribute"
    :resultsOfPotentialCustomerIndividual="
      potentialCustomerIndividualStore.customersList ?? undefined
    "
    :resultsOfPotentialCustomerCorporation="
      potentialCustomerCorporationStore.corporationsList ?? undefined
    "
    :resultsOfContractReportIndividual="
      contractReportStore.contractsListIndividual ?? undefined
    "
    :resultsOfContractReportCorporation="
      contractReportStore.contractsListCorporation ?? undefined
    "
    :resultsOfSecuritiesIndividual="
      securitiesStore.securitiesListIndividual ?? undefined
    "
    :resultsOfSecuritiesCorporation="
      securitiesStore.securitiesListCorporation ?? undefined
    "
  />
</template>

<style scoped></style>
