import { StaffQueryApiFactory } from "@/api";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import axiosInstance from "@/utils/axios-customized-instance";
import { safeAsyncCall, safeAsyncCallAndRaiseError } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const {
  getStaffs,
  getStaffCurrent,
  getMeStaffCurrent,
  postStaffStandardMonthlyRemunerationCsv,
} = StaffQueryApiFactory(...args, undefined, axiosInstance);

export function useStaffQueryService() {
  return {
    // #TODO: Separation of concerns. How should we handle pagination?
    findBy: safeAsyncCall(async (...args: Parameters<typeof getStaffs>) => {
      const res = await getStaffs(...args);
      return res.data;
    }),

    /**
     * getMeStaffCurrent
     */
    findbyMeCurrent: safeAsyncCall(
      async (...args: Parameters<typeof getMeStaffCurrent>) => {
        const res = await getMeStaffCurrent(...args);
        return res.data;
      }
    ),

    findByCurrent: safeAsyncCall(
      async (...args: Parameters<typeof getStaffCurrent>) => {
        const res = await getStaffCurrent(...args);
        return res.data;
      },
      // Pass the error handler to raise the error to the caller
      (error) => {
        throw error;
      }
    ),

    /**
     * PostStaffStandardMonthlyRemunerationCsv
     */
    importFile: safeAsyncCallAndRaiseError(
      async (
        ...args: Parameters<typeof postStaffStandardMonthlyRemunerationCsv>
      ) => {
        const res = await postStaffStandardMonthlyRemunerationCsv(...args);
        return res.data;
      }
    ),
  };
}
