import _ from "lodash";

export let dummyData = {
  consumptionTaxIncludeFlag: true,
  columnMapping: {
    policyNumberColumn: "証券\n番号",
    contractorColumn: "(契約者)漢字氏名",
    insuredColumn: "",
    commissionColumn: "合計手数料",
    insuranceFeeColumn: "保険料",
    staffColumn: "",
    staffCodeColumn: "募集人登録番号",
    insuranceTypeColumn: "保険種類",
    contractDateColumn: "成立日",
  },
};

// Replace function to use with mock Put requests.
// While there's only one record, just replace directly.
export function replace(record: any) {
  dummyData = record;
}
