import { rest } from "msw";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import _ from "lodash-es";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

/**
 * Payroll
 * PostPayrollCsv API
 **/

export const handlers = [
  // PostPayrollCsv API
  rest.post(`${BASE_PATH}/payroll/csv`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        createdCount: 1000,
      })
    );
  }),
];
