import { computed, ref } from "vue";
import { defineStore } from "pinia";

/**
 * 保険者フォーム.
 */
export interface BaseInsured {
  firstName: string; // 被保険者苗字
  lastName: string; // 被保険者名前
  firstNameKana: string; // 苗字カナ
  lastNameKana: string; // 名前カナ
  dateOfBirth: string; // 生年月日
  dateOfBirthWareki: string; // 生年月日(和暦)
}

export type Insured = BaseInsured | Record<string, unknown>;

export const useContractReportInsuredStore = defineStore("insured", () => {
  const currentItem = ref<Insured>({
    firstName: "",
    lastName: "",
    firstNameKana: "",
    lastNameKana: "",
    dateOfBirth: "",
    dateOfBirthWareki: "",
  });

  const clearContractReports = () => {
    currentItem.value = {};
  };

  /**
   * 保険者.
   *
   * Destructure the state into a partial object
   * and declare a getter and a setter for it.
   */
  const insured = computed((): Insured => {
    return currentItem.value;
  });
  const setInsured = (insured: Insured) => {
    currentItem.value = insured;

    // Guard from the following error.
    // TypeError: Cannot read properties of undefined (reading 'dateOfBirth')
    // if (insured) currentItem.value.dateOfBirthWareki = insured.dateOfBirth;
  };

  return {
    currentItem,
    insured,
    setInsured,
    clearContractReports,
  };
});
