let hostname = "";
if (location) {
  hostname = location.hostname;
}

// The type of the shape of the cognito config object
export interface CognitoConfig {
  CognitoRegion: string;
  CognitoUserPool: string;
  CognitoUserPoolClient: string;
  CognitoDomainPrefix: string;
}

export function cognitoConfig(environment: string): CognitoConfig {
  if (environment === "production") {
    return {
      CognitoRegion: "ap-northeast-1",
      CognitoUserPool: "ap-northeast-1_w82A8GSUB",
      CognitoUserPoolClient: "47jdm8h5hgse7lg3sopg3rl0lu",
      CognitoDomainPrefix: "dic",
    };
  } else {
    return {
      CognitoRegion: "ap-northeast-1",
      CognitoUserPool: "ap-northeast-1_wbSWVqvip",
      CognitoUserPoolClient: "2b35ebehs49b67uo4a59338si",
      CognitoDomainPrefix: "dic-dev",
    };
  }
}

export const getRedirectUri = (hostname: string) => {
  const uriMapping: Record<string, string> = {
    localhost: "http://localhost:5173",
    "doit-core.link": "https://doit-core.link/",
    "doit-core.com": "https://doit-core.com/",
  };
  return uriMapping[hostname] ? uriMapping[hostname] : uriMapping["localhost"];
};

const {
  CognitoRegion,
  CognitoUserPool,
  CognitoUserPoolClient,
  CognitoDomainPrefix,
} = cognitoConfig(import.meta.env.VITE_ENV as string);

export const amplifyConfig = {
  Auth: {
    region: CognitoRegion,
    userPoolId: CognitoUserPool,
    userPoolWebClientId: CognitoUserPoolClient,
    oauth: {
      domain: `${CognitoDomainPrefix}.auth.${CognitoRegion}.amazoncognito.com`,
      scope: ["openid"],
      redirectSignIn: getRedirectUri(hostname),
      redirectSignOut: getRedirectUri(hostname),
      responseType: "code",
    },
  },
};
