import type { GetAdjustmentDeductionTypes200ResponseResultsInner } from "@/api";
import _ from "lodash";

export let adjustmentDeductionTypesData: GetAdjustmentDeductionTypes200ResponseResultsInner[] =
  [
    {
      adjustmentDeductionTypeAttribute: "adjustment",
      adjustmentDeductionTypeId: 1234567,
      adjustmentDeductionTypeName: "アシスタント費",
      adjustmentDeductionTermFromDefaultAddMonth: 1,
      adjustmentDeductionTermToDefaultAddMonth: 2,
      adjustmentDeductionFeeDefaultPlus: true,
      sortNumber: 101,
    },
    {
      adjustmentDeductionTypeAttribute: "adjustment",
      adjustmentDeductionTypeId: 1234568,
      adjustmentDeductionTypeName: "証券フォルダ",
      adjustmentDeductionTermFromDefaultAddMonth: 2,
      adjustmentDeductionTermToDefaultAddMonth: 3,
      adjustmentDeductionFeeDefaultPlus: false,
      sortNumber: 250,
    },
    {
      adjustmentDeductionTypeAttribute: "deduction",
      adjustmentDeductionTypeId: 1234569,
      adjustmentDeductionTypeName: "ポリシーホルダー",
      adjustmentDeductionTermFromDefaultAddMonth: 3,
      adjustmentDeductionTermToDefaultAddMonth: 4,
      adjustmentDeductionFeeDefaultPlus: true,
      sortNumber: 300,
    },
    {
      adjustmentDeductionTypeAttribute: "deduction",
      adjustmentDeductionTypeId: 1234570,
      adjustmentDeductionTypeName: "試験代",
      adjustmentDeductionTermFromDefaultAddMonth: 4,
      adjustmentDeductionTermToDefaultAddMonth: 5,
      adjustmentDeductionFeeDefaultPlus: false,
      sortNumber: 444,
    },
    {
      adjustmentDeductionTypeAttribute: "deduction",
      adjustmentDeductionTypeId: 1234571,
      adjustmentDeductionTypeName: "有価品控除",
      adjustmentDeductionTermFromDefaultAddMonth: 11,
      adjustmentDeductionTermToDefaultAddMonth: undefined,
      adjustmentDeductionFeeDefaultPlus: true,
      sortNumber: 505,
    },
  ];

// Replace function to use with mock Put requests.
export function replace(oldRecord: any, updatedRecord: any) {
  adjustmentDeductionTypesData = _.concat(
    _.filter(adjustmentDeductionTypesData, (record) => record !== oldRecord),
    updatedRecord
  );
  sortBySortNumber();
}

// function to sort the data by 'sortNumber' in ascending order
function sortBySortNumber(): void {
  adjustmentDeductionTypesData.sort((a, b) => a.sortNumber - b.sortNumber);
}

// add new data, while also sorting by sortNumber
export function addNewData(
  newData: GetAdjustmentDeductionTypes200ResponseResultsInner
): void {
  adjustmentDeductionTypesData.push(newData);
  sortBySortNumber();
}
