<script setup lang="ts">
import SearchFilter from "@/components/AdjustmentDeductionManagement/SearchFilter.vue";
import ResultTable from "@/components/AdjustmentDeductionManagement/ResultTable.vue";
import NoDataFound from "@/components/NoDataFound.vue";
import { useAdjustmentDeductionStore } from "@/stores/AdjustmentDeductionManagement/AdjustmentsDeductions";

import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";

// Screen Title
import ScreenTitle from "@/components/ScreenTitle.vue";
import { useLoggedInUserStore } from "@/stores/LoggedInUser";
import { useRouter } from "vue-router";

const store = useAdjustmentDeductionStore();

const { deductionsList: resultsOfAdjustmentDeductionsList } =
  storeToRefs(store);

// staff ID to pass to result table, to be used in routing.
const selectedStaff = ref();

onMounted(() => {
  store.clearDeductions();
  store.noDataFound = false;
});

const router = useRouter();

// reset noDataFound status when moving to another screen
router.beforeEach((to, from, next) => {
  // reset noDataFound status not to show no data found message in the next screen
  store.noDataFound = false;

  // reset each result list
  store.clearDeductions();
  next();
});
</script>

<template>
  <screen-title :screenId="13" modeKey="search" />
  <search-filter
    :user-id="useLoggedInUserStore().loggedInUserId"
    v-model:selectedStaff="selectedStaff"
  ></search-filter>
  <!-- <div v-if="store.noDataFound">対象の情報がありません</div> -->
  <no-data-found :noDataFound="store.noDataFound" />

  <result-table
    v-if="!store.noDataFound"
    :resultsOfAdjustmentDeductionsList="resultsOfAdjustmentDeductionsList"
    :selectedStaff="selectedStaff"
  ></result-table>
</template>
