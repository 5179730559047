import { useEnvironmentVars } from "@/composables/use-environment-vars";
import { PayrollApiFactory } from "@/api";
import axiosInstance from "@/utils/axios-customized-instance";
import { safeAsyncCall, safeAsyncCallAndRaiseError } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const { postPayrollCsv } = PayrollApiFactory(...args, undefined, axiosInstance);

export default function usePayrollService() {
  return {
    /**
     * This function calls PostPayrollCsv API with the csv file a user attaches.
     * @param args
     */
    importFile: safeAsyncCallAndRaiseError(
      async (...args: Parameters<typeof postPayrollCsv>) => {
        const res = await postPayrollCsv(...args);
        return res;
      }
    ),
  };
}
