import { rest } from "msw";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import { dummySecurities, replace } from "@/mocks/Securities/dummy-securities";
import type { PostSecuritiesRequest } from "@/api";
import randomInteger from "random-int";
import { useStaffQueryService } from "@/services/use-staff-query-service";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

export const handlers = [
  /**
   * GetSecurities
   */
  rest.get(`${BASE_PATH}/securities/:securitiesId`, (req, res, ctx) => {
    const securitiesId = req.params.securitiesId;

    const security = dummySecurities.find(
      (dummy) => dummy.securitiesId === Number(securitiesId)
    );

    if (!security) {
      return res(
        ctx.status(404),
        ctx.json({
          message: "Security not found",
        })
      );
    }

    return res(ctx.status(200), ctx.json(security));
  }),

  /**
   * PostSecurities API.
   */
  rest.post(`${BASE_PATH}/securities`, async (req, res, ctx) => {
    const request = await req.json();
    const newSecurity = await convert(request);

    dummySecurities.push(newSecurity);

    return res(ctx.text(String(newSecurity.securitiesId)));
  }),

  /**
   * PutSecurities API.
   */
  rest.put(`${BASE_PATH}/securities/:securitiesId`, async (req, res, ctx) => {
    const request = await req.json();
    const securitiesId = Number(req.params.securitiesId);
    const newSecurity = await convert(request, securitiesId);

    // finds a record with the matching ID
    const oldSecurity = dummySecurities.find(
      (security) => security.securitiesId === securitiesId
    );

    // replaces the old data with the new
    replace(oldSecurity, newSecurity);

    return res(ctx.status(204));
  }),

  /**
   * DeleteSecurities API.
   */
  rest.delete(
    `${BASE_PATH}/securities/:securitiesId`,
    async (req, res, ctx) => {
      // find the matching record
      const id = req.params.securitiesId;
      const matchRecord = dummySecurities.find(
        (record) => record.securitiesId === Number(id)
      );

      // get index from the record
      const index = dummySecurities.indexOf(matchRecord);

      // if possible splice out the record, or give error message
      if (index > -1) {
        dummySecurities.splice(index, 1);
        return res(ctx.status(204));
      } else {
        return res(ctx.json("No record found."));
      }
    }
  ),
];

/**
 * Converts PostSecuritiesRequest to dummy data, including full current staff information.
 */

const convert = async (
  request: PostSecuritiesRequest,
  securitiesId?: number
) => {
  const staffInfo = await useStaffQueryService().findByCurrent(request.staffId);

  const staff = {
    firstName: staffInfo.personal.firstName,
    lastName: staffInfo.personal.lastName,
    staffId: request.staffId,
    staffNumber: staffInfo.staffNumber,
    departmentName: staffInfo.departmentAffiliation.departmentName,
    departmentId: staffInfo.departmentAffiliation.departmentId,
  };
  return {
    securitiesId: securitiesId
      ? Number(securitiesId)
      : randomInteger(1000000, 9999999),
    insuranceCategory: request.insuranceCategory,
    contractorAttribute: request.contractorAttribute,
    contractReportId: request.contractReportId ?? undefined,
    potentialCustomerId: request.potentialCustomerId ?? undefined,
    contractorIndividual: request.contractorIndividual,
    contractorCorporation: request.contractorCorporation,
    insured: request.insured,
    insuranceInfo: request.insuranceInfo,
    lifeInsuranceInfo: request.lifeInsuranceInfo,
    nonLifeInsuranceInfo: request.nonLifeInsuranceInfo,
    commissionShare: request.commissionShare,
    incentiveShare: request.incentiveShare,
    staffId: request.staffId,
    staff,
    potentialCustomerInfo: request.potentialCustomerInfo,
  };
};
