import { rest } from "msw";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import { baseDepartments } from "@/mocks/Department/dummy-departs";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

const departmentsQueryResults = (result: any) => {
  const results = result.map((department: any) => {
    const boss = department.boss?.[0];
    return {
      departmentId: department.departmentId,
      departmentName: department.departmentName,
      boss: boss
        ? {
            staffId: boss.staffId,
            staffNumber: boss.staffNumber,
            firstName: boss.firstName,
            lastName: boss.lastName,
          }
        : undefined,
      sortNumber: department.sortNumber,
    };
  });
  return results;
};

export const handlers = [
  /*
   * DepartmentQuery
  GetDepartments
   */
  rest.get(`${BASE_PATH}/department`, async (req, res, ctx) => {
    const departmentName = req.url.searchParams.get("departmentName");
    let filteredDepartments: any[] = baseDepartments;

    if (departmentName) {
      filteredDepartments = baseDepartments.filter((department) =>
        department.departmentName.includes(departmentName as string)
      );
    }

    if (filteredDepartments.length > 0) {
      filteredDepartments = departmentsQueryResults(filteredDepartments);
    }

    return res(
      ctx.status(200),
      ctx.json({
        results: filteredDepartments,
        pagination: {
          totalCount: 0,
        },
      })
    );
  }),
];
