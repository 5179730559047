import _ from "lodash";

export function replace(oldRecord: any, newRecord: any) {
  dummyData = _.concat(
    _.filter(dummyData, (department) => department !== oldRecord),
    newRecord
  );
}

const defaultDummyData = [
  {
    insuranceCompanyStaffId: "123456A",
    insuranceCompany: {
      insuranceCompanyId: 1234567,
      insuranceCompanyName: "アイティーソリューションズ生命",
    },
    staff: {
      staffId: 7777,
      staffNumber: "7777",
      firstName: "Valerie",
      lastName: "Yamanaka",
    },
  },
  {
    insuranceCompanyStaffId: "123456BB",
    insuranceCompany: {
      insuranceCompanyId: 1234567,
      insuranceCompanyName: "第一生命",
    },
    staff: {
      staffId: 69736,
      staffNumber: "69736",
      firstName: "ハルト",
      lastName: "田中",
    },
  },
];

export let dummyData = defaultDummyData;

export const initializeDummyData = () => {
  dummyData = _.cloneDeep(defaultDummyData);
};
