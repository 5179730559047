/* tslint:disable */
/* eslint-disable */
/**
 * doit-core APIs
 * doit-coreのバックエンドAPIです。
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, {
  type AxiosPromise,
  type AxiosInstance,
  type AxiosRequestConfig,
} from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  type RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 * 調整控除項目属性
 * @export
 * @enum {string}
 */

export const AdjustmentDeductionTypeAttribute = {
  Adjustment: "adjustment",
  Deduction: "deduction",
  DeductionForValuables: "deductionForValuables",
  MarketDeduction: "marketDeduction",
  PerformanceBasedPayTransfer: "performanceBasedPayTransfer",
} as const;

export type AdjustmentDeductionTypeAttribute =
  (typeof AdjustmentDeductionTypeAttribute)[keyof typeof AdjustmentDeductionTypeAttribute];

/**
 * API名
 * @export
 * @enum {string}
 */

export const ApiName = {
  PostStaff: "PostStaff",
  PutStaff: "PutStaff",
  PutStaffPassword: "PutStaffPassword",
  PutStaffAccountActive: "PutStaffAccountActive",
  PostStaffStandardMonthlyRemunerationCsv:
    "PostStaffStandardMonthlyRemunerationCsv",
  PostMarket: "PostMarket",
  PutMarket: "PutMarket",
  PostInsuranceCompany: "PostInsuranceCompany",
  PutInsuranceCompany: "PutInsuranceCompany",
  PostInsuranceType: "PostInsuranceType",
  PutInsuranceType: "PutInsuranceType",
  PostPotentialCustomer: "PostPotentialCustomer",
  PutPotentialCustomer: "PutPotentialCustomer",
  DeletePotentialCustomer: "DeletePotentialCustomer",
  PostDepartment: "PostDepartment",
  PutDepartment: "PutDepartment",
  PostAdjustmentDeduction: "PostAdjustmentDeduction",
  PutAdjustmentDeduction: "PutAdjustmentDeduction",
  DeleteAdjustmentDeduction: "DeleteAdjustmentDeduction",
  GetAdjustmentDeductionCsv: "GetAdjustmentDeductionCsv",
  PostAdjustmentDeductionCsv: "PostAdjustmentDeductionCsv",
  PostAdjustmentDeductionIncentiveCsv: "PostAdjustmentDeductionIncentiveCsv",
  PostAdjustmentDeductionType: "PostAdjustmentDeductionType",
  PutAdjustmentDeductionType: "PutAdjustmentDeductionType",
  PostContractReport: "PostContractReport",
  PutContractReport: "PutContractReport",
  DeleteContractReport: "DeleteContractReport",
  GetContractReportLifeInsuranceCsv: "GetContractReportLifeInsuranceCsv",
  GetContractReportNonLifeInsuranceCsv: "GetContractReportNonLifeInsuranceCsv",
  PostSecurities: "PostSecurities",
  PutSecurities: "PutSecurities",
  DeleteSecurities: "DeleteSecurities",
  PostSecuritiesCommissionMatching: "PostSecuritiesCommissionMatching",
  PutSecuritiesCommissionMatching: "PutSecuritiesCommissionMatching",
  GetSecuritiesCsv: "GetSecuritiesCsv",
  PostSecuritiesCsv: "PostSecuritiesCsv",
  PostSecuritiesShareCsv: "PostSecuritiesShareCsv",
  PutCommissionFormat: "PutCommissionFormat",
  PostCommissionCsv: "PostCommissionCsv",
  GetCommissionsCsv: "GetCommissionsCsv",
  DeleteCommissionImport: "DeleteCommissionImport",
  PutCommissionCalculation: "PutCommissionCalculation",
  GetCommissionCalculationResultsCsv: "GetCommissionCalculationResultsCsv",
  PutPerformanceBasedPayCalculation: "PutPerformanceBasedPayCalculation",
  PostPurchasePotentialCustomer: "PostPurchasePotentialCustomer",
  PutPurchasePotentialCustomer: "PutPurchasePotentialCustomer",
  PostPayrollCsv: "PostPayrollCsv",
  PutStaffMap: "PutStaffMap",
  DeleteStaffMap: "DeleteStaffMap",
  GetPerformanceBasedPayPayrollCsv: "GetPerformanceBasedPayPayrollCsv",
} as const;

export type ApiName = (typeof ApiName)[keyof typeof ApiName];

/**
 * 手数料区分
 * @export
 * @enum {string}
 */

export const CommissionCategory = {
  Commission: "commission",
  Incentive: "incentive",
} as const;

export type CommissionCategory =
  (typeof CommissionCategory)[keyof typeof CommissionCategory];

/**
 * 手数料取込ステータス
 * @export
 * @enum {string}
 */

export const CommissionImportStatus = {
  CommissionNotCalculated: "commissionNotCalculated",
  CommissionCalculated: "commissionCalculated",
  PerformanceBasedPayCalculated: "performanceBasedPayCalculated",
  Deleted: "deleted",
} as const;

export type CommissionImportStatus =
  (typeof CommissionImportStatus)[keyof typeof CommissionImportStatus];

/**
 * <dl>   <dt>未設定：申達報告日（降順）</dt>   <dt>staff：担当者（社員番号昇順）、申達報告日（降順）</dt>   <dt>insuranceCompany：保険会社（保険会社のソート昇順）、申達報告日（降順）</dt>   <dt>market：マーケット（マーケットのソート昇順で最後に自己開拓）、申達報告日（降順）</dt> </dl>
 * @export
 * @enum {string}
 */

export const ContractReportSort = {
  Staff: "staff",
  InsuranceCompany: "insuranceCompany",
  Market: "market",
} as const;

export type ContractReportSort =
  (typeof ContractReportSort)[keyof typeof ContractReportSort];

/**
 * 契約者の属性
 * @export
 * @enum {string}
 */

export const ContractorAttribute = {
  Corporation: "corporation",
  Individual: "individual",
} as const;

export type ContractorAttribute =
  (typeof ContractorAttribute)[keyof typeof ContractorAttribute];

/**
 * FP資格
 * @export
 * @enum {string}
 */

export const FpQualification = {
  NoQualification: "noQualification",
  FirstClass: "firstClass",
  SecondClass: "secondClass",
  ThirdClass: "thirdClass",
} as const;

export type FpQualification =
  (typeof FpQualification)[keyof typeof FpQualification];

/**
 *
 * @export
 * @interface GetAdjustmentDeductionTypes200Response
 */
export interface GetAdjustmentDeductionTypes200Response {
  /**
   * 調整控除項目一覧
   * @type {Array<GetAdjustmentDeductionTypes200ResponseResultsInner>}
   * @memberof GetAdjustmentDeductionTypes200Response
   */
  results: Array<GetAdjustmentDeductionTypes200ResponseResultsInner>;
}
/**
 *
 * @export
 * @interface GetAdjustmentDeductionTypes200ResponseResultsInner
 */
export interface GetAdjustmentDeductionTypes200ResponseResultsInner {
  /**
   *
   * @type {AdjustmentDeductionTypeAttribute}
   * @memberof GetAdjustmentDeductionTypes200ResponseResultsInner
   */
  adjustmentDeductionTypeAttribute: AdjustmentDeductionTypeAttribute;
  /**
   * 調整控除項目ID
   * @type {number}
   * @memberof GetAdjustmentDeductionTypes200ResponseResultsInner
   */
  adjustmentDeductionTypeId: number;
  /**
   * 調整控除項目名
   * @type {string}
   * @memberof GetAdjustmentDeductionTypes200ResponseResultsInner
   */
  adjustmentDeductionTypeName: string;
  /**
   * 調整控除期間Fromのデフォルト月(現在月に対する加算月)
   * @type {number}
   * @memberof GetAdjustmentDeductionTypes200ResponseResultsInner
   */
  adjustmentDeductionTermFromDefaultAddMonth: number;
  /**
   * 調整控除期間Toのデフォルト月(現在月に対する加算月)
   * @type {number}
   * @memberof GetAdjustmentDeductionTypes200ResponseResultsInner
   */
  adjustmentDeductionTermToDefaultAddMonth?: number;
  /**
   * 調整控除費の正負のデフォルト（true：正、false：負）
   * @type {boolean}
   * @memberof GetAdjustmentDeductionTypes200ResponseResultsInner
   */
  adjustmentDeductionFeeDefaultPlus: boolean;
  /**
   * 表示順
   * @type {number}
   * @memberof GetAdjustmentDeductionTypes200ResponseResultsInner
   */
  sortNumber: number;
}
/**
 *
 * @export
 * @interface GetAdjustmentDeductions200Response
 */
export interface GetAdjustmentDeductions200Response {
  /**
   * 調整控除一覧
   * @type {Array<GetAdjustmentDeductions200ResponseResultsInner>}
   * @memberof GetAdjustmentDeductions200Response
   */
  results: Array<GetAdjustmentDeductions200ResponseResultsInner>;
  /**
   *
   * @type {Pagination}
   * @memberof GetAdjustmentDeductions200Response
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface GetAdjustmentDeductions200ResponseResultsInner
 */
export interface GetAdjustmentDeductions200ResponseResultsInner {
  /**
   * 調整控除ID
   * @type {number}
   * @memberof GetAdjustmentDeductions200ResponseResultsInner
   */
  adjustmentDeductionId: number;
  /**
   * 調整控除項目ID
   * @type {number}
   * @memberof GetAdjustmentDeductions200ResponseResultsInner
   */
  adjustmentDeductionTypeId: number;
  /**
   * 調整控除項目名
   * @type {string}
   * @memberof GetAdjustmentDeductions200ResponseResultsInner
   */
  adjustmentDeductionTypeName: string;
  /**
   * 調整控除名
   * @type {string}
   * @memberof GetAdjustmentDeductions200ResponseResultsInner
   */
  adjustmentDeductionName: string;
  /**
   * 調整控除費
   * @type {number}
   * @memberof GetAdjustmentDeductions200ResponseResultsInner
   */
  adjustmentDeductionFee: number;
  /**
   *
   * @type {GetAdjustmentDeductions200ResponseResultsInnerAdjustmentDeductionTerm}
   * @memberof GetAdjustmentDeductions200ResponseResultsInner
   */
  adjustmentDeductionTerm: GetAdjustmentDeductions200ResponseResultsInnerAdjustmentDeductionTerm;
  /**
   *
   * @type {GetAdjustmentDeductions200ResponseResultsInnerStaff}
   * @memberof GetAdjustmentDeductions200ResponseResultsInner
   */
  staff: GetAdjustmentDeductions200ResponseResultsInnerStaff;
}
/**
 *
 * @export
 * @interface GetAdjustmentDeductions200ResponseResultsInnerAdjustmentDeductionTerm
 */
export interface GetAdjustmentDeductions200ResponseResultsInnerAdjustmentDeductionTerm {
  /**
   * 調整控除期間
   * @type {string}
   * @memberof GetAdjustmentDeductions200ResponseResultsInnerAdjustmentDeductionTerm
   */
  adjustmentDeductionTermFrom: string;
  /**
   * 調整控除期間
   * @type {string}
   * @memberof GetAdjustmentDeductions200ResponseResultsInnerAdjustmentDeductionTerm
   */
  adjustmentDeductionTermTo?: string;
}
/**
 *
 * @export
 * @interface GetAdjustmentDeductions200ResponseResultsInnerStaff
 */
export interface GetAdjustmentDeductions200ResponseResultsInnerStaff {
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetAdjustmentDeductions200ResponseResultsInnerStaff
   */
  staffId: number;
  /**
   * 社員番号
   * @type {string}
   * @memberof GetAdjustmentDeductions200ResponseResultsInnerStaff
   */
  staffNumber: string;
  /**
   * 名前
   * @type {string}
   * @memberof GetAdjustmentDeductions200ResponseResultsInnerStaff
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetAdjustmentDeductions200ResponseResultsInnerStaff
   */
  lastName: string;
  /**
   * 所属名
   * @type {string}
   * @memberof GetAdjustmentDeductions200ResponseResultsInnerStaff
   */
  departmentName: string;
  /**
   * 所属ID
   * @type {number}
   * @memberof GetAdjustmentDeductions200ResponseResultsInnerStaff
   */
  departmentId: number;
}
/**
 *
 * @export
 * @interface GetCommissionCalculationResults200Response
 */
export interface GetCommissionCalculationResults200Response {
  /**
   * 手数料計算結果一覧
   * @type {Array<GetCommissionCalculationResults200ResponseResultsInner>}
   * @memberof GetCommissionCalculationResults200Response
   */
  results: Array<GetCommissionCalculationResults200ResponseResultsInner>;
  /**
   *
   * @type {Pagination}
   * @memberof GetCommissionCalculationResults200Response
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface GetCommissionCalculationResults200ResponseResultsInner
 */
export interface GetCommissionCalculationResults200ResponseResultsInner {
  /**
   *
   * @type {GetCommissionCalculationResults200ResponseResultsInnerCommissionImport}
   * @memberof GetCommissionCalculationResults200ResponseResultsInner
   */
  commissionImport: GetCommissionCalculationResults200ResponseResultsInnerCommissionImport;
  /**
   *
   * @type {GetCommissionCalculationResults200ResponseResultsInnerSecurities}
   * @memberof GetCommissionCalculationResults200ResponseResultsInner
   */
  securities: GetCommissionCalculationResults200ResponseResultsInnerSecurities;
  /**
   *
   * @type {GetCommissionCalculationResults200ResponseResultsInnerCommission}
   * @memberof GetCommissionCalculationResults200ResponseResultsInner
   */
  commission: GetCommissionCalculationResults200ResponseResultsInnerCommission;
}
/**
 * <dl>    <dt>手数料の取分</dt>      <dt>myself、boss、otherStaff1、otherStaff2、otherStaff3、marketの設定</dt>   <dd>リクエストのstaffIdとmyselfのstaffIdが同じ場合（自分が担当の場合）</dd>   <dd>     <ul>       <li>all、myself、boss、otherStaff1、otherStaff2、otherStaff3、marketの全てを設定</li>     </ul>   </dd>   <dd>リクエストのstaffIdとmyselfのstaffIdが異なる場合（自分がbossやotherStaffの場合）</dd>   <dd>     <ul>       <li>allは設定</li>       <li>myselfは、staffId・lastName・firstNameのみ設定（手数料や取分は未設定）</li>       <li>リクエストのstaffIdに該当するboss、otherStaff1、otherStaff2、otherStaff3のみ設定</li>       <li>marketは未設定</li>     </ul>   </dd>  </dl>
 * @export
 * @interface GetCommissionCalculationResults200ResponseResultsInnerCommission
 */
export interface GetCommissionCalculationResults200ResponseResultsInnerCommission {
  /**
   *
   * @type {GetCommissionCalculationResults200ResponseResultsInnerCommissionAll}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommission
   */
  all: GetCommissionCalculationResults200ResponseResultsInnerCommissionAll;
  /**
   *
   * @type {GetCommissionCalculationResults200ResponseResultsInnerCommissionMyself}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommission
   */
  myself: GetCommissionCalculationResults200ResponseResultsInnerCommissionMyself;
  /**
   *
   * @type {GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommission
   */
  boss?: GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss;
  /**
   *
   * @type {GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommission
   */
  otherStaff1?: GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss;
  /**
   *
   * @type {GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommission
   */
  otherStaff2?: GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss;
  /**
   *
   * @type {GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommission
   */
  otherStaff3?: GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss;
  /**
   *
   * @type {GetCommissionCalculationResults200ResponseResultsInnerCommissionMarket}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommission
   */
  market?: GetCommissionCalculationResults200ResponseResultsInnerCommissionMarket;
}
/**
 *
 * @export
 * @interface GetCommissionCalculationResults200ResponseResultsInnerCommissionAll
 */
export interface GetCommissionCalculationResults200ResponseResultsInnerCommissionAll {
  /**
   *
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionAll
   */
  commission: number;
  /**
   *
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionAll
   */
  consumptionTax: number;
}
/**
 *
 * @export
 * @interface GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss
 */
export interface GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss {
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss
   */
  staffId: number;
  /**
   * 社員番号
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss
   */
  staffNumber: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss
   */
  lastName: string;
  /**
   * 名前
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss
   */
  firstName: string;
  /**
   * <dl>    <dt>取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss
   */
  share: string;
  /**
   *
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss
   */
  commission: number;
  /**
   *
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionBoss
   */
  consumptionTax: number;
}
/**
 *
 * @export
 * @interface GetCommissionCalculationResults200ResponseResultsInnerCommissionImport
 */
export interface GetCommissionCalculationResults200ResponseResultsInnerCommissionImport {
  /**
   * 手数料取込ID
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionImport
   */
  commissionImportId: number;
  /**
   * 保険会社ID
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionImport
   */
  insuranceCompanyId: number;
  /**
   * 保険会社名
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionImport
   */
  insuranceCompanyName: string;
  /**
   *
   * @type {CommissionCategory}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionImport
   */
  commissionCategory: CommissionCategory;
}
/**
 *
 * @export
 * @interface GetCommissionCalculationResults200ResponseResultsInnerCommissionMarket
 */
export interface GetCommissionCalculationResults200ResponseResultsInnerCommissionMarket {
  /**
   * マーケットID
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionMarket
   */
  marketId: number;
  /**
   * マーケット名
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionMarket
   */
  marketName: string;
  /**
   * <dl>    <dt>取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionMarket
   */
  share: string;
  /**
   *
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionMarket
   */
  commission: number;
  /**
   *
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionMarket
   */
  consumptionTax: number;
}
/**
 *
 * @export
 * @interface GetCommissionCalculationResults200ResponseResultsInnerCommissionMyself
 */
export interface GetCommissionCalculationResults200ResponseResultsInnerCommissionMyself {
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionMyself
   */
  staffId: number;
  /**
   * 社員番号
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionMyself
   */
  staffNumber: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionMyself
   */
  lastName: string;
  /**
   * 名前
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionMyself
   */
  firstName: string;
  /**
   * <dl>    <dt>取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionMyself
   */
  share?: string;
  /**
   *
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionMyself
   */
  commission?: number;
  /**
   *
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerCommissionMyself
   */
  consumptionTax?: number;
}
/**
 *
 * @export
 * @interface GetCommissionCalculationResults200ResponseResultsInnerSecurities
 */
export interface GetCommissionCalculationResults200ResponseResultsInnerSecurities {
  /**
   * 証券ID
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecurities
   */
  securitiesId: number;
  /**
   * 証券番号
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecurities
   */
  policyNumber: string;
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecurities
   */
  staffId: number;
  /**
   *
   * @type {InsuranceCategory}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecurities
   */
  insuranceCategory: InsuranceCategory;
  /**
   *
   * @type {ContractorAttribute}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecurities
   */
  contractorAttribute: ContractorAttribute;
  /**
   * 初回支払月
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecurities
   */
  firstPaymentMonth: string;
  /**
   *
   * @type {GetCommissionCalculationResults200ResponseResultsInnerSecuritiesContractorIndividual}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecurities
   */
  contractorIndividual?: GetCommissionCalculationResults200ResponseResultsInnerSecuritiesContractorIndividual;
  /**
   *
   * @type {GetCommissionCalculationResults200ResponseResultsInnerSecuritiesContractorCorporation}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecurities
   */
  contractorCorporation?: GetCommissionCalculationResults200ResponseResultsInnerSecuritiesContractorCorporation;
  /**
   *
   * @type {GetCommissionCalculationResults200ResponseResultsInnerSecuritiesInsured}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecurities
   */
  insured?: GetCommissionCalculationResults200ResponseResultsInnerSecuritiesInsured;
  /**
   *
   * @type {GetCommissionCalculationResults200ResponseResultsInnerSecuritiesInsuranceInfo}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecurities
   */
  insuranceInfo?: GetCommissionCalculationResults200ResponseResultsInnerSecuritiesInsuranceInfo;
}
/**
 * <dl>   <dt>contractorAttributeがcorporationの場合に設定される契約者の情報</dt> </dl>
 * @export
 * @interface GetCommissionCalculationResults200ResponseResultsInnerSecuritiesContractorCorporation
 */
export interface GetCommissionCalculationResults200ResponseResultsInnerSecuritiesContractorCorporation {
  /**
   * 法人名
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecuritiesContractorCorporation
   */
  corporationName?: string;
}
/**
 * <dl>   <dt>contractorAttributeがindividualの場合に設定される契約者の情報</dt> </dl>
 * @export
 * @interface GetCommissionCalculationResults200ResponseResultsInnerSecuritiesContractorIndividual
 */
export interface GetCommissionCalculationResults200ResponseResultsInnerSecuritiesContractorIndividual {
  /**
   * 名前
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecuritiesContractorIndividual
   */
  firstName?: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecuritiesContractorIndividual
   */
  lastName?: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecuritiesContractorIndividual
   */
  firstNameKana?: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecuritiesContractorIndividual
   */
  lastNameKana?: string;
}
/**
 * 生保・損保ともに共通する保険の情報
 * @export
 * @interface GetCommissionCalculationResults200ResponseResultsInnerSecuritiesInsuranceInfo
 */
export interface GetCommissionCalculationResults200ResponseResultsInnerSecuritiesInsuranceInfo {
  /**
   * 保険種類ID
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecuritiesInsuranceInfo
   */
  insuranceTypeId?: number;
  /**
   * 保険種類名
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecuritiesInsuranceInfo
   */
  insuranceTypeName?: string;
  /**
   * 保険料
   * @type {number}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecuritiesInsuranceInfo
   */
  insuranceFee?: number;
  /**
   *
   * @type {PaymentMethod}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecuritiesInsuranceInfo
   */
  paymentMethod?: PaymentMethod;
}
/**
 * 被保険者の情報
 * @export
 * @interface GetCommissionCalculationResults200ResponseResultsInnerSecuritiesInsured
 */
export interface GetCommissionCalculationResults200ResponseResultsInnerSecuritiesInsured {
  /**
   * 名前
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecuritiesInsured
   */
  firstName?: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecuritiesInsured
   */
  lastName?: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecuritiesInsured
   */
  firstNameKana?: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof GetCommissionCalculationResults200ResponseResultsInnerSecuritiesInsured
   */
  lastNameKana?: string;
}
/**
 *
 * @export
 * @interface GetCommissionFormat200Response
 */
export interface GetCommissionFormat200Response {
  /**
   * 内税フラグ
   * @type {boolean}
   * @memberof GetCommissionFormat200Response
   */
  consumptionTaxIncludeFlag: boolean;
  /**
   *
   * @type {GetCommissionFormat200ResponseColumnMapping}
   * @memberof GetCommissionFormat200Response
   */
  columnMapping: GetCommissionFormat200ResponseColumnMapping;
}
/**
 * <dl>    <dt>手数料CSVのヘッダ名とシステムの手数料項目のマッピング情報</dt>   <dt>XXXColumnに手数料CSVのヘッダ名が設定される</dt>  </dl>
 * @export
 * @interface GetCommissionFormat200ResponseColumnMapping
 */
export interface GetCommissionFormat200ResponseColumnMapping {
  /**
   * 証券番号カラム
   * @type {string}
   * @memberof GetCommissionFormat200ResponseColumnMapping
   */
  policyNumberColumn: string;
  /**
   * 契約者カラム
   * @type {string}
   * @memberof GetCommissionFormat200ResponseColumnMapping
   */
  contractorColumn: string;
  /**
   * 被保険者カラム
   * @type {string}
   * @memberof GetCommissionFormat200ResponseColumnMapping
   */
  insuredColumn?: string;
  /**
   * 手数料カラム
   * @type {string}
   * @memberof GetCommissionFormat200ResponseColumnMapping
   */
  commissionColumn: string;
  /**
   * 保険料カラム
   * @type {string}
   * @memberof GetCommissionFormat200ResponseColumnMapping
   */
  insuranceFeeColumn?: string;
  /**
   * 担当者カラム
   * @type {string}
   * @memberof GetCommissionFormat200ResponseColumnMapping
   */
  staffColumn?: string;
  /**
   * 担当者コードカラム
   * @type {string}
   * @memberof GetCommissionFormat200ResponseColumnMapping
   */
  staffCodeColumn?: string;
  /**
   * 保険種類カラム
   * @type {string}
   * @memberof GetCommissionFormat200ResponseColumnMapping
   */
  insuranceTypeColumn?: string;
  /**
   * 契約日カラム
   * @type {string}
   * @memberof GetCommissionFormat200ResponseColumnMapping
   */
  contractDateColumn?: string;
}
/**
 *
 * @export
 * @interface GetCommissionInsuranceCompanyCalculationResults200Response
 */
export interface GetCommissionInsuranceCompanyCalculationResults200Response {
  /**
   *
   * @type {GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200Response
   */
  staff: GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff;
  /**
   * <dl>    <dt>生命保険の保険会社毎の手数料合計額。</dt>   <dt>insuranceCategoryが lifeInsurance の保険会社を集計します。</dt>      <dt>ソート順</dt>   <dd>保険会社の表示順</dd>  </dl>
   * @type {Array<GetCommissionInsuranceCompanyCalculationResults200ResponseLifeInsuranceCommissionsInner>}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200Response
   */
  lifeInsuranceCommissions: Array<GetCommissionInsuranceCompanyCalculationResults200ResponseLifeInsuranceCommissionsInner>;
  /**
   * <dl>    <dt>損害保険の保険会社毎の手数料合計額。</dt>   <dt>insuranceCategoryが nonLifeInsurance の保険会社を集計します。</dt>      <dt>ソート順</dt>   <dd>保険会社の表示順</dd>  </dl>
   * @type {Array<GetCommissionInsuranceCompanyCalculationResults200ResponseLifeInsuranceCommissionsInner>}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200Response
   */
  nonLifeInsuranceCommissions: Array<GetCommissionInsuranceCompanyCalculationResults200ResponseLifeInsuranceCommissionsInner>;
  /**
   *
   * @type {GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommission}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200Response
   */
  totalCommission: GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommission;
}
/**
 *
 * @export
 * @interface GetCommissionInsuranceCompanyCalculationResults200ResponseLifeInsuranceCommissionsInner
 */
export interface GetCommissionInsuranceCompanyCalculationResults200ResponseLifeInsuranceCommissionsInner {
  /**
   * 保険会社ID
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseLifeInsuranceCommissionsInner
   */
  insuranceCompanyId: number;
  /**
   * 保険会社名
   * @type {string}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseLifeInsuranceCommissionsInner
   */
  insuranceCompanyName: string;
  /**
   * <dl>    <dt>初年度分の手数料の合計値（消費税込み）</dt>      <dt>条件</dt>   <dd>     <ul>       <li>commissionCategoryがcommission</li>       <li>firstYearFlagがtrue（初年度）</li>     </ul>   </dd>  </dl>
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseLifeInsuranceCommissionsInner
   */
  firstYearCommission: number;
  /**
   * <dl>    <dt>継続（初年度以外）分の手数料の合計値（消費税込み）</dt>      <dt>条件</dt>   <dd>     <ul>       <li>commissionCategoryがcommission</li>       <li>firstYearFlagがfalse</li>     </ul>   </dd>  </dl>
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseLifeInsuranceCommissionsInner
   */
  continuationCommission: number;
  /**
   * <dl>    <dt>インセンティブ分の手数料の合計値（消費税込み）</dt>      <dt>条件</dt>   <dd>     <ul>       <li>commissionCategoryがincentive</li>     </ul>   </dd>  </dl>
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseLifeInsuranceCommissionsInner
   */
  incentiveCommission: number;
  /**
   * <dl>    <dt>初年度分の手数料の消費税合計値</dt>      <dt>条件</dt>   <dd>     <ul>       <li>commissionCategoryがcommission</li>       <li>firstYearFlagがtrue（初年度）</li>     </ul>   </dd>  </dl>
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseLifeInsuranceCommissionsInner
   */
  firstYearConsumptionTax: number;
  /**
   * <dl>    <dt>継続（初年度以外）分の手数料の消費税合計値</dt>      <dt>条件</dt>   <dd>     <ul>       <li>commissionCategoryがcommission</li>       <li>firstYearFlagがfalse</li>     </ul>   </dd>  </dl>
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseLifeInsuranceCommissionsInner
   */
  continuationConsumptionTax: number;
  /**
   * <dl>    <dt>インセンティブ分の手数料の消費税合計値</dt>      <dt>条件</dt>   <dd>     <ul>       <li>commissionCategoryがincentive</li>     </ul>   </dd>  </dl>
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseLifeInsuranceCommissionsInner
   */
  incentiveConsumptionTax: number;
}
/**
 * 手数料の合計額
 * @export
 * @interface GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommission
 */
export interface GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommission {
  /**
   *
   * @type {GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionTotal}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommission
   */
  total: GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionTotal;
  /**
   *
   * @type {GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionLifeInsuranceCommission}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommission
   */
  lifeInsuranceCommission: GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionLifeInsuranceCommission;
  /**
   *
   * @type {GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionNonLifeInsuranceCommission}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommission
   */
  nonLifeInsuranceCommission: GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionNonLifeInsuranceCommission;
}
/**
 * 生命保険の保険会社の合計額
 * @export
 * @interface GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionLifeInsuranceCommission
 */
export interface GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionLifeInsuranceCommission {
  /**
   * firstYearCommissionの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionLifeInsuranceCommission
   */
  firstYearCommission: number;
  /**
   * continuationCommissionの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionLifeInsuranceCommission
   */
  continuationCommission: number;
  /**
   * incentiveCommissionの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionLifeInsuranceCommission
   */
  incentiveCommission: number;
  /**
   * firstYearConsumptionTaxの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionLifeInsuranceCommission
   */
  firstYearConsumptionTax: number;
  /**
   * continuationConsumptionTaxの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionLifeInsuranceCommission
   */
  continuationConsumptionTax: number;
  /**
   * incentiveConsumptionTaxの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionLifeInsuranceCommission
   */
  incentiveConsumptionTax: number;
}
/**
 * 損害保険の保険会社の合計額
 * @export
 * @interface GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionNonLifeInsuranceCommission
 */
export interface GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionNonLifeInsuranceCommission {
  /**
   * firstYearCommissionの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionNonLifeInsuranceCommission
   */
  firstYearCommission: number;
  /**
   * continuationCommissionの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionNonLifeInsuranceCommission
   */
  continuationCommission: number;
  /**
   * incentiveCommissionの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionNonLifeInsuranceCommission
   */
  incentiveCommission: number;
  /**
   * firstYearConsumptionTaxの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionNonLifeInsuranceCommission
   */
  firstYearConsumptionTax: number;
  /**
   * continuationConsumptionTaxの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionNonLifeInsuranceCommission
   */
  continuationConsumptionTax: number;
  /**
   * incentiveConsumptionTaxの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionNonLifeInsuranceCommission
   */
  incentiveConsumptionTax: number;
}
/**
 * 全体の合計額
 * @export
 * @interface GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionTotal
 */
export interface GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionTotal {
  /**
   * firstYearCommissionの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionTotal
   */
  firstYearCommission: number;
  /**
   * continuationCommissionの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionTotal
   */
  continuationCommission: number;
  /**
   * incentiveCommissionの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionTotal
   */
  incentiveCommission: number;
  /**
   * firstYearConsumptionTaxの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionTotal
   */
  firstYearConsumptionTax: number;
  /**
   * continuationConsumptionTaxの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionTotal
   */
  continuationConsumptionTax: number;
  /**
   * incentiveConsumptionTaxの合計額
   * @type {number}
   * @memberof GetCommissionInsuranceCompanyCalculationResults200ResponseTotalCommissionTotal
   */
  incentiveConsumptionTax: number;
}
/**
 *
 * @export
 * @interface GetCommissionStatus200Response
 */
export interface GetCommissionStatus200Response {
  /**
   * 手数料状況一覧
   * @type {Array<GetCommissionStatus200ResponseResultsInner>}
   * @memberof GetCommissionStatus200Response
   */
  results: Array<GetCommissionStatus200ResponseResultsInner>;
}
/**
 *
 * @export
 * @interface GetCommissionStatus200ResponseResultsInner
 */
export interface GetCommissionStatus200ResponseResultsInner {
  /**
   * 手数料取込ID
   * @type {number}
   * @memberof GetCommissionStatus200ResponseResultsInner
   */
  commissionImportId: number;
  /**
   * 保険会社ID
   * @type {number}
   * @memberof GetCommissionStatus200ResponseResultsInner
   */
  insuranceCompanyId: number;
  /**
   * 保険会社名
   * @type {string}
   * @memberof GetCommissionStatus200ResponseResultsInner
   */
  insuranceCompanyName: string;
  /**
   *
   * @type {InsuranceCategory}
   * @memberof GetCommissionStatus200ResponseResultsInner
   */
  insuranceCategory: InsuranceCategory;
  /**
   *
   * @type {CommissionCategory}
   * @memberof GetCommissionStatus200ResponseResultsInner
   */
  commissionCategory: CommissionCategory;
  /**
   * 手数料取込毎の取込件数
   * @type {number}
   * @memberof GetCommissionStatus200ResponseResultsInner
   */
  commissionRecordCount: number;
  /**
   * <dl>   <dt>手数料取込毎の手数料マッチング済件数</dt>   <dd>手数料に証券IDが設定されているデータをマッチング済とします</dd> </dl>
   * @type {number}
   * @memberof GetCommissionStatus200ResponseResultsInner
   */
  commissionMatchingCount: number;
  /**
   * 手数料取込毎の手数料合計額
   * @type {number}
   * @memberof GetCommissionStatus200ResponseResultsInner
   */
  commissionTotalAmount: number;
  /**
   * 手数料取込日
   * @type {string}
   * @memberof GetCommissionStatus200ResponseResultsInner
   */
  commissionImportAt: string;
  /**
   *
   * @type {CommissionImportStatus}
   * @memberof GetCommissionStatus200ResponseResultsInner
   */
  commissionImportStatus: CommissionImportStatus;
  /**
   * 内税フラグ
   * @type {boolean}
   * @memberof GetCommissionStatus200ResponseResultsInner
   */
  consumptionTaxIncludeFlag: boolean;
}
/**
 *
 * @export
 * @interface GetCommissions200Response
 */
export interface GetCommissions200Response {
  /**
   *
   * @type {GetCommissions200ResponseInsuranceCompanyInfo}
   * @memberof GetCommissions200Response
   */
  insuranceCompanyInfo: GetCommissions200ResponseInsuranceCompanyInfo;
  /**
   *
   * @type {Array<GetCommissions200ResponseCommissionsInner>}
   * @memberof GetCommissions200Response
   */
  commissions: Array<GetCommissions200ResponseCommissionsInner>;
}
/**
 *
 * @export
 * @interface GetCommissions200ResponseCommissionsInner
 */
export interface GetCommissions200ResponseCommissionsInner {
  /**
   * 手数料ID
   * @type {number}
   * @memberof GetCommissions200ResponseCommissionsInner
   */
  commissionId: number;
  /**
   * 手数料CSVの証券番号
   * @type {string}
   * @memberof GetCommissions200ResponseCommissionsInner
   */
  policyNumberFromCsv: string;
  /**
   *
   * @type {GetCommissions200ResponseCommissionsInnerContractor}
   * @memberof GetCommissions200ResponseCommissionsInner
   */
  contractor?: GetCommissions200ResponseCommissionsInnerContractor;
  /**
   *
   * @type {GetCommissions200ResponseCommissionsInnerInsured}
   * @memberof GetCommissions200ResponseCommissionsInner
   */
  insured?: GetCommissions200ResponseCommissionsInnerInsured;
  /**
   *
   * @type {GetCommissions200ResponseCommissionsInnerStaff}
   * @memberof GetCommissions200ResponseCommissionsInner
   */
  staff?: GetCommissions200ResponseCommissionsInnerStaff;
  /**
   *
   * @type {GetCommissions200ResponseCommissionsInnerInsuranceInfo}
   * @memberof GetCommissions200ResponseCommissionsInner
   */
  insuranceInfo?: GetCommissions200ResponseCommissionsInnerInsuranceInfo;
}
/**
 * <dl>    <dt>手数料CSVの契約者の情報</dt>   <dt>firstName、lastNameはcontractorNameFromCsvをスペース区切りした値を設定</dt>  </dl>
 * @export
 * @interface GetCommissions200ResponseCommissionsInnerContractor
 */
export interface GetCommissions200ResponseCommissionsInnerContractor {
  /**
   * 手数料CSVの契約者名
   * @type {string}
   * @memberof GetCommissions200ResponseCommissionsInnerContractor
   */
  contractorNameFromCsv?: string;
  /**
   * 名前
   * @type {string}
   * @memberof GetCommissions200ResponseCommissionsInnerContractor
   */
  firstName?: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetCommissions200ResponseCommissionsInnerContractor
   */
  lastName?: string;
}
/**
 * 保険情報
 * @export
 * @interface GetCommissions200ResponseCommissionsInnerInsuranceInfo
 */
export interface GetCommissions200ResponseCommissionsInnerInsuranceInfo {
  /**
   * 手数料CSVの手数料
   * @type {number}
   * @memberof GetCommissions200ResponseCommissionsInnerInsuranceInfo
   */
  commissionFromCsv?: number;
  /**
   * 手数料CSVの保険料
   * @type {number}
   * @memberof GetCommissions200ResponseCommissionsInnerInsuranceInfo
   */
  insuranceFeeFromCsv?: number;
  /**
   * 手数料CSVの保険種類
   * @type {string}
   * @memberof GetCommissions200ResponseCommissionsInnerInsuranceInfo
   */
  insuranceTypeFromCsv?: string;
  /**
   * 手数料CSVの契約日
   * @type {string}
   * @memberof GetCommissions200ResponseCommissionsInnerInsuranceInfo
   */
  contractDateFromCsv?: string;
  /**
   * 契約日
   * @type {string}
   * @memberof GetCommissions200ResponseCommissionsInnerInsuranceInfo
   */
  contractDateOn?: string;
  /**
   * 初年度フラグ（true：初年度、false：次年度）
   * @type {boolean}
   * @memberof GetCommissions200ResponseCommissionsInnerInsuranceInfo
   */
  firstYearFlag?: boolean;
}
/**
 * <dl>    <dt>手数料CSVの被保険者の情報</dt>   <dt>firstName、lastNameはinsuredNameFromCsvをスペース区切りした値を設定</dt>  </dl>
 * @export
 * @interface GetCommissions200ResponseCommissionsInnerInsured
 */
export interface GetCommissions200ResponseCommissionsInnerInsured {
  /**
   * 手数料CSVの被保険者名
   * @type {string}
   * @memberof GetCommissions200ResponseCommissionsInnerInsured
   */
  insuredNameFromCsv?: string;
  /**
   * 名前
   * @type {string}
   * @memberof GetCommissions200ResponseCommissionsInnerInsured
   */
  firstName?: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetCommissions200ResponseCommissionsInnerInsured
   */
  lastName?: string;
}
/**
 * <dl>    <dt>手数料CSVの担当者の情報</dt>   <dt>staffCodeFromCsvと保険会社IDでStaffMapからstaffIdが取得できる場合</dt>     <dd>staffテーブルからstaffId、staffNumber、firstName、lastNameを設定します</dd>   <dt>staffCodeFromCsvと保険会社IDでStaffMapからstaffIdが取得できない場合</dt>     <dd>staffId、staffNumberは未設定</dd>     <dd>firstName、lastNameはstaffNameFromCsvをスペース区切りした値を設定</dd>  </dl>
 * @export
 * @interface GetCommissions200ResponseCommissionsInnerStaff
 */
export interface GetCommissions200ResponseCommissionsInnerStaff {
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetCommissions200ResponseCommissionsInnerStaff
   */
  staffId?: number;
  /**
   * 社員番号
   * @type {string}
   * @memberof GetCommissions200ResponseCommissionsInnerStaff
   */
  staffNumber?: string;
  /**
   * 手数料CSVの担当者名
   * @type {string}
   * @memberof GetCommissions200ResponseCommissionsInnerStaff
   */
  staffNameFromCsv?: string;
  /**
   * 手数料CSVの担当者コード
   * @type {string}
   * @memberof GetCommissions200ResponseCommissionsInnerStaff
   */
  staffCodeFromCsv?: string;
  /**
   * 名前
   * @type {string}
   * @memberof GetCommissions200ResponseCommissionsInnerStaff
   */
  firstName?: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetCommissions200ResponseCommissionsInnerStaff
   */
  lastName?: string;
}
/**
 *
 * @export
 * @interface GetCommissions200ResponseInsuranceCompanyInfo
 */
export interface GetCommissions200ResponseInsuranceCompanyInfo {
  /**
   * 保険会社ID
   * @type {number}
   * @memberof GetCommissions200ResponseInsuranceCompanyInfo
   */
  insuranceCompanyId: number;
  /**
   * 保険会社名
   * @type {string}
   * @memberof GetCommissions200ResponseInsuranceCompanyInfo
   */
  insuranceCompanyName: string;
}
/**
 *
 * @export
 * @interface GetContractReport200Response
 */
export interface GetContractReport200Response {
  /**
   * 申達報告ID
   * @type {number}
   * @memberof GetContractReport200Response
   */
  contractReportId: number;
  /**
   *
   * @type {InsuranceCategory}
   * @memberof GetContractReport200Response
   */
  insuranceCategory: InsuranceCategory;
  /**
   *
   * @type {ContractorAttribute}
   * @memberof GetContractReport200Response
   */
  contractorAttribute: ContractorAttribute;
  /**
   * 申達報告日
   * @type {string}
   * @memberof GetContractReport200Response
   */
  contractReportOn: string;
  /**
   *
   * @type {GetContractReport200ResponsePotentialCustomerInfo}
   * @memberof GetContractReport200Response
   */
  potentialCustomerInfo: GetContractReport200ResponsePotentialCustomerInfo;
  /**
   *
   * @type {GetContractReport200ResponseContractorIndividual}
   * @memberof GetContractReport200Response
   */
  contractorIndividual?: GetContractReport200ResponseContractorIndividual;
  /**
   *
   * @type {GetContractReport200ResponseContractorCorporation}
   * @memberof GetContractReport200Response
   */
  contractorCorporation?: GetContractReport200ResponseContractorCorporation;
  /**
   *
   * @type {GetContractReports200ResponseResultsInnerInsured}
   * @memberof GetContractReport200Response
   */
  insured?: GetContractReports200ResponseResultsInnerInsured;
  /**
   *
   * @type {PostContractReportRequestInsuranceInfo}
   * @memberof GetContractReport200Response
   */
  insuranceInfo: PostContractReportRequestInsuranceInfo;
  /**
   *
   * @type {GetContractReport200ResponseLifeInsuranceInfo}
   * @memberof GetContractReport200Response
   */
  lifeInsuranceInfo?: GetContractReport200ResponseLifeInsuranceInfo;
  /**
   *
   * @type {GetContractReport200ResponseNonLifeInsuranceInfo}
   * @memberof GetContractReport200Response
   */
  nonLifeInsuranceInfo?: GetContractReport200ResponseNonLifeInsuranceInfo;
  /**
   *
   * @type {PostContractReportRequestCommissionShare}
   * @memberof GetContractReport200Response
   */
  commissionShare: PostContractReportRequestCommissionShare;
  /**
   *
   * @type {PostContractReportRequestIncentiveShare}
   * @memberof GetContractReport200Response
   */
  incentiveShare: PostContractReportRequestIncentiveShare;
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetContractReport200Response
   */
  staffId: number;
}
/**
 * <dl>   <dt>contractorAttributeがcorporationの場合に設定される契約者の情報</dt>   <dt>contractorAttributeがcorporationの場合に必須で、それ以外は未設定</dt> </dl>
 * @export
 * @interface GetContractReport200ResponseContractorCorporation
 */
export interface GetContractReport200ResponseContractorCorporation {
  /**
   * 法人名
   * @type {string}
   * @memberof GetContractReport200ResponseContractorCorporation
   */
  corporationName: string;
  /**
   * 決算月
   * @type {number}
   * @memberof GetContractReport200ResponseContractorCorporation
   */
  corporationClosingMonth?: number;
  /**
   * 法人代表者名前
   * @type {string}
   * @memberof GetContractReport200ResponseContractorCorporation
   */
  corporationRepresentativeFirstName?: string;
  /**
   * 法人代表者苗字
   * @type {string}
   * @memberof GetContractReport200ResponseContractorCorporation
   */
  corporationRepresentativeLastName?: string;
  /**
   *
   * @type {string}
   * @memberof GetContractReport200ResponseContractorCorporation
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof GetContractReport200ResponseContractorCorporation
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof GetContractReport200ResponseContractorCorporation
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof GetContractReport200ResponseContractorCorporation
   */
  address3?: string;
  /**
   * 番地
   * @type {string}
   * @memberof GetContractReport200ResponseContractorCorporation
   */
  address4?: string;
  /**
   * ビル名、部屋番号
   * @type {string}
   * @memberof GetContractReport200ResponseContractorCorporation
   */
  address5?: string;
  /**
   *
   * @type {string}
   * @memberof GetContractReport200ResponseContractorCorporation
   */
  tel1?: string;
  /**
   *
   * @type {string}
   * @memberof GetContractReport200ResponseContractorCorporation
   */
  tel2?: string;
  /**
   * メールアドレス
   * @type {string}
   * @memberof GetContractReport200ResponseContractorCorporation
   */
  mailAddress?: string;
}
/**
 * <dl>   <dt>contractorAttributeがindividualの場合に設定される契約者の情報</dt>   <dt>contractorAttributeがindividualの場合に必須で、それ以外は未設定</dt> </dl>
 * @export
 * @interface GetContractReport200ResponseContractorIndividual
 */
export interface GetContractReport200ResponseContractorIndividual {
  /**
   * 名前
   * @type {string}
   * @memberof GetContractReport200ResponseContractorIndividual
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetContractReport200ResponseContractorIndividual
   */
  lastName: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof GetContractReport200ResponseContractorIndividual
   */
  firstNameKana: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof GetContractReport200ResponseContractorIndividual
   */
  lastNameKana: string;
  /**
   * 生年月日
   * @type {string}
   * @memberof GetContractReport200ResponseContractorIndividual
   */
  dateOfBirth: string;
  /**
   *
   * @type {string}
   * @memberof GetContractReport200ResponseContractorIndividual
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof GetContractReport200ResponseContractorIndividual
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof GetContractReport200ResponseContractorIndividual
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof GetContractReport200ResponseContractorIndividual
   */
  address3?: string;
  /**
   * 番地
   * @type {string}
   * @memberof GetContractReport200ResponseContractorIndividual
   */
  address4?: string;
  /**
   * ビル名、部屋番号
   * @type {string}
   * @memberof GetContractReport200ResponseContractorIndividual
   */
  address5?: string;
  /**
   *
   * @type {string}
   * @memberof GetContractReport200ResponseContractorIndividual
   */
  tel1?: string;
  /**
   *
   * @type {string}
   * @memberof GetContractReport200ResponseContractorIndividual
   */
  tel2?: string;
  /**
   * メールアドレス
   * @type {string}
   * @memberof GetContractReport200ResponseContractorIndividual
   */
  mailAddress?: string;
  /**
   * 案件人物ID
   * @type {number}
   * @memberof GetContractReport200ResponseContractorIndividual
   */
  potentialCustomerPersonId?: number;
}
/**
 * 生保固有の情報。insuranceCategoryがlifeInsuranceの場合は必須で、それ以外は未設定
 * @export
 * @interface GetContractReport200ResponseLifeInsuranceInfo
 */
export interface GetContractReport200ResponseLifeInsuranceInfo {
  /**
   * 契約日
   * @type {string}
   * @memberof GetContractReport200ResponseLifeInsuranceInfo
   */
  contractDateOn: string;
}
/**
 * 損保固有の情報。insuranceCategoryがnonLifeInsuranceの場合は必須で、それ以外は未設定。
 * @export
 * @interface GetContractReport200ResponseNonLifeInsuranceInfo
 */
export interface GetContractReport200ResponseNonLifeInsuranceInfo {
  /**
   * 証券番号
   * @type {string}
   * @memberof GetContractReport200ResponseNonLifeInsuranceInfo
   */
  policyNumber?: string;
  /**
   * 申込日
   * @type {string}
   * @memberof GetContractReport200ResponseNonLifeInsuranceInfo
   */
  applicationDateOn: string;
  /**
   * 保険始期
   * @type {string}
   * @memberof GetContractReport200ResponseNonLifeInsuranceInfo
   */
  insuranceStartDateOn: string;
  /**
   * 保険終期
   * @type {string}
   * @memberof GetContractReport200ResponseNonLifeInsuranceInfo
   */
  insuranceEndDateOn: string;
  /**
   * 領収日
   * @type {string}
   * @memberof GetContractReport200ResponseNonLifeInsuranceInfo
   */
  receiptDateOn?: string;
  /**
   * 返戻日
   * @type {string}
   * @memberof GetContractReport200ResponseNonLifeInsuranceInfo
   */
  returnDateOn?: string;
  /**
   *
   * @type {NonLifeInsuranceAccountingCategory}
   * @memberof GetContractReport200ResponseNonLifeInsuranceInfo
   */
  nonLifeInsuranceAccountingCategory: NonLifeInsuranceAccountingCategory;
  /**
   *
   * @type {NonLifeInsuranceDepositCategory}
   * @memberof GetContractReport200ResponseNonLifeInsuranceInfo
   */
  nonLifeInsuranceDepositCategory: NonLifeInsuranceDepositCategory;
}
/**
 * <dl>    <dt>案件情報</dt>  </dl>
 * @export
 * @interface GetContractReport200ResponsePotentialCustomerInfo
 */
export interface GetContractReport200ResponsePotentialCustomerInfo {
  /**
   * 案件ID
   * @type {number}
   * @memberof GetContractReport200ResponsePotentialCustomerInfo
   */
  potentialCustomerId?: number;
  /**
   *
   * @type {PotentialCustomerType}
   * @memberof GetContractReport200ResponsePotentialCustomerInfo
   */
  potentialCustomerType: PotentialCustomerType;
  /**
   * サポート利用有無（true：利用あり）
   * @type {boolean}
   * @memberof GetContractReport200ResponsePotentialCustomerInfo
   */
  supportUse?: boolean;
  /**
   * マーケットID
   * @type {number}
   * @memberof GetContractReport200ResponsePotentialCustomerInfo
   */
  marketId?: number;
}
/**
 *
 * @export
 * @interface GetContractReports200Response
 */
export interface GetContractReports200Response {
  /**
   * 申達報告の検索結果
   * @type {Array<GetContractReports200ResponseResultsInner>}
   * @memberof GetContractReports200Response
   */
  results?: Array<GetContractReports200ResponseResultsInner>;
  /**
   *
   * @type {Pagination}
   * @memberof GetContractReports200Response
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface GetContractReports200ResponseResultsInner
 */
export interface GetContractReports200ResponseResultsInner {
  /**
   * 申達報告ID
   * @type {number}
   * @memberof GetContractReports200ResponseResultsInner
   */
  contractReportId: number;
  /**
   *
   * @type {GetAdjustmentDeductions200ResponseResultsInnerStaff}
   * @memberof GetContractReports200ResponseResultsInner
   */
  staff: GetAdjustmentDeductions200ResponseResultsInnerStaff;
  /**
   *
   * @type {InsuranceCategory}
   * @memberof GetContractReports200ResponseResultsInner
   */
  insuranceCategory: InsuranceCategory;
  /**
   *
   * @type {ContractorAttribute}
   * @memberof GetContractReports200ResponseResultsInner
   */
  contractorAttribute: ContractorAttribute;
  /**
   * 申達報告日
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInner
   */
  contractReportOn: string;
  /**
   *
   * @type {GetContractReports200ResponseResultsInnerContractorIndividual}
   * @memberof GetContractReports200ResponseResultsInner
   */
  contractorIndividual?: GetContractReports200ResponseResultsInnerContractorIndividual;
  /**
   *
   * @type {GetContractReports200ResponseResultsInnerContractorCorporation}
   * @memberof GetContractReports200ResponseResultsInner
   */
  contractorCorporation?: GetContractReports200ResponseResultsInnerContractorCorporation;
  /**
   *
   * @type {GetContractReports200ResponseResultsInnerInsured}
   * @memberof GetContractReports200ResponseResultsInner
   */
  insured?: GetContractReports200ResponseResultsInnerInsured;
  /**
   *
   * @type {GetContractReports200ResponseResultsInnerInsuranceInfo}
   * @memberof GetContractReports200ResponseResultsInner
   */
  insuranceInfo: GetContractReports200ResponseResultsInnerInsuranceInfo;
  /**
   *
   * @type {GetContractReports200ResponseResultsInnerLifeInsuranceInfo}
   * @memberof GetContractReports200ResponseResultsInner
   */
  lifeInsuranceInfo?: GetContractReports200ResponseResultsInnerLifeInsuranceInfo;
  /**
   *
   * @type {GetContractReports200ResponseResultsInnerNonLifeInsuranceInfo}
   * @memberof GetContractReports200ResponseResultsInner
   */
  nonLifeInsuranceInfo?: GetContractReports200ResponseResultsInnerNonLifeInsuranceInfo;
  /**
   *
   * @type {GetContractReports200ResponseResultsInnerPotentialCustomerInfo}
   * @memberof GetContractReports200ResponseResultsInner
   */
  potentialCustomerInfo?: GetContractReports200ResponseResultsInnerPotentialCustomerInfo;
}
/**
 * <dl>   <dt>contractorAttributeがcorporationの場合に設定される契約者の情報</dt>   <dt>contractorAttributeがcorporationの場合に必須で、それ以外は未設定</dt> </dl>
 * @export
 * @interface GetContractReports200ResponseResultsInnerContractorCorporation
 */
export interface GetContractReports200ResponseResultsInnerContractorCorporation {
  /**
   * 法人名
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorCorporation
   */
  corporationName: string;
  /**
   * 決算月
   * @type {number}
   * @memberof GetContractReports200ResponseResultsInnerContractorCorporation
   */
  corporationClosingMonth?: number;
  /**
   * 法人代表者名前
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorCorporation
   */
  corporationRepresentativeFirstName?: string;
  /**
   * 法人代表者苗字
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorCorporation
   */
  corporationRepresentativeLastName?: string;
  /**
   *
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorCorporation
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorCorporation
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorCorporation
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorCorporation
   */
  address3?: string;
  /**
   * 電話番号下4桁
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorCorporation
   */
  telLast?: string;
}
/**
 * <dl>   <dt>contractorAttributeがindividualの場合に設定される契約者の情報</dt>   <dt>contractorAttributeがindividualの場合に必須で、それ以外は未設定</dt> </dl>
 * @export
 * @interface GetContractReports200ResponseResultsInnerContractorIndividual
 */
export interface GetContractReports200ResponseResultsInnerContractorIndividual {
  /**
   * 名前
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorIndividual
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorIndividual
   */
  lastName: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorIndividual
   */
  firstNameKana: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorIndividual
   */
  lastNameKana: string;
  /**
   * 生年月日
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorIndividual
   */
  dateOfBirth: string;
  /**
   *
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorIndividual
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorIndividual
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorIndividual
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorIndividual
   */
  address3?: string;
  /**
   * 電話番号下4桁
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerContractorIndividual
   */
  telLast?: string;
}
/**
 * 保険の情報
 * @export
 * @interface GetContractReports200ResponseResultsInnerInsuranceInfo
 */
export interface GetContractReports200ResponseResultsInnerInsuranceInfo {
  /**
   * 保険会社ID
   * @type {number}
   * @memberof GetContractReports200ResponseResultsInnerInsuranceInfo
   */
  insuranceCompanyId: number;
  /**
   * 保険会社名
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerInsuranceInfo
   */
  insuranceCompanyName: string;
  /**
   * 保険種類ID
   * @type {number}
   * @memberof GetContractReports200ResponseResultsInnerInsuranceInfo
   */
  insuranceTypeId: number;
  /**
   * 保険種類名
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerInsuranceInfo
   */
  insuranceTypeName: string;
  /**
   * 保険料
   * @type {number}
   * @memberof GetContractReports200ResponseResultsInnerInsuranceInfo
   */
  insuranceFee: number;
  /**
   *
   * @type {PaymentMethod}
   * @memberof GetContractReports200ResponseResultsInnerInsuranceInfo
   */
  paymentMethod: PaymentMethod;
  /**
   * みなし年払い
   * @type {boolean}
   * @memberof GetContractReports200ResponseResultsInnerInsuranceInfo
   */
  deemedAnnualPayment?: boolean;
}
/**
 * <dl>   <dt>被保険者の情報</dt>   <dt>生保の場合はinsuredオブジェクトと下記のpropertiesが必須</dt>   <dd>firstName、lastName、firstNameKana、lastNameKana、dateOfBirth</dd>   <dt>損保の場合はinsuredオブジェクト、propertiesとも任意</dt> </dl>
 * @export
 * @interface GetContractReports200ResponseResultsInnerInsured
 */
export interface GetContractReports200ResponseResultsInnerInsured {
  /**
   * 名前
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerInsured
   */
  firstName?: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerInsured
   */
  lastName?: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerInsured
   */
  firstNameKana?: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerInsured
   */
  lastNameKana?: string;
  /**
   * 生年月日
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerInsured
   */
  dateOfBirth?: string;
  /**
   * 案件人物ID
   * @type {number}
   * @memberof GetContractReports200ResponseResultsInnerInsured
   */
  potentialCustomerPersonId?: number;
}
/**
 * 生保固有の情報。insuranceCategoryがlifeInsuranceの場合は必須。それ以外は設定不可。
 * @export
 * @interface GetContractReports200ResponseResultsInnerLifeInsuranceInfo
 */
export interface GetContractReports200ResponseResultsInnerLifeInsuranceInfo {
  /**
   * 契約日
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerLifeInsuranceInfo
   */
  contractDateOn: string;
}
/**
 * 損保固有の情報。insuranceCategoryがnonLifeInsuranceの場合は必須。それ以外は設定不可。
 * @export
 * @interface GetContractReports200ResponseResultsInnerNonLifeInsuranceInfo
 */
export interface GetContractReports200ResponseResultsInnerNonLifeInsuranceInfo {
  /**
   * 証券番号
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerNonLifeInsuranceInfo
   */
  policyNumber?: string;
  /**
   * 申込日
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerNonLifeInsuranceInfo
   */
  applicationDateOn: string;
}
/**
 * <dl>    <dt>案件情報</dt>  </dl>
 * @export
 * @interface GetContractReports200ResponseResultsInnerPotentialCustomerInfo
 */
export interface GetContractReports200ResponseResultsInnerPotentialCustomerInfo {
  /**
   * 案件ID
   * @type {number}
   * @memberof GetContractReports200ResponseResultsInnerPotentialCustomerInfo
   */
  potentialCustomerId?: number;
  /**
   *
   * @type {PotentialCustomerType}
   * @memberof GetContractReports200ResponseResultsInnerPotentialCustomerInfo
   */
  potentialCustomerType: PotentialCustomerType;
  /**
   * サポート利用有無（true：利用あり）
   * @type {boolean}
   * @memberof GetContractReports200ResponseResultsInnerPotentialCustomerInfo
   */
  supportUse?: boolean;
  /**
   * マーケットID
   * @type {number}
   * @memberof GetContractReports200ResponseResultsInnerPotentialCustomerInfo
   */
  marketId?: number;
  /**
   * マーケット名
   * @type {string}
   * @memberof GetContractReports200ResponseResultsInnerPotentialCustomerInfo
   */
  marketName?: string;
}
/**
 *
 * @export
 * @interface GetContractReportsCommissionMatching200Response
 */
export interface GetContractReportsCommissionMatching200Response {
  /**
   * 申達報告の検索結果
   * @type {Array<GetContractReportsCommissionMatching200ResponseResultsInner>}
   * @memberof GetContractReportsCommissionMatching200Response
   */
  results?: Array<GetContractReportsCommissionMatching200ResponseResultsInner>;
  /**
   *
   * @type {Pagination}
   * @memberof GetContractReportsCommissionMatching200Response
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface GetContractReportsCommissionMatching200ResponseResultsInner
 */
export interface GetContractReportsCommissionMatching200ResponseResultsInner {
  /**
   * 申達報告ID
   * @type {number}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInner
   */
  contractReportId: number;
  /**
   *
   * @type {GetContractReportsCommissionMatching200ResponseResultsInnerStaff}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInner
   */
  staff: GetContractReportsCommissionMatching200ResponseResultsInnerStaff;
  /**
   *
   * @type {InsuranceCategory}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInner
   */
  insuranceCategory: InsuranceCategory;
  /**
   *
   * @type {ContractorAttribute}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInner
   */
  contractorAttribute: ContractorAttribute;
  /**
   * 申達報告日
   * @type {string}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInner
   */
  contractReportOn: string;
  /**
   *
   * @type {GetContractReportsCommissionMatching200ResponseResultsInnerContractorIndividual}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInner
   */
  contractorIndividual?: GetContractReportsCommissionMatching200ResponseResultsInnerContractorIndividual;
  /**
   *
   * @type {GetContractReportsCommissionMatching200ResponseResultsInnerContractorCorporation}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInner
   */
  contractorCorporation?: GetContractReportsCommissionMatching200ResponseResultsInnerContractorCorporation;
  /**
   *
   * @type {GetContractReportsCommissionMatching200ResponseResultsInnerInsured}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInner
   */
  insured?: GetContractReportsCommissionMatching200ResponseResultsInnerInsured;
  /**
   *
   * @type {GetContractReportsCommissionMatching200ResponseResultsInnerInsuranceInfo}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInner
   */
  insuranceInfo: GetContractReportsCommissionMatching200ResponseResultsInnerInsuranceInfo;
  /**
   *
   * @type {GetContractReports200ResponseResultsInnerLifeInsuranceInfo}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInner
   */
  lifeInsuranceInfo?: GetContractReports200ResponseResultsInnerLifeInsuranceInfo;
  /**
   *
   * @type {GetContractReports200ResponseResultsInnerNonLifeInsuranceInfo}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInner
   */
  nonLifeInsuranceInfo?: GetContractReports200ResponseResultsInnerNonLifeInsuranceInfo;
}
/**
 * <dl>   <dt>contractorAttributeがcorporationの場合に設定される契約者の情報</dt>   <dt>contractorAttributeがcorporationの場合に必須で、それ以外は未設定</dt> </dl>
 * @export
 * @interface GetContractReportsCommissionMatching200ResponseResultsInnerContractorCorporation
 */
export interface GetContractReportsCommissionMatching200ResponseResultsInnerContractorCorporation {
  /**
   * 法人名
   * @type {string}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInnerContractorCorporation
   */
  corporationName: string;
}
/**
 * <dl>   <dt>contractorAttributeがindividualの場合に設定される契約者の情報</dt>   <dt>contractorAttributeがindividualの場合に必須で、それ以外は未設定</dt> </dl>
 * @export
 * @interface GetContractReportsCommissionMatching200ResponseResultsInnerContractorIndividual
 */
export interface GetContractReportsCommissionMatching200ResponseResultsInnerContractorIndividual {
  /**
   * 名前
   * @type {string}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInnerContractorIndividual
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInnerContractorIndividual
   */
  lastName: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInnerContractorIndividual
   */
  firstNameKana: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInnerContractorIndividual
   */
  lastNameKana: string;
}
/**
 * 生保・損保ともに共通する保険の情報
 * @export
 * @interface GetContractReportsCommissionMatching200ResponseResultsInnerInsuranceInfo
 */
export interface GetContractReportsCommissionMatching200ResponseResultsInnerInsuranceInfo {
  /**
   * 保険種類ID
   * @type {number}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInnerInsuranceInfo
   */
  insuranceTypeId: number;
  /**
   * 保険種類名
   * @type {string}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInnerInsuranceInfo
   */
  insuranceTypeName: string;
  /**
   * 保険料
   * @type {number}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInnerInsuranceInfo
   */
  insuranceFee: number;
}
/**
 * <dl>   <dt>被保険者の情報</dt>   <dt>生保の場合はinsuredオブジェクトと下記のpropertiesが必須</dt>   <dd>firstName、lastName、firstNameKana、lastNameKana</dd>   <dt>損保の場合はinsuredオブジェクト、propertiesとも任意</dt> </dl>
 * @export
 * @interface GetContractReportsCommissionMatching200ResponseResultsInnerInsured
 */
export interface GetContractReportsCommissionMatching200ResponseResultsInnerInsured {
  /**
   * 名前
   * @type {string}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInnerInsured
   */
  firstName?: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInnerInsured
   */
  lastName?: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInnerInsured
   */
  firstNameKana?: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInnerInsured
   */
  lastNameKana?: string;
}
/**
 *
 * @export
 * @interface GetContractReportsCommissionMatching200ResponseResultsInnerStaff
 */
export interface GetContractReportsCommissionMatching200ResponseResultsInnerStaff {
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInnerStaff
   */
  staffId: number;
  /**
   * 社員番号
   * @type {string}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInnerStaff
   */
  staffNumber: string;
  /**
   * 名前
   * @type {string}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInnerStaff
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetContractReportsCommissionMatching200ResponseResultsInnerStaff
   */
  lastName: string;
}
/**
 *
 * @export
 * @interface GetDepartment200Response
 */
export interface GetDepartment200Response {
  /**
   * 所属ID
   * @type {number}
   * @memberof GetDepartment200Response
   */
  departmentId: number;
  /**
   * 所属名
   * @type {string}
   * @memberof GetDepartment200Response
   */
  departmentName: string;
  /**
   * 所属の全期間の所属長 tenureAtToの降順(最新の所属長順)
   * @type {Array<GetDepartment200ResponseBossInner>}
   * @memberof GetDepartment200Response
   */
  boss: Array<GetDepartment200ResponseBossInner>;
  /**
   * <dl>    <dt>案件・申達・証券権限を持つstaffのリスト</dt>   <dt>案件・申達・証券権限は<a href=https://github.com/doitplanning/doit-core-doc/blob/main/%E3%83%90%E3%83%83%E3%82%AF%E3%82%A8%E3%83%B3%E3%83%89/%E6%A8%A9%E9%99%90.md>権限doc</a>参照</dt>  </dl>
   * @type {Array<PostDepartmentRequestBossInner>}
   * @memberof GetDepartment200Response
   */
  authorizedStaff: Array<PostDepartmentRequestBossInner>;
  /**
   * <dl>    <dt>業績給参照権限を持つstaffのリスト</dt>   <dt>業績給参照権限は<a href=https://github.com/doitplanning/doit-core-doc/blob/main/%E3%83%90%E3%83%83%E3%82%AF%E3%82%A8%E3%83%B3%E3%83%89/%E6%A8%A9%E9%99%90.md>権限doc</a>参照</dt>  </dl>
   * @type {Array<PostDepartmentRequestBossInner>}
   * @memberof GetDepartment200Response
   */
  authorizedPerformanceBasedPay: Array<PostDepartmentRequestBossInner>;
  /**
   * 表示順
   * @type {number}
   * @memberof GetDepartment200Response
   */
  sortNumber: number;
}
/**
 * tenureAtToの降順
 * @export
 * @interface GetDepartment200ResponseBossInner
 */
export interface GetDepartment200ResponseBossInner {
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetDepartment200ResponseBossInner
   */
  staffId: number;
  /**
   * 在任期間
   * @type {string}
   * @memberof GetDepartment200ResponseBossInner
   */
  tenureAtFrom: string;
  /**
   * 在任期間
   * @type {string}
   * @memberof GetDepartment200ResponseBossInner
   */
  tenureAtTo?: string;
}
/**
 *
 * @export
 * @interface GetDepartments200Response
 */
export interface GetDepartments200Response {
  /**
   * 所属一覧
   * @type {Array<GetDepartments200ResponseResultsInner>}
   * @memberof GetDepartments200Response
   */
  results: Array<GetDepartments200ResponseResultsInner>;
  /**
   *
   * @type {Pagination}
   * @memberof GetDepartments200Response
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface GetDepartments200ResponseResultsInner
 */
export interface GetDepartments200ResponseResultsInner {
  /**
   * 所属ID
   * @type {number}
   * @memberof GetDepartments200ResponseResultsInner
   */
  departmentId: number;
  /**
   * 所属名
   * @type {string}
   * @memberof GetDepartments200ResponseResultsInner
   */
  departmentName: string;
  /**
   *
   * @type {GetDepartments200ResponseResultsInnerBoss}
   * @memberof GetDepartments200ResponseResultsInner
   */
  boss?: GetDepartments200ResponseResultsInnerBoss;
  /**
   * 表示順
   * @type {number}
   * @memberof GetDepartments200ResponseResultsInner
   */
  sortNumber: number;
}
/**
 * 最新の所属長を返す
 * @export
 * @interface GetDepartments200ResponseResultsInnerBoss
 */
export interface GetDepartments200ResponseResultsInnerBoss {
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetDepartments200ResponseResultsInnerBoss
   */
  staffId: number;
  /**
   * 社員番号
   * @type {string}
   * @memberof GetDepartments200ResponseResultsInnerBoss
   */
  staffNumber: string;
  /**
   * 名前
   * @type {string}
   * @memberof GetDepartments200ResponseResultsInnerBoss
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetDepartments200ResponseResultsInnerBoss
   */
  lastName: string;
}
/**
 *
 * @export
 * @interface GetInsuranceCompanies200Response
 */
export interface GetInsuranceCompanies200Response {
  /**
   * 保険会社一覧
   * @type {Array<InsuranceCompany>}
   * @memberof GetInsuranceCompanies200Response
   */
  results: Array<InsuranceCompany>;
  /**
   *
   * @type {Pagination}
   * @memberof GetInsuranceCompanies200Response
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface GetInsuranceTypes200Response
 */
export interface GetInsuranceTypes200Response {
  /**
   * 保険種類一覧
   * @type {Array<GetInsuranceTypes200ResponseResultsInner>}
   * @memberof GetInsuranceTypes200Response
   */
  results: Array<GetInsuranceTypes200ResponseResultsInner>;
  /**
   *
   * @type {Pagination}
   * @memberof GetInsuranceTypes200Response
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface GetInsuranceTypes200ResponseResultsInner
 */
export interface GetInsuranceTypes200ResponseResultsInner {
  /**
   * 保険種類ID
   * @type {number}
   * @memberof GetInsuranceTypes200ResponseResultsInner
   */
  insuranceTypeId: number;
  /**
   * 保険種類名
   * @type {string}
   * @memberof GetInsuranceTypes200ResponseResultsInner
   */
  insuranceTypeName: string;
  /**
   *
   * @type {InsuranceCategory}
   * @memberof GetInsuranceTypes200ResponseResultsInner
   */
  insuranceCategory: InsuranceCategory;
  /**
   * 表示順
   * @type {number}
   * @memberof GetInsuranceTypes200ResponseResultsInner
   */
  sortNumber: number;
}
/**
 *
 * @export
 * @interface GetMarkets200Response
 */
export interface GetMarkets200Response {
  /**
   * マーケット一覧
   * @type {Array<Market>}
   * @memberof GetMarkets200Response
   */
  results: Array<Market>;
  /**
   *
   * @type {Pagination}
   * @memberof GetMarkets200Response
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface GetMeStaffCurrent200Response
 */
export interface GetMeStaffCurrent200Response {
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetMeStaffCurrent200Response
   */
  staffId: number;
  /**
   * 社員番号
   * @type {string}
   * @memberof GetMeStaffCurrent200Response
   */
  staffNumber: string;
  /**
   *
   * @type {StaffRoleType}
   * @memberof GetMeStaffCurrent200Response
   */
  staffRoleType: StaffRoleType;
  /**
   *
   * @type {GetStaffCurrent200ResponsePersonal}
   * @memberof GetMeStaffCurrent200Response
   */
  personal: GetStaffCurrent200ResponsePersonal;
  /**
   *
   * @type {GetMeStaffCurrent200ResponseDepartmentAffiliation}
   * @memberof GetMeStaffCurrent200Response
   */
  departmentAffiliation: GetMeStaffCurrent200ResponseDepartmentAffiliation;
}
/**
 * <dl>    <dt>ユーザの現在の在籍所属情報</dt>    </dl>
 * @export
 * @interface GetMeStaffCurrent200ResponseDepartmentAffiliation
 */
export interface GetMeStaffCurrent200ResponseDepartmentAffiliation {
  /**
   * 所属ID
   * @type {number}
   * @memberof GetMeStaffCurrent200ResponseDepartmentAffiliation
   */
  departmentId: number;
  /**
   * 所属名
   * @type {string}
   * @memberof GetMeStaffCurrent200ResponseDepartmentAffiliation
   */
  departmentName: string;
  /**
   *
   * @type {GetMeStaffCurrent200ResponseDepartmentAffiliationBoss}
   * @memberof GetMeStaffCurrent200ResponseDepartmentAffiliation
   */
  boss?: GetMeStaffCurrent200ResponseDepartmentAffiliationBoss;
}
/**
 * 現在時点の所属長を返す
 * @export
 * @interface GetMeStaffCurrent200ResponseDepartmentAffiliationBoss
 */
export interface GetMeStaffCurrent200ResponseDepartmentAffiliationBoss {
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetMeStaffCurrent200ResponseDepartmentAffiliationBoss
   */
  staffId: number;
  /**
   * 社員番号
   * @type {string}
   * @memberof GetMeStaffCurrent200ResponseDepartmentAffiliationBoss
   */
  staffNumber: string;
  /**
   * 名前
   * @type {string}
   * @memberof GetMeStaffCurrent200ResponseDepartmentAffiliationBoss
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetMeStaffCurrent200ResponseDepartmentAffiliationBoss
   */
  lastName: string;
}
/**
 *
 * @export
 * @interface GetOperationLogs200Response
 */
export interface GetOperationLogs200Response {
  /**
   * log一覧
   * @type {Array<GetOperationLogs200ResponseResultsInner>}
   * @memberof GetOperationLogs200Response
   */
  results: Array<GetOperationLogs200ResponseResultsInner>;
  /**
   *
   * @type {Pagination}
   * @memberof GetOperationLogs200Response
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface GetOperationLogs200ResponseResultsInner
 */
export interface GetOperationLogs200ResponseResultsInner {
  /**
   * ログ日時
   * @type {string}
   * @memberof GetOperationLogs200ResponseResultsInner
   */
  logAt: string;
  /**
   *
   * @type {ApiName}
   * @memberof GetOperationLogs200ResponseResultsInner
   */
  apiName: ApiName;
  /**
   * log対象のリソースのID
   * @type {string}
   * @memberof GetOperationLogs200ResponseResultsInner
   */
  resourceId?: string;
  /**
   *
   * @type {GetPurchasePotentialCustomers200ResponseResultsInnerStaff}
   * @memberof GetOperationLogs200ResponseResultsInner
   */
  staff: GetPurchasePotentialCustomers200ResponseResultsInnerStaff;
}
/**
 *
 * @export
 * @interface GetPerformanceBasedPayCheck200Response
 */
export interface GetPerformanceBasedPayCheck200Response {
  /**
   * <dl>   <dt>保険会社マスタの保険会社で、該当支給月で、手数料区分が手数料の手数料取込がされていない保険会社を返す</dt> </dl>
   * @type {Array<GetCommissions200ResponseInsuranceCompanyInfo>}
   * @memberof GetPerformanceBasedPayCheck200Response
   */
  noCommissionImportedInsuranceCompanies: Array<GetCommissions200ResponseInsuranceCompanyInfo>;
  /**
   * <dl>   <dt>該当支給月で、手数料計算がされていない手数料取込の保険会社を返す</dt> </dl>
   * @type {Array<GetCommissions200ResponseInsuranceCompanyInfo>}
   * @memberof GetPerformanceBasedPayCheck200Response
   */
  noCommissionCalculatedInsuranceCompanies: Array<GetCommissions200ResponseInsuranceCompanyInfo>;
}
/**
 *
 * @export
 * @interface GetPerformanceBasedPayResults200Response
 */
export interface GetPerformanceBasedPayResults200Response {
  /**
   * 業績給計算結果一覧
   * @type {Array<PerformanceBasedPayResult>}
   * @memberof GetPerformanceBasedPayResults200Response
   */
  results: Array<PerformanceBasedPayResult>;
}
/**
 *
 * @export
 * @interface GetPotentialCustomer200Response
 */
export interface GetPotentialCustomer200Response {
  /**
   * 案件ID
   * @type {number}
   * @memberof GetPotentialCustomer200Response
   */
  potentialCustomerId: number;
  /**
   *
   * @type {PotentialCustomerAttribute}
   * @memberof GetPotentialCustomer200Response
   */
  potentialCustomerAttribute: PotentialCustomerAttribute;
  /**
   *
   * @type {PotentialCustomerType}
   * @memberof GetPotentialCustomer200Response
   */
  potentialCustomerType: PotentialCustomerType;
  /**
   * 初回面談日
   * @type {string}
   * @memberof GetPotentialCustomer200Response
   */
  potentialCustomerFirstVisitOn?: string;
  /**
   *
   * @type {GetPotentialCustomer200ResponseMarket}
   * @memberof GetPotentialCustomer200Response
   */
  market?: GetPotentialCustomer200ResponseMarket;
  /**
   *
   * @type {GetPotentialCustomer200ResponseIndividualPotentialCustomer}
   * @memberof GetPotentialCustomer200Response
   */
  individualPotentialCustomer?: GetPotentialCustomer200ResponseIndividualPotentialCustomer;
  /**
   *
   * @type {PostPotentialCustomerRequestCorporatePotentialCustomer}
   * @memberof GetPotentialCustomer200Response
   */
  corporatePotentialCustomer?: PostPotentialCustomerRequestCorporatePotentialCustomer;
  /**
   * 備考 改行コードを含む
   * @type {string}
   * @memberof GetPotentialCustomer200Response
   */
  memo?: string;
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetPotentialCustomer200Response
   */
  staffId: number;
  /**
   * 許可された募集人
   * @type {Array<number>}
   * @memberof GetPotentialCustomer200Response
   */
  authorizedStaff: Array<number>;
}
/**
 * <dl>   <dt>個人の案件の情報</dt>   <dt>個人の場合は必須で、それ以外は未設定</dt> </dl>
 * @export
 * @interface GetPotentialCustomer200ResponseIndividualPotentialCustomer
 */
export interface GetPotentialCustomer200ResponseIndividualPotentialCustomer {
  /**
   *
   * @type {GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomer
   */
  person: GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson;
  /**
   * <dl>   <dt>家族情報のリスト</dt> </dl>
   * @type {Array<GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner>}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomer
   */
  family: Array<GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner>;
}
/**
 * <dl>   <dt>案件人物</dt> </dl>
 * @export
 * @interface GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
 */
export interface GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner {
  /**
   * 案件人物ID
   * @type {number}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  potentialCustomerPersonId: number;
  /**
   *
   * @type {PotentialCustomerPersonAttribute}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  potentialCustomerPersonAttribute: PotentialCustomerPersonAttribute;
  /**
   * potentialCustomer内で一意となるsort順
   * @type {number}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  potentialCustomerPersonSortNumber: number;
  /**
   * 苗字
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  lastName: string;
  /**
   * 名前
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  firstName: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  lastNameKana: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  firstNameKana: string;
  /**
   * 生年月日
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  dateOfBirth: string;
  /**
   *
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  address3?: string;
  /**
   * 番地
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  address4?: string;
  /**
   * ビル名、部屋番号
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  address5?: string;
  /**
   *
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  tel1?: string;
  /**
   *
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  tel2?: string;
  /**
   * メールアドレス
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerFamilyInner
   */
  mailAddress?: string;
}
/**
 *
 * @export
 * @interface GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson
 */
export interface GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson {
  /**
   * 案件人物ID
   * @type {number}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson
   */
  potentialCustomerPersonId: number;
  /**
   * 苗字
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson
   */
  lastName: string;
  /**
   * 名前
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson
   */
  firstName: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson
   */
  lastNameKana: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson
   */
  firstNameKana: string;
  /**
   * 生年月日
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson
   */
  dateOfBirth: string;
  /**
   *
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson
   */
  address3?: string;
  /**
   * 番地
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson
   */
  address4?: string;
  /**
   * ビル名、部屋番号
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson
   */
  address5?: string;
  /**
   *
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson
   */
  tel1?: string;
  /**
   *
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson
   */
  tel2?: string;
  /**
   * メールアドレス
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson
   */
  mailAddress?: string;
}
/**
 * <dl>   <dt>マーケットの情報</dt>   <dt>potentialCustomerTypeがmarket・jointRecruitmentの場合は必須で、それ以外は未設定</dt> </dl>
 * @export
 * @interface GetPotentialCustomer200ResponseMarket
 */
export interface GetPotentialCustomer200ResponseMarket {
  /**
   * マーケットID
   * @type {number}
   * @memberof GetPotentialCustomer200ResponseMarket
   */
  marketId: number;
  /**
   * マーケット顧客ID
   * @type {string}
   * @memberof GetPotentialCustomer200ResponseMarket
   */
  marketCustomerId?: string;
  /**
   * サポート利用有無（true：利用あり）
   * @type {boolean}
   * @memberof GetPotentialCustomer200ResponseMarket
   */
  supportUse: boolean;
}
/**
 *
 * @export
 * @interface GetPotentialCustomerCorporations200Response
 */
export interface GetPotentialCustomerCorporations200Response {
  /**
   * 法人の案件の検索結果
   * @type {Array<GetPotentialCustomerCorporations200ResponseResultsInner>}
   * @memberof GetPotentialCustomerCorporations200Response
   */
  results?: Array<GetPotentialCustomerCorporations200ResponseResultsInner>;
  /**
   *
   * @type {Pagination}
   * @memberof GetPotentialCustomerCorporations200Response
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface GetPotentialCustomerCorporations200ResponseResultsInner
 */
export interface GetPotentialCustomerCorporations200ResponseResultsInner {
  /**
   * <dl>    <dt>案件の詳細を閲覧可能か判断するフラグ。</dt>      <dt>次のいずれかに該当する場合に閲覧可能としてtrueを設定する</dt>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>       <li>対象案件の許可された募集人</li>     </ul>   </dd>   <dd>上記以外はfalse</dd>  </dl>
   * @type {boolean}
   * @memberof GetPotentialCustomerCorporations200ResponseResultsInner
   */
  authorizedFlag: boolean;
  /**
   * 案件ID
   * @type {number}
   * @memberof GetPotentialCustomerCorporations200ResponseResultsInner
   */
  potentialCustomerId?: number;
  /**
   *
   * @type {GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff}
   * @memberof GetPotentialCustomerCorporations200ResponseResultsInner
   */
  staff: GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff;
  /**
   *
   * @type {GetPotentialCustomerCorporations200ResponseResultsInnerCorporation}
   * @memberof GetPotentialCustomerCorporations200ResponseResultsInner
   */
  corporation: GetPotentialCustomerCorporations200ResponseResultsInnerCorporation;
  /**
   *
   * @type {Market}
   * @memberof GetPotentialCustomerCorporations200ResponseResultsInner
   */
  market?: Market;
  /**
   *
   * @type {PotentialCustomerType}
   * @memberof GetPotentialCustomerCorporations200ResponseResultsInner
   */
  potentialCustomerType: PotentialCustomerType;
}
/**
 *
 * @export
 * @interface GetPotentialCustomerCorporations200ResponseResultsInnerCorporation
 */
export interface GetPotentialCustomerCorporations200ResponseResultsInnerCorporation {
  /**
   * 法人名
   * @type {string}
   * @memberof GetPotentialCustomerCorporations200ResponseResultsInnerCorporation
   */
  corporationName: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof GetPotentialCustomerCorporations200ResponseResultsInnerCorporation
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof GetPotentialCustomerCorporations200ResponseResultsInnerCorporation
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof GetPotentialCustomerCorporations200ResponseResultsInnerCorporation
   */
  address3?: string;
  /**
   * 電話番号下4桁
   * @type {string}
   * @memberof GetPotentialCustomerCorporations200ResponseResultsInnerCorporation
   */
  telLast?: string;
}
/**
 *
 * @export
 * @interface GetPotentialCustomerIndividualPersons200Response
 */
export interface GetPotentialCustomerIndividualPersons200Response {
  /**
   * 個人の案件に登録されている人物の検索結果
   * @type {Array<GetPotentialCustomerIndividualPersons200ResponseResultsInner>}
   * @memberof GetPotentialCustomerIndividualPersons200Response
   */
  results?: Array<GetPotentialCustomerIndividualPersons200ResponseResultsInner>;
  /**
   *
   * @type {Pagination}
   * @memberof GetPotentialCustomerIndividualPersons200Response
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface GetPotentialCustomerIndividualPersons200ResponseResultsInner
 */
export interface GetPotentialCustomerIndividualPersons200ResponseResultsInner {
  /**
   * <dl>    <dt>案件の詳細を閲覧可能か判断するフラグ。</dt>      <dt>次のいずれかに該当する場合に閲覧可能としてtrueを設定する</dt>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>       <li>対象案件の許可された募集人</li>     </ul>   </dd>   <dd>上記以外はfalse</dd>  </dl>
   * @type {boolean}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInner
   */
  authorizedFlag: boolean;
  /**
   * 案件ID
   * @type {number}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInner
   */
  potentialCustomerId?: number;
  /**
   *
   * @type {GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInner
   */
  staff: GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff;
  /**
   *
   * @type {GetPotentialCustomerIndividualPersons200ResponseResultsInnerSearchHitPerson}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInner
   */
  searchHitPerson?: GetPotentialCustomerIndividualPersons200ResponseResultsInnerSearchHitPerson;
  /**
   *
   * @type {GetPotentialCustomerIndividualPersons200ResponseResultsInnerPotentialCustomerPerson}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInner
   */
  potentialCustomerPerson?: GetPotentialCustomerIndividualPersons200ResponseResultsInnerPotentialCustomerPerson;
  /**
   *
   * @type {Market}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInner
   */
  market?: Market;
  /**
   *
   * @type {PotentialCustomerType}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInner
   */
  potentialCustomerType: PotentialCustomerType;
}
/**
 * 検索HITした該当者に紐づく案件の代表者
 * @export
 * @interface GetPotentialCustomerIndividualPersons200ResponseResultsInnerPotentialCustomerPerson
 */
export interface GetPotentialCustomerIndividualPersons200ResponseResultsInnerPotentialCustomerPerson {
  /**
   * 名前
   * @type {string}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInnerPotentialCustomerPerson
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInnerPotentialCustomerPerson
   */
  lastName: string;
}
/**
 * 検索HITした該当者
 * @export
 * @interface GetPotentialCustomerIndividualPersons200ResponseResultsInnerSearchHitPerson
 */
export interface GetPotentialCustomerIndividualPersons200ResponseResultsInnerSearchHitPerson {
  /**
   * 名前
   * @type {string}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInnerSearchHitPerson
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInnerSearchHitPerson
   */
  lastName: string;
  /**
   *
   * @type {PotentialCustomerPersonAttribute}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInnerSearchHitPerson
   */
  potentialCustomerPersonAttribute: PotentialCustomerPersonAttribute;
  /**
   * 生年月日
   * @type {string}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInnerSearchHitPerson
   */
  dateOfBirth: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInnerSearchHitPerson
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInnerSearchHitPerson
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInnerSearchHitPerson
   */
  address3?: string;
  /**
   * 電話番号下4桁
   * @type {string}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInnerSearchHitPerson
   */
  telLast?: string;
}
/**
 *
 * @export
 * @interface GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff
 */
export interface GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff {
  /**
   * 名前
   * @type {string}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff
   */
  lastName: string;
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff
   */
  staffId: number;
  /**
   * 社員番号
   * @type {string}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff
   */
  staffNumber: string;
  /**
   * 所属名
   * @type {string}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff
   */
  departmentName: string;
  /**
   * 所属ID
   * @type {number}
   * @memberof GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff
   */
  departmentId: number;
}
/**
 *
 * @export
 * @interface GetPurchasePotentialCustomers200Response
 */
export interface GetPurchasePotentialCustomers200Response {
  /**
   * 購入案件支払一覧
   * @type {Array<GetPurchasePotentialCustomers200ResponseResultsInner>}
   * @memberof GetPurchasePotentialCustomers200Response
   */
  results: Array<GetPurchasePotentialCustomers200ResponseResultsInner>;
  /**
   *
   * @type {Pagination}
   * @memberof GetPurchasePotentialCustomers200Response
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface GetPurchasePotentialCustomers200ResponseResultsInner
 */
export interface GetPurchasePotentialCustomers200ResponseResultsInner {
  /**
   * 購入案件ID
   * @type {number}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInner
   */
  purchasePotentialCustomerId: number;
  /**
   *
   * @type {GetPurchasePotentialCustomers200ResponseResultsInnerStaff}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInner
   */
  staff: GetPurchasePotentialCustomers200ResponseResultsInnerStaff;
  /**
   *
   * @type {GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomer}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInner
   */
  potentialCustomer: GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomer;
  /**
   *
   * @type {GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomerFee}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInner
   */
  potentialCustomerFee: GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomerFee;
  /**
   * 購入案件支払名
   * @type {string}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInner
   */
  purchasePotentialCustomerName: string;
  /**
   * 購入案件対象外フラグ（true：対象外、false：対象）
   * @type {boolean}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInner
   */
  purchasePotentialCustomerOutOfScopeFlag: boolean;
}
/**
 * <dl>    <dt>staff/potentialCustomerNameはpotentialCustomerIdに紐づくpotentialCustomerの値を設定</dt>   <dt>potentialCustomerIdが未設定であれば未設定</dt>   <dt>potentialCustomerNameはpotentialCustomerAttributeに従って設定</dt>   <dd>potentialCustomerAttributeがcorporationの場合、corporationName</dd>   <dd>potentialCustomerAttributeがindividualの場合、personのlastName firstName</dd>  </dl>
 * @export
 * @interface GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomer
 */
export interface GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomer {
  /**
   * 案件ID
   * @type {number}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomer
   */
  potentialCustomerId?: number;
  /**
   * マーケットID
   * @type {number}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomer
   */
  marketId: number;
  /**
   * マーケット名
   * @type {string}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomer
   */
  marketName: string;
  /**
   * 案件名
   * @type {string}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomer
   */
  potentialCustomerName?: string;
  /**
   *
   * @type {GetPurchasePotentialCustomers200ResponseResultsInnerStaff}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomer
   */
  staff?: GetPurchasePotentialCustomers200ResponseResultsInnerStaff;
}
/**
 *
 * @export
 * @interface GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomerFee
 */
export interface GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomerFee {
  /**
   * 購入案件トータル額（マーケット請求額）
   * @type {number}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomerFee
   */
  purchasePotentialCustomerTotalFee: number;
  /**
   * 購入案件サポート額
   * @type {number}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomerFee
   */
  purchasePotentialCustomerSupportAmount: number;
  /**
   * 購入案件支払額
   * @type {number}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomerFee
   */
  purchasePotentialCustomerFee: number;
  /**
   * 購入案件支給月
   * @type {string}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomerFee
   */
  purchasePotentialCustomerMonth?: string;
}
/**
 *
 * @export
 * @interface GetPurchasePotentialCustomers200ResponseResultsInnerStaff
 */
export interface GetPurchasePotentialCustomers200ResponseResultsInnerStaff {
  /**
   * 名前
   * @type {string}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInnerStaff
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInnerStaff
   */
  lastName: string;
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInnerStaff
   */
  staffId: number;
  /**
   * 社員番号
   * @type {string}
   * @memberof GetPurchasePotentialCustomers200ResponseResultsInnerStaff
   */
  staffNumber: string;
}
/**
 *
 * @export
 * @interface GetSecurities200Response
 */
export interface GetSecurities200Response {
  /**
   * 証券ID
   * @type {number}
   * @memberof GetSecurities200Response
   */
  securitiesId: number;
  /**
   *
   * @type {InsuranceCategory}
   * @memberof GetSecurities200Response
   */
  insuranceCategory: InsuranceCategory;
  /**
   *
   * @type {GetSecurities200ResponsePotentialCustomerInfo}
   * @memberof GetSecurities200Response
   */
  potentialCustomerInfo: GetSecurities200ResponsePotentialCustomerInfo;
  /**
   *
   * @type {ContractorAttribute}
   * @memberof GetSecurities200Response
   */
  contractorAttribute: ContractorAttribute;
  /**
   * 申達報告ID
   * @type {number}
   * @memberof GetSecurities200Response
   */
  contractReportId?: number;
  /**
   *
   * @type {PostSecuritiesRequestContractorIndividual}
   * @memberof GetSecurities200Response
   */
  contractorIndividual?: PostSecuritiesRequestContractorIndividual;
  /**
   *
   * @type {PostSecuritiesRequestContractorCorporation}
   * @memberof GetSecurities200Response
   */
  contractorCorporation?: PostSecuritiesRequestContractorCorporation;
  /**
   *
   * @type {PostSecuritiesRequestInsured}
   * @memberof GetSecurities200Response
   */
  insured?: PostSecuritiesRequestInsured;
  /**
   *
   * @type {PostSecuritiesRequestInsuranceInfo}
   * @memberof GetSecurities200Response
   */
  insuranceInfo: PostSecuritiesRequestInsuranceInfo;
  /**
   *
   * @type {GetSecurities200ResponseLifeInsuranceInfo}
   * @memberof GetSecurities200Response
   */
  lifeInsuranceInfo?: GetSecurities200ResponseLifeInsuranceInfo;
  /**
   *
   * @type {GetSecurities200ResponseNonLifeInsuranceInfo}
   * @memberof GetSecurities200Response
   */
  nonLifeInsuranceInfo?: GetSecurities200ResponseNonLifeInsuranceInfo;
  /**
   *
   * @type {PostContractReportRequestCommissionShare}
   * @memberof GetSecurities200Response
   */
  commissionShare: PostContractReportRequestCommissionShare;
  /**
   *
   * @type {PostContractReportRequestIncentiveShare}
   * @memberof GetSecurities200Response
   */
  incentiveShare: PostContractReportRequestIncentiveShare;
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetSecurities200Response
   */
  staffId: number;
}
/**
 * 生保固有の情報。insuranceCategoryがlifeInsuranceの場合は必須で、それ以外は未設定
 * @export
 * @interface GetSecurities200ResponseLifeInsuranceInfo
 */
export interface GetSecurities200ResponseLifeInsuranceInfo {
  /**
   * 契約日
   * @type {string}
   * @memberof GetSecurities200ResponseLifeInsuranceInfo
   */
  contractDateOn?: string;
}
/**
 * 損保固有の情報。insuranceCategoryがnonLifeInsuranceの場合は必須で、それ以外は未設定。
 * @export
 * @interface GetSecurities200ResponseNonLifeInsuranceInfo
 */
export interface GetSecurities200ResponseNonLifeInsuranceInfo {
  /**
   * 申込日
   * @type {string}
   * @memberof GetSecurities200ResponseNonLifeInsuranceInfo
   */
  applicationDateOn?: string;
  /**
   * 保険始期
   * @type {string}
   * @memberof GetSecurities200ResponseNonLifeInsuranceInfo
   */
  insuranceStartDateOn?: string;
  /**
   * 保険終期
   * @type {string}
   * @memberof GetSecurities200ResponseNonLifeInsuranceInfo
   */
  insuranceEndDateOn?: string;
  /**
   * 領収日
   * @type {string}
   * @memberof GetSecurities200ResponseNonLifeInsuranceInfo
   */
  receiptDateOn?: string;
  /**
   * 返戻日
   * @type {string}
   * @memberof GetSecurities200ResponseNonLifeInsuranceInfo
   */
  returnDateOn?: string;
  /**
   *
   * @type {NonLifeInsuranceAccountingCategory}
   * @memberof GetSecurities200ResponseNonLifeInsuranceInfo
   */
  nonLifeInsuranceAccountingCategory?: NonLifeInsuranceAccountingCategory;
  /**
   *
   * @type {NonLifeInsuranceDepositCategory}
   * @memberof GetSecurities200ResponseNonLifeInsuranceInfo
   */
  nonLifeInsuranceDepositCategory?: NonLifeInsuranceDepositCategory;
}
/**
 * <dl>    <dt>案件情報</dt>    </dl>
 * @export
 * @interface GetSecurities200ResponsePotentialCustomerInfo
 */
export interface GetSecurities200ResponsePotentialCustomerInfo {
  /**
   * 案件ID
   * @type {number}
   * @memberof GetSecurities200ResponsePotentialCustomerInfo
   */
  potentialCustomerId?: number;
  /**
   *
   * @type {PotentialCustomerType}
   * @memberof GetSecurities200ResponsePotentialCustomerInfo
   */
  potentialCustomerType: PotentialCustomerType;
  /**
   * サポート利用有無（true：利用あり）
   * @type {boolean}
   * @memberof GetSecurities200ResponsePotentialCustomerInfo
   */
  supportUse?: boolean;
  /**
   * マーケットID
   * @type {number}
   * @memberof GetSecurities200ResponsePotentialCustomerInfo
   */
  marketId?: number;
}
/**
 *
 * @export
 * @interface GetSecuritiesList200Response
 */
export interface GetSecuritiesList200Response {
  /**
   * 証券の検索結果
   * @type {Array<GetSecuritiesList200ResponseResultsInner>}
   * @memberof GetSecuritiesList200Response
   */
  results?: Array<GetSecuritiesList200ResponseResultsInner>;
  /**
   *
   * @type {Pagination}
   * @memberof GetSecuritiesList200Response
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface GetSecuritiesList200ResponseResultsInner
 */
export interface GetSecuritiesList200ResponseResultsInner {
  /**
   * 証券ID
   * @type {number}
   * @memberof GetSecuritiesList200ResponseResultsInner
   */
  securitiesId: number;
  /**
   *
   * @type {GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff}
   * @memberof GetSecuritiesList200ResponseResultsInner
   */
  staff: GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff;
  /**
   *
   * @type {InsuranceCategory}
   * @memberof GetSecuritiesList200ResponseResultsInner
   */
  insuranceCategory: InsuranceCategory;
  /**
   *
   * @type {ContractorAttribute}
   * @memberof GetSecuritiesList200ResponseResultsInner
   */
  contractorAttribute: ContractorAttribute;
  /**
   *
   * @type {GetSecuritiesList200ResponseResultsInnerContractorIndividual}
   * @memberof GetSecuritiesList200ResponseResultsInner
   */
  contractorIndividual?: GetSecuritiesList200ResponseResultsInnerContractorIndividual;
  /**
   *
   * @type {GetSecuritiesList200ResponseResultsInnerContractorCorporation}
   * @memberof GetSecuritiesList200ResponseResultsInner
   */
  contractorCorporation?: GetSecuritiesList200ResponseResultsInnerContractorCorporation;
  /**
   *
   * @type {GetSecuritiesList200ResponseResultsInnerInsured}
   * @memberof GetSecuritiesList200ResponseResultsInner
   */
  insured?: GetSecuritiesList200ResponseResultsInnerInsured;
  /**
   *
   * @type {GetSecuritiesList200ResponseResultsInnerInsuranceInfo}
   * @memberof GetSecuritiesList200ResponseResultsInner
   */
  insuranceInfo: GetSecuritiesList200ResponseResultsInnerInsuranceInfo;
}
/**
 * <dl>   <dt>contractorAttributeがcorporationの場合に設定される契約者の情報</dt> </dl>
 * @export
 * @interface GetSecuritiesList200ResponseResultsInnerContractorCorporation
 */
export interface GetSecuritiesList200ResponseResultsInnerContractorCorporation {
  /**
   * 法人名
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorCorporation
   */
  corporationName?: string;
  /**
   * 決算月
   * @type {number}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorCorporation
   */
  corporationClosingMonth?: number;
  /**
   * 法人代表者名前
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorCorporation
   */
  corporationRepresentativeFirstName?: string;
  /**
   * 法人代表者苗字
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorCorporation
   */
  corporationRepresentativeLastName?: string;
  /**
   *
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorCorporation
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorCorporation
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorCorporation
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorCorporation
   */
  address3?: string;
  /**
   * 電話番号下4桁
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorCorporation
   */
  telLast?: string;
}
/**
 * <dl>   <dt>contractorAttributeがindividualの場合に設定される契約者の情報</dt> </dl>
 * @export
 * @interface GetSecuritiesList200ResponseResultsInnerContractorIndividual
 */
export interface GetSecuritiesList200ResponseResultsInnerContractorIndividual {
  /**
   * 名前
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorIndividual
   */
  firstName?: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorIndividual
   */
  lastName?: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorIndividual
   */
  firstNameKana?: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorIndividual
   */
  lastNameKana?: string;
  /**
   * 生年月日
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorIndividual
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorIndividual
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorIndividual
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorIndividual
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorIndividual
   */
  address3?: string;
  /**
   * 電話番号下4桁
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerContractorIndividual
   */
  telLast?: string;
}
/**
 * 生保・損保ともに共通する保険の情報
 * @export
 * @interface GetSecuritiesList200ResponseResultsInnerInsuranceInfo
 */
export interface GetSecuritiesList200ResponseResultsInnerInsuranceInfo {
  /**
   * 保険会社ID
   * @type {number}
   * @memberof GetSecuritiesList200ResponseResultsInnerInsuranceInfo
   */
  insuranceCompanyId: number;
  /**
   * 保険会社名
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerInsuranceInfo
   */
  insuranceCompanyName: string;
  /**
   * 保険種類ID
   * @type {number}
   * @memberof GetSecuritiesList200ResponseResultsInnerInsuranceInfo
   */
  insuranceTypeId: number;
  /**
   * 保険種類名
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerInsuranceInfo
   */
  insuranceTypeName?: string;
  /**
   * 保険料
   * @type {number}
   * @memberof GetSecuritiesList200ResponseResultsInnerInsuranceInfo
   */
  insuranceFee: number;
  /**
   * 証券番号
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerInsuranceInfo
   */
  policyNumber?: string;
}
/**
 * 被保険者の情報
 * @export
 * @interface GetSecuritiesList200ResponseResultsInnerInsured
 */
export interface GetSecuritiesList200ResponseResultsInnerInsured {
  /**
   * 名前
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerInsured
   */
  firstName?: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerInsured
   */
  lastName?: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerInsured
   */
  firstNameKana?: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerInsured
   */
  lastNameKana?: string;
  /**
   * 生年月日
   * @type {string}
   * @memberof GetSecuritiesList200ResponseResultsInnerInsured
   */
  dateOfBirth?: string;
}
/**
 *
 * @export
 * @interface GetStaff200Response
 */
export interface GetStaff200Response {
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetStaff200Response
   */
  staffId: number;
  /**
   * 社員番号
   * @type {string}
   * @memberof GetStaff200Response
   */
  staffNumber: string;
  /**
   *
   * @type {StaffRoleType}
   * @memberof GetStaff200Response
   */
  staffRoleType: StaffRoleType;
  /**
   * アカウント有効フラグ（true：アカウント有効、false：アカウント無効）
   * @type {boolean}
   * @memberof GetStaff200Response
   */
  accountActiveFlag: boolean;
  /**
   * 業績給対象有無（true：対象）
   * @type {boolean}
   * @memberof GetStaff200Response
   */
  performanceBasedPayFlag: boolean;
  /**
   *
   * @type {PostStaffRequestPersonal}
   * @memberof GetStaff200Response
   */
  personal: PostStaffRequestPersonal;
  /**
   *
   * @type {PostStaffRequestEmergencyContact}
   * @memberof GetStaff200Response
   */
  emergencyContact: PostStaffRequestEmergencyContact;
  /**
   * <dl>    <dt>在籍所属</dt>   <dt>affiliationAtFromの降順</dt>  </dl>
   * @type {Array<GetStaff200ResponseDepartmentAffiliationInner>}
   * @memberof GetStaff200Response
   */
  departmentAffiliation: Array<GetStaff200ResponseDepartmentAffiliationInner>;
  /**
   *
   * @type {PostStaffRequestCompanyAffiliation}
   * @memberof GetStaff200Response
   */
  companyAffiliation: PostStaffRequestCompanyAffiliation;
  /**
   *
   * @type {GetStaff200ResponsePerformanceBasedPay}
   * @memberof GetStaff200Response
   */
  performanceBasedPay?: GetStaff200ResponsePerformanceBasedPay;
  /**
   *
   * @type {PostStaffRequestCommissionShareDefault}
   * @memberof GetStaff200Response
   */
  commissionShareDefault: PostStaffRequestCommissionShareDefault;
  /**
   *
   * @type {PostStaffRequestCommissionShareDefault}
   * @memberof GetStaff200Response
   */
  incentiveShareDefault: PostStaffRequestCommissionShareDefault;
  /**
   *
   * @type {PostStaffRequestLicense}
   * @memberof GetStaff200Response
   */
  license?: PostStaffRequestLicense;
}
/**
 *
 * @export
 * @interface GetStaff200ResponseDepartmentAffiliationInner
 */
export interface GetStaff200ResponseDepartmentAffiliationInner {
  /**
   * 在籍所属ID
   * @type {number}
   * @memberof GetStaff200ResponseDepartmentAffiliationInner
   */
  departmentAffiliationId: number;
  /**
   * 所属ID
   * @type {number}
   * @memberof GetStaff200ResponseDepartmentAffiliationInner
   */
  departmentId: number;
  /**
   * 所属期間
   * @type {string}
   * @memberof GetStaff200ResponseDepartmentAffiliationInner
   */
  affiliationAtFrom: string;
  /**
   * 所属期間
   * @type {string}
   * @memberof GetStaff200ResponseDepartmentAffiliationInner
   */
  affiliationAtTo?: string;
}
/**
 * <dl>    <dt>業績給計算に関わる情報</dt>   <dt>performanceBasedPayFlagがtrueの場合は必須で、それ以外は未設定</dt>    </dl>
 * @export
 * @interface GetStaff200ResponsePerformanceBasedPay
 */
export interface GetStaff200ResponsePerformanceBasedPay {
  /**
   * 休業フラグ（true：休業）
   * @type {boolean}
   * @memberof GetStaff200ResponsePerformanceBasedPay
   */
  closedFlag: boolean;
  /**
   * 雇用保険対象有無（true：対象）
   * @type {boolean}
   * @memberof GetStaff200ResponsePerformanceBasedPay
   */
  employmentInsuranceFlag: boolean;
  /**
   * 介護保険対象有無（true：対象）
   * @type {boolean}
   * @memberof GetStaff200ResponsePerformanceBasedPay
   */
  longTermCareInsuranceFlag: boolean;
  /**
   * 健康保険標準報酬月額
   * @type {number}
   * @memberof GetStaff200ResponsePerformanceBasedPay
   */
  healthInsuranceStandardMonthlyRemuneration: number;
  /**
   * 厚生年金標準報酬月額
   * @type {number}
   * @memberof GetStaff200ResponsePerformanceBasedPay
   */
  welfarePensionStandardMonthlyRemuneration: number;
  /**
   * 基本給
   * @type {number}
   * @memberof GetStaff200ResponsePerformanceBasedPay
   */
  basicSalary: number;
  /**
   * エントリーフィー
   * @type {number}
   * @memberof GetStaff200ResponsePerformanceBasedPay
   */
  entryFee: number;
  /**
   * 基本給控除（true：控除）
   * @type {boolean}
   * @memberof GetStaff200ResponsePerformanceBasedPay
   */
  basicSalaryDeductionFlag: boolean;
  /**
   * 社会保険料控除（true：控除）
   * @type {boolean}
   * @memberof GetStaff200ResponsePerformanceBasedPay
   */
  socialInsurancePremiumDeductionFlag: boolean;
  /**
   * インボイス対象有無（true：対象）
   * @type {boolean}
   * @memberof GetStaff200ResponsePerformanceBasedPay
   */
  invoiceBasedFlag: boolean;
  /**
   * インボイス登録番号
   * @type {string}
   * @memberof GetStaff200ResponsePerformanceBasedPay
   */
  invoiceCode?: string;
  /**
   * 業績給振替先募集人ID
   * @type {number}
   * @memberof GetStaff200ResponsePerformanceBasedPay
   */
  performanceBasedPayToStaffId?: number;
  /**
   * 固定給選択制度（true：対象）
   * @type {boolean}
   * @memberof GetStaff200ResponsePerformanceBasedPay
   */
  fixedSalaryFlag: boolean;
  /**
   * 特別枠控除（true：対象）
   * @type {boolean}
   * @memberof GetStaff200ResponsePerformanceBasedPay
   */
  specialDeductionFlag: boolean;
  /**
   * 業績給対象期間From
   * @type {string}
   * @memberof GetStaff200ResponsePerformanceBasedPay
   */
  performanceBasedPayTermFrom?: string;
  /**
   * 業績給対象期間To
   * @type {string}
   * @memberof GetStaff200ResponsePerformanceBasedPay
   */
  performanceBasedPayTermTo?: string;
}
/**
 *
 * @export
 * @interface GetStaffCurrent200Response
 */
export interface GetStaffCurrent200Response {
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetStaffCurrent200Response
   */
  staffId: number;
  /**
   * 社員番号
   * @type {string}
   * @memberof GetStaffCurrent200Response
   */
  staffNumber: string;
  /**
   *
   * @type {GetStaffCurrent200ResponsePersonal}
   * @memberof GetStaffCurrent200Response
   */
  personal: GetStaffCurrent200ResponsePersonal;
  /**
   *
   * @type {GetStaffCurrent200ResponseDepartmentAffiliation}
   * @memberof GetStaffCurrent200Response
   */
  departmentAffiliation?: GetStaffCurrent200ResponseDepartmentAffiliation;
  /**
   *
   * @type {GetStaffCurrent200ResponseCommissionShareDefault}
   * @memberof GetStaffCurrent200Response
   */
  commissionShareDefault: GetStaffCurrent200ResponseCommissionShareDefault;
  /**
   *
   * @type {GetStaffCurrent200ResponseCommissionShareDefault}
   * @memberof GetStaffCurrent200Response
   */
  incentiveShareDefault: GetStaffCurrent200ResponseCommissionShareDefault;
}
/**
 * <dl>    <dt>取分情報のデフォルト値</dt>   <dt>%指定</dt>    </dl>
 * @export
 * @interface GetStaffCurrent200ResponseCommissionShareDefault
 */
export interface GetStaffCurrent200ResponseCommissionShareDefault {
  /**
   * <dl>    <dt>会社の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof GetStaffCurrent200ResponseCommissionShareDefault
   */
  companyShare: string;
  /**
   * <dl>    <dt>担当募集人の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof GetStaffCurrent200ResponseCommissionShareDefault
   */
  myselfShare: string;
  /**
   * <dl>    <dt>所属長の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof GetStaffCurrent200ResponseCommissionShareDefault
   */
  bossShare: string;
  /**
   * その他募集人ID
   * @type {number}
   * @memberof GetStaffCurrent200ResponseCommissionShareDefault
   */
  otherStaffId1?: number;
  /**
   * <dl>    <dt>その他募集人の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof GetStaffCurrent200ResponseCommissionShareDefault
   */
  otherStaffShare1: string;
  /**
   * その他募集人ID
   * @type {number}
   * @memberof GetStaffCurrent200ResponseCommissionShareDefault
   */
  otherStaffId2?: number;
  /**
   * <dl>    <dt>その他募集人の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof GetStaffCurrent200ResponseCommissionShareDefault
   */
  otherStaffShare2: string;
  /**
   * その他募集人ID
   * @type {number}
   * @memberof GetStaffCurrent200ResponseCommissionShareDefault
   */
  otherStaffId3?: number;
  /**
   * <dl>    <dt>その他募集人の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof GetStaffCurrent200ResponseCommissionShareDefault
   */
  otherStaffShare3: string;
}
/**
 * <dl>    <dt>ユーザの現在の在籍所属情報</dt>   <dt>所属が無い場合はdepartmentAffiliationは未設定</dt>    </dl>
 * @export
 * @interface GetStaffCurrent200ResponseDepartmentAffiliation
 */
export interface GetStaffCurrent200ResponseDepartmentAffiliation {
  /**
   * 所属ID
   * @type {number}
   * @memberof GetStaffCurrent200ResponseDepartmentAffiliation
   */
  departmentId: number;
  /**
   * 所属名
   * @type {string}
   * @memberof GetStaffCurrent200ResponseDepartmentAffiliation
   */
  departmentName: string;
  /**
   *
   * @type {GetStaffCurrent200ResponseDepartmentAffiliationBoss}
   * @memberof GetStaffCurrent200ResponseDepartmentAffiliation
   */
  boss?: GetStaffCurrent200ResponseDepartmentAffiliationBoss;
}
/**
 * <dl>    <dt>現在時点の所属長を返す</dt>   <dt>所属に所属長がいない場合はbossは未設定</dt>  </dl>
 * @export
 * @interface GetStaffCurrent200ResponseDepartmentAffiliationBoss
 */
export interface GetStaffCurrent200ResponseDepartmentAffiliationBoss {
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetStaffCurrent200ResponseDepartmentAffiliationBoss
   */
  staffId: number;
  /**
   * 社員番号
   * @type {string}
   * @memberof GetStaffCurrent200ResponseDepartmentAffiliationBoss
   */
  staffNumber: string;
  /**
   * 名前
   * @type {string}
   * @memberof GetStaffCurrent200ResponseDepartmentAffiliationBoss
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetStaffCurrent200ResponseDepartmentAffiliationBoss
   */
  lastName: string;
}
/**
 * <dl>    <dt>ユーザの情報</dt>    </dl>
 * @export
 * @interface GetStaffCurrent200ResponsePersonal
 */
export interface GetStaffCurrent200ResponsePersonal {
  /**
   * 名前
   * @type {string}
   * @memberof GetStaffCurrent200ResponsePersonal
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetStaffCurrent200ResponsePersonal
   */
  lastName: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof GetStaffCurrent200ResponsePersonal
   */
  firstNameKana: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof GetStaffCurrent200ResponsePersonal
   */
  lastNameKana: string;
}
/**
 *
 * @export
 * @interface GetStaffMaps200Response
 */
export interface GetStaffMaps200Response {
  /**
   * StaffMap一覧
   * @type {Array<GetStaffMaps200ResponseResultsInner>}
   * @memberof GetStaffMaps200Response
   */
  results: Array<GetStaffMaps200ResponseResultsInner>;
  /**
   *
   * @type {Pagination}
   * @memberof GetStaffMaps200Response
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface GetStaffMaps200ResponseResultsInner
 */
export interface GetStaffMaps200ResponseResultsInner {
  /**
   * 保険会社募集人ID
   * @type {string}
   * @memberof GetStaffMaps200ResponseResultsInner
   */
  insuranceCompanyStaffId: string;
  /**
   *
   * @type {GetCommissions200ResponseInsuranceCompanyInfo}
   * @memberof GetStaffMaps200ResponseResultsInner
   */
  insuranceCompany: GetCommissions200ResponseInsuranceCompanyInfo;
  /**
   *
   * @type {GetContractReportsCommissionMatching200ResponseResultsInnerStaff}
   * @memberof GetStaffMaps200ResponseResultsInner
   */
  staff: GetContractReportsCommissionMatching200ResponseResultsInnerStaff;
}
/**
 *
 * @export
 * @interface GetStaffs200Response
 */
export interface GetStaffs200Response {
  /**
   * ユーザ一覧
   * @type {Array<GetStaffs200ResponseResultsInner>}
   * @memberof GetStaffs200Response
   */
  results: Array<GetStaffs200ResponseResultsInner>;
  /**
   *
   * @type {Pagination}
   * @memberof GetStaffs200Response
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface GetStaffs200ResponseResultsInner
 */
export interface GetStaffs200ResponseResultsInner {
  /**
   * 募集人ID
   * @type {number}
   * @memberof GetStaffs200ResponseResultsInner
   */
  staffId: number;
  /**
   * 社員番号
   * @type {string}
   * @memberof GetStaffs200ResponseResultsInner
   */
  staffNumber?: string;
  /**
   * 名前
   * @type {string}
   * @memberof GetStaffs200ResponseResultsInner
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof GetStaffs200ResponseResultsInner
   */
  lastName: string;
  /**
   * アカウント有効フラグ（true：アカウント有効、false：アカウント無効）
   * @type {boolean}
   * @memberof GetStaffs200ResponseResultsInner
   */
  accountActiveFlag: boolean;
}
/**
 * 保険カテゴリー
 * @export
 * @enum {string}
 */

export const InsuranceCategory = {
  LifeInsurance: "lifeInsurance",
  NonLifeInsurance: "nonLifeInsurance",
} as const;

export type InsuranceCategory =
  (typeof InsuranceCategory)[keyof typeof InsuranceCategory];

/**
 *
 * @export
 * @interface InsuranceCompany
 */
export interface InsuranceCompany {
  /**
   * 保険会社ID
   * @type {number}
   * @memberof InsuranceCompany
   */
  insuranceCompanyId: number;
  /**
   * 保険会社名
   * @type {string}
   * @memberof InsuranceCompany
   */
  insuranceCompanyName: string;
  /**
   * 内税フラグ
   * @type {boolean}
   * @memberof InsuranceCompany
   */
  consumptionTaxIncludeFlag: boolean;
  /**
   *
   * @type {InsuranceCategory}
   * @memberof InsuranceCompany
   */
  insuranceCategory: InsuranceCategory;
  /**
   * 表示順
   * @type {number}
   * @memberof InsuranceCompany
   */
  sortNumber: number;
}
/**
 *
 * @export
 * @interface Market
 */
export interface Market {
  /**
   * マーケットID
   * @type {number}
   * @memberof Market
   */
  marketId: number;
  /**
   * マーケット名
   * @type {string}
   * @memberof Market
   */
  marketName: string;
  /**
   * 表示順
   * @type {number}
   * @memberof Market
   */
  sortNumber: number;
}
/**
 * API Error時のBody
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   * ErrorMessage
   * @type {string}
   * @memberof ModelError
   */
  message?: string;
}
/**
 * 計上区分
 * @export
 * @enum {string}
 */

export const NonLifeInsuranceAccountingCategory = {
  New: "new",
  Renewal: "renewal",
  Transfer: "transfer",
  Unknown: "unknown",
} as const;

export type NonLifeInsuranceAccountingCategory =
  (typeof NonLifeInsuranceAccountingCategory)[keyof typeof NonLifeInsuranceAccountingCategory];

/**
 * 入金区分
 * @export
 * @enum {string}
 */

export const NonLifeInsuranceDepositCategory = {
  DirectDebit: "directDebit",
  AgencyAccount: "agencyAccount",
  CreditCard: "creditCard",
  SalaryDeduction: "salaryDeduction",
  PaymentSlip: "paymentSlip",
  Unknown: "unknown",
} as const;

export type NonLifeInsuranceDepositCategory =
  (typeof NonLifeInsuranceDepositCategory)[keyof typeof NonLifeInsuranceDepositCategory];

/**
 *
 * @export
 * @interface Pagination
 */
export interface Pagination {
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  totalCount: number;
}
/**
 * 支払方法
 * @export
 * @enum {string}
 */

export const PaymentMethod = {
  MonthlyPayment: "monthlyPayment",
  SemiAnnualPayment: "semiAnnualPayment",
  AnnualPayment: "annualPayment",
  OneTimePayment: "oneTimePayment",
  Prepayment: "prepayment",
} as const;

export type PaymentMethod = (typeof PaymentMethod)[keyof typeof PaymentMethod];

/**
 * -> <dl> <dt>支払額に対して減額する金額はマイナス表記で返す</dt> </dl>
 * @export
 * @interface PerformanceBasedPayResult
 */
export interface PerformanceBasedPayResult {
  /**
   *
   * @type {GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff}
   * @memberof PerformanceBasedPayResult
   */
  staff: GetPotentialCustomerIndividualPersons200ResponseResultsInnerStaff;
  /**
   *
   * @type {PerformanceBasedPayResultCommission}
   * @memberof PerformanceBasedPayResult
   */
  commission: PerformanceBasedPayResultCommission;
  /**
   *
   * @type {PerformanceBasedPayResultBasePoint}
   * @memberof PerformanceBasedPayResult
   */
  basePoint: PerformanceBasedPayResultBasePoint;
  /**
   *
   * @type {PerformanceBasedPayResultSocialInsurancePremiumsForPerformanceBasedPay}
   * @memberof PerformanceBasedPayResult
   */
  socialInsurancePremiumsForPerformanceBasedPay: PerformanceBasedPayResultSocialInsurancePremiumsForPerformanceBasedPay;
  /**
   *
   * @type {PerformanceBasedPayResultPurchasePotentialCustomer}
   * @memberof PerformanceBasedPayResult
   */
  purchasePotentialCustomer: PerformanceBasedPayResultPurchasePotentialCustomer;
  /**
   *
   * @type {PerformanceBasedPayResultDeductionForValuables}
   * @memberof PerformanceBasedPayResult
   */
  deductionForValuables: PerformanceBasedPayResultDeductionForValuables;
  /**
   *
   * @type {PerformanceBasedPayResultPerformanceBasedPayTransfer}
   * @memberof PerformanceBasedPayResult
   */
  performanceBasedPayTransfer: PerformanceBasedPayResultPerformanceBasedPayTransfer;
  /**
   *
   * @type {PerformanceBasedPayResultOtherDeduction}
   * @memberof PerformanceBasedPayResult
   */
  otherDeduction: PerformanceBasedPayResultOtherDeduction;
  /**
   *
   * @type {PerformanceBasedPayResultAdjustment}
   * @memberof PerformanceBasedPayResult
   */
  adjustment: PerformanceBasedPayResultAdjustment;
  /**
   * インボイス控除額
   * @type {number}
   * @memberof PerformanceBasedPayResult
   */
  invoiceDeductionFee: number;
  /**
   *
   * @type {PerformanceBasedPayResultPerformanceBasedPayCalculationResult}
   * @memberof PerformanceBasedPayResult
   */
  performanceBasedPayCalculationResult: PerformanceBasedPayResultPerformanceBasedPayCalculationResult;
}
/**
 * 調整額
 * @export
 * @interface PerformanceBasedPayResultAdjustment
 */
export interface PerformanceBasedPayResultAdjustment {
  /**
   * 調整額合計
   * @type {number}
   * @memberof PerformanceBasedPayResultAdjustment
   */
  total: number;
  /**
   * <dl>    <dt>調整額</dt>    <dt>adjustmentDeductionTypeAttributeがadjustmentのadjustmentDeductionTypeのadjustmentDeductionが対象</dt>    <dt>ソート順</dt>   <dd>     <ul>       <li>adjustmentDeductionTypeIdの昇順</li>       <li>adjustmentDeductionIdの昇順</li>     </ul>   </dd>  </dl>
   * @type {Array<PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner>}
   * @memberof PerformanceBasedPayResultAdjustment
   */
  adjustments: Array<PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner>;
}
/**
 * 基本ポイント
 * @export
 * @interface PerformanceBasedPayResultBasePoint
 */
export interface PerformanceBasedPayResultBasePoint {
  /**
   * 基本ポイント合計額
   * @type {number}
   * @memberof PerformanceBasedPayResultBasePoint
   */
  total: number;
  /**
   * 基本給
   * @type {number}
   * @memberof PerformanceBasedPayResultBasePoint
   */
  basicSalary: number;
  /**
   * 基本給消費税
   * @type {number}
   * @memberof PerformanceBasedPayResultBasePoint
   */
  basicSalaryConsumptionTax: number;
  /**
   * エントリーフィー
   * @type {number}
   * @memberof PerformanceBasedPayResultBasePoint
   */
  entryFee: number;
  /**
   * 健康保険料
   * @type {number}
   * @memberof PerformanceBasedPayResultBasePoint
   */
  healthInsurancePremium: number;
  /**
   * 介護保険料
   * @type {number}
   * @memberof PerformanceBasedPayResultBasePoint
   */
  longTermCareInsurancePremium: number;
  /**
   * 厚生年金保険料
   * @type {number}
   * @memberof PerformanceBasedPayResultBasePoint
   */
  welfarePensionPremium: number;
  /**
   * 子供子育て拠出金
   * @type {number}
   * @memberof PerformanceBasedPayResultBasePoint
   */
  childCareContribution: number;
  /**
   * 基本給に関わる一般拠出金
   * @type {number}
   * @memberof PerformanceBasedPayResultBasePoint
   */
  generalContributionForBasicSalary: number;
  /**
   * 基本給に関わる雇用保険料
   * @type {number}
   * @memberof PerformanceBasedPayResultBasePoint
   */
  employmentInsurancePremiumForBasicSalary: number;
  /**
   * 基本給に関わる労災保険料
   * @type {number}
   * @memberof PerformanceBasedPayResultBasePoint
   */
  industrialAccidentInsurancePremiumForBasicSalary: number;
}
/**
 * 手数料計算結果
 * @export
 * @interface PerformanceBasedPayResultCommission
 */
export interface PerformanceBasedPayResultCommission {
  /**
   * 手数料合計
   * @type {number}
   * @memberof PerformanceBasedPayResultCommission
   */
  total: number;
  /**
   * 生命保険手数料合計
   * @type {number}
   * @memberof PerformanceBasedPayResultCommission
   */
  lifeInsuranceCommission: number;
  /**
   * 損害保険手数料合計
   * @type {number}
   * @memberof PerformanceBasedPayResultCommission
   */
  nonLifeInsuranceCommission: number;
}
/**
 * 有価品控除
 * @export
 * @interface PerformanceBasedPayResultDeductionForValuables
 */
export interface PerformanceBasedPayResultDeductionForValuables {
  /**
   * 有価品控除合計額
   * @type {number}
   * @memberof PerformanceBasedPayResultDeductionForValuables
   */
  total: number;
  /**
   * <dl>    <dt>有価品の控除額</dt>    <dt>adjustmentDeductionTypeAttributeがdeductionForValuablesのadjustmentDeductionTypeのadjustmentDeductionが対象</dt>    <dt>ソート順</dt>   <dd>     <ul>       <li>adjustmentDeductionTypeIdの昇順</li>       <li>adjustmentDeductionIdの昇順</li>     </ul>   </dd>  </dl>
   * @type {Array<PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner>}
   * @memberof PerformanceBasedPayResultDeductionForValuables
   */
  deductions: Array<PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner>;
}
/**
 * その他控除額
 * @export
 * @interface PerformanceBasedPayResultOtherDeduction
 */
export interface PerformanceBasedPayResultOtherDeduction {
  /**
   * その他控除合計額
   * @type {number}
   * @memberof PerformanceBasedPayResultOtherDeduction
   */
  total: number;
  /**
   * <dl>    <dt>控除額</dt>    <dt>adjustmentDeductionTypeAttributeがdeductionのadjustmentDeductionTypeのadjustmentDeductionが対象</dt>    <dt>ソート順</dt>   <dd>     <ul>       <li>adjustmentDeductionTypeIdの昇順</li>       <li>adjustmentDeductionIdの昇順</li>     </ul>   </dd>  </dl>
   * @type {Array<PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner>}
   * @memberof PerformanceBasedPayResultOtherDeduction
   */
  deductions: Array<PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner>;
}
/**
 * 業績給計算結果
 * @export
 * @interface PerformanceBasedPayResultPerformanceBasedPayCalculationResult
 */
export interface PerformanceBasedPayResultPerformanceBasedPayCalculationResult {
  /**
   * 業績給額（消費税込）
   * @type {number}
   * @memberof PerformanceBasedPayResultPerformanceBasedPayCalculationResult
   */
  performanceBasedPay: number;
  /**
   * <dl>    <dt>今月の業績給繰越額</dt>   <dd>今月の業績給額（消費税込）がマイナス値の場合、マイナス値を設定します</dd>  </dl>
   * @type {number}
   * @memberof PerformanceBasedPayResultPerformanceBasedPayCalculationResult
   */
  amountCarriedForward: number;
  /**
   * <dl>    <dt>前月の業績給繰越額</dt>   <dd>前月の業績給額（消費税込）がマイナス値の場合、マイナス値を設定します</dd>  </dl>
   * @type {number}
   * @memberof PerformanceBasedPayResultPerformanceBasedPayCalculationResult
   */
  amountCarriedForwardFromPreviousMonth: number;
  /**
   * 外交員報酬支払額
   * @type {number}
   * @memberof PerformanceBasedPayResultPerformanceBasedPayCalculationResult
   */
  salesmanReward: number;
  /**
   * 外交員報酬支払額にかかる事業所得税
   * @type {number}
   * @memberof PerformanceBasedPayResultPerformanceBasedPayCalculationResult
   */
  businessIncomeTax: number;
}
/**
 * 業績給振替
 * @export
 * @interface PerformanceBasedPayResultPerformanceBasedPayTransfer
 */
export interface PerformanceBasedPayResultPerformanceBasedPayTransfer {
  /**
   * 業績給振替合計額
   * @type {number}
   * @memberof PerformanceBasedPayResultPerformanceBasedPayTransfer
   */
  total: number;
  /**
   * <dl>    <dt>業績給振替</dt>    <dt>adjustmentDeductionTypeAttributeがperformanceBasedPayTransferのadjustmentDeductionTypeのadjustmentDeductionが対象</dt>    <dt>ソート順</dt>   <dd>     <ul>       <li>adjustmentDeductionTypeIdの昇順</li>       <li>adjustmentDeductionIdの昇順</li>     </ul>   </dd>  </dl>
   * @type {Array<PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner>}
   * @memberof PerformanceBasedPayResultPerformanceBasedPayTransfer
   */
  performanceBasedPayTransfers: Array<PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner>;
}
/**
 * 購入案件
 * @export
 * @interface PerformanceBasedPayResultPurchasePotentialCustomer
 */
export interface PerformanceBasedPayResultPurchasePotentialCustomer {
  /**
   * 購入案件合計額（購入案件とマーケット控除額の合計）
   * @type {number}
   * @memberof PerformanceBasedPayResultPurchasePotentialCustomer
   */
  total: number;
  /**
   * <dl>    <dt>購入案件</dt>      <dt>ソート順</dt>   <dd>     <ul>       <li>marketIdの昇順</li>       <li>purchasePotentialCustomerIdの昇順</li>     </ul>   </dd>  </dl>
   * @type {Array<PerformanceBasedPayResultPurchasePotentialCustomerPurchasePotentialCustomersInner>}
   * @memberof PerformanceBasedPayResultPurchasePotentialCustomer
   */
  purchasePotentialCustomers: Array<PerformanceBasedPayResultPurchasePotentialCustomerPurchasePotentialCustomersInner>;
  /**
   * <dl>    <dt>マーケット関連の控除額</dt>    <dt>adjustmentDeductionTypeAttributeがmarketDeductionのadjustmentDeductionTypeのadjustmentDeductionが対象</dt>    <dt>ソート順</dt>   <dd>     <ul>       <li>adjustmentDeductionTypeIdの昇順</li>       <li>adjustmentDeductionIdの昇順</li>     </ul>   </dd>  </dl>
   * @type {Array<PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner>}
   * @memberof PerformanceBasedPayResultPurchasePotentialCustomer
   */
  marketDeductions: Array<PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner>;
}
/**
 *
 * @export
 * @interface PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner
 */
export interface PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner {
  /**
   * 調整控除ID
   * @type {number}
   * @memberof PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner
   */
  adjustmentDeductionId: number;
  /**
   * 調整控除費
   * @type {number}
   * @memberof PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner
   */
  adjustmentDeductionFee: number;
  /**
   * 調整控除項目ID
   * @type {number}
   * @memberof PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner
   */
  adjustmentDeductionTypeId: number;
  /**
   * 調整控除項目名
   * @type {string}
   * @memberof PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner
   */
  adjustmentDeductionTypeName: string;
  /**
   * 調整控除名
   * @type {string}
   * @memberof PerformanceBasedPayResultPurchasePotentialCustomerMarketDeductionsInner
   */
  adjustmentDeductionName: string;
}
/**
 *
 * @export
 * @interface PerformanceBasedPayResultPurchasePotentialCustomerPurchasePotentialCustomersInner
 */
export interface PerformanceBasedPayResultPurchasePotentialCustomerPurchasePotentialCustomersInner {
  /**
   * マーケットID
   * @type {number}
   * @memberof PerformanceBasedPayResultPurchasePotentialCustomerPurchasePotentialCustomersInner
   */
  marketId: number;
  /**
   * マーケット名
   * @type {string}
   * @memberof PerformanceBasedPayResultPurchasePotentialCustomerPurchasePotentialCustomersInner
   */
  marketName: string;
  /**
   * 購入案件ID
   * @type {number}
   * @memberof PerformanceBasedPayResultPurchasePotentialCustomerPurchasePotentialCustomersInner
   */
  purchasePotentialCustomerId: number;
  /**
   * 購入案件支払額
   * @type {number}
   * @memberof PerformanceBasedPayResultPurchasePotentialCustomerPurchasePotentialCustomersInner
   */
  purchasePotentialCustomerFee: number;
  /**
   * 購入案件支払名
   * @type {string}
   * @memberof PerformanceBasedPayResultPurchasePotentialCustomerPurchasePotentialCustomersInner
   */
  purchasePotentialCustomerName: string;
}
/**
 * 業績給に関わる社会保険料
 * @export
 * @interface PerformanceBasedPayResultSocialInsurancePremiumsForPerformanceBasedPay
 */
export interface PerformanceBasedPayResultSocialInsurancePremiumsForPerformanceBasedPay {
  /**
   * 業績給に関わる社会保険料合計額
   * @type {number}
   * @memberof PerformanceBasedPayResultSocialInsurancePremiumsForPerformanceBasedPay
   */
  total: number;
  /**
   * 仮の業績給
   * @type {number}
   * @memberof PerformanceBasedPayResultSocialInsurancePremiumsForPerformanceBasedPay
   */
  estimatedPerformanceBasedPay: number;
}
/**
 *
 * @export
 * @interface PostAdjustmentDeductionCsv200Response
 */
export interface PostAdjustmentDeductionCsv200Response {
  /**
   * CSV登録件数
   * @type {number}
   * @memberof PostAdjustmentDeductionCsv200Response
   */
  createdCount: number;
  /**
   * CSV更新件数
   * @type {number}
   * @memberof PostAdjustmentDeductionCsv200Response
   */
  updatedCount: number;
  /**
   * CSV削除件数
   * @type {number}
   * @memberof PostAdjustmentDeductionCsv200Response
   */
  deletedCount: number;
}
/**
 *
 * @export
 * @interface PostAdjustmentDeductionIncentiveCsv200Response
 */
export interface PostAdjustmentDeductionIncentiveCsv200Response {
  /**
   * CSVインポート件数
   * @type {number}
   * @memberof PostAdjustmentDeductionIncentiveCsv200Response
   */
  importCount: number;
}
/**
 *
 * @export
 * @interface PostAdjustmentDeductionRequest
 */
export interface PostAdjustmentDeductionRequest {
  /**
   * 募集人ID
   * @type {number}
   * @memberof PostAdjustmentDeductionRequest
   */
  staffId: number;
  /**
   * 調整控除項目ID
   * @type {number}
   * @memberof PostAdjustmentDeductionRequest
   */
  adjustmentDeductionTypeId: number;
  /**
   * 調整控除名
   * @type {string}
   * @memberof PostAdjustmentDeductionRequest
   */
  adjustmentDeductionName: string;
  /**
   * 調整控除費
   * @type {number}
   * @memberof PostAdjustmentDeductionRequest
   */
  adjustmentDeductionFee: number;
  /**
   *
   * @type {PostAdjustmentDeductionRequestAdjustmentDeductionTerm}
   * @memberof PostAdjustmentDeductionRequest
   */
  adjustmentDeductionTerm: PostAdjustmentDeductionRequestAdjustmentDeductionTerm;
}
/**
 * 調整控除の有効期間
 * @export
 * @interface PostAdjustmentDeductionRequestAdjustmentDeductionTerm
 */
export interface PostAdjustmentDeductionRequestAdjustmentDeductionTerm {
  /**
   * 調整控除期間
   * @type {string}
   * @memberof PostAdjustmentDeductionRequestAdjustmentDeductionTerm
   */
  adjustmentDeductionTermFrom: string;
  /**
   * 調整控除期間
   * @type {string}
   * @memberof PostAdjustmentDeductionRequestAdjustmentDeductionTerm
   */
  adjustmentDeductionTermTo?: string;
}
/**
 *
 * @export
 * @interface PostAdjustmentDeductionTypeRequest
 */
export interface PostAdjustmentDeductionTypeRequest {
  /**
   *
   * @type {AdjustmentDeductionTypeAttribute}
   * @memberof PostAdjustmentDeductionTypeRequest
   */
  adjustmentDeductionTypeAttribute: AdjustmentDeductionTypeAttribute;
  /**
   * 調整控除項目名
   * @type {string}
   * @memberof PostAdjustmentDeductionTypeRequest
   */
  adjustmentDeductionTypeName: string;
  /**
   * 調整控除期間Fromのデフォルト月(現在月に対する加算月)
   * @type {number}
   * @memberof PostAdjustmentDeductionTypeRequest
   */
  adjustmentDeductionTermFromDefaultAddMonth: number;
  /**
   * 調整控除期間Toのデフォルト月(現在月に対する加算月)
   * @type {number}
   * @memberof PostAdjustmentDeductionTypeRequest
   */
  adjustmentDeductionTermToDefaultAddMonth?: number;
  /**
   * 調整控除費の正負のデフォルト（true：正、false：負）
   * @type {boolean}
   * @memberof PostAdjustmentDeductionTypeRequest
   */
  adjustmentDeductionFeeDefaultPlus: boolean;
  /**
   * 表示順
   * @type {number}
   * @memberof PostAdjustmentDeductionTypeRequest
   */
  sortNumber: number;
}
/**
 *
 * @export
 * @interface PostCommissionCsv200Response
 */
export interface PostCommissionCsv200Response {
  /**
   * 手数料取込ID
   * @type {number}
   * @memberof PostCommissionCsv200Response
   */
  commissionImportId: number;
  /**
   * 手数料CSVインポート件数
   * @type {number}
   * @memberof PostCommissionCsv200Response
   */
  commissionImportCount: number;
}
/**
 *
 * @export
 * @interface PostContractReportRequest
 */
export interface PostContractReportRequest {
  /**
   *
   * @type {InsuranceCategory}
   * @memberof PostContractReportRequest
   */
  insuranceCategory: InsuranceCategory;
  /**
   *
   * @type {ContractorAttribute}
   * @memberof PostContractReportRequest
   */
  contractorAttribute: ContractorAttribute;
  /**
   * 申達報告日
   * @type {string}
   * @memberof PostContractReportRequest
   */
  contractReportOn: string;
  /**
   *
   * @type {PostContractReportRequestPotentialCustomerInfo}
   * @memberof PostContractReportRequest
   */
  potentialCustomerInfo: PostContractReportRequestPotentialCustomerInfo;
  /**
   *
   * @type {PostContractReportRequestContractorIndividual}
   * @memberof PostContractReportRequest
   */
  contractorIndividual?: PostContractReportRequestContractorIndividual;
  /**
   *
   * @type {PostContractReportRequestContractorCorporation}
   * @memberof PostContractReportRequest
   */
  contractorCorporation?: PostContractReportRequestContractorCorporation;
  /**
   *
   * @type {GetContractReports200ResponseResultsInnerInsured}
   * @memberof PostContractReportRequest
   */
  insured?: GetContractReports200ResponseResultsInnerInsured;
  /**
   *
   * @type {PostContractReportRequestInsuranceInfo}
   * @memberof PostContractReportRequest
   */
  insuranceInfo: PostContractReportRequestInsuranceInfo;
  /**
   *
   * @type {GetContractReports200ResponseResultsInnerLifeInsuranceInfo}
   * @memberof PostContractReportRequest
   */
  lifeInsuranceInfo?: GetContractReports200ResponseResultsInnerLifeInsuranceInfo;
  /**
   *
   * @type {PostContractReportRequestNonLifeInsuranceInfo}
   * @memberof PostContractReportRequest
   */
  nonLifeInsuranceInfo?: PostContractReportRequestNonLifeInsuranceInfo;
  /**
   *
   * @type {PostContractReportRequestCommissionShare}
   * @memberof PostContractReportRequest
   */
  commissionShare: PostContractReportRequestCommissionShare;
  /**
   *
   * @type {PostContractReportRequestIncentiveShare}
   * @memberof PostContractReportRequest
   */
  incentiveShare: PostContractReportRequestIncentiveShare;
  /**
   * 募集人ID
   * @type {number}
   * @memberof PostContractReportRequest
   */
  staffId: number;
}
/**
 * 手数料取分。取分の合計が100%でない場合は400 BadRequest
 * @export
 * @interface PostContractReportRequestCommissionShare
 */
export interface PostContractReportRequestCommissionShare {
  /**
   * <dl>    <dt>会社の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostContractReportRequestCommissionShare
   */
  companyShare: string;
  /**
   * <dl>    <dt>担当募集人の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostContractReportRequestCommissionShare
   */
  myselfShare: string;
  /**
   * 所属長ID
   * @type {number}
   * @memberof PostContractReportRequestCommissionShare
   */
  bossId?: number;
  /**
   * <dl>    <dt>所属長の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostContractReportRequestCommissionShare
   */
  bossShare: string;
  /**
   * その他募集人ID
   * @type {number}
   * @memberof PostContractReportRequestCommissionShare
   */
  otherStaffId1?: number;
  /**
   * <dl>    <dt>その他募集人の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostContractReportRequestCommissionShare
   */
  otherStaffShare1: string;
  /**
   * その他募集人ID
   * @type {number}
   * @memberof PostContractReportRequestCommissionShare
   */
  otherStaffId2?: number;
  /**
   * <dl>    <dt>その他募集人の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostContractReportRequestCommissionShare
   */
  otherStaffShare2: string;
  /**
   * その他募集人ID
   * @type {number}
   * @memberof PostContractReportRequestCommissionShare
   */
  otherStaffId3?: number;
  /**
   * <dl>    <dt>その他募集人の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostContractReportRequestCommissionShare
   */
  otherStaffShare3: string;
  /**
   * <dl>    <dt>マーケットの取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostContractReportRequestCommissionShare
   */
  marketShare: string;
}
/**
 * <dl>   <dt>contractorAttributeがcorporationの場合に設定される契約者の情報</dt>   <dt>contractorAttributeがcorporationの場合に必須で、それ以外は設定不可</dt> </dl>
 * @export
 * @interface PostContractReportRequestContractorCorporation
 */
export interface PostContractReportRequestContractorCorporation {
  /**
   * 法人名
   * @type {string}
   * @memberof PostContractReportRequestContractorCorporation
   */
  corporationName: string;
  /**
   * 決算月
   * @type {number}
   * @memberof PostContractReportRequestContractorCorporation
   */
  corporationClosingMonth?: number;
  /**
   * 法人代表者名前
   * @type {string}
   * @memberof PostContractReportRequestContractorCorporation
   */
  corporationRepresentativeFirstName?: string;
  /**
   * 法人代表者苗字
   * @type {string}
   * @memberof PostContractReportRequestContractorCorporation
   */
  corporationRepresentativeLastName?: string;
  /**
   *
   * @type {string}
   * @memberof PostContractReportRequestContractorCorporation
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof PostContractReportRequestContractorCorporation
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof PostContractReportRequestContractorCorporation
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof PostContractReportRequestContractorCorporation
   */
  address3?: string;
  /**
   * 番地
   * @type {string}
   * @memberof PostContractReportRequestContractorCorporation
   */
  address4?: string;
  /**
   * ビル名、部屋番号
   * @type {string}
   * @memberof PostContractReportRequestContractorCorporation
   */
  address5?: string;
  /**
   *
   * @type {string}
   * @memberof PostContractReportRequestContractorCorporation
   */
  tel1?: string;
  /**
   *
   * @type {string}
   * @memberof PostContractReportRequestContractorCorporation
   */
  tel2?: string;
  /**
   * メールアドレス
   * @type {string}
   * @memberof PostContractReportRequestContractorCorporation
   */
  mailAddress?: string;
}
/**
 * <dl>   <dt>contractorAttributeがindividualの場合に設定される契約者の情報</dt>   <dt>contractorAttributeがindividualの場合に必須で、それ以外は設定不可</dt> </dl>
 * @export
 * @interface PostContractReportRequestContractorIndividual
 */
export interface PostContractReportRequestContractorIndividual {
  /**
   * 名前
   * @type {string}
   * @memberof PostContractReportRequestContractorIndividual
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof PostContractReportRequestContractorIndividual
   */
  lastName: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof PostContractReportRequestContractorIndividual
   */
  firstNameKana: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof PostContractReportRequestContractorIndividual
   */
  lastNameKana: string;
  /**
   * 生年月日
   * @type {string}
   * @memberof PostContractReportRequestContractorIndividual
   */
  dateOfBirth: string;
  /**
   *
   * @type {string}
   * @memberof PostContractReportRequestContractorIndividual
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof PostContractReportRequestContractorIndividual
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof PostContractReportRequestContractorIndividual
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof PostContractReportRequestContractorIndividual
   */
  address3?: string;
  /**
   * 番地
   * @type {string}
   * @memberof PostContractReportRequestContractorIndividual
   */
  address4?: string;
  /**
   * ビル名、部屋番号
   * @type {string}
   * @memberof PostContractReportRequestContractorIndividual
   */
  address5?: string;
  /**
   *
   * @type {string}
   * @memberof PostContractReportRequestContractorIndividual
   */
  tel1?: string;
  /**
   *
   * @type {string}
   * @memberof PostContractReportRequestContractorIndividual
   */
  tel2?: string;
  /**
   * メールアドレス
   * @type {string}
   * @memberof PostContractReportRequestContractorIndividual
   */
  mailAddress?: string;
  /**
   * 案件人物ID
   * @type {number}
   * @memberof PostContractReportRequestContractorIndividual
   */
  potentialCustomerPersonId?: number;
}
/**
 * インセンティブ取分。取分の合計が100%でない場合は400 BadRequest
 * @export
 * @interface PostContractReportRequestIncentiveShare
 */
export interface PostContractReportRequestIncentiveShare {
  /**
   * <dl>    <dt>会社の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostContractReportRequestIncentiveShare
   */
  companyShare: string;
  /**
   * <dl>    <dt>担当募集人の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostContractReportRequestIncentiveShare
   */
  myselfShare: string;
  /**
   * 所属長ID
   * @type {number}
   * @memberof PostContractReportRequestIncentiveShare
   */
  bossId?: number;
  /**
   * <dl>    <dt>所属長の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostContractReportRequestIncentiveShare
   */
  bossShare: string;
  /**
   * その他募集人ID
   * @type {number}
   * @memberof PostContractReportRequestIncentiveShare
   */
  otherStaffId1?: number;
  /**
   * <dl>    <dt>その他募集人の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostContractReportRequestIncentiveShare
   */
  otherStaffShare1: string;
  /**
   * その他募集人ID
   * @type {number}
   * @memberof PostContractReportRequestIncentiveShare
   */
  otherStaffId2?: number;
  /**
   * <dl>    <dt>その他募集人の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostContractReportRequestIncentiveShare
   */
  otherStaffShare2: string;
  /**
   * その他募集人ID
   * @type {number}
   * @memberof PostContractReportRequestIncentiveShare
   */
  otherStaffId3?: number;
  /**
   * <dl>    <dt>その他募集人の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostContractReportRequestIncentiveShare
   */
  otherStaffShare3: string;
  /**
   * <dl>    <dt>マーケットの取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostContractReportRequestIncentiveShare
   */
  marketShare: string;
}
/**
 * 生保・損保ともに共通する保険の情報
 * @export
 * @interface PostContractReportRequestInsuranceInfo
 */
export interface PostContractReportRequestInsuranceInfo {
  /**
   * 保険会社ID
   * @type {number}
   * @memberof PostContractReportRequestInsuranceInfo
   */
  insuranceCompanyId: number;
  /**
   * 保険種類ID
   * @type {number}
   * @memberof PostContractReportRequestInsuranceInfo
   */
  insuranceTypeId: number;
  /**
   * 保険料
   * @type {number}
   * @memberof PostContractReportRequestInsuranceInfo
   */
  insuranceFee: number;
  /**
   *
   * @type {PaymentMethod}
   * @memberof PostContractReportRequestInsuranceInfo
   */
  paymentMethod: PaymentMethod;
  /**
   * みなし年払い
   * @type {boolean}
   * @memberof PostContractReportRequestInsuranceInfo
   */
  deemedAnnualPayment?: boolean;
}
/**
 * 損保固有の情報。insuranceCategoryがnonLifeInsuranceの場合は必須。それ以外は設定不可。
 * @export
 * @interface PostContractReportRequestNonLifeInsuranceInfo
 */
export interface PostContractReportRequestNonLifeInsuranceInfo {
  /**
   * 証券番号
   * @type {string}
   * @memberof PostContractReportRequestNonLifeInsuranceInfo
   */
  policyNumber?: string;
  /**
   * 申込日
   * @type {string}
   * @memberof PostContractReportRequestNonLifeInsuranceInfo
   */
  applicationDateOn: string;
  /**
   * 保険始期
   * @type {string}
   * @memberof PostContractReportRequestNonLifeInsuranceInfo
   */
  insuranceStartDateOn: string;
  /**
   * 保険終期
   * @type {string}
   * @memberof PostContractReportRequestNonLifeInsuranceInfo
   */
  insuranceEndDateOn: string;
  /**
   * 領収日
   * @type {string}
   * @memberof PostContractReportRequestNonLifeInsuranceInfo
   */
  receiptDateOn?: string;
  /**
   * 返戻日
   * @type {string}
   * @memberof PostContractReportRequestNonLifeInsuranceInfo
   */
  returnDateOn?: string;
  /**
   *
   * @type {NonLifeInsuranceAccountingCategory}
   * @memberof PostContractReportRequestNonLifeInsuranceInfo
   */
  nonLifeInsuranceAccountingCategory: NonLifeInsuranceAccountingCategory;
  /**
   *
   * @type {NonLifeInsuranceDepositCategory}
   * @memberof PostContractReportRequestNonLifeInsuranceInfo
   */
  nonLifeInsuranceDepositCategory: NonLifeInsuranceDepositCategory;
}
/**
 * <dl>    <dt>案件情報</dt>      <dd>marketIdは、potentialCustomerTypeがmarket・jointRecruitmentの場合は必須で、それ以外は設定不可</dd>   <dd>supportUseは、potentialCustomerTypeがmyselfの場合はリクエストに関わらずfalseを設定</dd>  </dl>
 * @export
 * @interface PostContractReportRequestPotentialCustomerInfo
 */
export interface PostContractReportRequestPotentialCustomerInfo {
  /**
   * 案件ID
   * @type {number}
   * @memberof PostContractReportRequestPotentialCustomerInfo
   */
  potentialCustomerId?: number;
  /**
   *
   * @type {PotentialCustomerType}
   * @memberof PostContractReportRequestPotentialCustomerInfo
   */
  potentialCustomerType: PotentialCustomerType;
  /**
   * サポート利用有無（true：利用あり）
   * @type {boolean}
   * @memberof PostContractReportRequestPotentialCustomerInfo
   */
  supportUse?: boolean;
  /**
   * マーケットID
   * @type {number}
   * @memberof PostContractReportRequestPotentialCustomerInfo
   */
  marketId?: number;
}
/**
 *
 * @export
 * @interface PostDepartmentRequest
 */
export interface PostDepartmentRequest {
  /**
   * 所属名
   * @type {string}
   * @memberof PostDepartmentRequest
   */
  departmentName: string;
  /**
   * <dl>    <dt>所属長</dt>   <dt>所属に所属長が存在する場合、最新の所属長の在任期間TOは未設定とする</dt>   <dt>Validation</dt>   <dd>在任期間が重複する場合は400BadRequest</dd>   <dd>在任期間に隙間がある場合は400BadRequest</dd>   <dd>所属長がいる場合、在任期間TOが未設定の所属長がいない（最新の所属長がいない）場合は400BadRequest</dd>  </dl>
   * @type {Array<PostDepartmentRequestBossInner>}
   * @memberof PostDepartmentRequest
   */
  boss: Array<PostDepartmentRequestBossInner>;
  /**
   * <dl>    <dt>案件・申達・証券権限を持つstaffのリスト</dt>   <dt>案件・申達・証券権限は<a href=https://github.com/doitplanning/doit-core-doc/blob/main/%E3%83%90%E3%83%83%E3%82%AF%E3%82%A8%E3%83%B3%E3%83%89/%E6%A8%A9%E9%99%90.md>権限doc</a>参照</dt>  </dl>
   * @type {Array<PostDepartmentRequestBossInner>}
   * @memberof PostDepartmentRequest
   */
  authorizedStaff: Array<PostDepartmentRequestBossInner>;
  /**
   * <dl>    <dt>業績給参照権限を持つstaffのリスト</dt>   <dt>業績給参照権限は<a href=https://github.com/doitplanning/doit-core-doc/blob/main/%E3%83%90%E3%83%83%E3%82%AF%E3%82%A8%E3%83%B3%E3%83%89/%E6%A8%A9%E9%99%90.md>権限doc</a>参照</dt>  </dl>
   * @type {Array<PostDepartmentRequestBossInner>}
   * @memberof PostDepartmentRequest
   */
  authorizedPerformanceBasedPay: Array<PostDepartmentRequestBossInner>;
  /**
   * 表示順
   * @type {number}
   * @memberof PostDepartmentRequest
   */
  sortNumber: number;
}
/**
 *
 * @export
 * @interface PostDepartmentRequestBossInner
 */
export interface PostDepartmentRequestBossInner {
  /**
   * 募集人ID
   * @type {number}
   * @memberof PostDepartmentRequestBossInner
   */
  staffId: number;
  /**
   * 在任期間
   * @type {string}
   * @memberof PostDepartmentRequestBossInner
   */
  tenureAtFrom: string;
  /**
   * 在任期間
   * @type {string}
   * @memberof PostDepartmentRequestBossInner
   */
  tenureAtTo?: string;
}
/**
 *
 * @export
 * @interface PostInsuranceCompanyRequest
 */
export interface PostInsuranceCompanyRequest {
  /**
   * 保険会社名
   * @type {string}
   * @memberof PostInsuranceCompanyRequest
   */
  insuranceCompanyName: string;
  /**
   * 内税フラグ
   * @type {boolean}
   * @memberof PostInsuranceCompanyRequest
   */
  consumptionTaxIncludeFlag: boolean;
  /**
   *
   * @type {InsuranceCategory}
   * @memberof PostInsuranceCompanyRequest
   */
  insuranceCategory: InsuranceCategory;
  /**
   * 表示順
   * @type {number}
   * @memberof PostInsuranceCompanyRequest
   */
  sortNumber: number;
}
/**
 *
 * @export
 * @interface PostInsuranceTypeRequest
 */
export interface PostInsuranceTypeRequest {
  /**
   * 保険種類名
   * @type {string}
   * @memberof PostInsuranceTypeRequest
   */
  insuranceTypeName: string;
  /**
   *
   * @type {InsuranceCategory}
   * @memberof PostInsuranceTypeRequest
   */
  insuranceCategory: InsuranceCategory;
  /**
   * 表示順
   * @type {number}
   * @memberof PostInsuranceTypeRequest
   */
  sortNumber: number;
}
/**
 *
 * @export
 * @interface PostMarketRequest
 */
export interface PostMarketRequest {
  /**
   * マーケット名
   * @type {string}
   * @memberof PostMarketRequest
   */
  marketName: string;
  /**
   * 表示順
   * @type {number}
   * @memberof PostMarketRequest
   */
  sortNumber: number;
}
/**
 *
 * @export
 * @interface PostPotentialCustomerRequest
 */
export interface PostPotentialCustomerRequest {
  /**
   *
   * @type {PotentialCustomerAttribute}
   * @memberof PostPotentialCustomerRequest
   */
  potentialCustomerAttribute: PotentialCustomerAttribute;
  /**
   *
   * @type {PotentialCustomerType}
   * @memberof PostPotentialCustomerRequest
   */
  potentialCustomerType: PotentialCustomerType;
  /**
   * 初回面談日
   * @type {string}
   * @memberof PostPotentialCustomerRequest
   */
  potentialCustomerFirstVisitOn?: string;
  /**
   *
   * @type {PostPotentialCustomerRequestMarket}
   * @memberof PostPotentialCustomerRequest
   */
  market?: PostPotentialCustomerRequestMarket;
  /**
   *
   * @type {PostPotentialCustomerRequestIndividualPotentialCustomer}
   * @memberof PostPotentialCustomerRequest
   */
  individualPotentialCustomer?: PostPotentialCustomerRequestIndividualPotentialCustomer;
  /**
   *
   * @type {PostPotentialCustomerRequestCorporatePotentialCustomer}
   * @memberof PostPotentialCustomerRequest
   */
  corporatePotentialCustomer?: PostPotentialCustomerRequestCorporatePotentialCustomer;
  /**
   * 備考 改行コードを含む
   * @type {string}
   * @memberof PostPotentialCustomerRequest
   */
  memo?: string;
  /**
   * 募集人ID
   * @type {number}
   * @memberof PostPotentialCustomerRequest
   */
  staffId: number;
  /**
   * 許可された募集人
   * @type {Array<number>}
   * @memberof PostPotentialCustomerRequest
   */
  authorizedStaff: Array<number>;
}
/**
 * <dl>   <dt>法人の案件の情報</dt>   <dt>法人の場合は必須で、それ以外は設定不可</dt> </dl>
 * @export
 * @interface PostPotentialCustomerRequestCorporatePotentialCustomer
 */
export interface PostPotentialCustomerRequestCorporatePotentialCustomer {
  /**
   * 法人名
   * @type {string}
   * @memberof PostPotentialCustomerRequestCorporatePotentialCustomer
   */
  corporationName: string;
  /**
   * 決算月
   * @type {number}
   * @memberof PostPotentialCustomerRequestCorporatePotentialCustomer
   */
  corporationClosingMonth?: number;
  /**
   * 法人代表者名前
   * @type {string}
   * @memberof PostPotentialCustomerRequestCorporatePotentialCustomer
   */
  corporationRepresentativeFirstName?: string;
  /**
   * 法人代表者苗字
   * @type {string}
   * @memberof PostPotentialCustomerRequestCorporatePotentialCustomer
   */
  corporationRepresentativeLastName?: string;
  /**
   *
   * @type {string}
   * @memberof PostPotentialCustomerRequestCorporatePotentialCustomer
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof PostPotentialCustomerRequestCorporatePotentialCustomer
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof PostPotentialCustomerRequestCorporatePotentialCustomer
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof PostPotentialCustomerRequestCorporatePotentialCustomer
   */
  address3?: string;
  /**
   * 番地
   * @type {string}
   * @memberof PostPotentialCustomerRequestCorporatePotentialCustomer
   */
  address4?: string;
  /**
   * ビル名、部屋番号
   * @type {string}
   * @memberof PostPotentialCustomerRequestCorporatePotentialCustomer
   */
  address5?: string;
  /**
   *
   * @type {string}
   * @memberof PostPotentialCustomerRequestCorporatePotentialCustomer
   */
  tel1?: string;
  /**
   *
   * @type {string}
   * @memberof PostPotentialCustomerRequestCorporatePotentialCustomer
   */
  tel2?: string;
  /**
   * メールアドレス
   * @type {string}
   * @memberof PostPotentialCustomerRequestCorporatePotentialCustomer
   */
  mailAddress?: string;
}
/**
 * <dl>   <dt>個人の案件の情報</dt>   <dt>個人の場合は必須で、それ以外は設定不可</dt> </dl>
 * @export
 * @interface PostPotentialCustomerRequestIndividualPotentialCustomer
 */
export interface PostPotentialCustomerRequestIndividualPotentialCustomer {
  /**
   *
   * @type {PostPotentialCustomerRequestIndividualPotentialCustomerPerson}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomer
   */
  person: PostPotentialCustomerRequestIndividualPotentialCustomerPerson;
  /**
   * <dl>   <dt>家族情報のリスト</dt> </dl>
   * @type {Array<PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner>}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomer
   */
  family: Array<PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner>;
}
/**
 * <dl>   <dt>案件人物</dt> </dl>
 * @export
 * @interface PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
 */
export interface PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner {
  /**
   *
   * @type {PotentialCustomerPersonAttribute}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  potentialCustomerPersonAttribute: PotentialCustomerPersonAttribute;
  /**
   * potentialCustomer内で一意となるsort順
   * @type {number}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  potentialCustomerPersonSortNumber: number;
  /**
   * 苗字
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  lastName: string;
  /**
   * 名前
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  firstName: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  lastNameKana: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  firstNameKana: string;
  /**
   * 生年月日
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  dateOfBirth: string;
  /**
   *
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  address3?: string;
  /**
   * 番地
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  address4?: string;
  /**
   * ビル名、部屋番号
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  address5?: string;
  /**
   *
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  tel1?: string;
  /**
   *
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  tel2?: string;
  /**
   * メールアドレス
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  mailAddress?: string;
}
/**
 *
 * @export
 * @interface PostPotentialCustomerRequestIndividualPotentialCustomerPerson
 */
export interface PostPotentialCustomerRequestIndividualPotentialCustomerPerson {
  /**
   * 苗字
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerPerson
   */
  lastName: string;
  /**
   * 名前
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerPerson
   */
  firstName: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerPerson
   */
  lastNameKana: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerPerson
   */
  firstNameKana: string;
  /**
   * 生年月日
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerPerson
   */
  dateOfBirth: string;
  /**
   *
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerPerson
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerPerson
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerPerson
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerPerson
   */
  address3?: string;
  /**
   * 番地
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerPerson
   */
  address4?: string;
  /**
   * ビル名、部屋番号
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerPerson
   */
  address5?: string;
  /**
   *
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerPerson
   */
  tel1?: string;
  /**
   *
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerPerson
   */
  tel2?: string;
  /**
   * メールアドレス
   * @type {string}
   * @memberof PostPotentialCustomerRequestIndividualPotentialCustomerPerson
   */
  mailAddress?: string;
}
/**
 * <dl>   <dt>マーケットの情報</dt>   <dt>potentialCustomerTypeがmarket・jointRecruitmentの場合は必須で、それ以外は設定不可</dt> </dl>
 * @export
 * @interface PostPotentialCustomerRequestMarket
 */
export interface PostPotentialCustomerRequestMarket {
  /**
   * マーケットID
   * @type {number}
   * @memberof PostPotentialCustomerRequestMarket
   */
  marketId: number;
  /**
   * マーケット顧客ID
   * @type {string}
   * @memberof PostPotentialCustomerRequestMarket
   */
  marketCustomerId?: string;
  /**
   * サポート利用有無（true：利用あり）
   * @type {boolean}
   * @memberof PostPotentialCustomerRequestMarket
   */
  supportUse: boolean;
}
/**
 *
 * @export
 * @interface PostPurchasePotentialCustomerRequest
 */
export interface PostPurchasePotentialCustomerRequest {
  /**
   * 社員番号
   * @type {string}
   * @memberof PostPurchasePotentialCustomerRequest
   */
  staffId: string;
  /**
   *
   * @type {PostPurchasePotentialCustomerRequestPotentialCustomer}
   * @memberof PostPurchasePotentialCustomerRequest
   */
  potentialCustomer: PostPurchasePotentialCustomerRequestPotentialCustomer;
  /**
   *
   * @type {GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomerFee}
   * @memberof PostPurchasePotentialCustomerRequest
   */
  potentialCustomerFee: GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomerFee;
  /**
   * 購入案件支払名
   * @type {string}
   * @memberof PostPurchasePotentialCustomerRequest
   */
  purchasePotentialCustomerName: string;
  /**
   * 購入案件対象外フラグ（true：対象外、false：対象）
   * @type {boolean}
   * @memberof PostPurchasePotentialCustomerRequest
   */
  purchasePotentialCustomerOutOfScopeFlag: boolean;
}
/**
 * <dl>    <dt>購入案件支払機能からの更新のため、potentialCustomerIdはpropertiesに存在しない</dt>  </dl>
 * @export
 * @interface PostPurchasePotentialCustomerRequestPotentialCustomer
 */
export interface PostPurchasePotentialCustomerRequestPotentialCustomer {
  /**
   * マーケットID
   * @type {number}
   * @memberof PostPurchasePotentialCustomerRequestPotentialCustomer
   */
  marketId: number;
}
/**
 *
 * @export
 * @interface PostSecuritiesCommissionMatchingRequest
 */
export interface PostSecuritiesCommissionMatchingRequest {
  /**
   * 手数料ID
   * @type {number}
   * @memberof PostSecuritiesCommissionMatchingRequest
   */
  commissionId: number;
  /**
   * 申達報告ID
   * @type {number}
   * @memberof PostSecuritiesCommissionMatchingRequest
   */
  contractReportId: number;
}
/**
 *
 * @export
 * @interface PostSecuritiesRequest
 */
export interface PostSecuritiesRequest {
  /**
   *
   * @type {InsuranceCategory}
   * @memberof PostSecuritiesRequest
   */
  insuranceCategory: InsuranceCategory;
  /**
   *
   * @type {PostContractReportRequestPotentialCustomerInfo}
   * @memberof PostSecuritiesRequest
   */
  potentialCustomerInfo: PostContractReportRequestPotentialCustomerInfo;
  /**
   *
   * @type {ContractorAttribute}
   * @memberof PostSecuritiesRequest
   */
  contractorAttribute: ContractorAttribute;
  /**
   * 申達報告ID
   * @type {number}
   * @memberof PostSecuritiesRequest
   */
  contractReportId?: number;
  /**
   *
   * @type {PostSecuritiesRequestContractorIndividual}
   * @memberof PostSecuritiesRequest
   */
  contractorIndividual?: PostSecuritiesRequestContractorIndividual;
  /**
   *
   * @type {PostSecuritiesRequestContractorCorporation}
   * @memberof PostSecuritiesRequest
   */
  contractorCorporation?: PostSecuritiesRequestContractorCorporation;
  /**
   *
   * @type {PostSecuritiesRequestInsured}
   * @memberof PostSecuritiesRequest
   */
  insured?: PostSecuritiesRequestInsured;
  /**
   *
   * @type {PostSecuritiesRequestInsuranceInfo}
   * @memberof PostSecuritiesRequest
   */
  insuranceInfo: PostSecuritiesRequestInsuranceInfo;
  /**
   *
   * @type {PostSecuritiesRequestLifeInsuranceInfo}
   * @memberof PostSecuritiesRequest
   */
  lifeInsuranceInfo?: PostSecuritiesRequestLifeInsuranceInfo;
  /**
   *
   * @type {PostSecuritiesRequestNonLifeInsuranceInfo}
   * @memberof PostSecuritiesRequest
   */
  nonLifeInsuranceInfo?: PostSecuritiesRequestNonLifeInsuranceInfo;
  /**
   *
   * @type {PostContractReportRequestCommissionShare}
   * @memberof PostSecuritiesRequest
   */
  commissionShare: PostContractReportRequestCommissionShare;
  /**
   *
   * @type {PostContractReportRequestIncentiveShare}
   * @memberof PostSecuritiesRequest
   */
  incentiveShare: PostContractReportRequestIncentiveShare;
  /**
   * 募集人ID
   * @type {number}
   * @memberof PostSecuritiesRequest
   */
  staffId: number;
}
/**
 * <dl>   <dt>contractorAttributeがcorporationの場合に設定される契約者の情報</dt> </dl>
 * @export
 * @interface PostSecuritiesRequestContractorCorporation
 */
export interface PostSecuritiesRequestContractorCorporation {
  /**
   * 法人名
   * @type {string}
   * @memberof PostSecuritiesRequestContractorCorporation
   */
  corporationName?: string;
  /**
   * 決算月
   * @type {number}
   * @memberof PostSecuritiesRequestContractorCorporation
   */
  corporationClosingMonth?: number;
  /**
   * 法人代表者名前
   * @type {string}
   * @memberof PostSecuritiesRequestContractorCorporation
   */
  corporationRepresentativeFirstName?: string;
  /**
   * 法人代表者苗字
   * @type {string}
   * @memberof PostSecuritiesRequestContractorCorporation
   */
  corporationRepresentativeLastName?: string;
  /**
   *
   * @type {string}
   * @memberof PostSecuritiesRequestContractorCorporation
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof PostSecuritiesRequestContractorCorporation
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof PostSecuritiesRequestContractorCorporation
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof PostSecuritiesRequestContractorCorporation
   */
  address3?: string;
  /**
   * 番地
   * @type {string}
   * @memberof PostSecuritiesRequestContractorCorporation
   */
  address4?: string;
  /**
   * ビル名、部屋番号
   * @type {string}
   * @memberof PostSecuritiesRequestContractorCorporation
   */
  address5?: string;
  /**
   *
   * @type {string}
   * @memberof PostSecuritiesRequestContractorCorporation
   */
  tel1?: string;
  /**
   *
   * @type {string}
   * @memberof PostSecuritiesRequestContractorCorporation
   */
  tel2?: string;
  /**
   * メールアドレス
   * @type {string}
   * @memberof PostSecuritiesRequestContractorCorporation
   */
  mailAddress?: string;
}
/**
 * <dl>   <dt>contractorAttributeがindividualの場合に設定される契約者の情報</dt> </dl>
 * @export
 * @interface PostSecuritiesRequestContractorIndividual
 */
export interface PostSecuritiesRequestContractorIndividual {
  /**
   * 名前
   * @type {string}
   * @memberof PostSecuritiesRequestContractorIndividual
   */
  firstName?: string;
  /**
   * 苗字
   * @type {string}
   * @memberof PostSecuritiesRequestContractorIndividual
   */
  lastName?: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof PostSecuritiesRequestContractorIndividual
   */
  firstNameKana?: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof PostSecuritiesRequestContractorIndividual
   */
  lastNameKana?: string;
  /**
   * 生年月日
   * @type {string}
   * @memberof PostSecuritiesRequestContractorIndividual
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof PostSecuritiesRequestContractorIndividual
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof PostSecuritiesRequestContractorIndividual
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof PostSecuritiesRequestContractorIndividual
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof PostSecuritiesRequestContractorIndividual
   */
  address3?: string;
  /**
   * 番地
   * @type {string}
   * @memberof PostSecuritiesRequestContractorIndividual
   */
  address4?: string;
  /**
   * ビル名、部屋番号
   * @type {string}
   * @memberof PostSecuritiesRequestContractorIndividual
   */
  address5?: string;
  /**
   *
   * @type {string}
   * @memberof PostSecuritiesRequestContractorIndividual
   */
  tel1?: string;
  /**
   *
   * @type {string}
   * @memberof PostSecuritiesRequestContractorIndividual
   */
  tel2?: string;
  /**
   * メールアドレス
   * @type {string}
   * @memberof PostSecuritiesRequestContractorIndividual
   */
  mailAddress?: string;
  /**
   * 案件人物ID
   * @type {number}
   * @memberof PostSecuritiesRequestContractorIndividual
   */
  potentialCustomerPersonId?: number;
}
/**
 * 生保・損保ともに共通する保険の情報
 * @export
 * @interface PostSecuritiesRequestInsuranceInfo
 */
export interface PostSecuritiesRequestInsuranceInfo {
  /**
   * 証券番号
   * @type {string}
   * @memberof PostSecuritiesRequestInsuranceInfo
   */
  policyNumber: string;
  /**
   * 保険会社ID
   * @type {number}
   * @memberof PostSecuritiesRequestInsuranceInfo
   */
  insuranceCompanyId: number;
  /**
   * 保険種類ID
   * @type {number}
   * @memberof PostSecuritiesRequestInsuranceInfo
   */
  insuranceTypeId: number;
  /**
   * 保険料
   * @type {number}
   * @memberof PostSecuritiesRequestInsuranceInfo
   */
  insuranceFee: number;
  /**
   *
   * @type {PaymentMethod}
   * @memberof PostSecuritiesRequestInsuranceInfo
   */
  paymentMethod?: PaymentMethod;
  /**
   * みなし年払い
   * @type {boolean}
   * @memberof PostSecuritiesRequestInsuranceInfo
   */
  deemedAnnualPayment?: boolean;
}
/**
 * 被保険者の情報
 * @export
 * @interface PostSecuritiesRequestInsured
 */
export interface PostSecuritiesRequestInsured {
  /**
   * 名前
   * @type {string}
   * @memberof PostSecuritiesRequestInsured
   */
  firstName?: string;
  /**
   * 苗字
   * @type {string}
   * @memberof PostSecuritiesRequestInsured
   */
  lastName?: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof PostSecuritiesRequestInsured
   */
  firstNameKana?: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof PostSecuritiesRequestInsured
   */
  lastNameKana?: string;
  /**
   * 生年月日
   * @type {string}
   * @memberof PostSecuritiesRequestInsured
   */
  dateOfBirth?: string;
  /**
   * 案件人物ID
   * @type {number}
   * @memberof PostSecuritiesRequestInsured
   */
  potentialCustomerPersonId?: number;
}
/**
 * 生保固有の情報。insuranceCategoryがlifeInsurance以外は設定不可。
 * @export
 * @interface PostSecuritiesRequestLifeInsuranceInfo
 */
export interface PostSecuritiesRequestLifeInsuranceInfo {
  /**
   * 契約日
   * @type {string}
   * @memberof PostSecuritiesRequestLifeInsuranceInfo
   */
  contractDateOn?: string;
}
/**
 * 損保固有の情報。insuranceCategoryがnonLifeInsurance以外は設定不可。
 * @export
 * @interface PostSecuritiesRequestNonLifeInsuranceInfo
 */
export interface PostSecuritiesRequestNonLifeInsuranceInfo {
  /**
   * 申込日
   * @type {string}
   * @memberof PostSecuritiesRequestNonLifeInsuranceInfo
   */
  applicationDateOn?: string;
  /**
   * 保険始期
   * @type {string}
   * @memberof PostSecuritiesRequestNonLifeInsuranceInfo
   */
  insuranceStartDateOn?: string;
  /**
   * 保険終期
   * @type {string}
   * @memberof PostSecuritiesRequestNonLifeInsuranceInfo
   */
  insuranceEndDateOn?: string;
  /**
   * 領収日
   * @type {string}
   * @memberof PostSecuritiesRequestNonLifeInsuranceInfo
   */
  receiptDateOn?: string;
  /**
   * 返戻日
   * @type {string}
   * @memberof PostSecuritiesRequestNonLifeInsuranceInfo
   */
  returnDateOn?: string;
  /**
   *
   * @type {NonLifeInsuranceAccountingCategory}
   * @memberof PostSecuritiesRequestNonLifeInsuranceInfo
   */
  nonLifeInsuranceAccountingCategory?: NonLifeInsuranceAccountingCategory;
  /**
   *
   * @type {NonLifeInsuranceDepositCategory}
   * @memberof PostSecuritiesRequestNonLifeInsuranceInfo
   */
  nonLifeInsuranceDepositCategory?: NonLifeInsuranceDepositCategory;
}
/**
 *
 * @export
 * @interface PostStaffRequest
 */
export interface PostStaffRequest {
  /**
   * 社員番号
   * @type {string}
   * @memberof PostStaffRequest
   */
  staffNumber: string;
  /**
   *
   * @type {StaffRoleType}
   * @memberof PostStaffRequest
   */
  staffRoleType: StaffRoleType;
  /**
   * 業績給対象有無（true：対象）
   * @type {boolean}
   * @memberof PostStaffRequest
   */
  performanceBasedPayFlag: boolean;
  /**
   * <dl>    <dt>Cognitoに連携するパスワード</dt>      <dt>以下を満たす文字列</dt>   <dd>     <ul>       <li>少なくとも1つの数字を含む（0-9）</li>       <li>少なくとも1つの大文字を含む（A-Z）</li>       <li>少なくとも1つの小文字を含む（a-z）</li>       <li>8文字以上</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostStaffRequest
   */
  password: string;
  /**
   *
   * @type {PostStaffRequestPersonal}
   * @memberof PostStaffRequest
   */
  personal: PostStaffRequestPersonal;
  /**
   *
   * @type {PostStaffRequestEmergencyContact}
   * @memberof PostStaffRequest
   */
  emergencyContact: PostStaffRequestEmergencyContact;
  /**
   * <dl>    <dt>在籍所属</dt>   <dt>会社に在籍中は、必ずどこか1つの組織に所属している</dt>   <dt>最後の在籍所属の在籍期間TOは未設定とする</dt>   <dt>Validation</dt>   <dd>在籍期間が重複する場合は400BadRequest</dd>   <dd>在籍期間に隙間がある場合は400BadRequest</dd>   <dd>在籍期間TOが未設定の在籍所属が1件ない場合は400BadRequest</dd>  </dl>
   * @type {Array<PostStaffRequestDepartmentAffiliationInner>}
   * @memberof PostStaffRequest
   */
  departmentAffiliation: Array<PostStaffRequestDepartmentAffiliationInner>;
  /**
   *
   * @type {PostStaffRequestCompanyAffiliation}
   * @memberof PostStaffRequest
   */
  companyAffiliation: PostStaffRequestCompanyAffiliation;
  /**
   *
   * @type {PostStaffRequestPerformanceBasedPay}
   * @memberof PostStaffRequest
   */
  performanceBasedPay?: PostStaffRequestPerformanceBasedPay;
  /**
   *
   * @type {PostStaffRequestCommissionShareDefault}
   * @memberof PostStaffRequest
   */
  commissionShareDefault: PostStaffRequestCommissionShareDefault;
  /**
   *
   * @type {PostStaffRequestCommissionShareDefault}
   * @memberof PostStaffRequest
   */
  incentiveShareDefault: PostStaffRequestCommissionShareDefault;
  /**
   *
   * @type {PostStaffRequestLicense}
   * @memberof PostStaffRequest
   */
  license?: PostStaffRequestLicense;
}
/**
 * <dl>    <dt>取分情報のデフォルト値</dt>   <dt>%指定</dt>   <dt>合計が100でない場合は、400BadRequest</dt>   <dt>その他募集人の取分が1以上で対応するStaffIdが未設定の場合は、400BadRequest</dt>    </dl>
 * @export
 * @interface PostStaffRequestCommissionShareDefault
 */
export interface PostStaffRequestCommissionShareDefault {
  /**
   * <dl>    <dt>会社の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostStaffRequestCommissionShareDefault
   */
  companyShare: string;
  /**
   * <dl>    <dt>担当募集人の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostStaffRequestCommissionShareDefault
   */
  myselfShare: string;
  /**
   * <dl>    <dt>所属長の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostStaffRequestCommissionShareDefault
   */
  bossShare: string;
  /**
   * その他募集人ID
   * @type {number}
   * @memberof PostStaffRequestCommissionShareDefault
   */
  otherStaffId1?: number;
  /**
   * <dl>    <dt>その他募集人の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostStaffRequestCommissionShareDefault
   */
  otherStaffShare1: string;
  /**
   * その他募集人ID
   * @type {number}
   * @memberof PostStaffRequestCommissionShareDefault
   */
  otherStaffId2?: number;
  /**
   * <dl>    <dt>その他募集人の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostStaffRequestCommissionShareDefault
   */
  otherStaffShare2: string;
  /**
   * その他募集人ID
   * @type {number}
   * @memberof PostStaffRequestCommissionShareDefault
   */
  otherStaffId3?: number;
  /**
   * <dl>    <dt>その他募集人の取分（％）</dt>      <dd>     <ul>       <li>小数点を含む場合があるのでStringで表現</li>       <li>0～100までの数値</li>       <li>小数点第２位まで指定可</li>       <li>整数の場合は小数点はつけない</li>       <li>100、0、15、5、7.5、0.25などが指定される</li>       <li>取分の合計値は100となる</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PostStaffRequestCommissionShareDefault
   */
  otherStaffShare3: string;
}
/**
 * <dl>    <dt>会社の所属情報</dt>    </dl>
 * @export
 * @interface PostStaffRequestCompanyAffiliation
 */
export interface PostStaffRequestCompanyAffiliation {
  /**
   * 入社日
   * @type {string}
   * @memberof PostStaffRequestCompanyAffiliation
   */
  hiringDateAt: string;
  /**
   * 退職日
   * @type {string}
   * @memberof PostStaffRequestCompanyAffiliation
   */
  leavingDateAt?: string;
}
/**
 *
 * @export
 * @interface PostStaffRequestDepartmentAffiliationInner
 */
export interface PostStaffRequestDepartmentAffiliationInner {
  /**
   * 所属ID
   * @type {number}
   * @memberof PostStaffRequestDepartmentAffiliationInner
   */
  departmentId: number;
  /**
   * 所属期間
   * @type {string}
   * @memberof PostStaffRequestDepartmentAffiliationInner
   */
  affiliationAtFrom: string;
  /**
   * 所属期間
   * @type {string}
   * @memberof PostStaffRequestDepartmentAffiliationInner
   */
  affiliationAtTo?: string;
}
/**
 * <dl>    <dt>緊急連絡先</dt>    </dl>
 * @export
 * @interface PostStaffRequestEmergencyContact
 */
export interface PostStaffRequestEmergencyContact {
  /**
   * 名前
   * @type {string}
   * @memberof PostStaffRequestEmergencyContact
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof PostStaffRequestEmergencyContact
   */
  lastName: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof PostStaffRequestEmergencyContact
   */
  firstNameKana: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof PostStaffRequestEmergencyContact
   */
  lastNameKana: string;
  /**
   *
   * @type {string}
   * @memberof PostStaffRequestEmergencyContact
   */
  tel1: string;
}
/**
 * <dl>    <dt>資格に関わる情報</dt>    </dl>
 * @export
 * @interface PostStaffRequestLicense
 */
export interface PostStaffRequestLicense {
  /**
   * 生命保険募集人登録番号
   * @type {string}
   * @memberof PostStaffRequestLicense
   */
  lifeInsuranceStaffNumber?: string;
  /**
   * 損害保険募集人登録番号
   * @type {string}
   * @memberof PostStaffRequestLicense
   */
  nonLifeInsuranceStaffNumber?: string;
  /**
   * 変額保険販売資格登録番号
   * @type {string}
   * @memberof PostStaffRequestLicense
   */
  variableInsuranceSalesQualificationNumber?: string;
  /**
   * 外貨建保険販売資格登録番号
   * @type {string}
   * @memberof PostStaffRequestLicense
   */
  foreignCurrencyInsuranceSalesQualificationNumber?: string;
  /**
   * 少額短期資格登録番号
   * @type {string}
   * @memberof PostStaffRequestLicense
   */
  smallAmountShortTermQualificationNumber?: string;
  /**
   * 専門課程あり
   * @type {boolean}
   * @memberof PostStaffRequestLicense
   */
  hasSpecializedCourse: boolean;
  /**
   * 大学課程あり
   * @type {boolean}
   * @memberof PostStaffRequestLicense
   */
  hasUniversityCourse: boolean;
  /**
   * 応用課程あり
   * @type {boolean}
   * @memberof PostStaffRequestLicense
   */
  hasAppliedCourse: boolean;
  /**
   * AFP資格あり
   * @type {boolean}
   * @memberof PostStaffRequestLicense
   */
  hasAfpQualification: boolean;
  /**
   * CFP資格あり
   * @type {boolean}
   * @memberof PostStaffRequestLicense
   */
  hasCfpQualification: boolean;
  /**
   *
   * @type {FpQualification}
   * @memberof PostStaffRequestLicense
   */
  fpQualification: FpQualification;
  /**
   *
   * @type {SecuritiesSalespersonQualification}
   * @memberof PostStaffRequestLicense
   */
  securitiesSalespersonQualification: SecuritiesSalespersonQualification;
}
/**
 * <dl>    <dt>業績給計算に関わる情報</dt>   <dt>performanceBasedPayFlagがtrueの場合は必須で、それ以外は設定不可</dt>    </dl>
 * @export
 * @interface PostStaffRequestPerformanceBasedPay
 */
export interface PostStaffRequestPerformanceBasedPay {
  /**
   * 休業フラグ（true：休業）
   * @type {boolean}
   * @memberof PostStaffRequestPerformanceBasedPay
   */
  closedFlag: boolean;
  /**
   * 雇用保険対象有無（true：対象）
   * @type {boolean}
   * @memberof PostStaffRequestPerformanceBasedPay
   */
  employmentInsuranceFlag: boolean;
  /**
   * 介護保険対象有無（true：対象）
   * @type {boolean}
   * @memberof PostStaffRequestPerformanceBasedPay
   */
  longTermCareInsuranceFlag: boolean;
  /**
   * 健康保険標準報酬月額
   * @type {number}
   * @memberof PostStaffRequestPerformanceBasedPay
   */
  healthInsuranceStandardMonthlyRemuneration: number;
  /**
   * 厚生年金標準報酬月額
   * @type {number}
   * @memberof PostStaffRequestPerformanceBasedPay
   */
  welfarePensionStandardMonthlyRemuneration: number;
  /**
   * 基本給
   * @type {number}
   * @memberof PostStaffRequestPerformanceBasedPay
   */
  basicSalary: number;
  /**
   * エントリーフィー
   * @type {number}
   * @memberof PostStaffRequestPerformanceBasedPay
   */
  entryFee: number;
  /**
   * 基本給控除（true：控除）
   * @type {boolean}
   * @memberof PostStaffRequestPerformanceBasedPay
   */
  basicSalaryDeductionFlag: boolean;
  /**
   * 社会保険料控除（true：控除）
   * @type {boolean}
   * @memberof PostStaffRequestPerformanceBasedPay
   */
  socialInsurancePremiumDeductionFlag: boolean;
  /**
   * インボイス対象有無（true：対象）
   * @type {boolean}
   * @memberof PostStaffRequestPerformanceBasedPay
   */
  invoiceBasedFlag: boolean;
  /**
   * インボイス登録番号
   * @type {string}
   * @memberof PostStaffRequestPerformanceBasedPay
   */
  invoiceCode?: string;
  /**
   * 業績給振替先募集人ID
   * @type {number}
   * @memberof PostStaffRequestPerformanceBasedPay
   */
  performanceBasedPayToStaffId?: number;
  /**
   * 固定給選択制度（true：対象）
   * @type {boolean}
   * @memberof PostStaffRequestPerformanceBasedPay
   */
  fixedSalaryFlag: boolean;
  /**
   * 特別枠控除（true：対象）
   * @type {boolean}
   * @memberof PostStaffRequestPerformanceBasedPay
   */
  specialDeductionFlag: boolean;
  /**
   * 業績給対象期間From
   * @type {string}
   * @memberof PostStaffRequestPerformanceBasedPay
   */
  performanceBasedPayTermFrom?: string;
  /**
   * 業績給対象期間To
   * @type {string}
   * @memberof PostStaffRequestPerformanceBasedPay
   */
  performanceBasedPayTermTo?: string;
}
/**
 * <dl>    <dt>ユーザの情報</dt>    </dl>
 * @export
 * @interface PostStaffRequestPersonal
 */
export interface PostStaffRequestPersonal {
  /**
   * 名前
   * @type {string}
   * @memberof PostStaffRequestPersonal
   */
  firstName: string;
  /**
   * 苗字
   * @type {string}
   * @memberof PostStaffRequestPersonal
   */
  lastName: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof PostStaffRequestPersonal
   */
  firstNameKana: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof PostStaffRequestPersonal
   */
  lastNameKana: string;
  /**
   * 生年月日
   * @type {string}
   * @memberof PostStaffRequestPersonal
   */
  dateOfBirth?: string;
  /**
   *
   * @type {Sex}
   * @memberof PostStaffRequestPersonal
   */
  sex: Sex;
  /**
   *
   * @type {string}
   * @memberof PostStaffRequestPersonal
   */
  postCode: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof PostStaffRequestPersonal
   */
  address1: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof PostStaffRequestPersonal
   */
  address2: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof PostStaffRequestPersonal
   */
  address3: string;
  /**
   * 番地
   * @type {string}
   * @memberof PostStaffRequestPersonal
   */
  address4?: string;
  /**
   * ビル名、部屋番号
   * @type {string}
   * @memberof PostStaffRequestPersonal
   */
  address5?: string;
  /**
   *
   * @type {string}
   * @memberof PostStaffRequestPersonal
   */
  tel1: string;
  /**
   *
   * @type {string}
   * @memberof PostStaffRequestPersonal
   */
  tel2?: string;
  /**
   * メールアドレス
   * @type {string}
   * @memberof PostStaffRequestPersonal
   */
  mailAddress1: string;
  /**
   * メールアドレス
   * @type {string}
   * @memberof PostStaffRequestPersonal
   */
  mailAddress2?: string;
}
/**
 *
 * @export
 * @interface PostStaffStandardMonthlyRemunerationCsv200Response
 */
export interface PostStaffStandardMonthlyRemunerationCsv200Response {
  /**
   * CSV取込件数
   * @type {number}
   * @memberof PostStaffStandardMonthlyRemunerationCsv200Response
   */
  importCount: number;
}
/**
 * 案件の属性
 * @export
 * @enum {string}
 */

export const PotentialCustomerAttribute = {
  Corporation: "corporation",
  Individual: "individual",
} as const;

export type PotentialCustomerAttribute =
  (typeof PotentialCustomerAttribute)[keyof typeof PotentialCustomerAttribute];

/**
 * 案件人物の属性
 * @export
 * @enum {string}
 */

export const PotentialCustomerPersonAttribute = {
  Themself: "themself",
  Partner: "partner",
  Child: "child",
  Other: "other",
} as const;

export type PotentialCustomerPersonAttribute =
  (typeof PotentialCustomerPersonAttribute)[keyof typeof PotentialCustomerPersonAttribute];

/**
 * 案件区分
 * @export
 * @enum {string}
 */

export const PotentialCustomerType = {
  Myself: "myself",
  Market: "market",
  JointRecruitment: "jointRecruitment",
} as const;

export type PotentialCustomerType =
  (typeof PotentialCustomerType)[keyof typeof PotentialCustomerType];

/**
 *
 * @export
 * @interface PutAdjustmentDeductionRequest
 */
export interface PutAdjustmentDeductionRequest {
  /**
   * 調整控除名
   * @type {string}
   * @memberof PutAdjustmentDeductionRequest
   */
  adjustmentDeductionName: string;
  /**
   *
   * @type {PutAdjustmentDeductionRequestAdjustmentDeductionTerm}
   * @memberof PutAdjustmentDeductionRequest
   */
  adjustmentDeductionTerm: PutAdjustmentDeductionRequestAdjustmentDeductionTerm;
}
/**
 * 調整控除の有効期間
 * @export
 * @interface PutAdjustmentDeductionRequestAdjustmentDeductionTerm
 */
export interface PutAdjustmentDeductionRequestAdjustmentDeductionTerm {
  /**
   * 調整控除期間
   * @type {string}
   * @memberof PutAdjustmentDeductionRequestAdjustmentDeductionTerm
   */
  adjustmentDeductionTermTo?: string;
}
/**
 *
 * @export
 * @interface PutCommissionCalculationRequest
 */
export interface PutCommissionCalculationRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof PutCommissionCalculationRequest
   */
  commissionImportIds: Array<number>;
}
/**
 *
 * @export
 * @interface PutContractReportRequest
 */
export interface PutContractReportRequest {
  /**
   *
   * @type {InsuranceCategory}
   * @memberof PutContractReportRequest
   */
  insuranceCategory: InsuranceCategory;
  /**
   *
   * @type {ContractorAttribute}
   * @memberof PutContractReportRequest
   */
  contractorAttribute: ContractorAttribute;
  /**
   * 申達報告日
   * @type {string}
   * @memberof PutContractReportRequest
   */
  contractReportOn: string;
  /**
   *
   * @type {PostContractReportRequestPotentialCustomerInfo}
   * @memberof PutContractReportRequest
   */
  potentialCustomerInfo: PostContractReportRequestPotentialCustomerInfo;
  /**
   *
   * @type {PostContractReportRequestContractorIndividual}
   * @memberof PutContractReportRequest
   */
  contractorIndividual?: PostContractReportRequestContractorIndividual;
  /**
   *
   * @type {PostContractReportRequestContractorCorporation}
   * @memberof PutContractReportRequest
   */
  contractorCorporation?: PostContractReportRequestContractorCorporation;
  /**
   *
   * @type {GetContractReports200ResponseResultsInnerInsured}
   * @memberof PutContractReportRequest
   */
  insured?: GetContractReports200ResponseResultsInnerInsured;
  /**
   *
   * @type {PostContractReportRequestInsuranceInfo}
   * @memberof PutContractReportRequest
   */
  insuranceInfo: PostContractReportRequestInsuranceInfo;
  /**
   *
   * @type {PutContractReportRequestLifeInsuranceInfo}
   * @memberof PutContractReportRequest
   */
  lifeInsuranceInfo?: PutContractReportRequestLifeInsuranceInfo;
  /**
   *
   * @type {PutContractReportRequestNonLifeInsuranceInfo}
   * @memberof PutContractReportRequest
   */
  nonLifeInsuranceInfo?: PutContractReportRequestNonLifeInsuranceInfo;
  /**
   *
   * @type {PostContractReportRequestCommissionShare}
   * @memberof PutContractReportRequest
   */
  commissionShare: PostContractReportRequestCommissionShare;
  /**
   *
   * @type {PostContractReportRequestIncentiveShare}
   * @memberof PutContractReportRequest
   */
  incentiveShare: PostContractReportRequestIncentiveShare;
  /**
   * 募集人ID
   * @type {number}
   * @memberof PutContractReportRequest
   */
  staffId: number;
}
/**
 * 生保固有の情報。insuranceCategoryがlifeInsuranceの場合は必須で、それ以外は設定不可。
 * @export
 * @interface PutContractReportRequestLifeInsuranceInfo
 */
export interface PutContractReportRequestLifeInsuranceInfo {
  /**
   * 契約日
   * @type {string}
   * @memberof PutContractReportRequestLifeInsuranceInfo
   */
  contractDateOn: string;
}
/**
 * 損保固有の情報。insuranceCategoryがnonLifeInsuranceの場合は必須で、それ以外は設定不可。
 * @export
 * @interface PutContractReportRequestNonLifeInsuranceInfo
 */
export interface PutContractReportRequestNonLifeInsuranceInfo {
  /**
   * 証券番号
   * @type {string}
   * @memberof PutContractReportRequestNonLifeInsuranceInfo
   */
  policyNumber?: string;
  /**
   * 申込日
   * @type {string}
   * @memberof PutContractReportRequestNonLifeInsuranceInfo
   */
  applicationDateOn: string;
  /**
   * 保険始期
   * @type {string}
   * @memberof PutContractReportRequestNonLifeInsuranceInfo
   */
  insuranceStartDateOn: string;
  /**
   * 保険終期
   * @type {string}
   * @memberof PutContractReportRequestNonLifeInsuranceInfo
   */
  insuranceEndDateOn: string;
  /**
   * 領収日
   * @type {string}
   * @memberof PutContractReportRequestNonLifeInsuranceInfo
   */
  receiptDateOn?: string;
  /**
   * 返戻日
   * @type {string}
   * @memberof PutContractReportRequestNonLifeInsuranceInfo
   */
  returnDateOn?: string;
  /**
   *
   * @type {NonLifeInsuranceAccountingCategory}
   * @memberof PutContractReportRequestNonLifeInsuranceInfo
   */
  nonLifeInsuranceAccountingCategory: NonLifeInsuranceAccountingCategory;
  /**
   *
   * @type {NonLifeInsuranceDepositCategory}
   * @memberof PutContractReportRequestNonLifeInsuranceInfo
   */
  nonLifeInsuranceDepositCategory: NonLifeInsuranceDepositCategory;
}
/**
 *
 * @export
 * @interface PutPotentialCustomerRequest
 */
export interface PutPotentialCustomerRequest {
  /**
   *
   * @type {PotentialCustomerType}
   * @memberof PutPotentialCustomerRequest
   */
  potentialCustomerType: PotentialCustomerType;
  /**
   * 初回面談日
   * @type {string}
   * @memberof PutPotentialCustomerRequest
   */
  potentialCustomerFirstVisitOn?: string;
  /**
   *
   * @type {PostPotentialCustomerRequestMarket}
   * @memberof PutPotentialCustomerRequest
   */
  market?: PostPotentialCustomerRequestMarket;
  /**
   *
   * @type {PutPotentialCustomerRequestIndividualPotentialCustomer}
   * @memberof PutPotentialCustomerRequest
   */
  individualPotentialCustomer?: PutPotentialCustomerRequestIndividualPotentialCustomer;
  /**
   *
   * @type {PostPotentialCustomerRequestCorporatePotentialCustomer}
   * @memberof PutPotentialCustomerRequest
   */
  corporatePotentialCustomer?: PostPotentialCustomerRequestCorporatePotentialCustomer;
  /**
   * 備考 改行コードを含む
   * @type {string}
   * @memberof PutPotentialCustomerRequest
   */
  memo?: string;
  /**
   * 募集人ID
   * @type {number}
   * @memberof PutPotentialCustomerRequest
   */
  staffId: number;
  /**
   * 許可された募集人
   * @type {Array<number>}
   * @memberof PutPotentialCustomerRequest
   */
  authorizedStaff: Array<number>;
}
/**
 * <dl>   <dt>個人の案件の情報</dt>   <dt>個人の場合は必須で、それ以外は設定不可</dt> </dl>
 * @export
 * @interface PutPotentialCustomerRequestIndividualPotentialCustomer
 */
export interface PutPotentialCustomerRequestIndividualPotentialCustomer {
  /**
   *
   * @type {GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomer
   */
  person: GetPotentialCustomer200ResponseIndividualPotentialCustomerPerson;
  /**
   * <dl>   <dt>家族情報のリスト</dt> </dl>
   * @type {Array<PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner>}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomer
   */
  family: Array<PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner>;
}
/**
 * <dl>   <dt>案件人物</dt> </dl>
 * @export
 * @interface PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
 */
export interface PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner {
  /**
   * 案件人物ID
   * @type {number}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  potentialCustomerPersonId?: number;
  /**
   *
   * @type {PotentialCustomerPersonAttribute}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  potentialCustomerPersonAttribute: PotentialCustomerPersonAttribute;
  /**
   * potentialCustomer内で一意となるsort順
   * @type {number}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  potentialCustomerPersonSortNumber: number;
  /**
   * 苗字
   * @type {string}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  lastName: string;
  /**
   * 名前
   * @type {string}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  firstName: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  lastNameKana: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  firstNameKana: string;
  /**
   * 生年月日
   * @type {string}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  dateOfBirth: string;
  /**
   *
   * @type {string}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  address3?: string;
  /**
   * 番地
   * @type {string}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  address4?: string;
  /**
   * ビル名、部屋番号
   * @type {string}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  address5?: string;
  /**
   *
   * @type {string}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  tel1?: string;
  /**
   *
   * @type {string}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  tel2?: string;
  /**
   * メールアドレス
   * @type {string}
   * @memberof PutPotentialCustomerRequestIndividualPotentialCustomerFamilyInner
   */
  mailAddress?: string;
}
/**
 *
 * @export
 * @interface PutPurchasePotentialCustomerRequest
 */
export interface PutPurchasePotentialCustomerRequest {
  /**
   * 社員番号
   * @type {string}
   * @memberof PutPurchasePotentialCustomerRequest
   */
  staffId: string;
  /**
   *
   * @type {PutPurchasePotentialCustomerRequestPotentialCustomer}
   * @memberof PutPurchasePotentialCustomerRequest
   */
  potentialCustomer: PutPurchasePotentialCustomerRequestPotentialCustomer;
  /**
   *
   * @type {GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomerFee}
   * @memberof PutPurchasePotentialCustomerRequest
   */
  potentialCustomerFee: GetPurchasePotentialCustomers200ResponseResultsInnerPotentialCustomerFee;
  /**
   * 購入案件支払名
   * @type {string}
   * @memberof PutPurchasePotentialCustomerRequest
   */
  purchasePotentialCustomerName: string;
  /**
   * 購入案件対象外フラグ（true：対象外、false：対象）
   * @type {boolean}
   * @memberof PutPurchasePotentialCustomerRequest
   */
  purchasePotentialCustomerOutOfScopeFlag: boolean;
}
/**
 * <dl>    <dt>購入案件支払機能からの更新のため、potentialCustomerIdはpropertiesに存在しない</dt>   <dt>更新対象のpotentialCustomerIdが設定済の場合、更新対象のpotentialCustomerIdはクリアしない</dt>  </dl>
 * @export
 * @interface PutPurchasePotentialCustomerRequestPotentialCustomer
 */
export interface PutPurchasePotentialCustomerRequestPotentialCustomer {
  /**
   * マーケットID
   * @type {number}
   * @memberof PutPurchasePotentialCustomerRequestPotentialCustomer
   */
  marketId: number;
}
/**
 *
 * @export
 * @interface PutSecuritiesCommissionMatching200Response
 */
export interface PutSecuritiesCommissionMatching200Response {
  /**
   * マッチング件数
   * @type {number}
   * @memberof PutSecuritiesCommissionMatching200Response
   */
  commissionMatchingCount: number;
}
/**
 *
 * @export
 * @interface PutSecuritiesRequest
 */
export interface PutSecuritiesRequest {
  /**
   *
   * @type {InsuranceCategory}
   * @memberof PutSecuritiesRequest
   */
  insuranceCategory: InsuranceCategory;
  /**
   *
   * @type {PostContractReportRequestPotentialCustomerInfo}
   * @memberof PutSecuritiesRequest
   */
  potentialCustomerInfo: PostContractReportRequestPotentialCustomerInfo;
  /**
   *
   * @type {ContractorAttribute}
   * @memberof PutSecuritiesRequest
   */
  contractorAttribute: ContractorAttribute;
  /**
   * 申達報告ID
   * @type {number}
   * @memberof PutSecuritiesRequest
   */
  contractReportId?: number;
  /**
   *
   * @type {PutSecuritiesRequestContractorIndividual}
   * @memberof PutSecuritiesRequest
   */
  contractorIndividual?: PutSecuritiesRequestContractorIndividual;
  /**
   *
   * @type {PutSecuritiesRequestContractorCorporation}
   * @memberof PutSecuritiesRequest
   */
  contractorCorporation?: PutSecuritiesRequestContractorCorporation;
  /**
   *
   * @type {PostSecuritiesRequestInsured}
   * @memberof PutSecuritiesRequest
   */
  insured?: PostSecuritiesRequestInsured;
  /**
   *
   * @type {PutSecuritiesRequestInsuranceInfo}
   * @memberof PutSecuritiesRequest
   */
  insuranceInfo: PutSecuritiesRequestInsuranceInfo;
  /**
   *
   * @type {PutSecuritiesRequestLifeInsuranceInfo}
   * @memberof PutSecuritiesRequest
   */
  lifeInsuranceInfo?: PutSecuritiesRequestLifeInsuranceInfo;
  /**
   *
   * @type {PutSecuritiesRequestNonLifeInsuranceInfo}
   * @memberof PutSecuritiesRequest
   */
  nonLifeInsuranceInfo?: PutSecuritiesRequestNonLifeInsuranceInfo;
  /**
   *
   * @type {PostContractReportRequestCommissionShare}
   * @memberof PutSecuritiesRequest
   */
  commissionShare: PostContractReportRequestCommissionShare;
  /**
   *
   * @type {PostContractReportRequestIncentiveShare}
   * @memberof PutSecuritiesRequest
   */
  incentiveShare: PostContractReportRequestIncentiveShare;
  /**
   * 募集人ID
   * @type {number}
   * @memberof PutSecuritiesRequest
   */
  staffId: number;
}
/**
 * <dl>   <dt>contractorAttributeがcorporationの場合に設定される契約者の情報</dt>   <dt>contractorAttributeがcorporationの場合に必須で、それ以外は設定不可</dt> </dl>
 * @export
 * @interface PutSecuritiesRequestContractorCorporation
 */
export interface PutSecuritiesRequestContractorCorporation {
  /**
   * 法人名
   * @type {string}
   * @memberof PutSecuritiesRequestContractorCorporation
   */
  corporationName?: string;
  /**
   * 決算月
   * @type {number}
   * @memberof PutSecuritiesRequestContractorCorporation
   */
  corporationClosingMonth?: number;
  /**
   * 法人代表者名前
   * @type {string}
   * @memberof PutSecuritiesRequestContractorCorporation
   */
  corporationRepresentativeFirstName?: string;
  /**
   * 法人代表者苗字
   * @type {string}
   * @memberof PutSecuritiesRequestContractorCorporation
   */
  corporationRepresentativeLastName?: string;
  /**
   *
   * @type {string}
   * @memberof PutSecuritiesRequestContractorCorporation
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof PutSecuritiesRequestContractorCorporation
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof PutSecuritiesRequestContractorCorporation
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof PutSecuritiesRequestContractorCorporation
   */
  address3?: string;
  /**
   * 番地
   * @type {string}
   * @memberof PutSecuritiesRequestContractorCorporation
   */
  address4?: string;
  /**
   * ビル名、部屋番号
   * @type {string}
   * @memberof PutSecuritiesRequestContractorCorporation
   */
  address5?: string;
  /**
   *
   * @type {string}
   * @memberof PutSecuritiesRequestContractorCorporation
   */
  tel1?: string;
  /**
   *
   * @type {string}
   * @memberof PutSecuritiesRequestContractorCorporation
   */
  tel2?: string;
  /**
   * メールアドレス
   * @type {string}
   * @memberof PutSecuritiesRequestContractorCorporation
   */
  mailAddress?: string;
}
/**
 * <dl>   <dt>contractorAttributeがindividualの場合に設定される契約者の情報</dt>   <dt>contractorAttributeがindividualの場合に必須で、それ以外は設定不可</dt> </dl>
 * @export
 * @interface PutSecuritiesRequestContractorIndividual
 */
export interface PutSecuritiesRequestContractorIndividual {
  /**
   * 名前
   * @type {string}
   * @memberof PutSecuritiesRequestContractorIndividual
   */
  firstName?: string;
  /**
   * 苗字
   * @type {string}
   * @memberof PutSecuritiesRequestContractorIndividual
   */
  lastName?: string;
  /**
   * 名前カナ
   * @type {string}
   * @memberof PutSecuritiesRequestContractorIndividual
   */
  firstNameKana?: string;
  /**
   * 苗字カナ
   * @type {string}
   * @memberof PutSecuritiesRequestContractorIndividual
   */
  lastNameKana?: string;
  /**
   * 生年月日
   * @type {string}
   * @memberof PutSecuritiesRequestContractorIndividual
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof PutSecuritiesRequestContractorIndividual
   */
  postCode?: string;
  /**
   * 住所 都道府県
   * @type {string}
   * @memberof PutSecuritiesRequestContractorIndividual
   */
  address1?: string;
  /**
   * 住所 市区町村
   * @type {string}
   * @memberof PutSecuritiesRequestContractorIndividual
   */
  address2?: string;
  /**
   * 住所 町名
   * @type {string}
   * @memberof PutSecuritiesRequestContractorIndividual
   */
  address3?: string;
  /**
   * 番地
   * @type {string}
   * @memberof PutSecuritiesRequestContractorIndividual
   */
  address4?: string;
  /**
   * ビル名、部屋番号
   * @type {string}
   * @memberof PutSecuritiesRequestContractorIndividual
   */
  address5?: string;
  /**
   *
   * @type {string}
   * @memberof PutSecuritiesRequestContractorIndividual
   */
  tel1?: string;
  /**
   *
   * @type {string}
   * @memberof PutSecuritiesRequestContractorIndividual
   */
  tel2?: string;
  /**
   * メールアドレス
   * @type {string}
   * @memberof PutSecuritiesRequestContractorIndividual
   */
  mailAddress?: string;
  /**
   * 案件人物ID
   * @type {number}
   * @memberof PutSecuritiesRequestContractorIndividual
   */
  potentialCustomerPersonId?: number;
}
/**
 * 生保・損保ともに共通する保険の情報（保険会社ID、証券番号は変更不可のため、項目にない）
 * @export
 * @interface PutSecuritiesRequestInsuranceInfo
 */
export interface PutSecuritiesRequestInsuranceInfo {
  /**
   * 保険種類ID
   * @type {number}
   * @memberof PutSecuritiesRequestInsuranceInfo
   */
  insuranceTypeId: number;
  /**
   * 保険料
   * @type {number}
   * @memberof PutSecuritiesRequestInsuranceInfo
   */
  insuranceFee: number;
  /**
   *
   * @type {PaymentMethod}
   * @memberof PutSecuritiesRequestInsuranceInfo
   */
  paymentMethod?: PaymentMethod;
  /**
   * みなし年払い
   * @type {boolean}
   * @memberof PutSecuritiesRequestInsuranceInfo
   */
  deemedAnnualPayment?: boolean;
}
/**
 * 生保固有の情報。insuranceCategoryがlifeInsuranceの場合は必須で、それ以外は設定不可。
 * @export
 * @interface PutSecuritiesRequestLifeInsuranceInfo
 */
export interface PutSecuritiesRequestLifeInsuranceInfo {
  /**
   * 契約日
   * @type {string}
   * @memberof PutSecuritiesRequestLifeInsuranceInfo
   */
  contractDateOn?: string;
}
/**
 * 損保固有の情報。insuranceCategoryがnonLifeInsuranceの場合は必須で、それ以外は設定不可。
 * @export
 * @interface PutSecuritiesRequestNonLifeInsuranceInfo
 */
export interface PutSecuritiesRequestNonLifeInsuranceInfo {
  /**
   * 申込日
   * @type {string}
   * @memberof PutSecuritiesRequestNonLifeInsuranceInfo
   */
  applicationDateOn?: string;
  /**
   * 保険始期
   * @type {string}
   * @memberof PutSecuritiesRequestNonLifeInsuranceInfo
   */
  insuranceStartDateOn?: string;
  /**
   * 保険終期
   * @type {string}
   * @memberof PutSecuritiesRequestNonLifeInsuranceInfo
   */
  insuranceEndDateOn?: string;
  /**
   * 領収日
   * @type {string}
   * @memberof PutSecuritiesRequestNonLifeInsuranceInfo
   */
  receiptDateOn?: string;
  /**
   * 返戻日
   * @type {string}
   * @memberof PutSecuritiesRequestNonLifeInsuranceInfo
   */
  returnDateOn?: string;
  /**
   *
   * @type {NonLifeInsuranceAccountingCategory}
   * @memberof PutSecuritiesRequestNonLifeInsuranceInfo
   */
  nonLifeInsuranceAccountingCategory?: NonLifeInsuranceAccountingCategory;
  /**
   *
   * @type {NonLifeInsuranceDepositCategory}
   * @memberof PutSecuritiesRequestNonLifeInsuranceInfo
   */
  nonLifeInsuranceDepositCategory?: NonLifeInsuranceDepositCategory;
}
/**
 *
 * @export
 * @interface PutStaffAccountActiveRequest
 */
export interface PutStaffAccountActiveRequest {
  /**
   * アカウント有効フラグ（true：アカウント有効、false：アカウント無効）
   * @type {boolean}
   * @memberof PutStaffAccountActiveRequest
   */
  accountActiveFlag: boolean;
}
/**
 *
 * @export
 * @interface PutStaffMapRequest
 */
export interface PutStaffMapRequest {
  /**
   * 保険会社募集人ID
   * @type {string}
   * @memberof PutStaffMapRequest
   */
  insuranceCompanyStaffId: string;
}
/**
 *
 * @export
 * @interface PutStaffPasswordRequest
 */
export interface PutStaffPasswordRequest {
  /**
   * <dl>    <dt>Cognitoに連携するパスワード</dt>      <dt>以下を満たす文字列</dt>   <dd>     <ul>       <li>少なくとも1つの数字を含む（0-9）</li>       <li>少なくとも1つの大文字を含む（A-Z）</li>       <li>少なくとも1つの小文字を含む（a-z）</li>       <li>8文字以上</li>     </ul>   </dd>  </dl>
   * @type {string}
   * @memberof PutStaffPasswordRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface PutStaffRequest
 */
export interface PutStaffRequest {
  /**
   * 社員番号
   * @type {string}
   * @memberof PutStaffRequest
   */
  staffNumber: string;
  /**
   *
   * @type {StaffRoleType}
   * @memberof PutStaffRequest
   */
  staffRoleType: StaffRoleType;
  /**
   * 業績給対象有無（true：対象）
   * @type {boolean}
   * @memberof PutStaffRequest
   */
  performanceBasedPayFlag: boolean;
  /**
   *
   * @type {PostStaffRequestPersonal}
   * @memberof PutStaffRequest
   */
  personal: PostStaffRequestPersonal;
  /**
   *
   * @type {PostStaffRequestEmergencyContact}
   * @memberof PutStaffRequest
   */
  emergencyContact: PostStaffRequestEmergencyContact;
  /**
   * <dl>    <dt>在籍所属</dt>   <dt>会社に在籍中は、必ずどこか1つの組織に所属している</dt>   <dt>最後の在籍所属の所属期間TOは未設定とする</dt>   <dt>Validation</dt>   <dd>所属期間が重複する場合は400BadRequest</dd>   <dd>所属期間に隙間がある場合は400BadRequest</dd>   <dd>所属期間TOが未設定の在籍所属が1件ない場合は400BadRequest</dd>  </dl>
   * @type {Array<PutStaffRequestDepartmentAffiliationInner>}
   * @memberof PutStaffRequest
   */
  departmentAffiliation: Array<PutStaffRequestDepartmentAffiliationInner>;
  /**
   *
   * @type {PostStaffRequestCompanyAffiliation}
   * @memberof PutStaffRequest
   */
  companyAffiliation: PostStaffRequestCompanyAffiliation;
  /**
   *
   * @type {PostStaffRequestPerformanceBasedPay}
   * @memberof PutStaffRequest
   */
  performanceBasedPay?: PostStaffRequestPerformanceBasedPay;
  /**
   *
   * @type {PostStaffRequestCommissionShareDefault}
   * @memberof PutStaffRequest
   */
  commissionShareDefault: PostStaffRequestCommissionShareDefault;
  /**
   *
   * @type {PostStaffRequestCommissionShareDefault}
   * @memberof PutStaffRequest
   */
  incentiveShareDefault: PostStaffRequestCommissionShareDefault;
  /**
   *
   * @type {PostStaffRequestLicense}
   * @memberof PutStaffRequest
   */
  license?: PostStaffRequestLicense;
}
/**
 *
 * @export
 * @interface PutStaffRequestDepartmentAffiliationInner
 */
export interface PutStaffRequestDepartmentAffiliationInner {
  /**
   * 在籍所属ID
   * @type {number}
   * @memberof PutStaffRequestDepartmentAffiliationInner
   */
  departmentAffiliationId?: number;
  /**
   * 所属ID
   * @type {number}
   * @memberof PutStaffRequestDepartmentAffiliationInner
   */
  departmentId: number;
  /**
   * 所属期間
   * @type {string}
   * @memberof PutStaffRequestDepartmentAffiliationInner
   */
  affiliationAtFrom: string;
  /**
   * 所属期間
   * @type {string}
   * @memberof PutStaffRequestDepartmentAffiliationInner
   */
  affiliationAtTo?: string;
}
/**
 * 証券外務員資格
 * @export
 * @enum {string}
 */

export const SecuritiesSalespersonQualification = {
  NoQualification: "noQualification",
  FirstClass: "firstClass",
  SecondClass: "secondClass",
} as const;

export type SecuritiesSalespersonQualification =
  (typeof SecuritiesSalespersonQualification)[keyof typeof SecuritiesSalespersonQualification];

/**
 * 性別
 * @export
 * @enum {string}
 */

export const Sex = {
  Male: "male",
  Female: "female",
} as const;

export type Sex = (typeof Sex)[keyof typeof Sex];

/**
 * ユーザ種別
 * @export
 * @enum {string}
 */

export const StaffRoleType = {
  Admin: "admin",
  Normal: "normal",
} as const;

export type StaffRoleType = (typeof StaffRoleType)[keyof typeof StaffRoleType];

/**
 * AdjustmentDeductionApi - axios parameter creator
 * @export
 */
export const AdjustmentDeductionApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>staffの調整控除を削除します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に削除可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>削除不可な場合は401を返却します</dd>    <dt>Validation</dt>   <dd>次に該当する場合に削除不可です</dd>   <dd>     <ul>       <li>過去（現在月 > 調整控除期間FROM）の調整控除の削除は不可</li>     </ul>   </dd>   <dd>削除不可な場合は400BadRequestを返却します</dd>  </dl>
     * @summary DeleteAdjustmentDeduction
     * @param {number} adjustmentDeductionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAdjustmentDeduction: async (
      adjustmentDeductionId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'adjustmentDeductionId' is not null or undefined
      assertParamExists(
        "deleteAdjustmentDeduction",
        "adjustmentDeductionId",
        adjustmentDeductionId
      );
      const localVarPath =
        `/adjustmentDeduction/{adjustmentDeductionId}`.replace(
          `{${"adjustmentDeductionId"}}`,
          encodeURIComponent(String(adjustmentDeductionId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>staffの調整控除を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合は登録不可です</dd>   <dd>     <ul>       <li>過去（現在月 > 調整控除期間FROM）の調整控除費の登録は不可</li>       <li>調整控除期間TO < 調整控除期間FROM 不正な期間</li>     </ul>   </dd>   <dd>登録不可な場合は400BadRequestを返却します</dd>  </dl>
     * @summary PostAdjustmentDeduction
     * @param {PostAdjustmentDeductionRequest} postAdjustmentDeductionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAdjustmentDeduction: async (
      postAdjustmentDeductionRequest: PostAdjustmentDeductionRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postAdjustmentDeductionRequest' is not null or undefined
      assertParamExists(
        "postAdjustmentDeduction",
        "postAdjustmentDeductionRequest",
        postAdjustmentDeductionRequest
      );
      const localVarPath = `/adjustmentDeduction`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postAdjustmentDeductionRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>調整控除を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>更新不可な場合は401を返却します</dd>    <dt>変更可能な項目について</dt>   <dd>業績給計算済の調整控除は変更させないため、次の値のみ更新可能としています</dd>   <dd>     <ul>       <li>調整控除名</li>       <li>調整控除期間TO（終了期間の設定や変更）</li>     </ul>   </dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合は登録不可です</dd>   <dd>     <ul>       <li>過去（現在月 > 調整控除期間TO）の期間設定は不可</li>       <li>調整控除期間TO < 調整控除期間FROM 不正な期間</li>     </ul>   </dd>   <dd>登録不可な場合は400BadRequestを返却します</dd>  </dl>
     * @summary PutAdjustmentDeduction
     * @param {number} adjustmentDeductionId
     * @param {PutAdjustmentDeductionRequest} putAdjustmentDeductionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putAdjustmentDeduction: async (
      adjustmentDeductionId: number,
      putAdjustmentDeductionRequest: PutAdjustmentDeductionRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'adjustmentDeductionId' is not null or undefined
      assertParamExists(
        "putAdjustmentDeduction",
        "adjustmentDeductionId",
        adjustmentDeductionId
      );
      // verify required parameter 'putAdjustmentDeductionRequest' is not null or undefined
      assertParamExists(
        "putAdjustmentDeduction",
        "putAdjustmentDeductionRequest",
        putAdjustmentDeductionRequest
      );
      const localVarPath =
        `/adjustmentDeduction/{adjustmentDeductionId}`.replace(
          `{${"adjustmentDeductionId"}}`,
          encodeURIComponent(String(adjustmentDeductionId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        putAdjustmentDeductionRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdjustmentDeductionApi - functional programming interface
 * @export
 */
export const AdjustmentDeductionApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    AdjustmentDeductionApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>staffの調整控除を削除します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に削除可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>削除不可な場合は401を返却します</dd>    <dt>Validation</dt>   <dd>次に該当する場合に削除不可です</dd>   <dd>     <ul>       <li>過去（現在月 > 調整控除期間FROM）の調整控除の削除は不可</li>     </ul>   </dd>   <dd>削除不可な場合は400BadRequestを返却します</dd>  </dl>
     * @summary DeleteAdjustmentDeduction
     * @param {number} adjustmentDeductionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAdjustmentDeduction(
      adjustmentDeductionId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAdjustmentDeduction(
          adjustmentDeductionId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>staffの調整控除を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合は登録不可です</dd>   <dd>     <ul>       <li>過去（現在月 > 調整控除期間FROM）の調整控除費の登録は不可</li>       <li>調整控除期間TO < 調整控除期間FROM 不正な期間</li>     </ul>   </dd>   <dd>登録不可な場合は400BadRequestを返却します</dd>  </dl>
     * @summary PostAdjustmentDeduction
     * @param {PostAdjustmentDeductionRequest} postAdjustmentDeductionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAdjustmentDeduction(
      postAdjustmentDeductionRequest: PostAdjustmentDeductionRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postAdjustmentDeduction(
          postAdjustmentDeductionRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>調整控除を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>更新不可な場合は401を返却します</dd>    <dt>変更可能な項目について</dt>   <dd>業績給計算済の調整控除は変更させないため、次の値のみ更新可能としています</dd>   <dd>     <ul>       <li>調整控除名</li>       <li>調整控除期間TO（終了期間の設定や変更）</li>     </ul>   </dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合は登録不可です</dd>   <dd>     <ul>       <li>過去（現在月 > 調整控除期間TO）の期間設定は不可</li>       <li>調整控除期間TO < 調整控除期間FROM 不正な期間</li>     </ul>   </dd>   <dd>登録不可な場合は400BadRequestを返却します</dd>  </dl>
     * @summary PutAdjustmentDeduction
     * @param {number} adjustmentDeductionId
     * @param {PutAdjustmentDeductionRequest} putAdjustmentDeductionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putAdjustmentDeduction(
      adjustmentDeductionId: number,
      putAdjustmentDeductionRequest: PutAdjustmentDeductionRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putAdjustmentDeduction(
          adjustmentDeductionId,
          putAdjustmentDeductionRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AdjustmentDeductionApi - factory interface
 * @export
 */
export const AdjustmentDeductionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdjustmentDeductionApiFp(configuration);
  return {
    /**
     * <dl>    <dt>staffの調整控除を削除します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に削除可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>削除不可な場合は401を返却します</dd>    <dt>Validation</dt>   <dd>次に該当する場合に削除不可です</dd>   <dd>     <ul>       <li>過去（現在月 > 調整控除期間FROM）の調整控除の削除は不可</li>     </ul>   </dd>   <dd>削除不可な場合は400BadRequestを返却します</dd>  </dl>
     * @summary DeleteAdjustmentDeduction
     * @param {number} adjustmentDeductionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAdjustmentDeduction(
      adjustmentDeductionId: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteAdjustmentDeduction(adjustmentDeductionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>staffの調整控除を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合は登録不可です</dd>   <dd>     <ul>       <li>過去（現在月 > 調整控除期間FROM）の調整控除費の登録は不可</li>       <li>調整控除期間TO < 調整控除期間FROM 不正な期間</li>     </ul>   </dd>   <dd>登録不可な場合は400BadRequestを返却します</dd>  </dl>
     * @summary PostAdjustmentDeduction
     * @param {PostAdjustmentDeductionRequest} postAdjustmentDeductionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAdjustmentDeduction(
      postAdjustmentDeductionRequest: PostAdjustmentDeductionRequest,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .postAdjustmentDeduction(postAdjustmentDeductionRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>調整控除を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>更新不可な場合は401を返却します</dd>    <dt>変更可能な項目について</dt>   <dd>業績給計算済の調整控除は変更させないため、次の値のみ更新可能としています</dd>   <dd>     <ul>       <li>調整控除名</li>       <li>調整控除期間TO（終了期間の設定や変更）</li>     </ul>   </dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合は登録不可です</dd>   <dd>     <ul>       <li>過去（現在月 > 調整控除期間TO）の期間設定は不可</li>       <li>調整控除期間TO < 調整控除期間FROM 不正な期間</li>     </ul>   </dd>   <dd>登録不可な場合は400BadRequestを返却します</dd>  </dl>
     * @summary PutAdjustmentDeduction
     * @param {number} adjustmentDeductionId
     * @param {PutAdjustmentDeductionRequest} putAdjustmentDeductionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putAdjustmentDeduction(
      adjustmentDeductionId: number,
      putAdjustmentDeductionRequest: PutAdjustmentDeductionRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putAdjustmentDeduction(
          adjustmentDeductionId,
          putAdjustmentDeductionRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdjustmentDeductionApi - object-oriented interface
 * @export
 * @class AdjustmentDeductionApi
 * @extends {BaseAPI}
 */
export class AdjustmentDeductionApi extends BaseAPI {
  /**
   * <dl>    <dt>staffの調整控除を削除します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に削除可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>削除不可な場合は401を返却します</dd>    <dt>Validation</dt>   <dd>次に該当する場合に削除不可です</dd>   <dd>     <ul>       <li>過去（現在月 > 調整控除期間FROM）の調整控除の削除は不可</li>     </ul>   </dd>   <dd>削除不可な場合は400BadRequestを返却します</dd>  </dl>
   * @summary DeleteAdjustmentDeduction
   * @param {number} adjustmentDeductionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdjustmentDeductionApi
   */
  public deleteAdjustmentDeduction(
    adjustmentDeductionId: number,
    options?: AxiosRequestConfig
  ) {
    return AdjustmentDeductionApiFp(this.configuration)
      .deleteAdjustmentDeduction(adjustmentDeductionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>staffの調整控除を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合は登録不可です</dd>   <dd>     <ul>       <li>過去（現在月 > 調整控除期間FROM）の調整控除費の登録は不可</li>       <li>調整控除期間TO < 調整控除期間FROM 不正な期間</li>     </ul>   </dd>   <dd>登録不可な場合は400BadRequestを返却します</dd>  </dl>
   * @summary PostAdjustmentDeduction
   * @param {PostAdjustmentDeductionRequest} postAdjustmentDeductionRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdjustmentDeductionApi
   */
  public postAdjustmentDeduction(
    postAdjustmentDeductionRequest: PostAdjustmentDeductionRequest,
    options?: AxiosRequestConfig
  ) {
    return AdjustmentDeductionApiFp(this.configuration)
      .postAdjustmentDeduction(postAdjustmentDeductionRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>調整控除を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>更新不可な場合は401を返却します</dd>    <dt>変更可能な項目について</dt>   <dd>業績給計算済の調整控除は変更させないため、次の値のみ更新可能としています</dd>   <dd>     <ul>       <li>調整控除名</li>       <li>調整控除期間TO（終了期間の設定や変更）</li>     </ul>   </dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合は登録不可です</dd>   <dd>     <ul>       <li>過去（現在月 > 調整控除期間TO）の期間設定は不可</li>       <li>調整控除期間TO < 調整控除期間FROM 不正な期間</li>     </ul>   </dd>   <dd>登録不可な場合は400BadRequestを返却します</dd>  </dl>
   * @summary PutAdjustmentDeduction
   * @param {number} adjustmentDeductionId
   * @param {PutAdjustmentDeductionRequest} putAdjustmentDeductionRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdjustmentDeductionApi
   */
  public putAdjustmentDeduction(
    adjustmentDeductionId: number,
    putAdjustmentDeductionRequest: PutAdjustmentDeductionRequest,
    options?: AxiosRequestConfig
  ) {
    return AdjustmentDeductionApiFp(this.configuration)
      .putAdjustmentDeduction(
        adjustmentDeductionId,
        putAdjustmentDeductionRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdjustmentDeductionQueryApi - axios parameter creator
 * @export
 */
export const AdjustmentDeductionQueryApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>queryに基づき調整控除をCSV取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>adjustmentDeductionTypeId、adjustmentDeductionTermFromの昇順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary GetAdjustmentDeductionCsv
     * @param {number} [staffId]
     * @param {number} [adjustmentDeductionTypeId]
     * @param {string} [adjustmentDeductionTerm] 指定月で有効な調整控除を検索します。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdjustmentDeductionCsv: async (
      staffId?: number,
      adjustmentDeductionTypeId?: number,
      adjustmentDeductionTerm?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/adjustmentDeduction/csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (staffId !== undefined) {
        localVarQueryParameter["staffId"] = staffId;
      }

      if (adjustmentDeductionTypeId !== undefined) {
        localVarQueryParameter["adjustmentDeductionTypeId"] =
          adjustmentDeductionTypeId;
      }

      if (adjustmentDeductionTerm !== undefined) {
        localVarQueryParameter["adjustmentDeductionTerm"] =
          adjustmentDeductionTerm;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>調整控除を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>adjustmentDeductionTypeId、adjustmentDeductionTermFromの昇順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>  </dl>
     * @summary GetAdjustmentDeductions
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {number} [staffId]
     * @param {number} [adjustmentDeductionTypeId]
     * @param {string} [adjustmentDeductionTerm] 指定月で有効な調整控除を検索します。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdjustmentDeductions: async (
      page: number,
      numberPerPage: number,
      staffId?: number,
      adjustmentDeductionTypeId?: number,
      adjustmentDeductionTerm?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getAdjustmentDeductions", "page", page);
      // verify required parameter 'numberPerPage' is not null or undefined
      assertParamExists(
        "getAdjustmentDeductions",
        "numberPerPage",
        numberPerPage
      );
      const localVarPath = `/adjustmentDeduction`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (staffId !== undefined) {
        localVarQueryParameter["staffId"] = staffId;
      }

      if (adjustmentDeductionTypeId !== undefined) {
        localVarQueryParameter["adjustmentDeductionTypeId"] =
          adjustmentDeductionTypeId;
      }

      if (adjustmentDeductionTerm !== undefined) {
        localVarQueryParameter["adjustmentDeductionTerm"] =
          adjustmentDeductionTerm;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberPerPage !== undefined) {
        localVarQueryParameter["numberPerPage"] = numberPerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>調整控除CSVで登録・更新します。</dt>   <dd>調整控除IDが設定されているレコードは更新</dd>   <dd>調整控除IDが未設定のレコードは登録</dd>    <dt>Validation</dt>   <dd>     <ul>       <li>過去（現在月 > 調整控除期間FROM）の調整控除の登録は不可</li>       <li>調整控除期間TO < 調整控除期間FROM 不正な期間</li>     </ul>   </dd>   <dd>登録不可な場合は400BadRequestを返却します</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary PostAdjustmentDeductionCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAdjustmentDeductionCsv: async (
      file: File,
      filename?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("postAdjustmentDeductionCsv", "file", file);
      const localVarPath = `/adjustmentDeduction/csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (filename !== undefined) {
        localVarFormParams.append("filename", filename as any);
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>queryに基づきインセンティブの調整控除をCSV登録します。</dt>   <dd>Staffのインセンティブ取分に応じてインセンティブ調整額を登録します。</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary PostAdjustmentDeductionIncentiveCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAdjustmentDeductionIncentiveCsv: async (
      file: File,
      filename?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("postAdjustmentDeductionIncentiveCsv", "file", file);
      const localVarPath = `/adjustmentDeduction/incentive/csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (filename !== undefined) {
        localVarFormParams.append("filename", filename as any);
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdjustmentDeductionQueryApi - functional programming interface
 * @export
 */
export const AdjustmentDeductionQueryApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    AdjustmentDeductionQueryApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき調整控除をCSV取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>adjustmentDeductionTypeId、adjustmentDeductionTermFromの昇順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary GetAdjustmentDeductionCsv
     * @param {number} [staffId]
     * @param {number} [adjustmentDeductionTypeId]
     * @param {string} [adjustmentDeductionTerm] 指定月で有効な調整控除を検索します。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAdjustmentDeductionCsv(
      staffId?: number,
      adjustmentDeductionTypeId?: number,
      adjustmentDeductionTerm?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAdjustmentDeductionCsv(
          staffId,
          adjustmentDeductionTypeId,
          adjustmentDeductionTerm,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>調整控除を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>adjustmentDeductionTypeId、adjustmentDeductionTermFromの昇順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>  </dl>
     * @summary GetAdjustmentDeductions
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {number} [staffId]
     * @param {number} [adjustmentDeductionTypeId]
     * @param {string} [adjustmentDeductionTerm] 指定月で有効な調整控除を検索します。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAdjustmentDeductions(
      page: number,
      numberPerPage: number,
      staffId?: number,
      adjustmentDeductionTypeId?: number,
      adjustmentDeductionTerm?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetAdjustmentDeductions200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAdjustmentDeductions(
          page,
          numberPerPage,
          staffId,
          adjustmentDeductionTypeId,
          adjustmentDeductionTerm,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>調整控除CSVで登録・更新します。</dt>   <dd>調整控除IDが設定されているレコードは更新</dd>   <dd>調整控除IDが未設定のレコードは登録</dd>    <dt>Validation</dt>   <dd>     <ul>       <li>過去（現在月 > 調整控除期間FROM）の調整控除の登録は不可</li>       <li>調整控除期間TO < 調整控除期間FROM 不正な期間</li>     </ul>   </dd>   <dd>登録不可な場合は400BadRequestを返却します</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary PostAdjustmentDeductionCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAdjustmentDeductionCsv(
      file: File,
      filename?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PostAdjustmentDeductionCsv200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postAdjustmentDeductionCsv(
          file,
          filename,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>queryに基づきインセンティブの調整控除をCSV登録します。</dt>   <dd>Staffのインセンティブ取分に応じてインセンティブ調整額を登録します。</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary PostAdjustmentDeductionIncentiveCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAdjustmentDeductionIncentiveCsv(
      file: File,
      filename?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PostAdjustmentDeductionIncentiveCsv200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postAdjustmentDeductionIncentiveCsv(
          file,
          filename,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AdjustmentDeductionQueryApi - factory interface
 * @export
 */
export const AdjustmentDeductionQueryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdjustmentDeductionQueryApiFp(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき調整控除をCSV取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>adjustmentDeductionTypeId、adjustmentDeductionTermFromの昇順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary GetAdjustmentDeductionCsv
     * @param {number} [staffId]
     * @param {number} [adjustmentDeductionTypeId]
     * @param {string} [adjustmentDeductionTerm] 指定月で有効な調整控除を検索します。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdjustmentDeductionCsv(
      staffId?: number,
      adjustmentDeductionTypeId?: number,
      adjustmentDeductionTerm?: string,
      options?: any
    ): AxiosPromise<File> {
      return localVarFp
        .getAdjustmentDeductionCsv(
          staffId,
          adjustmentDeductionTypeId,
          adjustmentDeductionTerm,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>調整控除を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>adjustmentDeductionTypeId、adjustmentDeductionTermFromの昇順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>  </dl>
     * @summary GetAdjustmentDeductions
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {number} [staffId]
     * @param {number} [adjustmentDeductionTypeId]
     * @param {string} [adjustmentDeductionTerm] 指定月で有効な調整控除を検索します。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdjustmentDeductions(
      page: number,
      numberPerPage: number,
      staffId?: number,
      adjustmentDeductionTypeId?: number,
      adjustmentDeductionTerm?: string,
      options?: any
    ): AxiosPromise<GetAdjustmentDeductions200Response> {
      return localVarFp
        .getAdjustmentDeductions(
          page,
          numberPerPage,
          staffId,
          adjustmentDeductionTypeId,
          adjustmentDeductionTerm,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>調整控除CSVで登録・更新します。</dt>   <dd>調整控除IDが設定されているレコードは更新</dd>   <dd>調整控除IDが未設定のレコードは登録</dd>    <dt>Validation</dt>   <dd>     <ul>       <li>過去（現在月 > 調整控除期間FROM）の調整控除の登録は不可</li>       <li>調整控除期間TO < 調整控除期間FROM 不正な期間</li>     </ul>   </dd>   <dd>登録不可な場合は400BadRequestを返却します</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary PostAdjustmentDeductionCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAdjustmentDeductionCsv(
      file: File,
      filename?: string,
      options?: any
    ): AxiosPromise<PostAdjustmentDeductionCsv200Response> {
      return localVarFp
        .postAdjustmentDeductionCsv(file, filename, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>queryに基づきインセンティブの調整控除をCSV登録します。</dt>   <dd>Staffのインセンティブ取分に応じてインセンティブ調整額を登録します。</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary PostAdjustmentDeductionIncentiveCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAdjustmentDeductionIncentiveCsv(
      file: File,
      filename?: string,
      options?: any
    ): AxiosPromise<PostAdjustmentDeductionIncentiveCsv200Response> {
      return localVarFp
        .postAdjustmentDeductionIncentiveCsv(file, filename, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdjustmentDeductionQueryApi - object-oriented interface
 * @export
 * @class AdjustmentDeductionQueryApi
 * @extends {BaseAPI}
 */
export class AdjustmentDeductionQueryApi extends BaseAPI {
  /**
   * <dl>    <dt>queryに基づき調整控除をCSV取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>adjustmentDeductionTypeId、adjustmentDeductionTermFromの昇順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
   * @summary GetAdjustmentDeductionCsv
   * @param {number} [staffId]
   * @param {number} [adjustmentDeductionTypeId]
   * @param {string} [adjustmentDeductionTerm] 指定月で有効な調整控除を検索します。
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdjustmentDeductionQueryApi
   */
  public getAdjustmentDeductionCsv(
    staffId?: number,
    adjustmentDeductionTypeId?: number,
    adjustmentDeductionTerm?: string,
    options?: AxiosRequestConfig
  ) {
    return AdjustmentDeductionQueryApiFp(this.configuration)
      .getAdjustmentDeductionCsv(
        staffId,
        adjustmentDeductionTypeId,
        adjustmentDeductionTerm,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>調整控除を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>adjustmentDeductionTypeId、adjustmentDeductionTermFromの昇順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>  </dl>
   * @summary GetAdjustmentDeductions
   * @param {number} page 取得したいpage
   * @param {number} numberPerPage 1ページあたりの検索結果数
   * @param {number} [staffId]
   * @param {number} [adjustmentDeductionTypeId]
   * @param {string} [adjustmentDeductionTerm] 指定月で有効な調整控除を検索します。
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdjustmentDeductionQueryApi
   */
  public getAdjustmentDeductions(
    page: number,
    numberPerPage: number,
    staffId?: number,
    adjustmentDeductionTypeId?: number,
    adjustmentDeductionTerm?: string,
    options?: AxiosRequestConfig
  ) {
    return AdjustmentDeductionQueryApiFp(this.configuration)
      .getAdjustmentDeductions(
        page,
        numberPerPage,
        staffId,
        adjustmentDeductionTypeId,
        adjustmentDeductionTerm,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>調整控除CSVで登録・更新します。</dt>   <dd>調整控除IDが設定されているレコードは更新</dd>   <dd>調整控除IDが未設定のレコードは登録</dd>    <dt>Validation</dt>   <dd>     <ul>       <li>過去（現在月 > 調整控除期間FROM）の調整控除の登録は不可</li>       <li>調整控除期間TO < 調整控除期間FROM 不正な期間</li>     </ul>   </dd>   <dd>登録不可な場合は400BadRequestを返却します</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
   * @summary PostAdjustmentDeductionCsv
   * @param {File} file
   * @param {string} [filename]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdjustmentDeductionQueryApi
   */
  public postAdjustmentDeductionCsv(
    file: File,
    filename?: string,
    options?: AxiosRequestConfig
  ) {
    return AdjustmentDeductionQueryApiFp(this.configuration)
      .postAdjustmentDeductionCsv(file, filename, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>queryに基づきインセンティブの調整控除をCSV登録します。</dt>   <dd>Staffのインセンティブ取分に応じてインセンティブ調整額を登録します。</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
   * @summary PostAdjustmentDeductionIncentiveCsv
   * @param {File} file
   * @param {string} [filename]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdjustmentDeductionQueryApi
   */
  public postAdjustmentDeductionIncentiveCsv(
    file: File,
    filename?: string,
    options?: AxiosRequestConfig
  ) {
    return AdjustmentDeductionQueryApiFp(this.configuration)
      .postAdjustmentDeductionIncentiveCsv(file, filename, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdjustmentDeductionTypeApi - axios parameter creator
 * @export
 */
export const AdjustmentDeductionTypeApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>調整控除項目を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostAdjustmentDeductionType
     * @param {PostAdjustmentDeductionTypeRequest} postAdjustmentDeductionTypeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAdjustmentDeductionType: async (
      postAdjustmentDeductionTypeRequest: PostAdjustmentDeductionTypeRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postAdjustmentDeductionTypeRequest' is not null or undefined
      assertParamExists(
        "postAdjustmentDeductionType",
        "postAdjustmentDeductionTypeRequest",
        postAdjustmentDeductionTypeRequest
      );
      const localVarPath = `/adjustmentDeductionType`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postAdjustmentDeductionTypeRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>調整控除項目を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutAdjustmentDeductionType
     * @param {number} adjustmentDeductionTypeId
     * @param {PostAdjustmentDeductionTypeRequest} postAdjustmentDeductionTypeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putAdjustmentDeductionType: async (
      adjustmentDeductionTypeId: number,
      postAdjustmentDeductionTypeRequest: PostAdjustmentDeductionTypeRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'adjustmentDeductionTypeId' is not null or undefined
      assertParamExists(
        "putAdjustmentDeductionType",
        "adjustmentDeductionTypeId",
        adjustmentDeductionTypeId
      );
      // verify required parameter 'postAdjustmentDeductionTypeRequest' is not null or undefined
      assertParamExists(
        "putAdjustmentDeductionType",
        "postAdjustmentDeductionTypeRequest",
        postAdjustmentDeductionTypeRequest
      );
      const localVarPath =
        `/adjustmentDeductionType/{adjustmentDeductionTypeId}`.replace(
          `{${"adjustmentDeductionTypeId"}}`,
          encodeURIComponent(String(adjustmentDeductionTypeId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postAdjustmentDeductionTypeRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdjustmentDeductionTypeApi - functional programming interface
 * @export
 */
export const AdjustmentDeductionTypeApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    AdjustmentDeductionTypeApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>調整控除項目を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostAdjustmentDeductionType
     * @param {PostAdjustmentDeductionTypeRequest} postAdjustmentDeductionTypeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAdjustmentDeductionType(
      postAdjustmentDeductionTypeRequest: PostAdjustmentDeductionTypeRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postAdjustmentDeductionType(
          postAdjustmentDeductionTypeRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>調整控除項目を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutAdjustmentDeductionType
     * @param {number} adjustmentDeductionTypeId
     * @param {PostAdjustmentDeductionTypeRequest} postAdjustmentDeductionTypeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putAdjustmentDeductionType(
      adjustmentDeductionTypeId: number,
      postAdjustmentDeductionTypeRequest: PostAdjustmentDeductionTypeRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putAdjustmentDeductionType(
          adjustmentDeductionTypeId,
          postAdjustmentDeductionTypeRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AdjustmentDeductionTypeApi - factory interface
 * @export
 */
export const AdjustmentDeductionTypeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdjustmentDeductionTypeApiFp(configuration);
  return {
    /**
     * <dl>    <dt>調整控除項目を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostAdjustmentDeductionType
     * @param {PostAdjustmentDeductionTypeRequest} postAdjustmentDeductionTypeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAdjustmentDeductionType(
      postAdjustmentDeductionTypeRequest: PostAdjustmentDeductionTypeRequest,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .postAdjustmentDeductionType(
          postAdjustmentDeductionTypeRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>調整控除項目を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutAdjustmentDeductionType
     * @param {number} adjustmentDeductionTypeId
     * @param {PostAdjustmentDeductionTypeRequest} postAdjustmentDeductionTypeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putAdjustmentDeductionType(
      adjustmentDeductionTypeId: number,
      postAdjustmentDeductionTypeRequest: PostAdjustmentDeductionTypeRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putAdjustmentDeductionType(
          adjustmentDeductionTypeId,
          postAdjustmentDeductionTypeRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdjustmentDeductionTypeApi - object-oriented interface
 * @export
 * @class AdjustmentDeductionTypeApi
 * @extends {BaseAPI}
 */
export class AdjustmentDeductionTypeApi extends BaseAPI {
  /**
   * <dl>    <dt>調整控除項目を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PostAdjustmentDeductionType
   * @param {PostAdjustmentDeductionTypeRequest} postAdjustmentDeductionTypeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdjustmentDeductionTypeApi
   */
  public postAdjustmentDeductionType(
    postAdjustmentDeductionTypeRequest: PostAdjustmentDeductionTypeRequest,
    options?: AxiosRequestConfig
  ) {
    return AdjustmentDeductionTypeApiFp(this.configuration)
      .postAdjustmentDeductionType(postAdjustmentDeductionTypeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>調整控除項目を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PutAdjustmentDeductionType
   * @param {number} adjustmentDeductionTypeId
   * @param {PostAdjustmentDeductionTypeRequest} postAdjustmentDeductionTypeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdjustmentDeductionTypeApi
   */
  public putAdjustmentDeductionType(
    adjustmentDeductionTypeId: number,
    postAdjustmentDeductionTypeRequest: PostAdjustmentDeductionTypeRequest,
    options?: AxiosRequestConfig
  ) {
    return AdjustmentDeductionTypeApiFp(this.configuration)
      .putAdjustmentDeductionType(
        adjustmentDeductionTypeId,
        postAdjustmentDeductionTypeRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdjustmentDeductionTypeQueryApi - axios parameter creator
 * @export
 */
export const AdjustmentDeductionTypeQueryApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>queryに基づき調整控除項目を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>表示順の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetAdjustmentDeductionTypes
     * @param {AdjustmentDeductionTypeAttribute} [adjustmentDeductionTypeAttribute] 調整控除項目属性
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdjustmentDeductionTypes: async (
      adjustmentDeductionTypeAttribute?: AdjustmentDeductionTypeAttribute,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/adjustmentDeductionType`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (adjustmentDeductionTypeAttribute !== undefined) {
        localVarQueryParameter["adjustmentDeductionTypeAttribute"] =
          adjustmentDeductionTypeAttribute;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdjustmentDeductionTypeQueryApi - functional programming interface
 * @export
 */
export const AdjustmentDeductionTypeQueryApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    AdjustmentDeductionTypeQueryApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき調整控除項目を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>表示順の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetAdjustmentDeductionTypes
     * @param {AdjustmentDeductionTypeAttribute} [adjustmentDeductionTypeAttribute] 調整控除項目属性
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAdjustmentDeductionTypes(
      adjustmentDeductionTypeAttribute?: AdjustmentDeductionTypeAttribute,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetAdjustmentDeductionTypes200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAdjustmentDeductionTypes(
          adjustmentDeductionTypeAttribute,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AdjustmentDeductionTypeQueryApi - factory interface
 * @export
 */
export const AdjustmentDeductionTypeQueryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdjustmentDeductionTypeQueryApiFp(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき調整控除項目を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>表示順の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetAdjustmentDeductionTypes
     * @param {AdjustmentDeductionTypeAttribute} [adjustmentDeductionTypeAttribute] 調整控除項目属性
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdjustmentDeductionTypes(
      adjustmentDeductionTypeAttribute?: AdjustmentDeductionTypeAttribute,
      options?: any
    ): AxiosPromise<GetAdjustmentDeductionTypes200Response> {
      return localVarFp
        .getAdjustmentDeductionTypes(adjustmentDeductionTypeAttribute, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdjustmentDeductionTypeQueryApi - object-oriented interface
 * @export
 * @class AdjustmentDeductionTypeQueryApi
 * @extends {BaseAPI}
 */
export class AdjustmentDeductionTypeQueryApi extends BaseAPI {
  /**
   * <dl>    <dt>queryに基づき調整控除項目を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>表示順の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
   * @summary GetAdjustmentDeductionTypes
   * @param {AdjustmentDeductionTypeAttribute} [adjustmentDeductionTypeAttribute] 調整控除項目属性
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdjustmentDeductionTypeQueryApi
   */
  public getAdjustmentDeductionTypes(
    adjustmentDeductionTypeAttribute?: AdjustmentDeductionTypeAttribute,
    options?: AxiosRequestConfig
  ) {
    return AdjustmentDeductionTypeQueryApiFp(this.configuration)
      .getAdjustmentDeductionTypes(adjustmentDeductionTypeAttribute, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CommissionApi - axios parameter creator
 * @export
 */
export const CommissionApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>手数料取込したCSVから発生した手数料、手数料マッチング、手数料計算結果の情報をクリアします。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>手数料取込</dt>   <dd>手数料取込IDに紐づくレコードの手数料取込ステータスを削除に更新します</dd>    <dt>手数料</dt>   <dd>手数料取込IDに紐づくレコードを削除します</dd>    <dt>手数料計算結果</dt>   <dd>手数料取込IDに紐づく手数料に紐づくレコードを削除します</dd>  </dl>
     * @summary DeleteCommissionImport
     * @param {number} commissionImportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCommissionImport: async (
      commissionImportId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'commissionImportId' is not null or undefined
      assertParamExists(
        "deleteCommissionImport",
        "commissionImportId",
        commissionImportId
      );
      const localVarPath = `/commission/import/{commissionImportId}`.replace(
        `{${"commissionImportId"}}`,
        encodeURIComponent(String(commissionImportId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>手数料CSVをインポートします。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合にinvalidです</dd>   <dd>     <ul>       <li>既に手数料取込データが存在する</li>       <li>pathの情報に紐づく手数料フォーマットが存在しない</li>       <li>手数料CSVがCSVとして読み込めない</li>       <li>手数料CSVの取込項目がinvalid</li>     </ul>   </dd>   <dd>invalidな場合は400を返却します</dd>   <dd>     <ul>       <li>invalidの内容がわかるメッセージを返却します</li>       <li>手数料CSVの取込項目がinvalidな場合は該当行数もメッセージに表示します</li>     </ul>   </dd>    <dt>手数料インポート</dt>   <dd>手数料CSVから手数料データを作成します</dd>   <dd>リクエスト情報から手数料取込データを作成します</dd>   <dd>取込の仕様は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97.md#%E6%89%8B%E6%95%B0%E6%96%99%E5%8F%96%E8%BE%BC%E5%87%A6%E7%90%86\">手数料取込処理</a>を参照</dd>  </dl>
     * @summary PostCommissionCsv
     * @param {number} insuranceCompanyId
     * @param {CommissionCategory} commissionCategory
     * @param {string} month
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postCommissionCsv: async (
      insuranceCompanyId: number,
      commissionCategory: CommissionCategory,
      month: string,
      file: File,
      filename?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'insuranceCompanyId' is not null or undefined
      assertParamExists(
        "postCommissionCsv",
        "insuranceCompanyId",
        insuranceCompanyId
      );
      // verify required parameter 'commissionCategory' is not null or undefined
      assertParamExists(
        "postCommissionCsv",
        "commissionCategory",
        commissionCategory
      );
      // verify required parameter 'month' is not null or undefined
      assertParamExists("postCommissionCsv", "month", month);
      // verify required parameter 'file' is not null or undefined
      assertParamExists("postCommissionCsv", "file", file);
      const localVarPath =
        `/commission/{insuranceCompanyId}/{commissionCategory}/{month}`
          .replace(
            `{${"insuranceCompanyId"}}`,
            encodeURIComponent(String(insuranceCompanyId))
          )
          .replace(
            `{${"commissionCategory"}}`,
            encodeURIComponent(String(commissionCategory))
          )
          .replace(`{${"month"}}`, encodeURIComponent(String(month)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (filename !== undefined) {
        localVarFormParams.append("filename", filename as any);
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CommissionApi - functional programming interface
 * @export
 */
export const CommissionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CommissionApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>手数料取込したCSVから発生した手数料、手数料マッチング、手数料計算結果の情報をクリアします。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>手数料取込</dt>   <dd>手数料取込IDに紐づくレコードの手数料取込ステータスを削除に更新します</dd>    <dt>手数料</dt>   <dd>手数料取込IDに紐づくレコードを削除します</dd>    <dt>手数料計算結果</dt>   <dd>手数料取込IDに紐づく手数料に紐づくレコードを削除します</dd>  </dl>
     * @summary DeleteCommissionImport
     * @param {number} commissionImportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCommissionImport(
      commissionImportId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteCommissionImport(
          commissionImportId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>手数料CSVをインポートします。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合にinvalidです</dd>   <dd>     <ul>       <li>既に手数料取込データが存在する</li>       <li>pathの情報に紐づく手数料フォーマットが存在しない</li>       <li>手数料CSVがCSVとして読み込めない</li>       <li>手数料CSVの取込項目がinvalid</li>     </ul>   </dd>   <dd>invalidな場合は400を返却します</dd>   <dd>     <ul>       <li>invalidの内容がわかるメッセージを返却します</li>       <li>手数料CSVの取込項目がinvalidな場合は該当行数もメッセージに表示します</li>     </ul>   </dd>    <dt>手数料インポート</dt>   <dd>手数料CSVから手数料データを作成します</dd>   <dd>リクエスト情報から手数料取込データを作成します</dd>   <dd>取込の仕様は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97.md#%E6%89%8B%E6%95%B0%E6%96%99%E5%8F%96%E8%BE%BC%E5%87%A6%E7%90%86\">手数料取込処理</a>を参照</dd>  </dl>
     * @summary PostCommissionCsv
     * @param {number} insuranceCompanyId
     * @param {CommissionCategory} commissionCategory
     * @param {string} month
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postCommissionCsv(
      insuranceCompanyId: number,
      commissionCategory: CommissionCategory,
      month: string,
      file: File,
      filename?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PostCommissionCsv200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postCommissionCsv(
          insuranceCompanyId,
          commissionCategory,
          month,
          file,
          filename,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * CommissionApi - factory interface
 * @export
 */
export const CommissionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CommissionApiFp(configuration);
  return {
    /**
     * <dl>    <dt>手数料取込したCSVから発生した手数料、手数料マッチング、手数料計算結果の情報をクリアします。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>手数料取込</dt>   <dd>手数料取込IDに紐づくレコードの手数料取込ステータスを削除に更新します</dd>    <dt>手数料</dt>   <dd>手数料取込IDに紐づくレコードを削除します</dd>    <dt>手数料計算結果</dt>   <dd>手数料取込IDに紐づく手数料に紐づくレコードを削除します</dd>  </dl>
     * @summary DeleteCommissionImport
     * @param {number} commissionImportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCommissionImport(
      commissionImportId: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteCommissionImport(commissionImportId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>手数料CSVをインポートします。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合にinvalidです</dd>   <dd>     <ul>       <li>既に手数料取込データが存在する</li>       <li>pathの情報に紐づく手数料フォーマットが存在しない</li>       <li>手数料CSVがCSVとして読み込めない</li>       <li>手数料CSVの取込項目がinvalid</li>     </ul>   </dd>   <dd>invalidな場合は400を返却します</dd>   <dd>     <ul>       <li>invalidの内容がわかるメッセージを返却します</li>       <li>手数料CSVの取込項目がinvalidな場合は該当行数もメッセージに表示します</li>     </ul>   </dd>    <dt>手数料インポート</dt>   <dd>手数料CSVから手数料データを作成します</dd>   <dd>リクエスト情報から手数料取込データを作成します</dd>   <dd>取込の仕様は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97.md#%E6%89%8B%E6%95%B0%E6%96%99%E5%8F%96%E8%BE%BC%E5%87%A6%E7%90%86\">手数料取込処理</a>を参照</dd>  </dl>
     * @summary PostCommissionCsv
     * @param {number} insuranceCompanyId
     * @param {CommissionCategory} commissionCategory
     * @param {string} month
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postCommissionCsv(
      insuranceCompanyId: number,
      commissionCategory: CommissionCategory,
      month: string,
      file: File,
      filename?: string,
      options?: any
    ): AxiosPromise<PostCommissionCsv200Response> {
      return localVarFp
        .postCommissionCsv(
          insuranceCompanyId,
          commissionCategory,
          month,
          file,
          filename,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CommissionApi - object-oriented interface
 * @export
 * @class CommissionApi
 * @extends {BaseAPI}
 */
export class CommissionApi extends BaseAPI {
  /**
   * <dl>    <dt>手数料取込したCSVから発生した手数料、手数料マッチング、手数料計算結果の情報をクリアします。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>手数料取込</dt>   <dd>手数料取込IDに紐づくレコードの手数料取込ステータスを削除に更新します</dd>    <dt>手数料</dt>   <dd>手数料取込IDに紐づくレコードを削除します</dd>    <dt>手数料計算結果</dt>   <dd>手数料取込IDに紐づく手数料に紐づくレコードを削除します</dd>  </dl>
   * @summary DeleteCommissionImport
   * @param {number} commissionImportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommissionApi
   */
  public deleteCommissionImport(
    commissionImportId: number,
    options?: AxiosRequestConfig
  ) {
    return CommissionApiFp(this.configuration)
      .deleteCommissionImport(commissionImportId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>手数料CSVをインポートします。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合にinvalidです</dd>   <dd>     <ul>       <li>既に手数料取込データが存在する</li>       <li>pathの情報に紐づく手数料フォーマットが存在しない</li>       <li>手数料CSVがCSVとして読み込めない</li>       <li>手数料CSVの取込項目がinvalid</li>     </ul>   </dd>   <dd>invalidな場合は400を返却します</dd>   <dd>     <ul>       <li>invalidの内容がわかるメッセージを返却します</li>       <li>手数料CSVの取込項目がinvalidな場合は該当行数もメッセージに表示します</li>     </ul>   </dd>    <dt>手数料インポート</dt>   <dd>手数料CSVから手数料データを作成します</dd>   <dd>リクエスト情報から手数料取込データを作成します</dd>   <dd>取込の仕様は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97.md#%E6%89%8B%E6%95%B0%E6%96%99%E5%8F%96%E8%BE%BC%E5%87%A6%E7%90%86\">手数料取込処理</a>を参照</dd>  </dl>
   * @summary PostCommissionCsv
   * @param {number} insuranceCompanyId
   * @param {CommissionCategory} commissionCategory
   * @param {string} month
   * @param {File} file
   * @param {string} [filename]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommissionApi
   */
  public postCommissionCsv(
    insuranceCompanyId: number,
    commissionCategory: CommissionCategory,
    month: string,
    file: File,
    filename?: string,
    options?: AxiosRequestConfig
  ) {
    return CommissionApiFp(this.configuration)
      .postCommissionCsv(
        insuranceCompanyId,
        commissionCategory,
        month,
        file,
        filename,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CommissionFormatApi - axios parameter creator
 * @export
 */
export const CommissionFormatApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>手数料フォーマットを取得します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>   <dd>取得結果がない場合は404を返却します</dd>  </dl>
     * @summary GetCommissionFormat
     * @param {number} insuranceCompanyId
     * @param {CommissionCategory} commissionCategory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissionFormat: async (
      insuranceCompanyId: number,
      commissionCategory: CommissionCategory,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'insuranceCompanyId' is not null or undefined
      assertParamExists(
        "getCommissionFormat",
        "insuranceCompanyId",
        insuranceCompanyId
      );
      // verify required parameter 'commissionCategory' is not null or undefined
      assertParamExists(
        "getCommissionFormat",
        "commissionCategory",
        commissionCategory
      );
      const localVarPath =
        `/commissionFormat/{insuranceCompanyId}/{commissionCategory}`
          .replace(
            `{${"insuranceCompanyId"}}`,
            encodeURIComponent(String(insuranceCompanyId))
          )
          .replace(
            `{${"commissionCategory"}}`,
            encodeURIComponent(String(commissionCategory))
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>手数料フォーマットを登録・更新します。</dt>   <dd>保険会社ID、手数料区分で既に手数料フォーマットがある場合は同データを更新。無い場合は新規登録します。</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutCommissionFormat
     * @param {number} insuranceCompanyId
     * @param {CommissionCategory} commissionCategory
     * @param {GetCommissionFormat200Response} getCommissionFormat200Response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putCommissionFormat: async (
      insuranceCompanyId: number,
      commissionCategory: CommissionCategory,
      getCommissionFormat200Response: GetCommissionFormat200Response,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'insuranceCompanyId' is not null or undefined
      assertParamExists(
        "putCommissionFormat",
        "insuranceCompanyId",
        insuranceCompanyId
      );
      // verify required parameter 'commissionCategory' is not null or undefined
      assertParamExists(
        "putCommissionFormat",
        "commissionCategory",
        commissionCategory
      );
      // verify required parameter 'getCommissionFormat200Response' is not null or undefined
      assertParamExists(
        "putCommissionFormat",
        "getCommissionFormat200Response",
        getCommissionFormat200Response
      );
      const localVarPath =
        `/commissionFormat/{insuranceCompanyId}/{commissionCategory}`
          .replace(
            `{${"insuranceCompanyId"}}`,
            encodeURIComponent(String(insuranceCompanyId))
          )
          .replace(
            `{${"commissionCategory"}}`,
            encodeURIComponent(String(commissionCategory))
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getCommissionFormat200Response,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CommissionFormatApi - functional programming interface
 * @export
 */
export const CommissionFormatApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CommissionFormatApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>手数料フォーマットを取得します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>   <dd>取得結果がない場合は404を返却します</dd>  </dl>
     * @summary GetCommissionFormat
     * @param {number} insuranceCompanyId
     * @param {CommissionCategory} commissionCategory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommissionFormat(
      insuranceCompanyId: number,
      commissionCategory: CommissionCategory,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetCommissionFormat200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCommissionFormat(
          insuranceCompanyId,
          commissionCategory,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>手数料フォーマットを登録・更新します。</dt>   <dd>保険会社ID、手数料区分で既に手数料フォーマットがある場合は同データを更新。無い場合は新規登録します。</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutCommissionFormat
     * @param {number} insuranceCompanyId
     * @param {CommissionCategory} commissionCategory
     * @param {GetCommissionFormat200Response} getCommissionFormat200Response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putCommissionFormat(
      insuranceCompanyId: number,
      commissionCategory: CommissionCategory,
      getCommissionFormat200Response: GetCommissionFormat200Response,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putCommissionFormat(
          insuranceCompanyId,
          commissionCategory,
          getCommissionFormat200Response,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * CommissionFormatApi - factory interface
 * @export
 */
export const CommissionFormatApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CommissionFormatApiFp(configuration);
  return {
    /**
     * <dl>    <dt>手数料フォーマットを取得します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>   <dd>取得結果がない場合は404を返却します</dd>  </dl>
     * @summary GetCommissionFormat
     * @param {number} insuranceCompanyId
     * @param {CommissionCategory} commissionCategory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissionFormat(
      insuranceCompanyId: number,
      commissionCategory: CommissionCategory,
      options?: any
    ): AxiosPromise<GetCommissionFormat200Response> {
      return localVarFp
        .getCommissionFormat(insuranceCompanyId, commissionCategory, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>手数料フォーマットを登録・更新します。</dt>   <dd>保険会社ID、手数料区分で既に手数料フォーマットがある場合は同データを更新。無い場合は新規登録します。</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutCommissionFormat
     * @param {number} insuranceCompanyId
     * @param {CommissionCategory} commissionCategory
     * @param {GetCommissionFormat200Response} getCommissionFormat200Response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putCommissionFormat(
      insuranceCompanyId: number,
      commissionCategory: CommissionCategory,
      getCommissionFormat200Response: GetCommissionFormat200Response,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putCommissionFormat(
          insuranceCompanyId,
          commissionCategory,
          getCommissionFormat200Response,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CommissionFormatApi - object-oriented interface
 * @export
 * @class CommissionFormatApi
 * @extends {BaseAPI}
 */
export class CommissionFormatApi extends BaseAPI {
  /**
   * <dl>    <dt>手数料フォーマットを取得します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>   <dd>取得結果がない場合は404を返却します</dd>  </dl>
   * @summary GetCommissionFormat
   * @param {number} insuranceCompanyId
   * @param {CommissionCategory} commissionCategory
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommissionFormatApi
   */
  public getCommissionFormat(
    insuranceCompanyId: number,
    commissionCategory: CommissionCategory,
    options?: AxiosRequestConfig
  ) {
    return CommissionFormatApiFp(this.configuration)
      .getCommissionFormat(insuranceCompanyId, commissionCategory, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>手数料フォーマットを登録・更新します。</dt>   <dd>保険会社ID、手数料区分で既に手数料フォーマットがある場合は同データを更新。無い場合は新規登録します。</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PutCommissionFormat
   * @param {number} insuranceCompanyId
   * @param {CommissionCategory} commissionCategory
   * @param {GetCommissionFormat200Response} getCommissionFormat200Response
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommissionFormatApi
   */
  public putCommissionFormat(
    insuranceCompanyId: number,
    commissionCategory: CommissionCategory,
    getCommissionFormat200Response: GetCommissionFormat200Response,
    options?: AxiosRequestConfig
  ) {
    return CommissionFormatApiFp(this.configuration)
      .putCommissionFormat(
        insuranceCompanyId,
        commissionCategory,
        getCommissionFormat200Response,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CommissionQueryApi - axios parameter creator
 * @export
 */
export const CommissionQueryApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>staffの手数料計算結果を取得します。</dt>    <dt>取得内容</dt>   <dd>手数料計算結果のmyself、boss、otherStaff1、otherStaff2、otherStaff3のいずれかが、リクエストのstaffIdにあたる計算結果を取得します。</dd>    <dt>取得結果のソート順</dt>   <dd>insuranceCategory、insuranceCompanyId、commissionCategoryの昇順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の業績参照権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetCommissionCalculationResults
     * @param {number} staffId
     * @param {string} month
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissionCalculationResults: async (
      staffId: number,
      month: string,
      page: number,
      numberPerPage: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffId' is not null or undefined
      assertParamExists("getCommissionCalculationResults", "staffId", staffId);
      // verify required parameter 'month' is not null or undefined
      assertParamExists("getCommissionCalculationResults", "month", month);
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getCommissionCalculationResults", "page", page);
      // verify required parameter 'numberPerPage' is not null or undefined
      assertParamExists(
        "getCommissionCalculationResults",
        "numberPerPage",
        numberPerPage
      );
      const localVarPath = `/commission/calculation/{staffId}/{month}`
        .replace(`{${"staffId"}}`, encodeURIComponent(String(staffId)))
        .replace(`{${"month"}}`, encodeURIComponent(String(month)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberPerPage !== undefined) {
        localVarQueryParameter["numberPerPage"] = numberPerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>staffの手数料計算結果のCSVを取得します。</dt>    <dt>取得内容</dt>   <dd>手数料計算結果のmyself、boss、otherStaff1、otherStaff2、otherStaff3のいずれかが、リクエストのstaffIdにあたる計算結果を取得します。</dd>    <dt>取得結果のソート順</dt>   <dd>insuranceCategory、insuranceCompanyId、commissionCategoryの昇順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の業績参照権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetCommissionCalculationResultsCsv
     * @param {number} staffId
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissionCalculationResultsCsv: async (
      staffId: number,
      month: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffId' is not null or undefined
      assertParamExists(
        "getCommissionCalculationResultsCsv",
        "staffId",
        staffId
      );
      // verify required parameter 'month' is not null or undefined
      assertParamExists("getCommissionCalculationResultsCsv", "month", month);
      const localVarPath = `/commission/calculation/{staffId}/{month}/csv`
        .replace(`{${"staffId"}}`, encodeURIComponent(String(staffId)))
        .replace(`{${"month"}}`, encodeURIComponent(String(month)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>staffの保険会社毎の手数料計算結果を取得します。</dt>    <dt>取得内容</dt>   <dd>pathのstaffId、支給月で手数料計算結果を保険会社毎に、初年度手数料・初年度以外手数料・インセンティブの手数料合計値を取得します。</dd>   <dd>初年度の判定は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97.md#%E5%88%9D%E5%B9%B4%E5%BA%A6%E3%83%95%E3%83%A9%E3%82%B0%E3%81%AE%E5%88%A4%E5%AE%9A\">こちらを参照</a></dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の業績参照権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetCommissionInsuranceCompanyCalculationResults
     * @param {number} staffId
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissionInsuranceCompanyCalculationResults: async (
      staffId: number,
      month: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffId' is not null or undefined
      assertParamExists(
        "getCommissionInsuranceCompanyCalculationResults",
        "staffId",
        staffId
      );
      // verify required parameter 'month' is not null or undefined
      assertParamExists(
        "getCommissionInsuranceCompanyCalculationResults",
        "month",
        month
      );
      const localVarPath =
        `/commission/calculation/{staffId}/{month}/insuranceCompany`
          .replace(`{${"staffId"}}`, encodeURIComponent(String(staffId)))
          .replace(`{${"month"}}`, encodeURIComponent(String(month)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>登録されている手数料取込の最新の支給月を取得します。</dt>      <dt>権限制御なし</dt>  </dl>
     * @summary GetCommissionMonthCurrent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissionMonthCurrent: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/commission/month/current`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>最新の手数料取込の状況と取込件数を取得します。</dt>   <dd>commissionImportStatusがdeleted以外のレコードを取得します。</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>    <dt>ソート順</dt>   <dd>保険会社の表示順の昇順、手数料・インセンティブの順</dd>  </dl>
     * @summary GetCommissionStatus
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissionStatus: async (
      month: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'month' is not null or undefined
      assertParamExists("getCommissionStatus", "month", month);
      const localVarPath = `/commission/status/{month}`.replace(
        `{${"month"}}`,
        encodeURIComponent(String(month))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>手数料取込毎に未マッチングの手数料を取得します。</dt>   <dt>手数料に証券IDが未設定なレコードが未マッチングの手数料です。</dt>      <dt>ソート順</dt>   <dd>保険会社の保険カテゴリーによりソート順が異なります</dd>   <dd>lifeInsurance：契約日降順、staffId昇順、手数料CSVの担当者コード昇順、手数料CSVの担当者名昇順、手数料CSVの契約者名昇順、保険料の昇順</dd>   <dd>nonLifeInsurance：証番号昇順、手数料CSVの契約者名昇順、契約日降順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary GetCommissions
     * @param {number} commissionImportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissions: async (
      commissionImportId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'commissionImportId' is not null or undefined
      assertParamExists(
        "getCommissions",
        "commissionImportId",
        commissionImportId
      );
      const localVarPath = `/commission/import/{commissionImportId}`.replace(
        `{${"commissionImportId"}}`,
        encodeURIComponent(String(commissionImportId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>手数料取込毎に未マッチングの手数料をCSV取得します。</dt>   <dt>手数料に証券IDが未設定なレコードが未マッチングの手数料です。</dt>      <dt>ソート順</dt>   <dd>staffId昇順、手数料CSVの担当者コード昇順、手数料CSVの担当者名昇順、手数料CSVの契約者名昇順、証券番号の昇順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary GetCommissionsCsv
     * @param {number} commissionImportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissionsCsv: async (
      commissionImportId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'commissionImportId' is not null or undefined
      assertParamExists(
        "getCommissionsCsv",
        "commissionImportId",
        commissionImportId
      );
      const localVarPath =
        `/commission/import/{commissionImportId}/csv`.replace(
          `{${"commissionImportId"}}`,
          encodeURIComponent(String(commissionImportId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>概要</dt>   <dd>手数料取込ID毎に手数料計算を実施します。</dd>   <dd>手数料取込IDに紐づく手数料取込ステータスが`commissionCalculated`の場合、手数料計算結果を削除して再計算します。</dd>   <dd>手数料計算の詳細は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97.md#%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97%E5%87%A6%E7%90%86\">手数料計算処理</a>を参照。</dd>    <dt>Validation</dt>   <dd>手数料取込IDに紐づく手数料で未マッチング（証券ID未設定）がある場合エラー</dd>      <dt>更新内容</dt>   <dd>手数料計算結果テーブルを作成します</dd>   <dd>手数料取込テーブルの手数料取込ステータスを`commissionCalculated`に更新します</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary PutCommissionCalculation
     * @param {PutCommissionCalculationRequest} putCommissionCalculationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putCommissionCalculation: async (
      putCommissionCalculationRequest: PutCommissionCalculationRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'putCommissionCalculationRequest' is not null or undefined
      assertParamExists(
        "putCommissionCalculation",
        "putCommissionCalculationRequest",
        putCommissionCalculationRequest
      );
      const localVarPath = `/commission/calculation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        putCommissionCalculationRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CommissionQueryApi - functional programming interface
 * @export
 */
export const CommissionQueryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CommissionQueryApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>staffの手数料計算結果を取得します。</dt>    <dt>取得内容</dt>   <dd>手数料計算結果のmyself、boss、otherStaff1、otherStaff2、otherStaff3のいずれかが、リクエストのstaffIdにあたる計算結果を取得します。</dd>    <dt>取得結果のソート順</dt>   <dd>insuranceCategory、insuranceCompanyId、commissionCategoryの昇順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の業績参照権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetCommissionCalculationResults
     * @param {number} staffId
     * @param {string} month
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommissionCalculationResults(
      staffId: number,
      month: string,
      page: number,
      numberPerPage: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetCommissionCalculationResults200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCommissionCalculationResults(
          staffId,
          month,
          page,
          numberPerPage,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>staffの手数料計算結果のCSVを取得します。</dt>    <dt>取得内容</dt>   <dd>手数料計算結果のmyself、boss、otherStaff1、otherStaff2、otherStaff3のいずれかが、リクエストのstaffIdにあたる計算結果を取得します。</dd>    <dt>取得結果のソート順</dt>   <dd>insuranceCategory、insuranceCompanyId、commissionCategoryの昇順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の業績参照権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetCommissionCalculationResultsCsv
     * @param {number} staffId
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommissionCalculationResultsCsv(
      staffId: number,
      month: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCommissionCalculationResultsCsv(
          staffId,
          month,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>staffの保険会社毎の手数料計算結果を取得します。</dt>    <dt>取得内容</dt>   <dd>pathのstaffId、支給月で手数料計算結果を保険会社毎に、初年度手数料・初年度以外手数料・インセンティブの手数料合計値を取得します。</dd>   <dd>初年度の判定は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97.md#%E5%88%9D%E5%B9%B4%E5%BA%A6%E3%83%95%E3%83%A9%E3%82%B0%E3%81%AE%E5%88%A4%E5%AE%9A\">こちらを参照</a></dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の業績参照権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetCommissionInsuranceCompanyCalculationResults
     * @param {number} staffId
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommissionInsuranceCompanyCalculationResults(
      staffId: number,
      month: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetCommissionInsuranceCompanyCalculationResults200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCommissionInsuranceCompanyCalculationResults(
          staffId,
          month,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>登録されている手数料取込の最新の支給月を取得します。</dt>      <dt>権限制御なし</dt>  </dl>
     * @summary GetCommissionMonthCurrent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommissionMonthCurrent(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCommissionMonthCurrent(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>最新の手数料取込の状況と取込件数を取得します。</dt>   <dd>commissionImportStatusがdeleted以外のレコードを取得します。</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>    <dt>ソート順</dt>   <dd>保険会社の表示順の昇順、手数料・インセンティブの順</dd>  </dl>
     * @summary GetCommissionStatus
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommissionStatus(
      month: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetCommissionStatus200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCommissionStatus(month, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>手数料取込毎に未マッチングの手数料を取得します。</dt>   <dt>手数料に証券IDが未設定なレコードが未マッチングの手数料です。</dt>      <dt>ソート順</dt>   <dd>保険会社の保険カテゴリーによりソート順が異なります</dd>   <dd>lifeInsurance：契約日降順、staffId昇順、手数料CSVの担当者コード昇順、手数料CSVの担当者名昇順、手数料CSVの契約者名昇順、保険料の昇順</dd>   <dd>nonLifeInsurance：証番号昇順、手数料CSVの契約者名昇順、契約日降順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary GetCommissions
     * @param {number} commissionImportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommissions(
      commissionImportId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetCommissions200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCommissions(
        commissionImportId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>手数料取込毎に未マッチングの手数料をCSV取得します。</dt>   <dt>手数料に証券IDが未設定なレコードが未マッチングの手数料です。</dt>      <dt>ソート順</dt>   <dd>staffId昇順、手数料CSVの担当者コード昇順、手数料CSVの担当者名昇順、手数料CSVの契約者名昇順、証券番号の昇順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary GetCommissionsCsv
     * @param {number} commissionImportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommissionsCsv(
      commissionImportId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCommissionsCsv(
          commissionImportId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>概要</dt>   <dd>手数料取込ID毎に手数料計算を実施します。</dd>   <dd>手数料取込IDに紐づく手数料取込ステータスが`commissionCalculated`の場合、手数料計算結果を削除して再計算します。</dd>   <dd>手数料計算の詳細は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97.md#%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97%E5%87%A6%E7%90%86\">手数料計算処理</a>を参照。</dd>    <dt>Validation</dt>   <dd>手数料取込IDに紐づく手数料で未マッチング（証券ID未設定）がある場合エラー</dd>      <dt>更新内容</dt>   <dd>手数料計算結果テーブルを作成します</dd>   <dd>手数料取込テーブルの手数料取込ステータスを`commissionCalculated`に更新します</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary PutCommissionCalculation
     * @param {PutCommissionCalculationRequest} putCommissionCalculationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putCommissionCalculation(
      putCommissionCalculationRequest: PutCommissionCalculationRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putCommissionCalculation(
          putCommissionCalculationRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * CommissionQueryApi - factory interface
 * @export
 */
export const CommissionQueryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CommissionQueryApiFp(configuration);
  return {
    /**
     * <dl>    <dt>staffの手数料計算結果を取得します。</dt>    <dt>取得内容</dt>   <dd>手数料計算結果のmyself、boss、otherStaff1、otherStaff2、otherStaff3のいずれかが、リクエストのstaffIdにあたる計算結果を取得します。</dd>    <dt>取得結果のソート順</dt>   <dd>insuranceCategory、insuranceCompanyId、commissionCategoryの昇順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の業績参照権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetCommissionCalculationResults
     * @param {number} staffId
     * @param {string} month
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissionCalculationResults(
      staffId: number,
      month: string,
      page: number,
      numberPerPage: number,
      options?: any
    ): AxiosPromise<GetCommissionCalculationResults200Response> {
      return localVarFp
        .getCommissionCalculationResults(
          staffId,
          month,
          page,
          numberPerPage,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>staffの手数料計算結果のCSVを取得します。</dt>    <dt>取得内容</dt>   <dd>手数料計算結果のmyself、boss、otherStaff1、otherStaff2、otherStaff3のいずれかが、リクエストのstaffIdにあたる計算結果を取得します。</dd>    <dt>取得結果のソート順</dt>   <dd>insuranceCategory、insuranceCompanyId、commissionCategoryの昇順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の業績参照権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetCommissionCalculationResultsCsv
     * @param {number} staffId
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissionCalculationResultsCsv(
      staffId: number,
      month: string,
      options?: any
    ): AxiosPromise<File> {
      return localVarFp
        .getCommissionCalculationResultsCsv(staffId, month, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>staffの保険会社毎の手数料計算結果を取得します。</dt>    <dt>取得内容</dt>   <dd>pathのstaffId、支給月で手数料計算結果を保険会社毎に、初年度手数料・初年度以外手数料・インセンティブの手数料合計値を取得します。</dd>   <dd>初年度の判定は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97.md#%E5%88%9D%E5%B9%B4%E5%BA%A6%E3%83%95%E3%83%A9%E3%82%B0%E3%81%AE%E5%88%A4%E5%AE%9A\">こちらを参照</a></dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の業績参照権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetCommissionInsuranceCompanyCalculationResults
     * @param {number} staffId
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissionInsuranceCompanyCalculationResults(
      staffId: number,
      month: string,
      options?: any
    ): AxiosPromise<GetCommissionInsuranceCompanyCalculationResults200Response> {
      return localVarFp
        .getCommissionInsuranceCompanyCalculationResults(
          staffId,
          month,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>登録されている手数料取込の最新の支給月を取得します。</dt>      <dt>権限制御なし</dt>  </dl>
     * @summary GetCommissionMonthCurrent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissionMonthCurrent(options?: any): AxiosPromise<string> {
      return localVarFp
        .getCommissionMonthCurrent(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>最新の手数料取込の状況と取込件数を取得します。</dt>   <dd>commissionImportStatusがdeleted以外のレコードを取得します。</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>    <dt>ソート順</dt>   <dd>保険会社の表示順の昇順、手数料・インセンティブの順</dd>  </dl>
     * @summary GetCommissionStatus
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissionStatus(
      month: string,
      options?: any
    ): AxiosPromise<GetCommissionStatus200Response> {
      return localVarFp
        .getCommissionStatus(month, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>手数料取込毎に未マッチングの手数料を取得します。</dt>   <dt>手数料に証券IDが未設定なレコードが未マッチングの手数料です。</dt>      <dt>ソート順</dt>   <dd>保険会社の保険カテゴリーによりソート順が異なります</dd>   <dd>lifeInsurance：契約日降順、staffId昇順、手数料CSVの担当者コード昇順、手数料CSVの担当者名昇順、手数料CSVの契約者名昇順、保険料の昇順</dd>   <dd>nonLifeInsurance：証番号昇順、手数料CSVの契約者名昇順、契約日降順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary GetCommissions
     * @param {number} commissionImportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissions(
      commissionImportId: number,
      options?: any
    ): AxiosPromise<GetCommissions200Response> {
      return localVarFp
        .getCommissions(commissionImportId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>手数料取込毎に未マッチングの手数料をCSV取得します。</dt>   <dt>手数料に証券IDが未設定なレコードが未マッチングの手数料です。</dt>      <dt>ソート順</dt>   <dd>staffId昇順、手数料CSVの担当者コード昇順、手数料CSVの担当者名昇順、手数料CSVの契約者名昇順、証券番号の昇順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary GetCommissionsCsv
     * @param {number} commissionImportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissionsCsv(
      commissionImportId: number,
      options?: any
    ): AxiosPromise<File> {
      return localVarFp
        .getCommissionsCsv(commissionImportId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>概要</dt>   <dd>手数料取込ID毎に手数料計算を実施します。</dd>   <dd>手数料取込IDに紐づく手数料取込ステータスが`commissionCalculated`の場合、手数料計算結果を削除して再計算します。</dd>   <dd>手数料計算の詳細は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97.md#%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97%E5%87%A6%E7%90%86\">手数料計算処理</a>を参照。</dd>    <dt>Validation</dt>   <dd>手数料取込IDに紐づく手数料で未マッチング（証券ID未設定）がある場合エラー</dd>      <dt>更新内容</dt>   <dd>手数料計算結果テーブルを作成します</dd>   <dd>手数料取込テーブルの手数料取込ステータスを`commissionCalculated`に更新します</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary PutCommissionCalculation
     * @param {PutCommissionCalculationRequest} putCommissionCalculationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putCommissionCalculation(
      putCommissionCalculationRequest: PutCommissionCalculationRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putCommissionCalculation(putCommissionCalculationRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CommissionQueryApi - object-oriented interface
 * @export
 * @class CommissionQueryApi
 * @extends {BaseAPI}
 */
export class CommissionQueryApi extends BaseAPI {
  /**
   * <dl>    <dt>staffの手数料計算結果を取得します。</dt>    <dt>取得内容</dt>   <dd>手数料計算結果のmyself、boss、otherStaff1、otherStaff2、otherStaff3のいずれかが、リクエストのstaffIdにあたる計算結果を取得します。</dd>    <dt>取得結果のソート順</dt>   <dd>insuranceCategory、insuranceCompanyId、commissionCategoryの昇順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の業績参照権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
   * @summary GetCommissionCalculationResults
   * @param {number} staffId
   * @param {string} month
   * @param {number} page 取得したいpage
   * @param {number} numberPerPage 1ページあたりの検索結果数
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommissionQueryApi
   */
  public getCommissionCalculationResults(
    staffId: number,
    month: string,
    page: number,
    numberPerPage: number,
    options?: AxiosRequestConfig
  ) {
    return CommissionQueryApiFp(this.configuration)
      .getCommissionCalculationResults(
        staffId,
        month,
        page,
        numberPerPage,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>staffの手数料計算結果のCSVを取得します。</dt>    <dt>取得内容</dt>   <dd>手数料計算結果のmyself、boss、otherStaff1、otherStaff2、otherStaff3のいずれかが、リクエストのstaffIdにあたる計算結果を取得します。</dd>    <dt>取得結果のソート順</dt>   <dd>insuranceCategory、insuranceCompanyId、commissionCategoryの昇順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の業績参照権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
   * @summary GetCommissionCalculationResultsCsv
   * @param {number} staffId
   * @param {string} month
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommissionQueryApi
   */
  public getCommissionCalculationResultsCsv(
    staffId: number,
    month: string,
    options?: AxiosRequestConfig
  ) {
    return CommissionQueryApiFp(this.configuration)
      .getCommissionCalculationResultsCsv(staffId, month, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>staffの保険会社毎の手数料計算結果を取得します。</dt>    <dt>取得内容</dt>   <dd>pathのstaffId、支給月で手数料計算結果を保険会社毎に、初年度手数料・初年度以外手数料・インセンティブの手数料合計値を取得します。</dd>   <dd>初年度の判定は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97.md#%E5%88%9D%E5%B9%B4%E5%BA%A6%E3%83%95%E3%83%A9%E3%82%B0%E3%81%AE%E5%88%A4%E5%AE%9A\">こちらを参照</a></dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の業績参照権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
   * @summary GetCommissionInsuranceCompanyCalculationResults
   * @param {number} staffId
   * @param {string} month
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommissionQueryApi
   */
  public getCommissionInsuranceCompanyCalculationResults(
    staffId: number,
    month: string,
    options?: AxiosRequestConfig
  ) {
    return CommissionQueryApiFp(this.configuration)
      .getCommissionInsuranceCompanyCalculationResults(staffId, month, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>登録されている手数料取込の最新の支給月を取得します。</dt>      <dt>権限制御なし</dt>  </dl>
   * @summary GetCommissionMonthCurrent
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommissionQueryApi
   */
  public getCommissionMonthCurrent(options?: AxiosRequestConfig) {
    return CommissionQueryApiFp(this.configuration)
      .getCommissionMonthCurrent(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>最新の手数料取込の状況と取込件数を取得します。</dt>   <dd>commissionImportStatusがdeleted以外のレコードを取得します。</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>    <dt>ソート順</dt>   <dd>保険会社の表示順の昇順、手数料・インセンティブの順</dd>  </dl>
   * @summary GetCommissionStatus
   * @param {string} month
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommissionQueryApi
   */
  public getCommissionStatus(month: string, options?: AxiosRequestConfig) {
    return CommissionQueryApiFp(this.configuration)
      .getCommissionStatus(month, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>手数料取込毎に未マッチングの手数料を取得します。</dt>   <dt>手数料に証券IDが未設定なレコードが未マッチングの手数料です。</dt>      <dt>ソート順</dt>   <dd>保険会社の保険カテゴリーによりソート順が異なります</dd>   <dd>lifeInsurance：契約日降順、staffId昇順、手数料CSVの担当者コード昇順、手数料CSVの担当者名昇順、手数料CSVの契約者名昇順、保険料の昇順</dd>   <dd>nonLifeInsurance：証番号昇順、手数料CSVの契約者名昇順、契約日降順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
   * @summary GetCommissions
   * @param {number} commissionImportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommissionQueryApi
   */
  public getCommissions(
    commissionImportId: number,
    options?: AxiosRequestConfig
  ) {
    return CommissionQueryApiFp(this.configuration)
      .getCommissions(commissionImportId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>手数料取込毎に未マッチングの手数料をCSV取得します。</dt>   <dt>手数料に証券IDが未設定なレコードが未マッチングの手数料です。</dt>      <dt>ソート順</dt>   <dd>staffId昇順、手数料CSVの担当者コード昇順、手数料CSVの担当者名昇順、手数料CSVの契約者名昇順、証券番号の昇順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
   * @summary GetCommissionsCsv
   * @param {number} commissionImportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommissionQueryApi
   */
  public getCommissionsCsv(
    commissionImportId: number,
    options?: AxiosRequestConfig
  ) {
    return CommissionQueryApiFp(this.configuration)
      .getCommissionsCsv(commissionImportId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>概要</dt>   <dd>手数料取込ID毎に手数料計算を実施します。</dd>   <dd>手数料取込IDに紐づく手数料取込ステータスが`commissionCalculated`の場合、手数料計算結果を削除して再計算します。</dd>   <dd>手数料計算の詳細は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97.md#%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97%E5%87%A6%E7%90%86\">手数料計算処理</a>を参照。</dd>    <dt>Validation</dt>   <dd>手数料取込IDに紐づく手数料で未マッチング（証券ID未設定）がある場合エラー</dd>      <dt>更新内容</dt>   <dd>手数料計算結果テーブルを作成します</dd>   <dd>手数料取込テーブルの手数料取込ステータスを`commissionCalculated`に更新します</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
   * @summary PutCommissionCalculation
   * @param {PutCommissionCalculationRequest} putCommissionCalculationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommissionQueryApi
   */
  public putCommissionCalculation(
    putCommissionCalculationRequest: PutCommissionCalculationRequest,
    options?: AxiosRequestConfig
  ) {
    return CommissionQueryApiFp(this.configuration)
      .putCommissionCalculation(putCommissionCalculationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ContractReportApi - axios parameter creator
 * @export
 */
export const ContractReportApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>申達報告を削除します。</dt>      <dt>Validation</dt>   <dd>該当の申達報告IDに紐づく証券テーブルが存在する場合、400BadRequest</dd>      <dd>次のいずれかに該当する場合に削除可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>削除不可な場合は401を返却します</dd>  </dl>
     * @summary DeleteContractReport
     * @param {number} contractReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteContractReport: async (
      contractReportId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'contractReportId' is not null or undefined
      assertParamExists(
        "deleteContractReport",
        "contractReportId",
        contractReportId
      );
      const localVarPath = `/contractReport/{contractReportId}`.replace(
        `{${"contractReportId"}}`,
        encodeURIComponent(String(contractReportId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>申達報告を取得します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>権限により取得できない場合、取得結果がない場合は404を返却します</dd>  </dl>
     * @summary GetContractReport
     * @param {number} contractReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContractReport: async (
      contractReportId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'contractReportId' is not null or undefined
      assertParamExists(
        "getContractReport",
        "contractReportId",
        contractReportId
      );
      const localVarPath = `/contractReport/{contractReportId}`.replace(
        `{${"contractReportId"}}`,
        encodeURIComponent(String(contractReportId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>申達報告を新規登録します。</dt>    <dt>Validation</dt>   <dd>     <ul>       <li>保険会社ID、証券番号の組み合わせが既に申達報告テーブルに存在する場合は400BadRequest</li>     </ul>   </dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostContractReport
     * @param {PostContractReportRequest} postContractReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postContractReport: async (
      postContractReportRequest: PostContractReportRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postContractReportRequest' is not null or undefined
      assertParamExists(
        "postContractReport",
        "postContractReportRequest",
        postContractReportRequest
      );
      const localVarPath = `/contractReport`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postContractReportRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>申達報告を更新します。</dt>    <dt>Validation</dt>   <dd>     <ul>       <li>保険会社ID、証券番号の組み合わせが既に申達報告テーブルに存在する場合は400BadRequest</li>     </ul>   </dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>保険料はユーザ種別が管理者の場合のみ更新可能です</dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutContractReport
     * @param {number} contractReportId
     * @param {PutContractReportRequest} putContractReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putContractReport: async (
      contractReportId: number,
      putContractReportRequest: PutContractReportRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'contractReportId' is not null or undefined
      assertParamExists(
        "putContractReport",
        "contractReportId",
        contractReportId
      );
      // verify required parameter 'putContractReportRequest' is not null or undefined
      assertParamExists(
        "putContractReport",
        "putContractReportRequest",
        putContractReportRequest
      );
      const localVarPath = `/contractReport/{contractReportId}`.replace(
        `{${"contractReportId"}}`,
        encodeURIComponent(String(contractReportId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        putContractReportRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ContractReportApi - functional programming interface
 * @export
 */
export const ContractReportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ContractReportApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>申達報告を削除します。</dt>      <dt>Validation</dt>   <dd>該当の申達報告IDに紐づく証券テーブルが存在する場合、400BadRequest</dd>      <dd>次のいずれかに該当する場合に削除可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>削除不可な場合は401を返却します</dd>  </dl>
     * @summary DeleteContractReport
     * @param {number} contractReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteContractReport(
      contractReportId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteContractReport(
          contractReportId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>申達報告を取得します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>権限により取得できない場合、取得結果がない場合は404を返却します</dd>  </dl>
     * @summary GetContractReport
     * @param {number} contractReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContractReport(
      contractReportId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetContractReport200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getContractReport(
          contractReportId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>申達報告を新規登録します。</dt>    <dt>Validation</dt>   <dd>     <ul>       <li>保険会社ID、証券番号の組み合わせが既に申達報告テーブルに存在する場合は400BadRequest</li>     </ul>   </dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostContractReport
     * @param {PostContractReportRequest} postContractReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postContractReport(
      postContractReportRequest: PostContractReportRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postContractReport(
          postContractReportRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>申達報告を更新します。</dt>    <dt>Validation</dt>   <dd>     <ul>       <li>保険会社ID、証券番号の組み合わせが既に申達報告テーブルに存在する場合は400BadRequest</li>     </ul>   </dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>保険料はユーザ種別が管理者の場合のみ更新可能です</dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutContractReport
     * @param {number} contractReportId
     * @param {PutContractReportRequest} putContractReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putContractReport(
      contractReportId: number,
      putContractReportRequest: PutContractReportRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putContractReport(
          contractReportId,
          putContractReportRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * ContractReportApi - factory interface
 * @export
 */
export const ContractReportApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ContractReportApiFp(configuration);
  return {
    /**
     * <dl>    <dt>申達報告を削除します。</dt>      <dt>Validation</dt>   <dd>該当の申達報告IDに紐づく証券テーブルが存在する場合、400BadRequest</dd>      <dd>次のいずれかに該当する場合に削除可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>削除不可な場合は401を返却します</dd>  </dl>
     * @summary DeleteContractReport
     * @param {number} contractReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteContractReport(
      contractReportId: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteContractReport(contractReportId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>申達報告を取得します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>権限により取得できない場合、取得結果がない場合は404を返却します</dd>  </dl>
     * @summary GetContractReport
     * @param {number} contractReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContractReport(
      contractReportId: number,
      options?: any
    ): AxiosPromise<GetContractReport200Response> {
      return localVarFp
        .getContractReport(contractReportId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>申達報告を新規登録します。</dt>    <dt>Validation</dt>   <dd>     <ul>       <li>保険会社ID、証券番号の組み合わせが既に申達報告テーブルに存在する場合は400BadRequest</li>     </ul>   </dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostContractReport
     * @param {PostContractReportRequest} postContractReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postContractReport(
      postContractReportRequest: PostContractReportRequest,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .postContractReport(postContractReportRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>申達報告を更新します。</dt>    <dt>Validation</dt>   <dd>     <ul>       <li>保険会社ID、証券番号の組み合わせが既に申達報告テーブルに存在する場合は400BadRequest</li>     </ul>   </dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>保険料はユーザ種別が管理者の場合のみ更新可能です</dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutContractReport
     * @param {number} contractReportId
     * @param {PutContractReportRequest} putContractReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putContractReport(
      contractReportId: number,
      putContractReportRequest: PutContractReportRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putContractReport(contractReportId, putContractReportRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ContractReportApi - object-oriented interface
 * @export
 * @class ContractReportApi
 * @extends {BaseAPI}
 */
export class ContractReportApi extends BaseAPI {
  /**
   * <dl>    <dt>申達報告を削除します。</dt>      <dt>Validation</dt>   <dd>該当の申達報告IDに紐づく証券テーブルが存在する場合、400BadRequest</dd>      <dd>次のいずれかに該当する場合に削除可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>削除不可な場合は401を返却します</dd>  </dl>
   * @summary DeleteContractReport
   * @param {number} contractReportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractReportApi
   */
  public deleteContractReport(
    contractReportId: number,
    options?: AxiosRequestConfig
  ) {
    return ContractReportApiFp(this.configuration)
      .deleteContractReport(contractReportId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>申達報告を取得します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>権限により取得できない場合、取得結果がない場合は404を返却します</dd>  </dl>
   * @summary GetContractReport
   * @param {number} contractReportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractReportApi
   */
  public getContractReport(
    contractReportId: number,
    options?: AxiosRequestConfig
  ) {
    return ContractReportApiFp(this.configuration)
      .getContractReport(contractReportId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>申達報告を新規登録します。</dt>    <dt>Validation</dt>   <dd>     <ul>       <li>保険会社ID、証券番号の組み合わせが既に申達報告テーブルに存在する場合は400BadRequest</li>     </ul>   </dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PostContractReport
   * @param {PostContractReportRequest} postContractReportRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractReportApi
   */
  public postContractReport(
    postContractReportRequest: PostContractReportRequest,
    options?: AxiosRequestConfig
  ) {
    return ContractReportApiFp(this.configuration)
      .postContractReport(postContractReportRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>申達報告を更新します。</dt>    <dt>Validation</dt>   <dd>     <ul>       <li>保険会社ID、証券番号の組み合わせが既に申達報告テーブルに存在する場合は400BadRequest</li>     </ul>   </dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>保険料はユーザ種別が管理者の場合のみ更新可能です</dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PutContractReport
   * @param {number} contractReportId
   * @param {PutContractReportRequest} putContractReportRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractReportApi
   */
  public putContractReport(
    contractReportId: number,
    putContractReportRequest: PutContractReportRequest,
    options?: AxiosRequestConfig
  ) {
    return ContractReportApiFp(this.configuration)
      .putContractReport(contractReportId, putContractReportRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ContractReportQueryApi - axios parameter creator
 * @export
 */
export const ContractReportQueryApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>queryに基づき生保の申達報告をCSV取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>申達報告日の降順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の案件・申達・証券権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetContractReportLifeInsuranceCsv
     * @param {ContractorAttribute} [contractorAttribute] 契約者の属性
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [contractReportId] 申達報告ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の申達報告を検索。
     * @param {number} [insuranceCompanyId] 保険会社ID
     * @param {number} [marketId] マーケットID
     * @param {string} [contractReportOnFrom] 申達報告日FROM
     * @param {string} [contractReportOnTo] 申達報告日TO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContractReportLifeInsuranceCsv: async (
      contractorAttribute?: ContractorAttribute,
      keyword?: string,
      contractReportId?: number,
      staffId?: number,
      departmentId?: number,
      insuranceCompanyId?: number,
      marketId?: number,
      contractReportOnFrom?: string,
      contractReportOnTo?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/contractReport/lifeInsurance/csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (contractorAttribute !== undefined) {
        localVarQueryParameter["contractorAttribute"] = contractorAttribute;
      }

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (contractReportId !== undefined) {
        localVarQueryParameter["contractReportId"] = contractReportId;
      }

      if (staffId !== undefined) {
        localVarQueryParameter["staffId"] = staffId;
      }

      if (departmentId !== undefined) {
        localVarQueryParameter["departmentId"] = departmentId;
      }

      if (insuranceCompanyId !== undefined) {
        localVarQueryParameter["insuranceCompanyId"] = insuranceCompanyId;
      }

      if (marketId !== undefined) {
        localVarQueryParameter["marketId"] = marketId;
      }

      if (contractReportOnFrom !== undefined) {
        localVarQueryParameter["contractReportOnFrom"] =
          (contractReportOnFrom as any) instanceof Date
            ? (contractReportOnFrom as any).toISOString().substr(0, 10)
            : contractReportOnFrom;
      }

      if (contractReportOnTo !== undefined) {
        localVarQueryParameter["contractReportOnTo"] =
          (contractReportOnTo as any) instanceof Date
            ? (contractReportOnTo as any).toISOString().substr(0, 10)
            : contractReportOnTo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>queryに基づき損保の申達報告をCSV取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>申達報告日の降順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の案件・申達・証券権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetContractReportNonLifeInsuranceCsv
     * @param {ContractorAttribute} [contractorAttribute] 契約者の属性
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [contractReportId] 申達報告ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の申達報告を検索。
     * @param {string} [policyNumber] 証券番号
     * @param {number} [insuranceCompanyId] 保険会社ID
     * @param {number} [marketId] マーケットID
     * @param {string} [contractReportOnFrom] 申達報告日FROM
     * @param {string} [contractReportOnTo] 申達報告日TO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContractReportNonLifeInsuranceCsv: async (
      contractorAttribute?: ContractorAttribute,
      keyword?: string,
      contractReportId?: number,
      staffId?: number,
      departmentId?: number,
      policyNumber?: string,
      insuranceCompanyId?: number,
      marketId?: number,
      contractReportOnFrom?: string,
      contractReportOnTo?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/contractReport/nonLifeInsurance/csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (contractorAttribute !== undefined) {
        localVarQueryParameter["contractorAttribute"] = contractorAttribute;
      }

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (contractReportId !== undefined) {
        localVarQueryParameter["contractReportId"] = contractReportId;
      }

      if (staffId !== undefined) {
        localVarQueryParameter["staffId"] = staffId;
      }

      if (departmentId !== undefined) {
        localVarQueryParameter["departmentId"] = departmentId;
      }

      if (policyNumber !== undefined) {
        localVarQueryParameter["policyNumber"] = policyNumber;
      }

      if (insuranceCompanyId !== undefined) {
        localVarQueryParameter["insuranceCompanyId"] = insuranceCompanyId;
      }

      if (marketId !== undefined) {
        localVarQueryParameter["marketId"] = marketId;
      }

      if (contractReportOnFrom !== undefined) {
        localVarQueryParameter["contractReportOnFrom"] =
          (contractReportOnFrom as any) instanceof Date
            ? (contractReportOnFrom as any).toISOString().substr(0, 10)
            : contractReportOnFrom;
      }

      if (contractReportOnTo !== undefined) {
        localVarQueryParameter["contractReportOnTo"] =
          (contractReportOnTo as any) instanceof Date
            ? (contractReportOnTo as any).toISOString().substr(0, 10)
            : contractReportOnTo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>queryに基づき申達報告を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>contractReportSortの指定に従う</dd>    <dt>権限制御</dt>   <dd>authorizedFilterFlagがfalseの場合、権限の制限なくデータ取得</dd>   <dd>authorizedFilterFlagがtrueの場合、次のデータが取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の案件・申達・証券権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetContractReports
     * @param {boolean} authorizedFilterFlag &lt;dl&gt;    &lt;dt&gt;詳細を参照する権限ありのRecordのみ取得するかを判定するフラグ。&lt;/dt&gt;      &lt;dt&gt;trueの場合は詳細参照する権限があるレコードのみを取得する&lt;/dt&gt;   &lt;dt&gt;詳細を参照する権限は次のとおり&lt;/dt&gt;   &lt;dd&gt;     &lt;ul&gt;       &lt;li&gt;ユーザ種別が管理者&lt;/li&gt;       &lt;li&gt;対象レコードの担当募集人が自分&lt;/li&gt;       &lt;li&gt;対象レコードの担当募集人の案件・申達・証券権限がある&lt;/li&gt;     &lt;/ul&gt;   &lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {InsuranceCategory} [insuranceCategory] 保険カテゴリー
     * @param {ContractorAttribute} [contractorAttribute] 契約者の属性
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [potentialCustomerId] 案件ID
     * @param {number} [contractReportId] 申達報告ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の申達報告を検索。
     * @param {string} [policyNumber] 証券番号
     * @param {number} [insuranceCompanyId] 保険会社ID
     * @param {number} [marketId] マーケットID
     * @param {string} [contractReportOnFrom] 申達報告日FROM
     * @param {string} [contractReportOnTo] 申達報告日TO
     * @param {boolean} [commissionMatchingFlag] 手数料マッチングモードフラグ（true：証券ID未設定のみ抽出）
     * @param {ContractReportSort} [contractReportSort] &lt;dl&gt;   &lt;dt&gt;未設定：申達報告日（降順）&lt;/dt&gt;   &lt;dt&gt;staff：担当者（社員番号昇順）、申達報告日（降順）&lt;/dt&gt;   &lt;dt&gt;insuranceCompany：保険会社（保険会社のソート昇順）、申達報告日（降順）&lt;/dt&gt;   &lt;dt&gt;market：マーケット（マーケットのソート昇順で最後に自己開拓）、申達報告日（降順）&lt;/dt&gt; &lt;/dl&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContractReports: async (
      authorizedFilterFlag: boolean,
      page: number,
      numberPerPage: number,
      insuranceCategory?: InsuranceCategory,
      contractorAttribute?: ContractorAttribute,
      keyword?: string,
      potentialCustomerId?: number,
      contractReportId?: number,
      staffId?: number,
      departmentId?: number,
      policyNumber?: string,
      insuranceCompanyId?: number,
      marketId?: number,
      contractReportOnFrom?: string,
      contractReportOnTo?: string,
      commissionMatchingFlag?: boolean,
      contractReportSort?: ContractReportSort,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorizedFilterFlag' is not null or undefined
      assertParamExists(
        "getContractReports",
        "authorizedFilterFlag",
        authorizedFilterFlag
      );
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getContractReports", "page", page);
      // verify required parameter 'numberPerPage' is not null or undefined
      assertParamExists("getContractReports", "numberPerPage", numberPerPage);
      const localVarPath = `/contractReport`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorizedFilterFlag !== undefined) {
        localVarQueryParameter["authorizedFilterFlag"] = authorizedFilterFlag;
      }

      if (insuranceCategory !== undefined) {
        localVarQueryParameter["insuranceCategory"] = insuranceCategory;
      }

      if (contractorAttribute !== undefined) {
        localVarQueryParameter["contractorAttribute"] = contractorAttribute;
      }

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (potentialCustomerId !== undefined) {
        localVarQueryParameter["potentialCustomerId"] = potentialCustomerId;
      }

      if (contractReportId !== undefined) {
        localVarQueryParameter["contractReportId"] = contractReportId;
      }

      if (staffId !== undefined) {
        localVarQueryParameter["staffId"] = staffId;
      }

      if (departmentId !== undefined) {
        localVarQueryParameter["departmentId"] = departmentId;
      }

      if (policyNumber !== undefined) {
        localVarQueryParameter["policyNumber"] = policyNumber;
      }

      if (insuranceCompanyId !== undefined) {
        localVarQueryParameter["insuranceCompanyId"] = insuranceCompanyId;
      }

      if (marketId !== undefined) {
        localVarQueryParameter["marketId"] = marketId;
      }

      if (contractReportOnFrom !== undefined) {
        localVarQueryParameter["contractReportOnFrom"] =
          (contractReportOnFrom as any) instanceof Date
            ? (contractReportOnFrom as any).toISOString().substr(0, 10)
            : contractReportOnFrom;
      }

      if (contractReportOnTo !== undefined) {
        localVarQueryParameter["contractReportOnTo"] =
          (contractReportOnTo as any) instanceof Date
            ? (contractReportOnTo as any).toISOString().substr(0, 10)
            : contractReportOnTo;
      }

      if (commissionMatchingFlag !== undefined) {
        localVarQueryParameter["commissionMatchingFlag"] =
          commissionMatchingFlag;
      }

      if (contractReportSort !== undefined) {
        localVarQueryParameter["contractReportSort"] = contractReportSort;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberPerPage !== undefined) {
        localVarQueryParameter["numberPerPage"] = numberPerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>手数料マッチング用に証券未作成の申達報告を取得します。</dt>   <dd>証券ID未設定の申達報告が証券未作成の申達報告です</dd>   <dd>申達報告毎に手数料マッチングの重みづけを行います。詳細は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97.md#%E6%89%8B%E6%95%B0%E6%96%99%E3%83%9E%E3%83%83%E3%83%81%E3%83%B3%E3%82%B0%E5%87%A6%E7%90%86\">別資料の手数料マッチング処理</a>参照</dd>   <dd>重みづけ値が1以上の申達報告を取得します</dd>      <dt>取得結果のソート順</dt>   <dd>手数料マッチングの重み付けが高い順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPIを実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary GetContractReportsCommissionMatching
     * @param {number} commissionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContractReportsCommissionMatching: async (
      commissionId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'commissionId' is not null or undefined
      assertParamExists(
        "getContractReportsCommissionMatching",
        "commissionId",
        commissionId
      );
      const localVarPath =
        `/contractReport/{commissionId}/commissionMatching`.replace(
          `{${"commissionId"}}`,
          encodeURIComponent(String(commissionId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ContractReportQueryApi - functional programming interface
 * @export
 */
export const ContractReportQueryApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    ContractReportQueryApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき生保の申達報告をCSV取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>申達報告日の降順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の案件・申達・証券権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetContractReportLifeInsuranceCsv
     * @param {ContractorAttribute} [contractorAttribute] 契約者の属性
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [contractReportId] 申達報告ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の申達報告を検索。
     * @param {number} [insuranceCompanyId] 保険会社ID
     * @param {number} [marketId] マーケットID
     * @param {string} [contractReportOnFrom] 申達報告日FROM
     * @param {string} [contractReportOnTo] 申達報告日TO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContractReportLifeInsuranceCsv(
      contractorAttribute?: ContractorAttribute,
      keyword?: string,
      contractReportId?: number,
      staffId?: number,
      departmentId?: number,
      insuranceCompanyId?: number,
      marketId?: number,
      contractReportOnFrom?: string,
      contractReportOnTo?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getContractReportLifeInsuranceCsv(
          contractorAttribute,
          keyword,
          contractReportId,
          staffId,
          departmentId,
          insuranceCompanyId,
          marketId,
          contractReportOnFrom,
          contractReportOnTo,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>queryに基づき損保の申達報告をCSV取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>申達報告日の降順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の案件・申達・証券権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetContractReportNonLifeInsuranceCsv
     * @param {ContractorAttribute} [contractorAttribute] 契約者の属性
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [contractReportId] 申達報告ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の申達報告を検索。
     * @param {string} [policyNumber] 証券番号
     * @param {number} [insuranceCompanyId] 保険会社ID
     * @param {number} [marketId] マーケットID
     * @param {string} [contractReportOnFrom] 申達報告日FROM
     * @param {string} [contractReportOnTo] 申達報告日TO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContractReportNonLifeInsuranceCsv(
      contractorAttribute?: ContractorAttribute,
      keyword?: string,
      contractReportId?: number,
      staffId?: number,
      departmentId?: number,
      policyNumber?: string,
      insuranceCompanyId?: number,
      marketId?: number,
      contractReportOnFrom?: string,
      contractReportOnTo?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getContractReportNonLifeInsuranceCsv(
          contractorAttribute,
          keyword,
          contractReportId,
          staffId,
          departmentId,
          policyNumber,
          insuranceCompanyId,
          marketId,
          contractReportOnFrom,
          contractReportOnTo,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>queryに基づき申達報告を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>contractReportSortの指定に従う</dd>    <dt>権限制御</dt>   <dd>authorizedFilterFlagがfalseの場合、権限の制限なくデータ取得</dd>   <dd>authorizedFilterFlagがtrueの場合、次のデータが取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の案件・申達・証券権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetContractReports
     * @param {boolean} authorizedFilterFlag &lt;dl&gt;    &lt;dt&gt;詳細を参照する権限ありのRecordのみ取得するかを判定するフラグ。&lt;/dt&gt;      &lt;dt&gt;trueの場合は詳細参照する権限があるレコードのみを取得する&lt;/dt&gt;   &lt;dt&gt;詳細を参照する権限は次のとおり&lt;/dt&gt;   &lt;dd&gt;     &lt;ul&gt;       &lt;li&gt;ユーザ種別が管理者&lt;/li&gt;       &lt;li&gt;対象レコードの担当募集人が自分&lt;/li&gt;       &lt;li&gt;対象レコードの担当募集人の案件・申達・証券権限がある&lt;/li&gt;     &lt;/ul&gt;   &lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {InsuranceCategory} [insuranceCategory] 保険カテゴリー
     * @param {ContractorAttribute} [contractorAttribute] 契約者の属性
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [potentialCustomerId] 案件ID
     * @param {number} [contractReportId] 申達報告ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の申達報告を検索。
     * @param {string} [policyNumber] 証券番号
     * @param {number} [insuranceCompanyId] 保険会社ID
     * @param {number} [marketId] マーケットID
     * @param {string} [contractReportOnFrom] 申達報告日FROM
     * @param {string} [contractReportOnTo] 申達報告日TO
     * @param {boolean} [commissionMatchingFlag] 手数料マッチングモードフラグ（true：証券ID未設定のみ抽出）
     * @param {ContractReportSort} [contractReportSort] &lt;dl&gt;   &lt;dt&gt;未設定：申達報告日（降順）&lt;/dt&gt;   &lt;dt&gt;staff：担当者（社員番号昇順）、申達報告日（降順）&lt;/dt&gt;   &lt;dt&gt;insuranceCompany：保険会社（保険会社のソート昇順）、申達報告日（降順）&lt;/dt&gt;   &lt;dt&gt;market：マーケット（マーケットのソート昇順で最後に自己開拓）、申達報告日（降順）&lt;/dt&gt; &lt;/dl&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContractReports(
      authorizedFilterFlag: boolean,
      page: number,
      numberPerPage: number,
      insuranceCategory?: InsuranceCategory,
      contractorAttribute?: ContractorAttribute,
      keyword?: string,
      potentialCustomerId?: number,
      contractReportId?: number,
      staffId?: number,
      departmentId?: number,
      policyNumber?: string,
      insuranceCompanyId?: number,
      marketId?: number,
      contractReportOnFrom?: string,
      contractReportOnTo?: string,
      commissionMatchingFlag?: boolean,
      contractReportSort?: ContractReportSort,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetContractReports200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getContractReports(
          authorizedFilterFlag,
          page,
          numberPerPage,
          insuranceCategory,
          contractorAttribute,
          keyword,
          potentialCustomerId,
          contractReportId,
          staffId,
          departmentId,
          policyNumber,
          insuranceCompanyId,
          marketId,
          contractReportOnFrom,
          contractReportOnTo,
          commissionMatchingFlag,
          contractReportSort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>手数料マッチング用に証券未作成の申達報告を取得します。</dt>   <dd>証券ID未設定の申達報告が証券未作成の申達報告です</dd>   <dd>申達報告毎に手数料マッチングの重みづけを行います。詳細は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97.md#%E6%89%8B%E6%95%B0%E6%96%99%E3%83%9E%E3%83%83%E3%83%81%E3%83%B3%E3%82%B0%E5%87%A6%E7%90%86\">別資料の手数料マッチング処理</a>参照</dd>   <dd>重みづけ値が1以上の申達報告を取得します</dd>      <dt>取得結果のソート順</dt>   <dd>手数料マッチングの重み付けが高い順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPIを実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary GetContractReportsCommissionMatching
     * @param {number} commissionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContractReportsCommissionMatching(
      commissionId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetContractReportsCommissionMatching200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getContractReportsCommissionMatching(
          commissionId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * ContractReportQueryApi - factory interface
 * @export
 */
export const ContractReportQueryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ContractReportQueryApiFp(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき生保の申達報告をCSV取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>申達報告日の降順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の案件・申達・証券権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetContractReportLifeInsuranceCsv
     * @param {ContractorAttribute} [contractorAttribute] 契約者の属性
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [contractReportId] 申達報告ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の申達報告を検索。
     * @param {number} [insuranceCompanyId] 保険会社ID
     * @param {number} [marketId] マーケットID
     * @param {string} [contractReportOnFrom] 申達報告日FROM
     * @param {string} [contractReportOnTo] 申達報告日TO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContractReportLifeInsuranceCsv(
      contractorAttribute?: ContractorAttribute,
      keyword?: string,
      contractReportId?: number,
      staffId?: number,
      departmentId?: number,
      insuranceCompanyId?: number,
      marketId?: number,
      contractReportOnFrom?: string,
      contractReportOnTo?: string,
      options?: any
    ): AxiosPromise<File> {
      return localVarFp
        .getContractReportLifeInsuranceCsv(
          contractorAttribute,
          keyword,
          contractReportId,
          staffId,
          departmentId,
          insuranceCompanyId,
          marketId,
          contractReportOnFrom,
          contractReportOnTo,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>queryに基づき損保の申達報告をCSV取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>申達報告日の降順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の案件・申達・証券権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetContractReportNonLifeInsuranceCsv
     * @param {ContractorAttribute} [contractorAttribute] 契約者の属性
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [contractReportId] 申達報告ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の申達報告を検索。
     * @param {string} [policyNumber] 証券番号
     * @param {number} [insuranceCompanyId] 保険会社ID
     * @param {number} [marketId] マーケットID
     * @param {string} [contractReportOnFrom] 申達報告日FROM
     * @param {string} [contractReportOnTo] 申達報告日TO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContractReportNonLifeInsuranceCsv(
      contractorAttribute?: ContractorAttribute,
      keyword?: string,
      contractReportId?: number,
      staffId?: number,
      departmentId?: number,
      policyNumber?: string,
      insuranceCompanyId?: number,
      marketId?: number,
      contractReportOnFrom?: string,
      contractReportOnTo?: string,
      options?: any
    ): AxiosPromise<File> {
      return localVarFp
        .getContractReportNonLifeInsuranceCsv(
          contractorAttribute,
          keyword,
          contractReportId,
          staffId,
          departmentId,
          policyNumber,
          insuranceCompanyId,
          marketId,
          contractReportOnFrom,
          contractReportOnTo,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>queryに基づき申達報告を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>contractReportSortの指定に従う</dd>    <dt>権限制御</dt>   <dd>authorizedFilterFlagがfalseの場合、権限の制限なくデータ取得</dd>   <dd>authorizedFilterFlagがtrueの場合、次のデータが取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の案件・申達・証券権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetContractReports
     * @param {boolean} authorizedFilterFlag &lt;dl&gt;    &lt;dt&gt;詳細を参照する権限ありのRecordのみ取得するかを判定するフラグ。&lt;/dt&gt;      &lt;dt&gt;trueの場合は詳細参照する権限があるレコードのみを取得する&lt;/dt&gt;   &lt;dt&gt;詳細を参照する権限は次のとおり&lt;/dt&gt;   &lt;dd&gt;     &lt;ul&gt;       &lt;li&gt;ユーザ種別が管理者&lt;/li&gt;       &lt;li&gt;対象レコードの担当募集人が自分&lt;/li&gt;       &lt;li&gt;対象レコードの担当募集人の案件・申達・証券権限がある&lt;/li&gt;     &lt;/ul&gt;   &lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {InsuranceCategory} [insuranceCategory] 保険カテゴリー
     * @param {ContractorAttribute} [contractorAttribute] 契約者の属性
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [potentialCustomerId] 案件ID
     * @param {number} [contractReportId] 申達報告ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の申達報告を検索。
     * @param {string} [policyNumber] 証券番号
     * @param {number} [insuranceCompanyId] 保険会社ID
     * @param {number} [marketId] マーケットID
     * @param {string} [contractReportOnFrom] 申達報告日FROM
     * @param {string} [contractReportOnTo] 申達報告日TO
     * @param {boolean} [commissionMatchingFlag] 手数料マッチングモードフラグ（true：証券ID未設定のみ抽出）
     * @param {ContractReportSort} [contractReportSort] &lt;dl&gt;   &lt;dt&gt;未設定：申達報告日（降順）&lt;/dt&gt;   &lt;dt&gt;staff：担当者（社員番号昇順）、申達報告日（降順）&lt;/dt&gt;   &lt;dt&gt;insuranceCompany：保険会社（保険会社のソート昇順）、申達報告日（降順）&lt;/dt&gt;   &lt;dt&gt;market：マーケット（マーケットのソート昇順で最後に自己開拓）、申達報告日（降順）&lt;/dt&gt; &lt;/dl&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContractReports(
      authorizedFilterFlag: boolean,
      page: number,
      numberPerPage: number,
      insuranceCategory?: InsuranceCategory,
      contractorAttribute?: ContractorAttribute,
      keyword?: string,
      potentialCustomerId?: number,
      contractReportId?: number,
      staffId?: number,
      departmentId?: number,
      policyNumber?: string,
      insuranceCompanyId?: number,
      marketId?: number,
      contractReportOnFrom?: string,
      contractReportOnTo?: string,
      commissionMatchingFlag?: boolean,
      contractReportSort?: ContractReportSort,
      options?: any
    ): AxiosPromise<GetContractReports200Response> {
      return localVarFp
        .getContractReports(
          authorizedFilterFlag,
          page,
          numberPerPage,
          insuranceCategory,
          contractorAttribute,
          keyword,
          potentialCustomerId,
          contractReportId,
          staffId,
          departmentId,
          policyNumber,
          insuranceCompanyId,
          marketId,
          contractReportOnFrom,
          contractReportOnTo,
          commissionMatchingFlag,
          contractReportSort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>手数料マッチング用に証券未作成の申達報告を取得します。</dt>   <dd>証券ID未設定の申達報告が証券未作成の申達報告です</dd>   <dd>申達報告毎に手数料マッチングの重みづけを行います。詳細は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97.md#%E6%89%8B%E6%95%B0%E6%96%99%E3%83%9E%E3%83%83%E3%83%81%E3%83%B3%E3%82%B0%E5%87%A6%E7%90%86\">別資料の手数料マッチング処理</a>参照</dd>   <dd>重みづけ値が1以上の申達報告を取得します</dd>      <dt>取得結果のソート順</dt>   <dd>手数料マッチングの重み付けが高い順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPIを実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary GetContractReportsCommissionMatching
     * @param {number} commissionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContractReportsCommissionMatching(
      commissionId: number,
      options?: any
    ): AxiosPromise<GetContractReportsCommissionMatching200Response> {
      return localVarFp
        .getContractReportsCommissionMatching(commissionId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ContractReportQueryApi - object-oriented interface
 * @export
 * @class ContractReportQueryApi
 * @extends {BaseAPI}
 */
export class ContractReportQueryApi extends BaseAPI {
  /**
   * <dl>    <dt>queryに基づき生保の申達報告をCSV取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>申達報告日の降順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の案件・申達・証券権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
   * @summary GetContractReportLifeInsuranceCsv
   * @param {ContractorAttribute} [contractorAttribute] 契約者の属性
   * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
   * @param {number} [contractReportId] 申達報告ID
   * @param {number} [staffId] 募集人ID
   * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の申達報告を検索。
   * @param {number} [insuranceCompanyId] 保険会社ID
   * @param {number} [marketId] マーケットID
   * @param {string} [contractReportOnFrom] 申達報告日FROM
   * @param {string} [contractReportOnTo] 申達報告日TO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractReportQueryApi
   */
  public getContractReportLifeInsuranceCsv(
    contractorAttribute?: ContractorAttribute,
    keyword?: string,
    contractReportId?: number,
    staffId?: number,
    departmentId?: number,
    insuranceCompanyId?: number,
    marketId?: number,
    contractReportOnFrom?: string,
    contractReportOnTo?: string,
    options?: AxiosRequestConfig
  ) {
    return ContractReportQueryApiFp(this.configuration)
      .getContractReportLifeInsuranceCsv(
        contractorAttribute,
        keyword,
        contractReportId,
        staffId,
        departmentId,
        insuranceCompanyId,
        marketId,
        contractReportOnFrom,
        contractReportOnTo,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>queryに基づき損保の申達報告をCSV取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>申達報告日の降順</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の案件・申達・証券権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
   * @summary GetContractReportNonLifeInsuranceCsv
   * @param {ContractorAttribute} [contractorAttribute] 契約者の属性
   * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
   * @param {number} [contractReportId] 申達報告ID
   * @param {number} [staffId] 募集人ID
   * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の申達報告を検索。
   * @param {string} [policyNumber] 証券番号
   * @param {number} [insuranceCompanyId] 保険会社ID
   * @param {number} [marketId] マーケットID
   * @param {string} [contractReportOnFrom] 申達報告日FROM
   * @param {string} [contractReportOnTo] 申達報告日TO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractReportQueryApi
   */
  public getContractReportNonLifeInsuranceCsv(
    contractorAttribute?: ContractorAttribute,
    keyword?: string,
    contractReportId?: number,
    staffId?: number,
    departmentId?: number,
    policyNumber?: string,
    insuranceCompanyId?: number,
    marketId?: number,
    contractReportOnFrom?: string,
    contractReportOnTo?: string,
    options?: AxiosRequestConfig
  ) {
    return ContractReportQueryApiFp(this.configuration)
      .getContractReportNonLifeInsuranceCsv(
        contractorAttribute,
        keyword,
        contractReportId,
        staffId,
        departmentId,
        policyNumber,
        insuranceCompanyId,
        marketId,
        contractReportOnFrom,
        contractReportOnTo,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>queryに基づき申達報告を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>contractReportSortの指定に従う</dd>    <dt>権限制御</dt>   <dd>authorizedFilterFlagがfalseの場合、権限の制限なくデータ取得</dd>   <dd>authorizedFilterFlagがtrueの場合、次のデータが取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の案件・申達・証券権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
   * @summary GetContractReports
   * @param {boolean} authorizedFilterFlag &lt;dl&gt;    &lt;dt&gt;詳細を参照する権限ありのRecordのみ取得するかを判定するフラグ。&lt;/dt&gt;      &lt;dt&gt;trueの場合は詳細参照する権限があるレコードのみを取得する&lt;/dt&gt;   &lt;dt&gt;詳細を参照する権限は次のとおり&lt;/dt&gt;   &lt;dd&gt;     &lt;ul&gt;       &lt;li&gt;ユーザ種別が管理者&lt;/li&gt;       &lt;li&gt;対象レコードの担当募集人が自分&lt;/li&gt;       &lt;li&gt;対象レコードの担当募集人の案件・申達・証券権限がある&lt;/li&gt;     &lt;/ul&gt;   &lt;/dd&gt;  &lt;/dl&gt;
   * @param {number} page 取得したいpage
   * @param {number} numberPerPage 1ページあたりの検索結果数
   * @param {InsuranceCategory} [insuranceCategory] 保険カテゴリー
   * @param {ContractorAttribute} [contractorAttribute] 契約者の属性
   * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
   * @param {number} [potentialCustomerId] 案件ID
   * @param {number} [contractReportId] 申達報告ID
   * @param {number} [staffId] 募集人ID
   * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の申達報告を検索。
   * @param {string} [policyNumber] 証券番号
   * @param {number} [insuranceCompanyId] 保険会社ID
   * @param {number} [marketId] マーケットID
   * @param {string} [contractReportOnFrom] 申達報告日FROM
   * @param {string} [contractReportOnTo] 申達報告日TO
   * @param {boolean} [commissionMatchingFlag] 手数料マッチングモードフラグ（true：証券ID未設定のみ抽出）
   * @param {ContractReportSort} [contractReportSort] &lt;dl&gt;   &lt;dt&gt;未設定：申達報告日（降順）&lt;/dt&gt;   &lt;dt&gt;staff：担当者（社員番号昇順）、申達報告日（降順）&lt;/dt&gt;   &lt;dt&gt;insuranceCompany：保険会社（保険会社のソート昇順）、申達報告日（降順）&lt;/dt&gt;   &lt;dt&gt;market：マーケット（マーケットのソート昇順で最後に自己開拓）、申達報告日（降順）&lt;/dt&gt; &lt;/dl&gt;
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractReportQueryApi
   */
  public getContractReports(
    authorizedFilterFlag: boolean,
    page: number,
    numberPerPage: number,
    insuranceCategory?: InsuranceCategory,
    contractorAttribute?: ContractorAttribute,
    keyword?: string,
    potentialCustomerId?: number,
    contractReportId?: number,
    staffId?: number,
    departmentId?: number,
    policyNumber?: string,
    insuranceCompanyId?: number,
    marketId?: number,
    contractReportOnFrom?: string,
    contractReportOnTo?: string,
    commissionMatchingFlag?: boolean,
    contractReportSort?: ContractReportSort,
    options?: AxiosRequestConfig
  ) {
    return ContractReportQueryApiFp(this.configuration)
      .getContractReports(
        authorizedFilterFlag,
        page,
        numberPerPage,
        insuranceCategory,
        contractorAttribute,
        keyword,
        potentialCustomerId,
        contractReportId,
        staffId,
        departmentId,
        policyNumber,
        insuranceCompanyId,
        marketId,
        contractReportOnFrom,
        contractReportOnTo,
        commissionMatchingFlag,
        contractReportSort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>手数料マッチング用に証券未作成の申達報告を取得します。</dt>   <dd>証券ID未設定の申達報告が証券未作成の申達報告です</dd>   <dd>申達報告毎に手数料マッチングの重みづけを行います。詳細は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%89%8B%E6%95%B0%E6%96%99%E8%A8%88%E7%AE%97.md#%E6%89%8B%E6%95%B0%E6%96%99%E3%83%9E%E3%83%83%E3%83%81%E3%83%B3%E3%82%B0%E5%87%A6%E7%90%86\">別資料の手数料マッチング処理</a>参照</dd>   <dd>重みづけ値が1以上の申達報告を取得します</dd>      <dt>取得結果のソート順</dt>   <dd>手数料マッチングの重み付けが高い順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPIを実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
   * @summary GetContractReportsCommissionMatching
   * @param {number} commissionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractReportQueryApi
   */
  public getContractReportsCommissionMatching(
    commissionId: number,
    options?: AxiosRequestConfig
  ) {
    return ContractReportQueryApiFp(this.configuration)
      .getContractReportsCommissionMatching(commissionId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DepartmentApi - axios parameter creator
 * @export
 */
export const DepartmentApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>所属を取得します。</dt>      <dt>権限制御</dt>   <dd>制限なく取得可能です。</dd>    <dt>取得結果がない場合404を返します。</dt>  </dl>
     * @summary GetDepartment
     * @param {number} departmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepartment: async (
      departmentId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'departmentId' is not null or undefined
      assertParamExists("getDepartment", "departmentId", departmentId);
      const localVarPath = `/department/{departmentId}`.replace(
        `{${"departmentId"}}`,
        encodeURIComponent(String(departmentId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>所属を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostDepartment
     * @param {PostDepartmentRequest} postDepartmentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDepartment: async (
      postDepartmentRequest: PostDepartmentRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postDepartmentRequest' is not null or undefined
      assertParamExists(
        "postDepartment",
        "postDepartmentRequest",
        postDepartmentRequest
      );
      const localVarPath = `/department`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postDepartmentRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>所属を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutDepartment
     * @param {number} departmentId
     * @param {PostDepartmentRequest} postDepartmentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putDepartment: async (
      departmentId: number,
      postDepartmentRequest: PostDepartmentRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'departmentId' is not null or undefined
      assertParamExists("putDepartment", "departmentId", departmentId);
      // verify required parameter 'postDepartmentRequest' is not null or undefined
      assertParamExists(
        "putDepartment",
        "postDepartmentRequest",
        postDepartmentRequest
      );
      const localVarPath = `/department/{departmentId}`.replace(
        `{${"departmentId"}}`,
        encodeURIComponent(String(departmentId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postDepartmentRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DepartmentApi - functional programming interface
 * @export
 */
export const DepartmentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DepartmentApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>所属を取得します。</dt>      <dt>権限制御</dt>   <dd>制限なく取得可能です。</dd>    <dt>取得結果がない場合404を返します。</dt>  </dl>
     * @summary GetDepartment
     * @param {number} departmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDepartment(
      departmentId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetDepartment200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartment(
        departmentId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>所属を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostDepartment
     * @param {PostDepartmentRequest} postDepartmentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postDepartment(
      postDepartmentRequest: PostDepartmentRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postDepartment(
        postDepartmentRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>所属を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutDepartment
     * @param {number} departmentId
     * @param {PostDepartmentRequest} postDepartmentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putDepartment(
      departmentId: number,
      postDepartmentRequest: PostDepartmentRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putDepartment(
        departmentId,
        postDepartmentRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * DepartmentApi - factory interface
 * @export
 */
export const DepartmentApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DepartmentApiFp(configuration);
  return {
    /**
     * <dl>    <dt>所属を取得します。</dt>      <dt>権限制御</dt>   <dd>制限なく取得可能です。</dd>    <dt>取得結果がない場合404を返します。</dt>  </dl>
     * @summary GetDepartment
     * @param {number} departmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepartment(
      departmentId: number,
      options?: any
    ): AxiosPromise<GetDepartment200Response> {
      return localVarFp
        .getDepartment(departmentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>所属を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostDepartment
     * @param {PostDepartmentRequest} postDepartmentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDepartment(
      postDepartmentRequest: PostDepartmentRequest,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .postDepartment(postDepartmentRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>所属を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutDepartment
     * @param {number} departmentId
     * @param {PostDepartmentRequest} postDepartmentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putDepartment(
      departmentId: number,
      postDepartmentRequest: PostDepartmentRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putDepartment(departmentId, postDepartmentRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DepartmentApi - object-oriented interface
 * @export
 * @class DepartmentApi
 * @extends {BaseAPI}
 */
export class DepartmentApi extends BaseAPI {
  /**
   * <dl>    <dt>所属を取得します。</dt>      <dt>権限制御</dt>   <dd>制限なく取得可能です。</dd>    <dt>取得結果がない場合404を返します。</dt>  </dl>
   * @summary GetDepartment
   * @param {number} departmentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DepartmentApi
   */
  public getDepartment(departmentId: number, options?: AxiosRequestConfig) {
    return DepartmentApiFp(this.configuration)
      .getDepartment(departmentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>所属を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PostDepartment
   * @param {PostDepartmentRequest} postDepartmentRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DepartmentApi
   */
  public postDepartment(
    postDepartmentRequest: PostDepartmentRequest,
    options?: AxiosRequestConfig
  ) {
    return DepartmentApiFp(this.configuration)
      .postDepartment(postDepartmentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>所属を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PutDepartment
   * @param {number} departmentId
   * @param {PostDepartmentRequest} postDepartmentRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DepartmentApi
   */
  public putDepartment(
    departmentId: number,
    postDepartmentRequest: PostDepartmentRequest,
    options?: AxiosRequestConfig
  ) {
    return DepartmentApiFp(this.configuration)
      .putDepartment(departmentId, postDepartmentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DepartmentQueryApi - axios parameter creator
 * @export
 */
export const DepartmentQueryApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>queryに基づき所属を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>表示順の昇順</dd>    <dt>権限制御</dt>   <dd>制限なく取得可能です。</dd>    </dl>
     * @summary GetDepartments
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [departmentName] 所属名の部分一致
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepartments: async (
      page: number,
      numberPerPage: number,
      departmentName?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getDepartments", "page", page);
      // verify required parameter 'numberPerPage' is not null or undefined
      assertParamExists("getDepartments", "numberPerPage", numberPerPage);
      const localVarPath = `/department`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (departmentName !== undefined) {
        localVarQueryParameter["departmentName"] = departmentName;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberPerPage !== undefined) {
        localVarQueryParameter["numberPerPage"] = numberPerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DepartmentQueryApi - functional programming interface
 * @export
 */
export const DepartmentQueryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DepartmentQueryApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき所属を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>表示順の昇順</dd>    <dt>権限制御</dt>   <dd>制限なく取得可能です。</dd>    </dl>
     * @summary GetDepartments
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [departmentName] 所属名の部分一致
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDepartments(
      page: number,
      numberPerPage: number,
      departmentName?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetDepartments200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartments(
        page,
        numberPerPage,
        departmentName,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * DepartmentQueryApi - factory interface
 * @export
 */
export const DepartmentQueryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DepartmentQueryApiFp(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき所属を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>表示順の昇順</dd>    <dt>権限制御</dt>   <dd>制限なく取得可能です。</dd>    </dl>
     * @summary GetDepartments
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [departmentName] 所属名の部分一致
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepartments(
      page: number,
      numberPerPage: number,
      departmentName?: string,
      options?: any
    ): AxiosPromise<GetDepartments200Response> {
      return localVarFp
        .getDepartments(page, numberPerPage, departmentName, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DepartmentQueryApi - object-oriented interface
 * @export
 * @class DepartmentQueryApi
 * @extends {BaseAPI}
 */
export class DepartmentQueryApi extends BaseAPI {
  /**
   * <dl>    <dt>queryに基づき所属を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>表示順の昇順</dd>    <dt>権限制御</dt>   <dd>制限なく取得可能です。</dd>    </dl>
   * @summary GetDepartments
   * @param {number} page 取得したいpage
   * @param {number} numberPerPage 1ページあたりの検索結果数
   * @param {string} [departmentName] 所属名の部分一致
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DepartmentQueryApi
   */
  public getDepartments(
    page: number,
    numberPerPage: number,
    departmentName?: string,
    options?: AxiosRequestConfig
  ) {
    return DepartmentQueryApiFp(this.configuration)
      .getDepartments(page, numberPerPage, departmentName, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HealthCheckApi - axios parameter creator
 * @export
 */
export const HealthCheckApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>システムのHealthCheckを行います。</dt>      <dt>権限制御なし</dt>    <dt>HealthCheckの成功可否はResponseのHttpのStatusで判定します</dt>   <dd>204：available</dd>   <dd>503：unavailable</dd>  </dl>
     * @summary GetHealthCheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHealthCheck: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/healthCheck`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HealthCheckApi - functional programming interface
 * @export
 */
export const HealthCheckApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    HealthCheckApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>システムのHealthCheckを行います。</dt>      <dt>権限制御なし</dt>    <dt>HealthCheckの成功可否はResponseのHttpのStatusで判定します</dt>   <dd>204：available</dd>   <dd>503：unavailable</dd>  </dl>
     * @summary GetHealthCheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHealthCheck(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHealthCheck(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * HealthCheckApi - factory interface
 * @export
 */
export const HealthCheckApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = HealthCheckApiFp(configuration);
  return {
    /**
     * <dl>    <dt>システムのHealthCheckを行います。</dt>      <dt>権限制御なし</dt>    <dt>HealthCheckの成功可否はResponseのHttpのStatusで判定します</dt>   <dd>204：available</dd>   <dd>503：unavailable</dd>  </dl>
     * @summary GetHealthCheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHealthCheck(options?: any): AxiosPromise<void> {
      return localVarFp
        .getHealthCheck(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * HealthCheckApi - object-oriented interface
 * @export
 * @class HealthCheckApi
 * @extends {BaseAPI}
 */
export class HealthCheckApi extends BaseAPI {
  /**
   * <dl>    <dt>システムのHealthCheckを行います。</dt>      <dt>権限制御なし</dt>    <dt>HealthCheckの成功可否はResponseのHttpのStatusで判定します</dt>   <dd>204：available</dd>   <dd>503：unavailable</dd>  </dl>
   * @summary GetHealthCheck
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthCheckApi
   */
  public getHealthCheck(options?: AxiosRequestConfig) {
    return HealthCheckApiFp(this.configuration)
      .getHealthCheck(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InsuranceCompanyApi - axios parameter creator
 * @export
 */
export const InsuranceCompanyApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>保険会社を取得します。</dt>      <dt>権限制御</dt>   <dd>なし</dd>    <dt>取得結果がない場合404を返します。</dt>  </dl>
     * @summary GetInsuranceCompany
     * @param {number} insuranceCompanyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInsuranceCompany: async (
      insuranceCompanyId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'insuranceCompanyId' is not null or undefined
      assertParamExists(
        "getInsuranceCompany",
        "insuranceCompanyId",
        insuranceCompanyId
      );
      const localVarPath = `/insuranceCompany/{insuranceCompanyId}`.replace(
        `{${"insuranceCompanyId"}}`,
        encodeURIComponent(String(insuranceCompanyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>保険会社を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostInsuranceCompany
     * @param {PostInsuranceCompanyRequest} postInsuranceCompanyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postInsuranceCompany: async (
      postInsuranceCompanyRequest: PostInsuranceCompanyRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postInsuranceCompanyRequest' is not null or undefined
      assertParamExists(
        "postInsuranceCompany",
        "postInsuranceCompanyRequest",
        postInsuranceCompanyRequest
      );
      const localVarPath = `/insuranceCompany`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postInsuranceCompanyRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>保険会社を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutInsuranceCompany
     * @param {number} insuranceCompanyId
     * @param {PostInsuranceCompanyRequest} postInsuranceCompanyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putInsuranceCompany: async (
      insuranceCompanyId: number,
      postInsuranceCompanyRequest: PostInsuranceCompanyRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'insuranceCompanyId' is not null or undefined
      assertParamExists(
        "putInsuranceCompany",
        "insuranceCompanyId",
        insuranceCompanyId
      );
      // verify required parameter 'postInsuranceCompanyRequest' is not null or undefined
      assertParamExists(
        "putInsuranceCompany",
        "postInsuranceCompanyRequest",
        postInsuranceCompanyRequest
      );
      const localVarPath = `/insuranceCompany/{insuranceCompanyId}`.replace(
        `{${"insuranceCompanyId"}}`,
        encodeURIComponent(String(insuranceCompanyId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postInsuranceCompanyRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InsuranceCompanyApi - functional programming interface
 * @export
 */
export const InsuranceCompanyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    InsuranceCompanyApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>保険会社を取得します。</dt>      <dt>権限制御</dt>   <dd>なし</dd>    <dt>取得結果がない場合404を返します。</dt>  </dl>
     * @summary GetInsuranceCompany
     * @param {number} insuranceCompanyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInsuranceCompany(
      insuranceCompanyId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InsuranceCompany>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getInsuranceCompany(
          insuranceCompanyId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>保険会社を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostInsuranceCompany
     * @param {PostInsuranceCompanyRequest} postInsuranceCompanyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postInsuranceCompany(
      postInsuranceCompanyRequest: PostInsuranceCompanyRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postInsuranceCompany(
          postInsuranceCompanyRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>保険会社を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutInsuranceCompany
     * @param {number} insuranceCompanyId
     * @param {PostInsuranceCompanyRequest} postInsuranceCompanyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putInsuranceCompany(
      insuranceCompanyId: number,
      postInsuranceCompanyRequest: PostInsuranceCompanyRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putInsuranceCompany(
          insuranceCompanyId,
          postInsuranceCompanyRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * InsuranceCompanyApi - factory interface
 * @export
 */
export const InsuranceCompanyApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = InsuranceCompanyApiFp(configuration);
  return {
    /**
     * <dl>    <dt>保険会社を取得します。</dt>      <dt>権限制御</dt>   <dd>なし</dd>    <dt>取得結果がない場合404を返します。</dt>  </dl>
     * @summary GetInsuranceCompany
     * @param {number} insuranceCompanyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInsuranceCompany(
      insuranceCompanyId: number,
      options?: any
    ): AxiosPromise<InsuranceCompany> {
      return localVarFp
        .getInsuranceCompany(insuranceCompanyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>保険会社を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostInsuranceCompany
     * @param {PostInsuranceCompanyRequest} postInsuranceCompanyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postInsuranceCompany(
      postInsuranceCompanyRequest: PostInsuranceCompanyRequest,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .postInsuranceCompany(postInsuranceCompanyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>保険会社を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutInsuranceCompany
     * @param {number} insuranceCompanyId
     * @param {PostInsuranceCompanyRequest} postInsuranceCompanyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putInsuranceCompany(
      insuranceCompanyId: number,
      postInsuranceCompanyRequest: PostInsuranceCompanyRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putInsuranceCompany(
          insuranceCompanyId,
          postInsuranceCompanyRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InsuranceCompanyApi - object-oriented interface
 * @export
 * @class InsuranceCompanyApi
 * @extends {BaseAPI}
 */
export class InsuranceCompanyApi extends BaseAPI {
  /**
   * <dl>    <dt>保険会社を取得します。</dt>      <dt>権限制御</dt>   <dd>なし</dd>    <dt>取得結果がない場合404を返します。</dt>  </dl>
   * @summary GetInsuranceCompany
   * @param {number} insuranceCompanyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InsuranceCompanyApi
   */
  public getInsuranceCompany(
    insuranceCompanyId: number,
    options?: AxiosRequestConfig
  ) {
    return InsuranceCompanyApiFp(this.configuration)
      .getInsuranceCompany(insuranceCompanyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>保険会社を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PostInsuranceCompany
   * @param {PostInsuranceCompanyRequest} postInsuranceCompanyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InsuranceCompanyApi
   */
  public postInsuranceCompany(
    postInsuranceCompanyRequest: PostInsuranceCompanyRequest,
    options?: AxiosRequestConfig
  ) {
    return InsuranceCompanyApiFp(this.configuration)
      .postInsuranceCompany(postInsuranceCompanyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>保険会社を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PutInsuranceCompany
   * @param {number} insuranceCompanyId
   * @param {PostInsuranceCompanyRequest} postInsuranceCompanyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InsuranceCompanyApi
   */
  public putInsuranceCompany(
    insuranceCompanyId: number,
    postInsuranceCompanyRequest: PostInsuranceCompanyRequest,
    options?: AxiosRequestConfig
  ) {
    return InsuranceCompanyApiFp(this.configuration)
      .putInsuranceCompany(
        insuranceCompanyId,
        postInsuranceCompanyRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InsuranceCompanyQueryApi - axios parameter creator
 * @export
 */
export const InsuranceCompanyQueryApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>queryに基づき保険会社を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>表示順の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetInsuranceCompanies
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;保険会社を検索するキーワード&lt;/dt&gt;      &lt;dt&gt;下記の項目をキーワードで検索します&lt;/dt&gt;   &lt;dd&gt;保険会社名の部分一致&lt;/dd&gt;    &lt;/dl&gt;
     * @param {InsuranceCategory} [insuranceCategory] 生保か損保かを指定します
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInsuranceCompanies: async (
      page: number,
      numberPerPage: number,
      keyword?: string,
      insuranceCategory?: InsuranceCategory,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getInsuranceCompanies", "page", page);
      // verify required parameter 'numberPerPage' is not null or undefined
      assertParamExists(
        "getInsuranceCompanies",
        "numberPerPage",
        numberPerPage
      );
      const localVarPath = `/insuranceCompany`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (insuranceCategory !== undefined) {
        localVarQueryParameter["insuranceCategory"] = insuranceCategory;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberPerPage !== undefined) {
        localVarQueryParameter["numberPerPage"] = numberPerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InsuranceCompanyQueryApi - functional programming interface
 * @export
 */
export const InsuranceCompanyQueryApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    InsuranceCompanyQueryApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき保険会社を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>表示順の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetInsuranceCompanies
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;保険会社を検索するキーワード&lt;/dt&gt;      &lt;dt&gt;下記の項目をキーワードで検索します&lt;/dt&gt;   &lt;dd&gt;保険会社名の部分一致&lt;/dd&gt;    &lt;/dl&gt;
     * @param {InsuranceCategory} [insuranceCategory] 生保か損保かを指定します
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInsuranceCompanies(
      page: number,
      numberPerPage: number,
      keyword?: string,
      insuranceCategory?: InsuranceCategory,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetInsuranceCompanies200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getInsuranceCompanies(
          page,
          numberPerPage,
          keyword,
          insuranceCategory,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * InsuranceCompanyQueryApi - factory interface
 * @export
 */
export const InsuranceCompanyQueryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = InsuranceCompanyQueryApiFp(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき保険会社を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>表示順の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetInsuranceCompanies
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;保険会社を検索するキーワード&lt;/dt&gt;      &lt;dt&gt;下記の項目をキーワードで検索します&lt;/dt&gt;   &lt;dd&gt;保険会社名の部分一致&lt;/dd&gt;    &lt;/dl&gt;
     * @param {InsuranceCategory} [insuranceCategory] 生保か損保かを指定します
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInsuranceCompanies(
      page: number,
      numberPerPage: number,
      keyword?: string,
      insuranceCategory?: InsuranceCategory,
      options?: any
    ): AxiosPromise<GetInsuranceCompanies200Response> {
      return localVarFp
        .getInsuranceCompanies(
          page,
          numberPerPage,
          keyword,
          insuranceCategory,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InsuranceCompanyQueryApi - object-oriented interface
 * @export
 * @class InsuranceCompanyQueryApi
 * @extends {BaseAPI}
 */
export class InsuranceCompanyQueryApi extends BaseAPI {
  /**
   * <dl>    <dt>queryに基づき保険会社を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>表示順の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
   * @summary GetInsuranceCompanies
   * @param {number} page 取得したいpage
   * @param {number} numberPerPage 1ページあたりの検索結果数
   * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;保険会社を検索するキーワード&lt;/dt&gt;      &lt;dt&gt;下記の項目をキーワードで検索します&lt;/dt&gt;   &lt;dd&gt;保険会社名の部分一致&lt;/dd&gt;    &lt;/dl&gt;
   * @param {InsuranceCategory} [insuranceCategory] 生保か損保かを指定します
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InsuranceCompanyQueryApi
   */
  public getInsuranceCompanies(
    page: number,
    numberPerPage: number,
    keyword?: string,
    insuranceCategory?: InsuranceCategory,
    options?: AxiosRequestConfig
  ) {
    return InsuranceCompanyQueryApiFp(this.configuration)
      .getInsuranceCompanies(
        page,
        numberPerPage,
        keyword,
        insuranceCategory,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InsuranceTypeApi - axios parameter creator
 * @export
 */
export const InsuranceTypeApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>保険種類を取得します。</dt>      <dt>権限制御</dt>   <dd>なし</dd>      <dt>取得結果がない場合404を返します。</dt>  </dl>
     * @summary GetInsuranceType
     * @param {number} insuranceTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInsuranceType: async (
      insuranceTypeId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'insuranceTypeId' is not null or undefined
      assertParamExists("getInsuranceType", "insuranceTypeId", insuranceTypeId);
      const localVarPath = `/insuranceType/{insuranceTypeId}`.replace(
        `{${"insuranceTypeId"}}`,
        encodeURIComponent(String(insuranceTypeId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>保険種類を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostInsuranceType
     * @param {PostInsuranceTypeRequest} postInsuranceTypeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postInsuranceType: async (
      postInsuranceTypeRequest: PostInsuranceTypeRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postInsuranceTypeRequest' is not null or undefined
      assertParamExists(
        "postInsuranceType",
        "postInsuranceTypeRequest",
        postInsuranceTypeRequest
      );
      const localVarPath = `/insuranceType`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postInsuranceTypeRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>保険種類を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutInsuranceType
     * @param {number} insuranceTypeId
     * @param {PostInsuranceTypeRequest} postInsuranceTypeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putInsuranceType: async (
      insuranceTypeId: number,
      postInsuranceTypeRequest: PostInsuranceTypeRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'insuranceTypeId' is not null or undefined
      assertParamExists("putInsuranceType", "insuranceTypeId", insuranceTypeId);
      // verify required parameter 'postInsuranceTypeRequest' is not null or undefined
      assertParamExists(
        "putInsuranceType",
        "postInsuranceTypeRequest",
        postInsuranceTypeRequest
      );
      const localVarPath = `/insuranceType/{insuranceTypeId}`.replace(
        `{${"insuranceTypeId"}}`,
        encodeURIComponent(String(insuranceTypeId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postInsuranceTypeRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InsuranceTypeApi - functional programming interface
 * @export
 */
export const InsuranceTypeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    InsuranceTypeApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>保険種類を取得します。</dt>      <dt>権限制御</dt>   <dd>なし</dd>      <dt>取得結果がない場合404を返します。</dt>  </dl>
     * @summary GetInsuranceType
     * @param {number} insuranceTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInsuranceType(
      insuranceTypeId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetInsuranceTypes200ResponseResultsInner>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getInsuranceType(
          insuranceTypeId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>保険種類を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostInsuranceType
     * @param {PostInsuranceTypeRequest} postInsuranceTypeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postInsuranceType(
      postInsuranceTypeRequest: PostInsuranceTypeRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postInsuranceType(
          postInsuranceTypeRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>保険種類を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutInsuranceType
     * @param {number} insuranceTypeId
     * @param {PostInsuranceTypeRequest} postInsuranceTypeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putInsuranceType(
      insuranceTypeId: number,
      postInsuranceTypeRequest: PostInsuranceTypeRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putInsuranceType(
          insuranceTypeId,
          postInsuranceTypeRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * InsuranceTypeApi - factory interface
 * @export
 */
export const InsuranceTypeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = InsuranceTypeApiFp(configuration);
  return {
    /**
     * <dl>    <dt>保険種類を取得します。</dt>      <dt>権限制御</dt>   <dd>なし</dd>      <dt>取得結果がない場合404を返します。</dt>  </dl>
     * @summary GetInsuranceType
     * @param {number} insuranceTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInsuranceType(
      insuranceTypeId: number,
      options?: any
    ): AxiosPromise<GetInsuranceTypes200ResponseResultsInner> {
      return localVarFp
        .getInsuranceType(insuranceTypeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>保険種類を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostInsuranceType
     * @param {PostInsuranceTypeRequest} postInsuranceTypeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postInsuranceType(
      postInsuranceTypeRequest: PostInsuranceTypeRequest,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .postInsuranceType(postInsuranceTypeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>保険種類を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutInsuranceType
     * @param {number} insuranceTypeId
     * @param {PostInsuranceTypeRequest} postInsuranceTypeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putInsuranceType(
      insuranceTypeId: number,
      postInsuranceTypeRequest: PostInsuranceTypeRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putInsuranceType(insuranceTypeId, postInsuranceTypeRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InsuranceTypeApi - object-oriented interface
 * @export
 * @class InsuranceTypeApi
 * @extends {BaseAPI}
 */
export class InsuranceTypeApi extends BaseAPI {
  /**
   * <dl>    <dt>保険種類を取得します。</dt>      <dt>権限制御</dt>   <dd>なし</dd>      <dt>取得結果がない場合404を返します。</dt>  </dl>
   * @summary GetInsuranceType
   * @param {number} insuranceTypeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InsuranceTypeApi
   */
  public getInsuranceType(
    insuranceTypeId: number,
    options?: AxiosRequestConfig
  ) {
    return InsuranceTypeApiFp(this.configuration)
      .getInsuranceType(insuranceTypeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>保険種類を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PostInsuranceType
   * @param {PostInsuranceTypeRequest} postInsuranceTypeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InsuranceTypeApi
   */
  public postInsuranceType(
    postInsuranceTypeRequest: PostInsuranceTypeRequest,
    options?: AxiosRequestConfig
  ) {
    return InsuranceTypeApiFp(this.configuration)
      .postInsuranceType(postInsuranceTypeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>保険種類を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PutInsuranceType
   * @param {number} insuranceTypeId
   * @param {PostInsuranceTypeRequest} postInsuranceTypeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InsuranceTypeApi
   */
  public putInsuranceType(
    insuranceTypeId: number,
    postInsuranceTypeRequest: PostInsuranceTypeRequest,
    options?: AxiosRequestConfig
  ) {
    return InsuranceTypeApiFp(this.configuration)
      .putInsuranceType(insuranceTypeId, postInsuranceTypeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InsuranceTypeQueryApi - axios parameter creator
 * @export
 */
export const InsuranceTypeQueryApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>queryに基づき保険種類を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>保険カテゴリー（生保、損保の順）、表示順、保険種類名の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetInsuranceTypes
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {InsuranceCategory} [insuranceCategory]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInsuranceTypes: async (
      page: number,
      numberPerPage: number,
      insuranceCategory?: InsuranceCategory,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getInsuranceTypes", "page", page);
      // verify required parameter 'numberPerPage' is not null or undefined
      assertParamExists("getInsuranceTypes", "numberPerPage", numberPerPage);
      const localVarPath = `/insuranceType`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (insuranceCategory !== undefined) {
        localVarQueryParameter["insuranceCategory"] = insuranceCategory;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberPerPage !== undefined) {
        localVarQueryParameter["numberPerPage"] = numberPerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InsuranceTypeQueryApi - functional programming interface
 * @export
 */
export const InsuranceTypeQueryApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    InsuranceTypeQueryApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき保険種類を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>保険カテゴリー（生保、損保の順）、表示順、保険種類名の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetInsuranceTypes
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {InsuranceCategory} [insuranceCategory]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInsuranceTypes(
      page: number,
      numberPerPage: number,
      insuranceCategory?: InsuranceCategory,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetInsuranceTypes200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getInsuranceTypes(
          page,
          numberPerPage,
          insuranceCategory,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * InsuranceTypeQueryApi - factory interface
 * @export
 */
export const InsuranceTypeQueryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = InsuranceTypeQueryApiFp(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき保険種類を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>保険カテゴリー（生保、損保の順）、表示順、保険種類名の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetInsuranceTypes
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {InsuranceCategory} [insuranceCategory]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInsuranceTypes(
      page: number,
      numberPerPage: number,
      insuranceCategory?: InsuranceCategory,
      options?: any
    ): AxiosPromise<GetInsuranceTypes200Response> {
      return localVarFp
        .getInsuranceTypes(page, numberPerPage, insuranceCategory, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InsuranceTypeQueryApi - object-oriented interface
 * @export
 * @class InsuranceTypeQueryApi
 * @extends {BaseAPI}
 */
export class InsuranceTypeQueryApi extends BaseAPI {
  /**
   * <dl>    <dt>queryに基づき保険種類を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>保険カテゴリー（生保、損保の順）、表示順、保険種類名の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
   * @summary GetInsuranceTypes
   * @param {number} page 取得したいpage
   * @param {number} numberPerPage 1ページあたりの検索結果数
   * @param {InsuranceCategory} [insuranceCategory]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InsuranceTypeQueryApi
   */
  public getInsuranceTypes(
    page: number,
    numberPerPage: number,
    insuranceCategory?: InsuranceCategory,
    options?: AxiosRequestConfig
  ) {
    return InsuranceTypeQueryApiFp(this.configuration)
      .getInsuranceTypes(page, numberPerPage, insuranceCategory, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MarketApi - axios parameter creator
 * @export
 */
export const MarketApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>マーケットを取得します。</dt>      <dt>権限制御</dt>   <dd>なし</dd>    <dt>取得結果がない場合404を返します。</dt>  </dl>
     * @summary GetMarket
     * @param {number} marketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMarket: async (
      marketId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'marketId' is not null or undefined
      assertParamExists("getMarket", "marketId", marketId);
      const localVarPath = `/market/{marketId}`.replace(
        `{${"marketId"}}`,
        encodeURIComponent(String(marketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>マーケットを新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostMarket
     * @param {PostMarketRequest} postMarketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMarket: async (
      postMarketRequest: PostMarketRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postMarketRequest' is not null or undefined
      assertParamExists("postMarket", "postMarketRequest", postMarketRequest);
      const localVarPath = `/market`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postMarketRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>マーケットを更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutMarket
     * @param {number} marketId
     * @param {PostMarketRequest} postMarketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putMarket: async (
      marketId: number,
      postMarketRequest: PostMarketRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'marketId' is not null or undefined
      assertParamExists("putMarket", "marketId", marketId);
      // verify required parameter 'postMarketRequest' is not null or undefined
      assertParamExists("putMarket", "postMarketRequest", postMarketRequest);
      const localVarPath = `/market/{marketId}`.replace(
        `{${"marketId"}}`,
        encodeURIComponent(String(marketId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postMarketRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MarketApi - functional programming interface
 * @export
 */
export const MarketApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MarketApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>マーケットを取得します。</dt>      <dt>権限制御</dt>   <dd>なし</dd>    <dt>取得結果がない場合404を返します。</dt>  </dl>
     * @summary GetMarket
     * @param {number} marketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMarket(
      marketId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Market>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMarket(
        marketId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>マーケットを新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostMarket
     * @param {PostMarketRequest} postMarketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postMarket(
      postMarketRequest: PostMarketRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postMarket(
        postMarketRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>マーケットを更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutMarket
     * @param {number} marketId
     * @param {PostMarketRequest} postMarketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putMarket(
      marketId: number,
      postMarketRequest: PostMarketRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putMarket(
        marketId,
        postMarketRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * MarketApi - factory interface
 * @export
 */
export const MarketApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = MarketApiFp(configuration);
  return {
    /**
     * <dl>    <dt>マーケットを取得します。</dt>      <dt>権限制御</dt>   <dd>なし</dd>    <dt>取得結果がない場合404を返します。</dt>  </dl>
     * @summary GetMarket
     * @param {number} marketId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMarket(marketId: number, options?: any): AxiosPromise<Market> {
      return localVarFp
        .getMarket(marketId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>マーケットを新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostMarket
     * @param {PostMarketRequest} postMarketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMarket(
      postMarketRequest: PostMarketRequest,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .postMarket(postMarketRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>マーケットを更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutMarket
     * @param {number} marketId
     * @param {PostMarketRequest} postMarketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putMarket(
      marketId: number,
      postMarketRequest: PostMarketRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putMarket(marketId, postMarketRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MarketApi - object-oriented interface
 * @export
 * @class MarketApi
 * @extends {BaseAPI}
 */
export class MarketApi extends BaseAPI {
  /**
   * <dl>    <dt>マーケットを取得します。</dt>      <dt>権限制御</dt>   <dd>なし</dd>    <dt>取得結果がない場合404を返します。</dt>  </dl>
   * @summary GetMarket
   * @param {number} marketId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public getMarket(marketId: number, options?: AxiosRequestConfig) {
    return MarketApiFp(this.configuration)
      .getMarket(marketId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>マーケットを新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PostMarket
   * @param {PostMarketRequest} postMarketRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public postMarket(
    postMarketRequest: PostMarketRequest,
    options?: AxiosRequestConfig
  ) {
    return MarketApiFp(this.configuration)
      .postMarket(postMarketRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>マーケットを更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PutMarket
   * @param {number} marketId
   * @param {PostMarketRequest} postMarketRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public putMarket(
    marketId: number,
    postMarketRequest: PostMarketRequest,
    options?: AxiosRequestConfig
  ) {
    return MarketApiFp(this.configuration)
      .putMarket(marketId, postMarketRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MarketQueryApi - axios parameter creator
 * @export
 */
export const MarketQueryApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>queryに基づきマーケットを取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>表示順の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetMarkets
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;マーケットを検索するキーワード&lt;/dt&gt;      &lt;dt&gt;下記の項目をキーワードで検索します&lt;/dt&gt;   &lt;dd&gt;マーケット名の部分一致&lt;/dd&gt;    &lt;/dl&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMarkets: async (
      page: number,
      numberPerPage: number,
      keyword?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getMarkets", "page", page);
      // verify required parameter 'numberPerPage' is not null or undefined
      assertParamExists("getMarkets", "numberPerPage", numberPerPage);
      const localVarPath = `/market`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberPerPage !== undefined) {
        localVarQueryParameter["numberPerPage"] = numberPerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MarketQueryApi - functional programming interface
 * @export
 */
export const MarketQueryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    MarketQueryApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づきマーケットを取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>表示順の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetMarkets
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;マーケットを検索するキーワード&lt;/dt&gt;      &lt;dt&gt;下記の項目をキーワードで検索します&lt;/dt&gt;   &lt;dd&gt;マーケット名の部分一致&lt;/dd&gt;    &lt;/dl&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMarkets(
      page: number,
      numberPerPage: number,
      keyword?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetMarkets200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMarkets(
        page,
        numberPerPage,
        keyword,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * MarketQueryApi - factory interface
 * @export
 */
export const MarketQueryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = MarketQueryApiFp(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づきマーケットを取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>表示順の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetMarkets
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;マーケットを検索するキーワード&lt;/dt&gt;      &lt;dt&gt;下記の項目をキーワードで検索します&lt;/dt&gt;   &lt;dd&gt;マーケット名の部分一致&lt;/dd&gt;    &lt;/dl&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMarkets(
      page: number,
      numberPerPage: number,
      keyword?: string,
      options?: any
    ): AxiosPromise<GetMarkets200Response> {
      return localVarFp
        .getMarkets(page, numberPerPage, keyword, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MarketQueryApi - object-oriented interface
 * @export
 * @class MarketQueryApi
 * @extends {BaseAPI}
 */
export class MarketQueryApi extends BaseAPI {
  /**
   * <dl>    <dt>queryに基づきマーケットを取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>表示順の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
   * @summary GetMarkets
   * @param {number} page 取得したいpage
   * @param {number} numberPerPage 1ページあたりの検索結果数
   * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;マーケットを検索するキーワード&lt;/dt&gt;      &lt;dt&gt;下記の項目をキーワードで検索します&lt;/dt&gt;   &lt;dd&gt;マーケット名の部分一致&lt;/dd&gt;    &lt;/dl&gt;
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketQueryApi
   */
  public getMarkets(
    page: number,
    numberPerPage: number,
    keyword?: string,
    options?: AxiosRequestConfig
  ) {
    return MarketQueryApiFp(this.configuration)
      .getMarkets(page, numberPerPage, keyword, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OperationLogQueryApi - axios parameter creator
 * @export
 */
export const OperationLogQueryApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>queryに基づき操作ログを取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>ログ日時の降順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>  </dl>
     * @summary GetOperationLogs
     * @param {Array<ApiName>} apiNames API名List
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [logAtFrom] ログ日時From
     * @param {string} [logAtTo] ログ日時To
     * @param {string} [resourceId] log対象のリソースのID
     * @param {number} [staffId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperationLogs: async (
      apiNames: Array<ApiName>,
      page: number,
      numberPerPage: number,
      logAtFrom?: string,
      logAtTo?: string,
      resourceId?: string,
      staffId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiNames' is not null or undefined
      assertParamExists("getOperationLogs", "apiNames", apiNames);
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getOperationLogs", "page", page);
      // verify required parameter 'numberPerPage' is not null or undefined
      assertParamExists("getOperationLogs", "numberPerPage", numberPerPage);
      const localVarPath = `/operationLog`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (logAtFrom !== undefined) {
        localVarQueryParameter["logAtFrom"] =
          (logAtFrom as any) instanceof Date
            ? (logAtFrom as any).toISOString()
            : logAtFrom;
      }

      if (logAtTo !== undefined) {
        localVarQueryParameter["logAtTo"] =
          (logAtTo as any) instanceof Date
            ? (logAtTo as any).toISOString()
            : logAtTo;
      }

      if (apiNames) {
        localVarQueryParameter["apiNames"] = apiNames;
      }

      if (resourceId !== undefined) {
        localVarQueryParameter["resourceId"] = resourceId;
      }

      if (staffId !== undefined) {
        localVarQueryParameter["staffId"] = staffId;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberPerPage !== undefined) {
        localVarQueryParameter["numberPerPage"] = numberPerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OperationLogQueryApi - functional programming interface
 * @export
 */
export const OperationLogQueryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    OperationLogQueryApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき操作ログを取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>ログ日時の降順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>  </dl>
     * @summary GetOperationLogs
     * @param {Array<ApiName>} apiNames API名List
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [logAtFrom] ログ日時From
     * @param {string} [logAtTo] ログ日時To
     * @param {string} [resourceId] log対象のリソースのID
     * @param {number} [staffId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOperationLogs(
      apiNames: Array<ApiName>,
      page: number,
      numberPerPage: number,
      logAtFrom?: string,
      logAtTo?: string,
      resourceId?: string,
      staffId?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetOperationLogs200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOperationLogs(
          apiNames,
          page,
          numberPerPage,
          logAtFrom,
          logAtTo,
          resourceId,
          staffId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * OperationLogQueryApi - factory interface
 * @export
 */
export const OperationLogQueryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OperationLogQueryApiFp(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき操作ログを取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>ログ日時の降順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>  </dl>
     * @summary GetOperationLogs
     * @param {Array<ApiName>} apiNames API名List
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [logAtFrom] ログ日時From
     * @param {string} [logAtTo] ログ日時To
     * @param {string} [resourceId] log対象のリソースのID
     * @param {number} [staffId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperationLogs(
      apiNames: Array<ApiName>,
      page: number,
      numberPerPage: number,
      logAtFrom?: string,
      logAtTo?: string,
      resourceId?: string,
      staffId?: number,
      options?: any
    ): AxiosPromise<GetOperationLogs200Response> {
      return localVarFp
        .getOperationLogs(
          apiNames,
          page,
          numberPerPage,
          logAtFrom,
          logAtTo,
          resourceId,
          staffId,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OperationLogQueryApi - object-oriented interface
 * @export
 * @class OperationLogQueryApi
 * @extends {BaseAPI}
 */
export class OperationLogQueryApi extends BaseAPI {
  /**
   * <dl>    <dt>queryに基づき操作ログを取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>ログ日時の降順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>  </dl>
   * @summary GetOperationLogs
   * @param {Array<ApiName>} apiNames API名List
   * @param {number} page 取得したいpage
   * @param {number} numberPerPage 1ページあたりの検索結果数
   * @param {string} [logAtFrom] ログ日時From
   * @param {string} [logAtTo] ログ日時To
   * @param {string} [resourceId] log対象のリソースのID
   * @param {number} [staffId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperationLogQueryApi
   */
  public getOperationLogs(
    apiNames: Array<ApiName>,
    page: number,
    numberPerPage: number,
    logAtFrom?: string,
    logAtTo?: string,
    resourceId?: string,
    staffId?: number,
    options?: AxiosRequestConfig
  ) {
    return OperationLogQueryApiFp(this.configuration)
      .getOperationLogs(
        apiNames,
        page,
        numberPerPage,
        logAtFrom,
        logAtTo,
        resourceId,
        staffId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PayrollApi - axios parameter creator
 * @export
 */
export const PayrollApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>給与CSVをインポートします。</dt>      <dt>給与CSVインポート</dt>   <dd>CSV仕様参照</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostPayrollCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPayrollCsv: async (
      file: File,
      filename?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("postPayrollCsv", "file", file);
      const localVarPath = `/payroll/csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (filename !== undefined) {
        localVarFormParams.append("filename", filename as any);
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PayrollApi - functional programming interface
 * @export
 */
export const PayrollApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PayrollApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>給与CSVをインポートします。</dt>      <dt>給与CSVインポート</dt>   <dd>CSV仕様参照</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostPayrollCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postPayrollCsv(
      file: File,
      filename?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PostAdjustmentDeductionIncentiveCsv200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postPayrollCsv(
        file,
        filename,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * PayrollApi - factory interface
 * @export
 */
export const PayrollApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PayrollApiFp(configuration);
  return {
    /**
     * <dl>    <dt>給与CSVをインポートします。</dt>      <dt>給与CSVインポート</dt>   <dd>CSV仕様参照</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostPayrollCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPayrollCsv(
      file: File,
      filename?: string,
      options?: any
    ): AxiosPromise<PostAdjustmentDeductionIncentiveCsv200Response> {
      return localVarFp
        .postPayrollCsv(file, filename, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PayrollApi - object-oriented interface
 * @export
 * @class PayrollApi
 * @extends {BaseAPI}
 */
export class PayrollApi extends BaseAPI {
  /**
   * <dl>    <dt>給与CSVをインポートします。</dt>      <dt>給与CSVインポート</dt>   <dd>CSV仕様参照</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PostPayrollCsv
   * @param {File} file
   * @param {string} [filename]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PayrollApi
   */
  public postPayrollCsv(
    file: File,
    filename?: string,
    options?: AxiosRequestConfig
  ) {
    return PayrollApiFp(this.configuration)
      .postPayrollCsv(file, filename, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PerformanceBasedPayQueryApi - axios parameter creator
 * @export
 */
export const PerformanceBasedPayQueryApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>概要</dt>   <dd>支給月の業績給計算開始前のチェックを実施します。</dd>   <dd>保険会社マスタの保険会社が全て手数料取込されているか。</dd>   <dd>全ての手数料取込が`commissionCalculated`であるか。</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary GetPerformanceBasedPayCheck
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPerformanceBasedPayCheck: async (
      month: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'month' is not null or undefined
      assertParamExists("getPerformanceBasedPayCheck", "month", month);
      const localVarPath =
        `/performanceBasedPay/calculation/check/{month}`.replace(
          `{${"month"}}`,
          encodeURIComponent(String(month))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>指定した月の給与システム連携情報をCSVエクスポートします。</dt>   <dt>ソート順：社員番号昇順</dt>   <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary GetPerformanceBasedPayPayrollCsv
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPerformanceBasedPayPayrollCsv: async (
      month: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'month' is not null or undefined
      assertParamExists("getPerformanceBasedPayPayrollCsv", "month", month);
      const localVarPath = `/performanceBasedPay/{month}/payroll/csv`.replace(
        `{${"month"}}`,
        encodeURIComponent(String(month))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>指定した月の業績給計算結果を取得します。</dt>   <dt>staffId未指定の場合</dt>   <dd>ユーザ種別が管理者の場合、該当月の全業績給計算結果を取得</dd>   <dd>ユーザ種別が一般の場合、自分の業績給計算結果と業績給参照権限を持つ所属に在籍する募集人の業績給計算結果</dd>   <dt>staffId指定ありの場合</dt>   <dd>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の業績参照権限のある所属に在籍するstaffId のデータのみ取得可</dd>    <dt>取得結果のソート順</dt>   <dd>所属の表示順</dd>   <dd>社員番号の昇順</dd>    <dt>本APIは製造と要件のコスパを考慮してpageとnumberPerPageを指定しません</dt>   <dd>性能問題が発生した際などに、pageとnumberPerPageの追加も含めて対策を検討します</dd>  </dl>
     * @summary GetPerformanceBasedPayResults
     * @param {string} month
     * @param {number} [staffId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPerformanceBasedPayResults: async (
      month: string,
      staffId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'month' is not null or undefined
      assertParamExists("getPerformanceBasedPayResults", "month", month);
      const localVarPath = `/performanceBasedPay/result/{month}`.replace(
        `{${"month"}}`,
        encodeURIComponent(String(month))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (staffId !== undefined) {
        localVarQueryParameter["staffId"] = staffId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>概要</dt>   <dd>支給月の業績給計算を実施します。</dd>   <dd>既に支給月の業績給計算が実施済の場合、業績給計算の結果をクリアして再計算します。</dd>   <dd>業績給計算の詳細は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%A5%AD%E7%B8%BE%E7%B5%A6%E8%A8%88%E7%AE%97.md\">業績給計算処理</a>を参照。</dd>    <dt>Validation</dt>   <dd>支給月の手数料取込IDに紐づく手数料取込ステータスが`commissionCalculated`でない場合、400BadRequest。</dd>      <dt>更新内容</dt>   <dd>業績給計算結果テーブルを作成します</dd>   <dd>支給月の手数料取込の手数料取込ステータスをperformanceBasedPayCalculatedに更新します</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary PutPerformanceBasedPayCalculation
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putPerformanceBasedPayCalculation: async (
      month: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'month' is not null or undefined
      assertParamExists("putPerformanceBasedPayCalculation", "month", month);
      const localVarPath = `/performanceBasedPay/calculation/{month}`.replace(
        `{${"month"}}`,
        encodeURIComponent(String(month))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PerformanceBasedPayQueryApi - functional programming interface
 * @export
 */
export const PerformanceBasedPayQueryApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    PerformanceBasedPayQueryApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>概要</dt>   <dd>支給月の業績給計算開始前のチェックを実施します。</dd>   <dd>保険会社マスタの保険会社が全て手数料取込されているか。</dd>   <dd>全ての手数料取込が`commissionCalculated`であるか。</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary GetPerformanceBasedPayCheck
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPerformanceBasedPayCheck(
      month: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetPerformanceBasedPayCheck200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPerformanceBasedPayCheck(
          month,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>指定した月の給与システム連携情報をCSVエクスポートします。</dt>   <dt>ソート順：社員番号昇順</dt>   <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary GetPerformanceBasedPayPayrollCsv
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPerformanceBasedPayPayrollCsv(
      month: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPerformanceBasedPayPayrollCsv(
          month,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>指定した月の業績給計算結果を取得します。</dt>   <dt>staffId未指定の場合</dt>   <dd>ユーザ種別が管理者の場合、該当月の全業績給計算結果を取得</dd>   <dd>ユーザ種別が一般の場合、自分の業績給計算結果と業績給参照権限を持つ所属に在籍する募集人の業績給計算結果</dd>   <dt>staffId指定ありの場合</dt>   <dd>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の業績参照権限のある所属に在籍するstaffId のデータのみ取得可</dd>    <dt>取得結果のソート順</dt>   <dd>所属の表示順</dd>   <dd>社員番号の昇順</dd>    <dt>本APIは製造と要件のコスパを考慮してpageとnumberPerPageを指定しません</dt>   <dd>性能問題が発生した際などに、pageとnumberPerPageの追加も含めて対策を検討します</dd>  </dl>
     * @summary GetPerformanceBasedPayResults
     * @param {string} month
     * @param {number} [staffId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPerformanceBasedPayResults(
      month: string,
      staffId?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetPerformanceBasedPayResults200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPerformanceBasedPayResults(
          month,
          staffId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>概要</dt>   <dd>支給月の業績給計算を実施します。</dd>   <dd>既に支給月の業績給計算が実施済の場合、業績給計算の結果をクリアして再計算します。</dd>   <dd>業績給計算の詳細は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%A5%AD%E7%B8%BE%E7%B5%A6%E8%A8%88%E7%AE%97.md\">業績給計算処理</a>を参照。</dd>    <dt>Validation</dt>   <dd>支給月の手数料取込IDに紐づく手数料取込ステータスが`commissionCalculated`でない場合、400BadRequest。</dd>      <dt>更新内容</dt>   <dd>業績給計算結果テーブルを作成します</dd>   <dd>支給月の手数料取込の手数料取込ステータスをperformanceBasedPayCalculatedに更新します</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary PutPerformanceBasedPayCalculation
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putPerformanceBasedPayCalculation(
      month: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putPerformanceBasedPayCalculation(
          month,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * PerformanceBasedPayQueryApi - factory interface
 * @export
 */
export const PerformanceBasedPayQueryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PerformanceBasedPayQueryApiFp(configuration);
  return {
    /**
     * <dl>    <dt>概要</dt>   <dd>支給月の業績給計算開始前のチェックを実施します。</dd>   <dd>保険会社マスタの保険会社が全て手数料取込されているか。</dd>   <dd>全ての手数料取込が`commissionCalculated`であるか。</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary GetPerformanceBasedPayCheck
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPerformanceBasedPayCheck(
      month: string,
      options?: any
    ): AxiosPromise<GetPerformanceBasedPayCheck200Response> {
      return localVarFp
        .getPerformanceBasedPayCheck(month, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>指定した月の給与システム連携情報をCSVエクスポートします。</dt>   <dt>ソート順：社員番号昇順</dt>   <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary GetPerformanceBasedPayPayrollCsv
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPerformanceBasedPayPayrollCsv(
      month: string,
      options?: any
    ): AxiosPromise<File> {
      return localVarFp
        .getPerformanceBasedPayPayrollCsv(month, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>指定した月の業績給計算結果を取得します。</dt>   <dt>staffId未指定の場合</dt>   <dd>ユーザ種別が管理者の場合、該当月の全業績給計算結果を取得</dd>   <dd>ユーザ種別が一般の場合、自分の業績給計算結果と業績給参照権限を持つ所属に在籍する募集人の業績給計算結果</dd>   <dt>staffId指定ありの場合</dt>   <dd>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の業績参照権限のある所属に在籍するstaffId のデータのみ取得可</dd>    <dt>取得結果のソート順</dt>   <dd>所属の表示順</dd>   <dd>社員番号の昇順</dd>    <dt>本APIは製造と要件のコスパを考慮してpageとnumberPerPageを指定しません</dt>   <dd>性能問題が発生した際などに、pageとnumberPerPageの追加も含めて対策を検討します</dd>  </dl>
     * @summary GetPerformanceBasedPayResults
     * @param {string} month
     * @param {number} [staffId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPerformanceBasedPayResults(
      month: string,
      staffId?: number,
      options?: any
    ): AxiosPromise<GetPerformanceBasedPayResults200Response> {
      return localVarFp
        .getPerformanceBasedPayResults(month, staffId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>概要</dt>   <dd>支給月の業績給計算を実施します。</dd>   <dd>既に支給月の業績給計算が実施済の場合、業績給計算の結果をクリアして再計算します。</dd>   <dd>業績給計算の詳細は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%A5%AD%E7%B8%BE%E7%B5%A6%E8%A8%88%E7%AE%97.md\">業績給計算処理</a>を参照。</dd>    <dt>Validation</dt>   <dd>支給月の手数料取込IDに紐づく手数料取込ステータスが`commissionCalculated`でない場合、400BadRequest。</dd>      <dt>更新内容</dt>   <dd>業績給計算結果テーブルを作成します</dd>   <dd>支給月の手数料取込の手数料取込ステータスをperformanceBasedPayCalculatedに更新します</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
     * @summary PutPerformanceBasedPayCalculation
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putPerformanceBasedPayCalculation(
      month: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putPerformanceBasedPayCalculation(month, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PerformanceBasedPayQueryApi - object-oriented interface
 * @export
 * @class PerformanceBasedPayQueryApi
 * @extends {BaseAPI}
 */
export class PerformanceBasedPayQueryApi extends BaseAPI {
  /**
   * <dl>    <dt>概要</dt>   <dd>支給月の業績給計算開始前のチェックを実施します。</dd>   <dd>保険会社マスタの保険会社が全て手数料取込されているか。</dd>   <dd>全ての手数料取込が`commissionCalculated`であるか。</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
   * @summary GetPerformanceBasedPayCheck
   * @param {string} month
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PerformanceBasedPayQueryApi
   */
  public getPerformanceBasedPayCheck(
    month: string,
    options?: AxiosRequestConfig
  ) {
    return PerformanceBasedPayQueryApiFp(this.configuration)
      .getPerformanceBasedPayCheck(month, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>指定した月の給与システム連携情報をCSVエクスポートします。</dt>   <dt>ソート順：社員番号昇順</dt>   <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
   * @summary GetPerformanceBasedPayPayrollCsv
   * @param {string} month
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PerformanceBasedPayQueryApi
   */
  public getPerformanceBasedPayPayrollCsv(
    month: string,
    options?: AxiosRequestConfig
  ) {
    return PerformanceBasedPayQueryApiFp(this.configuration)
      .getPerformanceBasedPayPayrollCsv(month, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>指定した月の業績給計算結果を取得します。</dt>   <dt>staffId未指定の場合</dt>   <dd>ユーザ種別が管理者の場合、該当月の全業績給計算結果を取得</dd>   <dd>ユーザ種別が一般の場合、自分の業績給計算結果と業績給参照権限を持つ所属に在籍する募集人の業績給計算結果</dd>   <dt>staffId指定ありの場合</dt>   <dd>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の業績参照権限のある所属に在籍するstaffId のデータのみ取得可</dd>    <dt>取得結果のソート順</dt>   <dd>所属の表示順</dd>   <dd>社員番号の昇順</dd>    <dt>本APIは製造と要件のコスパを考慮してpageとnumberPerPageを指定しません</dt>   <dd>性能問題が発生した際などに、pageとnumberPerPageの追加も含めて対策を検討します</dd>  </dl>
   * @summary GetPerformanceBasedPayResults
   * @param {string} month
   * @param {number} [staffId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PerformanceBasedPayQueryApi
   */
  public getPerformanceBasedPayResults(
    month: string,
    staffId?: number,
    options?: AxiosRequestConfig
  ) {
    return PerformanceBasedPayQueryApiFp(this.configuration)
      .getPerformanceBasedPayResults(month, staffId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>概要</dt>   <dd>支給月の業績給計算を実施します。</dd>   <dd>既に支給月の業績給計算が実施済の場合、業績給計算の結果をクリアして再計算します。</dd>   <dd>業績給計算の詳細は<a href=\"https://github.com/doitplanning/doit-core-doc/blob/main/%E8%A6%81%E4%BB%B6/%E8%A8%88%E7%AE%97/%E6%A5%AD%E7%B8%BE%E7%B5%A6%E8%A8%88%E7%AE%97.md\">業績給計算処理</a>を参照。</dd>    <dt>Validation</dt>   <dd>支給月の手数料取込IDに紐づく手数料取込ステータスが`commissionCalculated`でない場合、400BadRequest。</dd>      <dt>更新内容</dt>   <dd>業績給計算結果テーブルを作成します</dd>   <dd>支給月の手数料取込の手数料取込ステータスをperformanceBasedPayCalculatedに更新します</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>  </dl>
   * @summary PutPerformanceBasedPayCalculation
   * @param {string} month
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PerformanceBasedPayQueryApi
   */
  public putPerformanceBasedPayCalculation(
    month: string,
    options?: AxiosRequestConfig
  ) {
    return PerformanceBasedPayQueryApiFp(this.configuration)
      .putPerformanceBasedPayCalculation(month, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PotentialCustomerApi - axios parameter creator
 * @export
 */
export const PotentialCustomerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>案件と案件に紐づく購入案件管理を削除します。</dt>      <dt>Validation</dt>   <dd>次のいずれかに該当する場合、400BadRequest</dd>   <dd>     <ul>       <li>該当の案件IDに紐づく申達報告テーブルが存在する</li>       <li>該当の案件IDに紐づく購入案件支払管理テーブルの支給月が設定されている</li>     </ul>   </dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に削除可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>       <li>対象案件の許可された募集人</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary DeletePotentialCustomer
     * @param {number} potentialCustomerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePotentialCustomer: async (
      potentialCustomerId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'potentialCustomerId' is not null or undefined
      assertParamExists(
        "deletePotentialCustomer",
        "potentialCustomerId",
        potentialCustomerId
      );
      const localVarPath = `/potentialCustomer/{potentialCustomerId}`.replace(
        `{${"potentialCustomerId"}}`,
        encodeURIComponent(String(potentialCustomerId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>案件を取得します。</dt>      <dt>権限制御</dt>   <dd>次の場合に取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>       <li>対象案件の許可された募集人</li>     </ul>   </dd>   <dd>権限により取得できない場合、取得結果がない場合は404を返却します</dd>  </dl>
     * @summary GetPotentialCustomer
     * @param {number} potentialCustomerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPotentialCustomer: async (
      potentialCustomerId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'potentialCustomerId' is not null or undefined
      assertParamExists(
        "getPotentialCustomer",
        "potentialCustomerId",
        potentialCustomerId
      );
      const localVarPath = `/potentialCustomer/{potentialCustomerId}`.replace(
        `{${"potentialCustomerId"}}`,
        encodeURIComponent(String(potentialCustomerId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>案件を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>購入案件支払テーブルの登録</dt>   <dd>案件テーブルとあわせて購入案件支払テーブルを<a href=\"https://github.com/doitplanning/doit-core-api/blob/main/仕様別紙.md#案件更新時の購入案件支払管理の更新\">案件登録の購入案件支払管理の登録仕様</a>に従って登録します</dd>   </dl>
     * @summary PostPotentialCustomer
     * @param {PostPotentialCustomerRequest} postPotentialCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPotentialCustomer: async (
      postPotentialCustomerRequest: PostPotentialCustomerRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postPotentialCustomerRequest' is not null or undefined
      assertParamExists(
        "postPotentialCustomer",
        "postPotentialCustomerRequest",
        postPotentialCustomerRequest
      );
      const localVarPath = `/potentialCustomer`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postPotentialCustomerRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>案件を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>       <li>対象案件の許可された募集人</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>購入案件支払テーブルの更新</dt>   <dd>案件テーブルとあわせて購入案件支払テーブルを<a href=\"https://github.com/doitplanning/doit-core-api/blob/main/仕様別紙.md#案件更新時の購入案件支払管理の更新\">案件登録の購入案件支払管理の登録仕様</a>に従って更新します</dd>  </dl>
     * @summary PutPotentialCustomer
     * @param {number} potentialCustomerId
     * @param {PutPotentialCustomerRequest} putPotentialCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putPotentialCustomer: async (
      potentialCustomerId: number,
      putPotentialCustomerRequest: PutPotentialCustomerRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'potentialCustomerId' is not null or undefined
      assertParamExists(
        "putPotentialCustomer",
        "potentialCustomerId",
        potentialCustomerId
      );
      // verify required parameter 'putPotentialCustomerRequest' is not null or undefined
      assertParamExists(
        "putPotentialCustomer",
        "putPotentialCustomerRequest",
        putPotentialCustomerRequest
      );
      const localVarPath = `/potentialCustomer/{potentialCustomerId}`.replace(
        `{${"potentialCustomerId"}}`,
        encodeURIComponent(String(potentialCustomerId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        putPotentialCustomerRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PotentialCustomerApi - functional programming interface
 * @export
 */
export const PotentialCustomerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PotentialCustomerApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>案件と案件に紐づく購入案件管理を削除します。</dt>      <dt>Validation</dt>   <dd>次のいずれかに該当する場合、400BadRequest</dd>   <dd>     <ul>       <li>該当の案件IDに紐づく申達報告テーブルが存在する</li>       <li>該当の案件IDに紐づく購入案件支払管理テーブルの支給月が設定されている</li>     </ul>   </dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に削除可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>       <li>対象案件の許可された募集人</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary DeletePotentialCustomer
     * @param {number} potentialCustomerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePotentialCustomer(
      potentialCustomerId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deletePotentialCustomer(
          potentialCustomerId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>案件を取得します。</dt>      <dt>権限制御</dt>   <dd>次の場合に取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>       <li>対象案件の許可された募集人</li>     </ul>   </dd>   <dd>権限により取得できない場合、取得結果がない場合は404を返却します</dd>  </dl>
     * @summary GetPotentialCustomer
     * @param {number} potentialCustomerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPotentialCustomer(
      potentialCustomerId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetPotentialCustomer200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPotentialCustomer(
          potentialCustomerId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>案件を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>購入案件支払テーブルの登録</dt>   <dd>案件テーブルとあわせて購入案件支払テーブルを<a href=\"https://github.com/doitplanning/doit-core-api/blob/main/仕様別紙.md#案件更新時の購入案件支払管理の更新\">案件登録の購入案件支払管理の登録仕様</a>に従って登録します</dd>   </dl>
     * @summary PostPotentialCustomer
     * @param {PostPotentialCustomerRequest} postPotentialCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postPotentialCustomer(
      postPotentialCustomerRequest: PostPotentialCustomerRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postPotentialCustomer(
          postPotentialCustomerRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>案件を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>       <li>対象案件の許可された募集人</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>購入案件支払テーブルの更新</dt>   <dd>案件テーブルとあわせて購入案件支払テーブルを<a href=\"https://github.com/doitplanning/doit-core-api/blob/main/仕様別紙.md#案件更新時の購入案件支払管理の更新\">案件登録の購入案件支払管理の登録仕様</a>に従って更新します</dd>  </dl>
     * @summary PutPotentialCustomer
     * @param {number} potentialCustomerId
     * @param {PutPotentialCustomerRequest} putPotentialCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putPotentialCustomer(
      potentialCustomerId: number,
      putPotentialCustomerRequest: PutPotentialCustomerRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putPotentialCustomer(
          potentialCustomerId,
          putPotentialCustomerRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * PotentialCustomerApi - factory interface
 * @export
 */
export const PotentialCustomerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PotentialCustomerApiFp(configuration);
  return {
    /**
     * <dl>    <dt>案件と案件に紐づく購入案件管理を削除します。</dt>      <dt>Validation</dt>   <dd>次のいずれかに該当する場合、400BadRequest</dd>   <dd>     <ul>       <li>該当の案件IDに紐づく申達報告テーブルが存在する</li>       <li>該当の案件IDに紐づく購入案件支払管理テーブルの支給月が設定されている</li>     </ul>   </dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に削除可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>       <li>対象案件の許可された募集人</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary DeletePotentialCustomer
     * @param {number} potentialCustomerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePotentialCustomer(
      potentialCustomerId: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deletePotentialCustomer(potentialCustomerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>案件を取得します。</dt>      <dt>権限制御</dt>   <dd>次の場合に取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>       <li>対象案件の許可された募集人</li>     </ul>   </dd>   <dd>権限により取得できない場合、取得結果がない場合は404を返却します</dd>  </dl>
     * @summary GetPotentialCustomer
     * @param {number} potentialCustomerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPotentialCustomer(
      potentialCustomerId: number,
      options?: any
    ): AxiosPromise<GetPotentialCustomer200Response> {
      return localVarFp
        .getPotentialCustomer(potentialCustomerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>案件を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>購入案件支払テーブルの登録</dt>   <dd>案件テーブルとあわせて購入案件支払テーブルを<a href=\"https://github.com/doitplanning/doit-core-api/blob/main/仕様別紙.md#案件更新時の購入案件支払管理の更新\">案件登録の購入案件支払管理の登録仕様</a>に従って登録します</dd>   </dl>
     * @summary PostPotentialCustomer
     * @param {PostPotentialCustomerRequest} postPotentialCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPotentialCustomer(
      postPotentialCustomerRequest: PostPotentialCustomerRequest,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .postPotentialCustomer(postPotentialCustomerRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>案件を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>       <li>対象案件の許可された募集人</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>購入案件支払テーブルの更新</dt>   <dd>案件テーブルとあわせて購入案件支払テーブルを<a href=\"https://github.com/doitplanning/doit-core-api/blob/main/仕様別紙.md#案件更新時の購入案件支払管理の更新\">案件登録の購入案件支払管理の登録仕様</a>に従って更新します</dd>  </dl>
     * @summary PutPotentialCustomer
     * @param {number} potentialCustomerId
     * @param {PutPotentialCustomerRequest} putPotentialCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putPotentialCustomer(
      potentialCustomerId: number,
      putPotentialCustomerRequest: PutPotentialCustomerRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putPotentialCustomer(
          potentialCustomerId,
          putPotentialCustomerRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PotentialCustomerApi - object-oriented interface
 * @export
 * @class PotentialCustomerApi
 * @extends {BaseAPI}
 */
export class PotentialCustomerApi extends BaseAPI {
  /**
   * <dl>    <dt>案件と案件に紐づく購入案件管理を削除します。</dt>      <dt>Validation</dt>   <dd>次のいずれかに該当する場合、400BadRequest</dd>   <dd>     <ul>       <li>該当の案件IDに紐づく申達報告テーブルが存在する</li>       <li>該当の案件IDに紐づく購入案件支払管理テーブルの支給月が設定されている</li>     </ul>   </dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に削除可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>       <li>対象案件の許可された募集人</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary DeletePotentialCustomer
   * @param {number} potentialCustomerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PotentialCustomerApi
   */
  public deletePotentialCustomer(
    potentialCustomerId: number,
    options?: AxiosRequestConfig
  ) {
    return PotentialCustomerApiFp(this.configuration)
      .deletePotentialCustomer(potentialCustomerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>案件を取得します。</dt>      <dt>権限制御</dt>   <dd>次の場合に取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>       <li>対象案件の許可された募集人</li>     </ul>   </dd>   <dd>権限により取得できない場合、取得結果がない場合は404を返却します</dd>  </dl>
   * @summary GetPotentialCustomer
   * @param {number} potentialCustomerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PotentialCustomerApi
   */
  public getPotentialCustomer(
    potentialCustomerId: number,
    options?: AxiosRequestConfig
  ) {
    return PotentialCustomerApiFp(this.configuration)
      .getPotentialCustomer(potentialCustomerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>案件を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>購入案件支払テーブルの登録</dt>   <dd>案件テーブルとあわせて購入案件支払テーブルを<a href=\"https://github.com/doitplanning/doit-core-api/blob/main/仕様別紙.md#案件更新時の購入案件支払管理の更新\">案件登録の購入案件支払管理の登録仕様</a>に従って登録します</dd>   </dl>
   * @summary PostPotentialCustomer
   * @param {PostPotentialCustomerRequest} postPotentialCustomerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PotentialCustomerApi
   */
  public postPotentialCustomer(
    postPotentialCustomerRequest: PostPotentialCustomerRequest,
    options?: AxiosRequestConfig
  ) {
    return PotentialCustomerApiFp(this.configuration)
      .postPotentialCustomer(postPotentialCustomerRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>案件を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>対象案件の担当募集人が自分</li>       <li>対象案件の担当募集人の案件・申達・証券権限がある</li>       <li>対象案件の許可された募集人</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>購入案件支払テーブルの更新</dt>   <dd>案件テーブルとあわせて購入案件支払テーブルを<a href=\"https://github.com/doitplanning/doit-core-api/blob/main/仕様別紙.md#案件更新時の購入案件支払管理の更新\">案件登録の購入案件支払管理の登録仕様</a>に従って更新します</dd>  </dl>
   * @summary PutPotentialCustomer
   * @param {number} potentialCustomerId
   * @param {PutPotentialCustomerRequest} putPotentialCustomerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PotentialCustomerApi
   */
  public putPotentialCustomer(
    potentialCustomerId: number,
    putPotentialCustomerRequest: PutPotentialCustomerRequest,
    options?: AxiosRequestConfig
  ) {
    return PotentialCustomerApiFp(this.configuration)
      .putPotentialCustomer(
        potentialCustomerId,
        putPotentialCustomerRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PotentialCustomerQueryApi - axios parameter creator
 * @export
 */
export const PotentialCustomerQueryApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>queryに基づき法人の案件を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>検索HITした法人名の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetPotentialCustomerCorporations
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;法人の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象&lt;/dt&gt;   &lt;dd&gt;案件の法人名の部分一致&lt;/dd&gt;    &lt;/dl&gt;
     * @param {number} [potentialCustomerId] 案件ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [marketId] マーケットID
     * @param {PotentialCustomerType} [potentialCustomerType] 案件区分
     * @param {string} [createdAtFrom] 登録日FROM
     * @param {string} [createdAtTo] 登録日TO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPotentialCustomerCorporations: async (
      page: number,
      numberPerPage: number,
      keyword?: string,
      potentialCustomerId?: number,
      staffId?: number,
      marketId?: number,
      potentialCustomerType?: PotentialCustomerType,
      createdAtFrom?: string,
      createdAtTo?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getPotentialCustomerCorporations", "page", page);
      // verify required parameter 'numberPerPage' is not null or undefined
      assertParamExists(
        "getPotentialCustomerCorporations",
        "numberPerPage",
        numberPerPage
      );
      const localVarPath = `/potentialCustomer/corporation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (potentialCustomerId !== undefined) {
        localVarQueryParameter["potentialCustomerId"] = potentialCustomerId;
      }

      if (staffId !== undefined) {
        localVarQueryParameter["staffId"] = staffId;
      }

      if (marketId !== undefined) {
        localVarQueryParameter["marketId"] = marketId;
      }

      if (potentialCustomerType !== undefined) {
        localVarQueryParameter["potentialCustomerType"] = potentialCustomerType;
      }

      if (createdAtFrom !== undefined) {
        localVarQueryParameter["createdAtFrom"] =
          (createdAtFrom as any) instanceof Date
            ? (createdAtFrom as any).toISOString().substr(0, 10)
            : createdAtFrom;
      }

      if (createdAtTo !== undefined) {
        localVarQueryParameter["createdAtTo"] =
          (createdAtTo as any) instanceof Date
            ? (createdAtTo as any).toISOString().substr(0, 10)
            : createdAtTo;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberPerPage !== undefined) {
        localVarQueryParameter["numberPerPage"] = numberPerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>queryに基づき個人の案件に登録されている人物を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>検索HITした該当者の氏名漢字の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetPotentialCustomerIndividualPersons
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;個人の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;案件&lt;/dd&gt;   &lt;dd&gt;案件の家族&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [potentialCustomerId] 案件ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [marketId] マーケットID
     * @param {PotentialCustomerType} [potentialCustomerType] 案件区分
     * @param {string} [createdAtFrom] 登録日FROM
     * @param {string} [createdAtTo] 登録日TO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPotentialCustomerIndividualPersons: async (
      page: number,
      numberPerPage: number,
      keyword?: string,
      potentialCustomerId?: number,
      staffId?: number,
      marketId?: number,
      potentialCustomerType?: PotentialCustomerType,
      createdAtFrom?: string,
      createdAtTo?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getPotentialCustomerIndividualPersons", "page", page);
      // verify required parameter 'numberPerPage' is not null or undefined
      assertParamExists(
        "getPotentialCustomerIndividualPersons",
        "numberPerPage",
        numberPerPage
      );
      const localVarPath = `/potentialCustomer/individual/person`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (potentialCustomerId !== undefined) {
        localVarQueryParameter["potentialCustomerId"] = potentialCustomerId;
      }

      if (staffId !== undefined) {
        localVarQueryParameter["staffId"] = staffId;
      }

      if (marketId !== undefined) {
        localVarQueryParameter["marketId"] = marketId;
      }

      if (potentialCustomerType !== undefined) {
        localVarQueryParameter["potentialCustomerType"] = potentialCustomerType;
      }

      if (createdAtFrom !== undefined) {
        localVarQueryParameter["createdAtFrom"] =
          (createdAtFrom as any) instanceof Date
            ? (createdAtFrom as any).toISOString().substr(0, 10)
            : createdAtFrom;
      }

      if (createdAtTo !== undefined) {
        localVarQueryParameter["createdAtTo"] =
          (createdAtTo as any) instanceof Date
            ? (createdAtTo as any).toISOString().substr(0, 10)
            : createdAtTo;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberPerPage !== undefined) {
        localVarQueryParameter["numberPerPage"] = numberPerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PotentialCustomerQueryApi - functional programming interface
 * @export
 */
export const PotentialCustomerQueryApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    PotentialCustomerQueryApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき法人の案件を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>検索HITした法人名の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetPotentialCustomerCorporations
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;法人の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象&lt;/dt&gt;   &lt;dd&gt;案件の法人名の部分一致&lt;/dd&gt;    &lt;/dl&gt;
     * @param {number} [potentialCustomerId] 案件ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [marketId] マーケットID
     * @param {PotentialCustomerType} [potentialCustomerType] 案件区分
     * @param {string} [createdAtFrom] 登録日FROM
     * @param {string} [createdAtTo] 登録日TO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPotentialCustomerCorporations(
      page: number,
      numberPerPage: number,
      keyword?: string,
      potentialCustomerId?: number,
      staffId?: number,
      marketId?: number,
      potentialCustomerType?: PotentialCustomerType,
      createdAtFrom?: string,
      createdAtTo?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetPotentialCustomerCorporations200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPotentialCustomerCorporations(
          page,
          numberPerPage,
          keyword,
          potentialCustomerId,
          staffId,
          marketId,
          potentialCustomerType,
          createdAtFrom,
          createdAtTo,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>queryに基づき個人の案件に登録されている人物を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>検索HITした該当者の氏名漢字の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetPotentialCustomerIndividualPersons
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;個人の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;案件&lt;/dd&gt;   &lt;dd&gt;案件の家族&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [potentialCustomerId] 案件ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [marketId] マーケットID
     * @param {PotentialCustomerType} [potentialCustomerType] 案件区分
     * @param {string} [createdAtFrom] 登録日FROM
     * @param {string} [createdAtTo] 登録日TO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPotentialCustomerIndividualPersons(
      page: number,
      numberPerPage: number,
      keyword?: string,
      potentialCustomerId?: number,
      staffId?: number,
      marketId?: number,
      potentialCustomerType?: PotentialCustomerType,
      createdAtFrom?: string,
      createdAtTo?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetPotentialCustomerIndividualPersons200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPotentialCustomerIndividualPersons(
          page,
          numberPerPage,
          keyword,
          potentialCustomerId,
          staffId,
          marketId,
          potentialCustomerType,
          createdAtFrom,
          createdAtTo,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * PotentialCustomerQueryApi - factory interface
 * @export
 */
export const PotentialCustomerQueryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PotentialCustomerQueryApiFp(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき法人の案件を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>検索HITした法人名の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetPotentialCustomerCorporations
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;法人の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象&lt;/dt&gt;   &lt;dd&gt;案件の法人名の部分一致&lt;/dd&gt;    &lt;/dl&gt;
     * @param {number} [potentialCustomerId] 案件ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [marketId] マーケットID
     * @param {PotentialCustomerType} [potentialCustomerType] 案件区分
     * @param {string} [createdAtFrom] 登録日FROM
     * @param {string} [createdAtTo] 登録日TO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPotentialCustomerCorporations(
      page: number,
      numberPerPage: number,
      keyword?: string,
      potentialCustomerId?: number,
      staffId?: number,
      marketId?: number,
      potentialCustomerType?: PotentialCustomerType,
      createdAtFrom?: string,
      createdAtTo?: string,
      options?: any
    ): AxiosPromise<GetPotentialCustomerCorporations200Response> {
      return localVarFp
        .getPotentialCustomerCorporations(
          page,
          numberPerPage,
          keyword,
          potentialCustomerId,
          staffId,
          marketId,
          potentialCustomerType,
          createdAtFrom,
          createdAtTo,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>queryに基づき個人の案件に登録されている人物を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>検索HITした該当者の氏名漢字の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetPotentialCustomerIndividualPersons
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;個人の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;案件&lt;/dd&gt;   &lt;dd&gt;案件の家族&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [potentialCustomerId] 案件ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [marketId] マーケットID
     * @param {PotentialCustomerType} [potentialCustomerType] 案件区分
     * @param {string} [createdAtFrom] 登録日FROM
     * @param {string} [createdAtTo] 登録日TO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPotentialCustomerIndividualPersons(
      page: number,
      numberPerPage: number,
      keyword?: string,
      potentialCustomerId?: number,
      staffId?: number,
      marketId?: number,
      potentialCustomerType?: PotentialCustomerType,
      createdAtFrom?: string,
      createdAtTo?: string,
      options?: any
    ): AxiosPromise<GetPotentialCustomerIndividualPersons200Response> {
      return localVarFp
        .getPotentialCustomerIndividualPersons(
          page,
          numberPerPage,
          keyword,
          potentialCustomerId,
          staffId,
          marketId,
          potentialCustomerType,
          createdAtFrom,
          createdAtTo,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PotentialCustomerQueryApi - object-oriented interface
 * @export
 * @class PotentialCustomerQueryApi
 * @extends {BaseAPI}
 */
export class PotentialCustomerQueryApi extends BaseAPI {
  /**
   * <dl>    <dt>queryに基づき法人の案件を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>検索HITした法人名の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
   * @summary GetPotentialCustomerCorporations
   * @param {number} page 取得したいpage
   * @param {number} numberPerPage 1ページあたりの検索結果数
   * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;法人の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象&lt;/dt&gt;   &lt;dd&gt;案件の法人名の部分一致&lt;/dd&gt;    &lt;/dl&gt;
   * @param {number} [potentialCustomerId] 案件ID
   * @param {number} [staffId] 募集人ID
   * @param {number} [marketId] マーケットID
   * @param {PotentialCustomerType} [potentialCustomerType] 案件区分
   * @param {string} [createdAtFrom] 登録日FROM
   * @param {string} [createdAtTo] 登録日TO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PotentialCustomerQueryApi
   */
  public getPotentialCustomerCorporations(
    page: number,
    numberPerPage: number,
    keyword?: string,
    potentialCustomerId?: number,
    staffId?: number,
    marketId?: number,
    potentialCustomerType?: PotentialCustomerType,
    createdAtFrom?: string,
    createdAtTo?: string,
    options?: AxiosRequestConfig
  ) {
    return PotentialCustomerQueryApiFp(this.configuration)
      .getPotentialCustomerCorporations(
        page,
        numberPerPage,
        keyword,
        potentialCustomerId,
        staffId,
        marketId,
        potentialCustomerType,
        createdAtFrom,
        createdAtTo,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>queryに基づき個人の案件に登録されている人物を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>検索HITした該当者の氏名漢字の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
   * @summary GetPotentialCustomerIndividualPersons
   * @param {number} page 取得したいpage
   * @param {number} numberPerPage 1ページあたりの検索結果数
   * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;個人の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;案件&lt;/dd&gt;   &lt;dd&gt;案件の家族&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;  &lt;/dl&gt;
   * @param {number} [potentialCustomerId] 案件ID
   * @param {number} [staffId] 募集人ID
   * @param {number} [marketId] マーケットID
   * @param {PotentialCustomerType} [potentialCustomerType] 案件区分
   * @param {string} [createdAtFrom] 登録日FROM
   * @param {string} [createdAtTo] 登録日TO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PotentialCustomerQueryApi
   */
  public getPotentialCustomerIndividualPersons(
    page: number,
    numberPerPage: number,
    keyword?: string,
    potentialCustomerId?: number,
    staffId?: number,
    marketId?: number,
    potentialCustomerType?: PotentialCustomerType,
    createdAtFrom?: string,
    createdAtTo?: string,
    options?: AxiosRequestConfig
  ) {
    return PotentialCustomerQueryApiFp(this.configuration)
      .getPotentialCustomerIndividualPersons(
        page,
        numberPerPage,
        keyword,
        potentialCustomerId,
        staffId,
        marketId,
        potentialCustomerType,
        createdAtFrom,
        createdAtTo,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PurchasePotentialCustomerApi - axios parameter creator
 * @export
 */
export const PurchasePotentialCustomerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>購入案件支払を新規登録します。</dt>    <dt>Validation</dt>   <dd>過去（現在月 > 購入案件の支給月） の場合、400BadRequest。</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostPurchasePotentialCustomer
     * @param {PostPurchasePotentialCustomerRequest} postPurchasePotentialCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPurchasePotentialCustomer: async (
      postPurchasePotentialCustomerRequest: PostPurchasePotentialCustomerRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postPurchasePotentialCustomerRequest' is not null or undefined
      assertParamExists(
        "postPurchasePotentialCustomer",
        "postPurchasePotentialCustomerRequest",
        postPurchasePotentialCustomerRequest
      );
      const localVarPath = `/purchasePotentialCustomer`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postPurchasePotentialCustomerRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>購入案件支払を更新します。</dt>    <dt>Validation</dt>   <dd>過去（現在月 > 購入案件の支給月） の場合、400BadRequest。</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutPurchasePotentialCustomer
     * @param {number} purchasePotentialCustomerId
     * @param {PutPurchasePotentialCustomerRequest} putPurchasePotentialCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putPurchasePotentialCustomer: async (
      purchasePotentialCustomerId: number,
      putPurchasePotentialCustomerRequest: PutPurchasePotentialCustomerRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'purchasePotentialCustomerId' is not null or undefined
      assertParamExists(
        "putPurchasePotentialCustomer",
        "purchasePotentialCustomerId",
        purchasePotentialCustomerId
      );
      // verify required parameter 'putPurchasePotentialCustomerRequest' is not null or undefined
      assertParamExists(
        "putPurchasePotentialCustomer",
        "putPurchasePotentialCustomerRequest",
        putPurchasePotentialCustomerRequest
      );
      const localVarPath =
        `/purchasePotentialCustomer/{purchasePotentialCustomerId}`.replace(
          `{${"purchasePotentialCustomerId"}}`,
          encodeURIComponent(String(purchasePotentialCustomerId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        putPurchasePotentialCustomerRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PurchasePotentialCustomerApi - functional programming interface
 * @export
 */
export const PurchasePotentialCustomerApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    PurchasePotentialCustomerApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>購入案件支払を新規登録します。</dt>    <dt>Validation</dt>   <dd>過去（現在月 > 購入案件の支給月） の場合、400BadRequest。</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostPurchasePotentialCustomer
     * @param {PostPurchasePotentialCustomerRequest} postPurchasePotentialCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postPurchasePotentialCustomer(
      postPurchasePotentialCustomerRequest: PostPurchasePotentialCustomerRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postPurchasePotentialCustomer(
          postPurchasePotentialCustomerRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>購入案件支払を更新します。</dt>    <dt>Validation</dt>   <dd>過去（現在月 > 購入案件の支給月） の場合、400BadRequest。</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutPurchasePotentialCustomer
     * @param {number} purchasePotentialCustomerId
     * @param {PutPurchasePotentialCustomerRequest} putPurchasePotentialCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putPurchasePotentialCustomer(
      purchasePotentialCustomerId: number,
      putPurchasePotentialCustomerRequest: PutPurchasePotentialCustomerRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putPurchasePotentialCustomer(
          purchasePotentialCustomerId,
          putPurchasePotentialCustomerRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * PurchasePotentialCustomerApi - factory interface
 * @export
 */
export const PurchasePotentialCustomerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PurchasePotentialCustomerApiFp(configuration);
  return {
    /**
     * <dl>    <dt>購入案件支払を新規登録します。</dt>    <dt>Validation</dt>   <dd>過去（現在月 > 購入案件の支給月） の場合、400BadRequest。</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostPurchasePotentialCustomer
     * @param {PostPurchasePotentialCustomerRequest} postPurchasePotentialCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPurchasePotentialCustomer(
      postPurchasePotentialCustomerRequest: PostPurchasePotentialCustomerRequest,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .postPurchasePotentialCustomer(
          postPurchasePotentialCustomerRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>購入案件支払を更新します。</dt>    <dt>Validation</dt>   <dd>過去（現在月 > 購入案件の支給月） の場合、400BadRequest。</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutPurchasePotentialCustomer
     * @param {number} purchasePotentialCustomerId
     * @param {PutPurchasePotentialCustomerRequest} putPurchasePotentialCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putPurchasePotentialCustomer(
      purchasePotentialCustomerId: number,
      putPurchasePotentialCustomerRequest: PutPurchasePotentialCustomerRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putPurchasePotentialCustomer(
          purchasePotentialCustomerId,
          putPurchasePotentialCustomerRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PurchasePotentialCustomerApi - object-oriented interface
 * @export
 * @class PurchasePotentialCustomerApi
 * @extends {BaseAPI}
 */
export class PurchasePotentialCustomerApi extends BaseAPI {
  /**
   * <dl>    <dt>購入案件支払を新規登録します。</dt>    <dt>Validation</dt>   <dd>過去（現在月 > 購入案件の支給月） の場合、400BadRequest。</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PostPurchasePotentialCustomer
   * @param {PostPurchasePotentialCustomerRequest} postPurchasePotentialCustomerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PurchasePotentialCustomerApi
   */
  public postPurchasePotentialCustomer(
    postPurchasePotentialCustomerRequest: PostPurchasePotentialCustomerRequest,
    options?: AxiosRequestConfig
  ) {
    return PurchasePotentialCustomerApiFp(this.configuration)
      .postPurchasePotentialCustomer(
        postPurchasePotentialCustomerRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>購入案件支払を更新します。</dt>    <dt>Validation</dt>   <dd>過去（現在月 > 購入案件の支給月） の場合、400BadRequest。</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PutPurchasePotentialCustomer
   * @param {number} purchasePotentialCustomerId
   * @param {PutPurchasePotentialCustomerRequest} putPurchasePotentialCustomerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PurchasePotentialCustomerApi
   */
  public putPurchasePotentialCustomer(
    purchasePotentialCustomerId: number,
    putPurchasePotentialCustomerRequest: PutPurchasePotentialCustomerRequest,
    options?: AxiosRequestConfig
  ) {
    return PurchasePotentialCustomerApiFp(this.configuration)
      .putPurchasePotentialCustomer(
        purchasePotentialCustomerId,
        putPurchasePotentialCustomerRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PurchasePotentialCustomerQueryApi - axios parameter creator
 * @export
 */
export const PurchasePotentialCustomerQueryApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>queryに基づき購入案件支払を取得します。</dt>    <dt>取得結果のソート順</dt>   <dd>支給月の降順（未設定が先頭）</dd>   <dd>マーケットの表示順の昇順</dd>   <dd>staffIdの昇順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>    <dt>Validation</dt>   <dd>marketIdかstaffIdのいずれかが設定されていない場合は400BadRequest</dd>   </dl>
     * @summary GetPurchasePotentialCustomers
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {number} [marketId] マーケットID
     * @param {number} [potentialCustomerStaffId] 案件の募集人ID
     * @param {number} [staffId] 購入案件の募集人ID
     * @param {string} [purchasePotentialCustomerMonth] 購入案件の支給月
     * @param {boolean} [purchasePotentialCustomerScopeFlag] true：購入案件対象外フラグfalseのみ検索、false：購入案件対象外フラグは検索条件にしない
     * @param {boolean} [purchasePotentialCustomerMonthNotSetFlag] true：支給月未設定のみ検索、false：支給月の設定・未設定は検索条件にしない
     * @param {boolean} [potentialCustomerIdSetFlag] true：案件ID設定ありのみ検索、false：案件IDの設定・未設定は検索条件にしない
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPurchasePotentialCustomers: async (
      page: number,
      numberPerPage: number,
      marketId?: number,
      potentialCustomerStaffId?: number,
      staffId?: number,
      purchasePotentialCustomerMonth?: string,
      purchasePotentialCustomerScopeFlag?: boolean,
      purchasePotentialCustomerMonthNotSetFlag?: boolean,
      potentialCustomerIdSetFlag?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getPurchasePotentialCustomers", "page", page);
      // verify required parameter 'numberPerPage' is not null or undefined
      assertParamExists(
        "getPurchasePotentialCustomers",
        "numberPerPage",
        numberPerPage
      );
      const localVarPath = `/purchasePotentialCustomer`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (marketId !== undefined) {
        localVarQueryParameter["marketId"] = marketId;
      }

      if (potentialCustomerStaffId !== undefined) {
        localVarQueryParameter["potentialCustomerStaffId"] =
          potentialCustomerStaffId;
      }

      if (staffId !== undefined) {
        localVarQueryParameter["staffId"] = staffId;
      }

      if (purchasePotentialCustomerMonth !== undefined) {
        localVarQueryParameter["purchasePotentialCustomerMonth"] =
          purchasePotentialCustomerMonth;
      }

      if (purchasePotentialCustomerScopeFlag !== undefined) {
        localVarQueryParameter["purchasePotentialCustomerScopeFlag"] =
          purchasePotentialCustomerScopeFlag;
      }

      if (purchasePotentialCustomerMonthNotSetFlag !== undefined) {
        localVarQueryParameter["purchasePotentialCustomerMonthNotSetFlag"] =
          purchasePotentialCustomerMonthNotSetFlag;
      }

      if (potentialCustomerIdSetFlag !== undefined) {
        localVarQueryParameter["potentialCustomerIdSetFlag"] =
          potentialCustomerIdSetFlag;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberPerPage !== undefined) {
        localVarQueryParameter["numberPerPage"] = numberPerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PurchasePotentialCustomerQueryApi - functional programming interface
 * @export
 */
export const PurchasePotentialCustomerQueryApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    PurchasePotentialCustomerQueryApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき購入案件支払を取得します。</dt>    <dt>取得結果のソート順</dt>   <dd>支給月の降順（未設定が先頭）</dd>   <dd>マーケットの表示順の昇順</dd>   <dd>staffIdの昇順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>    <dt>Validation</dt>   <dd>marketIdかstaffIdのいずれかが設定されていない場合は400BadRequest</dd>   </dl>
     * @summary GetPurchasePotentialCustomers
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {number} [marketId] マーケットID
     * @param {number} [potentialCustomerStaffId] 案件の募集人ID
     * @param {number} [staffId] 購入案件の募集人ID
     * @param {string} [purchasePotentialCustomerMonth] 購入案件の支給月
     * @param {boolean} [purchasePotentialCustomerScopeFlag] true：購入案件対象外フラグfalseのみ検索、false：購入案件対象外フラグは検索条件にしない
     * @param {boolean} [purchasePotentialCustomerMonthNotSetFlag] true：支給月未設定のみ検索、false：支給月の設定・未設定は検索条件にしない
     * @param {boolean} [potentialCustomerIdSetFlag] true：案件ID設定ありのみ検索、false：案件IDの設定・未設定は検索条件にしない
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPurchasePotentialCustomers(
      page: number,
      numberPerPage: number,
      marketId?: number,
      potentialCustomerStaffId?: number,
      staffId?: number,
      purchasePotentialCustomerMonth?: string,
      purchasePotentialCustomerScopeFlag?: boolean,
      purchasePotentialCustomerMonthNotSetFlag?: boolean,
      potentialCustomerIdSetFlag?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetPurchasePotentialCustomers200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPurchasePotentialCustomers(
          page,
          numberPerPage,
          marketId,
          potentialCustomerStaffId,
          staffId,
          purchasePotentialCustomerMonth,
          purchasePotentialCustomerScopeFlag,
          purchasePotentialCustomerMonthNotSetFlag,
          potentialCustomerIdSetFlag,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * PurchasePotentialCustomerQueryApi - factory interface
 * @export
 */
export const PurchasePotentialCustomerQueryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PurchasePotentialCustomerQueryApiFp(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき購入案件支払を取得します。</dt>    <dt>取得結果のソート順</dt>   <dd>支給月の降順（未設定が先頭）</dd>   <dd>マーケットの表示順の昇順</dd>   <dd>staffIdの昇順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>    <dt>Validation</dt>   <dd>marketIdかstaffIdのいずれかが設定されていない場合は400BadRequest</dd>   </dl>
     * @summary GetPurchasePotentialCustomers
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {number} [marketId] マーケットID
     * @param {number} [potentialCustomerStaffId] 案件の募集人ID
     * @param {number} [staffId] 購入案件の募集人ID
     * @param {string} [purchasePotentialCustomerMonth] 購入案件の支給月
     * @param {boolean} [purchasePotentialCustomerScopeFlag] true：購入案件対象外フラグfalseのみ検索、false：購入案件対象外フラグは検索条件にしない
     * @param {boolean} [purchasePotentialCustomerMonthNotSetFlag] true：支給月未設定のみ検索、false：支給月の設定・未設定は検索条件にしない
     * @param {boolean} [potentialCustomerIdSetFlag] true：案件ID設定ありのみ検索、false：案件IDの設定・未設定は検索条件にしない
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPurchasePotentialCustomers(
      page: number,
      numberPerPage: number,
      marketId?: number,
      potentialCustomerStaffId?: number,
      staffId?: number,
      purchasePotentialCustomerMonth?: string,
      purchasePotentialCustomerScopeFlag?: boolean,
      purchasePotentialCustomerMonthNotSetFlag?: boolean,
      potentialCustomerIdSetFlag?: boolean,
      options?: any
    ): AxiosPromise<GetPurchasePotentialCustomers200Response> {
      return localVarFp
        .getPurchasePotentialCustomers(
          page,
          numberPerPage,
          marketId,
          potentialCustomerStaffId,
          staffId,
          purchasePotentialCustomerMonth,
          purchasePotentialCustomerScopeFlag,
          purchasePotentialCustomerMonthNotSetFlag,
          potentialCustomerIdSetFlag,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PurchasePotentialCustomerQueryApi - object-oriented interface
 * @export
 * @class PurchasePotentialCustomerQueryApi
 * @extends {BaseAPI}
 */
export class PurchasePotentialCustomerQueryApi extends BaseAPI {
  /**
   * <dl>    <dt>queryに基づき購入案件支払を取得します。</dt>    <dt>取得結果のソート順</dt>   <dd>支給月の降順（未設定が先頭）</dd>   <dd>マーケットの表示順の昇順</dd>   <dd>staffIdの昇順</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>権限がない場合は401を返却します</dd>    <dt>Validation</dt>   <dd>marketIdかstaffIdのいずれかが設定されていない場合は400BadRequest</dd>   </dl>
   * @summary GetPurchasePotentialCustomers
   * @param {number} page 取得したいpage
   * @param {number} numberPerPage 1ページあたりの検索結果数
   * @param {number} [marketId] マーケットID
   * @param {number} [potentialCustomerStaffId] 案件の募集人ID
   * @param {number} [staffId] 購入案件の募集人ID
   * @param {string} [purchasePotentialCustomerMonth] 購入案件の支給月
   * @param {boolean} [purchasePotentialCustomerScopeFlag] true：購入案件対象外フラグfalseのみ検索、false：購入案件対象外フラグは検索条件にしない
   * @param {boolean} [purchasePotentialCustomerMonthNotSetFlag] true：支給月未設定のみ検索、false：支給月の設定・未設定は検索条件にしない
   * @param {boolean} [potentialCustomerIdSetFlag] true：案件ID設定ありのみ検索、false：案件IDの設定・未設定は検索条件にしない
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PurchasePotentialCustomerQueryApi
   */
  public getPurchasePotentialCustomers(
    page: number,
    numberPerPage: number,
    marketId?: number,
    potentialCustomerStaffId?: number,
    staffId?: number,
    purchasePotentialCustomerMonth?: string,
    purchasePotentialCustomerScopeFlag?: boolean,
    purchasePotentialCustomerMonthNotSetFlag?: boolean,
    potentialCustomerIdSetFlag?: boolean,
    options?: AxiosRequestConfig
  ) {
    return PurchasePotentialCustomerQueryApiFp(this.configuration)
      .getPurchasePotentialCustomers(
        page,
        numberPerPage,
        marketId,
        potentialCustomerStaffId,
        staffId,
        purchasePotentialCustomerMonth,
        purchasePotentialCustomerScopeFlag,
        purchasePotentialCustomerMonthNotSetFlag,
        potentialCustomerIdSetFlag,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SecuritiesApi - axios parameter creator
 * @export
 */
export const SecuritiesApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>証券を削除します。</dt>   <dt>該当の証券IDが設定されている手数料テーブルの証券IDをクリアします。</dt>      <dt>Validation</dt>   <dd>該当の証券IDに紐づく手数料計算結果テーブルが存在する場合、400BadRequest</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に削除可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>削除不可な場合は401を返却します</dd>  </dl>
     * @summary DeleteSecurities
     * @param {number} securitiesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSecurities: async (
      securitiesId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'securitiesId' is not null or undefined
      assertParamExists("deleteSecurities", "securitiesId", securitiesId);
      const localVarPath = `/securities/{securitiesId}`.replace(
        `{${"securitiesId"}}`,
        encodeURIComponent(String(securitiesId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>証券を取得します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>権限により取得できない場合、取得結果がない場合は404を返却します</dd>  </dl>
     * @summary GetSecurities
     * @param {number} securitiesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecurities: async (
      securitiesId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'securitiesId' is not null or undefined
      assertParamExists("getSecurities", "securitiesId", securitiesId);
      const localVarPath = `/securities/{securitiesId}`.replace(
        `{${"securitiesId"}}`,
        encodeURIComponent(String(securitiesId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>証券を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Validation</dt>   <dd>     <ul>       <li>保険会社ID、証券番号の組み合わせが既に証券テーブルに存在する場合は400BadRequest</li>     </ul>   </dd>    <dt>手数料テーブルの紐づけ</dt>   <dd>保険会社ID、証券番号が合致する手数料レコードに証券IDを設定する</dd>    <dt>firstPaymentMonthの設定</dt>   <dd>最新の支給月を設定します</dd>   <dd>commission_import_status テーブルの MAX(commission_import_month) の値</dd>  </dl>
     * @summary PostSecurities
     * @param {PostSecuritiesRequest} postSecuritiesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSecurities: async (
      postSecuritiesRequest: PostSecuritiesRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postSecuritiesRequest' is not null or undefined
      assertParamExists(
        "postSecurities",
        "postSecuritiesRequest",
        postSecuritiesRequest
      );
      const localVarPath = `/securities`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postSecuritiesRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>証券を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutSecurities
     * @param {number} securitiesId
     * @param {PutSecuritiesRequest} putSecuritiesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putSecurities: async (
      securitiesId: number,
      putSecuritiesRequest: PutSecuritiesRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'securitiesId' is not null or undefined
      assertParamExists("putSecurities", "securitiesId", securitiesId);
      // verify required parameter 'putSecuritiesRequest' is not null or undefined
      assertParamExists(
        "putSecurities",
        "putSecuritiesRequest",
        putSecuritiesRequest
      );
      const localVarPath = `/securities/{securitiesId}`.replace(
        `{${"securitiesId"}}`,
        encodeURIComponent(String(securitiesId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        putSecuritiesRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SecuritiesApi - functional programming interface
 * @export
 */
export const SecuritiesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SecuritiesApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>証券を削除します。</dt>   <dt>該当の証券IDが設定されている手数料テーブルの証券IDをクリアします。</dt>      <dt>Validation</dt>   <dd>該当の証券IDに紐づく手数料計算結果テーブルが存在する場合、400BadRequest</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に削除可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>削除不可な場合は401を返却します</dd>  </dl>
     * @summary DeleteSecurities
     * @param {number} securitiesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSecurities(
      securitiesId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteSecurities(securitiesId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>証券を取得します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>権限により取得できない場合、取得結果がない場合は404を返却します</dd>  </dl>
     * @summary GetSecurities
     * @param {number} securitiesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSecurities(
      securitiesId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetSecurities200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSecurities(
        securitiesId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>証券を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Validation</dt>   <dd>     <ul>       <li>保険会社ID、証券番号の組み合わせが既に証券テーブルに存在する場合は400BadRequest</li>     </ul>   </dd>    <dt>手数料テーブルの紐づけ</dt>   <dd>保険会社ID、証券番号が合致する手数料レコードに証券IDを設定する</dd>    <dt>firstPaymentMonthの設定</dt>   <dd>最新の支給月を設定します</dd>   <dd>commission_import_status テーブルの MAX(commission_import_month) の値</dd>  </dl>
     * @summary PostSecurities
     * @param {PostSecuritiesRequest} postSecuritiesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postSecurities(
      postSecuritiesRequest: PostSecuritiesRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postSecurities(
        postSecuritiesRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>証券を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutSecurities
     * @param {number} securitiesId
     * @param {PutSecuritiesRequest} putSecuritiesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putSecurities(
      securitiesId: number,
      putSecuritiesRequest: PutSecuritiesRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putSecurities(
        securitiesId,
        putSecuritiesRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * SecuritiesApi - factory interface
 * @export
 */
export const SecuritiesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SecuritiesApiFp(configuration);
  return {
    /**
     * <dl>    <dt>証券を削除します。</dt>   <dt>該当の証券IDが設定されている手数料テーブルの証券IDをクリアします。</dt>      <dt>Validation</dt>   <dd>該当の証券IDに紐づく手数料計算結果テーブルが存在する場合、400BadRequest</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に削除可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>削除不可な場合は401を返却します</dd>  </dl>
     * @summary DeleteSecurities
     * @param {number} securitiesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSecurities(securitiesId: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteSecurities(securitiesId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>証券を取得します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>権限により取得できない場合、取得結果がない場合は404を返却します</dd>  </dl>
     * @summary GetSecurities
     * @param {number} securitiesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecurities(
      securitiesId: number,
      options?: any
    ): AxiosPromise<GetSecurities200Response> {
      return localVarFp
        .getSecurities(securitiesId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>証券を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Validation</dt>   <dd>     <ul>       <li>保険会社ID、証券番号の組み合わせが既に証券テーブルに存在する場合は400BadRequest</li>     </ul>   </dd>    <dt>手数料テーブルの紐づけ</dt>   <dd>保険会社ID、証券番号が合致する手数料レコードに証券IDを設定する</dd>    <dt>firstPaymentMonthの設定</dt>   <dd>最新の支給月を設定します</dd>   <dd>commission_import_status テーブルの MAX(commission_import_month) の値</dd>  </dl>
     * @summary PostSecurities
     * @param {PostSecuritiesRequest} postSecuritiesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSecurities(
      postSecuritiesRequest: PostSecuritiesRequest,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .postSecurities(postSecuritiesRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>証券を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutSecurities
     * @param {number} securitiesId
     * @param {PutSecuritiesRequest} putSecuritiesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putSecurities(
      securitiesId: number,
      putSecuritiesRequest: PutSecuritiesRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putSecurities(securitiesId, putSecuritiesRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SecuritiesApi - object-oriented interface
 * @export
 * @class SecuritiesApi
 * @extends {BaseAPI}
 */
export class SecuritiesApi extends BaseAPI {
  /**
   * <dl>    <dt>証券を削除します。</dt>   <dt>該当の証券IDが設定されている手数料テーブルの証券IDをクリアします。</dt>      <dt>Validation</dt>   <dd>該当の証券IDに紐づく手数料計算結果テーブルが存在する場合、400BadRequest</dd>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に削除可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>削除不可な場合は401を返却します</dd>  </dl>
   * @summary DeleteSecurities
   * @param {number} securitiesId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecuritiesApi
   */
  public deleteSecurities(securitiesId: number, options?: AxiosRequestConfig) {
    return SecuritiesApiFp(this.configuration)
      .deleteSecurities(securitiesId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>証券を取得します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>       <li>担当募集人が自分</li>       <li>担当募集人の案件・申達・証券権限がある</li>     </ul>   </dd>   <dd>権限により取得できない場合、取得結果がない場合は404を返却します</dd>  </dl>
   * @summary GetSecurities
   * @param {number} securitiesId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecuritiesApi
   */
  public getSecurities(securitiesId: number, options?: AxiosRequestConfig) {
    return SecuritiesApiFp(this.configuration)
      .getSecurities(securitiesId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>証券を新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Validation</dt>   <dd>     <ul>       <li>保険会社ID、証券番号の組み合わせが既に証券テーブルに存在する場合は400BadRequest</li>     </ul>   </dd>    <dt>手数料テーブルの紐づけ</dt>   <dd>保険会社ID、証券番号が合致する手数料レコードに証券IDを設定する</dd>    <dt>firstPaymentMonthの設定</dt>   <dd>最新の支給月を設定します</dd>   <dd>commission_import_status テーブルの MAX(commission_import_month) の値</dd>  </dl>
   * @summary PostSecurities
   * @param {PostSecuritiesRequest} postSecuritiesRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecuritiesApi
   */
  public postSecurities(
    postSecuritiesRequest: PostSecuritiesRequest,
    options?: AxiosRequestConfig
  ) {
    return SecuritiesApiFp(this.configuration)
      .postSecurities(postSecuritiesRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>証券を更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に更新可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PutSecurities
   * @param {number} securitiesId
   * @param {PutSecuritiesRequest} putSecuritiesRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecuritiesApi
   */
  public putSecurities(
    securitiesId: number,
    putSecuritiesRequest: PutSecuritiesRequest,
    options?: AxiosRequestConfig
  ) {
    return SecuritiesApiFp(this.configuration)
      .putSecurities(securitiesId, putSecuritiesRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SecuritiesQueryApi - axios parameter creator
 * @export
 */
export const SecuritiesQueryApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>queryに基づき証券担当者取分変更CSVを取得します。</dt>   <dd>queryはGetSecuritiesListAPIと同じ。</dd>   <dt>証券担当者取分変更CSVエクスポート</dt>   <dd>ソート順：保険会社の表示順、保険種類の表示順、証券番号の順</dd>   <dd>CSV仕様参照</dd>      <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary GetSecuritiesCsv
     * @param {ContractorAttribute} contractorAttribute 契約者の属性
     * @param {InsuranceCategory} [insuranceCategory] 保険カテゴリー
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [staffId] 募集人ID
     * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の証券を検索。
     * @param {string} [policyNumber] 証券番号
     * @param {number} [insuranceCompanyId] 保険会社ID
     * @param {number} [marketId] マーケットID
     * @param {number} [shareStaffId] &lt;dl&gt;    &lt;dt&gt;取分募集人ID&lt;/dt&gt;   &lt;dd&gt;手数料取分、インセンティブ取分にstaffIdが設定されているレコードを検索します&lt;/dd&gt;   &lt;dd&gt;staffIdが担当者、所属長、その他募集人１～３のいずれかに該当するレコードがこれにあたります&lt;/dd&gt;  &lt;/dl&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecuritiesCsv: async (
      contractorAttribute: ContractorAttribute,
      insuranceCategory?: InsuranceCategory,
      keyword?: string,
      staffId?: number,
      departmentId?: number,
      policyNumber?: string,
      insuranceCompanyId?: number,
      marketId?: number,
      shareStaffId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'contractorAttribute' is not null or undefined
      assertParamExists(
        "getSecuritiesCsv",
        "contractorAttribute",
        contractorAttribute
      );
      const localVarPath = `/securities/csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (insuranceCategory !== undefined) {
        localVarQueryParameter["insuranceCategory"] = insuranceCategory;
      }

      if (contractorAttribute !== undefined) {
        localVarQueryParameter["contractorAttribute"] = contractorAttribute;
      }

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (staffId !== undefined) {
        localVarQueryParameter["staffId"] = staffId;
      }

      if (departmentId !== undefined) {
        localVarQueryParameter["departmentId"] = departmentId;
      }

      if (policyNumber !== undefined) {
        localVarQueryParameter["policyNumber"] = policyNumber;
      }

      if (insuranceCompanyId !== undefined) {
        localVarQueryParameter["insuranceCompanyId"] = insuranceCompanyId;
      }

      if (marketId !== undefined) {
        localVarQueryParameter["marketId"] = marketId;
      }

      if (shareStaffId !== undefined) {
        localVarQueryParameter["shareStaffId"] = shareStaffId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>queryに基づき証券を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>証券IDの降順</dd>    <dt>権限制御</dt>   <dd>authorizedFilterFlagがfalseの場合、権限制御なし</dd>   <dd>authorizedFilterFlagがtrueの場合、次のデータが取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の案件・申達・証券権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetSecuritiesList
     * @param {boolean} authorizedFilterFlag &lt;dl&gt;    &lt;dt&gt;詳細を参照する権限ありのRecordのみ取得するかを判定するフラグ。&lt;/dt&gt;      &lt;dt&gt;trueの場合は詳細参照する権限があるレコードのみを取得する&lt;/dt&gt;   &lt;dt&gt;詳細を参照する権限は次のとおり&lt;/dt&gt;   &lt;dd&gt;     &lt;ul&gt;       &lt;li&gt;ユーザ種別が管理者&lt;/li&gt;       &lt;li&gt;対象レコードの担当募集人が自分&lt;/li&gt;       &lt;li&gt;対象レコードの担当募集人の案件・申達・証券権限がある&lt;/li&gt;     &lt;/ul&gt;   &lt;/dd&gt;  &lt;/dl&gt;
     * @param {ContractorAttribute} contractorAttribute 契約者の属性
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {InsuranceCategory} [insuranceCategory] 保険カテゴリー
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [potentialCustomerId] 案件ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の証券を検索。
     * @param {string} [policyNumber] 証券番号
     * @param {number} [insuranceCompanyId] 保険会社ID
     * @param {number} [marketId] マーケットID
     * @param {number} [shareStaffId] &lt;dl&gt;    &lt;dt&gt;取分募集人ID&lt;/dt&gt;   &lt;dd&gt;手数料取分、インセンティブ取分にstaffIdが設定されているレコードを検索します&lt;/dd&gt;   &lt;dd&gt;staffIdが担当者、所属長、その他募集人１～３のいずれかに該当するレコードがこれにあたります&lt;/dd&gt;  &lt;/dl&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecuritiesList: async (
      authorizedFilterFlag: boolean,
      contractorAttribute: ContractorAttribute,
      page: number,
      numberPerPage: number,
      insuranceCategory?: InsuranceCategory,
      keyword?: string,
      potentialCustomerId?: number,
      staffId?: number,
      departmentId?: number,
      policyNumber?: string,
      insuranceCompanyId?: number,
      marketId?: number,
      shareStaffId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorizedFilterFlag' is not null or undefined
      assertParamExists(
        "getSecuritiesList",
        "authorizedFilterFlag",
        authorizedFilterFlag
      );
      // verify required parameter 'contractorAttribute' is not null or undefined
      assertParamExists(
        "getSecuritiesList",
        "contractorAttribute",
        contractorAttribute
      );
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getSecuritiesList", "page", page);
      // verify required parameter 'numberPerPage' is not null or undefined
      assertParamExists("getSecuritiesList", "numberPerPage", numberPerPage);
      const localVarPath = `/securities`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorizedFilterFlag !== undefined) {
        localVarQueryParameter["authorizedFilterFlag"] = authorizedFilterFlag;
      }

      if (insuranceCategory !== undefined) {
        localVarQueryParameter["insuranceCategory"] = insuranceCategory;
      }

      if (contractorAttribute !== undefined) {
        localVarQueryParameter["contractorAttribute"] = contractorAttribute;
      }

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (potentialCustomerId !== undefined) {
        localVarQueryParameter["potentialCustomerId"] = potentialCustomerId;
      }

      if (staffId !== undefined) {
        localVarQueryParameter["staffId"] = staffId;
      }

      if (departmentId !== undefined) {
        localVarQueryParameter["departmentId"] = departmentId;
      }

      if (policyNumber !== undefined) {
        localVarQueryParameter["policyNumber"] = policyNumber;
      }

      if (insuranceCompanyId !== undefined) {
        localVarQueryParameter["insuranceCompanyId"] = insuranceCompanyId;
      }

      if (marketId !== undefined) {
        localVarQueryParameter["marketId"] = marketId;
      }

      if (shareStaffId !== undefined) {
        localVarQueryParameter["shareStaffId"] = shareStaffId;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberPerPage !== undefined) {
        localVarQueryParameter["numberPerPage"] = numberPerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>手数料と申達報告から証券を新規登録します。</dt>   <dd>証券番号は手数料から、他の項目は申達報告から作成します。</dd>   <dd>作成した証券の証券IDを手数料の証券IDに設定します。</dd>    <dt>証券のfirstPaymentMonthの設定</dt>   <dd>最新の支給月を設定します</dd>   <dd>commission_import_status テーブルの MAX(commission_import_month) の値</dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合にinvalidです</dd>   <dd>     <ul>       <li>既に手数料の保険会社ID・証券番号で証券が作成されている</li>     </ul>   </dd>   <dd>invalidな場合は400を返却します</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostSecuritiesCommissionMatching
     * @param {PostSecuritiesCommissionMatchingRequest} postSecuritiesCommissionMatchingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSecuritiesCommissionMatching: async (
      postSecuritiesCommissionMatchingRequest: PostSecuritiesCommissionMatchingRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postSecuritiesCommissionMatchingRequest' is not null or undefined
      assertParamExists(
        "postSecuritiesCommissionMatching",
        "postSecuritiesCommissionMatchingRequest",
        postSecuritiesCommissionMatchingRequest
      );
      const localVarPath = `/securities/commissionMatching`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postSecuritiesCommissionMatchingRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>証券CSVで証券を登録します。</dt>   <dt>作成した証券の証券IDを手数料の証券IDに設定します。</dt>    <dt>証券のfirstPaymentMonthの設定</dt>   <dd>最新の支給月を設定します</dd>   <dd>commission_import_status テーブルの MAX(commission_import_month) の値</dd>    <dt>証券CSVインポート</dt>   <dd>CSV仕様参照</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary PostSecuritiesCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSecuritiesCsv: async (
      file: File,
      filename?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("postSecuritiesCsv", "file", file);
      const localVarPath = `/securities/csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (filename !== undefined) {
        localVarFormParams.append("filename", filename as any);
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>証券担当者取分変更CSVで証券を更新します。</dt>   <dt>証券担当者取分変更CSVインポート</dt>   <dd>CSV仕様参照</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary PostSecuritiesShareCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSecuritiesShareCsv: async (
      file: File,
      filename?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("postSecuritiesShareCsv", "file", file);
      const localVarPath = `/securities/share/csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (filename !== undefined) {
        localVarFormParams.append("filename", filename as any);
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>commissionImportIdに紐づく未マッチングの手数料と申達から証券を新規登録します。</dt>   <dd>証券の作成内容はPostSecuritiesCommissionMatchingと同じです。</dd>   <dd>作成した証券の証券IDを手数料の証券IDに設定します。</dd>    <dt>手数料の対象</dt>   <dd>commissionImportIdに紐づく未マッチングの手数料が対象</dd>    <dt>証券作成の判定</dt>   <dd>手数料の保険会社IDと証券番号から申達報告を取得し、申達報告が一意となる場合に証券を作成します</dd>   <dd>申達報告が取得できないor取得結果が複数件ある場合は、証券の作成はSKIPします</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutSecuritiesCommissionMatching
     * @param {number} commissionImportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putSecuritiesCommissionMatching: async (
      commissionImportId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'commissionImportId' is not null or undefined
      assertParamExists(
        "putSecuritiesCommissionMatching",
        "commissionImportId",
        commissionImportId
      );
      const localVarPath =
        `/securities/{commissionImportId}/commissionMatching`.replace(
          `{${"commissionImportId"}}`,
          encodeURIComponent(String(commissionImportId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SecuritiesQueryApi - functional programming interface
 * @export
 */
export const SecuritiesQueryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SecuritiesQueryApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき証券担当者取分変更CSVを取得します。</dt>   <dd>queryはGetSecuritiesListAPIと同じ。</dd>   <dt>証券担当者取分変更CSVエクスポート</dt>   <dd>ソート順：保険会社の表示順、保険種類の表示順、証券番号の順</dd>   <dd>CSV仕様参照</dd>      <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary GetSecuritiesCsv
     * @param {ContractorAttribute} contractorAttribute 契約者の属性
     * @param {InsuranceCategory} [insuranceCategory] 保険カテゴリー
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [staffId] 募集人ID
     * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の証券を検索。
     * @param {string} [policyNumber] 証券番号
     * @param {number} [insuranceCompanyId] 保険会社ID
     * @param {number} [marketId] マーケットID
     * @param {number} [shareStaffId] &lt;dl&gt;    &lt;dt&gt;取分募集人ID&lt;/dt&gt;   &lt;dd&gt;手数料取分、インセンティブ取分にstaffIdが設定されているレコードを検索します&lt;/dd&gt;   &lt;dd&gt;staffIdが担当者、所属長、その他募集人１～３のいずれかに該当するレコードがこれにあたります&lt;/dd&gt;  &lt;/dl&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSecuritiesCsv(
      contractorAttribute: ContractorAttribute,
      insuranceCategory?: InsuranceCategory,
      keyword?: string,
      staffId?: number,
      departmentId?: number,
      policyNumber?: string,
      insuranceCompanyId?: number,
      marketId?: number,
      shareStaffId?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSecuritiesCsv(
          contractorAttribute,
          insuranceCategory,
          keyword,
          staffId,
          departmentId,
          policyNumber,
          insuranceCompanyId,
          marketId,
          shareStaffId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>queryに基づき証券を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>証券IDの降順</dd>    <dt>権限制御</dt>   <dd>authorizedFilterFlagがfalseの場合、権限制御なし</dd>   <dd>authorizedFilterFlagがtrueの場合、次のデータが取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の案件・申達・証券権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetSecuritiesList
     * @param {boolean} authorizedFilterFlag &lt;dl&gt;    &lt;dt&gt;詳細を参照する権限ありのRecordのみ取得するかを判定するフラグ。&lt;/dt&gt;      &lt;dt&gt;trueの場合は詳細参照する権限があるレコードのみを取得する&lt;/dt&gt;   &lt;dt&gt;詳細を参照する権限は次のとおり&lt;/dt&gt;   &lt;dd&gt;     &lt;ul&gt;       &lt;li&gt;ユーザ種別が管理者&lt;/li&gt;       &lt;li&gt;対象レコードの担当募集人が自分&lt;/li&gt;       &lt;li&gt;対象レコードの担当募集人の案件・申達・証券権限がある&lt;/li&gt;     &lt;/ul&gt;   &lt;/dd&gt;  &lt;/dl&gt;
     * @param {ContractorAttribute} contractorAttribute 契約者の属性
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {InsuranceCategory} [insuranceCategory] 保険カテゴリー
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [potentialCustomerId] 案件ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の証券を検索。
     * @param {string} [policyNumber] 証券番号
     * @param {number} [insuranceCompanyId] 保険会社ID
     * @param {number} [marketId] マーケットID
     * @param {number} [shareStaffId] &lt;dl&gt;    &lt;dt&gt;取分募集人ID&lt;/dt&gt;   &lt;dd&gt;手数料取分、インセンティブ取分にstaffIdが設定されているレコードを検索します&lt;/dd&gt;   &lt;dd&gt;staffIdが担当者、所属長、その他募集人１～３のいずれかに該当するレコードがこれにあたります&lt;/dd&gt;  &lt;/dl&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSecuritiesList(
      authorizedFilterFlag: boolean,
      contractorAttribute: ContractorAttribute,
      page: number,
      numberPerPage: number,
      insuranceCategory?: InsuranceCategory,
      keyword?: string,
      potentialCustomerId?: number,
      staffId?: number,
      departmentId?: number,
      policyNumber?: string,
      insuranceCompanyId?: number,
      marketId?: number,
      shareStaffId?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetSecuritiesList200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSecuritiesList(
          authorizedFilterFlag,
          contractorAttribute,
          page,
          numberPerPage,
          insuranceCategory,
          keyword,
          potentialCustomerId,
          staffId,
          departmentId,
          policyNumber,
          insuranceCompanyId,
          marketId,
          shareStaffId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>手数料と申達報告から証券を新規登録します。</dt>   <dd>証券番号は手数料から、他の項目は申達報告から作成します。</dd>   <dd>作成した証券の証券IDを手数料の証券IDに設定します。</dd>    <dt>証券のfirstPaymentMonthの設定</dt>   <dd>最新の支給月を設定します</dd>   <dd>commission_import_status テーブルの MAX(commission_import_month) の値</dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合にinvalidです</dd>   <dd>     <ul>       <li>既に手数料の保険会社ID・証券番号で証券が作成されている</li>     </ul>   </dd>   <dd>invalidな場合は400を返却します</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostSecuritiesCommissionMatching
     * @param {PostSecuritiesCommissionMatchingRequest} postSecuritiesCommissionMatchingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postSecuritiesCommissionMatching(
      postSecuritiesCommissionMatchingRequest: PostSecuritiesCommissionMatchingRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postSecuritiesCommissionMatching(
          postSecuritiesCommissionMatchingRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>証券CSVで証券を登録します。</dt>   <dt>作成した証券の証券IDを手数料の証券IDに設定します。</dt>    <dt>証券のfirstPaymentMonthの設定</dt>   <dd>最新の支給月を設定します</dd>   <dd>commission_import_status テーブルの MAX(commission_import_month) の値</dd>    <dt>証券CSVインポート</dt>   <dd>CSV仕様参照</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary PostSecuritiesCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postSecuritiesCsv(
      file: File,
      filename?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PostAdjustmentDeductionIncentiveCsv200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postSecuritiesCsv(
          file,
          filename,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>証券担当者取分変更CSVで証券を更新します。</dt>   <dt>証券担当者取分変更CSVインポート</dt>   <dd>CSV仕様参照</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary PostSecuritiesShareCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postSecuritiesShareCsv(
      file: File,
      filename?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PostAdjustmentDeductionIncentiveCsv200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postSecuritiesShareCsv(
          file,
          filename,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>commissionImportIdに紐づく未マッチングの手数料と申達から証券を新規登録します。</dt>   <dd>証券の作成内容はPostSecuritiesCommissionMatchingと同じです。</dd>   <dd>作成した証券の証券IDを手数料の証券IDに設定します。</dd>    <dt>手数料の対象</dt>   <dd>commissionImportIdに紐づく未マッチングの手数料が対象</dd>    <dt>証券作成の判定</dt>   <dd>手数料の保険会社IDと証券番号から申達報告を取得し、申達報告が一意となる場合に証券を作成します</dd>   <dd>申達報告が取得できないor取得結果が複数件ある場合は、証券の作成はSKIPします</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutSecuritiesCommissionMatching
     * @param {number} commissionImportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putSecuritiesCommissionMatching(
      commissionImportId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PutSecuritiesCommissionMatching200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putSecuritiesCommissionMatching(
          commissionImportId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * SecuritiesQueryApi - factory interface
 * @export
 */
export const SecuritiesQueryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SecuritiesQueryApiFp(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき証券担当者取分変更CSVを取得します。</dt>   <dd>queryはGetSecuritiesListAPIと同じ。</dd>   <dt>証券担当者取分変更CSVエクスポート</dt>   <dd>ソート順：保険会社の表示順、保険種類の表示順、証券番号の順</dd>   <dd>CSV仕様参照</dd>      <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary GetSecuritiesCsv
     * @param {ContractorAttribute} contractorAttribute 契約者の属性
     * @param {InsuranceCategory} [insuranceCategory] 保険カテゴリー
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [staffId] 募集人ID
     * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の証券を検索。
     * @param {string} [policyNumber] 証券番号
     * @param {number} [insuranceCompanyId] 保険会社ID
     * @param {number} [marketId] マーケットID
     * @param {number} [shareStaffId] &lt;dl&gt;    &lt;dt&gt;取分募集人ID&lt;/dt&gt;   &lt;dd&gt;手数料取分、インセンティブ取分にstaffIdが設定されているレコードを検索します&lt;/dd&gt;   &lt;dd&gt;staffIdが担当者、所属長、その他募集人１～３のいずれかに該当するレコードがこれにあたります&lt;/dd&gt;  &lt;/dl&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecuritiesCsv(
      contractorAttribute: ContractorAttribute,
      insuranceCategory?: InsuranceCategory,
      keyword?: string,
      staffId?: number,
      departmentId?: number,
      policyNumber?: string,
      insuranceCompanyId?: number,
      marketId?: number,
      shareStaffId?: number,
      options?: any
    ): AxiosPromise<File> {
      return localVarFp
        .getSecuritiesCsv(
          contractorAttribute,
          insuranceCategory,
          keyword,
          staffId,
          departmentId,
          policyNumber,
          insuranceCompanyId,
          marketId,
          shareStaffId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>queryに基づき証券を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>証券IDの降順</dd>    <dt>権限制御</dt>   <dd>authorizedFilterFlagがfalseの場合、権限制御なし</dd>   <dd>authorizedFilterFlagがtrueの場合、次のデータが取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の案件・申達・証券権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
     * @summary GetSecuritiesList
     * @param {boolean} authorizedFilterFlag &lt;dl&gt;    &lt;dt&gt;詳細を参照する権限ありのRecordのみ取得するかを判定するフラグ。&lt;/dt&gt;      &lt;dt&gt;trueの場合は詳細参照する権限があるレコードのみを取得する&lt;/dt&gt;   &lt;dt&gt;詳細を参照する権限は次のとおり&lt;/dt&gt;   &lt;dd&gt;     &lt;ul&gt;       &lt;li&gt;ユーザ種別が管理者&lt;/li&gt;       &lt;li&gt;対象レコードの担当募集人が自分&lt;/li&gt;       &lt;li&gt;対象レコードの担当募集人の案件・申達・証券権限がある&lt;/li&gt;     &lt;/ul&gt;   &lt;/dd&gt;  &lt;/dl&gt;
     * @param {ContractorAttribute} contractorAttribute 契約者の属性
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {InsuranceCategory} [insuranceCategory] 保険カテゴリー
     * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} [potentialCustomerId] 案件ID
     * @param {number} [staffId] 募集人ID
     * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の証券を検索。
     * @param {string} [policyNumber] 証券番号
     * @param {number} [insuranceCompanyId] 保険会社ID
     * @param {number} [marketId] マーケットID
     * @param {number} [shareStaffId] &lt;dl&gt;    &lt;dt&gt;取分募集人ID&lt;/dt&gt;   &lt;dd&gt;手数料取分、インセンティブ取分にstaffIdが設定されているレコードを検索します&lt;/dd&gt;   &lt;dd&gt;staffIdが担当者、所属長、その他募集人１～３のいずれかに該当するレコードがこれにあたります&lt;/dd&gt;  &lt;/dl&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSecuritiesList(
      authorizedFilterFlag: boolean,
      contractorAttribute: ContractorAttribute,
      page: number,
      numberPerPage: number,
      insuranceCategory?: InsuranceCategory,
      keyword?: string,
      potentialCustomerId?: number,
      staffId?: number,
      departmentId?: number,
      policyNumber?: string,
      insuranceCompanyId?: number,
      marketId?: number,
      shareStaffId?: number,
      options?: any
    ): AxiosPromise<GetSecuritiesList200Response> {
      return localVarFp
        .getSecuritiesList(
          authorizedFilterFlag,
          contractorAttribute,
          page,
          numberPerPage,
          insuranceCategory,
          keyword,
          potentialCustomerId,
          staffId,
          departmentId,
          policyNumber,
          insuranceCompanyId,
          marketId,
          shareStaffId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>手数料と申達報告から証券を新規登録します。</dt>   <dd>証券番号は手数料から、他の項目は申達報告から作成します。</dd>   <dd>作成した証券の証券IDを手数料の証券IDに設定します。</dd>    <dt>証券のfirstPaymentMonthの設定</dt>   <dd>最新の支給月を設定します</dd>   <dd>commission_import_status テーブルの MAX(commission_import_month) の値</dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合にinvalidです</dd>   <dd>     <ul>       <li>既に手数料の保険会社ID・証券番号で証券が作成されている</li>     </ul>   </dd>   <dd>invalidな場合は400を返却します</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PostSecuritiesCommissionMatching
     * @param {PostSecuritiesCommissionMatchingRequest} postSecuritiesCommissionMatchingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSecuritiesCommissionMatching(
      postSecuritiesCommissionMatchingRequest: PostSecuritiesCommissionMatchingRequest,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .postSecuritiesCommissionMatching(
          postSecuritiesCommissionMatchingRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>証券CSVで証券を登録します。</dt>   <dt>作成した証券の証券IDを手数料の証券IDに設定します。</dt>    <dt>証券のfirstPaymentMonthの設定</dt>   <dd>最新の支給月を設定します</dd>   <dd>commission_import_status テーブルの MAX(commission_import_month) の値</dd>    <dt>証券CSVインポート</dt>   <dd>CSV仕様参照</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary PostSecuritiesCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSecuritiesCsv(
      file: File,
      filename?: string,
      options?: any
    ): AxiosPromise<PostAdjustmentDeductionIncentiveCsv200Response> {
      return localVarFp
        .postSecuritiesCsv(file, filename, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>証券担当者取分変更CSVで証券を更新します。</dt>   <dt>証券担当者取分変更CSVインポート</dt>   <dd>CSV仕様参照</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary PostSecuritiesShareCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSecuritiesShareCsv(
      file: File,
      filename?: string,
      options?: any
    ): AxiosPromise<PostAdjustmentDeductionIncentiveCsv200Response> {
      return localVarFp
        .postSecuritiesShareCsv(file, filename, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>commissionImportIdに紐づく未マッチングの手数料と申達から証券を新規登録します。</dt>   <dd>証券の作成内容はPostSecuritiesCommissionMatchingと同じです。</dd>   <dd>作成した証券の証券IDを手数料の証券IDに設定します。</dd>    <dt>手数料の対象</dt>   <dd>commissionImportIdに紐づく未マッチングの手数料が対象</dd>    <dt>証券作成の判定</dt>   <dd>手数料の保険会社IDと証券番号から申達報告を取得し、申達報告が一意となる場合に証券を作成します</dd>   <dd>申達報告が取得できないor取得結果が複数件ある場合は、証券の作成はSKIPします</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutSecuritiesCommissionMatching
     * @param {number} commissionImportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putSecuritiesCommissionMatching(
      commissionImportId: number,
      options?: any
    ): AxiosPromise<PutSecuritiesCommissionMatching200Response> {
      return localVarFp
        .putSecuritiesCommissionMatching(commissionImportId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SecuritiesQueryApi - object-oriented interface
 * @export
 * @class SecuritiesQueryApi
 * @extends {BaseAPI}
 */
export class SecuritiesQueryApi extends BaseAPI {
  /**
   * <dl>    <dt>queryに基づき証券担当者取分変更CSVを取得します。</dt>   <dd>queryはGetSecuritiesListAPIと同じ。</dd>   <dt>証券担当者取分変更CSVエクスポート</dt>   <dd>ソート順：保険会社の表示順、保険種類の表示順、証券番号の順</dd>   <dd>CSV仕様参照</dd>      <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
   * @summary GetSecuritiesCsv
   * @param {ContractorAttribute} contractorAttribute 契約者の属性
   * @param {InsuranceCategory} [insuranceCategory] 保険カテゴリー
   * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
   * @param {number} [staffId] 募集人ID
   * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の証券を検索。
   * @param {string} [policyNumber] 証券番号
   * @param {number} [insuranceCompanyId] 保険会社ID
   * @param {number} [marketId] マーケットID
   * @param {number} [shareStaffId] &lt;dl&gt;    &lt;dt&gt;取分募集人ID&lt;/dt&gt;   &lt;dd&gt;手数料取分、インセンティブ取分にstaffIdが設定されているレコードを検索します&lt;/dd&gt;   &lt;dd&gt;staffIdが担当者、所属長、その他募集人１～３のいずれかに該当するレコードがこれにあたります&lt;/dd&gt;  &lt;/dl&gt;
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecuritiesQueryApi
   */
  public getSecuritiesCsv(
    contractorAttribute: ContractorAttribute,
    insuranceCategory?: InsuranceCategory,
    keyword?: string,
    staffId?: number,
    departmentId?: number,
    policyNumber?: string,
    insuranceCompanyId?: number,
    marketId?: number,
    shareStaffId?: number,
    options?: AxiosRequestConfig
  ) {
    return SecuritiesQueryApiFp(this.configuration)
      .getSecuritiesCsv(
        contractorAttribute,
        insuranceCategory,
        keyword,
        staffId,
        departmentId,
        policyNumber,
        insuranceCompanyId,
        marketId,
        shareStaffId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>queryに基づき証券を取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>証券IDの降順</dd>    <dt>権限制御</dt>   <dd>authorizedFilterFlagがfalseの場合、権限制御なし</dd>   <dd>authorizedFilterFlagがtrueの場合、次のデータが取得可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は制限なく取得可能</li>       <li>ユーザ種別が管理者以外の場合は、staffIdが自分 または 自分の案件・申達・証券権限のある所属に在籍するstaffId のデータのみ取得可</li>     </ul>   </dd>  </dl>
   * @summary GetSecuritiesList
   * @param {boolean} authorizedFilterFlag &lt;dl&gt;    &lt;dt&gt;詳細を参照する権限ありのRecordのみ取得するかを判定するフラグ。&lt;/dt&gt;      &lt;dt&gt;trueの場合は詳細参照する権限があるレコードのみを取得する&lt;/dt&gt;   &lt;dt&gt;詳細を参照する権限は次のとおり&lt;/dt&gt;   &lt;dd&gt;     &lt;ul&gt;       &lt;li&gt;ユーザ種別が管理者&lt;/li&gt;       &lt;li&gt;対象レコードの担当募集人が自分&lt;/li&gt;       &lt;li&gt;対象レコードの担当募集人の案件・申達・証券権限がある&lt;/li&gt;     &lt;/ul&gt;   &lt;/dd&gt;  &lt;/dl&gt;
   * @param {ContractorAttribute} contractorAttribute 契約者の属性
   * @param {number} page 取得したいpage
   * @param {number} numberPerPage 1ページあたりの検索結果数
   * @param {InsuranceCategory} [insuranceCategory] 保険カテゴリー
   * @param {string} [keyword] &lt;dl&gt;    &lt;dt&gt;契約者・被保険者の名称検索のキーワード&lt;/dt&gt;      &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象者&lt;/dt&gt;   &lt;dd&gt;契約者&lt;/dd&gt;   &lt;dd&gt;被保険者&lt;/dd&gt;    &lt;dt&gt;キーワード検索対象の項目&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;法人名の部分一致&lt;/dd&gt;  &lt;/dl&gt;
   * @param {number} [potentialCustomerId] 案件ID
   * @param {number} [staffId] 募集人ID
   * @param {number} [departmentId] 所属ID。所属に現在在籍する募集人が担当の証券を検索。
   * @param {string} [policyNumber] 証券番号
   * @param {number} [insuranceCompanyId] 保険会社ID
   * @param {number} [marketId] マーケットID
   * @param {number} [shareStaffId] &lt;dl&gt;    &lt;dt&gt;取分募集人ID&lt;/dt&gt;   &lt;dd&gt;手数料取分、インセンティブ取分にstaffIdが設定されているレコードを検索します&lt;/dd&gt;   &lt;dd&gt;staffIdが担当者、所属長、その他募集人１～３のいずれかに該当するレコードがこれにあたります&lt;/dd&gt;  &lt;/dl&gt;
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecuritiesQueryApi
   */
  public getSecuritiesList(
    authorizedFilterFlag: boolean,
    contractorAttribute: ContractorAttribute,
    page: number,
    numberPerPage: number,
    insuranceCategory?: InsuranceCategory,
    keyword?: string,
    potentialCustomerId?: number,
    staffId?: number,
    departmentId?: number,
    policyNumber?: string,
    insuranceCompanyId?: number,
    marketId?: number,
    shareStaffId?: number,
    options?: AxiosRequestConfig
  ) {
    return SecuritiesQueryApiFp(this.configuration)
      .getSecuritiesList(
        authorizedFilterFlag,
        contractorAttribute,
        page,
        numberPerPage,
        insuranceCategory,
        keyword,
        potentialCustomerId,
        staffId,
        departmentId,
        policyNumber,
        insuranceCompanyId,
        marketId,
        shareStaffId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>手数料と申達報告から証券を新規登録します。</dt>   <dd>証券番号は手数料から、他の項目は申達報告から作成します。</dd>   <dd>作成した証券の証券IDを手数料の証券IDに設定します。</dd>    <dt>証券のfirstPaymentMonthの設定</dt>   <dd>最新の支給月を設定します</dd>   <dd>commission_import_status テーブルの MAX(commission_import_month) の値</dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合にinvalidです</dd>   <dd>     <ul>       <li>既に手数料の保険会社ID・証券番号で証券が作成されている</li>     </ul>   </dd>   <dd>invalidな場合は400を返却します</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PostSecuritiesCommissionMatching
   * @param {PostSecuritiesCommissionMatchingRequest} postSecuritiesCommissionMatchingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecuritiesQueryApi
   */
  public postSecuritiesCommissionMatching(
    postSecuritiesCommissionMatchingRequest: PostSecuritiesCommissionMatchingRequest,
    options?: AxiosRequestConfig
  ) {
    return SecuritiesQueryApiFp(this.configuration)
      .postSecuritiesCommissionMatching(
        postSecuritiesCommissionMatchingRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>証券CSVで証券を登録します。</dt>   <dt>作成した証券の証券IDを手数料の証券IDに設定します。</dt>    <dt>証券のfirstPaymentMonthの設定</dt>   <dd>最新の支給月を設定します</dd>   <dd>commission_import_status テーブルの MAX(commission_import_month) の値</dd>    <dt>証券CSVインポート</dt>   <dd>CSV仕様参照</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
   * @summary PostSecuritiesCsv
   * @param {File} file
   * @param {string} [filename]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecuritiesQueryApi
   */
  public postSecuritiesCsv(
    file: File,
    filename?: string,
    options?: AxiosRequestConfig
  ) {
    return SecuritiesQueryApiFp(this.configuration)
      .postSecuritiesCsv(file, filename, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>証券担当者取分変更CSVで証券を更新します。</dt>   <dt>証券担当者取分変更CSVインポート</dt>   <dd>CSV仕様参照</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
   * @summary PostSecuritiesShareCsv
   * @param {File} file
   * @param {string} [filename]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecuritiesQueryApi
   */
  public postSecuritiesShareCsv(
    file: File,
    filename?: string,
    options?: AxiosRequestConfig
  ) {
    return SecuritiesQueryApiFp(this.configuration)
      .postSecuritiesShareCsv(file, filename, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>commissionImportIdに紐づく未マッチングの手数料と申達から証券を新規登録します。</dt>   <dd>証券の作成内容はPostSecuritiesCommissionMatchingと同じです。</dd>   <dd>作成した証券の証券IDを手数料の証券IDに設定します。</dd>    <dt>手数料の対象</dt>   <dd>commissionImportIdに紐づく未マッチングの手数料が対象</dd>    <dt>証券作成の判定</dt>   <dd>手数料の保険会社IDと証券番号から申達報告を取得し、申達報告が一意となる場合に証券を作成します</dd>   <dd>申達報告が取得できないor取得結果が複数件ある場合は、証券の作成はSKIPします</dd>    <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PutSecuritiesCommissionMatching
   * @param {number} commissionImportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecuritiesQueryApi
   */
  public putSecuritiesCommissionMatching(
    commissionImportId: number,
    options?: AxiosRequestConfig
  ) {
    return SecuritiesQueryApiFp(this.configuration)
      .putSecuritiesCommissionMatching(commissionImportId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * StaffApi - axios parameter creator
 * @export
 */
export const StaffApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>ユーザを取得します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>   <dd>取得結果が無い場合は404を返却します</dd>  </dl>
     * @summary GetStaff
     * @param {number} staffId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStaff: async (
      staffId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffId' is not null or undefined
      assertParamExists("getStaff", "staffId", staffId);
      const localVarPath = `/staff/{staffId}`.replace(
        `{${"staffId"}}`,
        encodeURIComponent(String(staffId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>ユーザを新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Cognitoにメールアドレス1、パスワードを連携してユーザ登録します</dt>  </dl>
     * @summary PostStaff
     * @param {PostStaffRequest} postStaffRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postStaff: async (
      postStaffRequest: PostStaffRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postStaffRequest' is not null or undefined
      assertParamExists("postStaff", "postStaffRequest", postStaffRequest);
      const localVarPath = `/staff`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postStaffRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>ユーザを更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutStaff
     * @param {number} staffId
     * @param {PutStaffRequest} putStaffRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putStaff: async (
      staffId: number,
      putStaffRequest: PutStaffRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffId' is not null or undefined
      assertParamExists("putStaff", "staffId", staffId);
      // verify required parameter 'putStaffRequest' is not null or undefined
      assertParamExists("putStaff", "putStaffRequest", putStaffRequest);
      const localVarPath = `/staff/{staffId}`.replace(
        `{${"staffId"}}`,
        encodeURIComponent(String(staffId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        putStaffRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>ユーザの有効・無効を変更します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Cognitoにユーザの有効・無効を連携します。</dt>    </dl>
     * @summary PutStaffAccountActive
     * @param {number} staffId
     * @param {PutStaffAccountActiveRequest} putStaffAccountActiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putStaffAccountActive: async (
      staffId: number,
      putStaffAccountActiveRequest: PutStaffAccountActiveRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffId' is not null or undefined
      assertParamExists("putStaffAccountActive", "staffId", staffId);
      // verify required parameter 'putStaffAccountActiveRequest' is not null or undefined
      assertParamExists(
        "putStaffAccountActive",
        "putStaffAccountActiveRequest",
        putStaffAccountActiveRequest
      );
      const localVarPath = `/staff/{staffId}/accountActive`.replace(
        `{${"staffId"}}`,
        encodeURIComponent(String(staffId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        putStaffAccountActiveRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>ユーザのパスワードを強制変更します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutStaffPassword
     * @param {number} staffId
     * @param {PutStaffPasswordRequest} putStaffPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putStaffPassword: async (
      staffId: number,
      putStaffPasswordRequest: PutStaffPasswordRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffId' is not null or undefined
      assertParamExists("putStaffPassword", "staffId", staffId);
      // verify required parameter 'putStaffPasswordRequest' is not null or undefined
      assertParamExists(
        "putStaffPassword",
        "putStaffPasswordRequest",
        putStaffPasswordRequest
      );
      const localVarPath = `/staff/{staffId}/password`.replace(
        `{${"staffId"}}`,
        encodeURIComponent(String(staffId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        putStaffPasswordRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StaffApi - functional programming interface
 * @export
 */
export const StaffApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = StaffApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>ユーザを取得します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>   <dd>取得結果が無い場合は404を返却します</dd>  </dl>
     * @summary GetStaff
     * @param {number} staffId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStaff(
      staffId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetStaff200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStaff(
        staffId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>ユーザを新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Cognitoにメールアドレス1、パスワードを連携してユーザ登録します</dt>  </dl>
     * @summary PostStaff
     * @param {PostStaffRequest} postStaffRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postStaff(
      postStaffRequest: PostStaffRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postStaff(
        postStaffRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>ユーザを更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutStaff
     * @param {number} staffId
     * @param {PutStaffRequest} putStaffRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putStaff(
      staffId: number,
      putStaffRequest: PutStaffRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putStaff(
        staffId,
        putStaffRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>ユーザの有効・無効を変更します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Cognitoにユーザの有効・無効を連携します。</dt>    </dl>
     * @summary PutStaffAccountActive
     * @param {number} staffId
     * @param {PutStaffAccountActiveRequest} putStaffAccountActiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putStaffAccountActive(
      staffId: number,
      putStaffAccountActiveRequest: PutStaffAccountActiveRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putStaffAccountActive(
          staffId,
          putStaffAccountActiveRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>ユーザのパスワードを強制変更します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutStaffPassword
     * @param {number} staffId
     * @param {PutStaffPasswordRequest} putStaffPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putStaffPassword(
      staffId: number,
      putStaffPasswordRequest: PutStaffPasswordRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putStaffPassword(
          staffId,
          putStaffPasswordRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * StaffApi - factory interface
 * @export
 */
export const StaffApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = StaffApiFp(configuration);
  return {
    /**
     * <dl>    <dt>ユーザを取得します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>   <dd>取得結果が無い場合は404を返却します</dd>  </dl>
     * @summary GetStaff
     * @param {number} staffId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStaff(
      staffId: number,
      options?: any
    ): AxiosPromise<GetStaff200Response> {
      return localVarFp
        .getStaff(staffId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>ユーザを新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Cognitoにメールアドレス1、パスワードを連携してユーザ登録します</dt>  </dl>
     * @summary PostStaff
     * @param {PostStaffRequest} postStaffRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postStaff(
      postStaffRequest: PostStaffRequest,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .postStaff(postStaffRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>ユーザを更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutStaff
     * @param {number} staffId
     * @param {PutStaffRequest} putStaffRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putStaff(
      staffId: number,
      putStaffRequest: PutStaffRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putStaff(staffId, putStaffRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>ユーザの有効・無効を変更します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Cognitoにユーザの有効・無効を連携します。</dt>    </dl>
     * @summary PutStaffAccountActive
     * @param {number} staffId
     * @param {PutStaffAccountActiveRequest} putStaffAccountActiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putStaffAccountActive(
      staffId: number,
      putStaffAccountActiveRequest: PutStaffAccountActiveRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putStaffAccountActive(staffId, putStaffAccountActiveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>ユーザのパスワードを強制変更します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutStaffPassword
     * @param {number} staffId
     * @param {PutStaffPasswordRequest} putStaffPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putStaffPassword(
      staffId: number,
      putStaffPasswordRequest: PutStaffPasswordRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putStaffPassword(staffId, putStaffPasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * StaffApi - object-oriented interface
 * @export
 * @class StaffApi
 * @extends {BaseAPI}
 */
export class StaffApi extends BaseAPI {
  /**
   * <dl>    <dt>ユーザを取得します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合にAPI実行可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>   <dd>取得結果が無い場合は404を返却します</dd>  </dl>
   * @summary GetStaff
   * @param {number} staffId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffApi
   */
  public getStaff(staffId: number, options?: AxiosRequestConfig) {
    return StaffApiFp(this.configuration)
      .getStaff(staffId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>ユーザを新規登録します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Cognitoにメールアドレス1、パスワードを連携してユーザ登録します</dt>  </dl>
   * @summary PostStaff
   * @param {PostStaffRequest} postStaffRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffApi
   */
  public postStaff(
    postStaffRequest: PostStaffRequest,
    options?: AxiosRequestConfig
  ) {
    return StaffApiFp(this.configuration)
      .postStaff(postStaffRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>ユーザを更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PutStaff
   * @param {number} staffId
   * @param {PutStaffRequest} putStaffRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffApi
   */
  public putStaff(
    staffId: number,
    putStaffRequest: PutStaffRequest,
    options?: AxiosRequestConfig
  ) {
    return StaffApiFp(this.configuration)
      .putStaff(staffId, putStaffRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>ユーザの有効・無効を変更します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>    <dt>Cognitoにユーザの有効・無効を連携します。</dt>    </dl>
   * @summary PutStaffAccountActive
   * @param {number} staffId
   * @param {PutStaffAccountActiveRequest} putStaffAccountActiveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffApi
   */
  public putStaffAccountActive(
    staffId: number,
    putStaffAccountActiveRequest: PutStaffAccountActiveRequest,
    options?: AxiosRequestConfig
  ) {
    return StaffApiFp(this.configuration)
      .putStaffAccountActive(staffId, putStaffAccountActiveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>ユーザのパスワードを強制変更します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PutStaffPassword
   * @param {number} staffId
   * @param {PutStaffPasswordRequest} putStaffPasswordRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffApi
   */
  public putStaffPassword(
    staffId: number,
    putStaffPasswordRequest: PutStaffPasswordRequest,
    options?: AxiosRequestConfig
  ) {
    return StaffApiFp(this.configuration)
      .putStaffPassword(staffId, putStaffPasswordRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * StaffMapApi - axios parameter creator
 * @export
 */
export const StaffMapApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>募集人MAPを削除します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary DeleteStaffMap
     * @param {number} staffId
     * @param {number} insuranceCompanyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStaffMap: async (
      staffId: number,
      insuranceCompanyId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffId' is not null or undefined
      assertParamExists("deleteStaffMap", "staffId", staffId);
      // verify required parameter 'insuranceCompanyId' is not null or undefined
      assertParamExists(
        "deleteStaffMap",
        "insuranceCompanyId",
        insuranceCompanyId
      );
      const localVarPath = `/staffMap/{staffId}/{insuranceCompanyId}`
        .replace(`{${"staffId"}}`, encodeURIComponent(String(staffId)))
        .replace(
          `{${"insuranceCompanyId"}}`,
          encodeURIComponent(String(insuranceCompanyId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>募集人MAPを登録・更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutStaffMap
     * @param {number} staffId
     * @param {number} insuranceCompanyId
     * @param {PutStaffMapRequest} putStaffMapRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putStaffMap: async (
      staffId: number,
      insuranceCompanyId: number,
      putStaffMapRequest: PutStaffMapRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffId' is not null or undefined
      assertParamExists("putStaffMap", "staffId", staffId);
      // verify required parameter 'insuranceCompanyId' is not null or undefined
      assertParamExists(
        "putStaffMap",
        "insuranceCompanyId",
        insuranceCompanyId
      );
      // verify required parameter 'putStaffMapRequest' is not null or undefined
      assertParamExists(
        "putStaffMap",
        "putStaffMapRequest",
        putStaffMapRequest
      );
      const localVarPath = `/staffMap/{staffId}/{insuranceCompanyId}`
        .replace(`{${"staffId"}}`, encodeURIComponent(String(staffId)))
        .replace(
          `{${"insuranceCompanyId"}}`,
          encodeURIComponent(String(insuranceCompanyId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        putStaffMapRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StaffMapApi - functional programming interface
 * @export
 */
export const StaffMapApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = StaffMapApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>募集人MAPを削除します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary DeleteStaffMap
     * @param {number} staffId
     * @param {number} insuranceCompanyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteStaffMap(
      staffId: number,
      insuranceCompanyId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStaffMap(
        staffId,
        insuranceCompanyId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>募集人MAPを登録・更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutStaffMap
     * @param {number} staffId
     * @param {number} insuranceCompanyId
     * @param {PutStaffMapRequest} putStaffMapRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putStaffMap(
      staffId: number,
      insuranceCompanyId: number,
      putStaffMapRequest: PutStaffMapRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putStaffMap(
        staffId,
        insuranceCompanyId,
        putStaffMapRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * StaffMapApi - factory interface
 * @export
 */
export const StaffMapApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = StaffMapApiFp(configuration);
  return {
    /**
     * <dl>    <dt>募集人MAPを削除します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary DeleteStaffMap
     * @param {number} staffId
     * @param {number} insuranceCompanyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStaffMap(
      staffId: number,
      insuranceCompanyId: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteStaffMap(staffId, insuranceCompanyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>募集人MAPを登録・更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
     * @summary PutStaffMap
     * @param {number} staffId
     * @param {number} insuranceCompanyId
     * @param {PutStaffMapRequest} putStaffMapRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putStaffMap(
      staffId: number,
      insuranceCompanyId: number,
      putStaffMapRequest: PutStaffMapRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putStaffMap(staffId, insuranceCompanyId, putStaffMapRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * StaffMapApi - object-oriented interface
 * @export
 * @class StaffMapApi
 * @extends {BaseAPI}
 */
export class StaffMapApi extends BaseAPI {
  /**
   * <dl>    <dt>募集人MAPを削除します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary DeleteStaffMap
   * @param {number} staffId
   * @param {number} insuranceCompanyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffMapApi
   */
  public deleteStaffMap(
    staffId: number,
    insuranceCompanyId: number,
    options?: AxiosRequestConfig
  ) {
    return StaffMapApiFp(this.configuration)
      .deleteStaffMap(staffId, insuranceCompanyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>募集人MAPを登録・更新します。</dt>      <dt>権限制御</dt>   <dd>次のいずれかに該当する場合に登録可能です</dd>   <dd>     <ul>       <li>ユーザ種別が管理者</li>     </ul>   </dd>   <dd>登録不可な場合は401を返却します</dd>  </dl>
   * @summary PutStaffMap
   * @param {number} staffId
   * @param {number} insuranceCompanyId
   * @param {PutStaffMapRequest} putStaffMapRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffMapApi
   */
  public putStaffMap(
    staffId: number,
    insuranceCompanyId: number,
    putStaffMapRequest: PutStaffMapRequest,
    options?: AxiosRequestConfig
  ) {
    return StaffMapApiFp(this.configuration)
      .putStaffMap(staffId, insuranceCompanyId, putStaffMapRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * StaffMapQueryApi - axios parameter creator
 * @export
 */
export const StaffMapQueryApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>queryに基づき募集人Mapを取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>保険会社の表示順、社員番号の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetStaffMaps
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {number} [insuranceCompanyId]
     * @param {number} [staffId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStaffMaps: async (
      page: number,
      numberPerPage: number,
      insuranceCompanyId?: number,
      staffId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getStaffMaps", "page", page);
      // verify required parameter 'numberPerPage' is not null or undefined
      assertParamExists("getStaffMaps", "numberPerPage", numberPerPage);
      const localVarPath = `/staffMap`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (insuranceCompanyId !== undefined) {
        localVarQueryParameter["insuranceCompanyId"] = insuranceCompanyId;
      }

      if (staffId !== undefined) {
        localVarQueryParameter["staffId"] = staffId;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberPerPage !== undefined) {
        localVarQueryParameter["numberPerPage"] = numberPerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StaffMapQueryApi - functional programming interface
 * @export
 */
export const StaffMapQueryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    StaffMapQueryApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき募集人Mapを取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>保険会社の表示順、社員番号の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetStaffMaps
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {number} [insuranceCompanyId]
     * @param {number} [staffId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStaffMaps(
      page: number,
      numberPerPage: number,
      insuranceCompanyId?: number,
      staffId?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetStaffMaps200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStaffMaps(
        page,
        numberPerPage,
        insuranceCompanyId,
        staffId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * StaffMapQueryApi - factory interface
 * @export
 */
export const StaffMapQueryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = StaffMapQueryApiFp(configuration);
  return {
    /**
     * <dl>    <dt>queryに基づき募集人Mapを取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>保険会社の表示順、社員番号の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetStaffMaps
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {number} [insuranceCompanyId]
     * @param {number} [staffId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStaffMaps(
      page: number,
      numberPerPage: number,
      insuranceCompanyId?: number,
      staffId?: number,
      options?: any
    ): AxiosPromise<GetStaffMaps200Response> {
      return localVarFp
        .getStaffMaps(page, numberPerPage, insuranceCompanyId, staffId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * StaffMapQueryApi - object-oriented interface
 * @export
 * @class StaffMapQueryApi
 * @extends {BaseAPI}
 */
export class StaffMapQueryApi extends BaseAPI {
  /**
   * <dl>    <dt>queryに基づき募集人Mapを取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>保険会社の表示順、社員番号の昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
   * @summary GetStaffMaps
   * @param {number} page 取得したいpage
   * @param {number} numberPerPage 1ページあたりの検索結果数
   * @param {number} [insuranceCompanyId]
   * @param {number} [staffId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffMapQueryApi
   */
  public getStaffMaps(
    page: number,
    numberPerPage: number,
    insuranceCompanyId?: number,
    staffId?: number,
    options?: AxiosRequestConfig
  ) {
    return StaffMapQueryApiFp(this.configuration)
      .getStaffMaps(page, numberPerPage, insuranceCompanyId, staffId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * StaffQueryApi - axios parameter creator
 * @export
 */
export const StaffQueryApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * <dl>    <dt>自分のユーザ情報を取得します。</dt>   <dd>ログイン中のユーザ情報を返却</dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合にinvalidです</dd>   <dd>     <ul>       <li>現在在籍する所属が存在しない</li>       <li>現在在籍する所属の所属長が存在しない</li>     </ul>   </dd>   <dd>invalidな場合は400BadRequestを返却します</dd>    <dt>権限制御</dt>   <dd>なし</dd>   <dt>取得結果がない場合は404を返却します</dt>  </dl>
     * @summary GetMeStaffCurrent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMeStaffCurrent: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/staff/me/current`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>現在のユーザ情報を取得します。</dt>      <dt>権限制御</dt>   <dd>なし</dd>   <dt>取得結果がない場合は404を返却します</dt>  </dl>
     * @summary GetStaffCurrent
     * @param {number} staffId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStaffCurrent: async (
      staffId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffId' is not null or undefined
      assertParamExists("getStaffCurrent", "staffId", staffId);
      const localVarPath = `/staff/{staffId}/current`.replace(
        `{${"staffId"}}`,
        encodeURIComponent(String(staffId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>queryに基づきユーザを取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>ユーザ姓カナの昇順</dd>   <dd>ユーザ名カナの昇順</dd>   <dd>ユーザIDの昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetStaffs
     * @param {string} keyword &lt;dl&gt;    &lt;dt&gt;ユーザを検索するキーワード&lt;/dt&gt;      &lt;dt&gt;下記の項目をキーワードで検索します&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;    &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {boolean} [accountActiveFlag] アカウント有効フラグ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStaffs: async (
      keyword: string,
      page: number,
      numberPerPage: number,
      accountActiveFlag?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'keyword' is not null or undefined
      assertParamExists("getStaffs", "keyword", keyword);
      // verify required parameter 'page' is not null or undefined
      assertParamExists("getStaffs", "page", page);
      // verify required parameter 'numberPerPage' is not null or undefined
      assertParamExists("getStaffs", "numberPerPage", numberPerPage);
      const localVarPath = `/staff`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (keyword !== undefined) {
        localVarQueryParameter["keyword"] = keyword;
      }

      if (accountActiveFlag !== undefined) {
        localVarQueryParameter["accountActiveFlag"] = accountActiveFlag;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (numberPerPage !== undefined) {
        localVarQueryParameter["numberPerPage"] = numberPerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <dl>    <dt>標準報酬月額のCSVでStaffの標準報酬月額の情報を更新します。</dt>    <dt>機能</dt>   <dd>     <ul>       <li>CSVのstaffNumberからstaffを特定</li>       <li>CSVの健康保険標準報酬月額でstaffのhealthInsuranceStandardMonthlyRemunerationを更新</li>       <li>CSVの厚生年金標準報酬月額でstaffのwelfarePensionStandardMonthlyRemunerationを更新</li>     </ul>   </dd>    <dt>Validation</dt>   <dd>     <ul>       <li>CSVのフォーマットが仕様通りでない場合エラー</li>       <li>社員番号に該当するstaffが存在しない場合エラー</li>     </ul>   </dd>   <dd>Validationエラー時は400BadRequestを返却します</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary PostStaffStandardMonthlyRemunerationCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postStaffStandardMonthlyRemunerationCsv: async (
      file: File,
      filename?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists(
        "postStaffStandardMonthlyRemunerationCsv",
        "file",
        file
      );
      const localVarPath = `/staff/standardMonthlyRemuneration/csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (filename !== undefined) {
        localVarFormParams.append("filename", filename as any);
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StaffQueryApi - functional programming interface
 * @export
 */
export const StaffQueryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    StaffQueryApiAxiosParamCreator(configuration);
  return {
    /**
     * <dl>    <dt>自分のユーザ情報を取得します。</dt>   <dd>ログイン中のユーザ情報を返却</dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合にinvalidです</dd>   <dd>     <ul>       <li>現在在籍する所属が存在しない</li>       <li>現在在籍する所属の所属長が存在しない</li>     </ul>   </dd>   <dd>invalidな場合は400BadRequestを返却します</dd>    <dt>権限制御</dt>   <dd>なし</dd>   <dt>取得結果がない場合は404を返却します</dt>  </dl>
     * @summary GetMeStaffCurrent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMeStaffCurrent(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetMeStaffCurrent200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMeStaffCurrent(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>現在のユーザ情報を取得します。</dt>      <dt>権限制御</dt>   <dd>なし</dd>   <dt>取得結果がない場合は404を返却します</dt>  </dl>
     * @summary GetStaffCurrent
     * @param {number} staffId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStaffCurrent(
      staffId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetStaffCurrent200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStaffCurrent(
        staffId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>queryに基づきユーザを取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>ユーザ姓カナの昇順</dd>   <dd>ユーザ名カナの昇順</dd>   <dd>ユーザIDの昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetStaffs
     * @param {string} keyword &lt;dl&gt;    &lt;dt&gt;ユーザを検索するキーワード&lt;/dt&gt;      &lt;dt&gt;下記の項目をキーワードで検索します&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;    &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {boolean} [accountActiveFlag] アカウント有効フラグ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStaffs(
      keyword: string,
      page: number,
      numberPerPage: number,
      accountActiveFlag?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetStaffs200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStaffs(
        keyword,
        page,
        numberPerPage,
        accountActiveFlag,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * <dl>    <dt>標準報酬月額のCSVでStaffの標準報酬月額の情報を更新します。</dt>    <dt>機能</dt>   <dd>     <ul>       <li>CSVのstaffNumberからstaffを特定</li>       <li>CSVの健康保険標準報酬月額でstaffのhealthInsuranceStandardMonthlyRemunerationを更新</li>       <li>CSVの厚生年金標準報酬月額でstaffのwelfarePensionStandardMonthlyRemunerationを更新</li>     </ul>   </dd>    <dt>Validation</dt>   <dd>     <ul>       <li>CSVのフォーマットが仕様通りでない場合エラー</li>       <li>社員番号に該当するstaffが存在しない場合エラー</li>     </ul>   </dd>   <dd>Validationエラー時は400BadRequestを返却します</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary PostStaffStandardMonthlyRemunerationCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postStaffStandardMonthlyRemunerationCsv(
      file: File,
      filename?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PostStaffStandardMonthlyRemunerationCsv200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postStaffStandardMonthlyRemunerationCsv(
          file,
          filename,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * StaffQueryApi - factory interface
 * @export
 */
export const StaffQueryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = StaffQueryApiFp(configuration);
  return {
    /**
     * <dl>    <dt>自分のユーザ情報を取得します。</dt>   <dd>ログイン中のユーザ情報を返却</dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合にinvalidです</dd>   <dd>     <ul>       <li>現在在籍する所属が存在しない</li>       <li>現在在籍する所属の所属長が存在しない</li>     </ul>   </dd>   <dd>invalidな場合は400BadRequestを返却します</dd>    <dt>権限制御</dt>   <dd>なし</dd>   <dt>取得結果がない場合は404を返却します</dt>  </dl>
     * @summary GetMeStaffCurrent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMeStaffCurrent(
      options?: any
    ): AxiosPromise<GetMeStaffCurrent200Response> {
      return localVarFp
        .getMeStaffCurrent(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>現在のユーザ情報を取得します。</dt>      <dt>権限制御</dt>   <dd>なし</dd>   <dt>取得結果がない場合は404を返却します</dt>  </dl>
     * @summary GetStaffCurrent
     * @param {number} staffId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStaffCurrent(
      staffId: number,
      options?: any
    ): AxiosPromise<GetStaffCurrent200Response> {
      return localVarFp
        .getStaffCurrent(staffId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>queryに基づきユーザを取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>ユーザ姓カナの昇順</dd>   <dd>ユーザ名カナの昇順</dd>   <dd>ユーザIDの昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
     * @summary GetStaffs
     * @param {string} keyword &lt;dl&gt;    &lt;dt&gt;ユーザを検索するキーワード&lt;/dt&gt;      &lt;dt&gt;下記の項目をキーワードで検索します&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;    &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;  &lt;/dl&gt;
     * @param {number} page 取得したいpage
     * @param {number} numberPerPage 1ページあたりの検索結果数
     * @param {boolean} [accountActiveFlag] アカウント有効フラグ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStaffs(
      keyword: string,
      page: number,
      numberPerPage: number,
      accountActiveFlag?: boolean,
      options?: any
    ): AxiosPromise<GetStaffs200Response> {
      return localVarFp
        .getStaffs(keyword, page, numberPerPage, accountActiveFlag, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * <dl>    <dt>標準報酬月額のCSVでStaffの標準報酬月額の情報を更新します。</dt>    <dt>機能</dt>   <dd>     <ul>       <li>CSVのstaffNumberからstaffを特定</li>       <li>CSVの健康保険標準報酬月額でstaffのhealthInsuranceStandardMonthlyRemunerationを更新</li>       <li>CSVの厚生年金標準報酬月額でstaffのwelfarePensionStandardMonthlyRemunerationを更新</li>     </ul>   </dd>    <dt>Validation</dt>   <dd>     <ul>       <li>CSVのフォーマットが仕様通りでない場合エラー</li>       <li>社員番号に該当するstaffが存在しない場合エラー</li>     </ul>   </dd>   <dd>Validationエラー時は400BadRequestを返却します</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
     * @summary PostStaffStandardMonthlyRemunerationCsv
     * @param {File} file
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postStaffStandardMonthlyRemunerationCsv(
      file: File,
      filename?: string,
      options?: any
    ): AxiosPromise<PostStaffStandardMonthlyRemunerationCsv200Response> {
      return localVarFp
        .postStaffStandardMonthlyRemunerationCsv(file, filename, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * StaffQueryApi - object-oriented interface
 * @export
 * @class StaffQueryApi
 * @extends {BaseAPI}
 */
export class StaffQueryApi extends BaseAPI {
  /**
   * <dl>    <dt>自分のユーザ情報を取得します。</dt>   <dd>ログイン中のユーザ情報を返却</dd>    <dt>Validation</dt>   <dd>次のいずれかに該当する場合にinvalidです</dd>   <dd>     <ul>       <li>現在在籍する所属が存在しない</li>       <li>現在在籍する所属の所属長が存在しない</li>     </ul>   </dd>   <dd>invalidな場合は400BadRequestを返却します</dd>    <dt>権限制御</dt>   <dd>なし</dd>   <dt>取得結果がない場合は404を返却します</dt>  </dl>
   * @summary GetMeStaffCurrent
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffQueryApi
   */
  public getMeStaffCurrent(options?: AxiosRequestConfig) {
    return StaffQueryApiFp(this.configuration)
      .getMeStaffCurrent(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>現在のユーザ情報を取得します。</dt>      <dt>権限制御</dt>   <dd>なし</dd>   <dt>取得結果がない場合は404を返却します</dt>  </dl>
   * @summary GetStaffCurrent
   * @param {number} staffId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffQueryApi
   */
  public getStaffCurrent(staffId: number, options?: AxiosRequestConfig) {
    return StaffQueryApiFp(this.configuration)
      .getStaffCurrent(staffId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>queryに基づきユーザを取得します。</dt>      <dt>取得結果のソート順</dt>   <dd>ユーザ姓カナの昇順</dd>   <dd>ユーザ名カナの昇順</dd>   <dd>ユーザIDの昇順</dd>    <dt>権限制御</dt>   <dd>なし</dd>  </dl>
   * @summary GetStaffs
   * @param {string} keyword &lt;dl&gt;    &lt;dt&gt;ユーザを検索するキーワード&lt;/dt&gt;      &lt;dt&gt;下記の項目をキーワードで検索します&lt;/dt&gt;   &lt;dd&gt;姓名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓名カナの前方一致&lt;/dd&gt;   &lt;dd&gt;姓漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;姓カナの前方一致&lt;/dd&gt;   &lt;dd&gt;名漢字の前方一致&lt;/dd&gt;   &lt;dd&gt;名カナの前方一致&lt;/dd&gt;    &lt;dt&gt;DBの保存値にあわせて、下記を対応して検索します&lt;/dt&gt;   &lt;dd&gt;スペースが含まれる場合は、スペースを取り除き検索します&lt;/dd&gt;   &lt;dd&gt;データストアにあわせ半角・全角を変換して検索します&lt;/dd&gt;  &lt;/dl&gt;
   * @param {number} page 取得したいpage
   * @param {number} numberPerPage 1ページあたりの検索結果数
   * @param {boolean} [accountActiveFlag] アカウント有効フラグ
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffQueryApi
   */
  public getStaffs(
    keyword: string,
    page: number,
    numberPerPage: number,
    accountActiveFlag?: boolean,
    options?: AxiosRequestConfig
  ) {
    return StaffQueryApiFp(this.configuration)
      .getStaffs(keyword, page, numberPerPage, accountActiveFlag, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <dl>    <dt>標準報酬月額のCSVでStaffの標準報酬月額の情報を更新します。</dt>    <dt>機能</dt>   <dd>     <ul>       <li>CSVのstaffNumberからstaffを特定</li>       <li>CSVの健康保険標準報酬月額でstaffのhealthInsuranceStandardMonthlyRemunerationを更新</li>       <li>CSVの厚生年金標準報酬月額でstaffのwelfarePensionStandardMonthlyRemunerationを更新</li>     </ul>   </dd>    <dt>Validation</dt>   <dd>     <ul>       <li>CSVのフォーマットが仕様通りでない場合エラー</li>       <li>社員番号に該当するstaffが存在しない場合エラー</li>     </ul>   </dd>   <dd>Validationエラー時は400BadRequestを返却します</dd>    <dt>権限制御</dt>   <dd>     <ul>       <li>ユーザ種別が管理者の場合は実行可能</li>     </ul>   </dd>   <dd>実行不可な場合は401を返却します</dd>    </dl>
   * @summary PostStaffStandardMonthlyRemunerationCsv
   * @param {File} file
   * @param {string} [filename]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffQueryApi
   */
  public postStaffStandardMonthlyRemunerationCsv(
    file: File,
    filename?: string,
    options?: AxiosRequestConfig
  ) {
    return StaffQueryApiFp(this.configuration)
      .postStaffStandardMonthlyRemunerationCsv(file, filename, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
