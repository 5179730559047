<script setup lang="ts">
const props = defineProps<{
  searchResults: Array<{
    staffId: number;
    staffNumber: string;
    lastName: string;
    firstName: string;
    staffFullName: string;
  }>;
}>();

const emits = defineEmits<{
  (e: "select-employee", staff: any): void;
}>();
</script>

<template>
  <ul v-if="searchResults.length" class="results-list">
    <li
      class="result-item"
      v-for="staff in searchResults"
      :key="staff.staffId"
      @click="emits('select-employee', staff)"
    >
      {{ staff.staffNumber }}: {{ staff.lastName }} {{ staff.firstName }}
    </li>
  </ul>
</template>

<style scoped>
.results-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.results-list li {
  padding: 12px 16px;
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.results-list li:last-child {
  border-bottom: none;
}

.results-list li:hover {
  background-color: #0056b3;
  color: white;
}

.results-list li:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
</style>
