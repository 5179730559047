import type {
  GetContractReport200Response,
  GetContractReports200ResponseResultsInner,
  GetContractReportsCommissionMatching200ResponseResultsInner,
} from "@/api";
import _ from "lodash";
import { defaultBaseDummyData } from "../Staff/dummy-get-staff";
import { baseDepartments } from "../Department/dummy-departs";

export function replace(oldContract: any, updatedContract: any) {
  dummyContactReports = _.concat(
    _.filter(dummyContactReports, (contract) => contract !== oldContract),
    updatedContract
  );
}

export let dummyContactReports: GetContractReport200Response[] = [
  //// individual - nonLifeInsurance #1 - contractReportId: 1234567
  {
    staffId: 65432,
    potentialCustomerInfo: {
      potentialCustomerId: 4162085,
      potentialCustomerType: "jointRecruitment",
      supportUse: true,
      marketId: 1234567,
      marketName: "保険の一覧",
    },
    insuranceCategory: "nonLifeInsurance",
    contractorAttribute: "individual",
    contractReportOn: "2019-01-01",
    contractReportId: 1234567,
    contractorIndividual: {
      firstName: "麻由美",
      lastName: "大澤",
      firstNameKana: "アユミ",
      lastNameKana: "オオサワ",
      dateOfBirth: "2022-07-21",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress: "system@doitplanning.com",
    },
    insured: {
      firstName: "麻由美",
      lastName: "大澤",
      firstNameKana: "アユミ",
      lastNameKana: "オオサワ",
      dateOfBirth: "2022-07-21",
      potentialCustomerPersonId: 1234567,
    },
    insuranceInfo: {
      insuranceCompanyId: 1234567,
      insuranceTypeId: 1234567,
      insuranceFee: 10000,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    nonLifeInsuranceInfo: {
      policyNumber: "AA123456",
      applicationDateOn: "2022-07-21",
      insuranceStartDateOn: "2022-07-21",
      insuranceEndDateOn: "2022-07-21",
      receiptDateOn: "2022-07-21",
      returnDateOn: "2022-07-21",
      nonLifeInsuranceAccountingCategory: "new",
      nonLifeInsuranceDepositCategory: "directDebit",
    },
    commissionShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 1234567,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
    incentiveShare: {
      companyShare: "5",
      myselfShare: "40",
      bossId: 1234567,
      bossShare: "15",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
  },
  //// individual - nonLifeInsurance #2 - contractReportId: 1234568
  {
    staffId: 1234567,
    potentialCustomerInfo: {
      potentialCustomerId: 4162085,
      potentialCustomerType: "jointRecruitment",
      supportUse: true,
      marketId: 1234567,
      marketName: "保険のマーケット",
    },
    contractReportOn: "2022-07-22",
    contractorAttribute: "individual",
    insuranceCategory: "nonLifeInsurance",
    contractReportId: 1234568,
    contractorIndividual: {
      dateOfBirth: "2022-07-21",
      firstName: "Valerie",
      firstNameKana: "ヴァレリー",
      lastName: "Yamanaka",
      lastNameKana: "ヤマナカ",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress: "system@doitplanning.com",
    },
    insuranceInfo: {
      insuranceCompanyId: 1234567,
      insuranceTypeId: 1234567,
      insuranceFee: 45636,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    nonLifeInsuranceInfo: {
      policyNumber: "BB123123",
      applicationDateOn: "2022-07-21",
      insuranceStartDateOn: "2022-07-21",
      insuranceEndDateOn: "2022-07-21",
      receiptDateOn: "2022-07-21",
      returnDateOn: "2022-07-21",
      nonLifeInsuranceAccountingCategory: "new",
      nonLifeInsuranceDepositCategory: "directDebit",
    },
    insured: {
      dateOfBirth: "2022-07-21",
      firstName: "Valerie",
      firstNameKana: "ヴァレリー",
      lastName: "Yamanaka",
      lastNameKana: "ヤマナカ",
      potentialCustomerPersonId: 1234567,
    },
    commissionShare: {
      companyShare: "15",
      myselfShare: "40",
      bossId: 1234567,
      bossShare: "5",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
    incentiveShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 1234567,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
  },
  //// corporation - lifeInsurance #1 - contractReportId: 1234569
  {
    staffId: 7777,
    potentialCustomerInfo: {
      potentialCustomerId: 4162085,
      potentialCustomerType: "jointRecruitment",
      supportUse: true,
      marketId: 1234567,
      marketName: "保険の一覧",
    },
    contractReportOn: "2018-12-03",
    contractorAttribute: "corporation",
    insuranceCategory: "lifeInsurance",
    contractReportId: 1234569,
    contractorCorporation: {
      corporationClosingMonth: 12,
      corporationName: "アイティーソリューションズ株式会社",
      corporationRepresentativeFirstName: "岡田",
      corporationRepresentativeLastName: "成規",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748623",
      mailAddress: "system@doitplanning.com",
    },
    insuranceInfo: {
      insuranceCompanyId: 3333333,
      insuranceTypeId: 1234560,
      insuranceFee: 44452,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    lifeInsuranceInfo: {
      contractDateOn: "2022-07-21",
    },
    insured: {
      dateOfBirth: "2022-07-21",
      firstName: "麻由美",
      firstNameKana: "アユミ",
      lastName: "大澤",
      lastNameKana: "オオサワ",
      potentialCustomerPersonId: 1234567,
    },
    commissionShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
    incentiveShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
  },
  //// individual - lifeInsurance #1 - contractReportId: 1234571
  {
    staffId: 7777,
    potentialCustomerInfo: {
      potentialCustomerId: 4162085,
      potentialCustomerType: "market",
      supportUse: true,
      marketId: 1234567,
      marketName: "保険台帳",
    },
    contractReportOn: "2021-01-13",
    contractorAttribute: "individual",
    insuranceCategory: "lifeInsurance",
    contractReportId: 1234571,
    contractorIndividual: {
      dateOfBirth: "2020-02-22",
      firstName: "智",
      firstNameKana: "さとし",
      lastName: "勇崎",
      lastNameKana: "ゆうざき",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress: "system@doitplanning.com",
    },
    insuranceInfo: {
      insuranceCompanyId: 2222222,
      insuranceTypeId: 1234569,
      insuranceFee: 13525,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    lifeInsuranceInfo: {
      contractDateOn: "2022-07-21",
    },
    insured: {
      dateOfBirth: "2022-07-21",
      firstName: "まこと",
      firstNameKana: "まこと",
      lastName: "木野",
      lastNameKana: "きの",
      potentialCustomerPersonId: 1234567,
    },
    commissionShare: {
      companyShare: "20",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "0",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
    incentiveShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
  },
  //// individual - lifeInsurance #2 - contractReportId: 1234572
  {
    staffId: 65432,
    contractReportOn: "1999-01-23",
    potentialCustomerInfo: {
      potentialCustomerId: 4162085,
      potentialCustomerType: "jointRecruitment",
      supportUse: true,
      marketId: 1234567,
      marketName: "保険の一覧",
    },

    contractorAttribute: "individual",
    insuranceCategory: "lifeInsurance",
    contractReportId: 1234572,
    contractorIndividual: {
      dateOfBirth: "2020-02-22",
      firstName: "霞",
      firstNameKana: "かすみ",
      lastName: "水野",
      lastNameKana: "みずの",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress: "system@doitplanning.com",
    },
    insuranceInfo: {
      insuranceCompanyId: 4444444,
      insuranceTypeId: 1234569,
      insuranceFee: 543563,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    lifeInsuranceInfo: {
      contractDateOn: "2022-07-21",
    },
    insured: {
      dateOfBirth: "2022-07-21",
      firstName: "美奈子",
      firstNameKana: "みなこ",
      lastName: "愛野",
      lastNameKana: "あいの",
      potentialCustomerPersonId: 1234567,
    },
    commissionShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
    incentiveShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
  },
  //// corporation - nonLifeInsurance #1 - contractReportId: 1234573
  {
    staffId: 7777,
    potentialCustomerInfo: {
      potentialCustomerId: 7777333,
      potentialCustomerType: "jointRecruitment",
      supportUse: true,
      marketId: 1234567,
      marketName: "保険のマーケット",
    },
    contractReportOn: "2001-08-25",
    contractorAttribute: "corporation",
    insuranceCategory: "nonLifeInsurance",
    contractReportId: 1234573,
    contractorCorporation: {
      corporationClosingMonth: 12,
      corporationName: "株式会社NEKO",
      corporationRepresentativeFirstName: "ヤマナカ",
      corporationRepresentativeLastName: "まる",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress: "system@doitplanning.com",
    },
    insuranceInfo: {
      insuranceCompanyId: 1234568,
      insuranceTypeId: 1234560,
      insuranceFee: 54355,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    nonLifeInsuranceInfo: {
      policyNumber: "AA02937",
      applicationDateOn: "2022-07-21",
      insuranceStartDateOn: "2022-07-21",
      insuranceEndDateOn: "2022-07-21",
      receiptDateOn: "2022-07-21",
      returnDateOn: "2022-07-21",
      nonLifeInsuranceAccountingCategory: "new",
      nonLifeInsuranceDepositCategory: "directDebit",
    },
    insured: {
      dateOfBirth: "2022-07-21",
      firstName: "ほたる",
      firstNameKana: "ほたる",
      lastName: "土萠",
      lastNameKana: "ともえ",
      potentialCustomerPersonId: 1234567,
    },
    commissionShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
    incentiveShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
  },
  //// corporation - nonLifeInsurance #2 - contractReportId: 1234574
  {
    staffId: 65432,
    potentialCustomerInfo: {
      potentialCustomerId: 4162085,
      potentialCustomerType: "jointRecruitment",
      supportUse: true,
      marketId: 1234567,
      marketName: "保険一番",
    },

    contractReportOn: "2010-02-07",
    contractorAttribute: "corporation",
    insuranceCategory: "nonLifeInsurance",
    contractReportId: 1234574,
    contractorCorporation: {
      corporationClosingMonth: 12,
      corporationName: "株式会社ORANGE",
      corporationRepresentativeFirstName: "Adams",
      corporationRepresentativeLastName: "Yuzu",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress: "system@doitplanning.com",
    },
    insuranceInfo: {
      insuranceCompanyId: 1234567,
      insuranceTypeId: 1234568,
      insuranceFee: 10040,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    nonLifeInsuranceInfo: {
      policyNumber: "CC83746",
      applicationDateOn: "2022-07-21",
      insuranceStartDateOn: "2022-07-21",
      insuranceEndDateOn: "2022-07-21",
      receiptDateOn: "2022-07-21",
      returnDateOn: "2022-07-21",
      nonLifeInsuranceAccountingCategory: "new",
      nonLifeInsuranceDepositCategory: "directDebit",
    },
    insured: {
      dateOfBirth: "2022-07-21",
      firstName: "謙之",
      firstNameKana: "けんじ",
      lastName: "月野",
      lastNameKana: "つきの",
      potentialCustomerPersonId: 1234567,
    },
    commissionShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
    incentiveShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
  },
  //// corporation - lifeInsurance #2 - contractReportId: 1234575
  {
    staffId: 65432,
    potentialCustomerInfo: {
      potentialCustomerId: 4162085,
      potentialCustomerType: "jointRecruitment",
      supportUse: true,
      marketId: 1234567,
      marketName: "保険の一覧",
    },

    contractReportOn: "2013-02-23",
    contractorAttribute: "corporation",
    insuranceCategory: "lifeInsurance",
    contractReportId: 1234575,
    contractorCorporation: {
      corporationClosingMonth: 12,
      corporationName: "株式会社ORANGE",
      corporationRepresentativeFirstName: "Adams",
      corporationRepresentativeLastName: "Yuzu",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress: "system@doitplanning.com",
    },
    insuranceInfo: {
      insuranceCompanyId: 1234567,
      insuranceTypeId: 1234568,
      insuranceFee: 334442,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    lifeInsuranceInfo: {
      contractDateOn: "2022-07-21",
    },
    insured: {
      dateOfBirth: "2022-07-21",
      firstName: "春菜",
      firstNameKana: "はるな",
      lastName: "桜田",
      lastNameKana: "さくらだ",
      potentialCustomerPersonId: 1234567,
    },
    commissionShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
    incentiveShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
  },
  //// individual - nonLifeInsurance #3 - contractReportId: 1234576
  {
    staffId: 65432,
    potentialCustomerInfo: {
      potentialCustomerId: 4162085,
      potentialCustomerType: "jointRecruitment",
      supportUse: true,
      marketId: 1234567,
      marketName: "保険の大一覧",
    },
    insuranceCategory: "nonLifeInsurance",
    contractorAttribute: "individual",
    contractReportOn: "2022-07-21",
    contractReportId: 1234576,
    contractorIndividual: {
      firstName: "武",
      lastName: "灰谷",
      firstNameKana: "たけし",
      lastNameKana: "はいたに",
      dateOfBirth: "2022-07-21",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress: "system@doitplanning.com",
    },
    insured: {
      firstName: "桜",
      lastName: "木之本",
      firstNameKana: "さくら",
      lastNameKana: "きのもと",
      dateOfBirth: "2022-07-21",
      potentialCustomerPersonId: 1234567,
    },
    insuranceInfo: {
      insuranceCompanyId: 1111111,
      insuranceTypeId: 1234567,
      insuranceFee: 56534,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    nonLifeInsuranceInfo: {
      policyNumber: "CC543287",
      applicationDateOn: "2022-07-21",
      insuranceStartDateOn: "2022-07-21",
      insuranceEndDateOn: "2022-07-21",
      receiptDateOn: "2022-07-21",
      returnDateOn: "2022-07-21",
      nonLifeInsuranceAccountingCategory: "new",
      nonLifeInsuranceDepositCategory: "directDebit",
    },
    commissionShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 1234567,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
    incentiveShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 1234567,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
  },
  //// individual - nonLifeInsurance #4 - contractReportId: 1234577 - insured: null
  {
    staffId: 1234567,
    potentialCustomerInfo: {
      potentialCustomerId: 4162085,
      potentialCustomerType: "jointRecruitment",
      supportUse: true,
      marketId: 1234567,
      marketName: "保険のお仕事",
    },
    contractReportOn: "2017-10-22",
    contractorAttribute: "individual",
    insuranceCategory: "nonLifeInsurance",
    contractReportId: 1234577,
    contractorIndividual: {
      dateOfBirth: "2022-07-21",
      firstName: "健二",
      firstNameKana: "けんじ",
      lastName: "観島",
      lastNameKana: "みしま",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      mailAddress: "system@doitplanning.com",
    },
    insuranceInfo: {
      insuranceCompanyId: 1234567,
      insuranceTypeId: 1234568,
      insuranceFee: 10000,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    nonLifeInsuranceInfo: {
      policyNumber: "DD629273",
      applicationDateOn: "2022-07-21",
      insuranceStartDateOn: "2022-07-21",
      insuranceEndDateOn: "2022-07-21",
      receiptDateOn: "2022-07-21",
      returnDateOn: "2022-07-21",
      nonLifeInsuranceAccountingCategory: "new",
      nonLifeInsuranceDepositCategory: "directDebit",
    },
    // insured: {
    //   dateOfBirth: "2022-07-21",
    //   firstName: "Valerie",
    //   firstNameKana: "ヴァレリー",
    //   lastName: "Yamanaka",
    //   lastNameKana: "ヤマナカ",
    //   potentialCustomerPersonId: 1234567,
    // },
    commissionShare: {
      companyShare: "15",
      myselfShare: "40",
      bossId: 1234567,
      bossShare: "5",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
    incentiveShare: {
      companyShare: "15",
      myselfShare: "40",
      bossId: 1234567,
      bossShare: "5",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
  },
  //// corporation - lifeInsurance #1 - contractReportId: 1234578
  {
    staffId: 7777,
    potentialCustomerInfo: {
      potentialCustomerId: 4162085,
      potentialCustomerType: "jointRecruitment",
      supportUse: true,
      marketId: 1234567,
      marketName: "保険のマーケット",
    },

    contractReportOn: "2012-04-29",
    contractorAttribute: "corporation",
    insuranceCategory: "lifeInsurance",
    contractReportId: 1234578,
    contractorCorporation: {
      corporationClosingMonth: 12,
      corporationName: "ITZ株式会社",
      corporationRepresentativeFirstName: "岡田",
      corporationRepresentativeLastName: "成規",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
    },
    insuranceInfo: {
      insuranceCompanyId: 5555555,
      insuranceTypeId: 1234569,
      insuranceFee: 21344,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    lifeInsuranceInfo: {
      contractDateOn: "2022-07-21",
    },
    insured: {
      dateOfBirth: "2022-07-21",
      firstName: "小狼",
      firstNameKana: "シャオラン",
      lastName: "李",
      lastNameKana: "リ",
      potentialCustomerPersonId: 1234567,
    },
    commissionShare: {
      companyShare: "20",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "0",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
    incentiveShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
  },
  //// individual - lifeInsurance #3 - contractReportId: 1234579
  {
    staffId: 7777,
    potentialCustomerInfo: {
      potentialCustomerId: 4162085,
      potentialCustomerType: "jointRecruitment",
      supportUse: true,
      marketId: 1234567,
      marketName: "保険の一覧",
    },

    contractReportOn: "2023-03-23",
    contractorAttribute: "individual",
    insuranceCategory: "lifeInsurance",
    contractReportId: 1234579,
    contractorIndividual: {
      dateOfBirth: "2020-02-22",
      firstName: "博",
      firstNameKana: "ひろし",
      lastName: "友道",
      lastNameKana: "ともみち",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress: "system@doitplanning.com",
    },
    insuranceInfo: {
      insuranceCompanyId: 5555555,
      insuranceTypeId: 1234560,
      insuranceFee: 1244,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    lifeInsuranceInfo: {
      contractDateOn: "2022-07-21",
    },
    insured: {
      dateOfBirth: "2022-07-21",
      firstName: "知世",
      firstNameKana: "ともよ",
      lastName: "大道寺",
      lastNameKana: "だいどうじ",
      potentialCustomerPersonId: 1234567,
    },
    commissionShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
    incentiveShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
  },
  //// individual - lifeInsurance #2 - contractReportId: 1234580
  {
    staffId: 65432,
    potentialCustomerInfo: {
      potentialCustomerId: 4162085,
      potentialCustomerType: "jointRecruitment",
      supportUse: true,
      marketId: 1234567,
      marketName: "保険の一覧",
    },

    contractReportOn: "2021-06-16",
    contractorAttribute: "individual",
    insuranceCategory: "lifeInsurance",
    contractReportId: 1234580,
    contractorIndividual: {
      dateOfBirth: "2020-02-22",
      firstName: "うさぎ",
      firstNameKana: "うさぎ",
      lastName: "月野",
      lastNameKana: "つきの",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress: "system@doitplanning.com",
    },
    insuranceInfo: {
      insuranceCompanyId: 4444444,
      insuranceTypeId: 1234569,
      insuranceFee: 5363,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    lifeInsuranceInfo: {
      contractDateOn: "2022-07-21",
    },
    insured: {
      dateOfBirth: "2022-07-21",
      firstName: "衛",
      firstNameKana: "まもる",
      lastName: "地場",
      lastNameKana: "ちば",
      potentialCustomerPersonId: 1234567,
    },
    commissionShare: {
      companyShare: "5",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "15",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
    incentiveShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
  },
  //// corporation - nonLifeInsurance #3 - contractReportId: 12345781
  {
    staffId: 7777,
    potentialCustomerInfo: {
      potentialCustomerId: 4162085,
      potentialCustomerType: "jointRecruitment",
      supportUse: true,
      marketId: 1234567,
      marketName: "保険のマーケット",
    },

    contractReportOn: "2017-07-07",
    contractorAttribute: "corporation",
    insuranceCategory: "nonLifeInsurance",
    contractReportId: 1234581,
    contractorCorporation: {
      corporationClosingMonth: 12,
      corporationName: "ワン・ワルド株式会社",
      corporationRepresentativeFirstName: "ヤマナカ",
      corporationRepresentativeLastName: "まる",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress: "system@doitplanning.com",
    },
    insuranceInfo: {
      insuranceCompanyId: 1111111,
      insuranceTypeId: 1234567,
      insuranceFee: 30005,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    nonLifeInsuranceInfo: {
      policyNumber: "BB73263",
      applicationDateOn: "2022-07-21",
      insuranceStartDateOn: "2022-07-21",
      insuranceEndDateOn: "2022-07-21",
      receiptDateOn: "2022-07-21",
      returnDateOn: "2022-07-21",
      nonLifeInsuranceAccountingCategory: "new",
      nonLifeInsuranceDepositCategory: "directDebit",
    },
    insured: {
      dateOfBirth: "2022-07-21",
      firstName: "衛",
      firstNameKana: "まもる",
      lastName: "地場",
      lastNameKana: "ちば",
      potentialCustomerPersonId: 1234567,
    },
    commissionShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
    incentiveShare: {
      companyShare: "20",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "0",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
  },
  //// corporation - nonLifeInsurance #4 - contractReportId: 1234582
  {
    staffId: 65432,
    potentialCustomerInfo: {
      potentialCustomerId: 4162085,
      potentialCustomerType: "jointRecruitment",
      supportUse: true,
      marketId: 1234567,
      marketName: "保険の一覧",
    },

    contractReportOn: "2023-01-01",
    contractorAttribute: "corporation",
    insuranceCategory: "nonLifeInsurance",
    contractReportId: 1234582,
    contractorCorporation: {
      corporationClosingMonth: 12,
      corporationName: "yuzu株式会社",
      corporationRepresentativeFirstName: "Adams",
      corporationRepresentativeLastName: "Yuzu",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress: "system@doitplanning.com",
    },
    insuranceInfo: {
      insuranceCompanyId: 1111111,
      insuranceTypeId: 1234568,
      insuranceFee: 34324,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    nonLifeInsuranceInfo: {
      policyNumber: "DD02938",
      applicationDateOn: "2022-07-21",
      insuranceStartDateOn: "2022-07-21",
      insuranceEndDateOn: "2022-07-21",
      receiptDateOn: "2022-07-21",
      returnDateOn: "2022-07-21",
      nonLifeInsuranceAccountingCategory: "new",
      nonLifeInsuranceDepositCategory: "directDebit",
    },
    insured: {
      dateOfBirth: "2022-07-21",
      firstName: "亜美",
      firstNameKana: "あみ",
      lastName: "水野",
      lastNameKana: "みずの",
      potentialCustomerPersonId: 1234567,
    },
    commissionShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
    incentiveShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
  },
  //// corporation - lifeInsurance #2 - contractReportId: 1234583
  {
    staffId: 65432,
    potentialCustomerInfo: {
      potentialCustomerId: 4162085,
      potentialCustomerType: "jointRecruitment",
      supportUse: true,
      marketId: 1234567,
      marketName: "保険の一番",
    },

    contractReportOn: "2017-05-17",
    contractorAttribute: "corporation",
    insuranceCategory: "lifeInsurance",
    contractReportId: 1234583,
    contractorCorporation: {
      corporationClosingMonth: 12,
      corporationName: "テクノロジー株式会社",
      corporationRepresentativeFirstName: "Adams",
      corporationRepresentativeLastName: "Yuzu",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      mailAddress: "system@doitplanning.com",
    },
    insuranceInfo: {
      insuranceCompanyId: 1234567,
      insuranceTypeId: 1234568,
      insuranceFee: 10550,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    lifeInsuranceInfo: {
      contractDateOn: "2022-07-21",
    },
    insured: {
      dateOfBirth: "2022-07-21",
      firstName: "麻由美",
      firstNameKana: "アユミ",
      lastName: "大澤",
      lastNameKana: "オオサワ",
      potentialCustomerPersonId: 1234567,
    },
    commissionShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
    incentiveShare: {
      companyShare: "10",
      myselfShare: "40",
      bossId: 7777,
      bossShare: "10",
      otherStaffId1: 1234567,
      otherStaffShare1: "10",
      otherStaffId2: 7777,
      otherStaffShare2: "10",
      otherStaffId3: 1234567,
      otherStaffShare3: "10",
      marketShare: "10",
    },
  },
];

const staffs = defaultBaseDummyData;
const departments = baseDepartments;

export const getRestructuredDummyContactReports = async (): Promise<
  GetContractReports200ResponseResultsInner[]
> => {
  return Promise.all(
    dummyContactReports.map(async (report) => {
      // Get staff info from staffId
      // Filter staffs by staffId
      const staff = staffs.filter(
        (staff) => staff.staffId === report.staffId
      )[0];

      // Guard if staff is null
      if (!staff) throw new Error("Staff is not found");

      // Filter departments by departmentId
      const department = departments.filter(
        (department) =>
          department.departmentId ===
          staff.departmentAffiliation[0].departmentId
      )[0];

      const restructuredReport: GetContractReports200ResponseResultsInner = {
        staff: {
          firstName: staff.personal.firstName,
          lastName: staff.personal.lastName,
          staffNumber: staff.staffNumber,
          staffId: staff.staffId,
          departmentName: department.departmentName,
          departmentId: staff.departmentAffiliation[0].departmentId,
        },
        contractReportOn: report.contractReportOn,
        contractorAttribute: report.contractorAttribute,
        insuranceCategory: report.insuranceCategory,
        contractReportId: report.contractReportId,
        contractorIndividual: {
          firstName: report.contractorIndividual?.firstName ?? "",
          lastName: report.contractorIndividual?.lastName ?? "",
          firstNameKana: report.contractorIndividual?.firstNameKana ?? "",
          lastNameKana: report.contractorIndividual?.lastNameKana ?? "",
          dateOfBirth: report.contractorIndividual?.dateOfBirth ?? "",
          postCode: report.contractorIndividual?.postCode ?? "",
          address1: report.contractorIndividual?.address1 ?? "",
          address2: report.contractorIndividual?.address2 ?? "",
          address3: report.contractorIndividual?.address3 ?? "",
          // telLast is last 4 digits of tel1
          telLast: report.contractorIndividual?.tel1
            ? report.contractorIndividual?.tel1.slice(-4)
            : "",
        },
        contractorCorporation: {
          corporationClosingMonth:
            report.contractorCorporation?.corporationClosingMonth,
          corporationName: report.contractorCorporation?.corporationName ?? "",
          corporationRepresentativeFirstName:
            report.contractorCorporation?.corporationRepresentativeFirstName ??
            "",
          corporationRepresentativeLastName:
            report.contractorCorporation?.corporationRepresentativeLastName ??
            "",
          postCode: report.contractorCorporation?.postCode ?? "",
          address1: report.contractorCorporation?.address1 ?? "",
          address2: report.contractorCorporation?.address2 ?? "",
          address3: report.contractorCorporation?.address3 ?? "",
          telLast: report.contractorCorporation?.tel1 ?? "",
        },
        insuranceInfo: {
          insuranceCompanyId: report.insuranceInfo?.insuranceCompanyId,
          insuranceCompanyName: "テストカンパニー",
          insuranceTypeId: 1234567890,
          insuranceTypeName: "火災保険",
          insuranceFee: report.insuranceInfo?.insuranceFee,
          paymentMethod: report.insuranceInfo?.paymentMethod,
          deemedAnnualPayment: report.insuranceInfo?.deemedAnnualPayment,
        },
        ...(report.insured && {
          insured: {
            firstName: report.insured?.firstName ?? "",
            lastName: report.insured?.lastName ?? "",
            firstNameKana: report.insured?.firstNameKana ?? "",
            lastNameKana: report.insured?.lastNameKana ?? "",
            dateOfBirth: report.insured?.dateOfBirth ?? "",
            potentialCustomerPersonId:
              report.insured?.potentialCustomerPersonId,
          },
        }),
        ...(report.potentialCustomerInfo && {
          potentialCustomerInfo: {
            potentialCustomerId:
              report.potentialCustomerInfo.potentialCustomerId,
            potentialCustomerType:
              report.potentialCustomerInfo.potentialCustomerType,
            supportUse: report.potentialCustomerInfo.supportUse,
            marketId: report.potentialCustomerInfo.marketId,
            marketName: report.potentialCustomerInfo.marketName,
          },
        }),
      };

      return restructuredReport;
    })
  );
};

export const restructuredDummyContactReports: Promise<
  GetContractReports200ResponseResultsInner[]
> = Promise.all(
  dummyContactReports.map(async (report) => {
    // Get staff info from staffId
    // Filter staffs by staffId
    const staff = staffs.filter((staff) => staff.staffId === report.staffId)[0];

    // Guard if staff is null
    if (!staff) throw new Error("Staff is not found");

    // Filter departments by departmentId
    const department = departments.filter(
      (department) =>
        department.departmentId === staff.departmentAffiliation[0].departmentId
    )[0];

    const restructuredReport: GetContractReports200ResponseResultsInner = {
      staff: {
        firstName: staff.personal.firstName,
        lastName: staff.personal.lastName,
        staffNumber: staff.staffNumber,
        staffId: staff.staffId,
        departmentName: department.departmentName,
        departmentId: staff.departmentAffiliation[0].departmentId,
      },
      contractReportOn: report.contractReportOn,
      contractorAttribute: report.contractorAttribute,
      insuranceCategory: report.insuranceCategory,
      contractReportId: report.contractReportId,
      contractorIndividual: {
        firstName: report.contractorIndividual?.firstName ?? "",
        lastName: report.contractorIndividual?.lastName ?? "",
        firstNameKana: report.contractorIndividual?.firstNameKana ?? "",
        lastNameKana: report.contractorIndividual?.lastNameKana ?? "",
        dateOfBirth: report.contractorIndividual?.dateOfBirth ?? "",
        postCode: report.contractorIndividual?.postCode ?? "",
        address1: report.contractorIndividual?.address1 ?? "",
        address2: report.contractorIndividual?.address2 ?? "",
        address3: report.contractorIndividual?.address3 ?? "",
        // telLast is last 4 digits of tel1
        telLast: report.contractorIndividual?.tel1
          ? report.contractorIndividual?.tel1.slice(-4)
          : "",
      },
      contractorCorporation: {
        corporationClosingMonth:
          report.contractorCorporation?.corporationClosingMonth,
        corporationName: report.contractorCorporation?.corporationName ?? "",
        corporationRepresentativeFirstName:
          report.contractorCorporation?.corporationRepresentativeFirstName ??
          "",
        corporationRepresentativeLastName:
          report.contractorCorporation?.corporationRepresentativeLastName ?? "",
        postCode: report.contractorCorporation?.postCode ?? "",
        address1: report.contractorCorporation?.address1 ?? "",
        address2: report.contractorCorporation?.address2 ?? "",
        address3: report.contractorCorporation?.address3 ?? "",
        telLast: report.contractorCorporation?.tel1 ?? "",
      },
      insuranceInfo: {
        insuranceCompanyId: report.insuranceInfo?.insuranceCompanyId,
        insuranceCompanyName: "テストカンパニー",
        insuranceTypeId: 1234567890,
        insuranceTypeName: "火災保険",
        insuranceFee: report.insuranceInfo?.insuranceFee,
        paymentMethod: report.insuranceInfo?.paymentMethod,
        deemedAnnualPayment: report.insuranceInfo?.deemedAnnualPayment,
      },
      ...(report.insured && {
        insured: {
          firstName: report.insured?.firstName ?? "",
          lastName: report.insured?.lastName ?? "",
          firstNameKana: report.insured?.firstNameKana ?? "",
          lastNameKana: report.insured?.lastNameKana ?? "",
          dateOfBirth: report.insured?.dateOfBirth ?? "",
          potentialCustomerPersonId: report.insured?.potentialCustomerPersonId,
        },
      }),
      ...(report.potentialCustomerInfo && {
        potentialCustomerInfo: {
          potentialCustomerId: report.potentialCustomerInfo.potentialCustomerId,
          potentialCustomerType:
            report.potentialCustomerInfo.potentialCustomerType,
          supportUse: report.potentialCustomerInfo.supportUse,
          marketId: report.potentialCustomerInfo.marketId,
          marketName: report.potentialCustomerInfo.marketName,
        },
      }),
    };

    return restructuredReport;
  })
);
export const contractReportCommissionMatchingResults: (GetContractReportsCommissionMatching200ResponseResultsInner & {
  commissionId: number;
})[] = [
  {
    commissionId: 1234567,
    contractReportId: 1234567,
    staff: {
      staffId: 1234567,
      staffNumber: "00123",
      firstName: "麻由美",
      lastName: "大澤",
    },
    insuranceCategory: "nonLifeInsurance",
    contractorAttribute: "individual",
    contractReportOn: "2022-07-21",
    contractorIndividual: {
      firstName: "麻由美",
      lastName: "大澤",
      firstNameKana: "アユミ",
      lastNameKana: "オオサワ",
    },
    contractorCorporation: {
      corporationName: "アイティーソリューションズ株式会社",
    },
    insured: {
      firstName: "麻由美",
      lastName: "大澤",
      firstNameKana: "アユミ",
      lastNameKana: "オオサワ",
    },
    insuranceInfo: {
      insuranceTypeId: 1234567,
      insuranceFee: 10000,
      insuranceTypeName: "個人ガン保険",
    },
    lifeInsuranceInfo: {
      contractDateOn: "2022-07-21",
    },
    nonLifeInsuranceInfo: {
      policyNumber: "AA123456",
      applicationDateOn: "2022-07-21",
    },
  },
  {
    commissionId: 12345678,
    contractReportId: 1234568,
    staff: {
      staffId: 1234567,
      staffNumber: "0057043",
      firstName: "Test",
      lastName: "Smith",
    },
    insuranceCategory: "lifeInsurance",
    contractorAttribute: "individual",
    contractReportOn: "2022-08-21",
    contractorIndividual: {
      firstName: "Valerie",
      lastName: "Yamanaka",
      firstNameKana: "ヴァレリー",
      lastNameKana: "ヤマナカ",
    },
    contractorCorporation: {
      corporationName: "ABC Company",
    },
    insured: {
      firstName: "Valerie",
      lastName: "Yamanaka",
      firstNameKana: "ヴァレリー",
      lastNameKana: "ヤマナカ",
    },
    insuranceInfo: {
      insuranceTypeName: "個人ガン保険",
      insuranceTypeId: 1234567,
      insuranceFee: 10000,
    },
    lifeInsuranceInfo: {
      contractDateOn: "2022-08-21",
    },
    nonLifeInsuranceInfo: {
      policyNumber: "AB4948294",
      applicationDateOn: "2022-08-21",
    },
  },
];
