import { StaffApiFactory, type PutStaffAccountActiveRequest } from "@/api";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import axiosInstance from "@/utils/axios-customized-instance";
import { safeAsyncCall, safeAsyncCallAndRaiseError } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const {
  getStaff,
  postStaff,
  putStaff,
  putStaffAccountActive,
  putStaffPassword,
} = StaffApiFactory(...args, undefined, axiosInstance);

const setAccountActiveStatus = safeAsyncCall(
  async (staffId: number, isActive: boolean) => {
    const putStaffAccountActiveRequest: PutStaffAccountActiveRequest = {
      accountActiveFlag: isActive,
    };

    // Set 'accountActiveFlag' based on the 'isActive' parameter.
    const modifiedArgs = [
      staffId,
      putStaffAccountActiveRequest,
      undefined, // no options provided
    ] as const;

    const res = await putStaffAccountActive(...modifiedArgs);
    return res;
  }
);

export function useStaffService() {
  return {
    get: safeAsyncCall(async (...args: Parameters<typeof getStaff>) => {
      const res = await getStaff(...args);
      return res; // { data, status }
    }),
    add: safeAsyncCallAndRaiseError(
      async (...args: Parameters<typeof postStaff>) => {
        const res = await postStaff(...args);
        return res; // { data, status }
      }
    ),
    update: safeAsyncCallAndRaiseError(
      async (...args: Parameters<typeof putStaff>) => {
        const res = await putStaff(...args);
        return res; // { data, status }
      }
    ),
    enableAccount: safeAsyncCall(async (staffId: number) => {
      return await setAccountActiveStatus(staffId, true);
    }),
    disableAccount: safeAsyncCall(async (staffId: number) => {
      return await setAccountActiveStatus(staffId, false);
    }),
    forceChangePassword: safeAsyncCall(
      async (...args: Parameters<typeof putStaffPassword>) => {
        const res = await putStaffPassword(...args);
        return res;
      }
    ),
  };
}
