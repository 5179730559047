import { rest } from "msw";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import { commissionStatusData } from "@/mocks/CommissionQuery/dummy-data";
import { dummyInsuranceCompanies } from "@/mocks/InsuranceCompany/dummy-data";
import _ from "lodash-es";
import randomInt from "random-int";
import type {
  CommissionCategory,
  CommissionImportStatus,
  InsuranceCategory,
} from "@/api";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

/**
 * CommissionQuery
 * DeleteCommissionImport API
 */

export const handlers = [
  // DeleteCommissionImport API
  rest.delete(
    `${BASE_PATH}/commission/import/:commissionImportId`,
    (req, res, ctx) => {
      // TODO: This function delete the commission import data from the database in reality.
      const commissionImportId = req.params.commissionImportId;

      // directly pop out the item that matches the commissionImportId
      _.remove(
        commissionStatusData,
        (item) => item.commissionImportId === Number(commissionImportId)
      );

      return res(ctx.status(204));
    }
  ),

  // postCommissionCsv API
  rest.post(
    `${BASE_PATH}/commission/:insuranceCompanyId/:commissionCategory/:month`,
    (req, res, ctx) => {
      const insuranceCompanyId = Number(req.params.insuranceCompanyId);
      const commissionCategory = req.params.commissionCategory;
      const month = req.params.month;

      function getInsuranceCompanyInfo(insuranceCompanyId: number) {
        const match = dummyInsuranceCompanies.find(
          (company) => company.insuranceCompanyId === insuranceCompanyId
        );

        if (match) return match.insuranceCompanyName;
      }

      const commissionImportId = randomInt(1111111, 9999999);
      const commissionImportCount = randomInt(1111111, 9999999);

      const convertedData = {
        commissionImportId: commissionImportId,
        insuranceCompanyId: insuranceCompanyId,
        insuranceCompanyName: getInsuranceCompanyInfo(insuranceCompanyId) ?? "",
        insuranceCategory: "lifeInsurance" as InsuranceCategory,
        commissionCategory: commissionCategory as CommissionCategory,
        commissionRecordCount: 1,
        commissionMatchingCount: 1,
        commissionTotalAmount: 1,
        commissionImportAt: "2023-04-29",
        commissionImportStatus:
          "commissionCalculated" as CommissionImportStatus,
        commissionImportCount: commissionImportCount,
        consumptionTaxIncludeFlag: true,
      };

      // This error was randomly generated by MSW 50% of the time when it's develop mode.
      if (import.meta.env.VITE_ENV !== "test" && Math.random() < 0.5)
        return res(
          ctx.delay(1000),
          ctx.status(400),
          ctx.json({
            message:
              "This error was randomly generated by MSW 50% of the time when it's develop mode.",
          })
        );

      commissionStatusData.push(convertedData);

      // if not testing, insert a delay to simulate real import
      if (import.meta.env.VITE_ENV === "test") {
        return res(
          ctx.status(200),
          ctx.json({
            commissionImportId: 123456,
            commissionImportCount: 1000,
          })
        );
      } else {
        return res(
          ctx.delay(3000),
          ctx.status(200),
          ctx.json({
            commissionImportId: 123456,
            commissionImportCount: 1000,
          })
        );
      }
    }
  ),
];
