import { ref } from "vue";
import { defineStore } from "pinia";

interface ErrorMessages {
  errorMessages: string[];
}
export const useErrorMessageStore = defineStore("error-message", () => {
  const state = ref<ErrorMessages>({
    errorMessages: [],
  });

  const setErrorMessage = (message: string) => {
    state.value.errorMessages.push(message);
  };

  const removeErrorMessage = (index: number) => {
    state.value.errorMessages.splice(index, 1);
  };

  const clearErrorMessages = () => {
    // state.value.errorMessage = [];
  };

  return {
    state,
    setErrorMessage,
    clearErrorMessages,
    removeErrorMessage,
  };
});
