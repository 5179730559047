import { useEnvironmentVars } from "@/composables/use-environment-vars";
import { AdjustmentDeductionApiFactory } from "@/api";
import axiosInstance from "@/utils/axios-customized-instance";
import { safeAsyncCall } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const {
  postAdjustmentDeduction,
  putAdjustmentDeduction,
  deleteAdjustmentDeduction,
} = AdjustmentDeductionApiFactory(...args, undefined, axiosInstance);

export default function useAdjustmentDeductionService() {
  return {
    /**
     * Add adjustments/deductions by the given parameters.
     * @param args
     */
    add: safeAsyncCall(
      async (...args: Parameters<typeof postAdjustmentDeduction>) => {
        const res = await postAdjustmentDeduction(...args);
        const { data: id } = res;
        return id;
      }
    ),

    /**
     * Update adjustments/deductions by the given parameters.
     * @param args
     */
    update: safeAsyncCall(
      async (...args: Parameters<typeof putAdjustmentDeduction>) => {
        const res = await putAdjustmentDeduction(...args);
        return res;
      }
    ),

    /**
     * Delete adjustments/deductions by id.
     * @param args
     */
    delete: safeAsyncCall(
      async (...args: Parameters<typeof deleteAdjustmentDeduction>) => {
        const res = await deleteAdjustmentDeduction(...args);
        return res;
      }
    ),
  };
}
