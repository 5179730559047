import { dummyData, replace } from "./dummy-data";
import { rest } from "msw";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

export const handlers = [
  /**
   * CommissionFormat
   * GetCommissionFormat
   */
  rest.get(
    `${BASE_PATH}/commissionFormat/:insuranceCompanyId/:commissionCategory`,
    (req, res, ctx) => {
      const id = req.params.insuranceCompanyId;
      const category = req.params.commissionCategory;

      //   const record = dummyData.find(
      //     (record) =>
      //       record.insuranceCompanyId === Number(id) &&
      //       record.commissionCategory == category
      //   );

      console.log(`GetCommissionFormat: ${id} - ${category}`);

      // While there is only one dummy data record, it simply returns the dummy data.
      return res(ctx.status(200), ctx.json(dummyData));
    }
  ),

  /**
   * CommissionFormat
   * PutCommissionFormat
   */
  rest.put(
    `${BASE_PATH}/commissionFormat/:insuranceCompanyId/:commissionCategory`,
    async (req, res, ctx) => {
      const request = await req.json();
      const id = req.params.insuranceCompanyId;
      const category = req.params.commissionCategory;

      // While there's only one record, we just replace it directly.
      replace(request);

      console.log(`PutCommissionFormat: ${id} - ${category}`);

      return res(ctx.status(204));
    }
  ),
];
