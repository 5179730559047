import { rest } from "msw";
import { adjustmentDeductions as dummyAdjustmentDeductions } from "@/mocks/AdjustmentDeduction/dummy-records";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import type { GetAdjustmentDeductions200ResponseResultsInner } from "@/api";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

/**
 * GetAdjustmentDeductions API.
 * param staffId
 * return GetAdjustmentDeductions200Response.
 */
export const handlers = [
  rest.get(`${BASE_PATH}/adjustmentDeduction`, (req, res, ctx) => {
    let adjustmentDeductionsResults = <
      GetAdjustmentDeductions200ResponseResultsInner[]
    >dummyAdjustmentDeductions;
    // const staffId = Number(req.params.staffId);
    const staffId = Number(req.url.searchParams.get("staffId"));
    const adjustmentDeductionTerm = Number(
      req.url.searchParams.get("adjustmentDeductionTerm")
    );
    const adjustmentDeductionTypeId = Number(
      req.url.searchParams.get("adjustmentDeductionTypeId")
    );
    // get all results by filter.
    if (staffId) {
      adjustmentDeductionsResults = dummyAdjustmentDeductions.filter(
        (dummy) => dummy.staff.staffId === staffId
      );
    }
    if (adjustmentDeductionTerm) {
      adjustmentDeductionsResults = adjustmentDeductionsResults.filter(
        (dummy) =>
          Number(dummy.adjustmentDeductionTerm.adjustmentDeductionTermFrom) <=
            adjustmentDeductionTerm &&
          Number(dummy.adjustmentDeductionTerm.adjustmentDeductionTermTo) >=
            adjustmentDeductionTerm
      );
    }
    if (adjustmentDeductionTypeId) {
      adjustmentDeductionsResults = adjustmentDeductionsResults.filter(
        (dummy) => dummy.adjustmentDeductionTypeId === adjustmentDeductionTypeId
      );
    }

    // Returns empty list when the result is not found or empty.
    return res(
      ctx.status(200),
      ctx.json({
        results: adjustmentDeductionsResults,
        pagination: {
          totalCount: adjustmentDeductionsResults.length,
          currentPage: 0,
          lastPage: 0,
        },
      })
    );
  }),

  /**
   * GetAdjustmentDeductionCsv API.
   * query parameters are staffId, adjustmentDeductionTypeId and adjustmentDeductionTerm
   * return a tantative CSV file
   */
  rest.get(`${BASE_PATH}/adjustmentDeduction/csv`, (req, res, ctx) => {
    const staffId = Number(req.url.searchParams.get("staffId"));
    const adjustmentDeductionTypeId = Number(
      req.url.searchParams.get("adjustmentDeductionTypeId")
    );
    const adjustmentDeductionTerm = Number(
      req.url.searchParams.get("adjustmentDeductionTerm")
    );

    // return a tantative CSV file
    const csvData = `"header1","header2","header3"
"row1col1","row1col2","row1col3"
"row2col1","row2col2","③㈱髙"`;

    return res(
      ctx.status(200),
      ctx.set("Content-Type", "text/csv"),
      ctx.set("Content-Disposition", `attachment;`),
      ctx.body(csvData)
    );
  }),

  // putAdjustmentDeductionCsv API.
  // const putAdjustmentDeductionCsv: (file: File, filename?: string | undefined, options?: any)
  //  => AxiosPromise<PutAdjustmentDeductionCsv200Response>
  rest.post(`${BASE_PATH}/adjustmentDeduction/csv`, (req, res, ctx) => {
    // return a dummy response
    return res(
      ctx.status(200),
      ctx.json({
        createCount: 1234,
        updateCount: 5678,
      })
    );
  }),

  // postAdjustmentDeductionIncentiveCsv API.
  // const postAdjustmentDeductionIncentiveCsv: (file: File, filename?: string | undefined, options?: any)
  //  => AxiosPromise<PostAdjustmentDeductionIncentiveCsv200Response>
  rest.post(
    `${BASE_PATH}/adjustmentDeduction/incentive/csv`,
    (req, res, ctx) => {
      // return a dummy response
      return res(
        ctx.status(200),
        ctx.json({
          importCount: 123456789,
        })
      );
    }
  ),
];
