import { CommissionFormatApiFactory } from "@/api";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import axiosInstance from "@/utils/axios-customized-instance";
import { safeAsyncCall } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const { getCommissionFormat, putCommissionFormat } = CommissionFormatApiFactory(
  ...args,
  undefined,
  axiosInstance
);

export default function useCommissionFormatService() {
  return {
    get: safeAsyncCall(
      async (...args: Parameters<typeof getCommissionFormat>) => {
        const res = await getCommissionFormat(...args);
        return res.data;
      }
    ),
    update: safeAsyncCall(
      async (...args: Parameters<typeof putCommissionFormat>) => {
        const res = await putCommissionFormat(...args);
        return res;
      }
    ),
  };
}
