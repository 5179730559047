import { createRouter, createWebHistory } from "vue-router";
import SearchPotentialCustomer from "@/views/SearchPotentialCustomer.vue";
import SearchAdjustmentsDeductions from "@/views/SearchAdjustmentsDeductions.vue";
export const routes = [
  // Potential Customer Management
  {
    path: "/potential-customer/search",
    name: "searchPotentialCustomer",
    component: SearchPotentialCustomer,
  },
  {
    path: "/potential-customer/individual/new",
    name: "CreatePotentialCustomerIndividual",
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("@/views/CreatePotentialCustomerIndividual.vue"),
  },
  {
    path: "/potential-customer/corporation/new",
    name: "CreatePotentialCustomerCorporation",
    component: () => import("@/views/CreatePotentialCustomerCorporation.vue"),
  },
  {
    // Dynamic Route Matching with Params
    // https://router.vuejs.org/guide/essentials/dynamic-matching.html#dynamic-route-matching-with-params
    path: "/potential-customer/individual/:id",
    name: "EditPotentialCustomerIndividual",
    component: () => import("@/views/EditPotentialCustomerIndividual.vue"),
    // https://router.vuejs.org/guide/essentials/passing-props.html#passing-props-to-route-components
    props: true,
  },
  {
    path: "/potential-customer/corporation/:id",
    name: "EditPotentialCustomerCorporation",
    component: () => import("@/views/EditPotentialCustomerCorporation.vue"),
    // https://router.vuejs.org/guide/essentials/passing-props.html#passing-props-to-route-components
    props: true,
  },

  // Contact Reports Management

  {
    path: "/contract-reports/:contractorType/:insuranceType/new",
    name: "CreateContractReport",
    component: () => import("@/views/CreateContractReport.vue"),
    props: true,
  },

  {
    path: "/contract-reports/:contractorType/:insuranceType/:id",
    name: "EditContractReport",
    component: () => import("@/views/EditContractReport.vue"),
    props: true,
  },
  {
    path: "/contract-reports/:insuranceCategory/search",
    name: "SearchContractReportsLifeNonLife",
    component: () => import("@/views/SearchContractReports.vue"),
    props: true,
  },
  {
    path: "/contract-reports/search",
    name: "SearchContractReports",
    component: () => import("@/views/SearchContractReports.vue"),
  },
  {
    path: "/adjustments-deductions/search",
    name: "SearchAdjustmentsDeductions",
    component: SearchAdjustmentsDeductions,
  },
  {
    path: "/adjustments-deductions/new",
    name: "CreateAdjustmentDeduction",
    component: () => import("@/views/CreateAdjustmentDeduction.vue"),
  },
  {
    path: "/adjustments-deductions/:staffId/:adjustmentDeductionId",
    name: "EditAdjustmentDeduction",
    component: () => import("@/views/EditAdjustmentDeduction.vue"),
    props: true,
  },
  {
    path: "/department-management/sandbox",
    name: "DepartmentManagementSandbox",
    component: () => import("@/views/DepartmentManagementSandbox.vue"),
    props: true,
  },
  {
    path: "/department-management/:departmentId",
    name: "EditDepartmentManagement ",
    component: () => import("@/views/EditDepartment.vue"),
    props: true,
  },
  {
    path: "/department-management/new",
    name: "CreateDepartmentManagement",
    component: () => import("@/views/CreateDepartment.vue"),
  },
  {
    path: "/department-management/search",
    name: "SearchDepartmentManagement ",
    component: () => import("@/views/SearchDepartments.vue"),
    props: true,
  },
  {
    path: "/user-management/search",
    name: "SearchUserManagement",
    component: () => import("@/views/SearchUsers.vue"),
    props: true,
  },
  {
    path: "/user-management/new",
    name: "CreateUserManagement",
    component: () => import("@/views/CreateUserManagement.vue"),
    props: true,
  },
  {
    path: "/user-management/:staffId",
    name: "EditUserManagement",
    component: () => import("@/views/EditUserManagement.vue"),
    props: true,
  },
  {
    path: "/securities-management/search/:insuranceCategory",
    name: "SearchSecuritiesManagementLifeNonLife",
    component: () => import("@/views/SearchSecurities.vue"),
    props: true,
  },
  {
    path: "/securities-management/sandbox",
    name: "SecuritiesManagementSandbox",
    component: () => import("@/views/SecuritiesManagementSandbox.vue"),
    props: true,
  },
  {
    path: "/securities-management/:contractorType/:insuranceType/new",
    name: "CreateSecuritiesManagement",
    component: () => import("@/views/CreateSecurity.vue"),
    props: true,
  },
  {
    path: "/securities-management/:contractorType/:insuranceType/:securitiesId",
    name: "EditSecurity",
    component: () => import("@/views/EditSecurity.vue"),
    props: true,
  },
  {
    path: "/adjustment-deduction-types/search",
    name: "SearchAdjustmmentDeductionTypes",
    component: () => import("@/views/SearchAdjustmentDeductionTypes.vue"),
  },
  {
    path: "/adjustment-deduction-types/new",
    name: "CreateAdjustmmentDeductionType",
    component: () => import("@/views/CreateAdjustmentDeductionType.vue"),
  },
  {
    path: "/adjustment-deduction-types/:adjustmentDeductionTypeId",
    name: "EditAdjustmmentDeductionType",
    component: () => import("@/views/EditAdjustmentDeductionType.vue"),
    props: true,
  },
  {
    path: "/insurance-company-management/search",
    name: "SearchInsuranceCompany",
    component: () => import("@/views/SearchInsuranceCompany.vue"),
    props: true,
  },
  {
    path: "/insurance-company-management/new",
    name: "CreateInsuranceCompany",
    component: () => import("@/views/CreateInsuranceCompany.vue"),
    props: true,
  },
  {
    path: "/insurance-company-management/:insuranceCompanyId",
    name: "EditInsuranceCompany",
    component: () => import("@/views/EditInsuranceCompany.vue"),
    props: true,
  },
  {
    path: "/market-management/search",
    name: "SearchMarketManagement",
    component: () => import("@/views/SearchMarketManagement.vue"),
    props: true,
  },
  {
    path: "/market-management/new",
    name: "CreateMarketManagement",
    component: () => import("@/views/CreateMarketManagement.vue"),
  },
  {
    path: "/market-management/:marketId",
    name: "EditMarketManagement",
    component: () => import("@/views/EditMarketManagement.vue"),
    props: true,
  },
  {
    path: "/insurance-type-management/search",
    name: "SearchInsuranceTypeManagement",
    component: () => import("@/views/SearchInsuranceType.vue"),
  },
  {
    path: "/insurance-type-management/new",
    name: "CreateInsuranceTypeManagement",
    component: () => import("@/views/CreateInsuranceType.vue"),
  },
  {
    path: "/insurance-type-management/:insuranceTypeId",
    name: "EditInsuranceTypeManagement",
    component: () => import("@/views/EditInsuranceType.vue"),
    props: true,
  },
  {
    path: "/commission-and-performance-based-pay-calculation/sandbox",
    name: "CommissionAndPerformanceBasedPayCalculationSandbox",
    component: () =>
      import("@/views/CommissionAndPerformanceBasedPayCalculationSandbox.vue"),
    props: true,
  },
  {
    path: "/commission-and-performance-based-pay-calculation/save-commission-data/:month/:insuranceCompanyId/:commissionCategory/",
    name: "SaveCommissionData",
    component: () => import("@/views/SaveCommissionData.vue"),
    props: true,
  },
  {
    path: "/commission-and-performance-based-pay-calculation/ingest-commission/:defaultMonthValue/",
    name: "IngestCommission",
    component: () =>
      import(
        "@/components/CommissionAndPerformanceBasedPayCalculation/IngestCommission.vue"
      ),
    props: true,
  },
  {
    path: "/commission-and-performance-based-pay-calculation/check-status",
    name: "CheckCommissionIngestionStatus",
    component: () => import("@/views/CheckCommissionIngestionStatus.vue"),
    props: true,
  },
  {
    path: "/commission-and-performance-based-pay-calculation/commission-matching/:month/:commissionCategory/:commissionImportId/:insuranceCompanyId",
    name: "CommissionMatching",
    component: () => import("@/views/CommissionMatching.vue"),
    props: true,
  },
  {
    path: "/performance-based-pay-calculation-summary",
    name: "PerformanceBasedPayCalculationResultsSummary",
    component: () =>
      import("@/views/PerformanceBasedPayCalculationResultsSummary.vue"),
  },
  {
    path: "/performance-based-pay-slip",
    name: "PerformanceBasedPaySlip",
    component: () => import("@/views/PerformanceBasedPaySlip.vue"),
  },
  {
    path: "/commission-insurance-company-calculation",
    name: "CommissionInsuranceCompanyCalculationResults",
    component: () =>
      import("@/views/CommissionInsuranceCompanyCalculationResults.vue"),
  },
  {
    path: "/monthly-payment-contract-statement",
    name: "MonthlyPaymentStatementForContract",
    component: () => import("@/views/MonthlyPaymentStatementForContract.vue"),
  },
  {
    path: "/purchase-potential-customer-management/search",
    name: "SearchPurchasePotentialCustomerManagement",
    component: () =>
      import("@/views/SearchPurchasePotentialCustomerManagement.vue"),
  },
  {
    path: "/purchase-potential-customer-management/new",
    name: "CreatePurchasePotentialCustomerManagement",
    component: () =>
      import("@/views/CreatePurchasePotentialCustomerManagement.vue"),
  },
  {
    path: "/purchase-potential-customer-management/:purchasePotentialCustomerId",
    name: "EditPurchasePotentialCustomerManagement",
    component: () =>
      import("@/views/EditPurchasePotentialCustomerManagement.vue"),
    props: true,
  },
  {
    path: "/staff-code-mapping-management/search",
    name: "SearchStaffCodeMappingManagement",
    component: () => import("@/views/SearchStaffCodeMapping.vue"),
  },
  {
    path: "/staff-code-mapping-management/new",
    name: "CreateStaffCodeMappingManagement",
    component: () => import("@/views/CreateStaffCodeMapping.vue"),
  },
  {
    path: "/staff-code-mapping-management/:staffId/:insuranceCompanyId",
    name: "EditStaffCodeMappingManagement",
    component: () => import("@/views/EditStaffCodeMapping.vue"),
    props: true,
  },
  {
    path: "/operation-logs/search",
    name: "SearchOperationLogs",
    component: () => import("@/views/SearchOperationLogs.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
