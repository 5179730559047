export const dummyPerformanceBasedPayResults = [
  {
    staff: {
      staffId: "staff123",
      staffNumber: "S001",
      firstName: "インボイス",
      lastName: "山田",
      departmentId: "D100",
      departmentName: "インボイス支店",
    },
    commission: {
      total: 20000,
      lifeInsuranceCommission: 10000,
      nonLifeInsuranceCommission: 10000,
    },
    basePoint: {
      total: -20000,
      basicSalary: 300000,
      basicSalaryConsumptionTax: 30000,
      entryFee: 5000,
      healthInsurancePremium: -20000,
      longTermCareInsurancePremium: -15000,
      welfarePensionPremium: -25000,
      childCareContribution: -5000,
      generalContributionForBasicSalary: -3000,
      employmentInsurancePremiumForBasicSalary: -4000,
      industrialAccidentInsurancePremiumForBasicSalary: -1000,
    },
    socialInsurancePremiumsForPerformanceBasedPay: {
      total: -20000,
      estimatedPerformanceBasedPay: 250000,
    },
    purchasePotentialCustomer: {
      total: -50000,
      purchasePotentialCustomers: [
        {
          marketId: "M001",
          marketName: "Tokyo Market",
          purchasePotentialCustomerId: "PC100",
          purchasePotentialCustomerFee: 50000,
          purchasePotentialCustomerName: "Big Corp",
        },
      ],
      marketDeductions: [
        {
          adjustmentDeductionId: "AD001",
          adjustmentDeductionFee: -5000,
          adjustmentDeductionTypeId: "MD001",
          adjustmentDeductionTypeName: "Market Fees",
          adjustmentDeductionName: "Fee Adjustment",
        },
      ],
    },
    deductionForValuables: {
      total: -10000,
      deductions: [
        {
          adjustmentDeductionId: "AD002",
          adjustmentDeductionFee: -10000,
          adjustmentDeductionTypeId: "VD001",
          adjustmentDeductionTypeName: "Valuable Goods",
          adjustmentDeductionName: "High Value Item Fee",
        },
      ],
    },
    performanceBasedPayTransfer: {
      total: -20000,
      performanceBasedPayTransfers: [
        {
          adjustmentDeductionId: "AD003",
          adjustmentDeductionFee: -20000,
          adjustmentDeductionTypeId: "PT001",
          adjustmentDeductionTypeName: "Performance Pay",
          adjustmentDeductionName: "Monthly Bonus Adjustment",
        },
      ],
    },
    otherDeduction: {
      total: -15000,
      deductions: [
        {
          adjustmentDeductionId: "AD004",
          adjustmentDeductionFee: -15000,
          adjustmentDeductionTypeId: "OD001",
          adjustmentDeductionTypeName: "Miscellaneous",
          adjustmentDeductionName: "Misc Deduction",
        },
      ],
    },
    adjustment: {
      total: 20000,
      adjustments: [
        {
          adjustmentDeductionId: "AD005",
          adjustmentDeductionFee: 20000,
          adjustmentDeductionTypeId: "AJ001",
          adjustmentDeductionTypeName: "Adjustment",
          adjustmentDeductionName: "Correction",
        },
      ],
    },
    invoiceDeductionFee: -4328884,
    performanceBasedPayCalculationResult: {
      performanceBasedPay: 200000,
      amountCarriedForward: -20000,
      amountCarriedForwardFromPreviousMonth: -20000,
      salesmanReward: 200000,
      businessIncomeTax: -20000,
    },
  },
  {
    staff: {
      firstName: "雄司",
      lastName: "山本",
      staffId: 1234567,
      staffNumber: "65432",
      departmentName: "名古屋支店",
      departmentId: 1234567,
    },
    commission: {
      total: 20000,
      lifeInsuranceCommission: 20000,
      nonLifeInsuranceCommission: 20000,
    },
    basePoint: {
      total: 20000,
      basicSalary: 150000,
      basicSalaryConsumptionTax: 15000,
      entryFee: 20000,
      healthInsurancePremium: 20000,
      longTermCareInsurancePremium: 20000,
      welfarePensionPremium: 20000,
      childCareContribution: 20000,
      generalContributionForBasicSalary: 20000,
      employmentInsurancePremiumForBasicSalary: 20000,
      industrialAccidentInsurancePremiumForBasicSalary: 20000,
    },
    socialInsurancePremiumsForPerformanceBasedPay: {
      total: 20000,
      estimatedPerformanceBasedPay: 20000,
    },
    purchasePotentialCustomer: {
      total: 20000,
      purchasePotentialCustomers: [
        {
          marketId: 654633643,
          marketName: "Market Market",
          purchasePotentialCustomerId: 543535,
          purchasePotentialCustomerFee: 20000,
          purchasePotentialCustomerName: "YamanakaValerie",
        },
        {
          marketId: 1234567,
          marketName: "アイティーソリューションズ マーケット",
          purchasePotentialCustomerId: 1234567,
          purchasePotentialCustomerFee: 5466536,
          purchasePotentialCustomerName: "大澤一誠",
        },
      ],
      marketDeductions: [
        {
          adjustmentDeductionId: 1234567,
          adjustmentDeductionFee: 200000,
          adjustmentDeductionTypeId: 1234567,
          adjustmentDeductionTypeName: "アシスタント費",
          adjustmentDeductionName: "アシスタント一郎",
        },
      ],
    },
    deductionForValuables: {
      total: 20000,
      deductions: [
        {
          adjustmentDeductionId: 1234567,
          adjustmentDeductionFee: 200000,
          adjustmentDeductionTypeId: 1234567,
          adjustmentDeductionTypeName: "アシスタント費",
          adjustmentDeductionName: "アシスタント一郎",
        },
        {
          adjustmentDeductionId: 43242,
          adjustmentDeductionFee: 54353,
          adjustmentDeductionTypeId: 5453353,
          adjustmentDeductionTypeName: "Test費",
          adjustmentDeductionName: "Test郎",
        },
      ],
    },
    performanceBasedPayTransfer: {
      total: 20000,
      performanceBasedPayTransfers: [
        {
          adjustmentDeductionId: 64267678,
          adjustmentDeductionFee: 9536536753,
          adjustmentDeductionTypeId: 1234567,
          adjustmentDeductionTypeName: "Performance BasedP ay Transfers",
          adjustmentDeductionName: "performanceBasedPayTransfers",
        },
      ],
    },
    otherDeduction: {
      total: 20000,
      deductions: [
        {
          adjustmentDeductionId: 1234567,
          adjustmentDeductionFee: 200000,
          adjustmentDeductionTypeId: 1234567,
          adjustmentDeductionTypeName: "アシスタント費",
          adjustmentDeductionName: "アシスタント一郎",
        },
      ],
    },
    adjustment: {
      total: 20000,
      adjustments: [
        {
          adjustmentDeductionId: 1234567,
          adjustmentDeductionFee: 200000,
          adjustmentDeductionTypeId: 1234567,
          adjustmentDeductionTypeName: "Adjustment郎",
          adjustmentDeductionName: "Adjustment郎",
        },
      ],
    },
    performanceBasedPayCalculationResult: {
      performanceBasedPay: 200000,
      amountCarriedForward: 20000,
      amountCarriedForwardFromPreviousMonth: 20000,
      salesmanReward: 200000,
      consumptionTax: 20000,
      consumptionTaxPayment: 20000,
      businessIncomeTax: 20000,
    },
    invoiceDeductionFee: -20000,
  },

  //
  {
    staff: {
      firstName: "ルカ",
      lastName: "山中",
      staffId: 1234568,
      staffNumber: "7809A",
      departmentName: "本店",
      departmentId: 1,
    },
    commission: {
      total: -3245245,
      lifeInsuranceCommission: -4324,
      nonLifeInsuranceCommission: -4546,
    },
    basePoint: {
      total: -8678,
      basicSalary: -3600,
      basicSalaryConsumptionTax: -15000356,
      entryFee: -3650,
      healthInsurancePremium: -6536,
      longTermCareInsurancePremium: -20653600,
      welfarePensionPremium: -4200,
      childCareContribution: -2000434420,
      generalContributionForBasicSalary: -432432,
      employmentInsurancePremiumForBasicSalary: -675765,
      industrialAccidentInsurancePremiumForBasicSalary: -32432,
    },
    socialInsurancePremiumsForPerformanceBasedPay: {
      total: -4324,
      estimatedPerformanceBasedPay: 23423,
    },
    purchasePotentialCustomer: {
      total: -23423,
      purchasePotentialCustomers: [
        {
          marketId: 1234567,
          marketName: "ITZマーケット",
          purchasePotentialCustomerId: 1234567,
          purchasePotentialCustomerFee: -20000,
          purchasePotentialCustomerName: "ITZマーケット 大澤一誠",
        },
      ],
      marketDeductions: [
        {
          adjustmentDeductionId: 1234567,
          adjustmentDeductionFee: -200000,
          adjustmentDeductionTypeId: 1234567,
          adjustmentDeductionTypeName: "アシスタント費",
          adjustmentDeductionName: "アシスタント一郎",
        },
      ],
    },
    deductionForValuables: {
      total: -20000,
      deductions: [
        {
          adjustmentDeductionId: 1234567,
          adjustmentDeductionFee: -200000,
          adjustmentDeductionTypeId: 1234567,
          adjustmentDeductionTypeName: "アシスタント費",
          adjustmentDeductionName: "アシスタント一郎",
        },
      ],
    },
    performanceBasedPayTransfer: {
      total: -20000,
      performanceBasedPayTransfers: [
        {
          adjustmentDeductionId: 1234567,
          adjustmentDeductionFee: -200000,
          adjustmentDeductionTypeId: 1234567,
          adjustmentDeductionTypeName: "アシスタント費",
          adjustmentDeductionName: "アシスタント一郎",
        },
      ],
    },
    otherDeduction: {
      total: -20000,
      deductions: [
        {
          adjustmentDeductionId: 1234567,
          adjustmentDeductionFee: -200000,
          adjustmentDeductionTypeId: 1234567,
          adjustmentDeductionTypeName: "アシスタント費",
          adjustmentDeductionName: "アシスタント一郎",
        },
      ],
    },
    adjustment: {
      total: -20000,
      adjustments: [
        {
          adjustmentDeductionId: 1234567,
          adjustmentDeductionFee: -200000,
          adjustmentDeductionTypeId: 1234567,
          adjustmentDeductionTypeName: "アシスタント費",
          adjustmentDeductionName: "アシスタント一郎",
        },
      ],
    },
    performanceBasedPayCalculationResult: {
      performanceBasedPay: -200000,
      amountCarriedForward: -40000,
      amountCarriedForwardFromPreviousMonth: -20000,
      salesmanReward: -200000,
      consumptionTax: -20000,
      consumptionTaxPayment: -20000,
      businessIncomeTax: -20000,
    },
    invoiceDeductionFee: -20000,
  },
];

export const dummyResultOfGetPerformanceBasedPayCheck = [
  {
    month: "202308",
    noCommissionImportedInsuranceCompanies: [
      {
        insuranceCompanyId: 8579611,
        insuranceCompanyName: "アイティーソリューションズ生命",
      },
      {
        insuranceCompanyId: 8091021,
        insuranceCompanyName: "クラウドカバレッジ",
      },
      {
        insuranceCompanyId: 4583228,
        insuranceCompanyName: "テックインシュアランス",
      },
    ],
    noCommissionCalculatedInsuranceCompanies: [
      {
        insuranceCompanyId: 3885191,
        insuranceCompanyName: "データプロテクト生命",
      },
      {
        insuranceCompanyId: 5121135,
        insuranceCompanyName: "ソフトウェアセキュア",
      },
    ],
  },
];
