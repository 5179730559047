import _ from "lodash-es";

export let dummyInsuranceCompanies = [
  {
    insuranceCompanyId: 1234567,
    insuranceCompanyName: "アイティーソリューションズ生命",
    consumptionTaxIncludeFlag: true,
    insuranceCategory: "nonLifeInsurance",
    sortNumber: 10,
  },
  {
    insuranceCompanyId: 1111111,
    insuranceCompanyName: "USA Insurance",
    consumptionTaxIncludeFlag: false,
    insuranceCategory: "nonLifeInsurance",
    sortNumber: 20,
  },
  {
    insuranceCompanyId: 2222222,
    insuranceCompanyName: "第一生命",
    consumptionTaxIncludeFlag: false,
    insuranceCategory: "lifeInsurance",
    sortNumber: 30,
  },
  {
    insuranceCompanyId: 3333333,
    insuranceCompanyName: "日本生命",
    consumptionTaxIncludeFlag: true,
    insuranceCategory: "lifeInsurance",
    sortNumber: 40,
  },
  {
    insuranceCompanyId: 4444444,
    insuranceCompanyName: "日本興亜",
    consumptionTaxIncludeFlag: true,
    insuranceCategory: "lifeInsurance",
    sortNumber: 50,
  },
  {
    insuranceCompanyId: 5555555,
    insuranceCompanyName: "丸山生命",
    consumptionTaxIncludeFlag: false,
    insuranceCategory: "lifeInsurance",
    sortNumber: 60,
  },
];

// Replace function to use with mock Put requests.
export function replace(oldRecord: any, updatedRecord: any) {
  dummyInsuranceCompanies = _.concat(
    _.filter(dummyInsuranceCompanies, (record) => record !== oldRecord),
    updatedRecord
  );
  sortBySortNumber();
}

// function to sort the data by 'sortNumber' in ascending order
function sortBySortNumber(): void {
  dummyInsuranceCompanies.sort((a, b) => a.sortNumber - b.sortNumber);
}

// add new data, while also sorting by sortNumber
export function addNewData(newData: any): void {
  dummyInsuranceCompanies.push(newData);
  sortBySortNumber();
}
