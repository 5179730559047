import { storeToRefs } from "pinia";
import type {
  GetSecuritiesList200Response,
  GetSecuritiesList200ResponseResultsInner,
} from "@/api";
import { rest } from "msw";
import {
  dummyGetSecuritiesList200Response,
  dummySecurities,
} from "@/mocks/Securities/dummy-securities";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);
import randomInteger from "random-int";
import { useCommissionAndPerformanceBasedPayCalculationStore } from "@/stores/CommissionAndPerformanceBasedPayCalculation/CommissionAndPerformanceBasedPayCalculation";

/**
 * Converts a string to a boolean value.
 * @param str - The string to convert.
 * @returns The boolean value.
 */
function stringToBoolean(str: string): boolean {
  return str === "true";
}
export const handlers = [
  /**
   *
   * PostSecuritiesCommissionMatching
   */
  rest.post(
    `${BASE_PATH}/securities/commissionMatching`,
    async (req, res, ctx) => {
      const request = await req.json();
      const commissionId = request.commissionId;
      const contractReportId = request.contractReportId;

      console.log(
        "PostSecuritiesCommissionMatching - commissionId: " +
          commissionId +
          ", contractReportId: " +
          contractReportId
      );
      return res(ctx.status(200), ctx.json(randomInteger(1000000, 9999999)));
    }
  ),
  /**
   *
   * GetSecuritiesList
   */
  rest.get(`${BASE_PATH}/securities`, (req, res, ctx) => {
    if (import.meta.env.VITE_ENV !== "test") {
      if (Math.random() < 0.05) {
        // Return 500 error 95% of the time except for test environment
        return res(
          ctx.status(500),
          ctx.json({
            message: "Internal Server Error, but this is created intentionally",
          })
        );
      }
    }

    const authorizedFilterFlag = req.url.searchParams.get(
      "authorizedFilterFlag"
    );
    const attribute = req.url.searchParams.get("contractorAttribute");

    const insuranceCategory = req.url.searchParams.get("insuranceCategory");
    const keyword = req.url.searchParams.get("keyword");
    const potentialCustomerId = req.url.searchParams.get("potentialCustomerId");
    const staffId = req.url.searchParams.get("staffId");
    const departmentId = req.url.searchParams.get("departmentId");
    const policyNumber = req.url.searchParams.get("policyNumber");
    const insuranceCompanyId = req.url.searchParams.get("insuranceCompanyId");
    const shareStaffId = req.url.searchParams.get("shareStaffId");

    let filteredSecurities = dummySecurities;

    filteredSecurities = filteredSecurities.filter(
      (security) => security.contractorAttribute == attribute
    );

    // NOTE: Intentinally this mock API doesn't handle `authorizedFilterFlag` from query params.
    // if (authorizedFilterFlag) {
    //   // return an empty array if authorizationFlag is true
    //   if (stringToBoolean(authorizedFilterFlag) == true) {
    //     return res(ctx.status(200), ctx.json({ results: [] }));
    //   }
    // }

    if (insuranceCategory) {
      filteredSecurities = filteredSecurities?.filter((security) => {
        return security?.insuranceCategory == insuranceCategory;
      });
    }
    if (potentialCustomerId != undefined) {
      filteredSecurities = filteredSecurities?.filter((security) => {
        return security?.potentialCustomerId == Number(potentialCustomerId);
      });
    }
    if (staffId != undefined) {
      filteredSecurities = filteredSecurities?.filter((security) => {
        return security?.staff.staffId == Number(staffId);
      });
    }
    if (departmentId) {
      filteredSecurities = filteredSecurities?.filter((security) => {
        return security?.staff.departmentId == Number(departmentId);
      });
    }
    if (policyNumber) {
      filteredSecurities = filteredSecurities?.filter((security) => {
        return security?.insuranceInfo?.policyNumber == policyNumber;
      });
    }
    if (insuranceCompanyId) {
      filteredSecurities = filteredSecurities?.filter((security) => {
        return (
          security?.insuranceInfo?.insuranceCompanyId ==
          Number(insuranceCompanyId)
        );
      });
    }

    if (keyword) {
      filteredSecurities = filteredSecurities?.filter((security) =>
        `${security.contractorIndividual?.firstName?.toLowerCase()}
        ${security.contractorIndividual?.lastName?.toLowerCase()}
        ${security.contractorIndividual?.lastNameKana?.toLowerCase()}
        ${security.contractorIndividual?.firstNameKana?.toLowerCase()}
        ${security.insured?.firstName?.toLowerCase()}
        ${security.insured?.lastName?.toLowerCase()}
        ${security.insured?.lastNameKana?.toLowerCase()}
        ${security.insured?.firstNameKana?.toLowerCase()}
        ${security.staff.firstName.toLowerCase()}
        ${security.staff.lastName.toLowerCase()}
        ${security.contractorCorporation?.corporationName.toLowerCase()}

        }`.includes(keyword?.toLowerCase() as string)
      );
    }

    if (shareStaffId) {
      filteredSecurities = filteredSecurities?.filter((security) =>
        [
          "otherStaffNumber1",
          "otherStaffNumber2",
          "otherStaffNumber3",
          "bossId",
        ].some(
          (property) => security?.commissionShare?.[property] === shareStaffId
        )
      );
    }
    let convertedSecurities: GetSecuritiesList200ResponseResultsInner[] = [];
    if (filteredSecurities && filteredSecurities.length > 0) {
      convertedSecurities =
        dummyGetSecuritiesList200Response(filteredSecurities);
    }

    const response = {
      results: convertedSecurities,
      pagination: {
        totalCount: convertedSecurities ? convertedSecurities.length : 0,
        currentPage: 0,
        lastPage: 0,
      },
    } as GetSecuritiesList200Response;
    return res(ctx.status(200), ctx.json(response));
  }),

  /**
   *
   * PostSecuritiesCsv
   */
  rest.post(`${BASE_PATH}/securities/csv`, async (req, res, ctx) => {
    const filename = req.params.filename;
    const file = req.params.file;

    console.log(
      "PostSecuritiesCsv - filename: " + filename ??
        undefined + ", file: " + file
    );

    const { queryCommissions } = storeToRefs(
      useCommissionAndPerformanceBasedPayCalculationStore()
    );
    queryCommissions.value?.commissions.push(
      {
        commissionId: 6546754367,
        policyNumberFromCsv: "AA123456",
        contractor: {
          contractorNameFromCsv: "Import Name",
          firstName: "Name",
          lastName: "Import",
        },
        insured: {
          insuredNameFromCsv: "Import Name",
          firstName: "Name",
          lastName: "Import",
        },
        staff: {
          staffId: 54355,
          staffNumber: "54355A",
          staffNameFromCsv: "Import Name",
          staffCodeFromCsv: "123456",
          firstName: "Name",
          lastName: "Import",
        },
        insuranceInfo: {
          commissionFromCsv: 100,
          insuranceFeeFromCsv: 1000,
          insuranceTypeFromCsv: "Import Insurance",
          contractDateFromCsv: "2022年12月01日",
          contractDateOn: "2022-07-21",
          firstYearFlag: true,
        },
      },
      {
        commissionId: 8647,
        policyNumberFromCsv: "643664356",
        contractor: {
          contractorNameFromCsv: "Import Two",
          firstName: "Two",
          lastName: "Import",
        },
        insured: {
          insuredNameFromCsv: "Import Two",
          firstName: "Two",
          lastName: "Import",
        },
        staff: {
          staffId: 8475373,
          staffNumber: "00753736123",
          staffNameFromCsv: "Import Two",
          staffCodeFromCsv: "123456",
          firstName: "Two",
          lastName: "Import",
        },
        insuranceInfo: {
          commissionFromCsv: 100,
          insuranceFeeFromCsv: 200,
          insuranceTypeFromCsv: "Import Two Insurance",
          contractDateFromCsv: "2022年12月01日",
          contractDateOn: "2022-07-21",
          firstYearFlag: true,
        },
      }
    );

    const response = {
      importCount: randomInteger(1, 1000),
    };
    return res(ctx.status(200), ctx.json(response));
  }),

  /**
   *
   * GetSecuritiesCsv
   */
  rest.get(`${BASE_PATH}/securities/csv`, (req, res, ctx) => {
    // return a tantative CSV file
    const csvData = `"header1","header2","header3"
"row1col1","row1col2","row1col3"
"row2col1","row2col2","③㈱髙"`;

    return res(
      ctx.status(200),
      ctx.set("Content-Type", "text/csv"),
      ctx.set("Content-Disposition", `attachment;`),
      ctx.body(csvData)
    );
  }),

  /**
   *
   * PostSecuritiesShareCsv
   */
  rest.post(`${BASE_PATH}/securities/share/csv`, (req, res, ctx) => {
    // return a dummy response
    return res(
      ctx.status(200),
      ctx.json({
        importCount: 123456789,
      })
    );
  }),
];
