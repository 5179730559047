import type { PotentialCustomerIndividualScreenItem } from "@/types/PotentialCustomer/potential-customer-individual";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useStaffInfoStore = defineStore("staff-info", () => {
  // initialize state
  const currentItem = ref<PotentialCustomerIndividualScreenItem["staff"]>({
    // initialize all properties of StaffInfo
    // regardless of whether they are required or optional
    staffId: undefined,
    authorizedStaffIds: [],
  });

  return {
    currentItem,
  };
});
