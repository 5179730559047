<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { createInput } from "@formkit/vue";
import AutocompleteComponent from "@/components/AutocompleteComponent/AutocompleteComponent.vue";

const acc = createInput(AutocompleteComponent, {
  props: ["isEditable", "isOptional", "staffId", "id"],
});

const currentStaffId = ref<number | undefined>();
const currentStaffIdString = computed({
  get: () => currentStaffId.value?.toString() || "",
  set: (val: string | undefined) => {
    if (val === undefined) {
      currentStaffId.value = undefined;
      return;
    }
    if (currentStaffId.value !== Number(val)) {
      currentStaffId.value = val ? Number(val) : undefined;
    }
  },
});

const props = withDefaults(
  defineProps<{
    isEditable: boolean;
    isOptional: boolean;
    label: string;
    name: string;
    staffId: number;
    id: string;
    validation: string;
  }>(),
  {
    isEditable: true,
    isOptional: false,
    label: "募集人",
    name: "AutoCompleteStaff",
  }
);

const emits = defineEmits<{
  // emit the event when currentStaffId is updated
  (e: "update:modelValue", value: any): void;
}>();

watch(
  () => currentStaffId.value,
  (newValue, oldValue) => {
    // Guard against a calling loop by checking if the new value is the same as the old value.
    if (newValue === oldValue) return;
    emits("update:modelValue", newValue);
  }
);
</script>

<template>
  <FormKit
    v-model="currentStaffIdString"
    :id="id"
    :type="acc"
    :label="label"
    :name="name"
    inner-class="$reset"
    :validation="validation"
    validation-visibility="live"
    :staff-id="staffId"
    :is-optional="isOptional"
    :is-editable="isEditable"
    :disabled="!isEditable"
  />
</template>
