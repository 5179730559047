import { rest } from "msw";
import { getPurchasePotentialCustomersResults } from "@/mocks/PurchasePotentialCustomer/dummy-data";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import _ from "lodash-es";
import type { GetPurchasePotentialCustomers200ResponseResultsInner } from "@/api";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

function stringToBoolean(str: string | null) {
  if (_.isEqual(str, "true")) {
    return true;
  } else if (
    _.isEqual(str, "false") ||
    _.isEqual(str, null) ||
    _.isEqual(str, undefined)
  ) {
    return false;
  }
  // 任意で、無効な入力に対する処理をここに追加することができます
}

/**e
 * PurchasePotentialCustomerQuery:
 * GetPurchasePotentialCustomers

 **/

export const handlers = [
  // GetPurchasePotentialCustomers API
  rest.get(`${BASE_PATH}/purchasePotentialCustomer`, (req, res, ctx) => {
    const marketId = Number(req.url.searchParams.get("marketId"));
    const potentialCustomerStaffId = Number(
      req.url.searchParams.get("potentialCustomerStaffId")
    );
    const staffId = Number(req.url.searchParams.get("staffId"));

    // currently not used as the dummy data does not include date info
    const purchasePotentialCustomerMonth = req.url.searchParams.get(
      "purchasePotentialCustomerMonth"
    );

    const purchasePotentialCustomerScopeFlag = stringToBoolean(
      req.url.searchParams.get("purchasePotentialCustomerScopeFlag")
    );

    // currently not used as the dummy data does not include date info
    const purchasePotentialCustomerMonthNotSetFlag = stringToBoolean(
      req.url.searchParams.get("purchasePotentialCustomerMonthNotSetFlag")
    );
    const potentialCustomerIdSetFlag = stringToBoolean(
      req.url.searchParams.get("potentialCustomerIdSetFlag")
    );

    let results: GetPurchasePotentialCustomers200ResponseResultsInner[] =
      getPurchasePotentialCustomersResults;

    if (marketId) {
      results = results.filter((result) => {
        return result?.potentialCustomer.marketId == marketId;
      });
    }
    if (potentialCustomerStaffId) {
      results = results.filter((result) => {
        return (
          result?.potentialCustomer.staff?.staffId == potentialCustomerStaffId
        );
      });
    }
    if (staffId) {
      results = results.filter((result) => {
        return result?.staff?.staffId == staffId;
      });
    }
    if (purchasePotentialCustomerScopeFlag) {
      results = results.filter((result) => {
        return (
          result?.purchasePotentialCustomerOutOfScopeFlag ==
          !purchasePotentialCustomerScopeFlag
        );
      });
    }
    if (potentialCustomerIdSetFlag) {
      results = results.filter((result) => {
        return result?.potentialCustomer.potentialCustomerId !== undefined;
      });
    }

    return res(
      ctx.status(200),
      ctx.json({
        results: results,
        pagination: {
          totalCount: 0,
        },
      }),
      ctx.delay(200)
    );
  }),
];
