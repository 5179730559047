import _ from "lodash";

export function replace(oldDepartment: any, updatedDepartment: any) {
  baseDepartments = _.concat(
    _.filter(baseDepartments, (department) => department !== oldDepartment),
    updatedDepartment
  );
}

// The department master list, containing all information needed for get/query requests.
export let baseDepartments = [
  {
    departmentId: 1,
    departmentName: "本店",
    boss: [
      {
        staffId: 7777,
        staffNumber: "7777",
        firstName: "Valerie",
        lastName: "Yamanaka",
        tenureAtFrom: "2022-07-22",
        tenureAtTo: undefined,
      },
      {
        staffId: 1234567,
        staffNumber: "1234567",
        firstName: "ルカ",
        lastName: "山中",
        tenureAtFrom: "2021-07-21",
        tenureAtTo: "2022-07-21",
      },
    ],
    authorizedStaff: [
      {
        staffId: 1234567,
        tenureAtFrom: "2022-07-22",
        tenureAtTo: undefined,
      },
    ],
    authorizedPerformanceBasedPay: [
      {
        staffId: 1234567,
        tenureAtFrom: "2022-07-22",
        tenureAtTo: undefined,
      },
      {
        staffId: 7777,
        tenureAtFrom: "2021-07-21",
        tenureAtTo: "2022-07-21",
      },
    ],
    sortNumber: 10,
  },
  {
    departmentId: 2,
    departmentName: "名古屋支店",
    boss: [
      {
        staffId: 1234567,
        staffNumber: "1234567",
        firstName: "ルカ",
        lastName: "山中",
        tenureAtFrom: "2022-07-22",
        tenureAtTo: undefined,
      },
    ],
    authorizedStaff: [
      {
        staffId: 1234567,
        tenureAtFrom: "2022-07-22",
        tenureAtTo: undefined,
      },
      {
        staffId: 7777,
        tenureAtFrom: "2021-07-21",
        tenureAtTo: "2022-07-21",
      },
    ],
    authorizedPerformanceBasedPay: [
      {
        staffId: 1234567,
        tenureAtFrom: "2022-07-22",
        tenureAtTo: undefined,
      },
      {
        staffId: 7777,
        tenureAtFrom: "2021-07-21",
        tenureAtTo: "2022-07-21",
      },
    ],
    sortNumber: 5,
  },
  {
    departmentId: 3,
    departmentName: "沖縄支店",
    boss: [
      {
        staffId: 89077,
        staffNumber: "89077",
        firstName: "一夫",
        lastName: "黒田",
        tenureAtFrom: "2022-07-22",
        tenureAtTo: undefined,
      },
      {
        staffId: 7777,
        staffNumber: "7777",
        firstName: "Valerie",
        lastName: "Yamanaka",
        tenureAtFrom: "2021-07-21",
        tenureAtTo: "2022-07-21",
      },
    ],
    authorizedStaff: [
      {
        staffId: 1234567,
        tenureAtFrom: "2022-07-22",
        tenureAtTo: undefined,
      },
      {
        staffId: 7777,
        tenureAtFrom: "2021-07-21",
        tenureAtTo: "2022-07-21",
      },
    ],
    authorizedPerformanceBasedPay: [
      {
        staffId: 7777,
        tenureAtFrom: "2021-07-21",
        tenureAtTo: "2022-07-21",
      },
    ],
    sortNumber: 1,
  },
];
