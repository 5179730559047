import { MarketQueryApiFactory } from "@/api";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import axiosInstance from "@/utils/axios-customized-instance";
import { safeAsyncCall } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const { getMarkets } = MarketQueryApiFactory(...args, undefined, axiosInstance);

export function useMarketQueryService() {
  return {
    findBy: safeAsyncCall(async (...args: Parameters<typeof getMarkets>) => {
      const res = await getMarkets(...args);
      return res.data;
    }),
  };
}
