import { useEnvironmentVars } from "@/composables/use-environment-vars";
import { defineStore } from "pinia";
import type {
  SecuritiesListItem,
  SecuritiesScreenItem,
  SecuritiesIndividualListItemForPotentialCustomer,
  SecuritiesCorporationListItemForPotentialCustomer,
} from "@/types/SecuritiesManagement/securities-management";
import { computed, ref } from "vue";
import type {
  GetSecurities200Response,
  GetSecuritiesList200ResponseResultsInner,
  PostSecuritiesRequest,
} from "@/api";
import useSecuritiesQueryService from "@/services/use-securities-query-service";
import { useSecuritiesService } from "@/services/use-securities-service";
import { useMetaInfoStore } from "./MetaInfo";
import { useContractorStore } from "./Contractor";
import { useInsuredStore } from "./Insured";
import { useInsuranceInfoStore } from "./InsuranceInfo";
import { useCommissionShareStore } from "./CommissionShare";
import { useIncentiveShareStore } from "./IncentiveShare";
import { useSecuritiesManagementPotentialCustomerTypeStore } from "./PotentialCustomerType";
import {
  mapFalsyToUndefinedAndCall,
  removeUndefinedAndEmptyStringFromObject,
} from "@/utils/helper";
import _ from "lodash-es";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

/**
 * User defined guard function.
 */
const isDataExists = (value: any): value is Response => {
  return value !== null;
};

export const useSecuritiesStore = defineStore("securities-store", () => {
  /**
   * Collecting partial data into one currentScreenItem
   */
  const metaInfo = useMetaInfoStore();
  const contractor = useContractorStore();
  const insured = useInsuredStore();
  const insuranceInfo = useInsuranceInfoStore();
  const commissionShare = useCommissionShareStore();
  const incentiveShare = useIncentiveShareStore();
  const potentialCustomerType =
    useSecuritiesManagementPotentialCustomerTypeStore();

  const { findBy } = useSecuritiesQueryService();
  const { get, add, update } = useSecuritiesService();

  const currentScreenItem = ref({
    metaInfo: computed(() => metaInfo.currentItem),
    contractor: computed(() => contractor.currentItem),
    insured: computed(() => insured.currentItem),
    insuranceInfo: computed(() => insuranceInfo.currentItem),
    commissionShare: computed(() => commissionShare.currentItem),
    incentiveShare: computed(() => incentiveShare.currentItem),
    potentialCustomerInfo: computed(() => potentialCustomerType.currentItem),
  });

  // state
  const securities = ref<
    GetSecuritiesList200ResponseResultsInner[] | undefined
  >(undefined);

  // This ref is used to show the message when no data is found
  const noDataFound = ref<boolean>(false);

  //    * Collection for search results
  //  */
  const securitiesList = computed(() => {
    // reutrn undefined if securities.value is undefined
    if (!securities.value) return undefined;

    const res = securities.value?.map((security) => {
      return convertGetSecuritiesList200ResponseResultsInnerToListItem(
        security
      );
    });
    return res;
  });

  // individual results for Potential Customer management search
  const securitiesListIndividual = computed(() => {
    // reutrn undefined if securities.value is undefined
    if (!securities.value) return undefined;

    if (securities.value.length > 0) {
      const res = securities.value.map((security) => {
        return convertGetSecuritiesListIndividual200ResponseResultsInnerToListItem(
          security
        );
      });
      return res;
    } else return [];
  });

  // corporate results for Potential Customer management search
  const securitiesListCorporation = computed(() => {
    // reutrn undefined if securities.value is undefined
    if (!securities.value) return undefined;

    if (securities.value.length > 0) {
      const res = securities.value.map((security) => {
        return convertGetSecuritiesListCorporation200ResponseResultsInnerToListItem(
          security
        );
      });
      return res;
    } else return [];
  });

  // collect all securities query results
  const securitiesFindBy = mapFalsyToUndefinedAndCall(
    async (...args: Parameters<typeof findBy>) => {
      // reset noDataFound
      noDataFound.value = false;

      const response = await findBy(...args);
      if (isDataExists(response) && response.data.results)
        securities.value = response.data.results;

      // Set noDataFound if the length of the results is 0
      if (securities.value && securities.value.length === 0) {
        noDataFound.value = true;
      }
    }
  );

  // clear security results
  const clearSecurities = () => {
    securities.value = undefined;
  };

  // clear the current security info
  const clearSecurity = () => {
    metaInfo.$reset();
    contractor.$reset();
    insured.$reset();
    insuranceInfo.$reset();
    commissionShare.$reset();
    incentiveShare.$reset();
    potentialCustomerType.$reset();
  };
  // add security; Post request
  const securitiesAdd = async (security: any) => {
    const res = await add(
      convertScreenItemToPostSecuritiesRequest(
        security
      ) as PostSecuritiesRequest
    );

    return res;
  };

  const securitiesGet = async (...args: Parameters<typeof get>) => {
    const res = await get(...args);

    // Guard function, to check if the response is valid
    if (!res) return undefined;

    const { data, status } = res;

    console.log("get!", data);
    if (status === 200)
      return convertGetSecurities200ResponseToScreenItem(data);
  };

  const securitiesUpdate = async (
    securitiesId: number,
    security: SecuritiesScreenItem
  ) => {
    if (isSecuritiesScreenItem(security)) {
      const res = await update(
        securitiesId,
        convertScreenItemToPostSecuritiesRequest(
          security
        ) as PostSecuritiesRequest
      );

      // Guard function, to check if the response is valid
      if (!res) return;

      const { status } = res;
      if (status != 204) throw new Error("Failed to update");
    } else {
      throw new Error("Incorrect screen item");
    }
  };

  return {
    securities,
    securitiesListIndividual,
    securitiesListCorporation,
    securitiesList,
    securitiesFindBy,
    clearSecurities,
    clearSecurity,
    securitiesAdd,
    securitiesGet,
    securitiesUpdate,
    currentScreenItem,
    noDataFound,
  };
});

/**
 * convert shape of GetCGetSecuritiesList200ResponseResultsInner to SecuritiesListIndividualScreenItem
 * @param response
 * @returns SecuritiesScreenItem
 */
export const convertGetSecuritiesList200ResponseResultsInnerToListItem = (
  response: GetSecuritiesList200ResponseResultsInner
): Partial<SecuritiesListItem> => {
  const staffFullName =
    (response.staff.lastName ?? "") + " " + (response.staff.firstName ?? "");
  const insuredFullName =
    response.insured &&
    (response.insured.lastName ?? "") +
      " " +
      (response.insured.firstName ?? "");
  const contractorFullName =
    response.contractorAttribute === "individual"
      ? (response.contractorIndividual?.lastName ?? "") +
        " " +
        (response.contractorIndividual?.firstName ?? "")
      : response.contractorCorporation?.corporationName ?? "";
  return removeUndefinedAndEmptyStringFromObject({
    securitiesId: response.securitiesId,
    contractorAttribute: response.contractorAttribute,
    insuranceCategory: response.insuranceCategory,
    departmentName: response.staff.departmentName,
    staffNumber: response.staff.staffNumber,
    staffName: staffFullName,
    contractorName: contractorFullName ?? "",
    insuredName: insuredFullName ?? "",
    insuranceCompanyName: response.insuranceInfo.insuranceCompanyName ?? "",
    policyNumber: response.insuranceInfo.policyNumber ?? "",
    insuranceTypeName: response.insuranceInfo.insuranceTypeName ?? "",
    insuranceFee: response.insuranceInfo.insuranceFee ?? undefined,
  });
};

/**
 * convert shape of GetSecuritiesListIndividual200ResponseResultsInner to SecuritiesIndividualListItemForPotentialCustomer
 * @param response
 * @returns SecuritiesIndividualListItemForPotentialCustomer
 */
export const convertGetSecuritiesListIndividual200ResponseResultsInnerToListItem =
  (
    response: GetSecuritiesList200ResponseResultsInner
  ): Partial<SecuritiesIndividualListItemForPotentialCustomer> => {
    const converted: SecuritiesIndividualListItemForPotentialCustomer = {
      id: response.securitiesId,
      departmentName: response.staff.departmentName,
      staffName: `${response.staff.lastName} ${response.staff.firstName}`,
      staffNumber: response.staff.staffNumber,
      contractorName: `${response.contractorIndividual?.lastName ?? ""} ${
        response.contractorIndividual?.firstName ?? ""
      }`,
      contractorDateOfBirth: response.contractorIndividual?.dateOfBirth ?? "",
      insuredName: `${response.insured?.lastName ?? ""} ${
        response.insured?.firstName ?? ""
      }`,
      insuredDateOfBirth: response.insured?.dateOfBirth ?? "",
      telLast: response.contractorIndividual?.telLast ?? "",
      address: _.compact([
        response.contractorIndividual?.address1,
        response.contractorIndividual?.address2,
        response.contractorIndividual?.address3,
      ]).join(" "),
    };
    return removeUndefinedAndEmptyStringFromObject(converted);
  };

/**
 * convert shape of GetSecuritiesListCorporation200ResponseResultsInner to SecuritiesCorporationListItemForPotentialCustomer
 * @param response
 * @returns SecuritiesCorporationListItemForPotentialCustomer
 */
export const convertGetSecuritiesListCorporation200ResponseResultsInnerToListItem =
  (
    response: GetSecuritiesList200ResponseResultsInner
  ): Partial<SecuritiesCorporationListItemForPotentialCustomer> => {
    const converted: SecuritiesCorporationListItemForPotentialCustomer = {
      id: response.securitiesId,
      departmentName: response.staff.departmentName,
      staffNumber: response.staff.staffNumber,
      staffName:
        `${response.staff.lastName ?? ""} ${response.staff.firstName ?? ""}` ??
        "",
      corporationName: `${
        response.contractorCorporation?.corporationName ?? ""
      }`,
      telLast: response.contractorCorporation?.telLast ?? "",
      address: _.compact([
        response.contractorCorporation?.address1,
        response.contractorCorporation?.address2,
        response.contractorCorporation?.address3,
      ]).join(" "),
    };

    return removeUndefinedAndEmptyStringFromObject(converted);
  };

export const convertScreenItemToPostSecuritiesRequest = (
  screenItem: SecuritiesScreenItem
): Partial<PostSecuritiesRequest> => {
  // Guard function, to check if the screenItem is valid
  if (!isSecuritiesScreenItem(screenItem))
    throw new Error("Invalid screenItem");

  // This property can accept "" but it shoulb raise error if is left blank.
  if (screenItem.metaInfo.insuranceCategory === "")
    throw new Error("Invalid screenItem");
  if (screenItem.metaInfo.contractorAttribute === "")
    throw new Error("Invalid screenItem");
  if (screenItem.metaInfo.staffId === "") throw new Error("Invalid screenItem");

  const converted: PostSecuritiesRequest = {
    insuranceCategory: screenItem.metaInfo.insuranceCategory,
    contractorAttribute: screenItem.metaInfo.contractorAttribute,
    contractReportId: screenItem.metaInfo.contractReportId ?? undefined,
    potentialCustomerInfo: {
      potentialCustomerId: screenItem.potentialCustomerInfo.potentialCustomerId,
      potentialCustomerType:
        screenItem.potentialCustomerInfo.potentialCustomerType,
      supportUse:
        screenItem.potentialCustomerInfo.supportUse === "support" ?? false,
      marketId: screenItem.potentialCustomerInfo.marketId,
    },

    contractorIndividual:
      screenItem.metaInfo.contractorAttribute === "individual" &&
      screenItem.contractor
        ? {
            firstName: screenItem.contractor?.firstName ?? undefined,
            lastName: screenItem.contractor?.lastName ?? undefined,
            firstNameKana: screenItem.contractor?.firstNameKana ?? undefined,
            lastNameKana: screenItem.contractor?.lastNameKana ?? undefined,
            dateOfBirth: screenItem.contractor?.dateOfBirth ?? undefined,
            postCode: screenItem.contractor?.addressInformation?.postCode,
            address1: screenItem.contractor?.addressInformation?.address1,
            address2: screenItem.contractor?.addressInformation?.address2,
            address3: screenItem.contractor?.addressInformation?.address3,
            address4: screenItem.contractor?.addressInformation?.address4,
            address5: screenItem.contractor?.addressInformation?.address5,
            tel1: screenItem.contractor?.tel1,
            tel2: screenItem.contractor?.tel2,
            mailAddress: screenItem.contractor?.mailAddress,
            potentialCustomerPersonId:
              screenItem.contractor?.potentialCustomerPersonId,
          }
        : undefined,
    contractorCorporation:
      screenItem.metaInfo.contractorAttribute === "corporation" &&
      screenItem.contractor
        ? {
            corporationName: screenItem.contractor?.corporationName,
            corporationClosingMonth:
              screenItem.contractor?.corporationClosingMonth,
            corporationRepresentativeFirstName:
              screenItem.contractor?.corporationRepresentativeFirstName,
            corporationRepresentativeLastName:
              screenItem.contractor?.corporationRepresentativeLastName,
            postCode: screenItem.contractor?.addressInformation?.postCode,
            address1: screenItem.contractor?.addressInformation?.address1,
            address2: screenItem.contractor?.addressInformation?.address2,
            address3: screenItem.contractor?.addressInformation?.address3,
            address4: screenItem.contractor?.addressInformation?.address4,
            address5: screenItem.contractor?.addressInformation?.address5,
            tel1: screenItem.contractor?.tel1,
            tel2: screenItem.contractor?.tel2,
            mailAddress: screenItem.contractor?.mailAddress,
          }
        : undefined,
    insured: screenItem.insured
      ? {
          firstName: screenItem.insured?.firstName,
          lastName: screenItem.insured?.lastName,
          firstNameKana: screenItem.insured?.firstNameKana,
          lastNameKana: screenItem.insured?.lastNameKana,
          dateOfBirth: screenItem.insured?.dateOfBirth,
          potentialCustomerPersonId:
            screenItem.insured?.potentialCustomerPersonId,
        }
      : undefined,
    insuranceInfo: screenItem.insuranceInfo
      ? {
          policyNumber: screenItem.insuranceInfo.policyNumber ?? "",
          insuranceCompanyId: screenItem.insuranceInfo.insuranceCompanyId,
          insuranceTypeId: screenItem.insuranceInfo.insuranceTypeId,
          insuranceFee: screenItem.insuranceInfo.insuranceFee,
          paymentMethod: screenItem.insuranceInfo?.paymentMethod,
          deemedAnnualPayment: screenItem.insuranceInfo?.deemedAnnualPayment,
        }
      : {
          // Just in case, if insuranceInfo is not present
          policyNumber: "",
          insuranceCompanyId: 0,
          insuranceTypeId: 0,
          insuranceFee: 0,
        },
    lifeInsuranceInfo: screenItem.insuranceInfo
      ? {
          contractDateOn: screenItem.insuranceInfo?.contractDateOn,
        }
      : undefined,
    nonLifeInsuranceInfo: screenItem.insuranceInfo
      ? {
          applicationDateOn: screenItem.insuranceInfo?.applicationDateOn,
          insuranceStartDateOn: screenItem.insuranceInfo?.insuranceStartDateOn,
          insuranceEndDateOn: screenItem.insuranceInfo?.insuranceEndDateOn,
          receiptDateOn: screenItem.insuranceInfo?.receiptDateOn,
          returnDateOn: screenItem.insuranceInfo?.returnDateOn,
          nonLifeInsuranceAccountingCategory:
            screenItem.insuranceInfo?.nonLifeInsuranceAccountingCategory,
          nonLifeInsuranceDepositCategory:
            screenItem.insuranceInfo?.nonLifeInsuranceDepositCategory,
        }
      : undefined,
    commissionShare: {
      companyShare: screenItem.commissionShare.companyShare,
      myselfShare: screenItem.commissionShare.myselfShare,
      bossId: screenItem.commissionShare?.bossId,
      bossShare: screenItem.commissionShare.bossShare,
      otherStaffId1: screenItem.commissionShare?.otherStaffId1,
      otherStaffShare1: screenItem.commissionShare.otherStaffShare1,
      otherStaffId2: screenItem.commissionShare?.otherStaffId2,
      otherStaffShare2: screenItem.commissionShare.otherStaffShare2,
      otherStaffId3: screenItem.commissionShare?.otherStaffId3,
      otherStaffShare3: screenItem.commissionShare.otherStaffShare3,
      marketShare: screenItem.commissionShare.marketShare,
    },
    incentiveShare: {
      companyShare: screenItem.incentiveShare.companyShare,
      myselfShare: screenItem.incentiveShare.myselfShare,
      bossId: screenItem.incentiveShare?.bossId,
      bossShare: screenItem.incentiveShare.bossShare,
      otherStaffId1: screenItem.incentiveShare?.otherStaffId1,
      otherStaffShare1: screenItem.incentiveShare.otherStaffShare1,
      otherStaffId2: screenItem.incentiveShare?.otherStaffId2,
      otherStaffShare2: screenItem.incentiveShare.otherStaffShare2,
      otherStaffId3: screenItem.incentiveShare?.otherStaffId3,
      otherStaffShare3: screenItem.incentiveShare.otherStaffShare3,
      marketShare: screenItem.incentiveShare.marketShare,
    },
    staffId: screenItem.metaInfo.staffId,
  };

  return removeUndefinedAndEmptyStringFromObject(converted);
};

/**
 * Convert shape of GetSecurities200Response to SecuritiesScreenItem
 * @param response
 * @returns
 */

export const convertGetSecurities200ResponseToScreenItem = (
  response: GetSecurities200Response
): Partial<SecuritiesScreenItem> => {
  let insuranceInfo = undefined;
  let insuranceInfoData = undefined;
  let lifeInsuranceInfo = undefined;
  let nonLifeInsuranceInfo = undefined;
  let addressInformation = undefined;
  let tel1 = undefined;
  let tel2 = undefined;
  let mailAddress = undefined;
  if (response.contractorAttribute === "individual") {
    addressInformation = {
      postCode: response.contractorIndividual?.postCode ?? undefined,
      address1: response.contractorIndividual?.address1 ?? undefined,
      address2: response.contractorIndividual?.address2 ?? undefined,
      address3: response.contractorIndividual?.address3 ?? undefined,
      address4: response.contractorIndividual?.address4 ?? undefined,
      address5: response.contractorIndividual?.address5 ?? undefined,
    };
    tel1 = response.contractorIndividual?.tel1 ?? undefined;
    tel2 = response.contractorIndividual?.tel2 ?? undefined;
    mailAddress = response.contractorIndividual?.mailAddress ?? undefined;
  } else if (response.contractorAttribute === "corporation") {
    addressInformation = {
      postCode: response.contractorCorporation?.postCode ?? undefined,
      address1: response.contractorCorporation?.address1 ?? undefined,
      address2: response.contractorCorporation?.address2 ?? undefined,
      address3: response.contractorCorporation?.address3 ?? undefined,
      address4: response.contractorCorporation?.address4 ?? undefined,
      address5: response.contractorCorporation?.address5 ?? undefined,
    };
    tel1 = response.contractorCorporation?.tel1 ?? undefined;
    tel2 = response.contractorCorporation?.tel2 ?? undefined;
    mailAddress = response.contractorCorporation?.mailAddress ?? undefined;
  }

  if (response.insuranceInfo) {
    insuranceInfoData = {
      policyNumber: response.insuranceInfo.policyNumber,
      insuranceCompanyId: response.insuranceInfo.insuranceCompanyId,
      insuranceTypeId: response.insuranceInfo.insuranceTypeId,
      insuranceFee: response.insuranceInfo.insuranceFee,
      paymentMethod: response.insuranceInfo?.paymentMethod ?? undefined,
      deemedAnnualPayment:
        response.insuranceInfo?.deemedAnnualPayment ?? undefined,
    };
  }

  if (response.lifeInsuranceInfo) {
    lifeInsuranceInfo = {
      contractDateOn: response.lifeInsuranceInfo?.contractDateOn ?? undefined,
    };
  }

  if (response.nonLifeInsuranceInfo) {
    nonLifeInsuranceInfo = {
      applicationDateOn:
        response.nonLifeInsuranceInfo?.applicationDateOn ?? undefined,
      insuranceStartDateOn:
        response.nonLifeInsuranceInfo?.insuranceStartDateOn ?? undefined,
      insuranceEndDateOn:
        response.nonLifeInsuranceInfo?.insuranceEndDateOn ?? undefined,
      receiptDateOn: response.nonLifeInsuranceInfo?.receiptDateOn ?? undefined,
      returnDateOn: response.nonLifeInsuranceInfo?.returnDateOn ?? undefined,
      nonLifeInsuranceAccountingCategory:
        response.nonLifeInsuranceInfo?.nonLifeInsuranceAccountingCategory ??
        undefined,
      nonLifeInsuranceDepositCategory:
        response.nonLifeInsuranceInfo?.nonLifeInsuranceDepositCategory ??
        undefined,
    };
  }
  insuranceInfo = {
    ...insuranceInfoData,
    ...nonLifeInsuranceInfo,
    ...lifeInsuranceInfo,
  } as SecuritiesScreenItem["insuranceInfo"];

  const potentialCustomerInfo = {
    potentialCustomerId: response.potentialCustomerInfo.potentialCustomerId,
    potentialCustomerType: response.potentialCustomerInfo.potentialCustomerType,
    supportUse:
      response.potentialCustomerInfo.supportUse === true
        ? "support"
        : "notSupport",
    marketId: response.potentialCustomerInfo.marketId,
  } as SecuritiesScreenItem["potentialCustomerInfo"];

  const converted: SecuritiesScreenItem = {
    metaInfo: {
      insuranceCategory: response.insuranceCategory,
      contractorAttribute: response.contractorAttribute,
      contractReportId: response.contractReportId ?? undefined,
      staffId: response.staffId,
      securitiesId: response.securitiesId,
    },
    contractor: {
      firstName: response.contractorIndividual?.firstName ?? undefined,
      lastName: response.contractorIndividual?.lastName ?? undefined,
      firstNameKana: response.contractorIndividual?.firstNameKana ?? undefined,
      lastNameKana: response.contractorIndividual?.lastNameKana ?? undefined,
      corporationName:
        response.contractorCorporation?.corporationName ?? undefined,
      dateOfBirth: response.contractorIndividual?.dateOfBirth,
      addressInformation,
      tel1,
      tel2,
      corporationRepresentativeFirstName:
        response.contractorCorporation?.corporationRepresentativeFirstName ??
        undefined,
      corporationRepresentativeLastName:
        response.contractorCorporation?.corporationRepresentativeLastName ??
        undefined,
      corporationClosingMonth:
        response.contractorCorporation?.corporationClosingMonth ?? undefined,
      mailAddress,
      potentialCustomerPersonId:
        response.contractorIndividual?.potentialCustomerPersonId,
    },
    insured: {
      firstName: response.insured?.firstName ?? undefined,
      lastName: response.insured?.lastName ?? undefined,
      firstNameKana: response.insured?.firstNameKana ?? undefined,
      lastNameKana: response.insured?.lastNameKana ?? undefined,
      dateOfBirth: response.insured?.dateOfBirth ?? undefined,
      potentialCustomerPersonId:
        response.insured?.potentialCustomerPersonId ?? undefined,
    },
    insuranceInfo,
    commissionShare: {
      companyShare: response.commissionShare.companyShare,
      myselfShare: response.commissionShare.myselfShare,
      bossId: response.commissionShare.bossId ?? undefined,
      bossShare: response.commissionShare.bossShare,
      otherStaffId1: response.commissionShare.otherStaffId1 ?? undefined,
      otherStaffShare1: response.commissionShare.otherStaffShare1,
      otherStaffId2: response.commissionShare.otherStaffId2 ?? undefined,
      otherStaffShare2: response.commissionShare.otherStaffShare2,
      otherStaffId3: response.commissionShare.otherStaffId3 ?? undefined,
      otherStaffShare3: response.commissionShare.otherStaffShare3,
      marketShare: response.commissionShare.marketShare,
    },
    incentiveShare: {
      companyShare: response.incentiveShare.companyShare,
      myselfShare: response.incentiveShare.myselfShare,
      bossId: response.incentiveShare.bossId ?? undefined,
      bossShare: response.incentiveShare.bossShare,
      otherStaffId1: response.incentiveShare.otherStaffId1 ?? undefined,
      otherStaffShare1: response.incentiveShare.otherStaffShare1,
      otherStaffId2: response.incentiveShare.otherStaffId2 ?? undefined,
      otherStaffShare2: response.incentiveShare.otherStaffShare2,
      otherStaffId3: response.incentiveShare.otherStaffId3 ?? undefined,
      otherStaffShare3: response.incentiveShare.otherStaffShare3,
      marketShare: response.incentiveShare.marketShare,
    },
    potentialCustomerInfo,
  };
  return removeUndefinedAndEmptyStringFromObject(converted);
};

export const isSecuritiesScreenItem = (
  formData: Record<string, any>
): formData is SecuritiesScreenItem => {
  // test if all of the required fields are present
  let ret = false;

  // if any of the required fields are missing, return false
  try {
    ret =
      formData.metaInfo.insuranceCategory &&
      formData.metaInfo.contractorAttribute &&
      formData.metaInfo.staffId;
  } catch {
    return false;
  }
  return !!ret;
};
