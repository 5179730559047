import { rest } from "msw";
import {
  adjustmentDeductionTypesData,
  replace,
  addNewData,
} from "@/mocks/AdjustmentDeductionType/dummy-data";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import randomInteger from "random-int";
import type {
  GetAdjustmentDeductionTypes200ResponseResultsInner,
  PostAdjustmentDeductionTypeRequest,
} from "@/api";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

/**
 * Convert json shape from PostAdjustmentDeductionTypeRequest to GetAdjustmentDeductionTypes200ResponseResultsInner
 * @param params PostAdjustmentDeductionTypeRequest
 * @returns GetAdjustmentDeductionTypes200ResponseResultsInner
 */
export const convert = (
  params: PostAdjustmentDeductionTypeRequest,
  adjustmentDeductionTypeId?: number
) => {
  return {
    adjustmentDeductionTypeAttribute: params.adjustmentDeductionTypeAttribute,
    adjustmentDeductionTypeId: adjustmentDeductionTypeId
      ? Number(adjustmentDeductionTypeId)
      : randomInteger(1000000, 9999999),
    adjustmentDeductionTypeName: params.adjustmentDeductionTypeName,
    adjustmentDeductionTermFromDefaultAddMonth:
      params.adjustmentDeductionTermFromDefaultAddMonth,
    adjustmentDeductionTermToDefaultAddMonth:
      params.adjustmentDeductionTermToDefaultAddMonth ?? "",
    adjustmentDeductionFeeDefaultPlus: params.adjustmentDeductionFeeDefaultPlus,
    sortNumber: params.sortNumber,
  } as GetAdjustmentDeductionTypes200ResponseResultsInner;
};

/**
 * handlers for AdjustmentDeduction.
 */
export const handlers = [
  /**
   * PostAdjustmentDeductionType API.
   * return adjustmentDeductionTypeId.
   */
  rest.post(`${BASE_PATH}/adjustmentDeductionType`, async (req, res, ctx) => {
    const request = await req.json();
    const newAdjustmentDeductionType = await convert(request);

    addNewData(newAdjustmentDeductionType);
    console.log("new adjustment/deduction type: ", newAdjustmentDeductionType);

    return res(
      ctx.status(201),
      ctx.text(String(newAdjustmentDeductionType.adjustmentDeductionTypeId))
    );
  }),

  /**
   * PutAdjustmentDeductionType API.
   */
  rest.put(
    `${BASE_PATH}/adjustmentDeductionType/:adjustmentDeductionTypeId`,
    async (req, res, ctx) => {
      const request = await req.json();
      const adjustmentDeductionTypeId = Number(
        req.params.adjustmentDeductionTypeId
      );

      const newAdjustmentDeduction = await convert(
        request,
        adjustmentDeductionTypeId
      );

      // finds a record with the matching ID
      const oldAdjustmentDeductionType = adjustmentDeductionTypesData.find(
        (adjustmentDeduction) =>
          adjustmentDeduction.adjustmentDeductionTypeId ===
          newAdjustmentDeduction.adjustmentDeductionTypeId
      );
      // replaces the old data with the new
      replace(oldAdjustmentDeductionType, newAdjustmentDeduction);

      return res(ctx.status(204));
    }
  ),
];
