<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import ComponentWrapper from "@/components/ComponentWrapper.vue";
import PotentialCustomerIndividualTable from "@/components/PotentialCustomerManagement/tables/individual/PotentialCustomerTable.vue";
import PotentialCustomerCorporateTable from "@/components/PotentialCustomerManagement/tables/corporate/PotentialCustomerTable.vue";
import ContractReportIndividualTable from "@/components/PotentialCustomerManagement/tables/individual/ContractReportTable.vue";
import ContractReportCorporateTable from "@/components/PotentialCustomerManagement/tables/corporate/ContractReportTable.vue";
import SecuritiesIndividualTable from "@/components/PotentialCustomerManagement/tables/individual/SecuritiesTable.vue";
import SecuritiesCorporateTable from "@/components/PotentialCustomerManagement/tables/corporate/SecuritiesTable.vue";
import NoDataFound from "@/components/NoDataFound.vue";

import { usePotentialCustomerIndividualStore } from "@/stores/potential-customer-individual";
import { usePotentialCustomerCorporationStore } from "@/stores/potential-customer-corporation";
import type { PotentialCustomerIndividualListScreenItem } from "@/types/PotentialCustomer/potential-customer-individual";
import type {
  SecuritiesCorporationListItemForPotentialCustomer,
  SecuritiesIndividualListItemForPotentialCustomer,
} from "@/types/SecuritiesManagement/securities-management";
import type { PotentialCustomerCorporationListScreenItem } from "@/types/PotentialCustomer/potential-customer-corporation";
import type { ContractReportListIndividualScreenItem } from "@/types/ContractReports/contract-reports";
import type { ContractReportListCorporationScreenItem } from "../../types/ContractReports/contract-reports";

const potentialCustomerIndividualStore = usePotentialCustomerIndividualStore();
const potentialCustomerCorporationStore =
  usePotentialCustomerCorporationStore();

type ResultTableProps = {
  resultsOfPotentialCustomerCorporation:
    | PotentialCustomerCorporationListScreenItem[]
    | undefined;
  resultsOfPotentialCustomerIndividual:
    | PotentialCustomerIndividualListScreenItem[]
    | undefined;
  resultsOfContractReportIndividual:
    | ContractReportListIndividualScreenItem[]
    | undefined;
  resultsOfContractReportCorporation:
    | ContractReportListCorporationScreenItem[]
    | undefined;
  resultsOfSecuritiesIndividual:
    | SecuritiesIndividualListItemForPotentialCustomer[]
    | undefined;
  resultsOfSecuritiesCorporation:
    | SecuritiesCorporationListItemForPotentialCustomer[]
    | undefined;
  contractorAttribute: string;
};
const router = useRouter();

/**
 * Props
 */
defineProps<ResultTableProps>();

// methods
const viewDetailIndividual = (id: number) => {
  router.push(`/potential-customer/individual/${String(id)}`);
};
const viewDetailCorporation = (id: number) => {
  router.push(`/potential-customer/corporation/${String(id)}`);
};

const openPotencialCustomerIndivisual = (id: number) => {
  const potentialCustomerRoute = router.resolve({
    name: "EditPotentialCustomerIndividual",
    params: {
      id: String(id),
    },
  });
  window.open(potentialCustomerRoute.href, "_blank");
};
const openPotencialCustomerCorporation = (id: number) => {
  const potentialCustomerRoute = router.resolve({
    name: "EditPotentialCustomerCorporation",
    params: {
      id: String(id),
    },
  });
  window.open(potentialCustomerRoute.href, "_blank");
};

const tab = ref<"potential-customer" | "contract-report" | "security">(
  "potential-customer"
);
</script>

<template>
  <component-wrapper title="検索結果">
    <!-- ===================== -->
    <!-- 個人 検索結果 -->
    <!-- ===================== -->
    <div v-if="contractorAttribute === 'individual'">
      <v-tabs v-model="tab">
        <v-tab value="potential-customer"
          ><v-badge
            v-if="
              resultsOfPotentialCustomerIndividual &&
              resultsOfPotentialCustomerIndividual.length >= 0
            "
            floating
            color="primary"
            :content="resultsOfPotentialCustomerIndividual?.length"
            data-testid="individual-potential-customer-result-badge"
            >案件</v-badge
          ><span v-else>案件</span></v-tab
        >
        <v-tab value="contract-report"
          ><v-badge
            v-if="
              resultsOfContractReportIndividual &&
              resultsOfContractReportIndividual.length >= 0
            "
            floating
            color="primary"
            :content="resultsOfContractReportIndividual.length"
            data-testid="individual-contract-report-result-badge"
            >申達</v-badge
          >
          <span v-else>申達</span></v-tab
        >
        <v-tab value="security"
          ><v-badge
            v-if="
              resultsOfSecuritiesIndividual &&
              resultsOfSecuritiesIndividual.length >= 0
            "
            floating
            color="primary"
            :content="resultsOfSecuritiesIndividual.length"
            data-testid="individual-securities-result-badge"
            >証券</v-badge
          ><span v-else>証券</span></v-tab
        >
      </v-tabs>
      <v-window v-model="tab">
        <!-- 検索結果 -->
        <v-window-item value="potential-customer">
          <!-- 案件 > 個人 -->
          <no-data-found
            :noDataFound="potentialCustomerIndividualStore.noDataFound"
          />
          <potential-customer-individual-table
            v-if="!potentialCustomerIndividualStore.noDataFound"
            :results-of-potential-customer-individual="
              resultsOfPotentialCustomerIndividual
            "
            :view-detail="openPotencialCustomerIndivisual"
          ></potential-customer-individual-table>
        </v-window-item>
        <v-window-item value="contract-report">
          <!-- 申達報告 > 個人 -->
          <contract-report-individual-table
            :results-of-contract-report-individual="
              resultsOfContractReportIndividual
            "
            :view-detail="openPotencialCustomerIndivisual"
          ></contract-report-individual-table>
        </v-window-item>
        <v-window-item value="security">
          <!-- 証券 > 個人 -->
          <securities-individual-table
            :resultsOfSecuritiesIndividual="resultsOfSecuritiesIndividual"
            :view-detail="openPotencialCustomerIndivisual"
          ></securities-individual-table>
        </v-window-item>
      </v-window>
    </div>

    <!-- ===================== -->
    <!-- 法人 検索結果 -->
    <!-- ===================== -->
    <div v-if="contractorAttribute === 'corporation'">
      <v-tabs v-model="tab">
        <v-tab value="potential-customer"
          ><v-badge
            v-if="
              resultsOfPotentialCustomerCorporation &&
              resultsOfPotentialCustomerCorporation.length >= 0
            "
            floating
            color="primary"
            :content="resultsOfPotentialCustomerCorporation?.length"
            data-testid="corporation-potential-customer-result-badge"
            >案件</v-badge
          ><span v-else>案件</span></v-tab
        >
        <v-tab value="contract-report"
          ><v-badge
            v-if="
              resultsOfContractReportCorporation &&
              resultsOfContractReportCorporation.length >= 0
            "
            floating
            color="primary"
            :content="resultsOfContractReportCorporation.length"
            data-testid="corporation-contract-report-result-badge"
            >申達</v-badge
          >
          <span v-else>申達</span></v-tab
        >
        <v-tab value="security"
          ><v-badge
            v-if="
              resultsOfSecuritiesCorporation &&
              resultsOfSecuritiesCorporation.length >= 0
            "
            floating
            color="primary"
            :content="resultsOfSecuritiesCorporation.length"
            data-testid="corporation-securities-result-badge"
            >証券</v-badge
          ><span v-else>証券</span></v-tab
        >
      </v-tabs>
      <v-window v-model="tab">
        <!-- 検索結果 -->
        <v-window-item value="potential-customer">
          <!-- 案件 > 法人 -->
          <no-data-found
            :noDataFound="potentialCustomerCorporationStore.noDataFound"
          />
          <potential-customer-corporate-table
            v-if="!potentialCustomerCorporationStore.noDataFound"
            :results-of-potential-customer-corporation="
              resultsOfPotentialCustomerCorporation
            "
            :view-detail="openPotencialCustomerCorporation"
          ></potential-customer-corporate-table>
        </v-window-item>
        <v-window-item value="contract-report">
          <!-- 申達報告 > 法人 -->
          <contract-report-corporate-table
            :results-of-contract-report-corporation="
              resultsOfContractReportCorporation
            "
            :view-detail="openPotencialCustomerCorporation"
          ></contract-report-corporate-table>
        </v-window-item>
        <v-window-item value="security">
          <!-- 証券 > 法人 -->
          <securities-corporate-table
            :resultsOfSecuritiesCorporation="resultsOfSecuritiesCorporation"
            :view-detail="openPotencialCustomerCorporation"
          ></securities-corporate-table>
        </v-window-item>
      </v-window>
    </div>
  </component-wrapper>
</template>

<style scoped></style>
