import { createApp, type ComponentPublicInstance } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { plugin, defaultConfig } from "@formkit/vue";
import "@formkit/themes/genesis";
import { ja } from "@formkit/i18n";
import { worker } from "@/mocks/browser";
import { useEnvironmentVars } from "./composables/use-environment-vars";
import { useErrorMessageStore } from "@/stores/ErrorMessage";

import storeReset from "@/stores/plugins/storeReset";

const { mode } = useEnvironmentVars();
console.log("mode", mode);
if (mode === "development" || mode === "staging") {
  worker.start();
}

loadFonts();

const pinia = createPinia();
pinia.use(storeReset);

const app = createApp(App)
  .use(pinia)
  .use(router)
  .use(vuetify)
  .use(
    plugin,
    defaultConfig({
      // Define additional locales
      locales: { ja },
      // Define the active locale
      locale: "ja",
    })
  );

app.config.errorHandler = (
  err: unknown,
  vm: ComponentPublicInstance | null,
  info: string
) => {
  if (err instanceof Error) {
    console.error("Captured in global error handler", err);
  } else {
    console.error(new Error("An unknown error occurred."));
  }
  console.error(info);
};

app.mount("#app");

window.addEventListener("error", (event) => {
  console.log("Captured in error EventListener", event.error);
});

window.addEventListener("unhandledrejection", (event) => {
  console.log("Captured in unhandledrejection EventListener", event.reason);
});

useErrorMessageStore();

console.log("import.meta: ", import.meta.env);
