import type { SecuritiesScreenItem } from "@/types/SecuritiesManagement/securities-management";
import { defineStore } from "pinia";
import { ref } from "vue";

// definition:
//
// contractor?: {
//     //契約者  個人/法人
//     firstName?: string | undefined;
//     lastName?: string | undefined;
//     firstNameKana?: string | undefined;
//     lastNameKana?: string | undefined;
//     corporationName?: string | undefined;
//     dateOfBirth?: string | undefined;
//     addressInformation?: {
//       postCode?: string | undefined;
//       address1?: string | undefined;
//       address2?: string | undefined;
//       address3?: string | undefined;
//       address4?: string | undefined;
//       address5?: string | undefined;
//     };
//     tel1?: string | undefined;
//     tel2?: string | undefined;
//     corporationRepresentativeFirstName?: string | undefined; //代表者
//     corporationRepresentativeLastName?: string | undefined; //代表者
//     corporationClosingMonth?: number | undefined; //決算月
//     mailAddress?: string | undefined;
//     potentialCustomerPersonId?: number;
//   };

/**
 * Defining the info
 */
type ContractorBase = SecuritiesScreenItem["contractor"];

export type Contractor = ContractorBase;

export const useContractorStore = defineStore("contractor", () => {
  // initialize state
  const currentItem = ref<Contractor>({
    firstName: "",
    lastName: "",
    firstNameKana: "",
    lastNameKana: "",
    corporationName: "",
    dateOfBirth: "",
    dateOfBirthWareki: "",
    addressInformation: {
      postCode: "",
      address1: "",
      address2: "",
      address3: "",
      address4: "",
      address5: "",
    },
    tel1: "",
    tel2: "",
    corporationRepresentativeFirstName: "",
    corporationRepresentativeLastName: "",
    corporationClosingMonth: undefined,
    mailAddress: "",
    potentialCustomerPersonId: undefined,
  });

  return {
    currentItem,
  };
});
