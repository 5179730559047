import { AdjustmentDeductionTypeQueryApiFactory } from "@/api";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import axiosInstance from "@/utils/axios-customized-instance";
import { safeAsyncCall } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const { getAdjustmentDeductionTypes } = AdjustmentDeductionTypeQueryApiFactory(
  ...args,
  undefined,
  axiosInstance
);

export default function useAdjustmentDeductionTypeQueryService() {
  return {
    findBy: safeAsyncCall(
      async (...args: Parameters<typeof getAdjustmentDeductionTypes>) => {
        const res = await getAdjustmentDeductionTypes(...args);
        const { data } = res;

        return data;
      }
    ),
  };
}
