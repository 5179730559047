import { dummyInsuranceCompanyTypes, addNewData, replace } from "./dummy-data";
import { rest } from "msw";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import type {
  GetInsuranceTypes200ResponseResultsInner,
  PostInsuranceTypeRequest,
} from "@/api";
import randomInteger from "random-int";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

export const handlers = [
  /**
   * InsuranceType
   * GetInsuranceType
   */

  rest.get(`${BASE_PATH}/insuranceType/:insuranceTypeId`, (req, res, ctx) => {
    const id = req.params.insuranceTypeId;
    const company = dummyInsuranceCompanyTypes.find(
      (company) => company.insuranceTypeId === Number(id)
    );

    return res(ctx.status(200), ctx.json(company));
  }),

  /**
   * PostInsuranceType
   * return insuranceTypeId.
   */
  rest.post(`${BASE_PATH}/insuranceType`, async (req, res, ctx) => {
    const request = await req.json();
    const newInsuranceType = await convert(request);

    addNewData(newInsuranceType);
    console.log("new insurance type: ", newInsuranceType);

    return res(
      ctx.status(201),
      ctx.text(String(newInsuranceType.insuranceTypeId))
    );
  }),

  /**
   * PutInsuranceType
   */
  rest.put(
    `${BASE_PATH}/insuranceType/:insuranceTypeId`,
    async (req, res, ctx) => {
      const request = await req.json();
      const insuranceTypeId = Number(req.params.insuranceTypeId);

      const newRecord = await convert(request, insuranceTypeId);

      // finds a record with the matching ID
      const oldRecord = dummyInsuranceCompanyTypes.find(
        (record) => record.insuranceTypeId === newRecord.insuranceTypeId
      );
      // replaces the old data with the new
      replace(oldRecord, newRecord);

      return res(ctx.status(204));
    }
  ),
];

/**
 * Convert json shape from PostInsuranceTypeRequest to GetInsuranceTypes200ResponseResultsInner
 * @param params PostInsuranceTypeRequest
 * @returns GetInsuranceTypes200ResponseResultsInner
 */
export const convert = async (
  params: PostInsuranceTypeRequest,
  insuranceTypeId?: number
) => {
  return {
    insuranceTypeId: insuranceTypeId
      ? Number(insuranceTypeId)
      : randomInteger(1000000, 9999999),
    insuranceTypeName: params.insuranceTypeName,
    insuranceCategory: params.insuranceCategory,
    sortNumber: params.sortNumber,
  } as GetInsuranceTypes200ResponseResultsInner;
};
