import type { PostDepartmentRequest } from "@/api";
import { rest } from "msw";
import { baseDepartments, replace } from "./dummy-departs";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import randomInteger from "random-int";
import { useStaffQueryService } from "@/services/use-staff-query-service";
import { defaultBaseDummyData } from "@/mocks/Staff/dummy-get-staff";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

/**
 * Converts form data to Post/PutDepartment request format
 * @param params PostAdjustmentDeductionRequest
 * @returns GetAdjustmentDeductions200Response
 */

const convert = async (
  params: PostDepartmentRequest,
  departmentId?: number
) => {
  const findPersonalInfo = async (staffId: number) => {
    const staff = defaultBaseDummyData.find(
      (staff) => staff.staffId === Number(staffId)
    );
    return {
      lastName: staff?.personal.lastName,
      firstName: staff?.personal.firstName,
      staffNumber: staff?.staffNumber,
    };
  };

  let boss;
  if (params.boss && params.boss.length > 0) {
    boss = await Promise.all(
      params.boss.map(async (boss) => ({
        ...boss,
        lastName: (await findPersonalInfo(boss.staffId))?.lastName,
        firstName: (await findPersonalInfo(boss.staffId))?.firstName,
        staffNumber: (await findPersonalInfo(boss.staffId))?.staffNumber,
      }))
    );
  }

  let authorizedStaff;
  if (params.authorizedStaff && params.authorizedStaff.length > 0) {
    authorizedStaff = await Promise.all(
      params.authorizedStaff.map(async (staff) => ({
        ...staff,
        lastName: (await findPersonalInfo(staff.staffId))?.lastName,
        firstName: (await findPersonalInfo(staff.staffId))?.firstName,
      }))
    );
  }

  let authorizedPerformanceBasedPay;
  if (
    params.authorizedPerformanceBasedPay &&
    params.authorizedPerformanceBasedPay.length > 0
  ) {
    authorizedPerformanceBasedPay = await Promise.all(
      params.authorizedPerformanceBasedPay.map(async (staff) => ({
        ...staff,
        lastName: (await findPersonalInfo(staff.staffId))?.lastName,
        firstName: (await findPersonalInfo(staff.staffId))?.firstName,
      }))
    );
  }

  const data = {
    departmentId: departmentId
      ? Number(departmentId)
      : randomInteger(1000000, 9999999),
    departmentName: params.departmentName,
    boss,
    authorizedStaff,
    authorizedPerformanceBasedPay,
    sortNumber: params.sortNumber,
  };
  return data;
};

export const handlers = [
  /**
   * GetDepartment
   */
  rest.get(`${BASE_PATH}/department/:departmentId`, (req, res, ctx) => {
    const departmentId = req.params.departmentId;

    const department = baseDepartments.find(
      (department) => department.departmentId === Number(departmentId)
    );

    if (!department) {
      return res(
        ctx.status(404),
        ctx.json({ message: "department not found" })
      );
    }

    return res(ctx.status(200), ctx.json(department));
  }),

  /**
   * PostDepartment API.
   */
  rest.post(`${BASE_PATH}/department`, async (req, res, ctx) => {
    const request = await req.json();
    const newDepartment = await convert(request);

    baseDepartments.push(newDepartment);

    return res(ctx.text(String(newDepartment.departmentId)));
  }),

  /**
   * PutDepartment API.
   */
  rest.put(`${BASE_PATH}/department/:departmentId`, async (req, res, ctx) => {
    const request = await req.json();
    const departmentId = Number(req.params.departmentId);
    const newDepartment = await convert(request, departmentId);

    // finds a record with the matching ID
    const oldDepartment = baseDepartments.find(
      (department) => department.departmentId === departmentId
    );

    // replaces the old data with the new
    replace(oldDepartment, newDepartment);

    return res(ctx.status(204));
  }),
];
