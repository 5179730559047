import type { SecuritiesScreenItem } from "@/types/SecuritiesManagement/securities-management";
import { defineStore } from "pinia";
import { ref } from "vue";

// definition:
//
// commissionShare: {
//     //手数料
//     // 取分合計が100%にならないとエラー
//     companyShare: number; // 本社取分率 @
//     myselfShare: number; //担当募集人 取分率 @
//     bossId?: number | undefined; // 所属長
//     bossShare: number; // 所属長 取分率 @
//     otherStaffId1?: number | undefined; //その他募集人１
//     otherStaffNumber1: string | undefined;
//     otherStaffShare1: number; // 取分率 @
//     otherStaffId2?: number | undefined; //その他募集人2
//     otherStaffShare2: number; //取分率 @
//     otherStaffId3?: number | undefined; // その他募集人3
//     otherStaffShare3: number; // 取分率 @
//     marketId?: number | undefined; //マーケットID
//     marketShare: number; //マーケット 取分率 @
// };

/**
 * Defining the info
 */

type CommissionShareBase = SecuritiesScreenItem["commissionShare"];

export type CommissionShare = CommissionShareBase;

export const useCommissionShareStore = defineStore("commission-share", () => {
  // initialize state
  const currentItem = ref<CommissionShare>({
    companyShare: "0",
    myselfShare: "0",
    bossShare: "0",
    otherStaffShare1: "0",
    otherStaffShare2: "0",
    otherStaffShare3: "0",
    marketShare: "0",
    bossId: undefined,
    otherStaffId1: undefined,
    otherStaffId2: undefined,
    otherStaffId3: undefined,
    marketId: undefined,
  });

  return {
    currentItem,
  };
});
