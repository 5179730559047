import {
  CommissionQueryApiFactory,
  type GetCommissionStatus200ResponseResultsInner,
} from "@/api";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import type { CommissionIngestionStatusScreenItem } from "@/types/CommissionAndPerformanceBasedPayCalculation/commission-and-performance-based-pay-calculation-types";
import axiosInstance from "@/utils/axios-customized-instance";
import { getValueFromEnum } from "@/utils/helper";
import { safeAsyncCall } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const {
  getCommissionMonthCurrent,
  getCommissionStatus,
  putCommissionCalculation,
  getCommissions,
  getCommissionsCsv,
  getCommissionInsuranceCompanyCalculationResults,
  getCommissionCalculationResults,
  getCommissionCalculationResultsCsv,
} = CommissionQueryApiFactory(...args, undefined, axiosInstance);

export default function useCommissionQueryService() {
  return {
    getCurrentMonth: safeAsyncCall(
      async (...args: Parameters<typeof getCommissionMonthCurrent>) => {
        const res = await getCommissionMonthCurrent(...args);

        return res.data;
      }
    ),
    getStatus: safeAsyncCall(
      async (...args: Parameters<typeof getCommissionStatus>) => {
        const res = await getCommissionStatus(...args);
        const { data } = res;

        const items =
          data && data.results.length > 0
            ? data.results.map((item) => {
                return convertToCommissionIngestionStatusScreenItem(item);
              })
            : [];
        console.log("items", items);
        return items;
      }
    ),
    calculate: safeAsyncCall(
      async (...args: Parameters<typeof putCommissionCalculation>) => {
        const res = await putCommissionCalculation(...args);
        const { data } = res;

        return data;
      }
    ),
    getCommissionsQuery: safeAsyncCall(
      async (...args: Parameters<typeof getCommissions>) => {
        const res = await getCommissions(...args);
        const { data } = res;

        return data;
      }
    ),
    getCommissionInsuranceCompanyCalculationResults: safeAsyncCall(
      async (
        ...args: Parameters<
          typeof getCommissionInsuranceCompanyCalculationResults
        >
      ) => {
        const res = await getCommissionInsuranceCompanyCalculationResults(
          ...args
        );
        const { data } = res;

        return data;
      }
    ),

    getCommissionCalculationResults: safeAsyncCall(
      async (...args: Parameters<typeof getCommissionCalculationResults>) => {
        const res = await getCommissionCalculationResults(...args);
        const { data } = res;

        return data;
      }
    ),

    exportBy: async (...args: Parameters<typeof getCommissionsCsv>) => {
      const res = await getCommissionsCsv(...args);
      return res;
    },

    exportCommissionCalculationResultsBy: async (
      ...args: Parameters<typeof getCommissionCalculationResultsCsv>
    ) => {
      const res = await getCommissionCalculationResultsCsv(...args);
      return res;
    },
  };
}

// =================================================================================================
// Business logic
// =================================================================================================
const convertToCommissionIngestionStatusScreenItem = (
  data: GetCommissionStatus200ResponseResultsInner
): CommissionIngestionStatusScreenItem => {
  return {
    commissionImportId: data.commissionImportId,
    insuranceCompanyId: data.insuranceCompanyId,
    insuranceCompanyName: data.insuranceCompanyName,
    insuranceCategory: getValueFromEnum(
      "insuranceCategory",
      data.insuranceCategory
    ),
    commissionCategory: data.commissionCategory,
    commissionRecordCount: data.commissionRecordCount,
    commissionMatchingCount: data.commissionMatchingCount,
    commissionTotalAmount: data.commissionTotalAmount,
    commissionImportAt: data.commissionImportAt,
    commissionImportStatus: getValueFromEnum(
      "commissionImportStatus",
      data.commissionImportStatus
    ),
    commissionImportCount: data.commissionImportCount,
    consumptionTaxIncludeFlag: data.consumptionTaxIncludeFlag,
  };
};
