<script setup lang="ts">
import ComponentWrapper from "@/components/ComponentWrapper.vue";
import { useRouter } from "vue-router";
import SearchAutocompleteWrapper from "@/components/SearchAutoComplete/SearchAutocompleteWrapper.vue";
import DownloadButton from "@/components/AdjustmentDeductionManagement/DownloadButton.vue";
import UploadButton from "@/components/sharable/UploadButton.vue";

import useAdjustmentDeductionTypeQueryService from "@/services/use-adjustment-deduction-types-query-service";
import useAdjustmentDeductionQueryService from "@/services/use-adjustment-deduction-query-service";
import { useAdjustmentDeductionStore } from "@/stores/AdjustmentDeductionManagement/AdjustmentsDeductions";
import { ref, computed, onMounted } from "vue";
import { getOptionsForSelectbox } from "@/utils/helper";

// emit the staffID to parent on search (for routing in the result table)
const emit = defineEmits<{
  (e: "update:selectedStaff", staffId: any): void;
}>();

const props = defineProps<{
  variant?:
    | "flat"
    | "text"
    | "elevated"
    | "tonal"
    | "outlined"
    | "plain"
    | undefined;
  userId?: number | undefined;
}>();

const store = useAdjustmentDeductionStore();

// initialize v-model values
const adjustmentDeductionTypeId = ref();
const adjustmentDeductionTerm = ref();
const staffId = ref(null);
const adjustmentDeductionTypes = ref<any[]>([]);
const searchedAtLeastOne = ref(false);
const uploadDialogCSVImport = ref(false);
const uploadDialogIncentiveCSVImport = ref(false);

// set up computed value for staff ID, so it can return undefined or number
const isFalsy = (value: any) => {
  return value === null || value === "" || value === undefined || isNaN(value);
};
const computedStaffId = computed(() => {
  return isFalsy(staffId.value) // They are called as falsy
    ? undefined
    : Number(staffId.value);
});

// Fetch names of deduction types.

onMounted(async () => {
  const adjustmentDeductionTypeResults =
    await useAdjustmentDeductionTypeQueryService().findBy();

  adjustmentDeductionTypes.value = getOptionsForSelectbox(
    adjustmentDeductionTypeResults?.results ?? [],
    "adjustmentDeductionTypeName",
    "adjustmentDeductionTypeId"
  );
});

// 新規作成 - create  method
const router = useRouter();

const onCreate = () => {
  const route = router.resolve({
    name: "CreateAdjustmentDeduction",
  });
  window.open(route.href, "_blank");
};

const myStaffId = ref();

// Handle error getting logged in user's ID
const noUserIdError = ref(false);

// 検索 - search  method
const search = async () => {
  console.log("search");
  searchedAtLeastOne.value = true;

  // First, clear the store.deductions
  store.clearDeductions();

  await store.deductionsFindBy(
    1,
    1000,
    computedStaffId.value,
    adjustmentDeductionTypeId.value,
    adjustmentDeductionTerm.value
  );
  emit("update:selectedStaff", staffId.value);
};

// CSVインポート - CSV import method
const importFile = async (file: File) => {
  await useAdjustmentDeductionQueryService().importFile(file);
};

const importIncentiveFile = async (file: File) => {
  await useAdjustmentDeductionQueryService().importIncentiveFile(file);
};
</script>

<template>
  <component-wrapper class="component-specific-style">
    <v-form @submit.prevent>
      <v-card-title>調整控除検索</v-card-title>
      <v-card-text>
        <v-container fluid
          ><v-row align="center">
            <v-col cols="6">
              <v-alert
                v-if="noUserIdError"
                density="compact"
                icon="mdi-account-question"
                closable
                border="start"
                type="info"
                class="mb-5"
              >
                ユーザーIDを取得できませんでした。
              </v-alert>
            </v-col></v-row
          >

          <v-row align="center">
            <v-col md="3">
              <div>
                <search-autocomplete-wrapper
                  label="担当募集人"
                  :isEditable="true"
                  v-model="staffId"
                  :debounce="20"
                  :staffId="myStaffId"
                  :isOptional="true"
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="3">
              <FormKit
                type="select"
                placeholder="調整控除項目を選択"
                :options="adjustmentDeductionTypes"
                label="調整控除項目"
                suffix-icon="trash"
                @suffix-icon-click="adjustmentDeductionTypeId = undefined"
                v-model="adjustmentDeductionTypeId"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="3">
              <FormKit
                type="text"
                label="支給月"
                placeholder="YYYYMM"
                validation="number|length:6,6"
                help="例) 2022年4月 → '202204'"
                suffix-icon="trash"
                @suffix-icon-click="adjustmentDeductionTerm = undefined"
                v-model="adjustmentDeductionTerm"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text class="d-flex flex-row">
        <v-sheet class="ma-2 pa-2">
          <v-btn size="large" color="primary" @click="search">検索</v-btn>
        </v-sheet>
        <v-sheet class="ma-2 pa-2">
          <v-btn size="large" @click="onCreate" color="primary">
            新規作成</v-btn
          >
        </v-sheet>
        <v-sheet class="ma-2 pa-2">
          <download-button
            :staffId="computedStaffId"
            :adjustmentDeductionTypeId="adjustmentDeductionTypeId"
            :adjustmentDeductionTerm="adjustmentDeductionTerm"
          />
        </v-sheet>
        <v-sheet class="ma-2 pa-2">
          <v-btn size="large" color="primary">
            CSVインポート
            <v-dialog
              width="auto"
              v-model="uploadDialogCSVImport"
              transition="fade-transition"
              activator="parent"
            >
              <upload-button :import-action="importFile" :import-args="[]" />
            </v-dialog>
          </v-btn>
        </v-sheet>
        <v-sheet class="ma-2 pa-2">
          <v-btn size="large" color="primary">
            インセンティブCSVインポート
            <v-dialog
              width="auto"
              v-model="uploadDialogIncentiveCSVImport"
              transition="fade-transition"
              activator="parent"
            >
              <upload-button
                :import-action="importIncentiveFile"
                :import-args="[]"
              />
            </v-dialog>
          </v-btn>
        </v-sheet>
      </v-card-text>
    </v-form>
  </component-wrapper>
</template>

<style lang="scss" scoped></style>
@/services/use-adjustment-deduction-types-query-service
