import type { SecuritiesScreenItem } from "@/types/SecuritiesManagement/securities-management";
import { defineStore } from "pinia";
import { ref } from "vue";

// definition:
//
// insured?: {
//被保険者
//   firstName?: string;
//   lastName?: string;
//   firstNameKana?: string;
//   lastNameKana?: string;
//   dateOfBirth?: string;
//   potentialCustomerPersonId?: number;
// };

/**
 * Defining the info
 */
type InsuredBase = SecuritiesScreenItem["insured"];

export type Insured = InsuredBase;

export const useInsuredStore = defineStore("insured", () => {
  // initialize state
  const currentItem = ref<Insured>({
    firstName: "",
    lastName: "",
    firstNameKana: "",
    lastNameKana: "",
    dateOfBirth: "",
    dateOfBirthWareki: "",
    potentialCustomerPersonId: 0,
  });

  return {
    currentItem,
  };
});
