import type { AdjustmentDeductionScreenItem } from "@/types/AdjustmentDeductionManagement/adjustments-deductions";
import type {
  GetAdjustmentDeductions200ResponseResultsInner,
  PostAdjustmentDeductionRequest,
  PutAdjustmentDeductionRequest,
} from "@/api";
import useAdjustmentDeductionService from "@/services/use-adjustment-deduction-service";
import useAdjustmentDeductionQueryService from "@/services/use-adjustment-deduction-query-service";
import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import NoDataFound from "@/components/NoDataFound.vue";
import {
  mapFalsyToUndefinedAndCall,
  removeUndefinedAndEmptyStringFromObject,
} from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const isDataExists = (value: any): value is Response => {
  return value !== null;
};

export const useAdjustmentDeductionStore = defineStore(
  "adjustments-deductions",
  () => {
    const { add, update } = useAdjustmentDeductionService();
    const { findBy } = useAdjustmentDeductionQueryService();

    const currentScreenItem = ref<AdjustmentDeductionScreenItem>();

    const noDataFound = ref(false);

    /**
     * Collection for search results
     */

    // Collect raw results
    const deductions = ref<GetAdjustmentDeductions200ResponseResultsInner[]>(
      []
    );

    // Convert the raw results to List items (for display as search results)
    const deductionsList = computed(() => {
      const res = deductions.value.map((deduction) => {
        return convertGetAdjustmentDeductions200ResponseResultsInnerToListItem(
          deduction
        );
      });
      return res;
    });

    // Clear API response results.
    const clearDeductions = () => {
      deductions.value = [];
    };

    /**
     * APIs to manipulate pinia states.
     */

    // Get a single record from the Query API, to display on an Edit screen.
    const getDeduction = async (
      staffId: number,
      adjustmentDeductionId: number
    ) => {
      console.log("details", staffId, adjustmentDeductionId);
      const response = await findBy(1, 1000, staffId);

      // Guard function, to check if the response is valid
      if (!response) return undefined;

      const allDeductions = response.data.results;
      const deduction = Object.assign(
        {},
        allDeductions.find(
          (deduction) =>
            deduction.adjustmentDeductionId === Number(adjustmentDeductionId)
        )
      );

      return convertGetAdjustmentDeductions200ResponseResultsInnerToScreenItem(
        deduction
      );
    };

    // Add data from the form (screen item) and send it as a Post request.
    const deductionsAdd = async (deduction: AdjustmentDeductionScreenItem) => {
      const res = await add(
        convertScreenItemToPostAdjustmentDeductionRequest(
          deduction
        ) as PostAdjustmentDeductionRequest
      );
      return res;
    };

    // Find records with the Query API
    const deductionsFindBy = mapFalsyToUndefinedAndCall(
      async (...args: Parameters<typeof findBy>) => {
        // reset noDataFound to false
        noDataFound.value = false;
        const response = await findBy(...args);
        if (isDataExists(response) && response.data.results) {
          deductions.value = response.data.results;

          // Set noDataFound to true if there is no data.
          if (response.data.results.length === 0) {
            noDataFound.value = true;
          } else {
            noDataFound.value = false;
          }
        }
      }
    );

    // Update a record by replacing it with a PUT request.
    const deductionsUpdate = async (
      deduction: AdjustmentDeductionScreenItem,
      id: number
    ) => {
      if (isAdjustmentDeductionScreenItem(deduction)) {
        const res = await update(
          id,
          convertScreenItemToPostAdjustmentDeductionRequest(
            deduction
          ) as PutAdjustmentDeductionRequest
        );

        if (!res) return;

        const { status } = res;
        if (status != 204) throw new Error("Failed to update");
      } else {
        throw new Error("Incorrect screen item");
      }
    };
    return {
      currentScreenItem,
      deductions,
      clearDeductions,
      deductionsAdd,
      deductionsUpdate,
      getDeduction,
      deductionsFindBy,
      deductionsList,
      noDataFound,
    };
  }
);

// Decide of adjustmentDeductionFeeDefaultPlus is true or false.
export const getPlusMinus = (fee: number) => {
  const feeString = fee.toString();
  const firstCharacter = Array.from(feeString)[0];
  if (firstCharacter === "-") {
    return "false";
  } else {
    return "true";
  }
};

// Get only the numeral value from adjustmentDeductionFee, in case that it is a negative (remove the '-' for the Edit screen)
export const convertFee = (fee: number) => {
  const feeString = fee.toString();
  const firstCharacter = Array.from(feeString)[0];
  if (firstCharacter === "-") {
    return Number(feeString.substring(1));
  } else {
    return Number(feeString);
  }
};

// Convert API response data to be displayed as search results.
export const convertGetAdjustmentDeductions200ResponseResultsInnerToListItem = (
  response: GetAdjustmentDeductions200ResponseResultsInner
): Partial<AdjustmentDeductionScreenItem> => {
  const converted: AdjustmentDeductionScreenItem = {
    staffId: response.staff.staffId,
    staffNumber: response.staff.staffNumber,
    staffName: `${response.staff.lastName} ${response.staff.firstName}`,
    adjustmentDeductionId: response.adjustmentDeductionId,
    adjustmentDeductionTypeId: response.adjustmentDeductionTypeId,
    adjustmentDeductionTypeName: response.adjustmentDeductionTypeName,
    adjustmentDeductionName: response.adjustmentDeductionName,
    adjustmentDeductionFee: convertFee(response.adjustmentDeductionFee),
    adjustmentDeductionTermFrom:
      response.adjustmentDeductionTerm.adjustmentDeductionTermFrom,
    adjustmentDeductionTermTo:
      response.adjustmentDeductionTerm.adjustmentDeductionTermTo,
    adjustmentDeductionFeeDefaultPlus: getPlusMinus(
      response.adjustmentDeductionFee
    ),
  };
  return removeUndefinedAndEmptyStringFromObject(converted);
};

// Converts the form data (Screen Item) into POST Request format.
// For 'add()', adjustmentDeductionId will become undefined-- it will be set later
// when sent as Post request.
export const convertScreenItemToPostAdjustmentDeductionRequest = (
  screenItem: AdjustmentDeductionScreenItem
): Partial<PostAdjustmentDeductionRequest> => {
  // Guard function, to check if the screenItem is valid
  if (!isAdjustmentDeductionScreenItem(screenItem))
    throw new Error("Invalid screenItem");

  let adjustedFee = 0;

  //
  if (screenItem.adjustmentDeductionFeeDefaultPlus === "false") {
    adjustedFee = Number("-" + String(screenItem.adjustmentDeductionFee));
    console.log(adjustedFee);
  }

  const converted: PostAdjustmentDeductionRequest & {
    adjustmentDeductionId: number;
  } = {
    staffId: screenItem.staffId,
    adjustmentDeductionId: screenItem.adjustmentDeductionId ?? undefined,
    adjustmentDeductionTypeId: screenItem.adjustmentDeductionTypeId,
    adjustmentDeductionName: screenItem.adjustmentDeductionName ?? "",
    adjustmentDeductionFee:
      adjustedFee != 0
        ? Number(adjustedFee)
        : screenItem.adjustmentDeductionFee,
    adjustmentDeductionTerm: {
      adjustmentDeductionTermFrom: screenItem.adjustmentDeductionTermFrom,
      adjustmentDeductionTermTo: screenItem.adjustmentDeductionTermTo,
    },
  };
  return removeUndefinedAndEmptyStringFromObject(converted);
};

// Converts API response to be displayed in a form on the Edit screen.
export const convertGetAdjustmentDeductions200ResponseResultsInnerToScreenItem =
  (
    response: GetAdjustmentDeductions200ResponseResultsInner
  ): Partial<AdjustmentDeductionScreenItem> => {
    const converted: AdjustmentDeductionScreenItem = {
      staffId: response.staff.staffId ?? undefined,
      staffNumber: response.staff.staffNumber ?? undefined,
      staffName: `${response.staff.lastName} ${response.staff.firstName}`,
      adjustmentDeductionId: response.adjustmentDeductionId,
      adjustmentDeductionTypeId: response.adjustmentDeductionTypeId,
      adjustmentDeductionTypeName: response.adjustmentDeductionTypeName,
      adjustmentDeductionName: response.adjustmentDeductionName ?? undefined,
      adjustmentDeductionFee: convertFee(response.adjustmentDeductionFee),
      adjustmentDeductionTermFrom:
        response.adjustmentDeductionTerm.adjustmentDeductionTermFrom,
      adjustmentDeductionTermTo:
        response.adjustmentDeductionTerm.adjustmentDeductionTermTo,
      adjustmentDeductionFeeDefaultPlus: getPlusMinus(
        Number(response.adjustmentDeductionFee)
      ),
    };
    return removeUndefinedAndEmptyStringFromObject(converted);
  };

// Check if data is ready to submit.
export const isAdjustmentDeductionScreenItem = (
  formData: Record<string, any>
): formData is AdjustmentDeductionScreenItem => {
  // test if all of the required fields are present
  let ret = false;

  // if any of the required fields are missing, return false
  // However, it is still valid if boolean fiead is false, and number field is 0.
  try {
    ret =
      formData.adjustmentDeductionTermFrom &&
      formData.staffId !== undefined &&
      formData.adjustmentDeductionTypeId !== undefined &&
      formData.adjustmentDeductionFee &&
      formData.adjustmentDeductionFeeDefaultPlus;
  } catch {
    return false;
  }
  return !!ret;
};
