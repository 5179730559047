// https://dev.to/the_one/pinia-how-to-reset-stores-created-with-functionsetup-syntax-1b74
// IMPORTANT: Deep-copy the initial state of the store to remove references to the copy itself.
// Refer to https://javascript.info/object-copy to learn more about deep copying.

import _ from "lodash-es";
import type { Store } from "pinia";

export default function storeReset({ store }: { store: Store }) {
  const initialState = _.cloneDeep(store.$state);
  store.$reset = () => store.$patch(_.cloneDeep(initialState));
}
