import { InsuranceTypeApiFactory } from "@/api";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import axiosInstance from "@/utils/axios-customized-instance";
import { safeAsyncCall } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const { getInsuranceType, postInsuranceType, putInsuranceType } =
  InsuranceTypeApiFactory(...args, undefined, axiosInstance);

export default function useInsuranceTypeService() {
  return {
    get: safeAsyncCall(async (...args: Parameters<typeof getInsuranceType>) => {
      const res = await getInsuranceType(...args);
      return res.data; // { data, status }
    }),
    add: safeAsyncCall(
      async (...args: Parameters<typeof postInsuranceType>) => {
        const res = await postInsuranceType(...args);
        return res; // { data, status }
      }
    ),
    update: safeAsyncCall(
      async (...args: Parameters<typeof putInsuranceType>) => {
        const res = await putInsuranceType(...args);
        return res; // { data, status }
      }
    ),
  };
}
