import { useEnvironmentVars } from "@/composables/use-environment-vars";
import { AdjustmentDeductionQueryApiFactory } from "@/api";
import axiosInstance from "@/utils/axios-customized-instance";
import { safeAsyncCall, safeAsyncCallAndRaiseError } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const {
  getAdjustmentDeductions,
  getAdjustmentDeductionCsv,
  postAdjustmentDeductionCsv,
  postAdjustmentDeductionIncentiveCsv,
} = AdjustmentDeductionQueryApiFactory(...args, undefined, axiosInstance);

/**
 * AdjustmentDeductionQuery.
 *
 * call getAdjustmentDeductions API.
 * @param args
 * @returns GetAdjustmentDeductions200Response
 */
export default function useAdjustmentDeductionQueryService() {
  return {
    findBy: safeAsyncCall(
      async (...args: Parameters<typeof getAdjustmentDeductions>) => {
        const res = await getAdjustmentDeductions(...args);
        return res;
      }
    ),

    /**
     * This function calls GetAdjustmentDeductionCsv API with filtering conditions as params.
     */
    exportBy: safeAsyncCallAndRaiseError(
      async (...args: Parameters<typeof getAdjustmentDeductionCsv>) => {
        const res = await getAdjustmentDeductionCsv(...args);
        return res;
      }
    ),

    /**
     * This function calls postAdjustmentDeductionCsv API with the csv file a user attaches.
     */
    importFile: safeAsyncCallAndRaiseError(
      async (...args: Parameters<typeof postAdjustmentDeductionCsv>) => {
        const res = await postAdjustmentDeductionCsv(...args);
        return res.data;
      }
    ),

    /**
     * This function calls PostAdjustmentDeductionIncentiveCsv API with the csv file a user attaches.
     */
    importIncentiveFile: safeAsyncCallAndRaiseError(
      async (
        ...args: Parameters<typeof postAdjustmentDeductionIncentiveCsv>
      ) => {
        const res = await postAdjustmentDeductionIncentiveCsv(...args);
        return res.data;
      }
    ),
  };
}
