import type { Market } from "@/api/";
import _ from "lodash-es";

/**
 * List of Dummy Market for GetMarketApi
 */
export let dummyData: (Market & {
  marketId?: number;
})[] = [
  {
    marketId: 1234567,
    marketName: "ITZマーケット",
    sortNumber: 10,
  },
  {
    marketId: 9999999,
    marketName: "大澤マーケット",
    sortNumber: 20,
  },
  {
    marketId: 1,
    marketName: "品川マーケット",
    sortNumber: 30,
  },
];

// Replace function to use with mock Put requests.
export function replace(oldRecord: any, updatedRecord: any) {
  dummyData = _.concat(
    _.filter(dummyData, (record) => record !== oldRecord),
    updatedRecord
  );
  sortBySortNumber();
}

// function to sort the data by 'sortNumber' in ascending order
function sortBySortNumber(): void {
  dummyData.sort((a, b) => a.sortNumber - b.sortNumber);
}

// add new data, while also sorting by sortNumber
export function addNewData(newData: any): void {
  dummyData.push(newData);
  sortBySortNumber();
}
