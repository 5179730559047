import { defineStore } from "pinia";
import { computed, ref } from "vue";

//  type for incentive shares

export type IncentiveShareType = {
  companyShare: string; // 本社取分率
  myselfShare: string; //担当募集人 取分率
  bossId: number | undefined; // 所属長
  bossShare: string; // 所属長 取分率
  otherStaffId1: number | undefined; //その他募集人１
  otherStaffShare1: string; // 取分率
  otherStaffId2: number | undefined; //その他募集人2
  otherStaffShare2: string; //取分率
  otherStaffId3: number | undefined; // その他募集人3
  otherStaffShare3: string; // 取分率
  marketShare: string; //マーケット 取分率
};

export type IncentiveShare = IncentiveShareType;

export const useContractReportIncentiveShareStore = defineStore(
  "incentive-share",
  () => {
    // initialize state
    const currentItem = ref<IncentiveShare>({
      companyShare: "0",
      myselfShare: "0",
      bossShare: "0",
      otherStaffShare1: "0",
      otherStaffShare2: "0",
      otherStaffShare3: "0",
      marketShare: "0",
      bossId: undefined,
      otherStaffId1: undefined,
      otherStaffId2: undefined,
      otherStaffId3: undefined,
      marketId: undefined,
    });

    const clearContractReports = () => {
      currentItem.value = {};
    };

    // declare getter/setters
    const incentiveShare = computed((): IncentiveShare => {
      return currentItem.value;
    });
    const setIncentiveShare = (incentiveShare: IncentiveShare) => {
      currentItem.value = incentiveShare;
    };

    const incentiveRates = computed((): number[] => {
      return [
        Number(currentItem.value.companyShare),
        Number(currentItem.value.myselfShare),
        Number(currentItem.value.bossShare),
        Number(currentItem.value.otherStaffShare1),
        Number(currentItem.value.otherStaffShare2),
        Number(currentItem.value.otherStaffShare3),
        Number(currentItem.value.marketShare),
      ];
    });

    return {
      currentItem,
      clearContractReports,
      incentiveShare,
      setIncentiveShare,
      incentiveRates,
    };
  }
);
