import { rest } from "msw";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import _ from "lodash-es";
import { format } from "date-fns";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);
import {
  dummyResultOfGetPerformanceBasedPayCheck,
  dummyPerformanceBasedPayResults,
} from "./dummy-data";

const duration = import.meta.env.VITE_ENV === "test" ? 0 : 2000;

/**
 * PerformanceBasedPayQuery
 * GetPerformanceBasedPayCheck API
 * PutPerformanceBasedPayCalculation API
 * GetPerformanceBasedPayPayrollCsv API
 */

export const handlers = [
  // GetPerformanceBasedPayCheck API
  rest.get(
    `${BASE_PATH}/performanceBasedPay/calculation/check/:month`,
    (req, res, ctx) => {
      // get month from path params
      const month = req.params.month;

      // get the current month
      const currentMonth = format(new Date(), "yyyyMM");

      // find the item that matches the month
      const item = _.find(dummyResultOfGetPerformanceBasedPayCheck, (item) => {
        return item.month === month || currentMonth === month;
      });

      // Pick noCommissionImportedInsuranceCompanies and noCommissionCalculatedInsuranceCompanies from the item
      const result = _.pick(item, [
        "noCommissionImportedInsuranceCompanies",
        "noCommissionCalculatedInsuranceCompanies",
      ]);

      // rondomly set an empty array to the noCommissionCalculatedInsuranceCompanies property from the result
      // Plus, this process only happens when it is in the development environment.
      if (import.meta.env.VITE_ENV !== "test") {
        if (Math.random() < 0.5) {
          result.noCommissionCalculatedInsuranceCompanies = [];
        }
      }
      return res(ctx.status(200), ctx.json(result), ctx.delay(duration));
    }
  ),

  // PutPerformanceBasedPayCalculation API
  rest.put(
    `${BASE_PATH}/performanceBasedPay/calculation/:month`,
    (req, res, ctx) => {
      return res(ctx.status(204), ctx.delay(duration));
    }
  ),

  // GetPerformanceBasedPayPayrollCsv API
  rest.get(
    `${BASE_PATH}/performanceBasedPay/:month/payroll/csv`,
    (req, res, ctx) => {
      // return a tantative CSV file
      const csvData = `"header1","header2","header3"
"row1col1","row1col2","row1col3"
"row2col1","row2col2","row2col3"`;

      return res(
        ctx.status(200),
        ctx.set("Content-Type", "text/csv"),
        ctx.set("Content-Disposition", `attachment;`),
        ctx.body(csvData)
      );
    }
  ),

  // GetPerformanceBasedPayResults
  rest.get(
    `${BASE_PATH}/performanceBasedPay/result/:month`,
    (req, res, ctx) => {
      // get month from path params
      const month = req.params.month;

      //get optional staffId param
      const staffId = Number(req.url.searchParams.get("staffId"));

      let results = dummyPerformanceBasedPayResults;

      // if there is a staffId, filter results by it.
      if (staffId) {
        results = dummyPerformanceBasedPayResults.filter((record) => {
          return record.staff.staffId == staffId;
        });
      }

      const response = {
        results: results,
      };

      return res(ctx.status(200), ctx.json(response));
    }
  ),
];
