<script setup lang="ts">
import { onMounted, watchEffect } from "vue";
import Encoding from "encoding-japanese";

const props = defineProps<{
  exportAction: (...args: any[]) => Promise<any>;
  exportArgs: any[];
  fileNameSuffix: string;
  buttonId: string;
}>();

type ExportFunction = (...args: any[]) => Promise<any>;
// 現在アタッチされているハンドラへの参照を保持するための変数
let currentHandler: ExportFunction;

const createDownloadHandler = (
  exportFunction: ExportFunction,
  exportArgs: any[],
  fileNameSuffix: string
) => {
  return async () => {
    try {
      const response = await exportFunction(...exportArgs);
      const fileName = `${new Date()
        .toISOString()
        .slice(0, 10)}_${fileNameSuffix}.csv`;
      const encodedFileName = encodeURIComponent(fileName);

      const disposition = response.headers["content-disposition"];
      const filename = disposition
        ? decodeURIComponent(
            disposition.match(/filename\*=UTF-8''([^;]+)/)?.[1] ||
              `${encodedFileName}`
          )
        : `${fileName}`;

      // Use Encoding.stringToCode to convert response.data from a string to a code array
      const sjisArray = Encoding.convert(Encoding.stringToCode(response.data), {
        // Specify the target encoding as Shift-JIS ("SJIS")
        to: "SJIS",
        // Specify the original encoding as Unicode ("UNICODE")
        from: "UNICODE",
      });
      // Create a new Blob object from the Shift-JIS encoded array
      // Uint8Array represents a typed array of 8-bit unsigned integers
      // Set the 'type' option to "text/csv;charset=shift-jis;" to indicate that the Blob represents a CSV file encoded in Shift-JIS
      const url = URL.createObjectURL(
        new Blob([new Uint8Array(sjisArray)], {
          type: "text/csv;charset=shift-jis;",
        })
      );
      const link = Object.assign(document.createElement("a"), {
        href: url,
        download: filename,
      });
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(
        "There has been a problem with connection to the back end:",
        error
      );
    }
  };
};

const attachDownloadHandler = (id: string, newHandler: () => Promise<void>) => {
  const button = document.getElementById(id);
  if (button) {
    // remove the current handler
    if (currentHandler) {
      button.removeEventListener("click", currentHandler);
    }
    // attach the new handler
    button.addEventListener("click", newHandler);
    // update the current handler
    currentHandler = newHandler;
  }
};

onMounted(() => {
  // Generate a specific handler for AdjustmentDeductionManagement
  const handler = createDownloadHandler(
    props.exportAction,
    props.exportArgs,
    props.fileNameSuffix
  );
  attachDownloadHandler(props.buttonId, handler);
});

watchEffect(() => {
  // Generate a specific handler for AdjustmentDeductionManagement
  const handler = createDownloadHandler(
    props.exportAction,
    props.exportArgs,
    props.fileNameSuffix
  );
  attachDownloadHandler(props.buttonId, handler);
});
</script>

<template>
  <!-- CSV export button gimic using v-btn -->
  <slot></slot>
</template>
