import { rest } from "msw";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import { dummyInsuranceCompanies } from "../InsuranceCompany/dummy-data";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

export const handlers = [
  /**
   * InsuranceCompanyQuery
   * GetInsuranceCompanies
   */
  rest.get(`${BASE_PATH}/insuranceCompany`, (req, res, ctx) => {
    const keyword = req.url.searchParams.get("keyword");
    const insuranceCategory = req.url.searchParams.get("insuranceCategory");
    let filteredInsuranceCompanies;

    filteredInsuranceCompanies = dummyInsuranceCompanies;

    if (keyword) {
      filteredInsuranceCompanies = filteredInsuranceCompanies.filter(
        (company) => company?.insuranceCompanyName.includes(keyword as string)
      );
    }

    if (insuranceCategory) {
      filteredInsuranceCompanies = filteredInsuranceCompanies.filter(
        (company) => {
          return company.insuranceCategory == insuranceCategory;
        }
      );
    }

    return res(
      ctx.status(200),
      ctx.json({
        results: filteredInsuranceCompanies,
        pagination: {
          totalCount: 0,
        },
      })
    );
  }),
];
