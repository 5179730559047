import { handlers as PotentialCustomerHandlers } from "@/mocks/PotentialCustomer/handlers";
import { handlers as StaffQueryHandlers } from "@/mocks/StaffQuery/handlers";
import { handlers as ContractReportQueryHandlers } from "./ContractReportQuery/handlers";
import { handlers as ContractReportHandlers } from "./ContractReport/handlers";
import { handlers as SecuritiesQueryHandlers } from "./SecuritiesQuery/handlers";
import { handlers as SecuritiesHandlers } from "./Securities/handlers";
import { handlers as InsuranceCompanyQueryHandlers } from "./InsuranceCompanyQuery/handlers";
import { handlers as InsuranceCompanyHandlers } from "./InsuranceCompany/handlers";
import { handlers as InsuranceTypeHandlers } from "./InsuranceType/handlers";
import { handlers as InsuranceTypeQueryHandlers } from "./InsuranceTypeQuery/handlers";
import { handlers as DepartmentHandlers } from "./Department/handlers";
import { handlers as DepartmentQueryHandlers } from "./DepartmentQuery/handlers";
import { handlers as StaffHandlers } from "./Staff/handlers";
import { handlers as MarketQueryHandlers } from "./MarketQuery/handlers";
import { handlers as MarketHandlers } from "./Market/handlers";
import { handlers as AdjustmentDeductionTypesQueryHandlers } from "./AdjustmentDeductionTypeQuery/handlers";
import { handlers as AdjustmentDeductionHandlers } from "./AdjustmentDeduction/handlers";
import { handlers as AdjustmentDeductionQueryHandlers } from "./AdjustmentDeductionQuery/handlers";
import { handlers as AdjustmentDeductionTypesHandlers } from "./AdjustmentDeductionType/handlers";
import { handlers as CommissionQueryHandlers } from "./CommissionQuery/handlers";
import { handlers as CommissionServiceHandlers } from "./Commission/handlers";
import { handlers as PerformanceBasedPayQueryServiceHandlers } from "./PerformanceBasedPayQuery/handlers";
import { handlers as CommissionFormatHandlers } from "./CommissionFormat/handlers";
import { handlers as PayrollServiceHandlers } from "./Payroll/handlers";
import { handlers as StaffMapQueryHandlers } from "./StaffMapQuery/handlers";
import { handlers as StaffMapHandlers } from "./StaffMap/handlers";
import { handlers as PurchasePotentialCustomerQueryHandlers } from "./PurchasePotentialCustomerQuery/handlers";
import { handlers as PurchasePotentialCustomerHandlers } from "./PurchasePotentialCustomer/handlers";
import { handlers as ZipCloudHandlers } from "./zipcloud";
import { handlers as OperationLogQueryHandlers } from "./OperationLogQuery/handlers";
import { rest } from "msw";

const timeoutApiHandlers = [
  // Return 500 error 95% of the time except for test environment
  rest.get("https://timeout.com/duration/:duraton", (req, res, ctx) => {
    const duration = Number(req.params.duraton);
    return res(
      ctx.status(500),
      ctx.delay(Number(`${duration - 300}`)),
      ctx.json({
        message: `timeout of ${duration - 300}ms exceeded`,
      })
    );
  }),
];

// export const handlers = [
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? PotentialCustomerHandlers
//     : PotentialCustomerHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? StaffQueryHandlers
//     : StaffQueryHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? ContractReportQueryHandlers
//     : ContractReportQueryHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? ContractReportHandlers
//     : ContractReportHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? SecuritiesQueryHandlers
//     : SecuritiesQueryHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? SecuritiesHandlers
//     : SecuritiesHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? InsuranceCompanyQueryHandlers
//     : InsuranceCompanyQueryHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? InsuranceCompanyHandlers
//     : InsuranceCompanyHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? InsuranceTypeHandlers
//     : InsuranceTypeHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? InsuranceTypeQueryHandlers
//     : InsuranceTypeQueryHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? DepartmentHandlers
//     : DepartmentHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? DepartmentQueryHandlers
//     : DepartmentQueryHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? StaffHandlers
//     : StaffHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? MarketQueryHandlers
//     : MarketQueryHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? MarketHandlers
//     : MarketHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? AdjustmentDeductionTypesQueryHandlers
//     : AdjustmentDeductionTypesQueryHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? AdjustmentDeductionHandlers
//     : AdjustmentDeductionHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? AdjustmentDeductionQueryHandlers
//     : AdjustmentDeductionQueryHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? AdjustmentDeductionTypesHandlers
//     : AdjustmentDeductionTypesHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ? ZipCloudHandlers : []),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? CommissionQueryHandlers
//     : CommissionQueryHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? CommissionServiceHandlers
//     : CommissionServiceHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? PerformanceBasedPayQueryServiceHandlers
//     : PerformanceBasedPayQueryServiceHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? CommissionFormatHandlers
//     : CommissionFormatHandlers),
//   ...(import.meta.env.VITE_ENV === "test" ||
//   import.meta.env.VITE_ENV === "development"
//     ? PayrollServiceHandlers
//     : PayrollServiceHandlers),
// ];

export const handlers = [
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? PotentialCustomerHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? StaffQueryHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? ContractReportQueryHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? ContractReportHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? SecuritiesQueryHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? SecuritiesHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? InsuranceCompanyQueryHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? InsuranceCompanyHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? InsuranceTypeHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? InsuranceTypeQueryHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? DepartmentHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? DepartmentQueryHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? StaffHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? MarketQueryHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? MarketHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? AdjustmentDeductionTypesQueryHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? AdjustmentDeductionHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? AdjustmentDeductionQueryHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? AdjustmentDeductionTypesHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? CommissionQueryHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? CommissionServiceHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? PerformanceBasedPayQueryServiceHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? CommissionFormatHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? PayrollServiceHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? StaffMapQueryHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? StaffMapHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? PurchasePotentialCustomerQueryHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? PurchasePotentialCustomerHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" ||
  import.meta.env.VITE_ENV === "development"
    ? OperationLogQueryHandlers
    : []),
  ...(import.meta.env.VITE_ENV === "test" || import.meta
    ? timeoutApiHandlers
    : []),
];
