import { rest } from "msw";
import { dummyOperationLogs } from "./dummy-data";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

export const handlers = [
  /**
   * GetOperationLogs
   */
  rest.get(`${BASE_PATH}/operationLog`, (req, res, ctx) => {
    const logAtFrom = req.url.searchParams.get("logAtFrom");
    const logAtTo = req.url.searchParams.get("logAtTo");
    const apiNames = req.url.searchParams.getAll("apiNames");
    const resourceId = req.url.searchParams.get("resourceId");
    const staffId = Number(req.url.searchParams.get("staffId"));

    let filteredOperationLogs;
    filteredOperationLogs = dummyOperationLogs;

    filteredOperationLogs = filteredOperationLogs.filter((operationLog) => {
      return apiNames.includes(operationLog.apiName);
    });

    if (logAtFrom) {
      filteredOperationLogs = filteredOperationLogs.filter((operationLog) => {
        return new Date(logAtFrom) <= new Date(operationLog.logAt);
      });
    }

    if (logAtTo) {
      filteredOperationLogs = filteredOperationLogs.filter((operationLog) => {
        return new Date(operationLog.logAt) <= new Date(logAtTo);
      });
    }

    if (resourceId) {
      filteredOperationLogs = filteredOperationLogs.filter((operationLog) => {
        return operationLog.resourceId === resourceId;
      });
    }

    if (staffId) {
      filteredOperationLogs = filteredOperationLogs.filter((operationLog) => {
        return operationLog.staff.staffId === staffId;
      });
    }

    return res(
      ctx.status(200),
      ctx.json({
        results: filteredOperationLogs,
        pagination: {
          totalCount: 0,
        },
      })
    );
  }),
];
