import type { GetPurchasePotentialCustomers200ResponseResultsInner } from "@/api";
import _ from "lodash";

export function replace(oldRecord: any, newRecord: any) {
  getPurchasePotentialCustomersResults = _.concat(
    _.filter(
      getPurchasePotentialCustomersResults,
      (record) => record !== oldRecord
    ),
    newRecord
  );
}

const genDummyData =
  (): GetPurchasePotentialCustomers200ResponseResultsInner[] => {
    return [
      /// #1
      {
        purchasePotentialCustomerId: 1234567,
        staff: {
          firstName: "ルカ",
          lastName: "山中",
          staffId: 1234567,
          staffNumber: "7809A",
        },
        potentialCustomer: {
          potentialCustomerId: 4162085,
          marketId: 1234567,
          marketName: "ITZマーケット",
          potentialCustomerName: "大澤麻由美",
          staff: {
            firstName: "Valerie",
            lastName: "Yamanaka",
            staffId: 7777,
            staffNumber: "7809A",
          },
        },
        potentialCustomerFee: {
          purchasePotentialCustomerTotalFee: 50000,
          purchasePotentialCustomerSupportAmount: 10000,
          purchasePotentialCustomerFee: 20000,
          purchasePotentialCustomerMonth: "202204",
        },
        purchasePotentialCustomerName: "ITZマーケット 大澤麻由美",
        purchasePotentialCustomerOutOfScopeFlag: true,
      },
      /// #2
      {
        purchasePotentialCustomerId: 3837462,
        staff: {
          firstName: "Jane",
          lastName: "Smith",
          staffId: 90909,
          staffNumber: "00123",
        },
        potentialCustomer: {
          potentialCustomerId: 3837462,
          marketId: 9999999,
          marketName: "大澤マーケット",
          potentialCustomerName: "田中惠美子",
          staff: {
            firstName: "Jane",
            lastName: "Smith",
            staffId: 90909,
            staffNumber: "00123",
          },
        },
        potentialCustomerFee: {
          purchasePotentialCustomerTotalFee: 50000,
          purchasePotentialCustomerSupportAmount: 10000,
          purchasePotentialCustomerFee: 20000,
          purchasePotentialCustomerMonth: "202204",
        },
        purchasePotentialCustomerName: "大澤マーケット 田中惠美子",
        purchasePotentialCustomerOutOfScopeFlag: false,
      },
      /// #3
      {
        purchasePotentialCustomerId: 1234564,
        staff: {
          firstName: "ルカ",
          lastName: "山中",
          staffId: 1234567,
          staffNumber: "00123",
        },
        potentialCustomer: {
          potentialCustomerId: undefined,
          marketId: 1,
          marketName: "品川マーケット",
          potentialCustomerName: undefined,
        },
        potentialCustomerFee: {
          purchasePotentialCustomerTotalFee: 50000,
          purchasePotentialCustomerSupportAmount: 10000,
          purchasePotentialCustomerFee: 20000,
          purchasePotentialCustomerMonth: "202204",
        },
        purchasePotentialCustomerName: "品川マーケット 大澤一誠",
        purchasePotentialCustomerOutOfScopeFlag: true,
      },
    ];
  };

export let getPurchasePotentialCustomersResults: GetPurchasePotentialCustomers200ResponseResultsInner[] =
  genDummyData();

export const reset = () => {
  getPurchasePotentialCustomersResults = genDummyData();
};
