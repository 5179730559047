import type {
  GetContractReport200Response,
  GetContractReports200ResponseResultsInner,
  InsuranceCategory,
  NonLifeInsuranceAccountingCategory,
  NonLifeInsuranceDepositCategory,
  PostContractReportRequest,
  PutContractReportRequest,
} from "@/api";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import type {
  ContractReportListIndividualScreenItem,
  ContractReportListCorporationScreenItem,
  ContractReportListLifeInsuranceScreenItem,
  ContractReportListNonLifeInsuranceScreenItem,
  ContractReportScreenItem,
  Case,
} from "@/types/ContractReports/contract-reports";
import { defineStore } from "pinia";
import { ref, computed, type Ref } from "vue";
import { useContractReportInsuranceInfoStore } from "@/stores/ContractReports/InsuranceInfo";
import { useContractReportContractorStore } from "@/stores/ContractReports/Contractor";
import { useContractReportInsuredStore } from "@/stores/ContractReports/Insured";
import { useContractReportCommissionShareStore } from "@/stores/ContractReports/CommissionShare";
import { useContractReportIncentiveShareStore } from "@/stores/ContractReports/IncentiveShare";
import {
  useContractReportPotentialCustomerTypeStore,
  type PotentialCustomerType,
} from "@/stores/ContractReports/PotentialCustomerType";
import type { InsuranceInfo } from "@/stores/ContractReports/InsuranceInfo";
import type { Contractor } from "@/stores/ContractReports/Contractor";
import type { Insured } from "@/stores/ContractReports/Insured";
import type { CommissionShare } from "@/stores/ContractReports/CommissionShare";
import type { IncentiveShare } from "@/stores/ContractReports/IncentiveShare";
import useContractReportQueryService from "@/services/use-contract-reports-service";
import {
  getValueFromEnum,
  mapFalsyToUndefinedAndCall,
  removeUndefinedAndEmptyStringFromObject,
} from "@/utils/helper";

import _ from "lodash-es";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

/**
 * User defined guard function.
 *
 * If the value is not null, it means that the type of
 * the value is Response(GetIndividualPotentialCustomersResponse["results"]).
 */
const isDataExists = (value: any): value is Response => {
  return value !== null;
};

export const useContractReportStore = defineStore("contract-reports", () => {
  const insuranceInfo = useContractReportInsuranceInfoStore();
  const contractor = useContractReportContractorStore();
  const insured = useContractReportInsuredStore();
  const commissionShare = useContractReportCommissionShareStore();
  const incentiveShare = useContractReportIncentiveShareStore();
  const potentialCustomerType = useContractReportPotentialCustomerTypeStore();

  const { findBy, add, get, update, deleteContractReport } =
    useContractReportQueryService();

  // state
  /**
   * The collection of each  partial form data
   */
  const currentScreenItem = ref<{
    insuranceInfo: Ref<InsuranceInfo>;
    contractor: Ref<Contractor>;
    insured: Ref<Insured>;
    commissionShare: Ref<CommissionShare>;
    incentiveShare: Ref<IncentiveShare>;
    potentialCustomerInfo: Ref<PotentialCustomerType>;
  }>({
    insuranceInfo: computed(() => insuranceInfo.currentItem),
    contractor: computed(() => contractor.currentItem),
    insured: computed(() => insured.currentItem),
    commissionShare: computed(() => commissionShare.currentItem),
    incentiveShare: computed(() => incentiveShare.currentItem),
    potentialCustomerInfo: computed(() => potentialCustomerType.currentItem),
  });

  /**
   * The collection for search results
   */
  const contracts = ref<
    GetContractReports200ResponseResultsInner[] | undefined
  >(undefined);

  // This ref is used to show the message when no data is found
  const noDataFound = ref<boolean>(false);

  const contractsListIndividual = computed(() => {
    // return undefined if contracts.value is undefined
    if (contracts.value === undefined) return undefined;

    const res = contracts.value.map((contract) => {
      if (contract.contractorAttribute == "individual") {
        return convertGetContractReports200ResponseResultsInnerToIndividualListItem(
          contract
        );
      }
    });
    return res;
  });

  const contractsListCorporation = computed(() => {
    // return undefined if contracts.value is undefined
    if (contracts.value === undefined) return undefined;

    const res = contracts.value.map((contract) => {
      if (contract.contractorAttribute == "corporation") {
        return convertGetContractReports200ResponseResultsInnerToCorporationListItem(
          contract
        );
      }
    });
    return res;
  });

  const createContractsList = (
    insuranceCategory: "lifeInsurance" | "nonLifeInsurance"
  ) => {
    return computed(() => {
      return contracts.value?.map((contract) => {
        if (contract.insuranceCategory === insuranceCategory) {
          if (insuranceCategory === "lifeInsurance") {
            return convertGetContractReports200ResponseResultsInnerToLifeInsuranceListItem(
              contract
            );
          } else {
            return convertGetContractReports200ResponseResultsInnerToNonLifeInsuranceListItem(
              contract
            );
          }
        }
      });
    });
  };

  const contractsListLifeInsurance = createContractsList("lifeInsurance");
  const contractsListNonLifeInsurance = createContractsList("nonLifeInsurance");

  const contractsRef = computed(() => {
    return contracts;
  });

  const clearContracts = () => {
    contracts.value = undefined;
  };

  /**
   * APIs to manipulate pinia states.
   */

  const contractsAdd = async (contract: any) => {
    const res = await add(
      // TODO: type error because PostContractReportRequest has fields marked
      // as required that may not exist depending on if it is
      // individual or corporation.
      // Can this be fixed?
      convertScreenItemToPostContractReportRequest(
        contract
      ) as PostContractReportRequest
    );

    return res;
  };

  const contractsFindBy = mapFalsyToUndefinedAndCall(
    async (...args: Parameters<typeof findBy>) => {
      // reset noDataFound
      noDataFound.value = false;

      const response = await findBy(...args);
      if (isDataExists(response) && response.results) {
        contracts.value = response.results;

        // Set noDataFound if the length of the results is 0
        if (response.results.length === 0) {
          noDataFound.value = true;
        }

        return response.results;
      } else {
        return [];
      }
    }
  );

  const contractsGet = async (...args: Parameters<typeof get>) => {
    const res = await get(...args);

    // Guard block
    if (!res || (res && res.status !== 200)) return;

    const { data, status } = res;

    console.log("get!", data);
    if (status === 200)
      return convertGetContractReport200ResponseToScreenItem(data);
  };

  const contractsUpdate = async (
    id: number,
    contract: ContractReportScreenItem
  ) => {
    console.log("add!", id, contract);
    if (isContractReportScreenItem(contract)) {
      const res = await update(
        id,
        convertScreenItemToPostContractReportRequest(
          contract
        ) as PutContractReportRequest
      );

      // Guard block
      if (!res) return;

      const { status } = res;
      if (status != 204) throw new Error("Failed to update");
    } else {
      throw new Error("Incorrect screen item");
    }
  };

  /**
   * Delete a contract report which is specified
   */
  const contractDelete = async (id: number): Promise<void> => {
    try {
      await deleteContractReport(id);
    } catch (error) {
      console.error(error);
      throw new Error("Failed to delete");
    }
  };

  /**
   * Clear the data of each partial form data
   */
  const clearContractReports = () => {
    contractor.$patch((state) => {
      state.currentItem = {};
      console.log("reset contractor");
    });
    insuranceInfo.$reset();
    insured.$reset();
    commissionShare.$reset();
    incentiveShare.$reset();
    potentialCustomerType.$reset();
  };

  return {
    contracts,
    currentScreenItem,
    clearContracts,
    contractsListIndividual,
    contractsListCorporation,
    contractsListLifeInsurance,
    contractsListNonLifeInsurance,
    contractsRef,
    contractsFindBy,
    contractsAdd,
    contractsGet,
    contractsUpdate,
    clearContractReports,
    contractDelete,
    noDataFound,
  };
});

//----------------------------------------------------------------
// Business Logic
//----------------------------------------------------------------

/**
 * convert shape of GetContractReports200ResponseResultsInner to ContractReportListIndividualScreenItem
 * @param response
 * @returns ContractReportListIndividualScreenItem
 */
export const convertGetContractReports200ResponseResultsInnerToIndividualListItem =
  (
    response: GetContractReports200ResponseResultsInner
  ): ContractReportListIndividualScreenItem => {
    const converted: ContractReportListIndividualScreenItem = {
      id: response.contractReportId,
      departmentName: response.staff.departmentName,
      staffName: `${response.staff.lastName} ${response.staff.firstName}`,
      staffNumber: response.staff.staffNumber,
      contractorName: `${response.contractorIndividual?.lastName} ${response.contractorIndividual?.firstName}`,
      contractorDateOfBirth: response.contractorIndividual?.dateOfBirth,
      // eslint-disable-next-line prettier/prettier
      insuredName: `${response.insured?.lastName ?? ""} ${
        response.insured?.firstName ?? ""
      }`,
      insuredDateOfBirth: response.insured?.dateOfBirth,
      telLast: response.contractorIndividual?.telLast,
      address: _.compact([
        response.contractorIndividual?.address1,
        response.contractorIndividual?.address2,
        response.contractorIndividual?.address3,
      ]).join(" "),
    };
    return removeUndefinedAndEmptyStringFromObject(converted);
  };
/**
 * convert shape of GetContractReports200ResponseResultsInner to ContractReportListIndividualScreenItem
 * @param response
 * @returns ContractReportListIndividualScreenItem
 */

export const convertGetContractReports200ResponseResultsInnerToCorporationListItem =
  (
    response: GetContractReports200ResponseResultsInner
  ): ContractReportListCorporationScreenItem => {
    const converted: ContractReportListCorporationScreenItem = {
      id: response.contractReportId,
      departmentName: response.staff.departmentName,
      staffNumber: response.staff.staffNumber,
      staffName: `${response.staff.lastName} ${response.staff.firstName}`,
      corporationName: response.contractorCorporation?.corporationName,
      telLast: response.contractorCorporation?.telLast,
      address: _.compact([
        response.contractorCorporation?.address1,
        response.contractorCorporation?.address2,
        response.contractorCorporation?.address3,
      ]).join(" "),
    };
    return removeUndefinedAndEmptyStringFromObject(converted);
  };

/**
 * convert shape of GetContractReports200ResponseResultsInner to
 * LifeInsurance List Item
 * @param response
 * @returns ContractReportListLifeInsuranceScreenItem
 */

export const convertGetContractReports200ResponseResultsInnerToLifeInsuranceListItem =
  (
    response: GetContractReports200ResponseResultsInner
  ): Partial<ContractReportListLifeInsuranceScreenItem> => {
    const converted: ContractReportListLifeInsuranceScreenItem = {
      id: response.contractReportId,
      departmentName: response.staff.departmentName,
      staffNumber: response.staff.staffNumber,
      staffName: `${response.staff.lastName} ${response.staff.firstName}`,
      contractorName: `${response.contractorIndividual?.lastName} ${response.contractorIndividual?.firstName}`,
      corporationName: response.contractorCorporation?.corporationName,
      insuredName: `${response.insured?.lastName} ${response.insured?.firstName}`,
      insuranceCompany: response.insuranceInfo.insuranceCompanyName,
      insuranceType: response.insuranceInfo.insuranceTypeName,
      paymentMethod: getValueFromEnum(
        "paymentMethod",
        response.insuranceInfo.paymentMethod
      ),
      deemedAnnualPayment: response.insuranceInfo.deemedAnnualPayment
        ? "有"
        : "無",
      insuranceFee: response.insuranceInfo.insuranceFee,
      contractReportOn: response.contractReportOn,
      case: getCase(response),
      contractorAttribute: response.contractorAttribute,
    };
    return removeUndefinedAndEmptyStringFromObject(converted);
  };

/**
 * convert shape of GetContractReports200ResponseResultsInner to
 * NonLifeInsurance List Item
 * @param response
 * @returns ContractReportListNonLifeInsuranceScreenItem
 */

export const convertGetContractReports200ResponseResultsInnerToNonLifeInsuranceListItem =
  (
    response: GetContractReports200ResponseResultsInner
  ): Partial<ContractReportListNonLifeInsuranceScreenItem> => {
    const converted: ContractReportListNonLifeInsuranceScreenItem = {
      id: response.contractReportId,
      departmentName: response.staff.departmentName,
      staffNumber: response.staff.staffNumber,
      staffName: `${response.staff.lastName} ${response.staff.firstName}`,
      contractorName: `${response.contractorIndividual?.lastName} ${response.contractorIndividual?.firstName}`,
      corporationName: response.contractorCorporation?.corporationName,
      // eslint-disable-next-line prettier/prettier
      insuredName: `${response.insured?.lastName ?? ""} ${
        response.insured?.firstName ?? ""
      }`,
      insuranceCompany: response.insuranceInfo.insuranceCompanyName,
      policyNumber: response.nonLifeInsuranceInfo?.policyNumber,
      insuranceType: response.insuranceInfo.insuranceTypeName,
      paymentMethod: getValueFromEnum(
        "paymentMethod",
        response.insuranceInfo.paymentMethod
      ),
      deemedAnnualPayment: response.insuranceInfo.deemedAnnualPayment
        ? "有"
        : "無",
      insuranceFee: response.insuranceInfo.insuranceFee,
      contractReportOn: response.contractReportOn,
      case: getCase(response),
      contractorAttribute: response.contractorAttribute,
    };
    return removeUndefinedAndEmptyStringFromObject(converted);
  };

/**
 * Convert shape of GetContractReport200Response to ContractReportScreenItem
 * @param response
 * @returns
 */

export const convertGetContractReport200ResponseToScreenItem = (
  response: GetContractReport200Response
): Partial<ContractReportScreenItem> => {
  const individual = {
    firstName: response.contractorIndividual?.firstName,
    lastName: response.contractorIndividual?.lastName,
    firstNameKana: response.contractorIndividual?.firstNameKana,
    lastNameKana: response.contractorIndividual?.lastNameKana,
    corporationName: undefined,
    dateOfBirth: response.contractorIndividual?.dateOfBirth,
    addressInformation: {
      postCode: response.contractorIndividual?.postCode,
      address1: response.contractorIndividual?.address1,
      address2: response.contractorIndividual?.address2,
      address3: response.contractorIndividual?.address3,
      address4: response.contractorIndividual?.address4,
      address5: response.contractorIndividual?.address5,
    },
    tel1: response.contractorIndividual?.tel1,
    tel2: response.contractorIndividual?.tel2,
    corporationRepresentativeFirstName: undefined,
    corporationRepresentativeLastName: undefined,
    corporationClosingMonth: undefined,
    mailAddress: response.contractorIndividual?.mailAddress,
  };
  const corporation = {
    firstName: undefined,
    lastName: undefined,
    firstNameKana: undefined,
    lastNameKana: undefined,
    dateOfBirth: undefined,
    corporationName: response.contractorCorporation?.corporationName,
    addressInformation: {
      postCode: response.contractorCorporation?.postCode,
      address1: response.contractorCorporation?.address1,
      address2: response.contractorCorporation?.address2,
      address3: response.contractorCorporation?.address3,
      address4: response.contractorCorporation?.address4,
      address5: response.contractorCorporation?.address5,
    },
    tel1: response.contractorCorporation?.tel1,
    tel2: response.contractorCorporation?.tel2,
    corporationRepresentativeFirstName:
      response.contractorCorporation?.corporationRepresentativeFirstName,
    corporationRepresentativeLastName:
      response.contractorCorporation?.corporationRepresentativeLastName,
    corporationClosingMonth:
      response.contractorCorporation?.corporationClosingMonth,
    mailAddress: response.contractorCorporation?.mailAddress,
  };
  const insured = {
    firstName: response.insured?.firstName,
    lastName: response.insured?.lastName,
    firstNameKana: response.insured?.firstNameKana,
    lastNameKana: response.insured?.lastNameKana,
    dateOfBirth: response.insured?.dateOfBirth,
  };
  const insuranceInfo = {
    insuranceCompanyId: response.insuranceInfo.insuranceCompanyId,
    insuranceTypeId: response.insuranceInfo.insuranceTypeId,
    insuranceFee: response.insuranceInfo.insuranceFee,
    paymentMethod: response.insuranceInfo.paymentMethod,
    deemedAnnualPayment: response.insuranceInfo.deemedAnnualPayment,
    contractDateOn: response.lifeInsuranceInfo?.contractDateOn,
    policyNumber: response.nonLifeInsuranceInfo?.policyNumber,
    applicationDateOn: response.nonLifeInsuranceInfo?.applicationDateOn,
    insuranceStartDateOn: response.nonLifeInsuranceInfo?.insuranceStartDateOn,
    insuranceEndDateOn: response.nonLifeInsuranceInfo?.insuranceEndDateOn,
    receiptDateOn: response.nonLifeInsuranceInfo?.receiptDateOn,
    returnDateOn: response.nonLifeInsuranceInfo?.returnDateOn,
    nonLifeInsuranceAccountingCategory:
      response.nonLifeInsuranceInfo?.nonLifeInsuranceAccountingCategory,
    nonLifeInsuranceDepositCategory:
      response.nonLifeInsuranceInfo?.nonLifeInsuranceDepositCategory,
  };

  const commissionShare = {
    companyShare: response.commissionShare.companyShare,
    myselfShare: response.commissionShare.myselfShare,
    bossId: response.commissionShare.bossId,
    bossShare: response.commissionShare.bossShare,
    otherStaffId1: response.commissionShare.otherStaffId1,
    otherStaffShare1: response.commissionShare.otherStaffShare1,
    otherStaffId2: response.commissionShare.otherStaffId2,
    otherStaffShare2: response.commissionShare.otherStaffShare2,
    otherStaffId3: response.commissionShare.otherStaffId3,
    otherStaffShare3: response.commissionShare.otherStaffShare3,
    marketShare: response.commissionShare.marketShare,
  };

  const incentiveShare = {
    companyShare: response.incentiveShare.companyShare,
    myselfShare: response.incentiveShare.myselfShare,
    bossId: response.incentiveShare.bossId,
    bossShare: response.incentiveShare.bossShare,
    otherStaffId1: response.incentiveShare.otherStaffId1,
    otherStaffShare1: response.incentiveShare.otherStaffShare1,
    otherStaffId2: response.incentiveShare.otherStaffId2,
    otherStaffShare2: response.incentiveShare.otherStaffShare2,
    otherStaffId3: response.incentiveShare.otherStaffId3,
    otherStaffShare3: response.incentiveShare.otherStaffShare3,
    marketShare: response.incentiveShare.marketShare,
  };

  const potentialCustomerInfo = {
    potentialCustomerId: response.potentialCustomerInfo.potentialCustomerId,
    potentialCustomerType: response.potentialCustomerInfo.potentialCustomerType,
    supportUse:
      response.potentialCustomerInfo.supportUse === true
        ? "support"
        : "notSupport",
    marketId: response.potentialCustomerInfo.marketId,
  };

  let contractor;
  if (response.contractorAttribute === "individual") {
    contractor = individual;
  } else {
    contractor = corporation;
  }

  const converted: ContractReportScreenItem = {
    insuranceCategory: response.insuranceCategory as InsuranceCategory,
    contractorAttribute: response.contractorAttribute,
    contractReportOn: response.contractReportOn,
    contractReportId: response.contractReportId,
    potentialCustomerInfo,
    staffId: response.staffId,
    insured,
    contractor,
    insuranceInfo,
    commissionShare,
    incentiveShare,
  };

  return removeUndefinedAndEmptyStringFromObject(converted);
};

/**
 * Convert shape of ContractReportScreenItem to PostContractReportRequest
 * @param screenItem
 * @returns
 */

export const convertScreenItemToPostContractReportRequest = (
  screenItem: ContractReportScreenItem
): Partial<PostContractReportRequest> => {
  // Guard function, to check if the screenItem is valid
  if (!isContractReportScreenItem(screenItem))
    throw new Error("Invalid screenItem");

  const converted: PostContractReportRequest = {
    insuranceCategory: screenItem.insuranceCategory,
    contractorAttribute: screenItem.contractorAttribute,
    contractReportOn: screenItem.contractReportOn,
    potentialCustomerInfo: {
      potentialCustomerId: screenItem.potentialCustomerInfo.potentialCustomerId,
      potentialCustomerType:
        screenItem.potentialCustomerInfo.potentialCustomerType,
      supportUse:
        screenItem.potentialCustomerInfo.supportUse === "support" ?? false,
      marketId: screenItem.potentialCustomerInfo.marketId,
    },
    contractorIndividual:
      screenItem.contractorAttribute === "individual"
        ? {
            //契約者  個人
            firstName: screenItem.contractor.firstName ?? "", // @
            lastName: screenItem.contractor.lastName ?? "", // @
            firstNameKana: screenItem.contractor.firstNameKana ?? "", // @
            lastNameKana: screenItem.contractor.lastNameKana ?? "", // @
            dateOfBirth: screenItem.contractor.dateOfBirth ?? "", // @
            postCode: screenItem.contractor.addressInformation?.postCode ?? "", // @
            address1: screenItem.contractor.addressInformation?.address1 ?? "", // @
            address2: screenItem.contractor.addressInformation?.address2 ?? "", // @
            address3: screenItem.contractor.addressInformation?.address3 ?? "", // @
            address4: screenItem.contractor.addressInformation?.address4 ?? "",
            address5: screenItem.contractor.addressInformation?.address5 ?? "",
            tel1: screenItem.contractor.tel1 ?? "", // @
            tel2: screenItem.contractor.tel2 ?? "",
            mailAddress: "",
            potentialCustomerPersonId: undefined,
          }
        : undefined,
    contractorCorporation:
      screenItem.contractorAttribute === "corporation"
        ? {
            // 契約者 法人
            corporationName: screenItem.contractor.corporationName ?? "", // @ // 法人名
            postCode: screenItem.contractor.addressInformation?.postCode ?? "", // @
            address1: screenItem.contractor.addressInformation?.address1 ?? "", // @
            address2: screenItem.contractor.addressInformation?.address2 ?? "", // @
            address3: screenItem.contractor.addressInformation?.address3 ?? "", // @
            address4: screenItem.contractor.addressInformation?.address4 ?? "",
            address5:
              screenItem.contractor.addressInformation?.address5 ?? undefined,
            tel1: screenItem.contractor.tel1 ?? "", // @
            tel2: screenItem.contractor.tel2 ?? undefined,
            corporationRepresentativeFirstName:
              screenItem.contractor.corporationRepresentativeFirstName ?? "", //代表者
            corporationRepresentativeLastName:
              screenItem.contractor.corporationRepresentativeLastName ?? "", //代表者
            corporationClosingMonth:
              screenItem.contractor.corporationClosingMonth ?? undefined, //決算月
            mailAddress: undefined,
          }
        : undefined,
    insured: {
      //被保険者
      firstName: screenItem.insured.firstName,
      lastName: screenItem.insured.lastName, // @
      firstNameKana: screenItem.insured.firstNameKana, // @
      lastNameKana: screenItem.insured.lastNameKana, // @
      dateOfBirth: screenItem.insured.dateOfBirth,
    },
    insuranceInfo: {
      //被保険者
      insuranceCompanyId: screenItem.insuranceInfo.insuranceCompanyId, // @ //保険会社＠
      insuranceTypeId: screenItem.insuranceInfo.insuranceTypeId, // @ // 保険種類＠
      insuranceFee: screenItem.insuranceInfo.insuranceFee,
      paymentMethod: screenItem.insuranceInfo.paymentMethod,
      deemedAnnualPayment: screenItem.insuranceInfo.deemedAnnualPayment,
    },
    lifeInsuranceInfo: {
      contractDateOn: screenItem.insuranceInfo.contractDateOn ?? "",
    },
    nonLifeInsuranceInfo: {
      policyNumber: screenItem.insuranceInfo.policyNumber ?? "",
      applicationDateOn: screenItem.insuranceInfo.applicationDateOn ?? "",
      insuranceStartDateOn: screenItem.insuranceInfo.insuranceStartDateOn ?? "",
      insuranceEndDateOn: screenItem.insuranceInfo.insuranceEndDateOn ?? "",
      receiptDateOn: screenItem.insuranceInfo.receiptDateOn ?? undefined,
      returnDateOn: screenItem.insuranceInfo.returnDateOn ?? undefined,
      nonLifeInsuranceAccountingCategory: screenItem.insuranceInfo
        .nonLifeInsuranceAccountingCategory as NonLifeInsuranceAccountingCategory,
      nonLifeInsuranceDepositCategory: screenItem.insuranceInfo
        .nonLifeInsuranceDepositCategory as NonLifeInsuranceDepositCategory,
    },
    commissionShare: {
      //手数料
      // 取分合計が100%にならないとエラー
      companyShare: screenItem.commissionShare.companyShare, // 本社取分率
      myselfShare: screenItem.commissionShare.myselfShare, //担当募集人 取分率
      bossId: screenItem.commissionShare.bossId ?? undefined, // 所属長
      bossShare: screenItem.commissionShare.bossShare, // 所属長 取分率
      otherStaffId1: screenItem.commissionShare.otherStaffId1 ?? undefined, //その他募集人１
      otherStaffShare1: screenItem.commissionShare.otherStaffShare1, // 取分率
      otherStaffId2: screenItem.commissionShare.otherStaffId2 ?? undefined, //その他募集人2
      otherStaffShare2: screenItem.commissionShare.otherStaffShare2, //取分率
      otherStaffId3: screenItem.commissionShare.otherStaffId3 ?? undefined, // その他募集人3
      otherStaffShare3: screenItem.commissionShare.otherStaffShare3, // 取分率
      marketShare: screenItem.commissionShare.marketShare, //マーケット 取分率
    },
    incentiveShare: {
      // インセンティブ
      // 取分合計が100%にならないとエラー
      companyShare: screenItem.incentiveShare.companyShare, // 本社取分率
      myselfShare: screenItem.incentiveShare.myselfShare, //担当募集人 取分率
      bossId: screenItem.incentiveShare.bossId ?? undefined, // 所属長
      bossShare: screenItem.incentiveShare.bossShare, // 所属長 取分率
      otherStaffId1: screenItem.incentiveShare.otherStaffId1 ?? undefined, //その他募集人１
      otherStaffShare1: screenItem.incentiveShare.otherStaffShare1, // 取分率
      otherStaffId2: screenItem.incentiveShare.otherStaffId2 ?? undefined, //その他募集人2
      otherStaffShare2: screenItem.incentiveShare.otherStaffShare2, //取分率
      otherStaffId3: screenItem.incentiveShare.otherStaffId3 ?? undefined, // その他募集人3
      otherStaffShare3: screenItem.incentiveShare.otherStaffShare3, // 取分率
      marketShare: screenItem.incentiveShare.marketShare, //マーケット 取分率
    },
    staffId: screenItem.staffId ?? undefined, // @ // 担当募集人＠
  };

  return removeUndefinedAndEmptyStringFromObject(converted);
};

export const isContractReportScreenItem = (
  formData: Record<string, any>
): formData is ContractReportScreenItem => {
  // test if all of the required fields are present
  let ret = false;

  // if any of the required fields are missing, return false
  try {
    ret =
      formData.insuranceCategory &&
      formData.contractorAttribute &&
      (formData.contractor.lastName || formData.contractor.corporationName);
  } catch {
    return false;
  }
  return !!ret;
};

export const getCase = (
  potentialCustomer: GetContractReports200ResponseResultsInner
): Case => {
  if (!potentialCustomer.potentialCustomerInfo) {
    return "";
  }

  const potentialCustomerType =
    potentialCustomer.potentialCustomerInfo.potentialCustomerType;
  const supportUse = potentialCustomer.potentialCustomerInfo.supportUse;
  const marketName = potentialCustomer.potentialCustomerInfo.marketName;

  switch (potentialCustomerType) {
    case "myself":
      return "自己開拓";

    case "market":
      return supportUse ? `${marketName}：購入サポート` : `${marketName}：購入`;

    case "jointRecruitment":
      return supportUse ? `${marketName}：共募サポート` : `${marketName}：共募`;

    default:
      return "";
  }
};
