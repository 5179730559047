import type {
  GetCommissionCalculationResults200ResponseResultsInner,
  GetCommissionInsuranceCompanyCalculationResults200Response,
  GetCommissionStatus200ResponseResultsInner,
  GetCommissions200Response,
} from "@/api";

export const getCommissionCalculationResults: GetCommissionCalculationResults200ResponseResultsInner[] =
  [
    {
      commissionImport: {
        commissionImportId: 123456,
        insuranceCompanyId: 1234567,
        insuranceCompanyName: "アイティーソリューションズ生命",
        commissionCategory: "commission",
      },
      securities: {
        securitiesId: 1234567,
        policyNumber: "1234567",
        staffId: 1234567,
        insuranceCategory: "nonLifeInsurance",
        contractorAttribute: "individual",
        firstPaymentMonth: "202411",
        contractorIndividual: {
          firstName: "麻由美",
          lastName: "大澤",
          firstNameKana: "アユミ",
          lastNameKana: "オオサワ",
        },
        contractorCorporation: {
          corporationName: "アイティーソリューションズ株式会社",
        },
        insured: {
          firstName: "麻由美",
          lastName: "大澤",
          firstNameKana: "アユミ",
          lastNameKana: "オオサワ",
        },
        insuranceInfo: {
          insuranceTypeId: 1234567,
          insuranceTypeName: "個人ガン保険",
          insuranceFee: 10000,
          paymentMethod: "monthlyPayment",
        },
      },
      commission: {
        all: {
          commission: 100,
          consumptionTax: 10,
        },
        // This happens in the specific case
        myself: {
          staffId: null,
          staffNumber: null,
          lastName: null,
          firstName: null,
          share: "0",
          commission: null,
          consumptionTax: null,
        },
        boss: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100.5",
          commission: 100,
          consumptionTax: 10,
        },
        otherStaff1: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100",
          commission: 100,
          consumptionTax: 10,
        },
        otherStaff2: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100",
          commission: 100,
          consumptionTax: 10,
        },
        otherStaff3: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100",
          commission: 100,
          consumptionTax: 10,
        },
        market: {
          marketId: 1234567,
          marketName: "アイティーソリューションズ マーケット",
          share: "100",
          commission: 100,
          consumptionTax: 10,
        },
      },
    },
    {
      commissionImport: {
        commissionImportId: 123456,
        insuranceCompanyId: 1234567,
        insuranceCompanyName: "アイティーソリューションズ生命",
        commissionCategory: "commission",
      },
      securities: {
        securitiesId: 1234567,
        policyNumber: "1234568",
        staffId: 1234568,
        insuranceCategory: "nonLifeInsurance",
        contractorAttribute: "individual",
        firstPaymentMonth: "202411",
        contractorIndividual: {
          firstName: "Valerie",
          lastName: "Yamanaka",
          firstNameKana: "ヴァレリー",
          lastNameKana: "ヤマナカ",
        },
        contractorCorporation: {
          corporationName: "アイティーソリューションズ株式会社",
        },
        insured: {
          firstName: "Valerie",
          lastName: "Yamanaka",
          firstNameKana: "ヴァレリー",
          lastNameKana: "ヤマナカ",
        },
        insuranceInfo: {
          insuranceTypeId: 1234567,
          insuranceTypeName: "個人ガン保険",
          insuranceFee: 10000,
          paymentMethod: "monthlyPayment",
        },
      },
      commission: {
        all: {
          commission: 100,
          consumptionTax: 10,
        },
        myself: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100.55",
          commission: 100,
          consumptionTax: 10,
        },
        boss: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100.5",
          commission: 100,
          consumptionTax: 10,
        },
        otherStaff1: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100",
          commission: 100,
          consumptionTax: 10,
        },
        otherStaff2: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100",
          commission: 100,
          consumptionTax: 10,
        },
        otherStaff3: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100",
          commission: 100,
          consumptionTax: 10,
        },
        market: {
          marketId: 1234567,
          marketName: "アイティーソリューションズ マーケット",
          share: "100",
          commission: 100,
          consumptionTax: 10,
        },
      },
    },
    {
      commissionImport: {
        commissionImportId: 123456,
        insuranceCompanyId: 1234567,
        insuranceCompanyName: "アイティーソリューションズ生命",
        commissionCategory: "commission",
      },
      securities: {
        securitiesId: 1234567,
        policyNumber: "AA123456",
        staffId: 1234,
        insuranceCategory: "nonLifeInsurance",
        contractorAttribute: "individual",
        firstPaymentMonth: "202411",
        contractorIndividual: {
          firstName: "麻由美",
          lastName: "大澤",
          firstNameKana: "アユミ",
          lastNameKana: "オオサワ",
        },
        contractorCorporation: {
          corporationName: "アイティーソリューションズ株式会社",
        },
        insured: {
          firstName: "麻由美",
          lastName: "大澤",
          firstNameKana: "アユミ",
          lastNameKana: "オオサワ",
        },
        insuranceInfo: {
          insuranceTypeId: 1234567,
          insuranceTypeName: "個人ガン保険",
          insuranceFee: 10000,
          paymentMethod: "monthlyPayment",
        },
      },
      commission: {
        all: {
          commission: 1000,
          consumptionTax: 10,
        },
        myself: {
          staffId: 1234,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100.55",
          commission: 100,
          consumptionTax: 10,
        },
        boss: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100.5",
          commission: 100,
          consumptionTax: 10,
        },
        otherStaff1: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100",
          commission: 100,
          consumptionTax: 10,
        },
        otherStaff2: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100",
          commission: 100,
          consumptionTax: 10,
        },
        otherStaff3: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100",
          commission: 100,
          consumptionTax: 10,
        },
        market: {
          marketId: 1234567,
          marketName: "アイティーソリューションズ マーケット",
          share: "100",
          commission: 100,
          consumptionTax: 10,
        },
      },
    },
    {
      commissionImport: {
        commissionImportId: 123456,
        insuranceCompanyId: 1234567,
        insuranceCompanyName: "アイティーソリューションズ生命",
        commissionCategory: "commission",
      },
      securities: {
        securitiesId: 1234567,
        policyNumber: "AB692837",
        staffId: 123,
        insuranceCategory: "nonLifeInsurance",
        contractorAttribute: "individual",
        firstPaymentMonth: "202411",
        contractorIndividual: {
          firstName: "麻由美",
          lastName: "大澤",
          firstNameKana: "アユミ",
          lastNameKana: "オオサワ",
        },
        contractorCorporation: {
          corporationName: "アイティーソリューションズ株式会社",
        },
        insured: {
          firstName: "麻由美",
          lastName: "大澤",
          firstNameKana: "アユミ",
          lastNameKana: "オオサワ",
        },
        insuranceInfo: {
          insuranceTypeId: 1234567,
          insuranceTypeName: "個人ガン保険",
          insuranceFee: 10000,
          paymentMethod: "monthlyPayment",
        },
      },
      commission: {
        all: {
          commission: 99,
          consumptionTax: 10,
        },
        myself: {
          staffId: 123,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100.55",
          commission: 100,
          consumptionTax: 10,
        },
        boss: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100.5",
          commission: 100,
          consumptionTax: 10,
        },
        otherStaff1: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100",
          commission: 100,
          consumptionTax: 10,
        },
        otherStaff2: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100",
          commission: 100,
          consumptionTax: 10,
        },
        otherStaff3: {
          staffId: 1234567,
          staffNumber: "00123",
          lastName: "大澤",
          firstName: "麻由美",
          share: "100",
          commission: 100,
          consumptionTax: 10,
        },
        market: {
          marketId: 1234567,
          marketName: "アイティーソリューションズ マーケット",
          share: "100",
          commission: 100,
          consumptionTax: 10,
        },
      },
    },
  ];

export const commissionInsuranceCompanyCalculationResults: GetCommissionInsuranceCompanyCalculationResults200Response =
  {
    staff: {
      firstName: "麻由美",
      lastName: "大澤",
      staffId: 1234567,
      staffNumber: "00123",
      departmentName: "名古屋支店",
      departmentId: 1234567,
    },
    lifeInsuranceCommissions: [
      {
        insuranceCompanyId: 1234567,
        insuranceCompanyName: "アイティーソリューションズ生命",
        firstYearCommission: 10000,
        continuationCommission: 10000,
        incentiveCommission: 10000,
        firstYearConsumptionTax: 10000,
        continuationConsumptionTax: 10000,
        incentiveConsumptionTax: 10000,
      },
      {
        insuranceCompanyId: 9484675,
        insuranceCompanyName: "Life Insurance Company",
        firstYearCommission: 8000,
        continuationCommission: 8000,
        incentiveCommission: 8000,
        firstYearConsumptionTax: 8000,
        continuationConsumptionTax: 8000,
        incentiveConsumptionTax: 8000,
      },
    ],
    nonLifeInsuranceCommissions: [
      {
        insuranceCompanyId: 1234567,
        insuranceCompanyName: "アイティーソリューションズ生命",
        firstYearCommission: 10000,
        continuationCommission: 10000,
        incentiveCommission: 10000,
        firstYearConsumptionTax: 10000,
        continuationConsumptionTax: 10000,
        incentiveConsumptionTax: 10000,
      },
    ],
    totalCommission: {
      total: {
        firstYearCommission: 10000,
        continuationCommission: 10000,
        incentiveCommission: 10000,
        firstYearConsumptionTax: 10000,
        continuationConsumptionTax: 10000,
        incentiveConsumptionTax: 10000,
      },
      lifeInsuranceCommission: {
        firstYearCommission: 10000,
        continuationCommission: 10000,
        incentiveCommission: 10000,
        firstYearConsumptionTax: 10000,
        continuationConsumptionTax: 10000,
        incentiveConsumptionTax: 10000,
      },
      nonLifeInsuranceCommission: {
        firstYearCommission: 10000,
        continuationCommission: 10000,
        incentiveCommission: 10000,
        firstYearConsumptionTax: 10000,
        continuationConsumptionTax: 10000,
        incentiveConsumptionTax: 10000,
      },
    },
  };

export const getCommissionsData: (GetCommissions200Response & {
  commissionImportId: number;
})[] = [
  {
    commissionImportId: 1234567,
    insuranceCompanyInfo: {
      insuranceCompanyId: 1234567,
      insuranceCompanyName: "アイティーソリューションズ生命",
    },
    commissions: [
      {
        commissionId: 1234567,
        policyNumberFromCsv: "AA123456",
        contractor: {
          contractorNameFromCsv: "大澤　一誠",
          firstName: "麻由美",
          lastName: "大澤",
        },
        insured: {
          insuredNameFromCsv: "大澤　一誠",
          firstName: "麻由美",
          lastName: "大澤",
        },
        //staffCodeFromCsv is undefined so that you can check 募集人コードマッピング with this data.
        staff: {
          staffId: 1234567,
          staffNumber: "00123",
          staffNameFromCsv: "大澤　一誠",
          firstName: "麻由美",
          lastName: "大澤",
        },
        insuranceInfo: {
          commissionFromCsv: 100,
          insuranceFeeFromCsv: 1000,
          insuranceTypeFromCsv: "個人ガン保険",
          contractDateFromCsv: "2022年12月01日",
          contractDateOn: "2022-07-21",
          firstYearFlag: true,
        },
      },
      {
        commissionId: 1234568,
        policyNumberFromCsv: "TS1989",
        contractor: {
          contractorNameFromCsv: "Test Test",
          firstName: "Test",
          lastName: "Test",
        },
        insured: {
          insuredNameFromCsv: "Test Test",
          firstName: "Test",
          lastName: "Test",
        },
        staff: {
          staffId: undefined,
          staffNumber: "00123",
          staffNameFromCsv: undefined,
          staffCodeFromCsv: "123456",
          firstName: "Test",
          lastName: "Staff",
        },
        insuranceInfo: {
          commissionFromCsv: 100,
          insuranceFeeFromCsv: 200,
          insuranceTypeFromCsv: "個人ガン保険",
          contractDateFromCsv: "2022年12月01日",
          contractDateOn: "2022-07-21",
          firstYearFlag: true,
        },
      },
      {
        commissionId: 2234563,
        policyNumberFromCsv: "BBB847503",
      },
      {
        commissionId: 3234567,
        policyNumberFromCsv: "CC0124234",
        contractor: {
          contractorNameFromCsv: "Test3 Test",
          firstName: "麻由美",
          lastName: "大澤",
        },
        insured: {
          insuredNameFromCsv: "Test3 Test",
          firstName: "麻由美",
          lastName: "大澤",
        },
        staff: {
          staffId: 1234567,
          staffNumber: "00123",
          staffNameFromCsv: "Test3 Test",
          staffCodeFromCsv: "123456",
          firstName: "麻由美",
          lastName: "大澤",
        },
        insuranceInfo: {
          commissionFromCsv: 100,
          insuranceFeeFromCsv: 456,
          insuranceTypeFromCsv: "個人ガン保険",
          contractDateFromCsv: "2022年12月01日",
          contractDateOn: "2022-07-21",
          firstYearFlag: false,
        },
      },
      {
        commissionId: 5234567,
        policyNumberFromCsv: "AA123456",
        contractor: {
          contractorNameFromCsv: "大澤　一誠",
          firstName: "麻由美",
          lastName: "大澤",
        },
        insured: {
          insuredNameFromCsv: "大澤　一誠",
          firstName: "麻由美",
          lastName: "大澤",
        },
        staff: {
          staffId: 1234567,
          staffNumber: "00123",
          staffNameFromCsv: "大澤　一誠",
          staffCodeFromCsv: "123456",
          firstName: "麻由美",
          lastName: "大澤",
        },
        insuranceInfo: {
          commissionFromCsv: 100,
          insuranceFeeFromCsv: 1000,
          insuranceTypeFromCsv: "個人ガン保険",
          contractDateFromCsv: "2022年12月01日",
          contractDateOn: "2022-07-21",
          firstYearFlag: true,
        },
      },
      {
        commissionId: 6234567,
        policyNumberFromCsv: "AA123456",
        contractor: {
          contractorNameFromCsv: "大澤　一誠",
          firstName: "麻由美",
          lastName: "大澤",
        },
        insured: {
          insuredNameFromCsv: "大澤　一誠",
          firstName: "麻由美",
          lastName: "大澤",
        },
        staff: {
          staffId: 1234567,
          staffNumber: "00123",
          staffNameFromCsv: "大澤　一誠",
          staffCodeFromCsv: "123456",
          firstName: "麻由美",
          lastName: "大澤",
        },
        insuranceInfo: {
          commissionFromCsv: 100,
          insuranceFeeFromCsv: 1000,
          insuranceTypeFromCsv: "個人ガン保険",
          contractDateFromCsv: "2022年12月01日",
          contractDateOn: "2022-07-21",
          firstYearFlag: true,
        },
      },
      {
        commissionId: 7234567,
        policyNumberFromCsv: "AA123456",
        contractor: {
          contractorNameFromCsv: "大澤　一誠",
          firstName: "麻由美",
          lastName: "大澤",
        },
        insured: {
          insuredNameFromCsv: "大澤　一誠",
          firstName: "麻由美",
          lastName: "大澤",
        },
        staff: {
          staffId: 1234567,
          staffNumber: "00123",
          staffNameFromCsv: "大澤　一誠",
          staffCodeFromCsv: "123456",
          firstName: "麻由美",
          lastName: "大澤",
        },
        insuranceInfo: {
          commissionFromCsv: 100,
          insuranceFeeFromCsv: 1000,
          insuranceTypeFromCsv: "個人ガン保険",
          contractDateFromCsv: "2022年12月01日",
          contractDateOn: "2022-07-21",
          firstYearFlag: true,
        },
      },
    ],
  },
  {
    commissionImportId: 12345678,
    insuranceCompanyInfo: {
      insuranceCompanyId: 18,
      insuranceCompanyName: "TEST損保(損保はStaffが未設定がおおい)",
    },
    commissions: [
      {
        commissionId: 1234567,
        policyNumberFromCsv: "VS1987",
        contractor: {
          contractorNameFromCsv: "ABC",
          firstName: "ABC",
          lastName: "DEF",
        },
        insured: {
          insuredNameFromCsv: "ABC DEF",
          firstName: "abc",
          lastName: "def",
        },
        staff: undefined,
        insuranceInfo: {
          commissionFromCsv: 100,
          insuranceFeeFromCsv: 1000,
          insuranceTypeFromCsv: "個人ガン保険",
          contractDateFromCsv: "2022年12月01日",
          contractDateOn: "2022-07-21",
          firstYearFlag: true,
        },
      },
      {
        commissionId: 1234568,
        policyNumberFromCsv: "FFFGFF8989",
        contractor: {
          contractorNameFromCsv: "Test Test",
          firstName: "Test",
          lastName: "Test",
        },
        insured: {
          insuredNameFromCsv: "Test Test",
          firstName: "Test",
          lastName: "Test",
        },
        staff: undefined,
        insuranceInfo: {
          commissionFromCsv: 100,
          insuranceFeeFromCsv: 1000,
          insuranceTypeFromCsv: "個人ガン保険",
          contractDateFromCsv: "2022年12月01日",
          contractDateOn: "2022-07-21",
          firstYearFlag: true,
        },
      },
    ],
  },
];

export const commissionStatusData: GetCommissionStatus200ResponseResultsInner[] =
  [
    {
      commissionImportId: 1234567,
      insuranceCompanyId: 1234567,
      insuranceCompanyName: "保険会社名A",
      insuranceCategory: "lifeInsurance",
      commissionCategory: "commission",
      commissionRecordCount: 100,
      commissionMatchingCount: 100,
      commissionTotalAmount: 1,
      commissionImportAt: "2023-08-01",
      commissionImportStatus: "commissionCalculated",
      commissionImportCount: 1,
      consumptionTaxIncludeFlag: true,
    },
    {
      commissionImportId: 12345678,
      insuranceCompanyId: 2222222,
      insuranceCompanyName: "保険会社名B",
      insuranceCategory: "nonLifeInsurance",
      commissionCategory: "incentive",
      commissionRecordCount: 15,
      commissionMatchingCount: 1,
      commissionTotalAmount: 1,
      commissionImportAt: "2023-08-01",
      commissionImportStatus: "commissionNotCalculated",
      commissionImportCount: 1,
      consumptionTaxIncludeFlag: true,
    },
  ];
