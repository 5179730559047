import { Configuration } from "@/api";

/**
 * @description
 * This function returns the configuration object for axios.
 * @returns Configuration
 */
function getDevConfig() {
  let config;
  if (
    import.meta.env.VITE_ENV === "development" ||
    import.meta.env.VITE_ENV === "staging"
  ) {
    config = new Configuration({
      basePath: "https://api.doit-core.link/api",
    });
  }

  if (import.meta.env.VITE_ENV === "test") {
    config = new Configuration({
      basePath: "http://10.0.0.2:3000",
    });
  }

  // for production
  if (import.meta.env.VITE_ENV === "production") {
    config = new Configuration({
      basePath: "https://api.doit-core.com/api",
    });
  }

  return config;
}

/**
 * @description
 * This function retuns the base url for the MSW server usecase.
 * @param mode
 * @returns string
 */
export function getBaseUrl(mode = "development") {
  let baseUrl;
  if (mode === "development") {
    baseUrl = "https://api.doit-core.link/api";
  }

  if (mode === "staging") {
    baseUrl = "https://api.doit-core.link/api";
  }

  // if VITE_ENV equals to `build` , then `basePath` should be `http://10.0.0.2:3000`
  if (mode === "test") {
    baseUrl = "http://10.0.0.2:3000";
  }

  // for production
  if (mode === "production") {
    baseUrl = "https://api.doit-core.com/api";
  }

  return baseUrl;
}

const mode = import.meta.env.MODE;

export function useEnvironmentVars() {
  const configObject = getDevConfig();
  return {
    configObject,
    mode,
    getBaseUrl,
  };
}
