<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";
import AutocompleteInput from "./AutocompleteInput.vue";
import ResultsList from "./ResultsList.vue";
import { useAutocompleteLogic } from "@/composables/use-autocomplete-staff-api";
import { type FormKitFrameworkContext } from "@formkit/core";

const props = defineProps<{ context: FormKitFrameworkContext }>();
const inputValue = ref("");
const {
  currentEmployee,
  getStaffData,
  searchResults,
  selectEmployee,
  error,
  clearCurrentEmployeeAndTheirId,
  loadStaffDataById,
  clearOnlyCurrentEmployee,
} = useAutocompleteLogic();

watchEffect(() => {
  // Reset the currentEmployee entered just before every time the context.staff.Id changes
  clearOnlyCurrentEmployee();

  // Ensure props.context.staffId is not null and its type is number
  if (
    typeof props.context.staffId === "number" &&
    !isNaN(props.context.staffId)
  ) {
    // Update the currentEmployee with the staff data fetched by the staffId
    loadStaffDataById(props.context.staffId, props.context.node);
  } else {
    inputValue.value = "";
  }
});

onMounted(() => {
  if (
    typeof props.context.staffId === "number" &&
    !isNaN(props.context.staffId)
  ) {
    loadStaffDataById(props.context.staffId, props.context.node);
  }
});
</script>

<template>
  <div class="autocomplete-area">
    <autocomplete-input
      v-model="inputValue"
      :get-staff-data="getStaffData"
      :is-editable="(props.context.isEditable as boolean)"
      :current-employee="currentEmployee"
      @clear-current-employee="
        () => clearCurrentEmployeeAndTheirId(props.context.node)
      "
    />
    <results-list
      :search-results="searchResults"
      @select-employee="(staff) => selectEmployee(staff, context.node)"
      :error="error"
    />
  </div>
</template>

<style scoped lang="scss">
.autocomplete-area {
  position: relative;
  z-index: 10;
}
</style>
