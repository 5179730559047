import { PerformanceBasedPayQueryApiFactory } from "@/api";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import axiosInstance from "@/utils/axios-customized-instance";
import { safeAsyncCall, safeAsyncCallAndRaiseError } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const {
  getPerformanceBasedPayCheck,
  putPerformanceBasedPayCalculation,
  getPerformanceBasedPayPayrollCsv,
  getPerformanceBasedPayResults,
} = PerformanceBasedPayQueryApiFactory(...args, undefined, axiosInstance);

export default function usePerformanceBasedPayQueryService() {
  return {
    // GetPerformanceBasedPayCheck API
    preCalculationCheck: safeAsyncCall(
      async (...args: Parameters<typeof getPerformanceBasedPayCheck>) => {
        const res = await getPerformanceBasedPayCheck(...args);
        return res;
      }
    ),

    // PutPerformanceBasedPayCalculation API
    calculate: safeAsyncCall(
      async (...args: Parameters<typeof putPerformanceBasedPayCalculation>) => {
        const res = await putPerformanceBasedPayCalculation(...args);
        return res;
      }
    ),

    // GetPerformanceBasedPayPayrollCsv API
    exportBy: safeAsyncCallAndRaiseError(
      async (...args: Parameters<typeof getPerformanceBasedPayPayrollCsv>) => {
        const res = await getPerformanceBasedPayPayrollCsv(...args);
        return res;
      }
    ),
    // GetPerformanceBasedPayPayrollCsv API
    findBy: safeAsyncCall(
      async (...args: Parameters<typeof getPerformanceBasedPayResults>) => {
        const res = await getPerformanceBasedPayResults(...args);
        return res.data;
      }
    ),
  };
}
