import { rest } from "msw";
import {
  dummyGetStaffs200Response as getStaffs,
  baseDummyData,
} from "../Staff/dummy-get-staff";

import { useEnvironmentVars } from "@/composables/use-environment-vars";
import type {
  GetMeStaffCurrent200Response,
  GetStaffCurrent200Response,
  StaffRoleType,
} from "@/api";
import { baseDepartments } from "../Department/dummy-departs";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

/**
 * Matched method by the order in which they were defined in handlers.
 */
export const handlers = [
  /**
   * StaffQuery
   * GetStaffs
   */
  rest.get(`${BASE_PATH}/staff`, (req, res, ctx) => {
    const keyword = req.url.searchParams.get("keyword");
    const accountActiveFlagString =
      req.url.searchParams.get("accountActiveFlag");

    let filteredStaffs = getStaffs();
    // if there is a keyword, filters by it.
    if (keyword) {
      filteredStaffs = filteredStaffs.filter((staff) =>
        `${staff.lastName.toLowerCase()}${staff.firstName.toLowerCase()}`.includes(
          keyword.toLowerCase()
        )
      );
    }

    // if there is an accountActiveFlag, filters by it.
    if (accountActiveFlagString !== null) {
      const convertedActiveFlag =
        accountActiveFlagString === "true"
          ? true
          : accountActiveFlagString === "false"
          ? false
          : undefined;
      if (accountActiveFlagString !== undefined) {
        filteredStaffs = filteredStaffs.filter(
          (staff) => staff.accountActiveFlag === convertedActiveFlag
        );
      }
    }

    return res(
      ctx.status(200),
      ctx.json({
        results: filteredStaffs,
        pagination: {
          totalCount: filteredStaffs.length,
          currentPage: 1,
          lastPage: 1,
        },
      })
    );
  }),

  /**
   * StaffQuery.
   * GetMeStaffCurrent.
   */
  rest.get(`${BASE_PATH}/staff/me/current`, async (req, res, ctx) => {
    // In this mock, we are using a fixed staffId for the testing purpose.
    const staff = baseDummyData.find((staff) => staff.staffId === 7777);

    let resultWithDepartment: GetMeStaffCurrent200Response;

    if (staff) {
      const departmentInfo = baseDepartments.find(
        (department) =>
          department.departmentId ===
          Number(staff.departmentAffiliation[0].departmentId)
      );

      const bossInfo = baseDummyData.find(
        (staff) => staff.staffId === Number(departmentInfo.boss[0].staffId)
      );

      resultWithDepartment = {
        staffId: staff.staffId,
        staffNumber: staff.staffNumber,
        staffRoleType: staff.staffRoleType as StaffRoleType,
        personal: {
          firstName: staff.personal.firstName,
          lastName: staff.personal.lastName,
          firstNameKana: staff.personal.firstNameKana,
          lastNameKana: staff.personal.lastNameKana,
        },
        departmentAffiliation: {
          departmentId: staff.departmentAffiliation[0].departmentId,
          departmentName: departmentInfo.departmentName,
          boss: {
            staffId: departmentInfo.boss[0].staffId,
            staffNumber: bossInfo.staffNumber,
            firstName: bossInfo.personal.firstName,
            lastName: bossInfo.personal.lastName,
          },
        },
      };

      return res(ctx.status(200), ctx.json(resultWithDepartment));
    } else if (!staff) {
      return res(
        ctx.status(404),
        ctx.json({
          message: "staff not found",
        })
      );
    }
  }),

  /**
   * StaffQuery
   * GetStaffCurrent
   */
  rest.get(`${BASE_PATH}/staff/:staffId/current`, async (req, res, ctx) => {
    const id = req.params.staffId;
    const staff = baseDummyData.find((staff) => staff.staffId === Number(id));

    let resultWithDepartment: GetStaffCurrent200Response;

    if (staff) {
      const departmentInfo = baseDepartments.find(
        (department) =>
          department.departmentId ===
          Number(staff.departmentAffiliation[0].departmentId)
      );

      const bossInfo = baseDummyData.find(
        (staff) => staff.staffId === Number(departmentInfo.boss[0].staffId)
      );

      resultWithDepartment = {
        staffId: staff.staffId,
        staffNumber: staff.staffNumber,
        personal: {
          firstName: staff.personal.firstName,
          lastName: staff.personal.lastName,
          firstNameKana: staff.personal.firstNameKana,
          lastNameKana: staff.personal.lastNameKana,
        },
        departmentAffiliation: {
          departmentId: staff.departmentAffiliation[0].departmentId,
          departmentName: departmentInfo.departmentName,
          boss: {
            staffId: departmentInfo.boss[0].staffId,
            staffNumber: bossInfo.staffNumber,
            firstName: bossInfo.personal.firstName,
            lastName: bossInfo.personal.lastName,
          },
        },
        commissionShareDefault: staff.commissionShareDefault,
        incentiveShareDefault: staff.incentiveShareDefault,
      };

      return res(ctx.status(200), ctx.json(resultWithDepartment));
    } else if (!staff) {
      return res(
        ctx.status(404),
        ctx.json({
          message: "staff not found",
        })
      );
    }
  }),

  /**
   * StaffQuery
   * PostStaffStandardMonthlyRemunerationCsv
   */
  rest.post(
    `${BASE_PATH}/staff/standardMonthlyRemuneration/csv`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          importCount: 1234,
        })
      );
    }
  ),
];
