import type { PotentialCustomerIndividualScreenItem } from "@/types/PotentialCustomer/potential-customer-individual";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useMarketInfoStore = defineStore("market-info", () => {
  // initialize state
  const currentItem = ref<PotentialCustomerIndividualScreenItem["market"]>({
    marketId: undefined,
    marketCustomerId: undefined,
    supportUse: undefined,
  });

  return {
    currentItem,
  };
});
