<script setup lang="ts">
import DownloadButton from "@/components/sharable/DownloadButton.vue";
import useAdjustmentDeductionQueryService from "@/services/use-adjustment-deduction-query-service";

const { exportBy } = useAdjustmentDeductionQueryService();

const props = defineProps<{
  staffId?: number;
  adjustmentDeductionTypeId?: number;
  // adjustmentDeductionTerm's type is string and the number of characters is 6.
  adjustmentDeductionTerm?: string;
}>();
</script>

<template>
  <!-- CSV export button gimic using v-btn -->
  <download-button
    :export-action="exportBy"
    :export-args="[
      props.staffId,
      props.adjustmentDeductionTypeId,
      props.adjustmentDeductionTerm,
    ]"
    file-name-suffix="調整控除額一覧"
    button-id="download-btn"
  >
    <v-btn id="download-btn" size="large" color="primary"
      >CSVエクスポート</v-btn
    >
  </download-button>
</template>
