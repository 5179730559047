import { rest } from "msw";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import { dummyData } from "../StaffMap/dummy-data";
import type { GetStaffMaps200ResponseResultsInner } from "@/api";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

export const handlers = [
  /**
   * StaffMapQuery
   * GetStaffMaps
   */
  rest.get(`${BASE_PATH}/staffMap`, async (req, res, ctx) => {
    const insuranceCompanyId = Number(
      req.url.searchParams.get("insuranceCompanyId")
    );
    const staffId = Number(req.url.searchParams.get("staffId"));

    let filteredResults: GetStaffMaps200ResponseResultsInner[] = [];

    if (insuranceCompanyId && staffId) {
      filteredResults = dummyData.filter(
        (record) =>
          record.insuranceCompany.insuranceCompanyId === insuranceCompanyId &&
          record.staff.staffId === staffId
      );
    } else if (insuranceCompanyId) {
      filteredResults = dummyData.filter(
        (record) =>
          record.insuranceCompany.insuranceCompanyId === insuranceCompanyId
      );
    } else if (staffId) {
      filteredResults = dummyData.filter(
        (record) => record.staff.staffId === staffId
      );
    } else {
      filteredResults = dummyData;
    }

    return res(
      ctx.status(200),
      ctx.json({
        results: filteredResults,
        pagination: {
          totalCount: filteredResults.length,
          currentPage: 1,
          lastPage: 1,
        },
      })
    );
  }),
];
