<script setup lang="ts">
import _ from "lodash-es";
import { ref } from "vue";
import type { AdjustmentDeductionScreenItem } from "@/types/AdjustmentDeductionManagement/adjustments-deductions";
import useAdjustmentDeductionService from "@/services/use-adjustment-deduction-service";
import { useAdjustmentDeductionStore } from "@/stores/AdjustmentDeductionManagement/AdjustmentsDeductions";
import { useRouter } from "vue-router";

type Props = {
  results?: AdjustmentDeductionScreenItem[];
  selectedStaff?: string | number;
};

const props = defineProps<Props>();

const { delete: deleteAdjustment } = useAdjustmentDeductionService();
const store = useAdjustmentDeductionStore();

// getting today's date to check edited TO/FROm against.

const date: Date = new Date();
const year = date.getFullYear();
const month = date.getMonth() + 1;
let newMonth = month.toString();
if (newMonth.length === 1) {
  newMonth = newMonth.padStart(2, "0");
}
const newYear = year.toString();
const todaysDate = ref();
todaysDate.value = newYear + newMonth;

const metadataOfAdjustmentsDeductions = {
  headers: () => [
    "控除項目名",
    "控除項目額",
    "備考",
    "支給開始月",
    "支給終了月",
  ],
  keys: () => [
    "adjustmentDeductionTypeName",
    "adjustmentDeductionFee",
    "adjustmentDeductionMemo",
    "adjustmentDeductionTermFrom",
    "adjustmentDeductionTermTo",
  ],
  headersAndKeys: () => {
    return _.zipObject(
      metadataOfAdjustmentsDeductions.keys(),
      metadataOfAdjustmentsDeductions.headers()
    );
  },
};
const router = useRouter();

const dialog = ref<boolean>(false);

// get correct staffId and deductionId for delete.
// When using v-card, any inner logic will only get the last
// value in the for-loop, so this catches the value before that and sets it
// to these external values.

const temporaryStaffId = ref();
const temporaryDeductionId = ref();
function setTemporaryStaffValues(
  staffId: number,
  adjustmentDeductionId: number
) {
  temporaryStaffId.value = staffId;
  temporaryDeductionId.value = adjustmentDeductionId;
}

// route to that item's edit page
function openAdjustmentDeductionDetail(
  staffId: number,
  adjustmentDeductionId: number
) {
  const adjustmentDeductionRoute = router.resolve({
    name: "EditAdjustmentDeduction",
    params: {
      staffId: String(staffId),
      adjustmentDeductionId: String(adjustmentDeductionId),
    },
  });
  window.open(adjustmentDeductionRoute.href, "_blank");
}

// delete this adjustment/deduction
function deleteItem(adjustmentDeductionId: number) {
  deleteAdjustment(adjustmentDeductionId);
  dialog.value = false;
  store.clearDeductions();
}
</script>

<template>
  <v-table density="compact" fixed-header class="text-no-wrap" height="30vh">
    <thead v-if="results && results.length > 0">
      <tr>
        <th></th>
        <th>担当募集人</th>
        <th>控除項目名</th>
        <th>控除項目額</th>
        <th>備考</th>
        <th>支給開始月</th>
        <th>支給終了月</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in results" :key="index">
        <td>
          <v-icon
            icon="mdi-open-in-new"
            data-testid="open-button"
            @click="
              openAdjustmentDeductionDetail(
                Number(item.staffId),
                item.adjustmentDeductionId
              )
            "
          ></v-icon>
        </td>
        <td>{{ item?.staffNumber }}: {{ item?.staffName }}</td>
        <td>
          {{ item?.adjustmentDeductionTypeName }}
        </td>
        <td>
          {{ item?.adjustmentDeductionFeeDefaultPlus === "true" ? "+" : "-" }}
          {{ item?.adjustmentDeductionFee }}
        </td>
        <td>
          {{ item?.adjustmentDeductionName }}
        </td>
        <td>
          {{ item?.adjustmentDeductionTermFrom }}
        </td>
        <td>
          {{ item?.adjustmentDeductionTermTo }}
        </td>
        <td>
          <v-btn
            class="ma-2"
            @click="
              setTemporaryStaffValues(
                Number(selectedStaff),
                item.adjustmentDeductionId
              )
            "
            :disabled="item.adjustmentDeductionTermFrom < todaysDate"
          >
            <v-icon start icon="mdi-trash-can-outline"></v-icon>
            削除

            <v-dialog
              v-model="dialog"
              transition="fade-transition"
              activator="parent"
            >
              <v-card>
                <v-card-text class="text-center">
                  削除します。よろしいですか？
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    block
                    @click="deleteItem(temporaryDeductionId)"
                    >OK</v-btn
                  >
                </v-card-actions>
                <v-card-actions>
                  <v-btn color="info" block @click="dialog = false"
                    >キャンセル</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-btn>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<style scoped>
.v-table tr:nth-child(even) {
  background-color: #fcfcfc;
}
.v-table tr:hover {
  background-color: #f2f2f2;
}
</style>
