import type { SecuritiesScreenItem } from "@/types/SecuritiesManagement/securities-management";
import { defineStore } from "pinia";
import { ref } from "vue";

// definition:
//
// insuranceInfo?: {
//     policyNumber: string; // 証券番号＠
//     insuranceCompanyId: number; //保険会社＠
//     insuranceTypeId: number; // 保険種類＠
//     insuranceFee: number; //保険料＠
//     paymentMethod?: PaymentMethod | undefined; //支払方法
//     deemedAnnualPayment?: boolean | undefined; //みなし年払い
//     contractDateOn?: string; // 契約日
//     applicationDateOn?: string | undefined; //申込日
//     insuranceStartDateOn?: string | undefined; //保険始期
//     insuranceEndDateOn?: string | undefined; //保険終期
//     receiptDateOn?: string | undefined; //領収日
//     returnDateOn?: string | undefined; //返戻日
//     nonLifeInsuranceAccountingCategory?:
//       | NonLifeInsuranceAccountingCategory
//       | undefined; // 計上区分
//     nonLifeInsuranceDepositCategory?:
//       | NonLifeInsuranceDepositCategory
//       | undefined; // 入金区分
//   };

/**
 * Defining the info
 */
type InsuranceInfoBase = SecuritiesScreenItem["insuranceInfo"];

export type InsuranceInfo = InsuranceInfoBase;

export const useInsuranceInfoStore = defineStore("insurance-info", () => {
  // initialize state
  const currentItem = ref<InsuranceInfo>({
    policyNumber: "",
    insuranceCompanyId: undefined,
    insuranceTypeId: undefined,
    insuranceFee: 0,
    paymentMethod: undefined,
    deemedAnnualPayment: false,
    contractDateOn: "",
    applicationDateOn: "",
    insuranceStartDateOn: "",
    insuranceEndDateOn: "",
    receiptDateOn: "",
    returnDateOn: "",
    nonLifeInsuranceAccountingCategory: undefined,
    nonLifeInsuranceDepositCategory: undefined,
  });

  return {
    currentItem,
  };
});
