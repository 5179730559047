import { rest } from "msw";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import { dummyInsuranceCompanies, replace, addNewData } from "./dummy-data";
import type { PostInsuranceCompanyRequest } from "@/api";
import randomInteger from "random-int";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

export const handlers = [
  /**
   * InsuranceCompany
   * GetInsuranceCompany
   */

  rest.get(
    `${BASE_PATH}/insuranceCompany/:insuranceCompanyId`,
    (req, res, ctx) => {
      const id = Number(req.params.insuranceCompanyId);
      const company = dummyInsuranceCompanies.find(
        (company) => company.insuranceCompanyId === Number(id)
      );

      return res(ctx.status(200), ctx.json(company));
    }
  ),

  /**
   * PostInsuranceCompany
   * return insuranceCompanyId.
   */
  rest.post(`${BASE_PATH}/insuranceCompany`, async (req, res, ctx) => {
    const request = await req.json();
    const newInsuranceCompany = await convert(request);

    addNewData(newInsuranceCompany);
    console.log("new insurance company: ", newInsuranceCompany);

    return res(
      ctx.status(201),
      ctx.text(String(newInsuranceCompany.insuranceCompanyId))
    );
  }),
  /**
   * PutInsuranceCompany
   */
  rest.put(
    `${BASE_PATH}/insuranceCompany/:insuranceCompanyId`,
    async (req, res, ctx) => {
      const request = await req.json();
      const insuranceCompanyId = Number(req.params.insuranceCompanyId);

      const newRecord = await convert(request, insuranceCompanyId);

      // finds a record with the matching ID
      const oldRecord = dummyInsuranceCompanies.find(
        (record) => record.insuranceCompanyId === newRecord.insuranceCompanyId
      );
      // replaces the old data with the new
      replace(oldRecord, newRecord);

      return res(ctx.status(204));
    }
  ),
];

/**
 * Convert json shape from PostAdjustmentDeductionRequest to GetAdjustmentDeductions200Response
 * @param params PostAdjustmentDeductionRequest
 * @returns GetAdjustmentDeductions200Response
 */
export const convert = async (
  params: PostInsuranceCompanyRequest,
  insuranceCompanyId?: number
) => {
  return {
    insuranceCompanyId: insuranceCompanyId
      ? Number(insuranceCompanyId)
      : randomInteger(1000000, 9999999),
    insuranceCompanyName: params.insuranceCompanyName,
    consumptionTaxIncludeFlag: params.consumptionTaxIncludeFlag,
    insuranceCategory: params.insuranceCategory,
    sortNumber: params.sortNumber,
  };
};
