import { CommissionApiFactory } from "@/api";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import { deepCopyWith } from "@/utils/axios-customized-instance";
import { safeAsyncCall, safeAsyncCallAndRaiseError } from "@/utils/helper";

const args = [];
// Inject configuration for each stage(development, staging, production)
const { configObject } = useEnvironmentVars();
args.push(configObject);

const axiosInstanceCopy = deepCopyWith({ timeout: 3600000 });

const { deleteCommissionImport, postCommissionCsv } = CommissionApiFactory(
  ...args,
  undefined,
  axiosInstanceCopy
);

export default function useCommissionQueryService() {
  return {
    delete: safeAsyncCall(
      async (...args: Parameters<typeof deleteCommissionImport>) => {
        const res = await deleteCommissionImport(...args);
        return res.data;
      }
    ),

    importFile: safeAsyncCallAndRaiseError(
      async (...args: Parameters<typeof postCommissionCsv>) => {
        const res = await postCommissionCsv(...args);
        const { data } = res;

        return data;
      },
      // Pass the error handler to raise the error to the caller
      (error) => {
        throw error;
      }
    ),
  };
}
