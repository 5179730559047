import type { GetSecuritiesList200ResponseResultsInner } from "@/api";
import _ from "lodash";
import { dummyInsuranceCompanyTypes } from "@/mocks/InsuranceType/dummy-data";
import { dummyInsuranceCompanies } from "@/mocks/InsuranceCompany/dummy-data";

export function replace(oldSecurity: any, updatedSecurity: any) {
  dummySecurities = _.concat(
    _.filter(dummySecurities, (security) => security !== oldSecurity),
    updatedSecurity
  );
}

// existing securities:
// individual: 2, corporation: 1
// lifeInsurance: 1, nonLifeInsurance: 2

export let dummySecurities = [
  {
    securitiesId: 1234567,
    insuranceCategory: "nonLifeInsurance",
    contractorAttribute: "individual",
    contractReportId: 1234567,
    potentialCustomerInfo: {
      potentialCustomerId: 1234567,
      potentialCustomerType: "myself",
      supportUse: true,
      marketId: 1234567,
    },
    contractorIndividual: {
      firstName: "麻由美",
      lastName: "大澤",
      firstNameKana: "アユミ",
      lastNameKana: "オオサワ",
      dateOfBirth: "2022-07-21",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress: "system@doitplanning.com",
      potentialCustomerPersonId: 1234567,
    },

    insured: {
      firstName: "麻由美",
      lastName: "大澤",
      firstNameKana: "アユミ",
      lastNameKana: "オオサワ",
      dateOfBirth: "2022-07-21",
      potentialCustomerPersonId: 1234567,
    },

    insuranceInfo: {
      policyNumber: "AA123456",
      insuranceCompanyId: 1234567,
      insuranceCompanyName: "アイティーソリューションズ生命",
      insuranceTypeId: 1234567,
      insuranceFee: 10000,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    lifeInsuranceInfo: {
      contractDateOn: "2022-07-21",
    },
    nonLifeInsuranceInfo: {
      applicationDateOn: "2022-07-21",
      insuranceStartDateOn: "2022-07-21",
      insuranceEndDateOn: "2022-07-21",
      receiptDateOn: "2022-07-21",
      returnDateOn: "2022-07-21",
      nonLifeInsuranceAccountingCategory: "new",
      nonLifeInsuranceDepositCategory: "directDebit",
    },
    commissionShare: {
      companyShare: "15",
      myselfShare: "60",
      bossId: 7777,
      bossShare: "5",
      otherStaffId1: 1234567,
      otherStaffNumber1: "1234567",
      otherStaffShare1: "5",
      otherStaffId2: 1234567,
      otherStaffShare2: "5",
      otherStaffId3: 1234567,
      otherStaffShare3: "5",
      marketId: 1234567,
      marketShare: "5",
    },
    incentiveShare: {
      companyShare: "15",
      myselfShare: "60",
      bossId: 1234567,
      bossShare: "5",
      otherStaffId1: 1234567,
      otherStaffShare1: "5",
      otherStaffId2: 1234567,
      otherStaffShare2: "5",
      otherStaffId3: 1234567,
      otherStaffShare3: "5",
      marketId: 1234567,
      marketShare: "5",
    },
    staffId: 7777,
    staff: {
      firstName: "Valerie",
      lastName: "Yamanaka",
      staffId: 7777,
      staffNumber: "7777",
      departmentName: "本店",
      departmentId: 1,
    },
  },
  ////////
  {
    securitiesId: 12345688,
    insuranceCategory: "lifeInsurance",
    contractorAttribute: "individual",
    contractReportId: 12345688,
    potentialCustomerInfo: {
      potentialCustomerId: 1234567,
      potentialCustomerType: "market",
      supportUse: false,
      marketId: 1234567,
    },
    contractorIndividual: {
      firstName: "ナナ",
      lastName: "大崎 ",
      firstNameKana: "ナナ",
      lastNameKana: "オオサキ",
      dateOfBirth: "2022-07-21",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "7-7-7",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362744856",
      tel2: "0362744856",
      mailAddress: "system@doitplanning.com",
      potentialCustomerPersonId: 1234567,
    },
    insured: {
      firstName: "麻由美",
      lastName: "大澤",
      firstNameKana: "アユミ",
      lastNameKana: "オオサワ",
      dateOfBirth: "2022-07-21",
      potentialCustomerPersonId: 1234567,
    },

    insuranceInfo: {
      policyNumber: "AA123456",
      insuranceCompanyId: 2222222,
      insuranceCompanyName: "第一生命",
      insuranceTypeId: 1234567,
      insuranceFee: 10000,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    lifeInsuranceInfo: {
      contractDateOn: "2022-07-21",
    },
    nonLifeInsuranceInfo: {
      applicationDateOn: "2022-07-21",
      insuranceStartDateOn: "2022-07-21",
      insuranceEndDateOn: "2022-07-21",
      receiptDateOn: "2022-07-21",
      returnDateOn: "2022-07-21",
      nonLifeInsuranceAccountingCategory: "new",
      nonLifeInsuranceDepositCategory: "directDebit",
    },
    commissionShare: {
      companyShare: "15",
      myselfShare: "60",
      bossId: 1234567,
      bossShare: "5",
      otherStaffId1: 1234567,
      otherStaffNumber1: "1234567",
      otherStaffShare1: "5",
      otherStaffId2: 1234567,
      otherStaffShare2: "5",
      otherStaffId3: 1234567,
      otherStaffShare3: "5",
      marketId: 1234567,
      marketShare: "5",
    },
    incentiveShare: {
      companyShare: "15",
      myselfShare: "60",
      bossId: 1234567,
      bossShare: "5",
      otherStaffId1: 1234567,
      otherStaffShare1: "5",
      otherStaffId2: 1234567,
      otherStaffShare2: "5",
      otherStaffId3: 1234567,
      otherStaffShare3: "5",
      marketId: 1234567,
      marketShare: "5",
    },
    staffId: 1234567,
    staff: {
      firstName: "ルカ",
      lastName: "山中",
      staffId: 1234567,
      staffNumber: "1234567",
      departmentName: "名古屋支店",
      departmentId: 2,
    },
  },

  ///////
  {
    securitiesId: 12345689,
    insuranceCategory: "nonLifeInsurance",
    contractorAttribute: "corporation",
    contractReportId: 12345689,
    potentialCustomerInfo: {
      potentialCustomerId: 1234567,
      potentialCustomerType: "jointRecruitment",
      supportUse: true,
      marketId: 1234567,
    },
    contractorIndividual: {},
    contractorCorporation: {
      corporationName: "アイティーソリューションズ株式会社",
      corporationClosingMonth: 12,
      corporationRepresentativeFirstName: "岡田",
      corporationRepresentativeLastName: "成規",
      postCode: "1600004",
      address1: "東京都",
      address2: "新宿区",
      address3: "四谷",
      address4: "4-16-3",
      address5: "川辺新宿御苑前ビル8階",
      tel1: "0362748622",
      tel2: "0362748622",
      mailAddress: "system@doitplanning.com",
    },

    insured: {
      firstName: "麻由美",
      lastName: "大澤",
      firstNameKana: "アユミ",
      lastNameKana: "オオサワ",
      dateOfBirth: "2022-07-21",
      potentialCustomerPersonId: 1234567,
    },

    insuranceInfo: {
      policyNumber: "AA123456",
      insuranceCompanyId: 1234567,
      insuranceCompanyName: "アイティーソリューションズ生命",
      insuranceTypeId: 1234567,
      insuranceFee: 10000,
      paymentMethod: "monthlyPayment",
      deemedAnnualPayment: true,
    },
    lifeInsuranceInfo: {
      contractDateOn: "2022-07-21",
    },
    nonLifeInsuranceInfo: {
      applicationDateOn: "2022-07-21",
      insuranceStartDateOn: "2022-07-21",
      insuranceEndDateOn: "2022-07-21",
      receiptDateOn: "2022-07-21",
      returnDateOn: "2022-07-21",
      nonLifeInsuranceAccountingCategory: "new",
      nonLifeInsuranceDepositCategory: "directDebit",
    },
    commissionShare: {
      companyShare: "15",
      myselfShare: "60",
      bossId: 1234567,
      bossShare: "5",
      otherStaffId1: 1234567,
      otherStaffNumber1: "1234567",
      otherStaffShare1: "5",
      otherStaffId2: 1234567,
      otherStaffShare2: "5",
      otherStaffId3: 1234567,
      otherStaffShare3: "5",
      marketId: 1234567,
      marketShare: "5",
    },
    incentiveShare: {
      companyShare: "15",
      myselfShare: "60",
      bossId: 1234567,
      bossShare: "5",
      otherStaffId1: 1234567,
      otherStaffShare1: "5",
      otherStaffId2: 1234567,
      otherStaffShare2: "5",
      otherStaffId3: 1234567,
      otherStaffShare3: "5",
      marketId: 1234567,
      marketShare: "5",
    },
    staffId: 1234567,
    staff: {
      firstName: "ルカ",
      lastName: "山中",
      staffId: 1234567,
      staffNumber: "1234567",
      departmentName: "名古屋支店",
      departmentId: 2,
    },
  },
];

export const dummyGetSecuritiesList200Response = (data) =>
  data.map((security) => {
    const insuranceTypeInfo = dummyInsuranceCompanyTypes.find(
      (type) =>
        type.insuranceTypeId === Number(security.insuranceInfo.insuranceTypeId)
    );
    const insuranceTypeName = insuranceTypeInfo?.insuranceTypeName;

    const insuranceCompanyInfo = dummyInsuranceCompanies.find(
      (company) =>
        company.insuranceCompanyId ===
        Number(security.insuranceInfo.insuranceCompanyId)
    );
    const insuranceCompanyName = insuranceCompanyInfo?.insuranceCompanyName;

    const telLastIndividual = security.contractorIndividual
      ? security.contractorIndividual.tel1
        ? security.contractorIndividual.tel1.slice(-4)
        : ""
      : "";
    const telLastCorporation = security.contractorCorporation
      ? security.contractorCorporation.tel1
        ? security.contractorCorporation.tel1.slice(-4)
        : ""
      : "";
    return {
      securitiesId: security.securitiesId,
      staff: security.staff,
      insuranceCategory: security.insuranceCategory,
      contractorAttribute: security.contractorAttribute,
      contractorIndividual:
        {
          firstName: security.contractorIndividual
            ? security.contractorIndividual.firstName
            : undefined,
          lastName: security.contractorIndividual
            ? security.contractorIndividual.lastName
            : undefined,
          firstNameKana: security.contractorIndividual
            ? security.contractorIndividual.firstNameKana
            : undefined,
          lastNameKana: security.contractorIndividual
            ? security.contractorIndividual.lastNameKana
            : undefined,
          dateOfBirth: security.contractorIndividual
            ? security.contractorIndividual.dateOfBirth
            : undefined,
          postCode: security.contractorIndividual
            ? security.contractorIndividual.postCode
            : undefined,
          address1: security.contractorIndividual
            ? security.contractorIndividual.address1
            : undefined,
          address2: security.contractorIndividual
            ? security.contractorIndividual.address2
            : undefined,
          address3: security.contractorIndividual
            ? security.contractorIndividual.address3
            : undefined,
          telLast: security.contractorIndividual
            ? telLastIndividual ?? undefined
            : undefined,
        } ?? {},
      contractorCorporation:
        {
          corporationName: security.contractorCorporation
            ? security.contractorCorporation.corporationName
            : undefined,
          corporationClosingMonth: security.contractorCorporation
            ? security.contractorCorporation.corporationClosingMonth
            : undefined,
          corporationRepresentativeFirstName: security.contractorCorporation
            ? security.contractorCorporation.corporationRepresentativeFirstName
            : undefined,
          corporationRepresentativeLastName: security.contractorCorporation
            ? security.contractorCorporation.corporationRepresentativeLastName
            : undefined,
          postCode: security.contractorCorporation
            ? security.contractorCorporation.postCode
            : undefined,
          address1: security.contractorCorporation
            ? security.contractorCorporation.address1
            : undefined,
          address2: security.contractorCorporation
            ? security.contractorCorporation.address2
            : undefined,
          address3: security.contractorCorporation
            ? security.contractorCorporation.address3
            : undefined,
          telLast: security.contractorCorporation
            ? telLastCorporation ?? undefined
            : undefined,
        } ?? {},
      insured:
        {
          firstName: security.insured ? security.insured.firstName : undefined,
          lastName: security.insured ? security.insured.lastName : undefined,
          firstNameKana: security.insured
            ? security.insured.firstNameKana
            : undefined,
          lastNameKana: security.insured
            ? security.insured.lastNameKana
            : undefined,
          dateOfBirth: security.insured
            ? security.insured.dateOfBirth
            : undefined,
        } ?? {},
      insuranceInfo: {
        insuranceCompanyId: security.insuranceInfo.insuranceCompanyId,
        insuranceCompanyName: insuranceCompanyName,
        insuranceTypeId: security.insuranceInfo.insuranceTypeId,
        insuranceTypeName: insuranceTypeName,
        insuranceFee: security.insuranceInfo.insuranceFee,
        policyNumber: security.insuranceInfo.policyNumber,
      },
    };
  }) as GetSecuritiesList200ResponseResultsInner[];
