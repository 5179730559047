import { rest } from "msw";
import {
  getPurchasePotentialCustomersResults,
  replace,
} from "@/mocks/PurchasePotentialCustomer/dummy-data";
import { dummyData as dummyMarkets } from "@/mocks/Market/dummy-markets";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import _ from "lodash-es";
import type {
  GetPurchasePotentialCustomers200ResponseResultsInner,
  PostPurchasePotentialCustomerRequest,
} from "@/api";
import randomInteger from "random-int";
import { removeUndefinedAndEmptyStringFromObject } from "@/utils/helper";
import { defaultBaseDummyData } from "../Staff/dummy-get-staff";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

/**
 * PurchasePotentialCustomer:
 * PostPurchasePotentialCustomer

 **/

export const handlers = [
  /**
   * PostPurchasePotentialCustomer API
   */
  rest.post(`${BASE_PATH}/purchasePotentialCustomer`, async (req, res, ctx) => {
    const request = await req.json();
    const newId = randomInteger(1000000, 9999999);
    const market = dummyMarkets.find(
      (market) => market.marketId === request.potentialCustomer.marketId
    );
    const marketName = market?.marketName ?? "";

    const staffInfo = defaultBaseDummyData.find(
      (staff) => staff.staffId === Number(request.staffId)
    );

    const newRecord = convertPostRequestToDummyData(
      request,
      newId,
      marketName,
      staffInfo
    );
    getPurchasePotentialCustomersResults.push(newRecord);

    return res(ctx.status(201), ctx.text(String(newId)));
  }),

  /**
   * PutPurchasePotentialCustomer API
   */
  rest.put(
    `${BASE_PATH}/purchasePotentialCustomer/:purchasePotentialCustomerId`,
    async (req, res, ctx) => {
      const request = await req.json();
      const purchasePotentialCustomerId = Number(
        req.params.purchasePotentialCustomerId
      );

      const market = dummyMarkets.find(
        (market) => market.marketId === request.potentialCustomer.marketId
      );
      const marketName = market?.marketName ?? "";

      const staffInfo = defaultBaseDummyData.find(
        (staff) => staff.staffId === Number(request.staffId)
      );

      // finds a record with the matching ID
      const oldRecord = getPurchasePotentialCustomersResults.find(
        (record) =>
          record.purchasePotentialCustomerId === purchasePotentialCustomerId
      );

      const newRecord = await convertPutRequestToDummyData(
        request,
        oldRecord,
        purchasePotentialCustomerId,
        marketName,
        staffInfo
      );

      // replaces the old data with the new
      replace(oldRecord, newRecord);

      console.log(getPurchasePotentialCustomersResults);

      return res(ctx.status(204));
    }
  ),
];

export const convertPostRequestToDummyData = (
  request: PostPurchasePotentialCustomerRequest,
  purchasePotentialCustomerId: number,
  marketName: string,
  staffInfo: any
) => {
  const converted: GetPurchasePotentialCustomers200ResponseResultsInner = {
    purchasePotentialCustomerId: purchasePotentialCustomerId,
    staff: {
      firstName: staffInfo.personal.firstName,
      lastName: staffInfo.personal.lastName,
      staffId: Number(request.staffId),
      staffNumber: staffInfo.staffNumber,
    },
    potentialCustomer: {
      marketId: request.potentialCustomer.marketId,
      marketName: marketName,
    },
    potentialCustomerFee: {
      purchasePotentialCustomerTotalFee:
        request.potentialCustomerFee.purchasePotentialCustomerTotalFee,
      purchasePotentialCustomerSupportAmount:
        request.potentialCustomerFee.purchasePotentialCustomerSupportAmount,
      purchasePotentialCustomerFee:
        request.potentialCustomerFee.purchasePotentialCustomerFee,
      purchasePotentialCustomerMonth:
        request.potentialCustomerFee.purchasePotentialCustomerMonth ??
        undefined,
    },
    purchasePotentialCustomerName: request.purchasePotentialCustomerName,
    purchasePotentialCustomerOutOfScopeFlag:
      request.purchasePotentialCustomerOutOfScopeFlag,
  };

  return removeUndefinedAndEmptyStringFromObject(converted);
};

export const convertPutRequestToDummyData = (
  request: PostPurchasePotentialCustomerRequest,
  oldRecord: any,
  purchasePotentialCustomerId: number,
  marketName: string,
  staffInfo: any
) => {
  const converted: GetPurchasePotentialCustomers200ResponseResultsInner = {
    purchasePotentialCustomerId: purchasePotentialCustomerId,
    staff: {
      firstName: staffInfo.personal.firstName,
      lastName: staffInfo.personal.lastName,
      staffId: Number(request.staffId),
      staffNumber: staffInfo.staffNumber,
    },
    potentialCustomer: {
      potentialCustomerId: oldRecord.potentialCustomer.potentialCustomerId,
      marketId: request.potentialCustomer.marketId,
      marketName: marketName,
      potentialCustomerName: oldRecord.potentialCustomer.potentialCustomerName,
      staff: {
        firstName: oldRecord.potentialCustomer.staff.firstName,
        lastName: oldRecord.potentialCustomer.staff.lastName,
        staffId: oldRecord.potentialCustomer.staff.staffId,
        staffNumber: oldRecord.potentialCustomer.staff.staffNumber,
      },
    },
    potentialCustomerFee: {
      purchasePotentialCustomerTotalFee:
        request.potentialCustomerFee.purchasePotentialCustomerTotalFee,
      purchasePotentialCustomerSupportAmount:
        request.potentialCustomerFee.purchasePotentialCustomerSupportAmount,
      purchasePotentialCustomerFee:
        request.potentialCustomerFee.purchasePotentialCustomerFee,
      purchasePotentialCustomerMonth:
        request.potentialCustomerFee.purchasePotentialCustomerMonth ??
        undefined,
    },
    purchasePotentialCustomerName: request.purchasePotentialCustomerName,
    purchasePotentialCustomerOutOfScopeFlag:
      request.purchasePotentialCustomerOutOfScopeFlag,
  };

  return removeUndefinedAndEmptyStringFromObject(converted);
};
