import type { PotentialCustomerIndividualScreenItem } from "@/types/PotentialCustomer/potential-customer-individual";
import { defineStore } from "pinia";
import { ref } from "vue";

export const usePotentialCustomerInfoStore = defineStore(
  "potential-customer-info",
  () => {
    // initialize state
    const currentItem = ref<
      Partial<PotentialCustomerIndividualScreenItem["potentialCustomerMeta"]>
    >({
      // initialize all properties of PotentialCustomerInfo
      // regardless of whether they are required or optional
      potentialCustomerType: undefined,
      potentialCustomerFirstVisitOn: undefined,
      memo: "",
    });

    return {
      currentItem,
    };
  }
);
