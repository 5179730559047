import { defineStore } from "pinia";
import { ref } from "vue";

// 案件情報フォーム

// Interface
export interface PotentialCustomerType {
  potentialCustomerId?: number;
  potentialCustomerType: "myself" | "market" | "jointRecruitment" | undefined;
  marketId: number | undefined;
  supportUse: "support" | "notSupport" | undefined;
}

export const useContractReportPotentialCustomerTypeStore = defineStore(
  "potential-customer-type",
  () => {
    // initialize state
    const currentItem = ref<PotentialCustomerType>({
      potentialCustomerType: undefined,
      marketId: undefined,
      supportUse: undefined,
    });

    return {
      currentItem,
    };
  }
);
