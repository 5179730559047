<script setup lang="ts">
import { computed } from "vue";
import ACWrraper from "@/components/AutocompleteComponent/ACWrapper.vue";

const props = withDefaults(
  defineProps<{
    label: string;
    debounce: number;
    // modelValue: any;
    isEditable: boolean | undefined;
    isCreateScreen?: boolean;
    staffId?: any;
    context?: Record<string, any>;
    isOptional?: boolean;
    validation?: string;
  }>(),
  {
    staffId: NaN,
    validation: "",
  }
);

// set id variable to be sent as props, to set errors to in component
// the id is concatenated with the component name and random charatcters to make it unique
const id = "currentStaffComponent-" + Math.random().toString(36).substring(7);

const emit = defineEmits<{
  (e: "update:modelValue", value: any): void;
}>();

const computedStaffId = computed({
  get: () => props.staffId,
  set: (staffId) => {
    const newStaffId = staffId;
    emit("update:modelValue", newStaffId);
  },
});

// Return false as Default
// Return true if props.isOptional is explicitly set to true
const isOptional = computed(() => {
  return props.isOptional ? true : false;
});
</script>

<template>
  <ACWrraper
    v-model="computedStaffId"
    :label="label"
    :id="id"
    inner-class="$reset"
    name="currentStaff"
    :isEditable="isEditable"
    :isOptional="isOptional"
    :staffId="staffId"
    :validation="validation"
  />
</template>

<style scoped></style>
