import { rest } from "msw";
import { useEnvironmentVars } from "@/composables/use-environment-vars";
import { defaultBaseDummyData } from "@/mocks/Staff/dummy-get-staff";
import { dummyInsuranceCompanies } from "@/mocks/InsuranceCompany/dummy-data";
import { dummyData, replace } from "@/mocks/StaffMap/dummy-data";
const { getBaseUrl, mode } = useEnvironmentVars();
const BASE_PATH = getBaseUrl(mode);

export const handlers = [
  /**
   * StaffMap
   * PutStaffMap
   */
  rest.put(
    `${BASE_PATH}/staffMap/:staffId/:insuranceCompanyId`,
    async (req, res, ctx) => {
      const request = await req.json();
      const insuranceCompanyId = Number(req.params.insuranceCompanyId);
      const staffId = Number(req.params.staffId);
      const insuranceCompanyStaffId = String(request.insuranceCompanyStaffId);

      console.log(
        "PutStaffMap - insuranceCompanyId: " +
          insuranceCompanyId +
          ", staffId: " +
          staffId +
          ", insuranceCompanyStaffId: " +
          insuranceCompanyStaffId
      );

      if (insuranceCompanyId && staffId && insuranceCompanyStaffId) {
        const staffInfo = defaultBaseDummyData.find(
          (staff) => staff.staffId === Number(staffId)
        );
        const insuranceCompanyInfo = dummyInsuranceCompanies.find(
          (company) => company.insuranceCompanyId === Number(insuranceCompanyId)
        );

        const newRecord = {
          insuranceCompanyStaffId: insuranceCompanyStaffId,
          insuranceCompany: {
            insuranceCompanyId: insuranceCompanyId,
            insuranceCompanyName:
              insuranceCompanyInfo?.insuranceCompanyName ?? "",
          },
          staff: {
            staffId: staffId,
            staffNumber: staffInfo?.staffNumber ?? "",
            firstName: staffInfo?.personal.firstName ?? "",
            lastName: staffInfo?.personal.lastName ?? "",
          },
        };

        const checkIfExists = dummyData.some(
          (item) => item.insuranceCompanyStaffId === insuranceCompanyStaffId
        );

        if (checkIfExists) {
          const oldRecord = dummyData.find(
            (item) => item.insuranceCompanyStaffId === insuranceCompanyStaffId
          );
          replace(oldRecord, newRecord);
          return res(ctx.status(204));
        } else {
          dummyData.push(newRecord);
          console.log("New StaffMap Data: ", dummyData);
          return res(ctx.status(204));
        }
      } else {
        return res(
          ctx.status(400),
          ctx.json({
            message: "Bad request",
          })
        );
      }
      // return res(ctx.status(204));
    }
  ),

  /**
   * StaffMap
   * DeleteStaffMap
   */
  rest.delete(
    `${BASE_PATH}/staffMap/:staffId/:insuranceCompanyId`,
    async (req, res, ctx) => {
      const staffId = Number(req.params.staffId);
      const insuranceCompanyId = Number(req.params.insuranceCompanyId);
      console.log(
        "DeleteStaffMap - staffId, insuranceCompanyId: ",
        staffId,
        insuranceCompanyId
      );

      if (staffId && insuranceCompanyId) {
        const checkIfExists = dummyData.some(
          (item) =>
            item.insuranceCompany.insuranceCompanyId === insuranceCompanyId &&
            item.staff.staffId === Number(staffId)
        );

        if (checkIfExists) {
          const deleteable = dummyData.find(
            (item) =>
              item.insuranceCompany.insuranceCompanyId === insuranceCompanyId &&
              item.staff.staffId === Number(staffId)
          );
          // remove the target from the dummyData without re-binding the variable
          if (deleteable) dummyData.splice(dummyData.indexOf(deleteable), 1);

          console.log("Present StaffMap Data: ", dummyData);
          return res(ctx.status(204));
        } else {
          return res(
            ctx.status(404),
            ctx.json({
              message: "StaffMap not found",
            })
          );
        }
      } else {
        return res(
          ctx.status(400),
          ctx.json({
            message: "Bad request",
          })
        );
      }
    }
  ),
];
